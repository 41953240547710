import Fuse from "fuse.js";
import { createChatBotMessage } from "react-chatbot-kit";

export const handleInput = async (
	message,
	location,
	handleUserMessage,
	assetTypeInput,
	setAssetTypeInput,
	assetTypes,
	question,
	setLastMessageCategory,
	setResponseData,
	setState,
	saveDataCaptureState,
	setCurrentState,
	capturePhoto,
	draftIdState,
	setChosenAssetType,
) => {
	if (assetTypeInput) {
		const assetTypeNames = assetTypes.map(
			(assetType) => assetType?.data()?.orgName || assetType?.data()?.name,
		);
		const options = {
			includeScore: true,
			threshold: 0.25,
		};
		const fuse = new Fuse(assetTypeNames, options);

		const results = fuse.search(message);
		let returnVal = [];

		for (const res of results) {
			returnVal = [...returnVal, { item: res.item, score: res.score }];
		}

		let response;
		if (returnVal[0]?.item && returnVal[0]?.score === 0) {
			setChosenAssetType(
				assetTypes.find(
					(obj) =>
						obj.data().name === returnVal[0]?.item ||
						obj.data().orgName === returnVal[0]?.item,
				),
			);

			setResponseData({
				[question.key]: returnVal[0].item,
			});

			response = createChatBotMessage("Please review the data.", {
				withAvatar: true,
				widget: "ExtractedInfo",
				payload: {
					capturePhoto,
					draftIdState,
				},
			});

			saveDataCaptureState([{ message: response, action: "add" }]);
			const currentState = {
				stage: "handleAssetDetails",
				showOptions: false,
			};
			setCurrentState(currentState);
			setState((prev) => ({
				...prev,
				messages: [...prev.messages, response],
				...currentState,
			}));
		} else if (returnVal.length) {
			response = createChatBotMessage(
				"Did you mean one of the options below?",
				{
					withAvatar: true,
					widget: "OptionalSelectors",
					payload: { topThreeLocations: [] },
				},
			);
			saveDataCaptureState([{ message: response, action: "add" }]);
			const currentState = {
				options: [...returnVal.map((val) => val.item), "No, another option."],
				showOptions: true,
			};
			setCurrentState(currentState);
			setState((prev) => ({
				...prev,
				messages: [...prev.messages, response],
				...currentState,
			}));
		} else {
			response = createChatBotMessage(
				"I was not able to find that asset type for your organization. Please enter a valid asset type.",
			);

			saveDataCaptureState([{ message: response, action: "add" }]);
			setState((prev) => ({
				...prev,
				messages: [...prev.messages, response],
			}));
			return;
		}
		setAssetTypeInput(false);
		return;
	}

	await handleUserMessage(message, location);

	const lowerCaseMessage = message.toLowerCase();
	if (lowerCaseMessage.includes("location")) {
		setLastMessageCategory("location");
	} else if (lowerCaseMessage.includes("asset")) {
		setLastMessageCategory("asset");
	} else {
		setLastMessageCategory("");
	}
};
