import { useChatbotStore, useFormStore } from "@/stores";
import { refrigerantOptions } from "@/utils/refrigerantOptions";
import Select from "react-select";
import { FormGroup, Label, Row } from "reactstrap";
import { GeneratedPropIcon } from "../../AssetProfile/GeneratedPropIcon";
import { CheckboxInput } from "./CheckboxInput";
import { PreviewField } from "./PreviewField";

const RefrigerantInput = ({
	question,
	responseData,
	setInputData,
	hidePreview,
	fromChatbot,
	placeholder,
}) => {
	const { ocrConfidenceAndColor: formOcrConfidenceAndColor } = useFormStore();
	const { ocrConfidenceAndColor: chatbotOcrConfidenceAndColor } =
		useChatbotStore();
	const ocrConfidenceAndColor = fromChatbot
		? chatbotOcrConfidenceAndColor
		: formOcrConfidenceAndColor;

	let confidence = null;
	let color = "";
	if (ocrConfidenceAndColor[question.key]) {
		confidence = (
			ocrConfidenceAndColor[question.key]?.confidence * 100
		).toFixed(2);
		color = ocrConfidenceAndColor[question.key]?.color;
	}
	const formattedRefrigerantOptions = refrigerantOptions.map((refrigerant) => ({
		label: refrigerant,
		value: refrigerant,
	}));

	const uppercaseResponseData = {
		...responseData,
		[question.key]: responseData[question.key]?.toUpperCase(),
	};

	const chosenOption = {
		label: uppercaseResponseData[question.key],
		value: uppercaseResponseData[question.key],
	};

	// const chosenOption = {
	// 	label: responseData[question.key],
	// 	value: responseData[question.key],
	// };

	return (
		<Row key={question.key}>
			<FormGroup>
				<div
					className={`d-flex align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div className="d-flex flex-row gap-1">
						<Label
							className={`${question.checkbox ? "mb-0" : "single-form-label"}`}
						>
							{question.label}
							{question.required === "true" && (
								<span style={{ color: "red" }}> *</span>
							)}
						</Label>
						{!hidePreview ? (
							<PreviewField
								question={question}
								responseData={responseData}
								fromChatbot={fromChatbot}
							/>
						) : null}
						{!responseData[question.key] && placeholder && (
							<GeneratedPropIcon />
						)}
					</div>
					{question.checkbox ? (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={setInputData}
							notStandalone={true}
						/>
					) : null}
				</div>
				<div className="position-relative">
					<Select
						key={question.key}
						value={chosenOption}
						onChange={(e) => {
							setInputData(question, e.value.toUpperCase());
						}}
						placeholder={placeholder || question.placeholder}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								boxShadow: color ? `0 0 10px ${color}` : "none",
								outline: color ? `1px solid ${color}` : "none",
							}),
						}}
						options={formattedRefrigerantOptions}
						isDisabled={
							question.tiedTo ? responseData[question.tiedTo] === true : false
						}
						className={`${
							question.highlighted ? "highlight-input" : ""
						} 'form-inputs'`}
					/>
					{ocrConfidenceAndColor && color && confidence && (
						<span
							className="confidence-text"
							style={{
								color: ocrConfidenceAndColor[question.key]?.color,
								right: "45px",
							}}
						>
							{confidence}%
						</span>
					)}
				</div>
			</FormGroup>
		</Row>
	);
};

export { RefrigerantInput };
