// File: SingleLocation.tsx

import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { AssetsTable } from "@/components/layouts/SignedIn/views/Admin/AssetsTable/children/AssetsTable";
import { ScrollableNavigationBar } from "@/components/pure/Navigation/dfp-scroll-navigation";
import { useGetLocation } from "@/hooks/locationQueries";
import { useGetSelfOrganization } from "@/hooks/organizationQueries";
import { useUserStore } from "@/stores/userStore";
import { GeneralTab } from "@SignedIn/views/SingleLocation/GeneralTab/GeneralTab.js";
import { MatterportTab } from "@SignedIn/views/SingleLocation/MatterportTab/MatterportTab";
import { SubmissionsTab } from "@SignedIn/views/SingleLocation/SubmissionsTab/SubmissionsTab";
import { LeftOutlined } from "@ant-design/icons";
import { AssetMapTab } from "@components/layouts/SignedIn/views/SingleLocation/AssetMapTab";
import { IGuideTab } from "@components/layouts/SignedIn/views/SingleLocation/IGuideTab/IGuide";
import { MaintenanceTab } from "@components/layouts/SignedIn/views/SingleLocation/MaintenanceTab/MaintenanceTab";
// import { PredictiveSpendTab } from '@components/layouts/SignedIn/views/SingleLocation/PredictiveSpendTab/PredictiveSpendTab';
import { SettingsTab } from "@components/layouts/SignedIn/views/SingleLocation/SettingsTab/SettingsTab";
import { Viewer360Tab } from "@components/layouts/SignedIn/views/SingleLocation/Viewer360Tab/Viewer360Tab";
import { VirtualModal } from "@components/layouts/SignedIn/views/SingleLocation/VirtualModal/VirtualModal";
import { DB_ORG } from "@constants/db";
import { useUpdateLocation } from "@hooks/locationQueries";
import { canViewVerizonLocation } from "@services/location-service";
import { Card, message } from "antd";
import { Button } from "antd";
import type React from "react";
import { useEffect, useState } from "react";
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { StatusIndicator } from "./single-location-status-icon";

let viewer = false;

interface SingleLocationProps {
	userRole: any;
	setToast: any;
}

export const SingleLocation: React.FC<SingleLocationProps> = ({
	userRole,
	setToast,
}) => {
	const updateLocationMutation = useUpdateLocation();
	const { id } = useParams<{ id: string }>();
	const { user, isVerizonUser } = useUserStore();
	const organization = user.organization.external_firebase_id;
	const locationState = useLocation();
	const [virtualTab, setVirtualTab] = useState<any>(null);
	const [isVerified, setIsVerified] = useState(false);
	const [locInfo, setLocInfo] = useState<any>();
	const { userOrganization, isLoading: isLoadingUserOrganization } =
		useGetSelfOrganization();
	const { locationInfo, isLoading: isLoadingLocation } = useGetLocation(
		id as string,
	);

	// Helper state to rerender when user uploads virtual model
	const [virtualChange, setVirtualChange] = useState(false);

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	// Define tabNames based on organization
	let tabNames: string[] = [];
	if (organization === DB_ORG.CUSHMANWAKEFIELD) {
		tabNames = ["Overview", "Virtual", "Assets", "Submissions"];
	} else {
		tabNames = [
			"Overview",
			"Virtual",
			"Assets",
			// 'Predictive Spend',
			"Submissions",
			"Maintenance",
		];
	}
	if (organization === DB_ORG.CVS) {
		tabNames.push("Asset Map");
	}
	tabNames.push("Settings");

	const [locationHealth, setLocationHealth] = useState<number | null>(null);

	const getVirtualTab = async () => {
		if (isLoadingUserOrganization !== false || isLoadingLocation !== false)
			return;
		const showMatterport = userOrganization.config.show_matterport;
		const showIguide = userOrganization.config.show_iguide;
		const matterport = locationInfo.matterport || null;
		const iguide = locationInfo.iguide || null;
		// add_in_later
		const viewerData = locationInfo.sphereViewerData || null;

		if (matterport) {
			setVirtualTab(
				<MatterportTab
					matterport={matterport}
					locationInfo={locationInfo}
					setVirtualChange={setVirtualChange}
					userRole={userRole}
				/>,
			);
		} else if (iguide) {
			setVirtualTab(
				<IGuideTab
					locationId={id as string}
					iguide={iguide}
					locationInfo={locationInfo}
					setVirtualChange={setVirtualChange}
					userRole={userRole}
				/>,
			);
		} else if (viewer) {
			setVirtualTab(
				<Viewer360Tab
					locationId={id}
					organization={userOrganization.external_firebase_id}
				/>,
			);
		} else {
			setVirtualTab(
				<Card className="h-100 w-100">
					<div className="d-flex flex-column align-items-center justify-content-evenly">
						<span
							className="mb-2 fs-4"
							style={{
								textShadow: "2px 2px 12px rgba(0, 0, 0, 0.5)",
							}}
						>
							No Model Currently Connected.
						</span>
						{showMatterport && (
							<VirtualModal
								virtualType={"Matterport"}
								locationInfo={locationInfo}
								organization={userOrganization.external_firebase_id}
								setVirtualChange={setVirtualChange}
							/>
						)}
						{showIguide && (
							<VirtualModal
								virtualType={"iGuide"}
								locationInfo={locationInfo}
								organization={userOrganization.external_firebase_id}
								setVirtualChange={setVirtualChange}
							/>
						)}
						{viewerData && (
							<Button
								color="success"
								style={{ width: "165px" }}
								onClick={() => {
									viewer = true;
									setVirtualChange(true);
								}}
							>
								Connect 360 View
							</Button>
						)}
					</div>
				</Card>,
			);
		}
		setVirtualChange(false);
	};

	const verifyCushmanUser = async () => {
		if (isVerizonUser) {
			const canViewPage = await canViewVerizonLocation(user?.id, id);
			if (canViewPage) setIsVerified(true);
			else navigate("/locations");
		} else setIsVerified(true);
	};

	// If Cushman org + VZW user, redirect if in wrong location
	useEffect(() => {
		if (isVerified) return;
		if (userOrganization.external_firebase_id !== DB_ORG.CUSHMANWAKEFIELD)
			setIsVerified(true);
		else verifyCushmanUser();
	}, [isVerizonUser]);

	// Update locInfo & locationHealth states when it is confirmed user can view page
	useEffect(() => {
		if (isVerified && locationInfo) {
			setLocInfo(locationInfo);
			if (locationInfo.health) {
				setLocationHealth(locationInfo.health.health_score.toFixed(1));
			} else {
				setLocationHealth(0);
			}
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVerified, locationInfo]);

	useEffect(() => {
		getVirtualTab();
	}, [virtualChange, locationInfo, userOrganization]);

	const resetVirtual = async () => {
		let virtual: string | null = null;
		if (locationInfo.matterport) {
			virtual = "matterport";
		} else if (locationInfo.iguide) {
			virtual = "iguide";
		}
		const body = { [`${virtual?.toString()}`]: "DELETE" };
		const is_firebase_id = false;
		const id = locationInfo.id;
		await updateLocationMutation.mutateAsync({
			id,
			is_firebase_id,
			body,
		});
		setVirtualChange(true);
		message.success("Virtual ID has been reset");
	};

	const goBackToLocations = () => {
		const stateProps = locationState?.state
			? {
					pageNum: locationState.state.pageNum,
					sortBy: locationState.state.sortBy,
					sortOrder: locationState.state.sortOrder,
					searchVal: locationState.state.searchVal,
				}
			: undefined;

		navigate("/locations", { state: stateProps });
	};

	const ADDRESS = `${
		locInfo?.address ||
		`${locInfo?.address1}${locInfo?.address2 ? ` ${locInfo.address2}` : ""}`
	}, ${locInfo?.city}, ${locInfo?.state} ${locInfo?.zip}`;

	if (
		isLoading ||
		isLoadingUserOrganization !== false ||
		isLoadingLocation !== false
	) {
		return <Loader />;
	}

	return isVerified ? (
		<div className="single-location-parent">
			{/* Navigation Bar */}
			<ScrollableNavigationBar
				tabNames={tabNames}
				basePath={`/locations/${id}`}
			/>

			{/* Updated Navigation Container */}
			<Card className="border-bottom-0 rounded-bottom-0 shadow-sm">
				<Button
					type="link"
					onClick={goBackToLocations}
					onKeyDown={goBackToLocations}
					icon={<LeftOutlined />}
					size="large"
					className="px-0"
				>
					Back
				</Button>

				{/* Second Row: Activity Status */}
				{organization === DB_ORG.CUSHMANWAKEFIELD && (
					<StatusIndicator isActive={locInfo?.is_active} />
				)}
				{/* First Row: Address and Health Score */}
				<div className="row align-items-between my-2">
					{/* Address */}
					<div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
						<span className="fw-bold">{ADDRESS}</span>
					</div>
					{/* Health Score */}
					{organization !== DB_ORG.CUSHMANWAKEFIELD && (
						<div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
							<div className="single-location-health-pill">
								<HealthScorePill
									healthScore={locationHealth || 0}
									size={"lg"}
								/>
							</div>
						</div>
					)}
				</div>
			</Card>

			{/* Route Content */}
			<div className="fullSize">
				<Routes>
					<Route path="/" element={<Navigate to="overview" replace />} />
					<Route
						path="overview"
						element={
							<GeneralTab
								healthScore={locationHealth}
								organization={userOrganization}
								info={locInfo}
								locationId={id}
								userRole={userRole}
								setToast={setToast}
							/>
						}
					/>
					<Route path="virtual" element={virtualTab} />
					<Route
						path="assets"
						element={<AssetsTable browserLocationId={id || ""} />}
					/>
					{/* <Route
						path="predictive-spend"
						element={
							<PredictiveSpendTab
								locationId={id}
								organization={userOrganization.external_firebase_id}
							/>
						}
					/> */}
					<Route
						path="submissions"
						element={
							<SubmissionsTab
								browserLocationId={id as string}
								organization={userOrganization as any}
							/>
						}
					/>
					<Route
						path="maintenance"
						element={
							<MaintenanceTab
								isFocused={true}
								locationId={id}
								organization={userOrganization.external_firebase_id}
							/>
						}
					/>
					<Route
						path="settings"
						element={
							<SettingsTab userRole={userRole} resetVirtual={resetVirtual} />
						}
					/>
					<Route
						path="asset-map"
						element={
							<AssetMapTab
								isFocused={true}
								locationId={id}
								organization={userOrganization.external_firebase_id}
								address={ADDRESS}
							/>
						}
					/>
					<Route path="*" element={<Navigate to="overview" replace />} />
				</Routes>
			</div>
		</div>
	) : null;
};
