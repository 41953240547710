const CustomLegend = ({
	state,
	parentDispatch,
	chartState,
	dispatch,
	chartRef,
	strategyRef,
	strategyAssetsRef,
}) => {
	const { numAssetTypes } = state;
	const { legendSelectedAssetTypes, showLegend, data } = chartState;
	const handleClick = (label) => {
		let updatedAssetTypes = [...legendSelectedAssetTypes];
		if (legendSelectedAssetTypes.includes(label)) {
			updatedAssetTypes = updatedAssetTypes.filter((type) => type !== label);
		} else {
			updatedAssetTypes.push(label);
		}

		// Update the selected asset types in state
		dispatch({
			type: "SET_LEGEND_SELECTED_ASSET_TYPES",
			payload: updatedAssetTypes,
		});

		// Filter assets based on selected types
		parentDispatch({
			type: "SET_CHART_ASSETS",
			payload: state.originalAssets.filter((asset) =>
				updatedAssetTypes.includes(asset.assetType),
			),
		});

		// Update the hidden property of each dataset based on the selected asset types
		chartRef.current.data.datasets.forEach((dataset) => {
			// exclude budget
			if (dataset.type === "line") return;
			dataset.hidden = !updatedAssetTypes.includes(dataset.label);
		});

		// Trigger chart update to animate the change
		chartRef.current.update();
	};

	const handleMouseEnter = (dataset) => {
		const chart = chartRef.current;
		if (!chart) return;

		const datasetIndex = chart.data.datasets.findIndex(
			(d) => d.label === dataset.label,
		);

		if (datasetIndex !== -1) {
			// Activate datalabels for all points in the hovered dataset, based on the original filtering logic
			chart.data.datasets.forEach((ds, index) => {
				ds.datalabels = {
					display: (context) => {
						if (index !== datasetIndex) return false;

						const year = chart.data.labels[context.dataIndex];
						const type = ds.label;
						const newAssets =
							strategyAssetsRef?.current || state.originalAssets;

						const count = newAssets.filter((asset) =>
							strategyRef.current.value === "Cumulative Do Nothing"
								? asset.replacementYear <= year && asset.assetType === type
								: asset.replacementYear === year && asset.assetType === type,
						).length;

						const cost = newAssets
							.filter((asset) =>
								strategyRef.current.value === "Cumulative Do Nothing"
									? asset.replacementYear <= year && asset.assetType === type
									: asset.replacementYear === year && asset.assetType === type,
							)
							.reduce((acc, asset) => {
								const replCost = Number(asset.avgReplCostUSD);
								return isNaN(replCost) ? acc : acc + replCost;
							}, 0);

						// Display the datalabel only if both count and cost are greater than 0
						return count > 0 && cost > 0;
					},
				};
			});

			chart.update();
		}
	};

	const handleMouseLeave = () => {
		const chart = chartRef.current;
		// Clear active elements to deactivate all tooltips
		chart.setActiveElements([]);
		chart.tooltip.setActiveElements([]);
		chart.data.datasets.forEach((ds) => {
			ds.datalabels = {
				display: false,
			};
		});
		chart.update();
	};

	return (
		showLegend && (
			<div
				className="custom-legend d-flex flex-column text-center fw-bold"
				style={{
					padding: "10px",
					fontSize: "16px",
					width: numAssetTypes > 18 ? "500px" : "260px",
				}}
			>
				<div className="position-relative mb-2">
					<h4 className="fw-bold m-0" style={{ fontSize: "16px" }}>
						Interactive Legend
					</h4>
				</div>
				<div
					className="pill-scrollbar-horizontal w-100"
					style={{
						maxHeight: "100%",
						overflowY: "auto",
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						height: "100%",
						flexWrap: "wrap",
					}}
				>
					{data.datasets
						.slice() // Create a shallow copy to avoid mutating the original array
						.sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically by label
						.filter((dataset) => dataset.type !== "line")
						.map((dataset, index) => {
							const isHidden = !legendSelectedAssetTypes.includes(
								dataset.label,
							);
							return (
								<div
									key={index}
									className="legend-item"
									onClick={() => handleClick(dataset.label)}
									onMouseEnter={() => handleMouseEnter(dataset)}
									onMouseLeave={handleMouseLeave}
									style={{
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
										marginBottom: "3px",
										fontFamily: "Helvetica Neue, Helvetica, sans-serif",
										width: "240px", // Ensure consistency in item width
									}}
								>
									<div
										className="legend-color"
										style={{
											flexShrink: 0, // Prevent the dot from shrinking
											width: "22px",
											height: "22px",
											backgroundColor: isHidden
												? "#FFFFFF"
												: dataset.backgroundColor[0],
											borderRadius: "50%",
											border: `2px solid ${dataset.backgroundColor[0]}`,
										}}
									/>
									<span
										style={{
											fontSize: "14px",
											opacity: isHidden ? 0.5 : 1,
											fontFamily:
												'"Helvetica Neue", Helvetica, Arial, sans-serif',
											marginLeft: "8px",
											textAlign: "start",
											overflowWrap: "break-word", // Ensure text wraps properly
											wordBreak: "break-word",
										}}
									>
										{dataset.label}
									</span>
								</div>
							);
						})}
				</div>
			</div>
		)
	);
};

export { CustomLegend };
