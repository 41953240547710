import Loader from "@components/layouts/SignedIn/Chatbot/Loader";
import { useLocationStore, useUserStore } from "@stores";
import { useEffect, useState } from "react";
import { BsBarChartFill } from "react-icons/bs";
import { IoArrowRedo } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import styles from "./BotChatMessage.module.css";
import { CONTAINER } from "./config";

const BotChatMessage = ({ message, container }) => {
	const [messageText, setMessageText] = useState();
	const [hasGraph, setHasGraph] = useState(false);
	const [redirect, setRedirect] = useState("");
	const [submitState, setSubmitState] = useState({ tab: "" });
	const themeMode = useUserStore((state) => state.themeMode);
	const setActiveTab = useLocationStore((state) => state.setActiveTab);
	const isWidget = container === CONTAINER.WIDGET;

	const scrollIntoView = () => {
		setTimeout(() => {
			const container = document.querySelector(
				".react-chatbot-kit-chat-message-container",
			);

			container.scrollTo(0, container.scrollHeight);
		}, 100);
	};
	useEffect(() => {
		messageText && scrollIntoView();
	}, [messageText]);

	useEffect(() => {
		const handleMessage = async () => {
			let data;
			if (message instanceof Promise) {
				data = await message;
			} else {
				data = message;
			}

			if (typeof data === "string") {
				setMessageText(data);
			}

			if (typeof data === "object") {
				console.log(data);
				setMessageText(data.text);
				setHasGraph(data.graph);
				setRedirect(data.redirect);
				setSubmitState(data.submitState || null);
			}
		};

		handleMessage();
	}, [message]);

	const messageContainerStyleClasses = isWidget
		? "react-chatbot-kit-chat-bot-message"
		: themeMode !== "dark"
			? styles.messageContainer
			: styles.messageContainer_dark;

	const messageTextStyleClasses = isWidget
		? ""
		: themeMode !== "dark"
			? styles.messageText
			: styles.messageText_dark;

	return (
		<div className={messageContainerStyleClasses}>
			<RedirectIcon
				hasRedirect={!!redirect}
				locationId={redirect}
				submitState={submitState}
				setActiveTab={setActiveTab}
			/>
			<GraphIcon hasGraph={hasGraph} />
			{messageText ? (
				<span className={messageTextStyleClasses}>{messageText}</span>
			) : (
				<Loader dashboard={true} isWidget={isWidget} />
			)}
			<div
				className="react-chatbot-kit-chat-bot-message-arrow"
				style={{ borderRightColor: "" }}
			></div>
		</div>
	);
};

const GraphIcon = ({ hasGraph }) => {
	if (!hasGraph) return null;

	return (
		<div className={styles.badge}>
			<BsBarChartFill style={{ color: "#bf1e2d" }} />
		</div>
	);
};

const RedirectIcon = ({
	hasRedirect,
	locationId,
	submitState,
	setActiveTab,
}) => {
	const navigate = useNavigate();

	if (!hasRedirect) return null;

	const handleRedirect = () => {
		if (!submitState) {
			console.error("submitState is null or undefined.");
			return;
		}
		console.log(submitState);
		setActiveTab(submitState.tab);
		navigate(`/locations/${locationId}`, { state: submitState });
	};

	return (
		<div
			className={styles.badge}
			style={{ cursor: "pointer" }}
			onClick={handleRedirect}
		>
			<IoArrowRedo style={{ color: "#bf1e2d" }} />
		</div>
	);
};

export default BotChatMessage;
