import { ALFRED_SERVICE_URL } from "@/constants/env";
import { auth } from "@assets/services/auth-service";
import axios from "axios";
import {
	getSelfOrganization,
	selfUpdateOrganization,
} from "./organization-service";

export const INTEGRATION_URL = "integration";
export const INTEGRATION_TYPES_URL = "types";
export const SYNC_URL = "sync";
const BASE_URL = ALFRED_SERVICE_URL || "http://localhost:3003";

export class IntegrationServiceError extends Error {
	constructor(message = "") {
		super(message);
		this.name = "IntegrationServiceError";
	}
}

export const getIntegrations = async () => {
	const fetchIntegrations = async () => {
		try {
			const url = `${BASE_URL}/${INTEGRATION_URL}/${INTEGRATION_TYPES_URL}`;
			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			return response.data;
		} catch (e: any) {
			throw new IntegrationServiceError(
				`Error fetching integrations ${JSON.stringify(e)}`,
			);
		}
	};
	return fetchIntegrations();
};

export const getIntegrationById = async (integrationId: string) => {
	const fetchIntegrationById = async () => {
		try {
			const url = `${BASE_URL}/${INTEGRATION_URL}/${INTEGRATION_TYPES_URL}/${integrationId}`;
			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			return response.data;
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error fetching integration by id: ${errorMessage}`,
			);
		}
	};
	return fetchIntegrationById();
};

export const getIntegrationByKey = async (integrationKey: any) => {
	const fetchIntegrationByKey = async () => {
		try {
			const url = `${BASE_URL}/${INTEGRATION_URL}/${INTEGRATION_TYPES_URL}/${integrationKey}`;
			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			return response.data;
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error fetching integration by key: ${errorMessage}`,
			);
		}
	};
	return fetchIntegrationByKey();
};

export const updateIntegrationCreds = async (
	integrationType: any,
	username: any,
	password: any,
	status: any,
	_orgId: any,
) => {
	const config = {
		username,
		password,
		enabled: status,
	};

	try {
		const org = await getSelfOrganization().catch((e) => {
			throw new IntegrationServiceError(
				`Error getting org ${JSON.stringify(e)}`,
			);
		});
		const updatedOrg = {
			...org,
			integrationSettings: { [integrationType]: config },
		};
		await selfUpdateOrganization(updatedOrg).catch((e) => {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error updating ${integrationType} config in db: ${errorMessage}`,
			);
		});
	} catch (e: any) {
		const errorMessage = e.response?.data?.detail || "Unknown error occurred";
		throw new IntegrationServiceError(
			`Unknown Error updating ${integrationType} config in db: ${errorMessage}`,
		);
	}
};

export const fetchAccessToken = async (
	integrationType: any,
	username: any,
	password: any,
) => {
	const reqBody = { username, password };
	const fetchAccessToken = async () => {
		try {
			const token = await auth?.currentUser?.getIdToken();
			const response = await axios.post(
				`${BASE_URL}/integration/${integrationType}/auth`,
				reqBody,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);
			const data = response.data;
			const time = new Date().getTime();
			return {
				lastUpdated: time,
				token: data.access_token,
				expiration: data.expires_in * 1000,
				refreshToken: data.refresh_token,
				tokenType: data.token_type,
				expiresAt: time + data.expires_in * 1000,
			};
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error getting access token for ${integrationType}: ${errorMessage}`,
			);
		}
	};
	return fetchAccessToken();
};

export const getAccessToken = async (
	integrationType: string,
	username: any,
	password: any,
) => {
	return await fetchAccessToken(integrationType, username, password);
};

export const fetchData = async (
	integrationType: string,
	endpoint: any,
	token: any,
	params: any,
) => {
	const fetchEndpointData = async () => {
		try {
			const response = await axios.post(
				`${BASE_URL}/integration/${integrationType}/${endpoint}`,
				{ APIKey: token, params },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			);
			return response.data;
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error fetching data from ${endpoint}: ${errorMessage}`,
			);
		}
	};
	return fetchEndpointData();
};

export const deleteIntegration = async (orgId: any, integrationId: any) => {
	const deleteIntegration = async () => {
		try {
			const url = `${BASE_URL}/${INTEGRATION_URL}/${INTEGRATION_TYPES_URL}/${orgId}/${integrationId}`;
			const response = await axios.delete(url, {
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			return response.data;
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error deleting integration by id: ${errorMessage}`,
			);
		}
	};
	return deleteIntegration();
};

export const addIntegration = async (
	orgId: any,
	integrationId: any,
	newIntegration: {
		is_enabled: boolean;
		credentials: any;
		name: any;
		id: any;
		key: any;
		sync_config: {
			invoice: { is_enabled: boolean };
			workOrder: { is_enabled: boolean };
			location: { is_enabled: boolean };
			asset: { is_enabled: boolean };
		};
	},
) => {
	const addIntegration = async () => {
		try {
			const url = `${BASE_URL}/${INTEGRATION_URL}/${INTEGRATION_TYPES_URL}/${orgId}/${integrationId}`;
			const response = await axios.post(
				url,
				{ integration: newIntegration },
				{
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			);
			return response.data;
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error adding integration by id: ${errorMessage}`,
			);
		}
	};
	return addIntegration();
};

export const startIntegrationSyncJob = async (
	orgId: any,
	integrationId: any,
	config: any,
) => {
	const startIntegrationSyncJob = async () => {
		try {
			const url = `${BASE_URL}/${INTEGRATION_URL}/${SYNC_URL}/${orgId}/${integrationId}`;
			const response = await axios.post(
				url,
				{ configuration: config },
				{
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			);
			return response.data;
		} catch (e: any) {
			const errorMessage = e.response?.data?.detail || "Unknown error occurred";
			throw new IntegrationServiceError(
				`Error starting sync job: ${errorMessage}`,
			);
		}
	};
	return startIntegrationSyncJob();
};
