import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleAssetPhoto = (
	urlPromise,
	savedLocation,
	setAssetPhoto,
	setState,
	assetQuestion,
	plateQuestion,
	capturePhoto,
	saveDataCaptureState,
	setCurrentState,
) => {
	const prevMessageNoWidget = createChatBotMessage(
		`${savedLocation?.data?.name ?? savedLocation?.data?.city}, got it. Please upload a photo of the asset.`,
		{
			withAvatar: true,
		},
	);

	const userMessage = createClientMessage("", {
		widget: "CustomImageMessage",
		payload: {
			question: assetQuestion,
			capturePhoto,
			imageUrl: urlPromise,
		},
	});
	const message = createChatBotMessage(
		"Thank you! Please upload a photo of the manufacturer plate.",
		{
			widget: "OptionalSelectors",
			withAvatar: true,
			payload: {
				topThreeLocations: [],
				question: plateQuestion,
				capturePhoto,
			},
		},
	);

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message, action: "add" },
	]);
	const currentState = {
		options: ["Upload photo"],
		stage: "platePhoto",
		showOptions: true,
	};

	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			message,
		],
		...currentState,
	}));

	urlPromise.then((url) => {
		setAssetPhoto(url);
	});
};
