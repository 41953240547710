import { Button, Modal } from "antd";

const ConfirmDialog = (props) => {
	const { showConfirm, toggle, title, body, functionality } = props;
	return (
		<Modal
			title={title}
			open={showConfirm}
			onOk={functionality}
			onCancel={toggle}
			footer={[
				<Button key="back" onClick={toggle}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={functionality}>
					Confirm
				</Button>,
			]}
		>
			<p>{body}</p>
		</Modal>
	);
};

export { ConfirmDialog };
