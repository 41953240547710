import { formatMillions } from "@/utils/formatNumberToMillions";

const BudgetDifference = ({ state, chartState }) => {
	const { budget, chartLoading, years } = state;
	const { selectedYear, isYAxisReplCost, budgetShortage } = chartState;
	return (
		budget &&
		budget.every((val) => !!val) &&
		!selectedYear &&
		!chartLoading &&
		isYAxisReplCost && (
			<div
				className="d-flex"
				style={{
					paddingLeft: "70px",
					paddingRight: "20px",
				}}
			>
				{budgetShortage &&
					budgetShortage.map((val, i) => {
						return (
							<div
								key={i}
								style={{
									width: `calc(100%/${years})`,
								}}
							>
								<div className="d-flex flex-column align-items-center">
									<div
										style={{
											color: val >= 0 ? "#32a852" : "#bf1e2d",
										}}
										className="d-flex align-items-center"
									>
										{val > 0
											? formatMillions(Math.abs(val), 1)
											: "(" + formatMillions(Math.abs(val), 1) + ")"}
									</div>
								</div>
							</div>
						);
					})}
			</div>
		)
	);
};

export { BudgetDifference };
