import { Loader } from "@SignedIn/Loader/Loader";
import { cushmanExport } from "@SignedIn/views/Admin/cushmanExport/cushmanExport";
import { useEffect, useState } from "react";
import { MdDownload } from "react-icons/md";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";

const PrevMonthTab = (props) => {
	const { isFocused } = props;
	const [locsWithSubmission, setLocsWithSubmission] = useState();
	const [locsWithoutSubmission, setLocsWithoutSubmission] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [tabChange, setTabChange] = useState(false);

	const getLocations = async () => {
		const firebaseConfig = JSON.parse(
			import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
		);
		const response = await fetch(
			firebaseConfig.projectId.includes("staging")
				? "https://us-central1-property-echo-staging.cloudfunctions.net/getPrevMonthCushmanData"
				: "https://us-central1-property-echo-4d747.cloudfunctions.net/getPrevMonthCushmanData",
			{
				method: "GET",
			},
		);
		const data = await response.json();
		await setLocsWithSubmission(data.locationsWithSubmission);
		await setLocsWithoutSubmission(data.locationsWithoutSubmission);
		setIsLoading(false);
	};

	const getLastMonthNameAndYear = () => {
		const date = new Date();
		date.setMonth(date.getMonth() - 1);
		const lastMonth = date.toLocaleString("default", { month: "long" });
		return `${lastMonth} ${date.getFullYear()}`;
	};

	useEffect(() => {
		if (isFocused) {
			setTabChange(true);
		}
	}, [isFocused]);

	useEffect(() => {
		if (isFocused) {
			getLocations();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabChange]);

	return (
		<div
			style={{
				gap: "20px",
			}}
			className="d-flex flex-column flex-lg-row"
		>
			<Card className="mt-4 previous-audit-table">
				<CardHeader className="p-3 d-flex flex-row justify-content-between">
					<div className="table-title m-0 opacity-75">
						Locations With PM Report {`for ${getLastMonthNameAndYear()}`}
					</div>
					<Button
						color="primary"
						className="rounded-circle no-border table-btn"
						outline
						onClick={() =>
							cushmanExport(
								locsWithSubmission,
								`Locations With PM Report ${getLastMonthNameAndYear()}`,
							)
						}
					>
						<MdDownload className="larger-font-icon larger-font" />
					</Button>
				</CardHeader>
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<Table>
							<thead>
								<tr>
									<th>Site Name</th>
									<th>Address</th>
									<th>City</th>
									<th>State</th>
									<th>Zip</th>
								</tr>
							</thead>
							<tbody>
								{locsWithSubmission.map((loc) => {
									return (
										<tr key={loc.id} className="pmreport-table-row">
											<td>{loc.sitename}</td>
											<td>{loc.address}</td>
											<td>{loc.city}</td>
											<td>{loc.state}</td>
											<td>{loc.zip}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					)}
				</CardBody>
			</Card>
			<Card className="mt-4 previous-audit-table">
				<CardHeader className="p-3 d-flex flex-column flex-md-row justify-content-between">
					<div className="table-title m-0 opacity-75">
						Locations Without PM Report {`for ${getLastMonthNameAndYear()}`}
					</div>
					<Button
						color="primary"
						className="rounded-circle no-border table-btn"
						outline
						onClick={() =>
							cushmanExport(
								locsWithoutSubmission,
								`Locations Without PM Report ${getLastMonthNameAndYear()}`,
							)
						}
					>
						<MdDownload className="larger-font-icon larger-font" />
					</Button>
				</CardHeader>
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<Table>
							<thead>
								<tr>
									<th>Site Name</th>
									<th>Address</th>
									<th>City</th>
									<th>State</th>
									<th>Zip</th>
								</tr>
							</thead>
							<tbody>
								{locsWithoutSubmission.map((loc) => {
									return (
										<tr key={loc.id} className="pmreport-table-row">
											<td>{loc.sitename}</td>
											<td>{loc.address}</td>
											<td>{loc.city}</td>
											<td>{loc.state}</td>
											<td>{loc.zip}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					)}
				</CardBody>
			</Card>
		</div>
	);
};

export { PrevMonthTab };
