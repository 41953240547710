import {
	assetTypesCaseLinearFeetDigi,
	assetTypesWithAssetTag,
	assetTypesWithCaseLinearFeet,
	assetTypesWithRackIdDigi,
} from "@constants/formInputs/assetTypes";

export const getAdditionalDetailsFields = (
	organization,
	responseData,
	swarovskiFields,
) =>
	organization === "campingworld.com"
		? [
				// 'quantity',
				// 'bayQuantity',
				"bayLocation",
			]
		: // : organization === 'carliecs.com'
			// ? ['assetTag', 'idTagAssetImage']
			organization === "foodlion.com"
			? [
					...(assetTypesWithCaseLinearFeet.includes(responseData.assetType)
						? ["caseLinearFeet"]
						: assetTypesWithAssetTag.includes(responseData.assetType)
							? ["assetTag", "idTagAssetImage"]
							: ["powerSource", "voltage"]),
					...(responseData.refUsageType === "Connected to a Rack"
						? ["rackId"]
						: responseData.refUsageType === "Self Contained Asset"
							? ["refType", "totalCharge"]
							: []),
				]
			: organization === "raisingcanes.com"
				? ["subLocation"]
				: organization === "razzoos.com"
					? ["assetTag", "idTagAssetImage"]
					: organization === "DIGI"
						? [
								"powerSource",
								"voltage",
								...(assetTypesWithRackIdDigi.includes(responseData.assetType)
									? ["refUsageType", "rackId", "refType", "totalCharge"]
									: assetTypesCaseLinearFeetDigi.includes(
												responseData.assetType,
											)
										? [
												"caseLinearFeet",
												"refUsageType",
												"rackId",
												"refType",
												"totalCharge",
											]
										: responseData.assetType === "Cooler- Self Contained"
											? [
													"leakDetector",
													"refUsageType",
													"rackId",
													"refType",
													"totalCharge",
												]
											: []),
							]
						: organization === "swarovski.com"
							? swarovskiFields
							: organization === "Smash Burger" ||
									organization === "CARMAX" ||
									organization === "VSM"
								? ["assetTag", "idTagAssetImage"]
								: organization === "COOPERSHAWK"
									? ["assetTag", "idTagAssetImage"]
									: organization === "Willie's"
										? ["assetTag", "idTagAssetImage"]
										: organization === "BJsRestaurant"
											? ["assetTag", "idTagAssetImage"]
											: [];

export const getNotAvailableMapping = (organization) => ({
	mapping:
		organization === "campingworld.com"
			? { bayLocation: "bayLocationNotAvailable" }
			: organization === "foodlion.com" || organization === "DIGI"
				? { totalCharge: "chargeNotFound", voltage: "voltageNotFound" }
				: organization === "raisingcanes.com" ||
						organization === "razzoos.com" ||
						organization === "swarovski.com" ||
						organization === "DIGI"
					? {}
					: "",
});
