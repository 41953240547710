import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleSubmitNew = async (
	setState,
	assetQuestion,
	saveDataCaptureState,
	setCurrentState,
	setSubmitDisabled,
	capturePhoto,
) => {
	const prevMessageNoWidget = createChatBotMessage("Please review the data.", {
		withAvatar: true,
	});

	const userMessage = createClientMessage("Submit & Start New", {
		withAvatar: true,
	});

	const res = createChatBotMessage(
		"Your submission has been recorded, thank you.",
		{
			withAvatar: true,
		},
	);

	const message = createChatBotMessage("Please upload a photo of the asset.", {
		widget: "OptionalSelectors",
		withAvatar: true,
		payload: {
			topThreeLocations: [],
			question: assetQuestion,
			capturePhoto,
		},
	});

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message: res, action: "add" },
		{ message, action: "add" },
	]);
	const currentState = {
		options: ["Upload photo"],
		stage: "assetPhoto",
		showOptions: true,
	};
	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			res,
			message,
		],
		...currentState,
	}));
	setSubmitDisabled(true);
};
