export const chartColors = [
	"#92278f",
	"#ef4474",
	"#bf1e2d",
	"#a486be",
	"#642d94",
	"#892a6f",
	"#bd454f",
	"#914888",
];
