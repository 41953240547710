import {
	addLocation,
	getLocation,
	getOrganizationLocations,
	searchLocations,
	updateLocation,
} from "@services/location-service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { SetStateAction } from "react";

export const useGetOrganizationLocations = (organization: any) => {
	return useQuery({
		queryKey: ["locations", organization],
		queryFn: () => getOrganizationLocations(),
		enabled: !!organization,
		staleTime: Number.POSITIVE_INFINITY,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});
};

export const useSearchLocations = (
	val: any,
	org: any,
	pageNum: number,
	pageSize: number,
	sort: any,
	sort_direction: string,
	columnFilters: any,
	refresh: boolean,
	setRefresh: {
		(value: SetStateAction<boolean>): void;
		(arg0: boolean): void;
	},
	setRefreshLoading: {
		(value: SetStateAction<boolean>): void;
		(arg0: boolean): void;
	},
) => {
	const queryClient = useQueryClient();
	const query = useQuery({
		queryKey: [
			"locations",
			val,
			org,
			pageNum,
			pageSize,
			sort,
			sort_direction,
			columnFilters,
		],
		queryFn: () =>
			searchLocations(
				val,
				org,
				pageNum,
				pageSize,
				sort,
				sort_direction,
				columnFilters,
			),
		enabled: !!org && !!pageNum,
		staleTime: Number.POSITIVE_INFINITY,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});
	if (refresh) {
		setRefreshLoading(true);
		setTimeout(() => {
			setRefreshLoading(false);
		}, 1000);
		queryClient.invalidateQueries({
			queryKey: ["locations", val, org, pageNum, pageSize, sort, columnFilters],
		});
		setRefresh(false);
	}
	return query;
};

/**
 * Fetches all the current user's organization forms
 * @param {string} location_id
 * @returns {{locationInfo: Object, isLoading: boolean, error: unknown}}
 */
export const useGetLocation = (id: string | number) => {
	const { data, error, isLoading } = useQuery(["location", id], () =>
		getLocation(id),
	);

	return {
		locationInfo: data,
		error,
		isLoading,
	};
};

export const useUpdateLocation = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ id, is_firebase_id, body }: any) =>
			updateLocation(id, is_firebase_id, body),
		{
			onSuccess: async (data) => {
				queryClient.invalidateQueries(["location", data.external_firebase_id]);
				queryClient.invalidateQueries("locations" as any);
				queryClient.invalidateQueries("searchLocations" as any);
				queryClient.setQueryData(["location", data.external_firebase_id], data);
			},
		},
	);
};

export const useAddLocation = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: any) => addLocation(body), {
		onSuccess: async () => {
			queryClient.invalidateQueries("locations" as any);
			queryClient.invalidateQueries("searchLocations" as any);
		},
	});
};
