export const validateAndSetPage4 = (
	organization,
	responseData,
	allRequiredFieldsPresent,
	setIsPage4Valid,
) => {
	const isValid =
		organization === "SSPAMERICA" || organization === "EcoTrak"
			? !!responseData.manufacturersPlateAssetImage //SSPAMERICA
			: !!allRequiredFieldsPresent; //cvs, applegreen, campingworld, carliecs, foodlion, raisingcanes, razoos, DIGI, swarovski
	// !!allRequiredFieldsPresentAdditionalDetails ||

	setIsPage4Valid(isValid);
};
