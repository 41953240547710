export const addToAnalytics = async (
	organization: any,
	submissionId: null,
	formId: any,
	analyticsDocId: string,
	readValue: any,
	actualValue: {
		manufacturer: any;
		modelNumber: any;
		serialNumber: any;
		refrigerant: any;
		refrigerantCharge: any;
		refrigerantChargeUnitMeasurement: any;
		voltage: any;
		amps: any;
	},
) => {
	const env = import.meta.env.VITE_ENV;
	try {
		const response = await fetch(
			env === "production"
				? "https://us-central1-property-echo-4d747.cloudfunctions.net/addAnalytics"
				: env === "staging"
					? "https://us-central1-property-echo-staging.cloudfunctions.net/addAnalytics"
					: "http://127.0.0.1:5001/property-echo-4d747/us-central1/addAnalytics",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					organization,
					submissionId,
					formId,
					analyticsDocId,
					readValue,
					actualValue,
				}),
			},
		);
		if (response.status !== 200) {
			console.log("Error in analytics");
		}
	} catch (error) {
		console.log("Error in analytics");
		console.log(error);
	}
};
