import {
	useMutation,
	useQueries,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";

import {
	selfOrganizationAssetTypeCategories,
	selfOrganizationAssetTypes,
} from "@/services/organization-service";
import { useLocationStore } from "@/stores";
import { useAdminStore } from "@/stores";
import {
	addAssetType,
	addMasterAssetTypes,
	deleteAsset,
	getAllExtractedInfo,
	getAssetCountByDecommissionedStatus,
	getAssetFromParentRef,
	getAssetsCount,
	getExtractedInfo,
	getLocationAssets,
	getMasterAssetsTypes,
	searchAssets,
} from "@services/asset-service";
import type { DocumentData, QuerySnapshot } from "firebase/firestore";

export const getDates = () => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();
	const currentMonthBeginning = new Date(currentYear, currentMonth, 1);
	const lastMonthBeginning = new Date(currentYear, currentMonth - 1, 1);
	const lastMonthEnding = new Date(currentYear, currentMonth - 1, 31);
	const secondMonthBeginning = new Date(currentYear, currentMonth - 2, 1);
	const secondMonthEnding = new Date(currentYear, currentMonth - 2, 31);
	const thirdMonthBeginning = new Date(currentYear, currentMonth - 3, 1);
	const thirdMonthEnding = new Date(currentYear, currentMonth - 3, 31);
	const fourthMonthBeginning = new Date(currentYear, currentMonth - 4, 1);
	const fourthMonthEnding = new Date(currentYear, currentMonth - 4, 31);
	const fifthMonthBeginning = new Date(currentYear, currentMonth - 5, 1);
	const fifthMonthEnding = new Date(currentYear, currentMonth - 5, 31);
	const sixthMonthBeginning = new Date(currentYear, currentMonth - 6, 1);
	const sixthMonthEnding = new Date(currentYear, currentMonth - 6, 31);

	return {
		currentDate: currentDate.toISOString(),
		currentMonthBeginning: currentMonthBeginning.toISOString(),
		lastMonthBeginning: lastMonthBeginning.toISOString(),
		lastMonthEnding: lastMonthEnding.toISOString(),
		secondMonthBeginning: secondMonthBeginning.toISOString(),
		secondMonthEnding: secondMonthEnding.toISOString(),
		thirdMonthBeginning: thirdMonthBeginning.toISOString(),
		thirdMonthEnding: thirdMonthEnding.toISOString(),
		fourthMonthBeginning: fourthMonthBeginning.toISOString(),
		fourthMonthEnding: fourthMonthEnding.toISOString(),
		fifthMonthBeginning: fifthMonthBeginning.toISOString(),
		fifthMonthEnding: fifthMonthEnding.toISOString(),
		sixthMonthBeginning: sixthMonthBeginning.toISOString(),
		sixthMonthEnding: sixthMonthEnding.toISOString(),
	};
};

/**
 * Returns a query object for retrieving master asset types using useQuery hook.
 *
 * asset_revisit_later
 * Only used by pred-spend, leave for now
 *
 * @return {Object} The query object for retrieving master asset types.
 */
export const useGetMasterAssetsTypes = () =>
	useQuery({
		queryKey: ["masterAssetTypes"],
		queryFn: () => getMasterAssetsTypes(),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * Returns a mutation function that adds master asset types and invalidates
 * the 'masterAssetTypes' query on success.
 *
 * @return {Function} The mutation function.
 */
export const useAddMasterAssetTypes = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: addMasterAssetTypes,
		onSuccess: () => {
			queryClient.invalidateQueries("masterAssetTypes" as any);
		},
	});
};

export const useGetAssetTypeCategories = (organization: any) =>
	useQuery({
		queryKey: ["assetTypeCategories", organization],
		queryFn: () => selfOrganizationAssetTypeCategories(),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * Returns a query object that fetches asset types for a given organization and asset name.
 *
 * @param {string} organization - The name of the organization.
 * @return {object} A query object that fetches asset types.
 */
export const useGetAssetTypes = (organization: string | number) =>
	useQuery({
		queryKey: ["assetTypes", organization],
		queryFn: () => selfOrganizationAssetTypes(),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * A function that uses the provided organization and location ID to query and retrieve the asset count by condition for a specific location.
 *
 * @param {string} organization - The organization for which the asset count is being retrieved.
 * @param {string} locationId - The ID of the location for which the asset count is being retrieved.
 * @return {Promise<Array<number>>} A promise that resolves to an array of asset counts for different conditions.
 */
export const useGetLocationAssetCountByCondition = (
	organization: string,
	locationId: any,
) =>
	useQuery({
		queryKey: ["locationAssetCountByCondition", organization, locationId],
		queryFn: async () => {
			return await Promise.all([
				getAssetsCount(organization, locationId, ["Broken"]),
				getAssetsCount(organization, locationId, ["Poor"]),
				getAssetsCount(organization, locationId, ["Average"]),
				getAssetsCount(organization, locationId, ["Good"]),
				getAssetsCount(organization, locationId, ["Excellent"]),
			]);
		},
		enabled: !!organization && !!locationId,
		refetchOnWindowFocus: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * Retrieves the added asset count by month for a specific location within an organization.
 *
 * @param {string} organization - The organization ID.
 * @param {string} locationId - The location ID.
 * @return {Promise<Array<number>>} A promise that resolves to an array of added asset counts for each month.
 */
export const useGetLocationAddedAssetCountByMonth = (
	organization: string,
	locationId: any,
) => {
	const dates = getDates();
	return useQuery({
		queryKey: ["locationAddedAssetCountByMonth", organization, locationId],
		queryFn: async () => {
			return await Promise.all([
				getAssetsCount(organization, locationId, null, [
					dates.currentMonthBeginning,
					dates.currentDate,
				]),
				getAssetsCount(organization, locationId, null, [
					dates.lastMonthBeginning,
					dates.lastMonthEnding,
				]),
				getAssetsCount(organization, locationId, null, [
					dates.secondMonthBeginning,
					dates.secondMonthEnding,
				]),
				getAssetsCount(organization, locationId, null, [
					dates.thirdMonthBeginning,
					dates.thirdMonthEnding,
				]),
				getAssetsCount(organization, locationId, null, [
					dates.fourthMonthBeginning,
					dates.fourthMonthEnding,
				]),
				getAssetsCount(organization, locationId, null, [
					dates.fifthMonthBeginning,
					dates.fifthMonthEnding,
				]),
				getAssetsCount(organization, locationId, null, [
					dates.sixthMonthBeginning,
					dates.sixthMonthEnding,
				]),
			]);
		},
		enabled: !!organization && !!locationId,
		refetchOnWindowFocus: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

/**
 * Returns the assets for a given organization and location.
 *
 * @param {string} organization - The organization ID.
 * @param {string} locationId - The location ID.
 * @return {Object} The assets for the given organization and location.
 */
export const useGetLocationAssets = (organization: string, locationId: any) =>
	useQuery({
		queryKey: ["locationAssets", organization, locationId],
		queryFn: () => getLocationAssets(organization, locationId),
		enabled: !!organization && !!locationId,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * Returns a mutation function that adds an asset type and invalidates the asset types query in the cache upon success.
 *
 * @return {function} A mutation function.
 */
export const useAddAssetType = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: addAssetType as any,
		onSuccess: () => {
			queryClient.invalidateQueries("assetTypes" as any);
		},
	});
};

/**
 * A hook that searches for assets based on the provided parameters.
 *
 * @param {string} val - The search value.
 * @param {array} list - Currently unused in search function
 * @param {boolean} displayAddress - Whether to display the address.
 * @param {string} org - The organization to filter by.
 * @param {number} pageNum - The page number to retrieve.
 * @param {number} pageSize - The number of assets to fetch per page.
 * @param {string} sort - The sorting criteria.
 * @param {string} location - The location to filter by.
 * @param {string} startDate - The start date to filter by.
 * @param {string} endDate - The end date to filter by.
 * @param {boolean} decommissioned - Whether to include decommissioned assets.
 * @param {boolean} isFocused - Whether the hook is focused.
 * @return {Query} The query result.
 */
export const useSearchAssets = (
	val: any,
	list: any,
	displayAddress: any,
	org: any,
	pageNum: any,
	pageSize: any,
	sortBy: any,
	sortOrder: any,
	location: any,
	startDate: any,
	endDate: any,
	decommissioned: any,
	columnFilters: any,
	refresh: undefined,
	setRefresh: (arg0: boolean) => void,
	setRefreshLoading: (arg0: boolean) => void,
	predSpendFilters: undefined,
) => {
	const queryClient = useQueryClient();
	const query = useQuery({
		queryKey: [
			"searchAssets",
			val,
			list,
			displayAddress,
			org,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			location,
			startDate,
			endDate,
			decommissioned,
			columnFilters,
			predSpendFilters,
		],
		queryFn: () =>
			searchAssets(
				val,
				list,
				displayAddress,
				org,
				pageNum,
				pageSize,
				sortBy,
				sortOrder,
				location,
				startDate,
				endDate,
				decommissioned,
				columnFilters,
				predSpendFilters,
			),
		// enabled: isFocused,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
	if (refresh) {
		setRefreshLoading(true);
		setTimeout(() => {
			setRefreshLoading(false);
		}, 1000);
		queryClient.invalidateQueries({
			queryKey: [
				"searchAssets",
				val,
				list,
				displayAddress,
				org,
				pageNum,
				pageSize,
				sortBy,
				sortOrder,
				location,
				startDate,
				endDate,
				decommissioned,
				columnFilters,
			],
		});
		setRefresh(false);
	}
	return query;
};

/**
 * Retrieves asset data from parent asset references.
 *
 * @param {Array} locAssets - The location assets.
 * @return {Array} Array of parent assets that correspond to the location assets.
 */
export const useGetAssetFromParentRef = (
	locAssets: QuerySnapshot<DocumentData> | undefined,
) => {
	const parentRefs =
		locAssets?.docs?.map((asset: any) => asset.data().parentAssetRef) ?? [];

	const { activeTab: locationActiveTab } = useLocationStore() as any;
	const { activeTab: adminActiveTab } = useAdminStore() as any;
	const isActiveTab =
		locationActiveTab === "Assets" || adminActiveTab === "Assets";
	return useQueries({
		queries: parentRefs?.map((assetId: { id: string }) => {
			return {
				queryKey: ["assetFromParentRef", assetId?.id],
				queryFn: () => getAssetFromParentRef(assetId?.id),
				enabled: !!assetId?.id && isActiveTab,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			};
		}),
	});
};

/**
 * A function that uses useQueries hook to retrieve asset counts for a given organization and location ID.
 * Decommissioned asset count retrieved by count from searchAssets (More reliable for small numbers)
 *
 * @param {string} organization - The organization to retrieve the asset counts for.
 * @param {string} locationId - The location ID to retrieve the asset counts for.
 * @return {object} An object containing the asset counts [active assets, all assets] for the organization and location ID.
 */
export const useGetAssetCounts = (organization: any, locationId: any) =>
	useQueries({
		queries: [
			{
				queryKey: ["assetCount", organization, locationId, false],
				queryFn: () =>
					getAssetCountByDecommissionedStatus(organization, locationId, false),
				enabled: !!organization,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			},
			{
				queryKey: ["assetCount", organization, locationId, null],
				queryFn: () =>
					getAssetCountByDecommissionedStatus(organization, locationId, null),
				enabled: !!organization,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			},
		],
	});

/**
 * Generates a custom hook for deleting an asset.
 *
 * @returns {MutationFunction} The mutation function for deleting an asset.
 */
export const useDeleteAsset = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ assetRef }: any) => await deleteAsset(assetRef),
		onSuccess: () => {
			queryClient.invalidateQueries("assets" as any);
			queryClient.invalidateQueries("searchAssets" as any);
			queryClient.invalidateQueries("doc" as any);
			queryClient.invalidateQueries("assetCount" as any);
		},
	});
};

export const useGetAllExtractedInfo = () => {
	return useQuery({
		queryKey: ["extractedInfo"],
		queryFn: () => getAllExtractedInfo(),
		enabled: true,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

export const useGetExtractedInfo = (model: any) => {
	return useQuery({
		queryKey: ["extractedInfo", model],
		queryFn: () => getExtractedInfo(model),
		enabled: !!model,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};
