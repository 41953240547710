// File: ViewTemplateModal.tsx

import { auth } from "@/assets/services/auth-service";
import { Button, Modal, Spin, Typography, message } from "antd";
import { useEffect, useReducer } from "react";
import { MdBrokenImage } from "react-icons/md";

interface ViewerSettings {
	templateName: string | null;
	isOpen: boolean;
	templateId?: string | null;
}

interface ViewTemplateModalProps {
	viewerSettings: ViewerSettings;
	onClose: () => void;
	onTest?: (templateName: string, templateId: string) => void;
	onDelete?: (templateId: string) => void;
}

interface ImageState {
	image: string | null;
	error: boolean;
}

type ImageAction =
	| { type: "SET_IMAGE"; payload: string | null }
	| { type: "SET_ERROR"; payload: boolean };

const imageReducer = (state: ImageState, action: ImageAction): ImageState => {
	switch (action.type) {
		case "SET_IMAGE":
			return { ...state, image: action.payload, error: false };
		case "SET_ERROR":
			return { ...state, error: action.payload, image: null };
		default:
			return state;
	}
};

export const ViewTemplateModal: React.FC<ViewTemplateModalProps> = ({
	viewerSettings,
	onClose,
	onTest,
	onDelete,
}) => {
	const [imageState, dispatchImage] = useReducer(imageReducer, {
		image: null,
		error: false,
	});

	const getImage = async (templateName: string) => {
		try {
			const token = await auth?.currentUser?.getIdToken();
			const response = await fetch(
				`${import.meta.env.VITE_ALFRED_SERVICE_URL}/FacilityVision/OCR/templates/image/${templateName}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					method: "GET",
				},
			);

			if (response.status !== 200)
				throw new Error("Endpoint returned non-200 status");
			const data = await response.json();
			dispatchImage({ type: "SET_IMAGE", payload: data.url });
		} catch (error) {
			dispatchImage({ type: "SET_ERROR", payload: true });
			console.error("Error fetching image:", error);
		}
	};

	useEffect(() => {
		if (viewerSettings.isOpen && viewerSettings.templateName) {
			getImage(viewerSettings.templateName);
		}

		return () => {
			dispatchImage({ type: "SET_IMAGE", payload: null });
			dispatchImage({ type: "SET_ERROR", payload: false });
		};
	}, [viewerSettings.isOpen, viewerSettings.templateName]);

	const handleTest = () => {
		if (onTest && viewerSettings.templateName && viewerSettings.templateId) {
			onTest(viewerSettings.templateName, viewerSettings.templateId);
		} else {
			message.error("Template information is missing.");
		}
	};

	const handleDelete = () => {
		if (onDelete && viewerSettings.templateId) {
			onDelete(viewerSettings.templateId);
		} else {
			message.error("Template ID is missing.");
		}
	};

	return (
		<Modal
			title="View OCR Template"
			open={viewerSettings.isOpen}
			onCancel={onClose}
			footer={[
				onDelete && (
					<Button key="delete" type="primary" danger onClick={handleDelete}>
						Delete
					</Button>
				),
				onTest && (
					<Button key="test" onClick={handleTest}>
						Test
					</Button>
				),
				<Button key="close" type="primary" onClick={onClose}>
					Close
				</Button>,
			]}
			centered
			width={800}
		>
			<div style={{ textAlign: "center", maxHeight: "60vh", overflow: "auto" }}>
				{imageState.image ? (
					<img
						src={imageState.image}
						alt="Template"
						style={{ width: "100%", height: "auto" }}
						onError={() => dispatchImage({ type: "SET_ERROR", payload: true })}
					/>
				) : imageState.error ? (
					<div
						style={{
							minHeight: "50vh",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<MdBrokenImage style={{ fontSize: "3rem", color: "red" }} />
						<Typography.Text type="danger">
							Failed to load image, please try again later.
						</Typography.Text>
					</div>
				) : (
					<Spin tip="Loading..." />
				)}
			</div>
		</Modal>
	);
};
