import Konva from "konva";
import { useEffect, useRef, useState } from "react";
import { Layer, Rect, Stage } from "react-konva";

const Ratio = ({ responseData, setResponseData }) => {
	const stageRef = useRef(null);
	const [image] = useState(new window.Image());
	const [dimensions, setDimensions] = useState({
		width: 1000,
		height: 1000,
		xShift: 0,
		yShift: 0,
	});

	useEffect(() => {
		if (responseData.alignedImage) {
			image.onload = () => {
				const dims = calculateAspectRatioFit(
					image.width,
					image.height,
					1000,
					1000,
				);
				setDimensions(dims);
				setupStage(image, dims);
				setResponseData((prevData) => ({
					...prevData,
					ratioImage: responseData.alignedImage,
				}));
			};
			image.src = responseData.alignedImage;
		}
	}, [responseData.alignedImage]);

	useEffect(() => {
		if (!responseData.ratioImage) {
			setResponseData((prevData) => ({
				...prevData,
				ratioImage: responseData.alignedImage,
			}));
		}
	}, []);

	const calculateAspectRatioFit = (
		srcWidth,
		srcHeight,
		maxWidth,
		maxHeight,
	) => {
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
		return {
			width: srcWidth * ratio,
			height: srcHeight * ratio,
			xShift: (maxWidth - srcWidth * ratio) / 2,
			yShift: (maxHeight - srcHeight * ratio) / 2,
		};
	};

	const setupStage = (loadedImage, dims) => {
		const stage = stageRef.current.getStage();
		const layer = new Konva.Layer();
		stage.add(layer);

		const konvaImage = new Konva.Image({
			x: dims.xShift,
			y: dims.yShift,
			image: loadedImage,
			width: dims.width,
			height: dims.height,
			draggable: false,
		});
		layer.add(konvaImage);

		const transformer = new Konva.Transformer({
			nodes: [konvaImage],
			rotateEnabled: false,
			enabledAnchors: ["middle-right"],
			keepRatio: true,
		});
		layer.add(transformer);

		transformer.on("transformend", () => {
			applyRatioTransformation(konvaImage);
		});

		layer.draw();
	};

	const applyRatioTransformation = (konvaImage) => {
		const scaleX = konvaImage.scaleX();
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		// Adjust the canvas size based on the image scale
		canvas.width = konvaImage.width() * scaleX;
		canvas.height = konvaImage.height(); // Assuming the height doesn't change; modify if necessary.

		// Ensure the image source is drawn correctly
		const imageElement = konvaImage.image(); // This should be the HTMLImageElement.
		context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);

		// Convert the canvas content to a Blob then to a URL
		canvas.toBlob((blob) => {
			const newImageSrc = URL.createObjectURL(blob);
			setResponseData((prevData) => ({
				...prevData,
				ratioImage: newImageSrc,
			}));
		}, "image/png"); // Specify the image format if needed, default is 'image/png'
	};

	return (
		<Stage
			ref={stageRef}
			width={dimensions.width + dimensions.xShift * 2}
			height={dimensions.height + dimensions.yShift * 2}
		>
			<Layer>
				<Rect
					width={dimensions.width + dimensions.xShift * 2}
					height={dimensions.height + dimensions.yShift * 2}
					fill="rgb(240, 240, 240)"
				/>
			</Layer>
		</Stage>
	);
};

export { Ratio };
