import { useChatbotStore, useFormStore } from "@stores";
import { Rating } from "react-simple-star-rating";
import { FormGroup, Row } from "reactstrap";
const RatingInput = ({ question, responseData, setInputData, fromChatbot }) => {
	const { fieldError: chatbotFieldError, setFieldError: setChatbotFieldError } =
		useChatbotStore();
	const { fieldError: formFieldError, setFieldError: setFormFieldError } =
		useFormStore();
	const fieldError = fromChatbot ? chatbotFieldError : formFieldError;
	const setFieldError = fromChatbot ? setChatbotFieldError : setFormFieldError;

	let textVal = "No Rating";
	let numVal = 0;
	let i = 0;
	for (const option of question.options) {
		i++;
		if (option.split(" ")[0] === responseData[question.key]) {
			textVal = option;
			numVal = i;
			break;
		}
	}

	const updateRating = (num) => {
		if (num % 20 === 0) num = num / 20;
		const val = question.options[num - 1].split(" ")[0];
		setInputData(question, val);

		// Check if the rating is required and not set, then set an error
		if (question.required === "true" && !responseData[question.key]) {
			setFieldError(question.key, `${question.label} rating is required`);
		} else {
			setFieldError(question.key, "");
		}
	};

	return (
		<Row key={question.key}>
			<FormGroup>
				<div className="d-flex flex-column align-items-center">
					<div className="d-flex align-items-center me-3">
						<span className="me-2" style={{ color: "red" }}>
							*
						</span>
						<Rating
							initialValue={numVal}
							size={55}
							onClick={updateRating}
							allowHalfIcon={false}
						/>
					</div>
					<div className="fst-italic">{textVal}</div>
				</div>
				{fieldError[question.key] && (
					<div className="error-message" style={{ textAlign: "center" }}>
						{fieldError[question.key]}
					</div>
				)}
			</FormGroup>
		</Row>
	);
};

export { RatingInput };
