import { useFormStore, useUserStore } from "@stores";
import { useRef, useState } from "react";
import { GrCheckmark } from "react-icons/gr";
import { PiImageSquareDuotone } from "react-icons/pi";
import { FormGroup, Input, Row } from "reactstrap";
import { InfoTooltip } from "./InfoTooltip";

const ImageInput = ({ question, responseData, capturePhoto }) => {
	const {
		setSelectedPhotoUrl,
		setIsPhotoShowing,
		setSelectedItem,
		setRefToOpen,
	} = useFormStore();

	const { user } = useUserStore();

	const useCameraOnPhotoCapture = user.useCameraOnPhotoCapture;

	const fileInputRef = useRef(null);
	const [overlayClass, setOverlayClass] = useState(
		"form-image-button-overlay invisible",
	);
	const previewPhoto = () => {
		if (!responseData[question.key]) return;
		setRefToOpen(fileInputRef);
		setSelectedPhotoUrl(responseData[question.key]);
		setIsPhotoShowing(true);
		setSelectedItem(question);
	};

	return (
		<Row key={question.key} className="d-flex flex-column align-items-center">
			{question.tooltip ? (
				<InfoTooltip message={question.tooltip} classes="image-input-tooltip" />
			) : null}
			<FormGroup>
				<div
					hidden={responseData[question.key]}
					className="d-flex justify-content-center"
				>
					<div
						className="form-image-button-container"
						onMouseEnter={() => setOverlayClass("light")}
						onTouchStart={() => setOverlayClass("light")}
						onMouseLeave={() => setOverlayClass("")}
						onTouchEnd={() => setOverlayClass("")}
						onClick={previewPhoto}
					>
						<div className="form-image-button">
							<PiImageSquareDuotone className="form-image-button-icon" />
							<div className="mb-2">
								{!responseData[question.key] ? (
									<div className="fw-bold">
										{question.hideLabel ? "Add Photo" : `Add ${question.label}`}
									</div>
								) : (
									<div className="d-flex flex-row align-items-center">
										<GrCheckmark className="me-2 fw-bold" />
										<div className="fw-bold">Preview Photo</div>
									</div>
								)}
							</div>
						</div>
						<div className={`form-image-button-overlay ${overlayClass}`} />
						{responseData[question.key] ? (
							<img
								className="form-image-button-image"
								src={responseData[question.key]}
							/>
						) : null}
						<Input
							ref={fileInputRef}
							hidden={responseData[question.key]}
							className="form-image-button-input"
							capture={useCameraOnPhotoCapture ? "environment" : undefined}
							id={question.key}
							type="file"
							onClick={(e) => {
								if (responseData[question.key]) {
									e.preventDefault();
									previewPhoto();
								}
							}}
							onChange={async (e) => {
								try {
									await capturePhoto(e, question, responseData);
								} catch {
									e.target.value = null;
								}
							}}
						/>
					</div>
				</div>
			</FormGroup>
		</Row>
	);
};

export { ImageInput };
