import { useFormStore } from "@/stores/formStore";
import { preventSpecialChars } from "@utils/preventSpecialChars";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { manufacturerValid } from "../childFunctions/manufacturerValid";

const IfOtherManufacturerInput = ({
	question,
	responseData,
	setInputData,
	isManufacturerNotAvailable,
}) => {
	const { setAlertModal } = useFormStore();

	const handleChange = (e) => {
		let otherManufacturer = e.target.value.toUpperCase();
		if (!manufacturerValid(otherManufacturer)) {
			setAlertModal({
				title: "Warning",
				body: "You may be entering a certification name instead of a valid manufacturer. Please double check your input before continuing.",
				show: true,
			});

			setTimeout(() => {
				setAlertModal({
					title: "",
					body: "",
					show: false,
				});
			}, 4000);
		}

		if (isManufacturerNotAvailable) {
			otherManufacturer = "";
		}

		setInputData(question, otherManufacturer);
	};

	const handlePaste = (e) =>
		preventSpecialChars(e.clipboardData.getData("text"), e);

	const handleKeyPress = (e) => preventSpecialChars(e.key, e);

	return (
		<Row key={question.key}>
			<FormGroup
				hidden={
					responseData[question.tiedTo] !== "(other)" ||
					responseData[`${question.tiedTo}NotAvailable`]
				}
			>
				<Label className="single-form-label">
					{question.label}
					{question.required === "true" ? (
						<span style={{ color: "red" }}> *</span>
					) : (
						""
					)}
				</Label>
				<br />

				<Input
					key={question.key}
					type={question.type}
					onPaste={handlePaste}
					onKeyDown={handleKeyPress}
					onChange={handleChange}
					onKeyPress={(e) => preventSpecialChars(e.key, e)}
					className={question.type !== "checkbox" ? "form-inputs" : ""}
					value={responseData[question.key]}
					placeholder={question.placeholder}
				/>
			</FormGroup>
		</Row>
	);
};

export { IfOtherManufacturerInput };
