import { addToAnalytics } from "@/services/analytics-service";
import { createNewDraft } from "@/services/draft-service";
import { assetTypeCompareUserToAI } from "@services/alfred-service";
import { addNewAssetToMapFromForm } from "@services/asset-map-service";
import {
	insertAssetFromForm,
	updateAssetFromForm,
} from "@services/asset-service";
import { useFormStore } from "@stores";
import { formValid } from "./formValidation";

export const submit = async (
	enterNew = false,
	setAlertModal,
	closeAlertModal,
	responseData,
	submitDisabled,
	setSubmitDisabled,
	setIsLoading,
	arePagesValid,
	setCurrentPage,
	currentPage,
	inputs,
	setSubmitAttempted,
	setFieldError,
	organization,
	formId,
	dupWarningShown,
	setDupWarningShown,
	assetTagError,
	isEditing,
	assetMapNewAsset,
	flags,
	isQA,
	draftId,
	location,
	authInfo,
	originalPath,
	submissionId,
	savedLocation,
	formName,
	userLocation,
	conditionRead,
	ocrData,
	assetMapDimensions,
	chosenAssetType,
	deleteSubmission,
	updateSubmission,
	addSubmission,
	updateWithRef,
	draftIdState,
	deleteDraft,
	setActiveTab,
	navigate,
	clearState,
	fromChatbot = false,
) => {
	const {
		isPage1Valid,
		isPage2Valid,
		isPage3Valid,
		isPage4Valid,
		isPage5Valid,
		isPage6Valid,
		isPage7Valid,
		isPage8Valid,
		isPage9Valid,
	} = arePagesValid;

	if (!navigator.onLine) {
		setAlertModal({
			show: true,
			title: "Connection Lost!",
			body: "Please check your internet settings",
		});
		setTimeout(closeAlertModal, 2500);

		setSubmitDisabled(false); // Reset submitDisabled
		return { status: "failed" };
	}

	if (submitDisabled) return { status: "failed" };
	else {
		setSubmitDisabled(true);
	}
	setIsLoading(true);

	// Redirect to first invalid page
	const validationFunctions = [
		isPage1Valid,
		isPage2Valid,
		isPage3Valid,
		isPage4Valid,
		isPage5Valid,
		isPage6Valid,
		isPage7Valid,
		isPage8Valid,
		isPage9Valid,
	];
	let firstInvalidPageIndex = -1;

	for (let i = 0; i < validationFunctions.length; i++) {
		if (!validationFunctions[i]) {
			firstInvalidPageIndex = i;
			break;
		}
	}

	if (firstInvalidPageIndex !== -1) {
		setCurrentPage(firstInvalidPageIndex);
	}

	if (currentPage === inputs.length - 1) {
		setSubmitAttempted(true);

		const validateField = (field) => {
			const {
				name,
				value,
				labelName,
				additionalCondition = true,
				notAvailableField,
			} = field;

			if (
				(!value || (typeof value === "string" && value.trim() === "")) &&
				additionalCondition &&
				!responseData[notAvailableField]
			) {
				setFieldError(name, `${labelName} is required`);
			} else {
				setFieldError(name, "");
			}
		};

		const fieldsToValidate = [
			{
				name: "manufacturer",
				labelName: "Manufacturer",
				value: responseData.manufacturer,
				notAvailableField: "manufacturerNotAvailable",
			},
			{
				name: "modelNumber",
				labelName: "Model Number",
				value: responseData.modelNumber,
				notAvailableField: "modelNotAvailable",
			},
			{
				name: "serialNumber",
				labelName: "Serial Number",
				value: responseData.serialNumber,
				notAvailableField: "serialNotAvailable",
			},
			{
				name: "assetLocation",
				labelName: "Asset Location",
				value: responseData.assetLocation,
			},
			{
				name: "assetType",
				labelName: "Asset Type",
				value: responseData.assetType,
			},
			{
				name: "assetClass",
				labelName: "Asset Class",
				value: responseData.assetClass,
			},
			{
				name: "assetNumber",
				labelName: "Asset Number",
				value: responseData.assetNumber,
			},
			{
				name: "assetRank",
				labelName: "Asset Rank",
				value: responseData.assetRank,
			},
			{
				name: "inServiceDate",
				labelName: "In-service Date",
				value: responseData.inServiceDate,
			},
			{
				name: "propertyId",
				labelName: "Property ID",
				value: responseData.propertyId,
			},
			{
				name: "assetDescription",
				labelName: "Asset Description",
				value: responseData.assetDescription,
			},
			{
				name: "assetComment",
				labelName: "Asset Comment",
				value: responseData.assetComment,
			},
			{
				name: "assetCondition",
				labelName: "Asset Condition",
				value: responseData.assetCondition,
			},
			{
				name: "manufacturerDate",
				labelName: "Manufacturer Date",
				value: responseData.manufacturerDate,
				notAvailableField: "manufacturerDateNotFound",
			},
			{
				name: "numDoors",
				labelName: "Number of Doors",
				value: responseData.numDoors,
			},
			{
				name: "refrigerantCharge",
				labelName: "Refrigerant Charge",
				value: responseData.refrigerantCharge,
				notAvailableField: "refrigerantChargeNotAvailable",
			},
			{
				name: "refrigerant",
				labelName: "Refrigerant",
				value: responseData.refrigerant,
				notAvailableField: "refrigerantNotAvailable",
			},
			{
				name: "voltage",
				labelName: "Voltage",
				value: responseData.voltage,
				notAvailableField: "voltageNotAvailable",
			},
			{
				name: "amps",
				labelName: "Amps",
				value: responseData.amps,
				notAvailableField: "ampsNotAvailable",
			},
			{
				name: "assetTag",
				labelName: "Asset Tag",
				value: responseData.assetTag,
				notAvailableField: "assetTagNotAvailable",
			},
			{
				name: "tempAlertId",
				labelName: "Temp Alert ID",
				value: responseData.tempAlertId,
				notAvailableField: "tempAlertIdNotAvailable",
			},
			{
				name: "numMirrors",
				labelName: "Number of Mirrors",
				value: responseData.numMirrors,
				notAvailableField: "numMirrorsNotAvailable",
			},

			{
				name: "warrantyExpirationDate",
				labelName: "Warranty Expiration Date",
				value: responseData.warrantyExpirationDate,
				notAvailableField: "warrantyNotFound",
			},
			{
				name: "warrantyProvider",
				labelName: "Warranty Provider",
				value: responseData.warrantyProvider,
				notAvailableField: "warrantyNotFound",
			},
			{
				name: "warrantyPeriod",
				labelName: "Warranty Period",
				value: responseData.warrantyPeriod,
				notAvailableField: "warrantyNotFound",
			},
			{
				name: "warrantyStartDate",
				labelName: "Warranty Start Date",
				value: responseData.warrantyStartDate,
				notAvailableField: "warrantyNotFound",
			},
			{
				name: "coilCleaningIncluded",
				labelName: "Coil Cleaning Included",
				value: responseData.coilCleaningIncluded,
				notAvailableField: "coilCleaningIncludedNotAvailable",
			},
			{
				name: "filterSize",
				labelName: "Filter Size",
				value: responseData.filterSize,
				notAvailableField: "filterSizeNotAvailable",
			},
			{
				name: "refTypeSwarovski",
				labelName: "Refrigeration Type",
				value: responseData.refTypeSwarovski,
				notAvailableField: "refTypeSwarovskiNotAvailable",
			},
			{
				name: "rtuSplitSystem",
				labelName: "RTU Split System",
				value: responseData.rtuSplitSystem,
				notAvailableField: "rtuSplitSystemNotAvailable",
			},
			{
				name: "hasCircuits",
				labelName: "Has Circuits",
				value: responseData.hasCircuits,
				notAvailableField: "hasCircuitsNotAvailable",
			},
			{
				name: "hasComponents",
				labelName: "Has Components",
				value: responseData.hasComponents,
				notAvailableField: "hasComponentsNotAvailable",
			},
			{
				name: "energyEfficiency",
				labelName: "Energy Efficiency",
				value: responseData.energyEfficiency,
				notAvailableField: "energyEfficiencyNotAvailable",
			},
			{
				name: "Life Expectancy",
				labelName: "Life Expectancy",
				value: responseData.lifeExpectancy,
				notAvailableField: "lifeExpectancyNotAvailable",
			},
			{
				name: "purchaseDate",
				labelName: "Purchase Date",
				value: responseData.purchaseDate,
				notAvailableField: "purchaseDateNotAvailable",
			},

			organization !== "applegreen"
				? {
						name: "installDate",
						labelName: "Install Date",
						value: responseData.installDate,
						notAvailableField: "installDateNotAvailable",
					}
				: "",
			{
				name: "originalValue",
				labelName: "Original Value",
				value: responseData.originalValue,
				notAvailableField: "originalValueNotAvailable",
			},
			{
				name: "currentValueEstimate",
				labelName: "Current Value Estimate",
				value: responseData.currentValueEstimate,
				notAvailableField: "currentValueEstimateNotAvailable",
			},
			{
				name: "workOrderArea",
				labelName: "Work Order Area",
				value: responseData.workOrderArea,
				notAvailableField: "workOrderAreaNotAvailable",
			},
			{
				name: "unitType",
				labelName: "Unit Type",
				value: responseData.unitType,
				notAvailableField: "unitTypeNotAvailable",
			},

			{
				name: "numShelves",
				labelName: "Number of Shelves",
				value: responseData.numShelves,
				notAvailableField: "numShelvesNotAvailable",
			},
			{
				name: "numPrisms",
				labelName: "Number of Prisms",
				value: responseData.numPrisms,
				notAvailableField: "numPrismsNotAvailable",
			},
			{
				name: "numBoxes",
				labelName: "Number of Boxes",
				value: responseData.numBoxes,
				notAvailableField: "numBoxesNotAvailable",
			},
			{
				name: "numBoxesLenses",
				labelName: "Number of Boxes Lenses",
				value: responseData.numBoxesLenses,
				notAvailableField: "numBoxesLensesNotAvailable",
			},
			{
				name: "numTiers",
				labelName: "Number of Tiers",
				value: responseData.numTiers,
				notAvailableField: "numTiersNotAvailable",
			},
			{
				name: "lightingType",
				labelName: "Lighting Type",
				value: responseData.lightingType,
				notAvailableField: "lightingTypeNotAvailable",
			},
			{
				name: "numLights",
				labelName: "Number of Lights",
				value: responseData.numLights,
				notAvailableField: "numLightsNotAvailable",
			},
			{
				name: "numDrawers",
				labelName: "Number of Drawers",
				value: responseData.numDrawers,
				notAvailableField: "numDrawersNotAvailable",
			},
			{
				name: "resolution",
				labelName: "Resolution",
				value: responseData.resolution,
				notAvailableField: "resolutionNotAvailable",
			},
			{
				name: "Monitoring System",
				labelName: "Monitoring System",
				value: responseData.monitoringSystem,
				notAvailableField: "monitoringSystemNotAvailable",
			},
			{
				name: "wattage",
				labelName: "Wattage",
				value: responseData.wattage,
				notAvailableField: "wattageNotAvailable",
			},
			{
				name: "numSpeakers",
				labelName: "Number of Speakers",
				value: responseData.numSpeakers,
				notAvailableField: "numSpeakersNotAvailable",
			},
			{
				name: "length",
				labelName: "Length",
				value: responseData.length,
				notAvailableField: "lengthNotAvailable",
			},
			{
				name: "lockingMechanism",
				labelName: "Locking Mechanism",
				value: responseData.lockingMechanism,
				notAvailableField: "lockingMechanismNotAvailable",
			},
			{
				name: "activationType",
				labelName: "Activation Type",
				value: responseData.activationType,
				notAvailableField: "activationTypeNotAvailable",
			},
			{
				name: "loadCapacity",
				labelName: "Load Capacity",
				value: responseData.loadCapacity,
				notAvailableField: "loadCapacityNotAvailable",
			},
			{
				name: "numTubes",
				labelName: "Number of Tubes",
				value: responseData.numTubes,
				notAvailableField: "numTubesNotAvailable",
			},
			{
				name: "screenSize",
				labelName: "Screen Size",
				value: responseData.screenSize,
				notAvailableField: "screenSizeNotAvailable",
			},
			{
				name: "numLightsControlled",
				labelName: "Number of Lights Controlled",
				value: responseData.numLightsControlled,
				notAvailableField: "numLightsControlledNotAvailable",
			},
			// {
			// 	name: 'bayQuantity',
			// 	labelName: 'Bay Quantity',
			// 	value: responseData.bayQuantity,
			// 	// notAvailableField: 'bayQuantityNotAvailable',
			// },
			{
				name: "bayLocation",
				labelName: "Bay Location",
				value: responseData.bayLocation,
				notAvailableField: "bayLocationNotAvailable",
			},

			{
				name: "caseLinearFeet",
				labelName: "Case Linear Feet",
				value: responseData.caseLinearFeet,
				notAvailableField: "caseLinearFeetNotAvailable",
			},
			{
				name: "powerSource",
				labelName: "Power Source",
				value: responseData.powerSource,
				notAvailableField: "powerSourceNotAvailable",
			},
			{
				name: "rackId",
				labelName: "Rack ID",
				value: responseData.rackId,
				notAvailableField: "rackIdNotAvailable",
			},
			{
				name: "refUsageType",
				labelName: "Refrigeration Usage Type",
				value: responseData.refUsageType,
				notAvailableField: "refUsageTypeNotAvailable",
			},

			{
				name: "totalCharge",
				labelName: "Total Charge",
				value: responseData.totalCharge,
				notAvailableField: "totalChargeNotAvailable",
			},
			{
				name: "leakDetector",
				labelName: "Leak Detector",
				value: responseData.leakDetector,
				notAvailableField: "leakDetectorNotAvailable",
			},
			{
				name: "caseLinearFeet",
				labelName: "Case Linear Feet",
				value: responseData.caseLinearFeet,
				notAvailableField: "caseLinearFeetNotAvailable",
			},

			{
				name: "capacity",
				labelName: "Capacity",
				value: responseData.capacity,
				notAvailableField: "capacityNotAvailable",
			},
			{
				name: "lastDueDate",
				labelName: "Last Due Date",
				value: responseData.lastDueDate,
				notAvailableField: "lastDueDateNotAvailable",
			},

			{
				name: "nextDueDate",
				labelName: "Next Due Date",
				value: responseData.nextDueDate,
				notAvailableField: "nextDueDateNotAvailable",
			},
			{
				name: "assetTypeCategory",
				labelName: "Asset Type Category",
				value: responseData.assetTypeCategory,
			},
			{
				name: "qsrStore",
				labelName: "Store Name",
				value: responseData.qsrStore,
			},
		].filter(Boolean);

		fieldsToValidate.forEach(validateField);

		if (!responseData.assetCondition) {
			setFieldError("assetCondition", "Asset Condition is required");
		} else {
			setFieldError("assetCondition", "");
		}

		if (
			!responseData.assetSubArea ||
			(typeof responseData.assetSubArea == "string" &&
				responseData.assetSubArea.trim() === "")
		) {
			setFieldError("assetSubArea", "Asset Sub Area location is required");
		} else {
			setFieldError("assetSubArea", "");
		}

		if (
			!responseData.bayLocation ||
			(typeof responseData.bayLocation == "string" &&
				responseData.bayLocation.trim() === "")
		) {
			setFieldError("bayLocation", "Bay Location is required");
		} else {
			setFieldError("bayLocation", "");
		}
	}

	const isValid = await formValid(
		setAlertModal,
		setSubmitDisabled,
		inputs,
		responseData,
		organization,
		formId,
		dupWarningShown,
		setDupWarningShown,
		assetTagError,
		isEditing,
		assetMapNewAsset,
		flags,
		isQA,
		draftId,
	);
	if (!isValid) {
		setIsLoading(false);
		setSubmitDisabled(false); // Reset submitDisabled

		return { status: "failed" };
	}

	// Generate new response obj & update fields
	const data = responseData;
	const locationId = savedLocation.id;
	data.locationId = locationId;
	data.formId = formId;
	data.deleted = false;
	data.audited = false;
	data.assetType = chosenAssetType?.ref || chosenAssetType;

	// Delete sub_areas from assetLocation to keep assetLocation object clean
	if (data.assetLocation?.sub_areas) {
		delete data.assetLocation.sub_areas;
	}

	if (userLocation.latitude && userLocation.longitude)
		data.userLocation = userLocation;

	// Add to qa-submissions if 'ifOther' is filled out
	// Otherwise, add to form responses & assets (if applicable)

	if (isEditing) {
		try {
			await updateAssetFromForm(data, submissionId, organization);
		} catch (error) {
			console.error("Error updating asset:", error);
			setIsLoading(false);
			setSubmitDisabled(false); // Reset submitDisabled
			setAlertModal({
				show: true,
				title: "Error During Submission",
				body: error.message,
			});
			return { status: "failed" };
		}
	} else {
		try {
			// If no draft has been created yet, make a draft
			if (!draftId) {
				let createdDraftId = await createNewDraft(
					formId,
					userLocation,
					savedLocation,
					responseData,
				);
				await insertAssetFromForm(data, createdDraftId, organization);
			} else {
				await insertAssetFromForm(data, draftId, organization);
			}
		} catch (error) {
			console.error("Error inserting asset:", error);
			setIsLoading(false);
			setSubmitDisabled(false); // Reset submitDisabled
			setAlertModal({
				show: true,
				title: "Error During Submission",
				body: error.message,
			});
			return { status: "failed" };
		}

		// Analytics
		// Asset condition
		addToAnalytics(
			organization,
			null,
			formId,
			"asset-condition",
			conditionRead,
			responseData["assetCondition"] ? responseData["assetCondition"] : null,
		);
		// OCR
		if (flags.config.allowOcr) {
			addToAnalytics(organization, null, formId, "ocr", ocrData, {
				manufacturer: responseData["manufacturer"] || null,
				modelNumber: responseData["modelNumber"] || null,
				serialNumber: responseData["serialNumber"] || null,
				refrigerant: responseData["refrigerant"] || null,
				refrigerantCharge: responseData["refrigerantCharge"] || null,
				refrigerantChargeUnitMeasurement:
					responseData["refrigerantChargeUnitMeasurement"] || null,
				voltage: responseData["voltage"] || null,
				amps: responseData["amps"] || null,
			});
		}
	}

	// Add asset to map if applicable
	if (
		flags.config.allowAssetMapping &&
		!isEditing &&
		responseData?.assetLocation === 2 // Pharmacy id
	) {
		await addNewAssetToMapFromForm(
			savedLocation.id,
			organization,
			responseData.assetTag ? responseData.assetTag : null,
			assetMapNewAsset,
			assetMapDimensions.width,
			assetMapDimensions.height,
			responseData["assetType"],
			isEditing,
		);
		useFormStore.setState({ assetMapNewAsset: null });
	}

	// Asset Type Shadow mode (don't need to await for the request to complete)
	assetTypeCompareUserToAI(
		responseData?.equipmentAssetImage,
		responseData?.assetTag,
		organization,
		responseData?.assetType,
	);

	const newSubmissionState = {
		data: data.location,
		id: locationId,
		tab: "Submissions",
	};

	// Redirect if applicable
	if (isEditing) {
		setIsLoading(false);
		// Navigate back to submission being edited
		navigate(originalPath, {
			state: {
				data: data.location,
				id: locationId,
				tab: "Submissions",
				submissionId: submissionId,
				formId: formId,
			},
		});
	} else if (!enterNew) {
		setIsLoading(false);

		!fromChatbot && setActiveTab("Submissions");

		!fromChatbot &&
			navigate("/locations/" + locationId + "/submissions", {
				state: newSubmissionState,
			});
	}

	setSubmitDisabled(false);
	setIsLoading(false);
	clearState();

	return {
		submitState: newSubmissionState,
		enterNew,
		status: "success",
	};
};
