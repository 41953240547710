import { db } from "@assets/services/auth-service";
import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
	pdf,
} from "@react-pdf/renderer";
import type { SourceObject } from "@react-pdf/types";
import domtoimage from "dom-to-image";
import { type DocumentReference, doc, updateDoc } from "firebase/firestore";
import type { MutableRefObject, SetStateAction } from "react";
import type {
	JSXElementConstructor,
	ReactElement,
	ReactNode,
	ReactPortal,
} from "react";
import logoUrl from "../assets/img/Property_Eco_Logo-03.png";
import { getAssetLocations } from "./asset-service";
import { getLocationOld } from "./location-service";

export const savePositions = async (
	newPositions: any[],
	locationId: string,
	org: string,
) => {
	const locationRef = doc(db, "organizations", org, "locations", locationId);
	try {
		await updateDoc(locationRef, {
			assetMapPositions: newPositions,
		});
	} catch (error) {
		console.log("Error saving positions", error);
	}
};

export const getPositions = async (locationId: string, org: string) => {
	//revisit_later
	const locationSnap = await getLocationOld(locationId, org);
	if (locationSnap.exists()) {
		return locationSnap.data().assetMapPositions || null;
	}
	return null;
};

export const getAssetData = async (locationId: any) => {
	try {
		const assetsSnap = await getAssetLocations(locationId);

		// Filter assets based on the location and map to original assets using the map
		const pharmacyAssets = assetsSnap
			.map((a_loc: { id: any }) => [a_loc.id, a_loc])
			.filter(
				(a_loc: { asset: { location_in_facility: string } }[]) =>
					a_loc[1].asset === null ||
					a_loc[1]?.asset?.location_in_facility === "PHARMACY",
			);

		return pharmacyAssets;
	} catch (error) {
		console.log("Error getting asset data", error);
	}
};

export const addNewAssetToMapFromForm = async (
	locationId: string,
	org: string,
	assetId: any,
	assetMapNewAsset: { x: number; y: number },
	canvasWidth: number,
	canvasHeight: number,
	chosenAssetType: any,
	isEditing: any,
) => {
	try {
		const locationRef = doc(db, "organizations", org, "locations", locationId);
		//revisit_later
		const locationSnap = await getLocationOld(org, locationId);
		let assetMapPositions = locationSnap?.data()?.assetMapPositions || [];

		const newAsset = {
			tagId: assetId,
			x: (assetMapNewAsset.x / canvasWidth) * 100,
			y: (assetMapNewAsset.y / canvasHeight) * 100,
			width: 30,
			height: 30,
			isDragging: false,
			type: chosenAssetType,
		};
		if (isEditing) {
			assetMapPositions = assetMapPositions.map((asset: { tagId: any }) =>
				asset.tagId === assetId ? newAsset : asset,
			);
		} else if (!isEditing) {
			assetMapPositions.push(newAsset);
		}

		await updateDoc(locationRef, {
			assetMapPositions,
		});
	} catch (error) {
		console.log("Error adding new asset", error);
	}
};

export const deleteAssetOffAssetMap = async (
	docRef: DocumentReference<unknown>,
) => {
	// try {
	// 	const docData: any = await getDoc(docRef).then((docSnap) => docSnap.data());
	// 	const org = docData.organization || docData.other?.organization;
	// 	if (org !== "cvs.com") return;
	// 	const assetTag = docData.assetTag || docData.other?.assetTag;
	// 	const locationId = docData.location?.id || docData.other?.location.id;
	// 	const locationDocRef = doc(
	// 		db,
	// 		"organizations",
	// 		org,
	// 		"locations",
	// 		locationId,
	// 	);
	// 	const locationDocSnap = await getDoc(locationDocRef);
	// 	if (locationDocSnap.exists()) {
	// 		const assetMapArr = locationDocSnap.data().assetMapPositions;
	// 		const newAssetMapArrFiltered = assetMapArr.filter(
	// 			(asset: { tagId: any }) => asset.tagId !== assetTag,
	// 		);
	// 		await updateDoc(locationDocRef, {
	// 			assetMapPositions: newAssetMapArrFiltered,
	// 		});
	// 	}
	// } catch (error) {
	// 	console.log("Error deleting asset off asset map", error);
	// }
	console.warn("deleteAssetOffAssetMap is deprecated.");
};

export const downloadAssetMapPDF = async (
	stageRef: MutableRefObject<any>,
	setPdfLoading: {
		(value: SetStateAction<boolean>): void;
		(value: SetStateAction<boolean>): void;
		(arg0: boolean): void;
	},
	address: any,
) => {
	domtoimage
		.toPng(stageRef?.current?.getStage().content) // Access the Konva stage and its content
		.then(async (result) => {
			setPdfLoading(true);
			// generate PDF blob
			const blob = await generateAssetMapPDF(result, address);
			// Third, download the PDF
			const tempLink = document.createElement("a");
			tempLink.href = URL.createObjectURL(blob);
			tempLink.setAttribute("download", `Asset Map - ${address}.pdf`);
			tempLink.click();
			setPdfLoading(false);
		})
		.catch((error) => {
			console.error("Error exporting as image:", error);
		});
};

const generateAssetMapPDF = async (
	result: SourceObject,
	address:
		| string
		| number
		| boolean
		| ReactElement<any, string | JSXElementConstructor<any>>
		| Iterable<ReactNode>
		| ReactPortal
		| null
		| undefined,
) => {
	const styles = StyleSheet.create({
		doc: {
			fontFamily: "Helvetica",
			flexDirection: "column",
			padding: "24px",
			fontSize: "12px",
		},
		assetMap: {
			border: "1px solid black",
			width: "90%",
			objectFit: "fill",
			height: "380px",
			margin: "0 auto",
		},
		title: {
			fontSize: "24px",
			textAlign: "center",
			marginBottom: "16px",
			fontWeight: "bold",
		},
		subtitle: {
			fontSize: "12px",
			textAlign: "center",
			marginBottom: "18px",
			color: "gray",
		},
		logo: {
			position: "absolute",
			left: 10,
			bottom: 0,
			width: 150,
		},
	});
	const document = (
		<Document>
			<Page size="A4" orientation="landscape" style={styles.doc}>
				<View>
					<Text style={styles.title}>Asset Map</Text>
					<Text style={styles.subtitle}>{address}</Text>
					<Image src={result} style={styles.assetMap}></Image>
				</View>
				<Image src={logoUrl} style={styles.logo}></Image>
			</Page>
		</Document>
	);

	const blob = await pdf(document).toBlob();

	return blob;
};
