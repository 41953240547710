import { LocationPicker } from "@/components/layouts/SignedIn/views/SingleForm/LocationPicker";
import { RCImageOptions } from "@/components/layouts/SignedIn/views/SingleForm/RCImageOptions";
import { inputs as initInputs } from "@/constants/formInputs/raisingCanesInputs";
import { getFormData } from "@services/form-service";
import { getLocationsArr } from "@services/location-service";
import { useFormStore, useUserStore } from "@stores";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
} from "reactstrap";
import { v4 as uuid } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDraftsByUser } from "@/hooks/draftQueries";
import { createDraft, saveProgress } from "@/services/draft-service";
import { debounce } from "@/utils/debounce";
import { DraftSelectionModal } from "@/views/SignedIn/SingleForm/DraftSelectionModal";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { LoadingModal } from "@components/layouts/SignedIn/LoadingModal/LoadingModal";
import { CropperModal } from "@components/layouts/SignedIn/views/SingleForm/CropperModal";
import { SubmittedImageModal } from "@components/layouts/SignedIn/views/SingleForm/SubmittedImageModal";
import {
	useAddCushmanSubmission,
	useUpdateCushmanSubmission,
} from "@hooks/submissionQueries";
import { uploadFieldCaptureFromDataUrl } from "@services/storage-service";
import { deepCopy } from "@utils/deepCopy";
import { AiOutlineCalendar } from "react-icons/ai";
// import savedLocation from "chartjs-plugin-dragdata/src";

const SingleFormRC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, userOrganization } = useUserStore();
	const { id } = useParams();
	const formId = location.state?.formId || id || null;
	const organization_id = user.organization.external_firebase_id;
	const {
		setShowDraftSelectionModal,
		setStartNew,
		startNew,
		isEditing,
		setIsEditing,
		setSavedLocation,
		savedLocation,
		isEditingRaisingCanes,
	} = useFormStore();
	const addRaisingCanesSubmission = useAddCushmanSubmission();
	const updateRaisingCanesSubmission = useUpdateCushmanSubmission();
	const isMobile = window.innerWidth < 768;
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [inputs, setInputs] = useState(() => {
		if (location.state?.inputs) {
			return JSON.parse(location.state.inputs);
		}
		return deepCopy(initInputs);
	});

	const [locations, setLocations] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentSection, setCurrentSection] = useState(0);
	const [submitDisabled, setSubmitDisabled] = useState(false);

	const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
	const [isPhotoShowing, setIsPhotoShowing] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isCropShowing, setIsCropShowing] = useState(false);
	const [photoToBeCropped, setPhotoToBeCropped] = useState(null);

	const [userLocation, setUserLocation] = useState(
		location.state?.userLocation || {},
	);

	const [shouldAutoSave, setShouldAutoSave] = useState();
	const { draftId, setDraftId } = useFormStore();

	const submissionId = location.state?.submissionId;
	const originalPath = location.state?.path;

	const [_alertModal, setAlertModal] = useState({
		show: false,
		title: "",
		body: "",
	});

	// Closes any alert modal
	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	const [isLoading, setIsLoading] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	const [todaysDrafts, setTodaysDrafts] = useState([]);

	const { data: drafts } = useGetDraftsByUser(null, formId);

	const fromDraftsTable = location.state?.fromDraftsTable || false;

	const addPhotoToInputs = (
		e,
		pageIndex,
		sectionIndex,
		questionIndex,
		_key,
	) => {
		// Stop function if no files or individual file
		if (!e.target.files) {
			setShowLoader(false);
			return;
		}
		if (e.target.files.length === 0) {
			setShowLoader(false);
			return;
		}
		const file = e.target.files[0];
		// Display loader
		setShowLoader(true);
		// Stop function & loader, then display error, if user uploads non-image file
		if (!file.type.includes("image")) {
			setShowLoader(false);
			setAlertModal({
				title: "Missing Fields",
				body: "Please only import an image",
				show: true,
			});
			const timerId = setTimeout(closeAlertModal, 3000);
			return clearTimeout(timerId);
		}
		// Init FileReader (to get image source for formatting & upload)
		const reader = new FileReader();
		reader.onload = (e) => {
			// Generate image
			const img = new Image();
			img.src = e.target.result;
			img.onload = async () => {
				// Calculate the height to maintain aspect ratio
				const scale = 1200 / img.width;
				const height = img.height * scale;
				// Create a canvas and draw the image on it
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);
				// Get the data URL in PNG format
				const dataUrl = canvas.toDataURL("image/png");
				// Upload image to storage and retrieve download URL
				const url = await uploadFieldCaptureFromDataUrl(dataUrl);
				// Update inputs & hide loader
				const tempInputs = deepCopy(inputs);
				if (
					Array.isArray(
						tempInputs[pageIndex].sections[sectionIndex].questions[
							questionIndex
						].value,
					)
				) {
					// If multiple images are allowed, push the new image URL into the array
					tempInputs[pageIndex].sections[sectionIndex].questions[
						questionIndex
					].value.push(url);
				} else {
					// If only one image is allowed, replace the current value
					tempInputs[pageIndex].sections[sectionIndex].questions[
						questionIndex
					].value = [url];
				}
				setInputs(tempInputs);
				setShowLoader(false);
			};
		};
		// Trigger image formatting & uploading process above, starting with `const reader = new FileReader()`
		reader.readAsDataURL(file);
	};

	useEffect(() => {
		if (!drafts) {
			setShowDraftSelectionModal(false);
			setStartNew(false);
			return;
		}
		setTodaysDrafts(drafts);
		if (
			drafts.length > 0 &&
			currentPage === 0 &&
			!startNew &&
			!isEditing &&
			!fromDraftsTable
		) {
			setShowDraftSelectionModal(true);
		} else {
			setShowDraftSelectionModal(false);
		}
	}, [drafts]);

	useEffect(() => {
		if (location?.state) {
			if (location.state.isEditing) {
				setIsEditing(location.state.isEditing);
			}
			if (location.state.savedLocation) {
				setSavedLocation(location.state.savedLocation);
			}
			if (location.state.submissionId) {
				setDraftId(location.state.submissionId);
			}
			if (location.state.draftId) {
				setDraftId(location.state.draftId);
			}
		}
	}, [location.state]);

	useEffect(() => {
		getLocationsArr(organization_id, setLocations);
		if (isEditing) {
			setShouldAutoSave(false);
		} else {
			setShouldAutoSave(userOrganization?.config?.auto_save_drafts === true);
		}
		getFormData(organization_id, formId, setFormName, setFormLogo, undefined);
	}, []);

	useEffect(() => {
		if (locations && locations.length > 0) {
			setIsLoading(false);
		}
	}, [locations]);

	useEffect(() => {
		if (photoToBeCropped) setIsCropShowing(true);
	}, [photoToBeCropped]);

	const saveProgressDebounced = useCallback(
		debounce((params) => {
			saveProgress(...params);
		}, 1500),
		[],
	);

	// Update draft state when inputs changes, only if shouldAutoSave is on & the user selects a dateOfVisit & location
	useEffect(() => {
		if (
			shouldAutoSave &&
			inputs[0].sections[0].questions[0].value &&
			savedLocation &&
			inputs[0].sections[0].questions[1].value?.length > 0 &&
			!isEditing
		) {
			saveProgressDebounced([
				formId,
				draftId,
				setDraftId,
				userLocation,
				savedLocation,
				inputs,
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	const _resetInputs = () => {
		setInputs(deepCopy(initInputs));
	};

	// Filter inputs to only include those with non-empty values
	const _filterInputs = (inputs) => {
		return inputs
			.map((page) => ({
				...page,
				sections: page.sections
					.map((section) => ({
						...section,
						questions: section.questions.filter((question) => {
							// For array types like images, check if the array is not empty
							if (Array.isArray(question.value)) {
								return question.value.length > 0;
							}
							// For other types, check if the value is not null, undefined, or an empty string
							return (
								question.value !== null &&
								question.value !== undefined &&
								question.value !== ""
							);
						}),
					}))
					.filter((section) => section.questions.length > 0), // Filter out sections with no questions left
			}))
			.filter((page) => page.sections.length > 0); // Filter out pages with no sections left
	};

	// Validate image fields
	const validateImages = () => {
		let allImagesValid = true;
		inputs.forEach((page) => {
			page.sections.forEach((section) => {
				section.questions.forEach((question) => {
					if (
						question.type === "image" &&
						(!Array.isArray(question.value) || question.value.length === 0)
					) {
						allImagesValid = false;
					}
				});
			});
		});
		return allImagesValid;
	};

	// Generates a date in MM/DD/YYYY format when given one from ReactDatePicker
	const formatDate = (e) => {
		const date = new Date(e);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${month}/${day}/${year}`;
	};

	const failSubmission = (body) => {
		setAlertModal({
			title: "Missing Fields",
			body: body,
			show: true,
		});

		const timerId = setTimeout(closeAlertModal, 3000);
		setSubmitDisabled(false);
		return clearTimeout(timerId);
	};

	const handleSubmit = async (enterNew) => {
		try {
			// Checks that a location is selected
			if (!inputs[0].sections[0].questions[0].value) {
				failSubmission("Please select a store location (on page 1).");
				// return;
			}

			// Checks that a dateOfVisit is entered
			if (!inputs[0].sections[0].questions[1].value) {
				failSubmission("Please enter the date of visit (on page 1).");
				// return;
			}

			// Grabs location from the available locations
			const location = locations.find(
				(loc) => loc.id === inputs[0].sections[0].questions[0].value,
			);
			if (!location) {
				failSubmission("Error finding location. Please try again.");
				// return;
			}

			if (!validateImages()) {
				failSubmission("Please upload images for all required fields.");
				// return;
			}

			// If editing submission, update it in the database
			if (isEditing) {
				await updateRaisingCanesSubmission.mutate({
					draftId,
					inputs,
					userLocation,
				}); // Pass the object with submissionId inside
			} else {
				if (!draftId) {
					const body = {
						data: inputs,
						form_id: formId,
						location_id: savedLocation.id,
					};
					const draft_id = await createDraft(body);
					await addRaisingCanesSubmission.mutate({
						draft_id,
						inputs,
						userLocation,
					});
				} else {
					await addRaisingCanesSubmission.mutate({
						draftId,
						inputs,
						userLocation,
					});
				}
			}

			// Reset all states
			const newInputs = deepCopy(initInputs);
			newInputs[0].sections[0].questions[0].value = savedLocation.id;
			setInputs(newInputs);
			setCurrentPage(0);
			setCurrentSection(0);
			setSelectedPhotoUrl(null);
			setIsPhotoShowing(false);
			setSelectedItem(null);
			setIsCropShowing(false);
			setPhotoToBeCropped(null);
			setSavedLocation(null);
			setUserLocation({});
			await setSubmitDisabled(false);

			// If editing submission, navigate back to submission page
			// Else, if user hit "Submit", navigate to submissions table of location
			if (isEditing) {
				navigate(originalPath, {
					state: {
						data: savedLocation.data,
						id: savedLocation.id,
						tab: "Submissions",
						selectedForm: formId,
						submissionId: submissionId, // Use submissionId from the object
					},
				});
			} else if (!enterNew) {
				navigate(`/locations/${location.id}/submissions`, {
					state: {
						data: savedLocation.data,
						id: savedLocation.id,
						tab: "Submissions",
						formName: formName,
						selectedForm: formId,
					},
				});
			}
		} catch (error) {
			console.error("Error during submission:", error);
			setAlertModal({
				title: "Submission Failed",
				body: "There was an issue submitting your form. Please try again.",
				show: true,
			});
		} finally {
			setSubmitDisabled(false);
		}
	};

	const getCroppedPhoto = async (dataUrl) => {
		setIsCropShowing(false);
		const url = await uploadFieldCaptureFromDataUrl(dataUrl);

		// Ensure the selected item exists
		if (!selectedItem) return;

		const { pageIndex, sectionIndex, questionIndex, index } = selectedItem;
		const tempInputs = deepCopy(inputs);

		// Check if we're replacing an existing image or adding a new one
		if (
			typeof index === "number" &&
			tempInputs[pageIndex].sections[sectionIndex].questions[questionIndex]
				.value[index]
		) {
			tempInputs[pageIndex].sections[sectionIndex].questions[
				questionIndex
			].value[index] = url;
		} else {
			tempInputs[pageIndex].sections[sectionIndex].questions[
				questionIndex
			].value.push(url);
		}

		setInputs(tempInputs);
		setPhotoToBeCropped(null);
	};

	const handleChange = (value, i, j, k) => {
		const newInputs = [...inputs];
		const input = newInputs[i].sections[j].questions[k];
		input.value = value;
		setInputs(newInputs);
	};

	const _toggle = (tab) => {
		if (currentPage !== tab) setCurrentPage(tab);
	};

	const toggleSection = (sectionIndex) => {
		if (currentSection !== sectionIndex) {
			setCurrentSection(sectionIndex);
		} else {
			setCurrentSection(null);
		}
	};

	const showImage = (photoUrl) => {
		setSelectedPhotoUrl(photoUrl);
		setIsPhotoShowing(true);
	};

	const removePhoto = (i, j, k, photoUrl) => {
		const newInputs = [...inputs];
		const input = newInputs[i].sections[j].questions[k];
		const newPhotosArr = input.value.filter((url) => url !== photoUrl);
		input.value = newPhotosArr;
		setInputs(newInputs);
	};

	const _savePhoto = (file, i, j, k) => {
		const photoID = uuid();
		setShowLoader(true);
		uploadFieldCaptureFromDataUrl(formId, authInfo.id, file, photoID).then(
			(newPhotoURL) => {
				setShowLoader(false);
				const newInputs = [...inputs];
				const input = newInputs[i].sections[j].questions[k];
				input.value.push(newPhotoURL);
				setInputs(newInputs);
			},
		);
	};

	const renderPreviewImages = (input, i, j, k) => {
		return input.value.map((photoUrl, index) => (
			<SubmittedImageModal
				photoUrl={photoUrl}
				removePhoto={() => removePhoto(i, j, k, photoUrl)}
				showImage={() => showImage(photoUrl)}
				key={index}
			/>
		));
	};

	const getFieldType = (input, i, j, k) => {
		console.log(savedLocation);
		if (input.key === "location") {
			console.log(inputs[0].sections[0].questions[0].value);
			return (
				<Row key="location">
					<LocationPicker
						locations={locations}
						chosenLocation={
							// inputs[0].sections[0].questions[0].value &&
							savedLocation ? savedLocation : "Select a Location"
						}
						setChosenLocation={(e) => {
							const tempInputs = deepCopy(inputs);
							tempInputs[0].sections[0].questions[0].value = e.id;
							setInputs(tempInputs);
							setSavedLocation(e);
						}}
						setUserLocation={setUserLocation}
						organization={organization_id}
					/>
				</Row>
			);
		}

		if (input.key === "dateOfVisit") {
			return (
				<Row key="dateOfVisit">
					<FormGroup className={isMobile ? "col-12" : "col-6"}>
						<Label className="single-form-label">
							{input.label}
							<span style={{ color: "red" }}> *</span>
						</Label>
						<br />
						<div className="d-flex">
							<DatePicker
								showIcon={true}
								showPopperArrow={false}
								key={input.key}
								id={id}
								onChange={(e) => {
									const date = formatDate(e);
									const tempInputs = deepCopy(inputs);
									tempInputs[0].sections[0].questions[1].value = date;
									setInputs(tempInputs);
								}}
								popperPlacement="top-start"
								className="form-inputs form-control"
								selected={
									inputs[0].sections[0].questions[1].value
										? new Date(inputs[0].sections[0].questions[1].value)
										: null
								}
								placeholderText="Select the date of visit"
								minDate={null}
								maxDate={null}
							/>
							<AiOutlineCalendar
								className={`date-picker-icon-${
									currentPage === 0 && currentSection === 0
								}`}
							/>
						</div>
					</FormGroup>
				</Row>
			);
		}

		// Existing logic for other input types
		if (input.type === "date") {
			return (
				<DatePicker
					id={input.responseTag}
					selected={input.value}
					onChange={(value) => handleChange(value, i, j, k)}
					placeholderText="Select Date"
					customInput={
						<Label
							for={input.responseTag}
							className="d-flex align-items-center mb-0"
						>
							{input.label}
							<AiOutlineCalendar size={20} className="ms-2" />
						</Label>
					}
					className="form-control"
				/>
			);
		}
		// Use the `options` from `raisingCanesInputs` if `input.type` is `select` or `multi-select`
		if (input.type === "select") {
			return (
				<FormGroup>
					<Label for={input.responseTag}>{input.label}</Label>
					<Input
						type="select"
						id={input.responseTag}
						value={input.value || ""} // Ensure value is initially an empty string
						onChange={(e) => handleChange(e.target.value, i, j, k)}
					>
						<option value="" disabled>
							Select...
						</option>
						{input.options?.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Input>
				</FormGroup>
			);
		}

		if (input.type === "multi-select") {
			return (
				<FormGroup>
					<Label for={input.responseTag}>{input.label}</Label>
					<Input
						type="select"
						id={input.responseTag}
						multiple
						value={input.value || []} // Ensure value is initially an empty array
						onChange={(e) =>
							handleChange(
								Array.from(e.target.selectedOptions, (option) => option.value),
								i,
								j,
								k,
							)
						}
					>
						<option value="" disabled>
							Select...
						</option>
						{input.options?.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Input>
				</FormGroup>
			);
		}
		// Handle other input types (text, textarea, image, checkbox) as before

		if (input.type === "text") {
			return (
				<FormGroup>
					<Label for={input.responseTag}>{input.label}</Label>
					<Input
						type="text"
						id={input.responseTag}
						value={input.value}
						onChange={(e) => handleChange(e.target.value, i, j, k)}
					/>
				</FormGroup>
			);
		}

		if (input.type === "textarea") {
			return (
				<FormGroup>
					<Label for={input.responseTag}>{input.label}</Label>
					<Input
						type="textarea"
						id={input.responseTag}
						value={input.value}
						onChange={(e) => handleChange(e.target.value, i, j, k)}
					/>
				</FormGroup>
			);
		}

		if (input.type === "image") {
			return (
				<FormGroup>
					<Label for={input.responseTag}>{input.label}</Label>
					<RCImageOptions
						input={input}
						questionIndex={k}
						sectionIndex={j}
						pageIndex={i}
						addPhotoToInputs={addPhotoToInputs}
						setPhotoToBeCropped={setPhotoToBeCropped}
					/>
					<div className="d-flex flex-wrap">
						{input.value?.length > 0 && renderPreviewImages(input, i, j, k)}
					</div>
				</FormGroup>
			);
		}

		if (input.type === "checkbox") {
			return (
				<FormGroup check>
					<Label check>
						<Input
							type="checkbox"
							id={input.responseTag}
							checked={input.value}
							onChange={(e) => handleChange(e.target.checked, i, j, k)}
						/>
						{input.label}
					</Label>
				</FormGroup>
			);
		}
	};

	const generatePageHeaders = () => {
		return inputs.map((page, i) => {
			return (
				<NavItem key={i}>
					<NavLink
						className={currentPage === i ? "active" : ""}
						onClick={() => {
							setCurrentPage(i);
							setCurrentSection(0);
						}}
					>
						<span style={{ fontWeight: "bolder" }}>
							<Label className="single-form-label">{page.title}</Label>
						</span>
					</NavLink>
				</NavItem>
			);
		});
	};

	const generateSections = () => {
		const items = inputs[currentPage].sections.map((section, sectionIndex) => {
			return (
				<AccordionItem key={`accordion-item-${currentPage}-${sectionIndex}`}>
					<AccordionHeader
						key={`accordion-header-${currentPage}-${sectionIndex}`}
						targetId={sectionIndex}
						onClick={() => {
							toggleSection(sectionIndex);
						}}
					>
						{section.title}
					</AccordionHeader>
					<AccordionBody accordionId={sectionIndex}>
						{generateQuestions(section, sectionIndex)}
					</AccordionBody>
				</AccordionItem>
			);
		});
		return (
			<Accordion open={currentSection} toggle={toggleSection}>
				{items}
			</Accordion>
		);
	};

	const generateQuestions = (section, sectionIndex) => {
		return section.questions.map((input, questionIndex) => (
			<Row key={`question-${currentPage}-${sectionIndex}-${questionIndex}`}>
				<Col sm={12}>
					{getFieldType(input, currentPage, sectionIndex, questionIndex)}
				</Col>
			</Row>
		));
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Card className="px-2 pt-4 some-drop-shadow">
				<span className="logo-container d-flex justify-content-center">
					<img
						src={formLogo}
						className="form-logo"
						hidden={!formLogo}
						alt="logo"
					/>
				</span>
				<CardHeader
					style={{
						backgroundColor: "#ffffff",
						border: "none",
						textAlign: "center",
					}}
				>
					<span className="form-title">{formName}</span>
				</CardHeader>
				<Form className="" onSubmit={(e) => e.preventDefault()}>
					<div>
						{/* { Nav section for page headers } */}
						<Nav tabs className="form">
							{generatePageHeaders()}
						</Nav>
						{/* { Tab section for page content } */}
						<TabContent
							activeTab={(currentPage + 1).toString()}
							className="fullSize"
						>
							{generateSections()}
						</TabContent>
					</div>
					{/* <div className='form-btns-submit'> */}
					<div className="form-btns-submit">
						<Row>
							<Button
								color="secondary"
								className="submit-btn-form"
								onClick={() => handleSubmit(false)}
								disabled={submitDisabled}
							>
								{isEditingRaisingCanes ? "Save Changes" : "Submit"}
							</Button>
						</Row>
						{/* {!isEditingRaisingCanes && (
							<Row>
								<Button
									color='secondary'
									className='submit-btn-form'
									onClick={() => handleSubmit(true)}
									disabled={submitDisabled}
								>
									Submit & Enter New
								</Button>
							</Row>
						)} */}
						{/* </div> */}
					</div>
				</Form>
			</Card>
			<Modal isOpen={isPhotoShowing} toggle={() => setIsPhotoShowing(false)}>
				<ModalHeader toggle={() => setIsPhotoShowing(false)}>
					Photo Preview
				</ModalHeader>
				<ModalBody className="text-center">
					<img
						src={selectedPhotoUrl}
						alt="Selected Preview"
						style={{ maxWidth: "100%" }}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => setIsPhotoShowing(false)}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
			<CropperModal
				showing={isCropShowing}
				photoUrl={photoToBeCropped}
				onCrop={getCroppedPhoto}
				cancel={() => {
					setIsCropShowing(false);
				}}
			/>
			<LoadingModal showing={showLoader} />
			<DraftSelectionModal
				drafts={todaysDrafts}
				setResponseData={setInputs}
				draftId={draftId}
				isNotAssetForm={true}
			/>
		</div>
	);
};

export default SingleFormRC;
