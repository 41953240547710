export function deepCopy(obj) {
	if (obj === null || typeof obj !== "object") {
		return obj;
	}

	if (obj instanceof Date) {
		return new Date(obj.getTime());
	}

	if (Array.isArray(obj)) {
		return obj.map((item) => deepCopy(item));
	}

	// Check for the _fh class instance
	if (obj.constructor.name === "_fh") {
		// Return the original object or handle it as needed
		return obj;
	}

	let copy;
	if (obj.constructor === Object) {
		copy = {};
	} else {
		try {
			copy = new obj.constructor();
		} catch (error) {
			console.error("deepCopy encountered an object it couldn't clone:", obj);
			throw error;
		}
	}

	for (const attr in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, attr)) {
			copy[attr] = deepCopy(obj[attr]);
		}
	}

	return copy;
}
