// SubmissionDetailControls.tsx
import { useFormStore, useUserStore } from "@/stores";
import { UserAccessLevel } from "@/stores/userStore";
import { addExifToInputs } from "@/utils/addExifToInputs";
import { parseDateToUTCString } from "@/utils/transforms";
import { FilePdfOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Tooltip, message } from "antd";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { GoThumbsup } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { DownloadCsv } from "./DownloadCsv";
import type { SubmissionDetailsState } from "./Submission";
import { approveSubmission } from "./functions/approveSubmission";
import { generatePDF } from "./generatePDF";

interface SubmissionDetailControlsProps {
	state: SubmissionDetailsState & { inputs: any };
	dispatch: React.Dispatch<any>;
	toggleSubmission: () => void;
}

export const SubmissionDetailControls: React.FC<
	SubmissionDetailControlsProps
> = ({ state, dispatch, toggleSubmission }) => {
	const navigate = useNavigate();
	const { setShowDraftSelectionModal } = useFormStore();
	const { userOrganization, user } = useUserStore();
	const { role } = user;
	const { access_level } = role;
	const organization = userOrganization.external_firebase_id;
	const queryClient = useQueryClient();

	const [isApprovalModalOpen, setIsApprovalModalVisible] = useState(false);

	const getNoEditMessage = useCallback(() => {
		if (state.responseData?.is_deleted) {
			return "This submission has been deleted.";
		}
		if (!state.responseData?.can_edit) {
			if (access_level >= UserAccessLevel.ADMIN) {
				return "You may only make changes to submissions within the first 9 weeks after submission.";
			}
			return "You may only make changes to submissions if you are the creator of the record and are within the first 72 hours after submission.";
		}
		return "";
	}, [
		state.responseData?.is_deleted,
		state.responseData?.can_edit,
		access_level,
	]);

	const noEditMessage = useMemo(() => getNoEditMessage(), [getNoEditMessage]);

	// Download PDF handler
	const downloadPdf = useCallback(async () => {
		dispatch({ type: "SET_STATE", payload: { pdfLoading: true } });
		try {
			const questions = await addExifToInputs(
				state.inputs,
				state.responseData.raw_input,
			);
			const submittedDate = parseDateToUTCString(state.responseData?.end_time);
			const blob = await generatePDF(
				questions,
				state.responseData,
				state.formName,
				state.formLogo,
				submittedDate,
				state.address,
			);
			const tempLink = document.createElement("a");
			tempLink.href = URL.createObjectURL(blob);
			tempLink.setAttribute(
				"download",
				`${state.formName} - ${state.address}.pdf`,
			);
			tempLink.click();
			message.success("PDF downloaded successfully");
		} catch (error) {
			console.error(error);
			message.error("Failed to download PDF");
		}
		dispatch({ type: "SET_STATE", payload: { pdfLoading: false } });
	}, [
		dispatch,
		state.inputs,
		state.responseData,
		state.formName,
		state.formLogo,
		state.address,
	]);

	// Edit submission handler
	const editSubmission = useCallback(async () => {
		setShowDraftSelectionModal(false);
		navigate(`/forms/${state.formId}`, {
			state: {
				isEditing: true,
				responseData: state.responseData.raw_input,
				generativeAiInputs:
					state.responseData?.submission_asset_ai_data_map ||
					state.responseData?.asset?.asset_ai_data_map ||
					{},
				submissionId: state.submissionId,
				savedLocation: state.responseData.location,
				chosenAssetType: state.responseData.asset.organization_asset_type,
				userLocation: state.userLocation,
				airportCode: organization === "SSPAMERICA" ? state.airportCode : null,
				path: state.path,
				isQA: state.isQA,
				assetRefPath: state.responseData.assetRef
					? state.responseData.assetRef.path
					: null,
				submissionsPageNum: state.submissionsPageNum,
			},
		});
	}, [
		setShowDraftSelectionModal,
		navigate,
		state.formId,
		state.responseData,
		state.submissionId,
		state.userLocation,
		organization,
		state.airportCode,
		state.path,
		state.isQA,
		state.submissionsPageNum,
	]);

	const handleApprove = async () => {
		setIsApprovalModalVisible(false);
		await approveSubmission(
			state.submissionId,
			(loading) =>
				dispatch({ type: "SET_STATE", payload: { isLoading: loading } }),
			queryClient,
			toggleSubmission,
		);
	};

	return (
		<div className="d-flex align-content-start">
			<div className="d-flex flex-row gap-2">
				{state?.responseData?.is_qa_flagged ? (
					<>
						<Tooltip title={noEditMessage}>
							<Button
								type="primary"
								disabled={
									state?.responseData?.is_deleted ||
									!state.responseData?.can_edit
								}
								onClick={() => setIsApprovalModalVisible(true)}
								icon={<GoThumbsup />}
							>
								Approve
							</Button>
						</Tooltip>
						<Modal
							title="Approve This Submission?"
							open={isApprovalModalOpen}
							onOk={handleApprove}
							onCancel={() => setIsApprovalModalVisible(false)}
						>
							<p>
								Are you sure you want to approve this submission? Clicking
								"Confirm" will remove it from QA and add it to the submissions
								collection.
							</p>
						</Modal>
					</>
				) : null}
				<Button
					onClick={downloadPdf}
					loading={state.pdfLoading}
					icon={<FilePdfOutlined />}
				>
					PDF
				</Button>
				<DownloadCsv form={state.responseData} />
				<Tooltip title={noEditMessage}>
					<Button
						type="primary"
						disabled={
							state.responseData?.is_deleted || !state.responseData?.can_edit
						}
						onClick={editSubmission}
						icon={<AiOutlineEdit />}
						id="editBtn"
					>
						Edit
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};
