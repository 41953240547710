import { MediumCard } from "@SignedIn/views/Dashboard/MediumCard/MediumCard";
import { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import "swiper/css";

register();

const swiperParams = {
	pagination: true,
	grabCursor: true,
	mousewheel: true,
	spaceBetween: 15,
	breakpoints: {
		0: {
			slidesPerView: 1,
		},
		992: {
			slidesPerView: 2,
		},
		1200: {
			slidesPerView: 3,
		},
	},
	injectStyles: [
		`
		.swiper-pagination-bullets {
		  bottom: var(--swiper-pagination-bottom,-6px) !important;
		}
		`,
	],
};

const Carousel = ({ graphs, tension = false, setDisplayToast }: any) => {
	const swiperRef = useRef<any>(null);
	const swiper = swiperRef.current;

	useEffect(() => {
		if (!swiper) return;

		Object.assign(swiper, swiperParams);
		swiper.initialize();
	}, [swiper]);

	// Update swiper on graphs change
	useEffect(() => {
		swiper?.swiper.update();
	}, [swiper, graphs]);

	return (
		// @ts-ignore
		<swiper-container ref={swiperRef} init="false">
			{graphs.map((graph: any) => (
				<Slide
					graph={graph}
					key={graph.id}
					tension={tension}
					setDisplayToast={setDisplayToast}
				/>
			))}
			{/* @ts-ignore */}
		</swiper-container>
	);
};

const Slide = ({ graph, tension, setDisplayToast }: any) => {
	return (
		// @ts-ignore
		<swiper-slide>
			<MediumCard
				graph={graph}
				tension={tension}
				setDisplayToast={setDisplayToast}
			/>
			{/* @ts-ignore */}
		</swiper-slide>
	);
};

export { Carousel };
