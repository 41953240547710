export const prevPage = (
	setAlertModal,
	closeAlertModal,
	currentPage,
	setCurrentPage,
) => {
	if (!navigator.onLine) {
		setAlertModal({
			show: true,
			title: "Connection Lost!",
			body: "Please try again when you have connected to the internet",
		});
		setTimeout(closeAlertModal, 2500);
		return;
	}
	if (currentPage > 0) {
		setCurrentPage(currentPage - 1);
	}
};
