// Converts dates to format similar to "March 31st, 2023"

export const formatDate = (ogDate: string | number | Date) => {
	const date = new Date(ogDate);
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const month = monthNames[date.getMonth()];
	const day = date.getDate() + 1;
	const year = date.getFullYear();

	function addSuffix(n: string | number) {
		const s = ["th", "st", "nd", "rd"];
		const v = Number(n) % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	}

	return `${month} ${addSuffix(day)}, ${year}`;
};
