import { useFormStore } from "@/stores/formStore";
import { parseDateToUTCString } from "@/utils/transforms";
import { useChatbotStore } from "@stores/chatbotStore";
import { type EventHandler, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FormGroup, Label, Row } from "reactstrap";
import { GeneratedPropIcon } from "../../AssetProfile/GeneratedPropIcon";
import { CheckboxInput } from "./CheckboxInput";
import { PreviewField } from "./PreviewField";

interface DateInputProps {
	question: any;
	responseData: any;
	setInputData: CallableFunction;
	hidePreview: boolean;
	fromChatbot: boolean;
	placeholder: string;
}

interface InputData {
	value: any;
	onClick: EventHandler<any>;
	onChange: EventHandler<any>;
	onBlur: EventHandler<any>;
	placeholder: string;
	className: string;
	disabled: boolean;
}

interface OCRConfidenceAndColor {
	color?: string;
	confidence?: string;
}

const DateInput = ({
	question,
	responseData,
	setInputData,
	hidePreview,
	fromChatbot,
	placeholder,
}: DateInputProps) => {
	const {
		fieldError: chatbotFieldError,
		setFieldError: setChatbotFieldError,
		ocrConfidenceAndColor: chatbotOcrConfidenceAndColor,
	} = useChatbotStore();
	const {
		fieldError: formFieldError,
		setFieldError: setFormFieldError,
		ocrConfidenceAndColor: formOcrConfidenceAndColor,
	} = useFormStore();

	const ocrConfidenceAndColor = fromChatbot
		? chatbotOcrConfidenceAndColor
		: formOcrConfidenceAndColor;
	const fieldError = fromChatbot ? chatbotFieldError : formFieldError;
	const setFieldErrorFn = fromChatbot
		? setChatbotFieldError
		: setFormFieldError;

	// Function to parse date string to Date object
	const parseDate = (dateValue: InputData["value"]) => {
		if (!dateValue) return null;

		if (typeof dateValue === "object" && dateValue.base) {
			// dateValue is an object with 'base' property
			const date = new Date(dateValue.base);
			return Number.isNaN(date.getTime()) ? null : date;
		}

		if (typeof dateValue === "string") {
			// Try parsing as ISO string
			const date = new Date(dateValue);
			if (!Number.isNaN(date.getTime())) {
				return date;
			}

			// Try parsing MM/DD/YYYY format
			const parts = dateValue.split("/");
			if (parts.length === 3) {
				const month = Number.parseInt(parts[0], 10) - 1; // Months are zero-based
				const day = Number.parseInt(parts[1], 10);
				const year = Number.parseInt(parts[2], 10);
				const date = new Date(year, month, day);
				return Number.isNaN(date.getTime()) ? null : date;
			}
		}

		return null;
	};

	const shouldDisable = () => {
		if (question.disabled === "true") {
			return true;
		}
		return (
			responseData[question.tiedTo] === true ||
			responseData[question.checkbox?.key] === true
		);
	};
	// Determine selectedDate for DatePicker
	const selectedDate = parseDate(responseData[question.key]);

	// useEffect to update responseData[question.key] when necessary
	useEffect(() => {
		if (
			responseData[question.key] &&
			(typeof responseData[question.key] === "string" ||
				(typeof responseData[question.key] === "object" &&
					!responseData[question.key].base))
		) {
			const date = parseDate(responseData[question.key]);
			if (date) {
				const isoString = date.toISOString();
				const formattedDate = parseDateToUTCString(date);
				setInputData(question, {
					base: isoString,
					formatted: formattedDate,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responseData[question.key]]);

	const handleCheckboxChange = (checkboxKey: any, checkboxValue: any) => {
		const checkboxKeyDerived = question.checkbox.key || checkboxKey;
		setInputData({ key: checkboxKeyDerived }, checkboxValue);

		if (checkboxValue) {
			setInputData({ key: question.key }, "");
			setFieldErrorFn(question.key, "");
		}
	};

	const handleDateChange = (date: InputData["value"]) => {
		if (date) {
			const isoString = date?.toISOString();
			const formattedDate = parseDateToUTCString(date);
			setInputData(question, {
				base: isoString,
				formatted: formattedDate,
			});
		} else {
			setInputData(question, "");
		}

		if (
			question.required === "true" &&
			!responseData[question.checkbox.key] &&
			!date
		) {
			setFieldErrorFn(question.key, `${question.label} is required`);
		} else {
			setFieldErrorFn(question.key, "");
		}
	};

	const ocrConfidenceAndColorObj: OCRConfidenceAndColor = {
		color: "",
		confidence: undefined,
	};

	if (ocrConfidenceAndColor?.[question.key]) {
		ocrConfidenceAndColorObj.confidence = (
			ocrConfidenceAndColor[question.key]?.confidence * 100
		).toFixed(2);
		ocrConfidenceAndColorObj.color = ocrConfidenceAndColor[question.key]?.color;
	}

	// Manage hover state
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Row key={question.key}>
			<FormGroup key={question.key} className="form-date-picker">
				<div
					className={`d-flex align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div className="d-flex flex-row gap-1">
						<Label
							className={`${question.checkbox ? "mb-0" : "single-form-label"}`}
						>
							{question.label}
							{question.required === "true" && (
								<span className="text-error"> *</span>
							)}
						</Label>
						{!hidePreview ? (
							<PreviewField
								question={question}
								responseData={responseData}
								fromChatbot={fromChatbot}
							/>
						) : null}
						{!responseData[question.key] && placeholder && (
							<GeneratedPropIcon />
						)}
					</div>
					{question.checkbox ? (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={(checkboxKey: any, checkboxValue: any) =>
								handleCheckboxChange(checkboxKey, checkboxValue)
							}
							notStandalone={true}
						/>
					) : null}
				</div>
				<div
					className="position-relative w-100"
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					style={{
						// @ts-expect-error Custom OCR border color
						"--ocr-border-color": ocrConfidenceAndColorObj.color || "#ced4da",
						"--hover-border-color": fieldError[question.key]
							? "red"
							: ocrConfidenceAndColorObj.color || "#ced4da",
					}}
				>
					<DatePicker
						showPopperArrow={false}
						key={question.key}
						onChange={handleDateChange}
						className={`form-inputs form-control custom-date-picker ${
							fieldError[question.key] ? "error-border" : ""
						} ${ocrConfidenceAndColorObj.color ? "ocr-border" : ""}`}
						selected={selectedDate}
						disabled={shouldDisable()}
						placeholderText={
							placeholder || question.placeholder || "Select a date"
						}
						dateFormat="MM/dd/yyyy" // Ensure DatePicker uses MM/DD/YYYY format
					/>

					{ocrConfidenceAndColorObj.color &&
						ocrConfidenceAndColorObj.confidence && (
							<span className="confidence-text">
								{ocrConfidenceAndColorObj.confidence}%
							</span>
						)}
				</div>

				{fieldError[question.key] && (
					<div className="error-message text-error">
						{fieldError[question.key]}
					</div>
				)}
			</FormGroup>
		</Row>
	);
};

export { DateInput };
