import { getLocationTickets } from "@services/location-service";
import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";

const LastAbsorbedTicketCard = (props) => {
	const { formId, locationId, org } = props;
	const [ticketsCard, setTicketsCard] = useState();

	const updateTicketsCard = async () => {
		const tickets = await getLocationTickets(locationId);

		let rows = [];
		for (const ticket of tickets) {
			const row = (
				<tr key={ticket.id}>
					<td>{ticket.po_number}</td>
					<td>{ticket.created_date}</td>
					<td>{ticket.completed_date}</td>
				</tr>
			);
			rows = [...rows, row];
		}
		setTicketsCard(
			<Card className="px-5 py-2 d-flex flex-column gen-info-card-wrapper gen-tab-submission h-100">
				<CardHeader className="absorbed-tickets-title">
					Absorbed Tickets
				</CardHeader>
				<CardBody>
					<Table className="absorbed-tickets-table" responsive hover>
						<thead>
							<tr>
								<th>PO Number</th>
								<th>Date Created</th>
								<th>Date Completed</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</CardBody>
			</Card>,
		);
	};

	useEffect(() => {
		if (formId && locationId && org) {
			updateTicketsCard();
		}
		// eslint-disable-next-line
	}, [formId, locationId, org]);

	return ticketsCard;
};

export { LastAbsorbedTicketCard };
