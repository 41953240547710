export const validateAndSetPage8 = (
	organization,
	assetMapNewAsset,
	setIsPage8Valid,
	responseData,
) => {
	console.log("organization", organization);
	const isValid =
		organization === "cvs.com"
			? !!assetMapNewAsset
			: organization === "EcoTrak"
				? responseData.warrantyNotFound ||
					(responseData.warrantyExpirationDate &&
						responseData.warrantyPeriod &&
						responseData.warrantyProvider &&
						responseData.warrantyStartDate) ||
					responseData.assetCondition
				: true;
	//cvs
	setIsPage8Valid(isValid);
};
