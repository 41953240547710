import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { parseDateToLocalString } from "@/utils/transforms";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";

export const columns: ColumnsType = [
	{
		title: "Asset ID",
		dataIndex: "asset_tag",
		key: "asset_tag",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
	},
	{
		title: "Asset Type",
		dataIndex: ["asset_type", "name"],
		key: "asset_type_name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		width: "30%",
		render: (text) => <CopyableTableCell text={text} />,
	},
	{
		title: "Address",
		dataIndex: "address",
		key: "address1",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		width: "30%",
		render: (text) => <CopyableTableCell text={text} />,
	},
	{
		title: "Submitting User",
		dataIndex: "submitting_user",
		key: "submitting_user",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => <CopyableTableCell text={text} />,
	},
	{
		title: "Submission Date",
		dataIndex: "submitted_date",
		key: "submitted_date",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (date: string) => (
			<CopyableTableCell text={parseDateToLocalString(date) || "N/A"} />
		),
	},
];

export const cushmanColumns: ColumnsType = [
	{
		title: "Site Name",
		dataIndex: "address",
		key: "address",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		width: "30%",
		render: (text) => <CopyableTableCell text={text} />,
	},
	{
		title: "Submitting User",
		dataIndex: "submitting_user",
		key: "submitting_user",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => <CopyableTableCell text={text} />,
	},
	{
		title: "Submission Date",
		dataIndex: "submitted_date",
		key: "submitted_date",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (date: string) => (
			<CopyableTableCell text={parseDateToLocalString(date) || "N/A"} />
		),
	},
	{
		title: "Approved",
		dataIndex: "is_approved",
		key: "is_approved",
		render: (text) => {
			return text ? (
				<CheckOutlined className="green" />
			) : (
				<CloseOutlined className="red" />
			);
		},
		align: "center",
		width: 150,
	},
];

export const getOrgColumns = (organization: any): ColumnsType => {
	switch (organization.external_firebase_id) {
		case "cushmanwakefield.com":
			return cushmanColumns;
		default:
			return columns;
	}
};
