import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export const usePageStore = create((set) => ({
	isSidebarOpen: false,
	setIsSidebarOpen: (open: any) => set({ isSidebarOpen: open }),

	alertModal: {
		show: false,
		title: "",
		body: "",
	},
	setAlertModal: (alertModal: any) => set({ alertModal }),

	activeTab: 1,
	setActiveTab: (activeTab: any) => set({ activeTab }),
}));

if (import.meta.env.NODE_ENV === "development") {
	mountStoreDevtool("pageStore", usePageStore);
}
