const BackButton = ({ label, handler, show }) => {
	return (
		<div
			className="location-back-button d-flex p-1"
			onClick={handler}
			style={{ maxWidth: "100px", visibility: show ? "visible" : "hidden" }}
		>
			<i className="bi bi-arrow-left me-3"></i>
			<div
				style={{
					fontWeight: "bold",
					fontSize: "14px",
					textAlign: "center",
				}}
			>
				{label}
			</div>
		</div>
	);
};

export { BackButton };
