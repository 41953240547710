export const setDefaultValues = async (inputs, setInputData, responseData) => {
	for (const page of inputs) {
		for (const question of page.questions) {
			if (question.default !== undefined) {
				setInputData(question, question.default);
			}
			if (
				question.unitMeasurementDropdown?.default &&
				!responseData[question.unitMeasurementDropdown.key]
			) {
				setInputData(
					question.unitMeasurementDropdown,
					question.unitMeasurementDropdown.default,
				);
			}
		}
	}
};
