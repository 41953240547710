const ASCENDING = "asc";
const DESCENDING = "desc";

export interface BaseTableState {
	sortBy: string;
	sortOrder: typeof ASCENDING | typeof DESCENDING;
	pageNum: number;
	pageSize: number;
	searchVal: string;
	dataSource: any[];
	count: number;
	isLoading: boolean;
	columnsShown: Record<string, boolean>;
	tableColumns: any[];
	checkedItems?: any[];
}

export type BaseTableAction =
	| { type: "SET_SORT_BY"; payload: string }
	| { type: "SET_SORT_ORDER"; payload: typeof ASCENDING | typeof DESCENDING }
	| { type: "SET_PAGE_NUM"; payload: number }
	| { type: "SET_PAGE_SIZE"; payload: number }
	| { type: "SET_SEARCH_VAL"; payload: string }
	| { type: "SET_DATA_SOURCE"; payload: any[] }
	| { type: "SET_COUNT"; payload: number }
	| { type: "SET_IS_LOADING"; payload: boolean }
	| { type: "SET_COLUMNS_SHOWN"; payload: Record<string, boolean> }
	| { type: "SET_TABLE_COLUMNS"; payload: any[] }
	| { type: "SET_CHECKED_ITEMS"; payload: any[] };

export function baseTableReducer<T extends BaseTableState>(
	state: T,
	action: BaseTableAction,
): T {
	switch (action.type) {
		case "SET_SORT_BY":
			return { ...state, sortBy: action.payload };
		case "SET_SORT_ORDER":
			return { ...state, sortOrder: action.payload };
		case "SET_PAGE_NUM":
			return { ...state, pageNum: action.payload };
		case "SET_PAGE_SIZE":
			return { ...state, pageSize: action.payload };
		case "SET_SEARCH_VAL":
			return { ...state, searchVal: action.payload };
		case "SET_DATA_SOURCE":
			return { ...state, dataSource: action.payload };
		case "SET_COUNT":
			return { ...state, count: action.payload };
		case "SET_IS_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_COLUMNS_SHOWN":
			return { ...state, columnsShown: action.payload };
		case "SET_TABLE_COLUMNS":
			return { ...state, tableColumns: action.payload };
		case "SET_CHECKED_ITEMS":
			return { ...state, checkedItems: action.payload };
		default:
			return state;
	}
}

export const baseInitialState: BaseTableState = {
	isLoading: true,
	sortBy: "", // Default sort field, can be overridden in components
	sortOrder: DESCENDING,
	pageNum: 1,
	pageSize: 10,
	searchVal: "",
	dataSource: [],
	count: 0,
	tableColumns: [], // Default columns, to be set in components
	columnsShown: {},
	checkedItems: [],
};
