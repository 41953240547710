import { useGetManufacturers } from "@/hooks/organizationQueries";
import type { StringMap } from "@/models/abstractTypes";
import { useFormStore } from "@/stores/formStore";
import { useChatbotStore } from "@stores/chatbotStore";
import { useEffect, useState } from "react";
import Select, {
	components,
	type GroupBase,
	type ValueContainerProps,
} from "react-select";
import type { JSX } from "react/jsx-runtime";
import { FormGroup, Label, Row } from "reactstrap";
import { CheckboxInput } from "./CheckboxInput";
import { PreviewField } from "./PreviewField";

interface ManufacturerInputProps {
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
	question: { [x: string]: any };
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
	responseData: { [x: string]: any };
	getOptions: CallableFunction;
	setInputData: CallableFunction;
	hidePreview: boolean;
	fromChatbot: boolean;
}

const ManufacturerInput = ({
	question,
	responseData,
	getOptions,
	setInputData,
	hidePreview,
	fromChatbot,
}: ManufacturerInputProps) => {
	// State variables
	const [options, setOptions] = useState<StringMap[]>([]);
	const [chosenOption, setChosenOption] = useState<StringMap | null>(null);
	const [showAllManufacturers, setShowAllManufacturers] = useState(false);
	const [isOptionsLoading, setIsOptionsLoading] = useState(false);

	// Accessing stores
	const {
		fieldError: chatbotFieldError,
		setFieldError: setChatbotFieldError,
		ocrConfidenceAndColor: chatbotOcrConfidenceAndColor,
		setOcrConfidenceAndColor: setChatbotOcrConfidenceAndColor,
	} = useChatbotStore();

	const {
		fieldError: formFieldError,
		setFieldError: setFormFieldError,
		ocrConfidenceAndColor: formOcrConfidenceAndColor,
		setOcrConfidenceAndColor: setFormOcrConfidenceAndColor,
	} = useFormStore();

	const ocrConfidenceAndColor = fromChatbot
		? chatbotOcrConfidenceAndColor
		: formOcrConfidenceAndColor;

	const setOcrConfidenceAndColorFn = fromChatbot
		? setChatbotOcrConfidenceAndColor
		: setFormOcrConfidenceAndColor;

	const fieldError = fromChatbot ? chatbotFieldError : formFieldError;
	const setFieldErrorFn = fromChatbot
		? setChatbotFieldError
		: setFormFieldError;

	// Fetch all manufacturers
	const {
		data: allManufacturers,
		isLoading: isAllManufacturersLoading,
		isError,
	} = useGetManufacturers(true);

	// Function to clean strings for comparison
	const cleanString = (str: string) => {
		return String(str)
			.replace(/[^a-zA-Z0-9. ]/g, "")
			.replace(/\s/g, "")
			.replace(".", "")
			.toUpperCase();
	};

	// Custom ValueContainer to display confidence scores
	const CustomValueContainer = (
		props: JSX.IntrinsicAttributes &
			ValueContainerProps<unknown, boolean, GroupBase<unknown>>,
	) => (
		<components.ValueContainer {...props}>
			{props.children}
			{ocrConfidenceAndColor[question.key]?.color &&
				ocrConfidenceAndColor[question.key]?.confidence && (
					<span
						className="confidence-text"
						style={{
							color: ocrConfidenceAndColor[question.key].color,
							marginLeft: "auto",
							marginRight: "10px",
							pointerEvents: "none",
						}}
					>
						{(ocrConfidenceAndColor[question.key].confidence * 100).toFixed(2)}%
					</span>
				)}
		</components.ValueContainer>
	);

	CustomValueContainer.displayName = "CustomValueContainer";

	// Prepare OCR confidence data
	const ocrConfidenceAndColorObj: {
		color: string;
		confidence?: string;
	} = {
		color: "",
		confidence: undefined,
	};

	if (ocrConfidenceAndColor[question.key]) {
		ocrConfidenceAndColorObj.confidence = (
			ocrConfidenceAndColor[question.key]?.confidence * 100
		).toFixed(2);
		ocrConfidenceAndColorObj.color = ocrConfidenceAndColor[question.key]?.color;
	}

	// Combined customStyles for react-select
	const customStyles = {
		control: (provided: { borderColor: string; boxShadow: string }) => ({
			...provided,
			width: "100%",
			borderColor: fieldError[question.key] ? "red" : provided.borderColor,
			boxShadow: ocrConfidenceAndColorObj.color
				? `0 0 10px ${ocrConfidenceAndColorObj.color}`
				: provided.boxShadow,
			outline: ocrConfidenceAndColorObj.color
				? `1px solid ${ocrConfidenceAndColorObj.color}`
				: "none",
			"&:hover": {
				borderColor: fieldError[question.key]
					? "red"
					: ocrConfidenceAndColorObj.color
						? ocrConfidenceAndColorObj.color
						: provided.borderColor,
			},
		}),
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: Reset showAllManufacturers when assetType changes
	useEffect(() => {
		setShowAllManufacturers(false);
	}, [responseData.assetType]);

	// Effect to set options and determine chosenOption
	// biome-ignore lint/correctness/useExhaustiveDependencies: cleanString would be recomputed on every render
	useEffect(() => {
		let isMounted = true;

		const fetchOptions = async () => {
			setIsOptionsLoading(true);
			let opts = [];

			if (showAllManufacturers) {
				// When 'Show All Manufacturers' is active, use all manufacturers
				if (allManufacturers && allManufacturers.length > 0) {
					opts = allManufacturers;
				}
			} else if (responseData.assetType) {
				// When assetType is selected, get options based on assetType
				try {
					const result = await Promise.resolve(
						getOptions(question, responseData.assetType),
					);
					opts = result || [];
				} catch (error) {
					console.error("Error fetching options:", error);
					opts = [];
				}
			} else {
				// If no assetType is selected, options list should be empty
				opts = [];
			}

			// Map options to react-select format
			const mappedOptions = opts
				.map((option: { name: string; id: string | number }) => ({
					label: option.name,
					value: option.id,
				}))
				.sort((a: { label: string }, b: { label: string }) =>
					a.label.localeCompare(b.label),
				);

			// Add "Expand All" option if not already expanded
			if (!showAllManufacturers) {
				mappedOptions.push({ label: "Expand All", value: "expand_all" });
			}

			if (isMounted) {
				setOptions(mappedOptions);
				setIsOptionsLoading(false);

				// Ensure chosenOption is set appropriately
				if (responseData[question.key]) {
					if (typeof responseData[question.key] === "string") {
						const manufacturer = allManufacturers?.find(
							(option: { name: string }) =>
								cleanString(option.name) ===
								cleanString(responseData[question.key]),
						);

						if (manufacturer) {
							const selectedOption = {
								label: manufacturer.name,
								value: manufacturer.id,
							};
							setChosenOption(selectedOption);
							setInputData(
								{ key: question.key },
								{
									id: manufacturer.id,
									name: manufacturer.name,
								},
							);
						} else {
							// Manufacturer not found; unset chosenOption and ocrState for this question
							setChosenOption(null);
							setInputData({ key: question.key }, null);

							// Create a new object excluding the question.key
							const newOcrConfidenceAndColor = { ...ocrConfidenceAndColor };
							delete newOcrConfidenceAndColor[question.key];
							setOcrConfidenceAndColorFn(newOcrConfidenceAndColor);
						}
					} else if (responseData[question.key]?.id) {
						const manufacturer = allManufacturers?.find(
							(option: { id: string | number }) =>
								option.id === responseData[question.key].id,
						);
						if (manufacturer) {
							const selectedOption = {
								label: manufacturer.name,
								value: manufacturer.id,
							};
							setChosenOption(selectedOption);
						} else {
							// Manufacturer not found; unset chosenOption and ocrState for this question
							setChosenOption(null);
							setInputData({ key: question.key }, null);

							// Create a new object excluding the question.key
							const newOcrConfidenceAndColor = { ...ocrConfidenceAndColor };
							delete newOcrConfidenceAndColor[question.key];
							setOcrConfidenceAndColorFn(newOcrConfidenceAndColor);
						}
					}
				} else {
					setChosenOption(null);
				}
			}
		};

		// Only call fetchOptions when loading is complete
		if (!isAllManufacturersLoading) {
			fetchOptions();
		}

		return () => {
			isMounted = false;
		};
	}, [
		getOptions,
		question,
		responseData.assetType,
		allManufacturers,
		showAllManufacturers,
		responseData[question.key],
		setInputData,
		ocrConfidenceAndColor, // Added dependency
		setOcrConfidenceAndColorFn,
	]);

	// Handle changes in the Select component
	const handleChange = (selectedOption: {
		label: string;
		value: string;
	}): void => {
		if (selectedOption && selectedOption.value === "expand_all") {
			setShowAllManufacturers(true);
			return;
		}

		// Ensure the selected option is valid
		const isValidOption = options.some(
			(option) => option.value === selectedOption.value,
		);

		if (!isValidOption) {
			setChosenOption(null);
			setInputData({ key: question.key }, null);

			// Create a new object excluding the question.key
			const newOcrConfidenceAndColor = { ...ocrConfidenceAndColor };
			delete newOcrConfidenceAndColor[question.key];
			setOcrConfidenceAndColorFn(newOcrConfidenceAndColor);

			return;
		}

		setChosenOption(selectedOption);

		if (selectedOption) {
			setInputData(
				{ key: question.key },
				{
					id: selectedOption.value,
					name: selectedOption.label,
				},
			);
		} else {
			setInputData({ key: question.key }, null);
		}

		if (
			question.required === "true" &&
			!responseData[question.checkbox?.key] &&
			!selectedOption
		) {
			setFieldErrorFn(question.key, `${question.label} is required`);
		} else {
			setFieldErrorFn(question.key, "");
		}
	};

	// Handle checkbox changes
	const handleCheckboxChange = (
		checkboxQuestion: StringMap,
		checkboxValue: boolean,
	) => {
		setInputData(checkboxQuestion, checkboxValue);
		if (checkboxValue) {
			setInputData({ key: question.key }, null);
			setFieldErrorFn(question.key, "");
		}
	};

	const isLoading = isOptionsLoading || isAllManufacturersLoading;

	return (
		<Row key={question.key}>
			<FormGroup>
				<div
					className={`d-flex align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div className="d-flex flex-row gap-1">
						<Label
							className={`${question.checkbox ? "mb-0" : "single-form-label"}`}
						>
							{question.label}
							{question.required === "true" && (
								<span className="text-error"> *</span>
							)}
						</Label>
						{!hidePreview && (
							<PreviewField
								question={question}
								responseData={responseData}
								fromChatbot={fromChatbot}
							/>
						)}
					</div>
					{question.checkbox && (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={handleCheckboxChange}
							notStandalone={true}
						/>
					)}
				</div>
				<Select
					key={question.key}
					value={chosenOption}
					// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
					styles={customStyles as any}
					options={options}
					isLoading={isLoading}
					isDisabled={
						question.tiedTo ? responseData[question.tiedTo] === true : false
					}
					className={`form-inputs ${
						fieldError[question.key] ? "error-border" : ""
					} ${question.highlighted ? "highlight-input" : ""}`}
					// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
					onChange={handleChange as any}
					components={{ ValueContainer: CustomValueContainer }}
				/>
				{fieldError[question.key] && (
					<div className="error-message text-error d-block">
						{fieldError[question.key]}
					</div>
				)}
				{isError && (
					<div
						className="error-message"
						style={{ display: "block", color: "red", marginTop: "5px" }}
					>
						Error loading manufacturers
					</div>
				)}
			</FormGroup>
		</Row>
	);
};

export { ManufacturerInput };
