import { Card } from "antd";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const LocationInfoCard = (props) => {
	const { info } = props;

	// Define the desired order of the fields
	const fieldOrder = [
		"name",
		"organization",
		"address1",
		"address2",
		"city",
		"state",
		"zip",
		// Add other fields as necessary, in the order you want them to appear
	];

	// Define fields that should not be shown
	const shouldNotShowFields = [
		"is_test_location",
		"is_deleted",
		"external_firebase_id",
		"organization_id",
		"created_timestamp",
		"last_updated",
		"id",
		"is_active",
		"is_demo_location",
	];
	// Add other fields as necessary

	// Function to convert camelCase text to Title Case
	const toTitleCase = (text) => {
		return text
			.replace(/([A-Z])/g, " $1") // insert a space before all caps
			.replace(/_/g, " ") // replacing '_' with a space
			.replace(/^./, (str) => str.toUpperCase()) // capitalize the first letter
			.trim();
	};

	// Check if the value is a displayable primitive
	const isDisplayablePrimitive = (value) => {
		const type = typeof value;
		return (
			value != null &&
			value !== "" &&
			value !== "null" &&
			value !== "undefined" &&
			(type === "string" || type === "number" || type === "boolean")
		);
	};

	// Check if the field should be shown
	const shouldShowField = (key) => {
		return !shouldNotShowFields.includes(key);
	};

	// Sort the keys of the info object according to the predefined fieldOrder
	const sortedKeys = Object.keys(info)
		.filter((key) => isDisplayablePrimitive(info[key]) && shouldShowField(key))
		.sort((a, b) => {
			const indexA = fieldOrder.indexOf(a);
			const indexB = fieldOrder.indexOf(b);
			if (indexA !== -1 && indexB !== -1) {
				// Both keys are in the fieldOrder, so sort normally
				return indexA - indexB;
			}
			if (indexA !== -1) {
				// Only key A is in the fieldOrder, so it comes first
				return -1;
			}
			if (indexB !== -1) {
				// Only key B is in the fieldOrder, so it comes first
				return 1;
			}
			// Neither key is in the fieldOrder, so keep their relative order
			return 0;
		});

	// Generate form groups based on sorted keys
	const generateFormGroups = () => {
		return sortedKeys.map((key) => (
			<Col key={key} xs="12" sm="6" md="4">
				<FormGroup floating>
					<Input id={`location-${key}`} disabled value={info[key].toString()} />
					<Label for={`location-${key}`}>{toTitleCase(key)}</Label>
				</FormGroup>
			</Col>
		));
	};

	return (
		<Card
			title={<span className="fw-bold">Location Information</span>}
			className="p-2 d-flex flex-column align-start gen-info-card-wrapper"
		>
			<Form className="card-body gen-info-card p-0">
				<Row>{generateFormGroups()}</Row>
			</Form>
		</Card>
	);
};

export { LocationInfoCard };
