import { useChatbotStore, useFormStore, usePageStore } from "@stores";
import { DateInput } from "@views/SignedIn/SingleForm/TaggingFormInputs/DateInput";
import { IfOtherManufacturerInput } from "@views/SignedIn/SingleForm/TaggingFormInputs/IfOtherManufacturerInput";
import { ManufacturerInput } from "@views/SignedIn/SingleForm/TaggingFormInputs/ManufacturerInput";
import { NumberInput } from "@views/SignedIn/SingleForm/TaggingFormInputs/NumberInput";
import { SelectInput } from "@views/SignedIn/SingleForm/TaggingFormInputs/SelectInput";
import { TextInput } from "@views/SignedIn/SingleForm/TaggingFormInputs/TextInput";
import React, { useState, useEffect } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";

const SubmittedImageModal = (props) => {
	const {
		responseData,
		setResponseData,
		getOptions,
		setInputData,
		getOptionsLength,
		fromChatbot,
	} = props;
	const [clickZoomIn, setClickZoomIn] = useState(false);
	const [clickZoomOut, setClickZoomOut] = useState(false);
	const { isSidebarOpen } = usePageStore();
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
	const {
		selectedPhotoUrl,
		setSelectedPhotoUrl,
		selectedItem,
		setSelectedItem,
		isPhotoShowing,
		setIsPhotoShowing,
		refToOpen,
		setRefToOpen,
		setPhotoToBeCropped,
		setIsCropShowing,
	} = useFormStore();

	const {
		isPhotoShowing: chatbotIsPhotoShowing,
		selectedPhotoUrl: chatbotSelectedPhotoUrl,
		setIsPhotoShowing: setChatbotIsPhotoShowing,
		selectedItem: chatbotSelectedItem,
		setSelectedItem: setChatbotSelectedItem,
		setIsCropShowing: setChatbotIsCropShowing,
		setPhotoToBeCropped: setChatbotPhotoToBeCropped,
	} = useChatbotStore();

	const removeImage = () => {
		const data = responseData;
		delete data[fromChatbot ? chatbotSelectedItem.key : selectedItem.key];
		setResponseData(data);
		fromChatbot ? setChatbotIsPhotoShowing(false) : setIsPhotoShowing(false);
		if (refToOpen?.current?.props?.id) {
			document.getElementById(refToOpen.current.props.id).click();
			setRefToOpen();
		}
	};

	const switchToCrop = () => {
		if (fromChatbot) {
			setChatbotIsPhotoShowing(false);
			setChatbotIsCropShowing(true);
			setChatbotPhotoToBeCropped(chatbotSelectedPhotoUrl);
		} else {
			setIsPhotoShowing(false);
			setIsCropShowing(true);
			setPhotoToBeCropped(selectedPhotoUrl);
		}
		document.body.style.overflow = "auto";
	};

	const cancel = () => {
		if (fromChatbot) {
			setChatbotIsPhotoShowing(false);
			setChatbotSelectedItem(null);
		} else {
			setIsPhotoShowing(false);
			// The line below ensures that the preview modal on the
			// manuf. field doesn't display the crop / remove buttons
			// because if selectedItem != null, those buttons will display.
			setSelectedItem(null);
		}
	};

	const updateMedia = () => {
		setIsDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	const generateFields = () => {
		return selectedItem.key === "manufacturer" ? (
			<div>
				<ManufacturerInput
					question={selectedItem}
					responseData={responseData}
					getOptions={getOptions}
					setInputData={setInputData}
					setSelectedPhotoUrl={setSelectedPhotoUrl}
					hidePreview={true}
					fromChatbot={fromChatbot}
				/>
				<IfOtherManufacturerInput
					question={{
						type: "text",
						label: "If Other, Enter Manufacturer",
						key: "ifOtherManufacturer",
						tiedTo: "manufacturer",
					}}
					responseData={responseData}
					setInputData={setInputData}
				/>
			</div>
		) : selectedItem.type === "select" ? (
			<SelectInput
				question={selectedItem}
				responseData={responseData}
				setInputData={setInputData}
				getOptions={getOptions}
				getOptionsLength={getOptionsLength}
				setSelectedPhotoUrl={setSelectedPhotoUrl}
				hidePreview={true}
				fromChatbot={fromChatbot}
			/>
		) : selectedItem.type === "date" ? (
			<DateInput
				question={selectedItem}
				responseData={responseData}
				setResponseData={setResponseData}
				setInputData={setInputData}
				hidePreview={true}
			/>
		) : selectedItem.type === "text" ? (
			<TextInput
				question={selectedItem}
				responseData={responseData}
				setInputData={setInputData}
				hidePreview={true}
				fromChatbot={fromChatbot}
			/>
		) : selectedItem.type === "number" ? (
			<NumberInput
				question={selectedItem}
				responseData={responseData}
				setInputData={setInputData}
				hidePreview={true}
				fromChatbot={fromChatbot}
			/>
		) : null;
	};

	return (
		<div>
			<Modal
				isOpen={fromChatbot ? chatbotIsPhotoShowing : isPhotoShowing}
				toggle={cancel}
				centered
				style={{
					left: isDesktop ? (isSidebarOpen ? "130px" : "40px") : "0px",
				}}
			>
				<ModalHeader toggle={cancel}></ModalHeader>
				<ModalBody>
					<TransformWrapper initialScale={1}>
						{({ zoomIn, zoomOut }) => (
							<React.Fragment>
								<div className="submitted-image-container">
									<TransformComponent>
										<img
											src={
												fromChatbot ? chatbotSelectedPhotoUrl : selectedPhotoUrl
											}
											alt="img"
											className="image-submitted"
										/>
									</TransformComponent>
									<div className="tools">
										<button
											className={`btn-zoom ${
												clickZoomOut ? "btn-zoom-pop" : ""
											}`}
											onClick={() => {
												setClickZoomOut(true);
												zoomOut();
											}}
											onAnimationEnd={() => setClickZoomOut(false)}
										>
											<FiMinus />
										</button>
										<button
											className={`btn-zoom ${
												clickZoomIn ? "btn-zoom-pop" : ""
											}`}
											onClick={() => {
												setClickZoomIn(true);
												zoomIn();
											}}
											onAnimationEnd={() => setClickZoomIn(false)}
										>
											<FiPlus />
										</button>
									</div>
								</div>
							</React.Fragment>
						)}
					</TransformWrapper>
				</ModalBody>
				<ModalFooter>
					{(
						fromChatbot
							? chatbotSelectedItem?.type === "image"
							: selectedItem?.type === "image"
					) ? (
						<Row>
							<Col>
								<Button onClick={switchToCrop} hidden={!props.switchToCrop}>
									Crop
								</Button>
							</Col>
							<Col>
								<Button
									color="dark"
									onClick={removeImage}
									hidden={!props.removeImage}
								>
									Retake
								</Button>
							</Col>
						</Row>
					) : (fromChatbot ? chatbotSelectedItem?.key : selectedItem?.key) ? (
						<div className="w-100">{generateFields()}</div>
					) : null}
				</ModalFooter>
			</Modal>
		</div>
	);
};
export { SubmittedImageModal };
