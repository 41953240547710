import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { FormGroup, Label, Row } from "reactstrap";
import { CheckboxInput } from "./CheckboxInput";
import { PreviewField } from "./PreviewField";

const AMDOrgInputs = ({
	question,
	responseData,
	setInputData,
	flags,
	equipmentAreas,
	hidePreview,
	organization,
	dataType,
}) => {
	const location = useLocation();
	const formId = location?.pathname.split("/").pop();

	const [options, setOptions] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let data = [];
				switch (dataType) {
					case "ranks":
						data = flags.collections["asset-ranks"].map((area) => area.name);
						break;
					case "classes":
						data = flags.collections["asset-classes"].map((area) => area.name);
						break;
					case "numbers":
						data = flags.collections["asset-numbers"].map((area) => area.name);
						break;
					case "propertyIds":
						data = flags.collections["property-ids"].map((area) => area.name);
						break;
					case "assetLocation":
						data = equipmentAreas.map((area) => area.name);
						break;
					default:
						console.error("Invalid data type provided.");
				}

				// Map and sort the fetched data
				const sortedOptions = data
					.map((item) => ({
						label: item,
						value: item,
					}))
					.sort((a, b) => a.label.localeCompare(b.label));

				setOptions(sortedOptions);
			} catch (error) {
				console.error(`Error fetching ${dataType}:`, error);
			}
		};

		fetchData();
	}, [organization, formId, dataType]);

	const handleChange = (newValue) => {
		let value = newValue ? newValue.value : "";
		// If the table is equipment-area, we want to save object of the area and not just the name
		if (question.table === "equipment-area") {
			value = equipmentAreas.find((area) => area.name === value);
			setInputData(question, value);
		} else {
			setInputData(question, value);
		}
	};

	const chosenOption = responseData[question.key]
		? {
				label:
					question.table === "equipment-area"
						? responseData[question.key].name
						: responseData[question.key],
				value:
					question.table === "equipment-area"
						? responseData[question.key].name
						: responseData[question.key],
			}
		: null;

	return (
		<Row key={question.key}>
			<FormGroup>
				<div
					className={`d-flex align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div>
						<Label
							className={`${question.checkbox ? "mb-0" : "single-form-label"}`}
						>
							{question.label}
							{question.required === "true" && (
								<span style={{ color: "red" }}> *</span>
							)}
						</Label>
						{!hidePreview ? (
							<PreviewField question={question} responseData={responseData} />
						) : null}
					</div>
					{question.checkbox ? (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={setInputData}
							notStandalone={true}
						/>
					) : null}
				</div>
				{dataType === "numbers" ? (
					<CreatableSelect
						key={question.key}
						value={chosenOption}
						onChange={handleChange}
						options={options}
						placeholder={`Select or type ${
							dataType.charAt(0).toUpperCase() + dataType.slice(1)
						}`}
						isDisabled={
							question.tiedTo ? responseData[question.tiedTo] === true : false
						}
						className={`${
							question.highlighted ? "highlight-input" : ""
						} 'form-inputs'`}
						isClearable
						formatCreateLabel={(inputValue) => `${inputValue}`}
					/>
				) : (
					<Select
						key={question.key}
						value={chosenOption}
						onChange={handleChange}
						options={options}
						placeholder={`Select ${
							dataType.charAt(0).toUpperCase() + dataType.slice(1)
						}`}
						isDisabled={
							question.tiedTo ? responseData[question.tiedTo] === true : false
						}
						className={`${
							question.highlighted ? "highlight-input" : ""
						} 'form-inputs'`}
						isClearable
					/>
				)}
			</FormGroup>
		</Row>
	);
};

export default AMDOrgInputs;
