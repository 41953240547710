import { useDashboardSummary } from "@/hooks/chatbotQueries";
import { useChatbotStore } from "@/stores";
import {
	MESSAGE_EVENTS,
	getMessages,
	sendFile,
	sendMessage,
} from "@services/alfred-service";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from "react";
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";
import { useLocation } from "react-router-dom";
import { useGraph } from "./graphContext";

const FAILED_REQUEST_MESSAGE =
	"Sorry, it seems I am having some technical difficulties at the moment. Please try your question again later.";

const ChatbotContext = createContext(null);

export const ACTION_TYPE = {
	ADD_MESSAGE: "ADD_MESSAGE",
	REMOVE_WIDGET: "REMOVE_WIDGET",
	REMOVE_LAST_WIDGET: "REMOVE_LAST_WIDGET",
	CLEAR_HISTORY: "CLEAR_HISTORY",
};

const DEFAULT_MESSAGE =
	"Hi, I'm FacilityAI, your personalized Facility Manager Assistant!\n\nAsk me anything related to your facilities and assets or choose an option below!";

const LOCATION_SPECIFIC_MESSAGE =
	"Ask me anything related to your facilities and assets or choose an option below!";

const INITIAL_MESSAGE_OVERVIEW =
	"Hello, here is an overview of your facilities and assets:\n\n";

function chatbotHistoryReducer(
	state: string | any[],
	action: { type: string; payload: { message: any } },
) {
	if (action.type === ACTION_TYPE.ADD_MESSAGE) {
		const newState = [...state, action.payload.message];
		return newState;
	}
	if (action.type === ACTION_TYPE.REMOVE_WIDGET) {
		const newState = state.slice(1);
		return [
			createChatBotMessage(
				location?.pathname?.includes("location") ||
					location?.pathname?.includes("asset")
					? LOCATION_SPECIFIC_MESSAGE
					: DEFAULT_MESSAGE,
				{},
			),
			...newState,
		];
	}
	if (action.type === ACTION_TYPE.CLEAR_HISTORY) {
		return [];
	}
	if (action.type === ACTION_TYPE.REMOVE_LAST_WIDGET) {
		return [...state.slice(0, -1), action.payload.message];
	}
}

function ChatbotHistoryProvider({ children }: any) {
	const location = useLocation() as any;
	const { addGraph } = useGraph() as any;
	const { setCurrentState } = useChatbotStore() as any;
	const [messageHistory, dispatch] = useReducer(
		chatbotHistoryReducer as any,
		[] as any,
	) as any;
	const { summary } = useDashboardSummary();
	const memoizedSummary = useMemo(() => summary, [summary]);
	const memoizedMessageHistory = useMemo(
		() => [...messageHistory],
		[messageHistory],
	);

	useEffect(() => {
		if (!location.pathname.includes("assets")) {
			dispatch({ type: ACTION_TYPE.CLEAR_HISTORY });
			dispatch({
				type: ACTION_TYPE.ADD_MESSAGE,
				payload: {
					message: createChatBotMessage(
						INITIAL_MESSAGE_OVERVIEW + memoizedSummary,
						{
							widget: "InitialMessageOptions",
						},
					),
				},
			});
		}
	}, [memoizedSummary, location]);

	const [, setOptionSelected] = useState(false);
	const initialMessageSet = useRef(false);

	useEffect(() => {
		setCurrentState({ messages: memoizedMessageHistory });
	}, [memoizedMessageHistory, setCurrentState]);

	const handleUserMessage = useCallback(
		async (message: string, location: any, classifier: any) => {
			if (!message) return;

			const userMessage = createClientMessage(message, {
				withAvatar: true,
			} as any);

			dispatch({
				type: ACTION_TYPE.ADD_MESSAGE,
				payload: {
					message: userMessage,
				},
			});

			const botResponse = await getBotResponse(message, location, classifier);

			const botMessage = createChatBotMessage(botResponse, {
				withAvatar: true,
			} as any);

			dispatch({
				type: ACTION_TYPE.ADD_MESSAGE,
				payload: {
					message: botMessage,
				},
			});
			return botMessage;
		},
		[dispatch],
	);

	// send user message to backend and to generate bot response.
	// response may be text or a job ID (table lookup/graph generation)
	// text is returned immediately, job ID is used to get the result after
	// it completes via a server sent event; which may include a chart and text
	const getBotResponse = async (
		message: any,
		location: string | undefined,
		classifier: string | undefined,
	) => {
		try {
			const result = await sendMessage(message, location, classifier);
			const responseText = await result.text();
			const intermediateData = JSON.parse(responseText);
			const data =
				typeof intermediateData === "string"
					? JSON.parse(intermediateData)
					: intermediateData;
			if (data.response) {
				const graphData = data.graph_data;
				if (Array.isArray(graphData)) {
					graphData.forEach((graph) => {
						addGraph(graph, data.id);
					});
					const result = {
						text: data.response,
						graph: true,
					};
					return result;
				}
				if (graphData) {
					await addGraph(graphData, data.id);
					const result = {
						text: data.response,
						graph: true,
					};
					return result;
				}
				return data.response;
			}

			if (data.id) {
				const eventSource = getMessages(data.id);

				eventSource.addEventListener(MESSAGE_EVENTS.GRAPH, ({ data }) =>
					addGraph(data),
				);

				return new Promise((resolve) => {
					eventSource.addEventListener(
						MESSAGE_EVENTS.MESSAGE,
						async ({ data }) => {
							const { response, graph } = JSON.parse(await data);
							const result = {
								text: response,
								graph: graph === true,
							};
							return resolve(result);
						},
					);
				});
			}
		} catch (_error) {
			return new Promise({ response: FAILED_REQUEST_MESSAGE } as any);
		}
	};

	const handleFileDrop = useCallback(
		async (file: Blob) => {
			const userMessage = createClientMessage(`Dropped a file: ${file?.name}`, {
				withAvatar: true,
			} as any);

			dispatch({
				type: ACTION_TYPE.ADD_MESSAGE,
				payload: { message: userMessage },
			});

			const response = await getBotFileResponse(file);

			dispatch({
				type: ACTION_TYPE.ADD_MESSAGE,
				payload: { message: response },
			});
			return response;
		},
		[dispatch],
	);

	// Send file to backend and generate bot response.
	const getBotFileResponse = async (file: string | Blob) => {
		try {
			const result = await sendFile(file);
			const data = await result.json();
			if (data.response) {
				return data.response;
			}

			if (data.id) {
				const eventSource = getMessages(data.id);

				return new Promise((resolve, reject) => {
					eventSource.addEventListener(
						MESSAGE_EVENTS.MESSAGE,
						async (event) => {
							try {
								const { response } = JSON.parse(event.data);
								const result = {
									text: response,
								};
								eventSource.close();
								resolve(result);
							} catch (error) {
								reject(error);
							}
						},
					);

					eventSource.onerror = (error) => {
						console.error("EventSource error:", error);
						console.error("EventSource readyState:", eventSource.readyState);
						if (eventSource.readyState === EventSource.CONNECTING) {
							console.error("EventSource is still connecting, will retry...");
						} else {
							console.error("EventSource failed to connect");
							eventSource.close(); // Close the EventSource here
						}
					};
				});
			}
		} catch (error) {
			console.error(error);
			return FAILED_REQUEST_MESSAGE;
		}
	};

	const saveDataCaptureState = useCallback((messages: any[]) => {
		if (!initialMessageSet.current) {
			initialMessageSet.current = true;
			console.log("removing initial widget");
			dispatch({ type: ACTION_TYPE.REMOVE_WIDGET });
		}
		messages.forEach((message: { action: any; message: any }) => {
			switch (message.action) {
				case "removeWidget":
					dispatch({
						type: ACTION_TYPE.REMOVE_LAST_WIDGET,
						payload: { message: message.message },
					});
					break;
				case "add":
					dispatch({
						type: ACTION_TYPE.ADD_MESSAGE,
						payload: { message: message.message },
					});
					break;
				default:
					break;
			}
		});
	}, []);

	const value = useMemo(
		() => ({
			messageHistory: memoizedMessageHistory,
			handleUserMessage,
			setOptionSelected,
			handleFileDrop,
			saveDataCaptureState,
			dispatch,
		}),
		[
			memoizedMessageHistory,
			handleUserMessage,
			setOptionSelected,
			handleFileDrop,
			saveDataCaptureState,
			dispatch,
		],
	);
	return (
		<ChatbotContext.Provider value={value as any}>
			{children}
		</ChatbotContext.Provider>
	);
}
function useChatbotHistory() {
	const context = useContext(ChatbotContext);
	if (context === undefined) {
		throw new Error("useChatbot must be used within a ChatbotProvider");
	}
	return context;
}

export { ChatbotHistoryProvider, useChatbotHistory };
