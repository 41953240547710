export const nextPage = (
	setAlertModal,
	currentPage,
	setIsLoading,
	responseData,
	closeAlertModal,
	inputs,
	setCurrentPage,
	isPage1Valid,
	pageValidation,
) => {
	const {
		validateAndSetPage1,
		validateAndSetPage2,
		validateAndSetPage3,
		validateAndSetPage4,
		validateAndSetPage5,
		validateAndSetPage6,
		validateAndSetPage7,
		validateAndSetPage8,
		validateAndSetPage9,
	} = pageValidation;

	if (!navigator.onLine) {
		setAlertModal({
			show: true,
			title: "Connection Lost!",
			body: "Please try again when you have connected to the internet",
		});
		setTimeout(closeAlertModal, 2500);
		return;
	}
	if (currentPage === 0) {
		setIsLoading(false);
		if (responseData.location) {
			validateAndSetPage1();
		} else if (!isPage1Valid) {
			setAlertModal({
				show: true,
				title: "Set Location",
				body: "Please select a location for this form.",
			});
			setTimeout(closeAlertModal, 2500);
			return;
		}
	} else if (currentPage === 1) {
		validateAndSetPage2();
	} else if (currentPage === 2) {
		validateAndSetPage3();
	} else if (currentPage === 3) {
		validateAndSetPage4();
	} else if (currentPage === 4) {
		validateAndSetPage5();
	} else if (currentPage === 5) {
		validateAndSetPage6();
	} else if (currentPage === 6) {
		validateAndSetPage7();
	} else if (currentPage === 7) {
		validateAndSetPage8();
	} else if (currentPage === 8) {
		validateAndSetPage9();
	}

	if (currentPage < inputs.length - 1) {
		setCurrentPage(currentPage + 1);
	}
};
