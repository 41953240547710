import { auth } from "@/assets/services/auth-service";
import { jsonToBase64 } from "@/services/location-service";
import {
	getSelfOrganizationLocations,
	selfOrganizationAssetTypes,
} from "@/services/organization-service";
import { chartColors } from "@/utils/chartColors";
import { saveAs } from "file-saver";

// Predictive Spend Global Component: PredictiveSpendNew.tsx

// Initialize asset types and locations
export const initializeAssetTypesAndLocations = async (dispatch) => {
	try {
		const [assetTypesSnapshot, locationsSnapshot] = await Promise.all([
			selfOrganizationAssetTypes(),
			getSelfOrganizationLocations(),
		]);

		dispatch({
			type: "SET_ASSET_TYPES",
			payload: assetTypesSnapshot.map((item) => item.name),
		});

		const locations = locationsSnapshot
			.map((row) => {
				if (row.is_test_location) return null;
				let label = `${row.address1}, ${row.city}, ${row.state} ${row.zip}`;
				return {
					id: row.id,
					label,
					data: row,
				};
			})
			.filter(Boolean);
		dispatch({ type: "SET_LOCATIONS", payload: locations });
	} catch (error) {
		console.error("Error fetching asset types and locations:", error);
	}
};

// Fetch assets based on current state
export const fetchAssets = async (state, dispatch) => {
	try {
		dispatch({ type: "SET_CHART_LOADING", payload: true });

		const params = new URLSearchParams();
		if (state.startDate) params.set("start_date", state.startDate);
		if (state.endDate) params.set("end_date", state.endDate);
		if (state.checkedAssetTypes.length > 0) {
			params.set(
				"checked_asset_types",
				jsonToBase64(state.checkedAssetTypes.map((item) => item.id)),
			);
		} else {
			params.set("include_all_asset_types", "true");
		}

		if (state.checkedLocations.length > 0) {
			params.set(
				"locations",
				jsonToBase64(state.checkedLocations.map((location) => location.id)),
			);
		}

		if (state.checkedStates.length > 0) {
			params.set(
				"states",
				jsonToBase64(state.checkedStates.map((item) => item.value)),
			);
		}

		if (state.healthScoreFilterType) {
			params.set("health_score_filter_type", state.healthScoreFilterType.value);
		}

		if (state.healthScoreFilterValue) {
			params.set(
				"health_score_filter_value",
				state.healthScoreFilterValue.value,
			);
		}

		if (state.years) {
			params.set("year_extension", state.years);
		}

		const baseURL = import.meta.env.VITE_ALFRED_SERVICE_URL;
		const url = `${baseURL}/organization/self/pred_spend_assets?${params.toString()}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});

		let responsejson = await response.json();
		const lastYear = new Date().getFullYear() + state.years;
		responsejson = responsejson.filter(
			(asset) => asset.replacementYear < lastYear,
		);

		const assetTypeArr = responsejson
			.map((asset) => asset.assetType)
			.filter((v, i, a) => a.indexOf(v) === i);

		dispatch({ type: "SET_CHART_ASSETS", payload: responsejson });
		dispatch({ type: "SET_EXISTING_ASSET_TYPES", payload: assetTypeArr });
		dispatch({ type: "SET_NUM_ASSET_TYPES", payload: assetTypeArr.length });
		dispatch({ type: "SET_ORIGINAL_ASSETS", payload: responsejson });
		dispatch({ type: "SET_CHART_LOADING", payload: false });
		dispatch({ type: "SET_LOADING", payload: false });
	} catch (error) {
		console.error("Failed to fetch data", error);
	}
};

const convertAssetsToChartData = (
	assets,
	isYAxisReplCost,
	legendSelectedAssetTypes,
	years,
	dispatch,
) => {
	const labels = Array.from(
		{ length: years },
		(_, i) => new Date().getFullYear() + i,
	);
	const startYear = labels[0];
	const endYear = labels[labels.length - 1];
	const numYears = endYear - startYear + 1;
	const assetTypeData = {};
	const cumulativeAssetTypeData = {};
	let replCostArr = new Array(numYears).fill(0);
	let colorDict = {};

	// Organize assets by type
	assets.forEach((asset) => {
		if (!asset) {
			return;
		}
		let { assetType, replacementYear, avgReplCostUSD } = asset;
		if (!assetTypeData[assetType]) {
			assetTypeData[assetType] = new Array(numYears).fill(0);
			cumulativeAssetTypeData[assetType] = new Array(numYears).fill(0);
		}
		// replacement year below start year should be set to start year
		if (replacementYear < startYear) {
			replacementYear = startYear;
		}
		const yearIndex = replacementYear - startYear;
		if (yearIndex >= 0 && yearIndex < numYears) {
			if (isYAxisReplCost) {
				if (avgReplCostUSD) {
					assetTypeData[assetType][yearIndex] += avgReplCostUSD;
					for (let i = yearIndex; i < numYears; i++) {
						cumulativeAssetTypeData[assetType][i] += avgReplCostUSD;
					}
				}
			} else {
				assetTypeData[assetType][yearIndex]++;
				for (let i = yearIndex; i < numYears; i++) {
					cumulativeAssetTypeData[assetType][i]++;
				}
			}
			if (avgReplCostUSD && legendSelectedAssetTypes) {
				replCostArr[yearIndex] += avgReplCostUSD;
			}
		}
	});

	// Convert to chart data format
	const chartDataSets = [];
	const cumulativeAssetTypeDataset = [];

	const backgroundColors = COLOR_DICT.assetTypeColors;

	let colorIndex = 0;
	for (const [type, data] of Object.entries(assetTypeData)) {
		// Apply the same background color to each data point in the dataset
		const backgroundColorArray = new Array(numYears).fill(
			backgroundColors[colorIndex % backgroundColors.length],
		);
		chartDataSets.push({
			label: type,
			data: data,
			backgroundColor: backgroundColorArray,
			borderRadius: 6,
			barThickness: 40,
			borderWidth: 4,
			borderColor: darkenColor(backgroundColorArray[0], 30),
			dragData: false,
			hoverBackgroundColor: "white",
			hidden: !legendSelectedAssetTypes.includes(type),
		});
		colorDict[type] = backgroundColorArray[0];
		cumulativeAssetTypeDataset.push({
			label: type,
			data: cumulativeAssetTypeData[type],
			backgroundColor: backgroundColorArray,
			borderRadius: 6,
			barThickness: 40,
			borderWidth: 4,
			borderColor: darkenColor(backgroundColorArray[0], 30),
			dragData: false,
			hoverBackgroundColor: "white",
			hidden: !legendSelectedAssetTypes.includes(type),
		});
		colorIndex++;
	}

	// Dispatch actions to update the necessary state
	dispatch({ type: "SET_ASSET_TYPE_COLORS", payload: colorDict });
	dispatch({
		type: "SET_CHART_DATA",
		payload: {
			assetCounts: chartDataSets,
			cumulativeAssetTypeDataset,
			replCosts: replCostArr,
		},
	});
	return {
		assetCounts: chartDataSets,
		cumulativeAssetTypeDataset,
		replCosts: replCostArr,
	};
};

// Chart Component Services: PredSpendChart.tsx

const COLOR_DICT = {
	budget: "#0287b2",
	replacementCost: "#bf1e2d",
	assetTypeColors: chartColors,
};

const convertDatasetToCumulative = (dataset) => {
	// convert dataset array to cumulative array meaning each element is the sum of all elements before it
	const cumulativeDataset = [];
	dataset.forEach((element, i) => {
		if (typeof element == "string" || typeof element == "number") {
			if (i === 0) {
				cumulativeDataset.push(Number(element));
			} else {
				cumulativeDataset.push(
					Number(element) + Number(cumulativeDataset[i - 1]),
				);
			}
		} else {
			return dataset;
		}
	});
	return cumulativeDataset;
};

function calculateExactFitToBudgetAssets(assets, budget, years, dispatch) {
	const newAssets = JSON.parse(JSON.stringify(assets)); // Deep copy of assets

	const currentYear = new Date().getFullYear();
	const budgetPerYear = budget.reduce((acc, val, i) => {
		acc[currentYear + i] = val;
		return acc;
	}, {});
	const startYear = new Date().getFullYear();

	let replacementCostsPerYear = {};

	for (let year = startYear; year < startYear + years; year++) {
		replacementCostsPerYear[year] = 0;
	}

	// Add a flag to each asset to track if it has been moved
	newAssets.forEach((asset) => {
		let year = asset.replacementYear;
		if (year < startYear) {
			year = startYear;
			asset.replacementYear = year;
		}

		if (!isNaN(Number(asset.avgReplCostUSD))) {
			replacementCostsPerYear[year] += asset.avgReplCostUSD;
		}
		asset.moved = false;
	});

	// loop over years where budget is over replacement cost
	for (let year = startYear; year < startYear + years; year++) {
		if (replacementCostsPerYear[year] >= budgetPerYear[year]) {
			continue;
		}
		// find next year assets and move them to current year until budget is met
		const nextYearAssets = newAssets.filter(
			(asset) => asset.replacementYear === year + 1,
		);
		// sort by health score in ascending order
		nextYearAssets.sort((a, b) => {
			return a.healthScore - b.healthScore;
		});
		// loop over assets in the year
		for (const asset of nextYearAssets) {
			if (replacementCostsPerYear[year] >= budgetPerYear[year]) {
				break;
			}
			let replCost = 0;
			if (!isNaN(Number(asset.avgReplCostUSD))) {
				replCost = asset.avgReplCostUSD;
			}
			replacementCostsPerYear[year] += replCost;
			replacementCostsPerYear[year + 1] -= replCost;
			asset.replacementYear = year;
		}
	}

	const premArr = [0];
	let premCost;

	// loop over years
	for (let year = startYear; year < startYear + years; year++) {
		if (!replacementCostsPerYear[year]) {
			replacementCostsPerYear[year] = 0;
		}
		// loop over assets
		const yearAssets = newAssets.filter(
			(asset) => asset.replacementYear === year,
		);
		// sort by non moved assets first then by lowest health score
		yearAssets.sort((a, b) => {
			if (a.moved === b.moved) {
				// If both are moved or non-moved, sort by healthScore in descending order
				return b.healthScore - a.healthScore;
			}
			// Otherwise, sort non-moved before moved
			return a.moved - b.moved;
		});

		let leftoverReplCost = 0;
		let leftoverCount = 0;
		premCost = 0;

		// loop over assets in the year
		for (const asset of yearAssets) {
			if (replacementCostsPerYear[year] > budgetPerYear[year]) {
				let nextYear = year + 1;
				asset.replacementYear = nextYear;
				let replCost = 0;
				if (!isNaN(Number(asset.avgReplCostUSD))) {
					replCost = asset.avgReplCostUSD;
				}

				// calculate reactive premium data
				if (!asset.moved) {
					premCost += Math.round(replCost * 0.2);
				}

				replacementCostsPerYear[year] -= replCost;
				replacementCostsPerYear[nextYear] += replCost;
				if (
					!replacementCostsPerYear[nextYear] &&
					replacementCostsPerYear[nextYear] !== 0
				) {
					leftoverReplCost += replCost;
					leftoverCount++;
					asset.replacementYear = null;
				}
				asset.moved = true;
			}
		}
		premArr.push(premCost);
		dispatch({
			type: "SET_LEFTOVER",
			payload: { cost: leftoverReplCost, count: leftoverCount },
		});
	}

	return {
		newAssets: newAssets.filter((asset) => asset.replacementYear !== null),
		premData: premArr,
	};
}

const calculateBudgetShortage = (
	assets,
	reactivePremiumData,
	years,
	budget,
) => {
	const labels = Array.from(
		{ length: years },
		(_, i) => new Date().getFullYear() + i,
	);
	let budgetShortage;
	if (assets) {
		const replCosts = labels.map((year) => {
			return assets
				.filter((asset) => asset.replacementYear === year)
				.reduce((acc, asset) => {
					const replCost = Number(asset.avgReplCostUSD);
					if (isNaN(replCost)) {
						return acc;
					}
					return acc + replCost;
				}, 0);
		});
		budgetShortage = budget.map((val, i) => {
			return val - replCosts[i];
		});
	} else {
		if (reactivePremiumData) {
			budgetShortage = budget.map((val, i) => {
				return val - reactivePremiumData[i];
			});
		}
	}
	return budgetShortage;
};

export const handleStrategyChange = (
	state,
	chartState,
	dispatch,
	strategyAssetsRef,
) => {
	const { originalAssets, years, budget, chartAssets: assets } = state;
	const { strategy, isYAxisReplCost, legendSelectedAssetTypes } = chartState;
	const { assetCounts, cumulativeAssetTypeDataset, replCosts } =
		convertAssetsToChartData(
			originalAssets,
			isYAxisReplCost,
			legendSelectedAssetTypes,
			years,
			dispatch,
		);
	const labels = Array.from(
		{ length: years },
		(_, i) => new Date().getFullYear() + i,
	);
	let newData;
	const budgetDataset = {
		type: "line",
		label: "Budget USD",
		borderColor: COLOR_DICT.budget,
		backgroundColor: COLOR_DICT.budget,
		borderWidth: 4,
		data: budget,
		order: -1, // Ensure the line is drawn on top of the bars
		dragData: true,
		pointRadius: 5,
		pointBackgroundColor: COLOR_DICT.budget,
		tension: 0.3,
		yAxisID: "y2",
	};
	const budgetExists = budget.every((val) => !!val);

	if (strategy.value === "Cumulative Do Nothing") {
		const cumulativeReplCosts = convertDatasetToCumulative(replCosts);
		const cumulativeData = {
			labels: chartState.data.labels,
			datasets: [...cumulativeAssetTypeDataset],
		};
		cumulativeData.datasets.push(budgetDataset);
		const reactivePremium = getReactivePremium(
			replCosts,
			assets,
			labels,
			budget,
			strategy,
			dispatch,
			years,
		);
		cumulativeData.datasets.push(reactivePremium);

		newData = cumulativeData;
		dispatch({ type: "SET_IS_CUMULATIVE", payload: true });
		dispatch({
			type: "SET_MAX_DATA_VALUE",
			payload: Math.max(
				...cumulativeReplCosts,
				...reactivePremium.data,
				...budget,
			),
		});
	} else if (strategy.value === "Best Fit to Budget") {
		const newAssets = calculateBestFitToBudgetAssets(
			JSON.parse(JSON.stringify(originalAssets)),
			budget,
		);
		strategyAssetsRef.current = newAssets;
		if (budget.every((val) => !!val)) {
			dispatch({
				type: "SET_BUDGET_SHORTAGE",
				payload: calculateBudgetShortage(newAssets, null, years, budget),
			});
		}
		const { assetCounts, cumulativeAssetTypeDataset, replCosts } =
			convertAssetsToChartData(
				newAssets,
				isYAxisReplCost,
				legendSelectedAssetTypes,
				years,
				dispatch,
			);
		const reactivePremium = getReactivePremium(
			replCosts,
			newAssets,
			labels,
			budget,
			strategy,
			dispatch,
			years,
		);
		newData = {
			labels: chartState.data.labels,
			datasets: [...assetCounts, reactivePremium],
		};
		if (budgetExists) {
			newData.datasets.push(budgetDataset);
		}
		dispatch({
			type: "SET_MAX_DATA_VALUE",
			payload: Math.max(...replCosts, ...reactivePremium.data, ...budget),
		});
		dispatch({ type: "SET_STRATEGY_ASSETS", payload: newAssets });
	} else if (strategy.value === "Exact Fit to Budget") {
		const { newAssets, premData } = calculateExactFitToBudgetAssets(
			JSON.parse(JSON.stringify(originalAssets)),
			budget,
			years,
			dispatch,
		);
		strategyAssetsRef.current = newAssets;
		if (budgetExists) {
			dispatch({
				type: "SET_BUDGET_SHORTAGE",
				payload: calculateBudgetShortage(newAssets, null, years, budget),
			});
		}
		const { assetCounts, cumulativeAssetTypeDataset, replCosts } =
			convertAssetsToChartData(
				newAssets,
				isYAxisReplCost,
				legendSelectedAssetTypes,
				years,
				dispatch,
			);

		const reactivePremiumData = replCosts.map((val, i) => {
			return val + premData[i];
		});
		const reactivePremiumDataset = {
			type: "line",
			label: "Reactive Replacement Premium",
			data: reactivePremiumData,
			borderColor: "#FF8C00",
			backgroundColor: "#FF8C00",
			borderWidth: 3,
			order: -1,
			tension: 0.3,
			dragData: false,
			yAxisID: "y2",
		};
		newData = {
			labels: chartState.data.labels,
			datasets: [...assetCounts, reactivePremiumDataset],
		};
		if (budgetExists) {
			newData.datasets.push(budgetDataset);
		}
		dispatch({
			type: "SET_MAX_DATA_VALUE",
			payload: Math.max(...replCosts, ...reactivePremiumData, ...budget),
		});
		dispatch({ type: "SET_STRATEGY_ASSETS", payload: newAssets });
	} else {
		dispatch({
			type: "SET_BUDGET_SHORTAGE",
			payload: calculateBudgetShortage(originalAssets, null, years, budget),
		});
		const budgetDataset = {
			type: "line",
			label: "Budget USD",
			borderColor: COLOR_DICT.budget,
			backgroundColor: COLOR_DICT.budget,
			borderWidth: 4,
			data: budget,
			order: -1, // Ensure the line is drawn on top of the bars
			dragData: true,
			pointRadius: 5,
			pointBackgroundColor: COLOR_DICT.budget,
			tension: 0.3,
			yAxisID: "y2",
		};
		newData = {
			labels,
			datasets: [...assetCounts],
		};
		if (budgetExists) {
			newData.datasets.push(budgetDataset);
		}
		dispatch({ type: "SET_REACTIVE_PREMIUM_DATA", payload: null });
		dispatch({
			type: "SET_MAX_DATA_VALUE",
			payload: Math.max(...replCosts, ...budget),
		});
	}

	if (strategy.value !== "Exact Fit to Budget" && chartState.leftover) {
		dispatch({ type: "SET_LEFTOVER", payload: null });
	}

	if (!chartState.isYAxisReplCost) {
		newData.datasets = newData.datasets.filter(
			(dataset) => dataset.type !== "line",
		);
	}

	dispatch({ type: "SET_DATA", payload: newData });
	dispatch({ type: "SET_STRATEGY", payload: strategy });
	dispatch({ type: "SET_CHART_LOADING", payload: false });
};

export const getReactivePremium = (
	replCosts,
	assets,
	labels,
	budget,
	strategy,
	dispatch,
	years,
) => {
	let result = {
		type: "line",
		label: "Reactive Replacement Premium",
		data: [],
		borderColor: "#FF8C00",
		backgroundColor: "#FF8C00",
		borderWidth: 3,
		order: -1,
		tension: 0.3,
		dragData: false,
		yAxisID: "y2",
	};

	let emergencyReplData = { count: 0, cost: 0 };
	const cumulativeReplCosts = convertDatasetToCumulative(replCosts);

	labels.forEach((year, i) => {
		const prevYear = year - 1;
		let currentYearCost = replCosts[year - labels[0]];
		if (!replCosts[prevYear - labels[0]]) {
			result.data.push(currentYearCost);
			return;
		}
		const prevYearBudget = budget[i - 1] ?? 0;

		if (strategy.value === "Cumulative Do Nothing") {
			result.data.push(currentYearCost + replCosts[prevYear - labels[0]] * 0.2);
		} else if (
			strategy.value === "Best Fit to Budget" ||
			strategy.value === "Exact Fit to Budget"
		) {
			const prevYearCost = replCosts[i - 1];
			if (prevYearBudget > prevYearCost) {
				result.data.push(currentYearCost);
			} else {
				const difference = prevYearCost - prevYearBudget;
				if (difference > 0) {
					result.data.push(currentYearCost + difference * 0.2);
				}
			}
		}

		let reactivePremiumVal = 0;
		assets.forEach((asset) => {
			if (asset.replacementYear === prevYear) {
				let replCost = Number(asset.avgReplCostUSD);
				if (!isNaN(replCost)) {
					replCost = Math.round(replCost * 0.2);
					reactivePremiumVal += replCost;
					emergencyReplData.count++;
					emergencyReplData.cost += replCost;
				}
			}
		});

		reactivePremiumVal += currentYearCost;
		emergencyReplData.cost += replCosts[prevYear - labels[0]];
	});

	if (strategy.value === "Cumulative Do Nothing") {
		result.data = convertDatasetToCumulative(result.data);
	}

	dispatch({
		type: "SET_BUDGET_SHORTAGE",
		payload: calculateBudgetShortage(null, result.data, years, budget),
	});
	dispatch({ type: "SET_REACTIVE_PREMIUM_DATA", payload: result.data });

	return result;
};

export const onBudgetDragEnd = (
	e,
	datasetIndex,
	index,
	value,
	budgetType,
	budgetRef,
	strategyRef,
	originalAssets,
	dispatch,
	budgetShortage,
	assets,
) => {
	if (budgetType.value === "static") {
		dispatch({
			type: "SET_BUDGET_TYPE",
			payload: { value: "varied", label: "Varied Budget" },
		});
	}

	const newBudget = [...budgetRef.current];
	newBudget[index] = value;

	dispatch({ type: "SET_BUDGET", payload: newBudget });
	const newBudgetShortage = calculateBudgetShortage(
		assets,
		null,
		newBudget.length,
		newBudget,
	);
	dispatch({ type: "SET_BUDGET_SHORTAGE", payload: newBudgetShortage });

	if (strategyRef.current.value === "Best Fit to Budget") {
		const newAssets = calculateBestFitToBudgetAssets(originalAssets, newBudget);
		dispatch({ type: "SET_ASSETS", payload: newAssets });
	} else if (strategyRef.current.value === "Exact Fit to Budget") {
		const { newAssets } = calculateExactFitToBudgetAssets(
			originalAssets,
			newBudget,
			newBudget.length,
			dispatch,
		);
		dispatch({ type: "SET_ASSETS", payload: newAssets });
	}
};

// for best fit to budget strategy
export const calculateBestFitToBudgetAssets = (assets, budget) => {
	const newAssets = [...assets];
	// Calculate initial replacement costs per year
	let replacementCostsPerYear = {};
	let budgetPerYear = {};
	budget.forEach((val, i) => {
		const year = new Date().getFullYear() + i;
		budgetPerYear[year] = val;
	});
	const startYear = new Date().getFullYear();

	newAssets.forEach((asset) => {
		let year = asset.replacementYear;
		if (year < startYear) {
			year = startYear;
		}
		if (!replacementCostsPerYear[year]) {
			replacementCostsPerYear[year] = 0;
		}
		if (!isNaN(Number(asset.avgReplCostUSD))) {
			replacementCostsPerYear[year] += asset.avgReplCostUSD;
		}
	});

	// Helper function to find the next best year to move an asset
	function findBestYear(asset, year) {
		const prevYear = year - 1;
		const nextYear = year + 1;
		if (
			replacementCostsPerYear[prevYear] + asset.avgReplCostUSD <
			budgetPerYear[prevYear]
		) {
			return prevYear;
		} else if (
			replacementCostsPerYear[nextYear] + asset.avgReplCostUSD <
			budgetPerYear[nextYear]
		) {
			return nextYear;
		}
		return year;
	}

	// Attempt to move assets to balance the budget
	newAssets.forEach((asset) => {
		let year = asset.replacementYear;
		if (year < startYear) {
			year = startYear;
		}
		if (replacementCostsPerYear[year] > budgetPerYear[year]) {
			const bestYear = findBestYear(asset, year);

			if (bestYear !== year && !isNaN(Number(asset.avgReplCostUSD))) {
				replacementCostsPerYear[year] -= asset.avgReplCostUSD;
				replacementCostsPerYear[bestYear] += asset.avgReplCostUSD;
				asset.replacementYear = bestYear;
			}
		}
	});
	return newAssets;
};

export const handleYearClick = (year, dispatch) => {
	dispatch({ type: "SET_SELECTED_YEAR", payload: year });
	dispatch({ type: "SET_SHOW_EXPORT_BUTTON", payload: false });
};

// Utils
export const formatMillions = (number, decimalPlaces = 3) => {
	// Check if the number is a valid number
	if (typeof number !== "number" || isNaN(number)) {
		return "Invalid number";
	}

	const million = 1000000;
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	});

	// Handle zero case specifically
	if (number === 0) {
		return "$0";
	}

	const absNumber = Math.abs(number);
	const formattedNumber = formatter.format(absNumber / million);
	return formattedNumber + "M";
};
export const darkenColor = (hex, percent) => {
	// Remove the hash if it exists
	hex = hex.replace("#", "");

	// Convert to RGB value
	let r = parseInt(hex.substring(0, 2), 16);
	let g = parseInt(hex.substring(2, 4), 16);
	let b = parseInt(hex.substring(4, 6), 16);

	// Darken by percentage
	r = Math.floor(r * (1 - percent / 100));
	g = Math.floor(g * (1 - percent / 100));
	b = Math.floor(b * (1 - percent / 100));

	// Ensure values stay within bounds
	r = r < 0 ? 0 : r;
	g = g < 0 ? 0 : g;
	b = b < 0 ? 0 : b;

	// Convert back to hex
	let darkerHex = "#" + (r * 65536 + g * 256 + b).toString(16).padStart(6, "0");

	return darkerHex;
};

export const calculateChartWidth = (showLegend, numAssetTypes) => {
	if (showLegend) {
		return `calc(100% - ${numAssetTypes > 18 ? "500" : "260"}px)`;
	} else {
		return "100%";
	}
};

export const exportToCSV = (state, chartState, chartRef) => {
	const {
		checkedLocations,
		checkedStates,
		portfolioChecked,
		healthScoreFilterType,
		healthScoreFilterValue,
		budgetType,
	} = state;
	// Use chartRef.current directly
	const visibleDatasets = chartRef.current.data.datasets.filter(
		(dataset, index) => {
			const meta = chartRef.current._metasets[index];
			return !meta.hidden;
		},
	);

	const headers = ["Year", ...visibleDatasets.map((dataset) => dataset.label)];

	const csvRows = [headers.join(",")];

	for (let i = 0; i < chartRef.current.data.labels.length; i++) {
		const row = [chartRef.current.data.labels[i]];
		visibleDatasets.forEach((dataset) => {
			let value = dataset.data[i];

			if (
				dataset.label.includes("Cost") ||
				dataset.label.includes("Budget") ||
				dataset.label.includes("Premium")
			) {
				value = `$${value}`;
			}
			row.push(value);
		});
		csvRows.push(row.join(","));
	}

	const checkedLocationsAddresses = checkedLocations
		.map((location) => location.data.address1)
		.join(", ");

	let predSpendFilterInfo = [];
	if (checkedLocationsAddresses) {
		predSpendFilterInfo.push(`Locations: ${checkedLocationsAddresses}`);
	}
	if (checkedStates.length > 0) {
		predSpendFilterInfo.push(
			`States: ${checkedStates.map((state) => state.label).join(", ")}`,
		);
	}
	if (portfolioChecked) {
		predSpendFilterInfo.push(`Portfolio: ${portfolioChecked ? "Yes" : "No"}`);
	}
	if (healthScoreFilterType?.label) {
		predSpendFilterInfo.push(
			`Health Score Filter Type: ${healthScoreFilterType.label} ${healthScoreFilterValue.value}`,
		);
	}
	if (budgetType?.label) {
		predSpendFilterInfo.push(`Budget Type: ${budgetType.label}`);
	}

	const combinedInfo = [
		`Strategy: ${chartState.strategy.label}`,
		...predSpendFilterInfo,
	];

	const numColumns = headers.length;

	const centeredCombinedInfo = combinedInfo.map((info) => {
		const quotedInfo = `"${info}"`;
		const padding = Math.floor((numColumns - 1) / 2);
		const centeredInfo = Array(padding)
			.fill("")
			.concat(quotedInfo)
			.concat(Array(padding).fill(""));
		return centeredInfo
			.join(",")
			.concat(",".repeat(numColumns - centeredInfo.length));
	});

	csvRows.unshift(...centeredCombinedInfo);

	const csvContent = csvRows.join("\n");
	const blob = new Blob([csvContent], {
		type: "text/csv;charset=utf-8;",
		title: "Predictive Spend Data",
	});
	saveAs(blob, "chart-data.csv");
};

export const handleReset = (
	state,
	chartState,
	dispatch,
	parentDispatch,
	chartRef,
) => {
	const { originalAssets, assetTypes } = state;
	dispatch({ type: "SET_LEGEND_SELECTED_ASSET_TYPES", payload: assetTypes });
	parentDispatch({ type: "SET_CHART_ASSETS", payload: originalAssets });
	handleStrategyChange(state, chartState, dispatch);

	// Reset the hidden property for each dataset
	chartRef.current.data.datasets.forEach((dataset) => {
		if (dataset.type === "line") return;
		dataset.hidden = false;
	});

	// Trigger chart update with explicit animation
	chartRef.current.update({ duration: 1000 });
};

export const toggleBubbleChart = (chartState, dispatch) => {
	dispatch({ type: "SET_BUBBLE_CHART", payload: !chartState.bubbleChart });
};

export const toggleYAxis = (chartState, dispatch) => {
	dispatch({
		type: "SET_IS_Y_AXIS_REPL_COST",
		payload: !chartState.isYAxisReplCost,
	});
};

const getStepSize = (chartState) => {
	// Define the minimum and maximum number of ticks
	const minTicks = 6;
	const maxTicks = 10;

	// Calculate the rough step size by dividing the max value by minTicks
	let roughStepSize = chartState.maxDataValue / minTicks;

	// Round the rough step size to the nearest "nice" value for readability (e.g., 500k, 1M, etc.)
	const magnitude = Math.pow(10, Math.floor(Math.log10(roughStepSize)));
	const normalizedStep = roughStepSize / magnitude;

	// Adjust the normalized step to a nice value
	let stepSize;
	if (normalizedStep < 1.5) {
		stepSize = 1 * magnitude;
	} else if (normalizedStep < 3) {
		stepSize = 2 * magnitude;
	} else if (normalizedStep < 7) {
		stepSize = 5 * magnitude;
	} else {
		stepSize = 10 * magnitude;
	}

	// Calculate the number of steps based on the step size and max value
	let numberOfSteps = Math.ceil(chartState.maxDataValue / stepSize);

	// Adjust stepSize if necessary to ensure between 6 and 8 ticks
	while (numberOfSteps > maxTicks) {
		stepSize *= 2;
		numberOfSteps = Math.ceil(chartState.maxDataValue / stepSize);
	}
	while (numberOfSteps < minTicks) {
		stepSize /= 2;
		numberOfSteps = Math.ceil(chartState.maxDataValue / stepSize);
	}

	return { stepSize, maxLabel: stepSize * numberOfSteps };
};

export const getChartOptions = (
	state,
	chartState,
	strategyAssetsRef,
	originalAssets,
	strategyRef,
	budgetRef,
	parentDispatch,
	dispatch,
) => {
	const { budget, budgetType, years, assets } = state;
	const { isYAxisReplCost, maxDataValue, budgetShortage } = chartState;
	const { stepSize, maxLabel } = getStepSize(chartState);
	const labels = Array.from(
		{ length: years },
		(_, i) => new Date().getFullYear() + i,
	);
	return {
		hover: {
			animationDuration: 0, // Disable animation when hovering
		},
		animation: {
			duration: 200,
		},
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (tooltipItem) => {
						let label = tooltipItem.dataset.label || "";
						label += ": ";
						if (
							label &&
							(label.includes("Budget") || label.includes("Replacement"))
						) {
							label += formatMillions(parseFloat(tooltipItem.raw));
						} else {
							const year = Number(tooltipItem.label);
							const type = tooltipItem.dataset.label;
							const newAssets = strategyAssetsRef.current || originalAssets;
							let unitCount = 0;
							const cost = newAssets
								.filter((asset) =>
									strategyRef.current.value == "Cumulative Do Nothing"
										? asset.replacementYear <= year && asset.assetType === type
										: asset.replacementYear === year &&
											asset.assetType === type,
								)
								.reduce((acc, asset) => {
									const replCost = Number(asset.avgReplCostUSD);
									if (isNaN(replCost)) {
										return acc;
									}
									unitCount++;
									return acc + replCost;
								}, 0);
							label += ` ${unitCount.toLocaleString()} units`;
							label += ` - ${formatMillions(cost)}`;
						}
						return label;
					},
				},
			},
			dragData: {
				round: 0,
				showTooltip: true,
				onDragStart: (e, datasetIndex, index, value) => {},
				onDrag: (e, datasetIndex, index, value) => {
					if (value < 0) return false;
				},
				onDragEnd: (e, datasetIndex, index, value) => {
					onBudgetDragEnd(
						e,
						datasetIndex,
						index,
						value,
						budgetType,
						budgetRef,
						strategyRef,
						originalAssets,
						parentDispatch,
						budgetShortage,
						assets,
					);
				},
				magnet: {
					to: (value) => Math.max(0, Math.round(value / 1000) * 1000),
				},
			},
			datalabels: {
				backgroundColor: "rgba(0, 0, 0, 0.7)", // Match tooltip background color and opacity
				color: "#FFFFFF", // Match tooltip text color
				borderRadius: 6, // Match tooltip border radius
				font: {
					size: 12, // Match tooltip font size
					family: "Arial, sans-serif", // Match tooltip font family
				},
				padding: {
					top: 8,
					right: 10,
					bottom: 8,
					left: 10,
				},
				align: "center",
				anchor: "center",
				formatter: (value, ctx) => {
					const newAssets = strategyAssetsRef.current || originalAssets;
					const year = labels[ctx.dataIndex];
					const type = ctx.dataset.label;
					const cost = newAssets
						.filter((asset) =>
							strategyRef.current.value == "Cumulative Do Nothing"
								? asset.replacementYear <= year && asset.assetType === type
								: asset.replacementYear === year && asset.assetType === type,
						)
						.reduce((acc, asset) => {
							const replCost = Number(asset.avgReplCostUSD);
							if (isNaN(replCost)) {
								return acc;
							}
							return acc + replCost;
						}, 0);
					const count = newAssets.filter((asset) =>
						strategyRef.current.value == "Cumulative Do Nothing"
							? asset.replacementYear <= year && asset.assetType === type
							: asset.replacementYear === year && asset.assetType === type,
					).length;
					const label = count + " units\n" + formatMillions(cost);
					return label;
				},
				display: () => {
					return false;
				},
			},
		},
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				ticks: {
					font: {
						size: 16,
						weight: "bold",
					},
				},
			},
			y: {
				stacked: true,
				title: {
					display: true,
					text: isYAxisReplCost ? "Replacement Cost" : "Asset Count",
				},
				ticks: {
					callback: isYAxisReplCost
						? (value) => formatMillions(value, 1)
						: (value) => value.toLocaleString(),
					font: {
						size: 16,
					},
					stepSize: isYAxisReplCost ? stepSize : null,
				},
				grid: {
					display: true,
				},
				position: "left",
				display: true,
				// Dynamically set the max and min values
				min: 0,
				max: isYAxisReplCost ? maxLabel : null,
			},
			// second y-axis for non-stacked datasets
			y2: {
				stacked: false,
				position: "right",
				title: {
					display: true,
				},
				ticks: {
					callback: isYAxisReplCost
						? (value) => formatMillions(value, 1)
						: (value) => value.toLocaleString(),
					font: {
						size: 16,
					},
					display: false,
					stepSize: isYAxisReplCost ? stepSize : null,
				},
				grid: {
					display: false,
				},
				min: 0,
				max: isYAxisReplCost ? maxLabel : null,
			},
		},
		onClick: (evt, activeElements, chart) => {
			if (activeElements.length > 0) {
				const firstPoint = activeElements[0];
				const label = chart.scales.x.getLabelForValue(firstPoint.index);
				handleYearClick(label, dispatch);
			}
		},
	};
};
