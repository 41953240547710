import { getForms } from "@services/form-service";
import { createChatBotMessage } from "react-chatbot-kit";
import { continueToLocationOptions } from "./continueToLocationOptions";

export const startDataCapture = async (
	organization,
	setState,
	latLonRef,
	setRetryLocation,
	setManufacturers,
	setAssetTypes,
	setShouldAutoSave,
	setOrgSpecificInputs,
	setFormId,
	setFormName,
	setAdditionalLocationInputs,
	setFormsSnap,
	setFormLogo,
	setFlags,
	setUserLocation,
	setInputData,
	question,
	saveDataCaptureState,
	setCurrentState,
) => {
	const formsSnap = await getForms(organization);
	setFormsSnap(formsSnap);
	if (
		formsSnap &&
		formsSnap.docs.filter(
			(doc) => doc.data().formName !== "Facility Vision Form",
		).length === 1
	) {
		const form = formsSnap.docs.filter(
			(doc) => doc.data().formName !== "Facility Vision Form",
		)[0];
		setFormId(form.id);
		continueToLocationOptions(
			latLonRef,
			setRetryLocation,
			organization,
			setManufacturers,
			setAssetTypes,
			setShouldAutoSave,
			setOrgSpecificInputs,
			setAdditionalLocationInputs,
			setState,
			setFlags,
			form.id,
			setFormName,
			setFormLogo,
			setUserLocation,
			setInputData,
			question,
			saveDataCaptureState,
			setCurrentState,
		);
	} else {
		const message = createChatBotMessage(
			"Please select the form you would like to fill out:",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					setUserLocation,
					setInputData,
					question,
					capturePhoto: () => {},
				},
			},
		);

		saveDataCaptureState([{ message, action: "add" }]);
		const currentState = {
			options: formsSnap.docs.map((form) => form.data().formName),
			stage: "multipleForms",
			showOptions: true,
			showInitialOptions: false,
		};
		setCurrentState(currentState);
		setState((prev) => ({
			...prev,
			messages: [...prev.messages, message],
			...currentState,
		}));
	}
	return true;
};
