import { useChatbotStore, useUserStore } from "@stores";
import React, { useRef, useState, useEffect } from "react";
import styles from "./BotChatMessage.module.css";

const Loader = ({ dashboard, isWidget }) => {
	const themeMode = useUserStore((state) => state.themeMode);
	const lastMessageCategory = useChatbotStore(
		(state) => state.lastMessageCategory,
	);

	const [generateMessage, setGenerateMessage] = useState(false);
	const [escalatedCategory, setEscalatedCategory] = useState("Initial");

	const messageTextStyleClasses = isWidget
		? ""
		: themeMode !== "dark"
			? styles.messageText
			: styles.messageText_dark;

	const initialLoadingMessages = [
		"Hold on, I'm digging deep for the answer! 🤔",
		"Hang on, I'm doing some mental gymnastics here! 🧠",
		"Just a sec, I'm pondering the possibilities! 🤔",
		"Give me a moment, I'm getting the gears turning!",
		"Hmm, let me see what I can conjure up!",
		"I'm sifting through the data! 📊",
		"Let me check that for you!",
		"Searching for an answer... 🔍😜",
		"Working out the best solution! 💡😆",
		"Calculating the response!",
		"Generating a response!",
		"Let me see what I can find to make you smile! 😊",
		"Coming up with a good reply! 💬😜",
		"Just a sec, I'm consulting with my team of expert robots! 🤖🤔",
		"I'm tapping into my vast knowledge bank to give you the best answer! 💡💰",
		"I'm channeling my inner Einstein to solve this problem! 🧐🧠",
		"Let me think on it for a second, I'm a professional brainstormer! 💭💼",
		"Hold your horses, I'm analyzing the problem like Sherlock Holmes! 🕵️‍♀️🔍",
		"I'm brewing up a storm of ideas in my mind! ☁️🌩️",
		"Hold on, I'm diving deep into my brain's database to retrieve the answer! 🤯💾",
	];

	const categoryMessage =
		lastMessageCategory === "location"
			? "Looking through the data for the requested location information!"
			: lastMessageCategory === "asset"
				? "Looking through the data for the requested asset information!"
				: null;

	const escalatedLoadingMessages = [
		"Searching through the data for an answer, I'll be done soon!",
		"Hang tight, I'm gathering the information you need! 📚",
		"Just a moment, I'm diving deep into the data! A response is imminent 🏊",
		"Hold on, I'm sifting through the knowledge base! 🕵️",
		"Bear with me, I'm on the hunt for your answer! 🐻",
		"I'm almost done fishing for the information you need! 🎣",
		"Hold on a sec, I'm stitching your answer together! 🧵",
	];

	const message = useRef(
		initialLoadingMessages[
			Math.floor(Math.random() * initialLoadingMessages.length)
		],
	);

	useEffect(() => {
		setTimeout(() => {
			message.current =
				escalatedCategory === "Initial" && categoryMessage !== null
					? categoryMessage
					: escalatedLoadingMessages[
							Math.floor(Math.random() * escalatedLoadingMessages.length)
						];
			setGenerateMessage(!generateMessage);
			escalatedCategory === "Initial" && categoryMessage !== null
				? setEscalatedCategory("Category")
				: escalatedCategory === "Initial"
					? setEscalatedCategory("Escalated")
					: setEscalatedCategory("Escalated");
		}, 10000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateMessage]);

	return (
		<span className={`loader ${dashboard ? messageTextStyleClasses : ""}`}>
			{message.current}
		</span>
	);
};

export default React.memo(Loader, (props, nextProps) => props === nextProps);
