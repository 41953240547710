import { smartManufacturerOptions } from "@/services/organization-service";
import { Loader } from "@SignedIn/Loader/Loader";
import { ConfirmDialog } from "@SignedIn/views/Locations/children/ConfirmDialog";
import { SubmittedImageModal } from "@components/layouts/SignedIn/views/SingleForm/SubmittedImageModal";
import { assetTypeToCategory } from "@constants/formInputs/assetTypes";
import { inputs as notRequiredInputs } from "@constants/formInputs/notRequiredInputs";
import { default as initInputs } from "@constants/formInputs/taggingFormInputs";
import { useDeleteDraft } from "@hooks/draftQueries";
import { useUpdateWithRef } from "@hooks/organizationQueries";
import {
	useAddSubmission,
	useDeleteSubmission,
	useUpdateSubmissionReturnAsset,
} from "@hooks/submissionQueries";
import { getOptionsData } from "@services/form-service";
import {
	useChatbotHighLevelStore,
	useChatbotStore,
	useLocationStore,
	usePageStore,
	useUserStore,
} from "@stores";
import { deepCopy } from "@utils/deepCopy";
import { CurrentPage } from "@views/SignedIn/SingleForm/CurrentPage";
import {
	getAssetConditionalInputs,
	getOptionsLength as getOptionsLengthWrapper,
	getOptions as getOptionsWrapper,
	submit as submitWrapper,
	updateInputsRequiredChange,
	validateAndSetPage1 as validateAndSetPage1Wrapper,
	validateAndSetPage2 as validateAndSetPage2Wrapper,
	validateAndSetPage3 as validateAndSetPage3Wrapper,
	validateAndSetPage4 as validateAndSetPage4Wrapper,
	validateAndSetPage5 as validateAndSetPage5Wrapper,
	validateAndSetPage6 as validateAndSetPage6Wrapper,
	validateAndSetPage7 as validateAndSetPage7Wrapper,
	validateAndSetPage8 as validateAndSetPage8Wrapper,
	validateAndSetPage9 as validateAndSetPage9Wrapper,
	validateRequiredFields,
} from "@views/SignedIn/SingleForm/childFunctions";
import React, { useState, useEffect, useCallback } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

const ExtractedInfo = React.memo(
	({ actionProvider, payload: { capturePhoto, draftIdState } }) => {
		const {
			inputs,
			formId,
			warrantyDetailsSwarovski,
			assetTagError,
			assetMapNewAsset,
			responseData,
			setResponseData,
			currentPage,
			setCurrentPage,
			assetMapDimensions,
			setFieldError,
			dupWarningShown,
			setDupWarningShown,
			flags,
			draftId,
			savedLocation,
			formName,
			userLocation,
			conditionRead,
			ocrData,
			chosenAssetType,
			resetResponseData,
			setAssetTagScanned,
			setSelectedPhotoUrl,
			setIsPhotoShowing,
			setSelectedItem,
			setInputs,
			setAssetConditionalInputs,
			additionalLocationInputs,
			setInputsRequired,
			orgSpecificInputs,
			setOptions,
			airportCode,
			setChosenAssetType,
			setDraftId,
			setOcrConfidenceAndColor,
			setAssetTagError,
			setInputData,
			setAssetMapNewAsset,
			setAssetMapDimensions,
			tempAlertError,
			inputsRequired,
			assetConditionalInputs,
			manufacturers,
			smartManufacturers,
			setSmartManufacturers,
			assetTypes,
			options,
			assetTypeName,
			categories,
		} = useChatbotStore();

		const { setSubmitDisabled, submitDisabled } = useChatbotHighLevelStore();

		const { authInfo } = useUserStore();
		const organization = authInfo.org;
		const { isSidebarOpen } = usePageStore();
		const setActiveTab = useLocationStore((state) => state.setActiveTab);

		const navigate = useNavigate();

		const addSubmission = useAddSubmission();
		const updateWithRef = useUpdateWithRef();
		const updateSubmission = useUpdateSubmissionReturnAsset();
		const deleteDraft = useDeleteDraft();
		const deleteSubmission = useDeleteSubmission();

		const [header, setHeader] = useState("");
		const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
		const [showCancelModal, setShowCancelModal] = useState(false);
		const [showModal, setShowModal] = useState(true);
		const [alertModal, setAlertModal] = useState({
			show: false,
			title: "",
			body: "",
		});

		const [isPage1Valid, setIsPage1Valid] = useState(false);
		const [isPage2Valid, setIsPage2Valid] = useState(false);
		const [isPage3Valid, setIsPage3Valid] = useState(false);
		const [isPage4Valid, setIsPage4Valid] = useState(false);
		const [isPage5Valid, setIsPage5Valid] = useState(false);
		const [isPage6Valid, setIsPage6Valid] = useState(false);
		const [isPage7Valid, setIsPage7Valid] = useState(false);
		const [isPage8Valid, setIsPage8Valid] = useState(false);
		const [isPage9Valid, setIsPage9Valid] = useState(false);
		const [allRequiredFieldsPresent, setAllRequiredFieldsPresent] =
			useState(false);
		const [
			allRequiredFieldsPresentAdditionalDetails,
			setAllRequiredFieldsPresentAdditionalDetails,
		] = useState(false);

		const getOptionsLength = useCallback(
			(item) =>
				getOptionsLengthWrapper(
					item,
					manufacturers,
					flags,
					assetTypes,
					responseData,
					options,
					assetTypeName,
					organization,
					assetTypeToCategory,
				),
			[
				manufacturers,
				flags,
				assetTypes,
				responseData,
				options,
				assetTypeName,
				organization,
			],
		);

		const getOptions = useCallback(
			(item, value) =>
				getOptionsWrapper(
					item,
					value,
					manufacturers,
					smartManufacturers,
					assetTypes,
					flags,
					responseData,
					options,
					organization,
					categories,
				),
			[
				manufacturers,
				smartManufacturers,
				assetTypes,
				flags,
				responseData,
				options,
				organization,
				categories,
			],
		);

		const submit = async (enterNew = false) => {
			const clearState = async () => {
				setAssetMapNewAsset(null);
				setAssetMapDimensions({});
				const copyInputs = deepCopy(initInputs);
				setInputs(copyInputs);
				setAssetConditionalInputs([]);
				setInputsRequired(true);
				resetResponseData();
				await getOptionsData(
					copyInputs,
					[],
					orgSpecificInputs,
					setOptions,
					organization,
					formId,
					airportCode,
				);
				setChosenAssetType();
				setSelectedPhotoUrl(null);
				setIsPhotoShowing(false);
				setSelectedItem(null);
				setDupWarningShown(false);
				setDraftId(null);
				setAssetTagScanned(false);
				setOcrConfidenceAndColor({});
				setAssetTagError(false);
				setCurrentPage(3);
			};
			console.log(responseData);

			return submitWrapper(
				enterNew,
				setAlertModal,
				closeAlertModal,
				responseData,
				submitDisabled,
				setSubmitDisabled,
				() => {},
				{
					isPage1Valid,
					isPage2Valid,
					isPage3Valid,
					isPage4Valid,
					isPage5Valid,
					isPage6Valid,
					isPage7Valid,
					isPage8Valid,
					isPage9Valid,
				},
				setCurrentPage,
				currentPage,
				inputs,
				() => {},
				setFieldError,
				organization,
				formId,
				dupWarningShown,
				setDupWarningShown,
				assetTagError,
				false,
				assetMapNewAsset,
				flags,
				false,
				draftId,
				location,
				authInfo,
				null,
				null,
				savedLocation,
				formName,
				userLocation,
				conditionRead,
				ocrData,
				assetMapDimensions,
				chosenAssetType,
				deleteSubmission,
				updateSubmission,
				addSubmission,
				updateWithRef,
				draftIdState,
				deleteDraft,
				setActiveTab,
				navigate,
				clearState,
				true, // fromChatbot
			);
		};

		const closeAlertModal = () => {
			setAlertModal({
				show: false,
				title: "",
				body: "",
			});
		};

		const handleCancel = async () => {
			if (draftIdState) {
				setShowCancelModal(true);
			}
		};
		const closeCancelModal = () => setShowCancelModal(false);
		const handleDeleteDraft = async () => {
			await deleteDraft.mutate({ organization, draftId: draftIdState });
			setShowCancelModal(false);
			window.location.reload();
		};

		const updateMedia = () => {
			setIsDesktop(window.innerWidth > 768);
		};

		useEffect(() => {
			window.addEventListener("resize", updateMedia);
			return () => window.removeEventListener("resize", updateMedia);
		});

		useEffect(() => {
			validateRequiredFields(
				organization,
				responseData,
				currentPage,
				setAllRequiredFieldsPresent,
				setAllRequiredFieldsPresentAdditionalDetails,
				true, // fromChatbot
			);
		}, [responseData, organization, currentPage]);

		useEffect(() => {
			updateInputsRequiredChange(
				inputsRequired,
				notRequiredInputs,
				true, // fromChatbot
				responseData,
				setResponseData,
				() => {}, // setCurrentPage
				setInputs,
				initInputs,
				assetConditionalInputs,
				orgSpecificInputs,
				additionalLocationInputs,
				savedLocation,
				flags,
				false, // isQA
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			inputsRequired,
			assetConditionalInputs,
			orgSpecificInputs,
			additionalLocationInputs,
			savedLocation?.id,
			flags.allowAssetMapping,
			responseData,
			setInputs,
		]);

		useEffect(() => {
			if (chosenAssetType) {
				// when asset type changes, delete all associated conditional inputs from responseData before getting new inputs
				const newState = { ...responseData };
				for (const input of assetConditionalInputs) {
					delete newState[input.key];
				}
				setResponseData(newState);
				getAssetConditionalInputs(
					chosenAssetType,
					setInputsRequired,
					setAssetConditionalInputs,
				);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [chosenAssetType]);

		useEffect(() => {
			const validateAndSetPage1 = () =>
				validateAndSetPage1Wrapper(responseData, setIsPage1Valid);

			const validateAndSetPage2 = () =>
				validateAndSetPage2Wrapper(organization, responseData, setIsPage2Valid);
			//cvs
			const validateAndSetPage3 = () =>
				validateAndSetPage3Wrapper(organization, responseData, setIsPage3Valid);

			const validateAndSetPage4 = () =>
				validateAndSetPage4Wrapper(
					organization,
					responseData,
					allRequiredFieldsPresent,
					setIsPage4Valid,
				);

			const validateAndSetPage5 = () =>
				validateAndSetPage5Wrapper(
					organization,
					allRequiredFieldsPresent,
					allRequiredFieldsPresentAdditionalDetails,
					setIsPage5Valid,
				);

			const validateAndSetPage6 = () =>
				validateAndSetPage6Wrapper(
					organization,
					responseData,
					warrantyDetailsSwarovski,
					setIsPage6Valid,
					true, // fromChatbot
					tempAlertError,
				);

			const validateAndSetPage7 = () =>
				validateAndSetPage7Wrapper(
					assetTagError,
					organization,
					responseData,
					setIsPage7Valid,
				);

			const validateAndSetPage8 = () =>
				validateAndSetPage8Wrapper(
					organization,
					assetMapNewAsset,
					setIsPage8Valid,
				);

			const validateAndSetPage9 = () =>
				validateAndSetPage9Wrapper(organization, responseData, setIsPage9Valid);

			if (!isPage1Valid && !isPage2Valid && !isPage3Valid) {
				validateAndSetPage1();
				validateAndSetPage2();
				validateAndSetPage3();
			}
			if (!isPage4Valid) {
				validateAndSetPage4();
			}
			if (!isPage5Valid) {
				validateAndSetPage5();
			}
			if (!isPage6Valid) {
				validateAndSetPage6();
			}
			if (!isPage7Valid) {
				validateAndSetPage7();
			}
			if (!isPage8Valid) {
				validateAndSetPage8();
			}
			if (!isPage9Valid) {
				validateAndSetPage9();
			}
		}, [
			allRequiredFieldsPresent,
			allRequiredFieldsPresentAdditionalDetails,
			assetMapNewAsset,
			assetTagError,
			currentPage,
			isPage1Valid,
			isPage2Valid,
			isPage3Valid,
			isPage4Valid,
			isPage5Valid,
			isPage6Valid,
			isPage7Valid,
			isPage8Valid,
			isPage9Valid,
			organization,
			responseData,
			warrantyDetailsSwarovski,
			tempAlertError,
		]);

		useEffect(() => {
			const fetchManufacturers = async () => {
				const options = await smartManufacturerOptions(
					responseData.assetType,
					organization,
				);
				setSmartManufacturers(options);
			};

			if (responseData.assetType) {
				fetchManufacturers();
			}
		}, [responseData.assetType]);

		return (
			<>
				<Modal
					isOpen={showModal}
					toggle={() => setShowModal(false)}
					centered
					style={{
						left: isDesktop ? (isSidebarOpen ? "130px" : "40px") : "0px",
						zIndex: 10,
					}}
				>
					<ModalHeader
						toggle={() => setShowModal(false)}
						// style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
						className="d-flex justify-content-center position-relative"
					>
						<strong className="fw-bold mx-2">
							{submitDisabled ? "Please wait..." : header}
						</strong>
					</ModalHeader>
					<ModalBody
						style={{
							alignSelf: "center",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						{submitDisabled ? (
							<Loader />
						) : (
							<CurrentPage
								inputs={inputs}
								currentPage={currentPage}
								setInputData={setInputData}
								responseData={responseData}
								setResponseData={setResponseData}
								capturePhoto={capturePhoto}
								getOptions={getOptions}
								getOptionsLength={getOptionsLength}
								setHeader={setHeader}
								formId={formId}
								fromChatbot={true}
								submitProcessing={submitDisabled}
								submit={async (enterNew) =>
									submit(enterNew)
										.then(async ({ submitState, enterNew, status }) => {
											if (status === "success") {
												submitState && enterNew
													? await actionProvider.handleSubmitNewAction()
													: await actionProvider.handleSubmitAction(
															submitState,
														);
											} else {
												return;
											}
										})
										.catch((err) => {
											console.log(err);
										})
										.finally(() => {
											setSubmitDisabled(false);
										})
								}
							/>
						)}
						{!submitDisabled && (
							<Row>
								<div className="position-relative">
									<div className="form-page-navigation text-center my-4">
										<Button
											className="m-2 px-4"
											onClick={() => setCurrentPage(currentPage - 1)}
											disabled={currentPage < 4}
										>
											<FaArrowLeft />
										</Button>
										{draftIdState !== null && (
											<Button
												className="m-2 px-4 btn-danger"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										)}
										<Button
											className="m-2 px-4"
											onClick={() => setCurrentPage(currentPage + 1)}
											disabled={currentPage >= inputs.length - 1}
										>
											<FaArrowRight />
										</Button>
									</div>
								</div>
							</Row>
						)}
					</ModalBody>
				</Modal>
				<SubmittedImageModal
					switchToCrop={true}
					removeImage={true}
					responseData={responseData}
					setResponseData={setResponseData}
					capturePhoto={capturePhoto}
					getOptions={getOptions}
					setInputData={setInputData}
					getOptionsLength={getOptionsLength}
					fromChatbot={true}
				/>
				<Modal
					centered
					isOpen={alertModal.show}
					toggle={() => {
						closeAlertModal();
					}}
					style={{
						left: !isDesktop ? "0px" : isSidebarOpen ? "130px" : "40px",
						zIndex: 20,
					}}
				>
					<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
					<ModalBody>{alertModal.body}</ModalBody>
				</Modal>
				<ConfirmDialog
					showConfirm={showCancelModal}
					toggle={closeCancelModal}
					title="Confirm Deletion"
					body={"Are you sure you want to delete draft?"}
					functionality={handleDeleteDraft}
				/>
			</>
		);
	},
);

ExtractedInfo.displayName = "ExtractedInfo";

export default ExtractedInfo;
