export const handleOptionSelection = async (
	message,
	location,
	handleUserMessage,
	setLastMessageCategory,
	setOptionSelected,
	setState,
) => {
	await handleUserMessage(message, location);
	const lowerCaseMessage = message.toLowerCase();
	if (lowerCaseMessage.includes("location")) {
		setLastMessageCategory("location");
	} else if (lowerCaseMessage.includes("asset")) {
		setLastMessageCategory("asset");
	} else {
		setLastMessageCategory("");
	}

	setOptionSelected(true);

	setState((prev) => {
		const newState = {
			...prev,
			messages: [...prev.messages],
			showInitialOptions: false,
		};
		return newState;
	});
};
