const SendEmail = () => {
	const subject = encodeURIComponent("Subject: Please enter subject here");
	const body = encodeURIComponent("Please enter description of the issue here");
	const mailTo = `mailto:support@datafleet.com?subject=${subject}&body=${body}`;

	return (
		<div>
			<a href={mailTo}>Contact Us</a>
		</div>
	);
};
export { SendEmail };
