import { SubmittedImageModal } from "@SignedIn/views/SingleForm/SubmittedImageModal";
import { LeftOutlined } from "@ant-design/icons";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { LoadingModal } from "@components/layouts/SignedIn/LoadingModal/LoadingModal";
import { useUpdateCushmanSubmissionReview } from "@hooks/submissionQueries";
import { getCushmanSubmissionData } from "@services/submission-service";
import { useUserStore } from "@stores";
import { useFormStore } from "@stores";
import { addExifToInputsCushman } from "@utils/addExifToInputs";
import { isValidUrl } from "@utils/validation";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Card,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import { generateCushmanPDF } from "./generateCushmanPdf";

const SubmissionCushman = (props) => {
	const {
		submissionId,
		formId,
		toggleSubmission,
		quickView = false,
		setModalIndex,
		responsesLength,
	} = props;
	const location = useLocation();
	const user = useUserStore((state) => state.user);
	const { setIsPhotoShowing, setSelectedPhotoUrl } = useFormStore();
	const setIsEditingCushman = useFormStore(
		(state) => state.setIsEditingCushman,
	);
	const updateCushmanSubmissionReview = useUpdateCushmanSubmissionReview();
	const navigate = useNavigate();

	// States used for showing loading animation upon init render and updates, respectively
	const [isLoading, setIsLoading] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	// State for submission data from database
	const [originalInputs, setOriginalInputs] = useState();
	const [inputs, setInputs] = useState();
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [dateOfVisit, setDateOfVisit] = useState();
	const [address, setAddress] = useState();
	const [summary, setSummary] = useState(false);
	const [reviewed, setReviewed] = useState();
	const [locationId, setLocationId] = useState();
	const [userLocation, setUserLocation] = useState();
	const [savedLocation, setSavedLocation] = useState();

	// States used for viewing images
	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImage, _setSelectedImage] = useState("");

	// State used for tracking if PDF is loading
	const [pdfLoading, setPdfLoading] = useState(false);

	// Updates submission (e.g., data), inputs, formName, and address states upon init render
	useEffect(() => {
		getCushmanSubmissionData(
			formId,
			submissionId,
			setOriginalInputs,
			setInputs,
			setFormName,
			setFormLogo,
			setDateOfVisit,
			setAddress,
			setSummary,
			setReviewed,
			setLocationId,
			setUserLocation,
			setSavedLocation,
		).finally(() => setIsLoading(false));
	}, [submissionId]);

	// Updates loader state to false when all other states are loaded
	useEffect(() => {
		if (
			originalInputs &&
			inputs &&
			formName &&
			formLogo &&
			dateOfVisit &&
			address &&
			summary !== false &&
			reviewed != null &&
			locationId &&
			submissionId &&
			formId
		) {
			setIsLoading(false);
		}
	}, [
		originalInputs,
		inputs,
		formName,
		formLogo,
		dateOfVisit,
		address,
		summary,
		reviewed,
		locationId,
		submissionId,
		formId,
	]);

	const returnPageHeaders = (page, index) => {
		return (
			<FormGroup
				key={`page-${index}`}
				className="pb-3 pt-4 px-3 submission-tab"
			>
				{page.title}
			</FormGroup>
		);
	};

	const returnSectionHeaders = (i, section, j) => {
		return (
			<FormGroup
				key={`page-${i}-section-${j}`}
				className="px-3 pt-3 pb-2 submission-section"
			>
				<Label className="submission-section-label">{section.title}</Label>
			</FormGroup>
		);
	};

	const returnQuestionTop = (i, j, question, k) => {
		return (
			<Row key={`page-${i}-section-${j}-question-${k}-top`}>
				{/* Description */}
				<Col xs="12" lg="6">
					<FormGroup className="p-3">
						<Label className="submission-label fw-bold">{question.label}</Label>
						<Input
							disabled
							className="bg-white"
							type="text"
							value={question.description}
						/>
					</FormGroup>
				</Col>
				{/* Action Taken */}
				<Col xs="12" lg="6">
					<FormGroup className="p-3">
						<Label className="submission-label fw-bold">Action Taken</Label>
						<Input
							disabled
							className="bg-white"
							type="text"
							value={question.actionTaken}
						/>
					</FormGroup>
				</Col>
			</Row>
		);
	};

	const returnQuestionBottom = (i, j, question, k) => {
		const imgs = [
			{ key: "imageBefore", label: "Asset Before" },
			{ key: "imageAfter", label: "Asset After" },
		];
		return (
			<Row key={`page-${i}-section-${j}-question-${k}-bottom`}>
				{/* Images Before & After */}
				{imgs.map((item) => {
					return (
						<Col xs="12" lg="6" key={item.key}>
							<FormGroup className="p-3 d-flex flex-column">
								<Label className="submission-label fw-bold">{item.label}</Label>
								{isValidUrl(question[item.key]) ? (
									<div className="position-relative submitted-image-parent">
										<img
											className="submitted-image"
											alt=""
											src={question[item.key]}
											onClick={() => openImgModal(question[item.key])}
										/>
									</div>
								) : (
									<div className="position-relative">
										<Input
											disabled
											className="bg-white"
											type="text"
											value="No Image"
										/>
									</div>
								)}
							</FormGroup>
						</Col>
					);
				})}
			</Row>
		);
	};

	const returnSubmission = () => {
		const lineItems = [];

		inputs?.forEach((page, i) => {
			// Page Header
			if (page.title && page.sections) {
				lineItems.push(returnPageHeaders(page, i));
				// Section Header
				page.sections.forEach((section, j) => {
					lineItems.push(returnSectionHeaders(i, section, j));
					// Question
					section.questions.forEach((question, k) => {
						lineItems.push(
							returnQuestionTop(i, j, question, k),
							returnQuestionBottom(i, j, question, k),
						);
					});
				});
			}
		});
		return lineItems;
	};

	const canUserEdit = () => {
		return user.role.access_level >= 125;
	};

	// Navigate to SingleFormCushman.js to edit the form
	const editSubmission = async () => {
		// Terminate function if user lacks edit permissions
		if (!canUserEdit()) return;
		// First, grab savedLocation data (ie, id and data as key:val pairs in an obj)
		setIsEditingCushman(true);
		navigate(`/forms/${formId}`, {
			state: {
				isEditing: true,
				inputs: originalInputs,
				submissionId,
				savedLocation: savedLocation,
				userLocation: userLocation,
				path: location.pathname,
			},
		});
	};

	const openImgModal = (response) => {
		setSelectedPhotoUrl(response);
		setShowImageModal(true);
		setIsPhotoShowing(true);
	};

	// Updates "reviewed" field for submission
	const updateReviewed = async (e) => {
		setShowLoader(true);
		const value = e.target.checked;
		setReviewed(value);
		await updateCushmanSubmissionReview.mutate({
			submissionId,
			value,
		});
		setShowLoader(false);
	};

	// Renders & downloads the submission's PDF
	const downloadPdf = async () => {
		setPdfLoading(true);
		// First, fetch images & update exif data
		const newInputs = await addExifToInputsCushman(inputs);
		// Second, generate PDF blob
		const blob = await generateCushmanPDF(
			newInputs,
			formName,
			formLogo,
			dateOfVisit,
			address,
			summary,
			reviewed,
		);
		// Third, download the PDF
		const tempLink = document.createElement("a");
		tempLink.href = URL.createObjectURL(blob);
		tempLink.setAttribute("download", `${formName} - ${address}.pdf`);
		tempLink.click();
		setPdfLoading(false);
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<div className="submission-header d-flex flex-row align-items-center justify-content-between h-100">
					{/* Invisible in single location overview tab */}
					{!quickView ? (
						toggleSubmission && (
							<Button
								type="link"
								onClick={toggleSubmission}
								icon={<LeftOutlined />}
							>
								Back to Submissions
							</Button>
						)
					) : (
						<FaArrowLeft
							style={{
								top: "50%",
								transform: "translateY(-50%)",
								cursor: "pointer",
							}}
							onClick={() => {
								setIsLoading(true);
								setModalIndex((prev) =>
									prev === 0 ? responsesLength - 1 : prev - 1,
								);
								setTimeout(() => setIsLoading(false), 500);
							}}
						/>
					)}
					{/* Invisible in single location overview tab */}
					{toggleSubmission && (
						<div className={`d-flex ${quickView ? "" : "align-content-start"}`}>
							<div className="d-flex flex-row">
								<div className="activities-page-header-buttons d-flex p-2 width-fit-content ">
									{pdfLoading ? (
										<div className="d-flex justify-content-center">
											Loading...
										</div>
									) : (
										<div
											className="d-flex justify-content-center"
											onClick={downloadPdf}
										>
											PDF <i className="bi bi-cloud-download mx-2" />
										</div>
									)}
								</div>
							</div>
							<button
								className="edit-submission-btn p-2"
								color="success"
								disabled={!canUserEdit()}
								onClick={editSubmission}
							>
								Edit
								<span>
									<AiOutlineEdit className="fs-5 mx-1" />
								</span>
							</button>
						</div>
					)}
					{quickView && (
						<FaArrowRight
							style={{
								top: "50%",
								transform: "translateY(-50%)",
								cursor: "pointer",
							}}
							onClick={() => {
								setIsLoading(true);
								setModalIndex((prev) =>
									prev === responsesLength - 1 ? 0 : prev + 1,
								);
								setTimeout(() => setIsLoading(false), 500);
							}}
						/>
					)}
				</div>
				{/* Invisible in single location overview tab */}
				<img
					hidden={toggleSubmission == null}
					src={formLogo}
					className="submission-logo"
					alt="logo"
				/>
				<CardHeader
					className="d-flex flex-row align-items-center justify-content-between"
					style={{
						backgroundColor: "#ffffff",
						border: "none",
					}}
				>
					{/* VISIBLE in single location overview tab */}
					<div hidden={toggleSubmission != null}>
						<div
							className="submission-title"
							style={{ fontSize: quickView ? "30px" : "" }}
						>
							Latest {formName}
						</div>
						<div className="d-flex align-content-start">
							<div className="d-flex flex-row">
								<div className="activities-page-header-buttons d-flex p-2 width-fit-content ">
									{pdfLoading ? (
										<div className="d-flex justify-content-center">
											Loading...
										</div>
									) : (
										<div
											className="d-flex justify-content-center"
											onClick={downloadPdf}
										>
											PDF <i className="bi bi-cloud-download mx-2" />
										</div>
									)}
								</div>
							</div>
							<button
								className="edit-submission-btn"
								color="success"
								disabled={!canUserEdit()}
								onClick={editSubmission}
							>
								Edit
								<span>
									<AiOutlineEdit className="fs-5 mx-1" />
								</span>
							</button>
						</div>
					</div>
					{/* Invisible in single location overview tab */}
					<div hidden={toggleSubmission == null} className="submission-title">
						{formName}
					</div>
				</CardHeader>
				{/* Submission Contents */}
				<div id="activities-tab-form-submission">
					{/* Line One - Reviewed Checkbox */}
					<FormGroup
						key={"reviewed-checkbox"}
						className="p-3 d-flex align-items-center"
					>
						<Label className="fw-bolder pt-0 mb-0">
							Report Reviewed and Approved?
						</Label>
						<Input
							onChange={updateReviewed}
							className=""
							type="checkbox"
							checked={reviewed}
						/>
					</FormGroup>
					{/* Line Two - Date of Visit */}
					<FormGroup key={"date-of-visit"} className="p-3">
						<Label className="fw-bolder pt-0">Date of Visit</Label>
						<Input
							disabled
							className="bg-white"
							type="date"
							value={dateOfVisit}
						/>
					</FormGroup>
					{/* Line Three - Address */}
					<FormGroup key={"address"} className="p-3">
						<Label className="fw-bolder pt-0">Location</Label>
						<Input disabled className="bg-white" type="text" value={address} />
					</FormGroup>
					{/* Line Four - Summary */}
					<FormGroup key={"summary"} className="p-3">
						<Label className="fw-bolder pt-0">Summary</Label>
						<Input
							disabled
							className="bg-white"
							type="textarea"
							value={summary}
						/>
					</FormGroup>
					{returnSubmission()}
				</div>
			</Card>
			<SubmittedImageModal
				showing={showImageModal}
				photoUrl={selectedImage}
				isZoom={true}
				cancel={() => setShowImageModal(false)}
			/>
			<LoadingModal showing={showLoader} />
		</div>
	);
};

export { SubmissionCushman };
