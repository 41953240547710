export const CERTIFICATION_NAMES = [
	"AHRI",
	"AIB",
	"ALI",
	"ANSI",
	"ANSI STANDARD",
	"ASHRAE",
	"ASI",
	"ASI FOOD SAFETY LLC",
	"ASSE",
	"ASSOCIATED LABORATORIES INC",
	"AUTOMOTIVE LIFT INSTITUTE INC",
	"BM TRADA",
	"CANADIAN ELECTRIC CODE",
	"CE",
	"CEC",
	"COMPLIANCE TESTING LLC",
	"CPSC",
	"CSA",
	"CSA GROUP",
	"CSA STD",
	"CSAUS",
	"CULUS",
	"DESIGN CERTIFIED",
	"DOT",
	"ENERGY STAR",
	"FCC",
	"FCC ID",
	"FDA",
	"FSC",
	"FSMA",
	"GUARDIAN FIRE TESTING LABORATORIES INC",
	"ICONTEC",
	"IDFL LABORATORY AND INSTITUTE INC",
	"INTERTEK",
	"INTERTEK TESTING SERVICES NA INC",
	"KEYSTONE CERTIFICATION INC",
	"LEED",
	"NRTL",
	"NSF",
	"NSI",
	"NSI STANDARD",
	"NSL/ANSI",
	"OSHA",
	"SA",
	"SAFETY EQUIPMENT INSTITUTE",
	"SCC",
	"SCS GLOBAL SERVICES",
	"SEI",
	"SFI",
	"SGS NORTH AMERICA INC",
	"SLI COMPLIANCE",
	"SME",
	"UL",
	"UL LISTED",
	"UNDERWRITER LABORATORY",
	"US SANITATION",
	"USDA",
];
