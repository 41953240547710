import { Select } from "antd";

interface FormConfig {
	allowAssetMapping: boolean;
	allowSmartManufacturer: boolean;
	organization: string;
	allowAssetIdTagImage: boolean;
	isConditional: boolean;
	allowTempAlertId: boolean;
	noAssetFamily: boolean;
	conditionalInputs: boolean;
	showQrCode: boolean;
	allowWideAngleImage: boolean;
	logo: string;
	allowManufacturersPlate: boolean;
	allowAssetCondition: boolean;
	allowWarrantyFields: boolean;
	allowExistingTag: boolean;
	isAssetForm: boolean;
	duplicates: any[];
	allowPhotoOFEquipment: boolean;
	allowTagId: boolean;
	allowOcr: boolean;
	allowAssetLocation: boolean;
	differentAssetTypesBasedOnLocation: boolean;
	multiplesPages: boolean;
	formName: string;
	inputs: {
		pages: Page[];
	};
	tagIdRequired: boolean;
	counter: number;
}

interface Page {
	Details: Detail[];
}

interface Detail {
	type:
		| "select"
		| "label"
		| "image"
		| "text"
		| "button"
		| "switch"
		| "checkbox"
		| "date";
	label: string;
	responseTag?: string;
	collection?: string;
	required?: "true" | "false";
	options?: string[] | number[];
	placeholder?: string;
	hidden?: "true" | "false";
	isRequired?: "true" | "false";
	tiedTo?: string;
	default?: "true" | "false";
	altRequired?: "true" | "false";
	id?: string;
	scanTag?: string;
	scanType?: "barcode" | "qr";
}

interface MasterFormKey {
	description: string | null;
	id: number;
	handler_function_key: string | null;
	name: string;
}

interface Form {
	form_id: number;
	config: FormConfig;
	required_access_level: number;
	organization_id: number;
	collections: unknown | null;
	enabled: boolean;
	master_form_key: MasterFormKey;
}

interface FormSelectProps {
	state: {
		formsArr: Form[];
		selectedForm: number | undefined;
		isLoading: boolean;
	};
	dispatch: (action: { type: string; payload: unknown }) => void;
}

const FormSelect: React.FC<FormSelectProps> = ({ state, dispatch }) => {
	const { formsArr, selectedForm, isLoading } = state;

	const handleChange = (value: number) => {
		dispatch({ type: "SET_SELECTED_FORM", payload: value });
		dispatch({ type: "SET_PAGE_NUM", payload: 1 });
	};

	const options = formsArr
		.map((form: Form) => ({
			value: form.form_id,
			label: form.config.formName,
		}))
		.sort((a, b) => a.value - b.value);

	const selectedFormOption =
		options.find((option) => option.value === selectedForm) || null;

	return (
		<Select
			style={{ width: 240 }}
			value={selectedFormOption}
			onChange={handleChange}
			options={options}
			defaultValue={
				selectedForm
					? options.find((option) => option.value === selectedForm)
					: undefined
			}
			placeholder="Loading forms..."
			loading={isLoading}
			disabled={isLoading || formsArr.length === 0}
		/>
	);
};

export { FormSelect };
