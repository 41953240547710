import { Card, Typography } from "antd";
import type React from "react";

export interface ReleaseItemProps {
	title: string;
	details: string[];
}

const ReleaseItem: React.FC<ReleaseItemProps> = ({ title, details }) => (
	<Card className="release-item" bordered={false}>
		<Typography.Title level={5} className="release-item-title">
			{title}
		</Typography.Title>
		<Typography.Paragraph className="release-item-details">
			{details[0]}
			{details.length > 1 && (
				<ul className="release-item-detail-list">
					{details.slice(1).map((detail, index) => (
						<li
							className="release-item-detail-list-item"
							key={`${detail?.slice(0, 10)}-${index}`}
						>
							{detail}
						</li>
					))}
				</ul>
			)}
		</Typography.Paragraph>
	</Card>
);

export default ReleaseItem;
