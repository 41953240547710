import { getColorBasedOnConfidence } from "@services/ocr-service";

/**
 * Updates a confidence value for a single item
 *
 * @param {Object} item - The item which we want to set the confidence for
 * @param {float} confidence - The confidence to display for the item
 */
export const addScanConfidence = (
	item,
	confidence,
	ocrConfidenceAndColor,
	setOcrConfidenceAndColor,
) => {
	const confObject =
		Object.keys(ocrConfidenceAndColor).length > 0 ? ocrConfidenceAndColor : {};
	confObject[item.scanTag] = {
		color: getColorBasedOnConfidence(confidence),
		confidence: confidence,
	};
	setOcrConfidenceAndColor(confObject);
};
