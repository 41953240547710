import { encode } from "@jsquash/jpeg";
import { decode } from "@jsquash/webp";
import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
	pdf,
} from "@react-pdf/renderer";
import { isValidUrl } from "@utils/validation";

const readBlobAsDataURL = (blob) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();

		// Define the onload event callback function
		reader.onload = (event) => {
			// Access the result property of the FileReader's event target to get the data URI
			var dataURI = event.target.result;

			// Resolve the Promise with the data URI
			resolve(dataURI);
		};

		// Define the onerror event callback function
		reader.onerror = (event) => {
			// Reject the Promise with the error
			reject(event.target.error);
		};

		// Start reading the contents of the Blob as a data URL
		reader.readAsDataURL(blob);
	});
};

const WebpToJpegDataURL = async (url) => {
	const res = await fetch(url);
	const blob = await res.blob();
	const arrBuff = await blob.arrayBuffer();
	const imageData = await decode(arrBuff);
	const jpegImage = await encode(imageData);
	const jpegBlob = new Blob([jpegImage], {
		type: "image/jpeg",
	});
	return readBlobAsDataURL(jpegBlob);
};

const formatResponseData = (questions, responseData) => {
	return new Promise((res, rej) => {
		if ((!Array.isArray(responseData), !Array.isArray(questions))) {
			rej(new Error("Input is not an array"));
		}
		const promises = questions.map(async (question) => {
			const response = responseData[question.key];
			if (question.type === "image" && isValidUrl(response)) {
				return new Promise(async (resolve, _reject) => {
					resolve({
						[question.key]: await WebpToJpegDataURL(response),
					});
				});
			}
			return;
		});
		Promise.all(promises).then((results) => {
			const formattedResponse = results.reduce((accumulator, currentObject) => {
				// Merge each object into the accumulator
				Object.assign(accumulator, currentObject);
				return accumulator;
			}, {});
			res(formattedResponse);
		});
	});
};

export const generatePDF = async (
	questions,
	responseData,
	formName,
	formLogo,
	submittedDate,
	address,
) => {
	const styles = StyleSheet.create({
		doc: {
			fontFamily: "Helvetica",
			flexDirection: "column",
			padding: "24px",
			fontSize: "12px",
		},
		title: {
			fontSize: "28px",
			padding: "0 6px 6px 6px",
			fontFamily: "Helvetica-Bold",
		},
		question: {
			fontFamily: "Helvetica-Bold",
			marginBottom: "2px",
		},
		questionImage: {
			fontFamily: "Helvetica-Bold",
			marginBottom: "18px",
		},
		answer: {
			border: "1px solid #ced4da",
			padding: "6px",
			borderRadius: "4px",
		},
		answerImageOrientationOne: {
			width: "100%",
			marginTop: "-15px",
		},
		answerImageOrientationSix: {
			transform: "rotate(90deg)",
			width: "100%",
			marginLeft: "-33px",
			marginTop: "18px",
		},
		answerImageOrientationThree: {
			transform: "rotate(180deg)",
			width: "100%",
			marginTop: "-15px",
		},
		answerImageOrientationEight: {
			transform: "rotate(270deg)",
			width: "100%",
			marginLeft: "-33px",
			marginTop: "18px",
		},
		questionAnswer: {
			display: "flex",
			flexDirection: "column",
			padding: "6px",
		},
		questionAnswerImage: {
			display: "flex",
			flexDirection: "column",
			width: "273px",
			padding: "6px",
			overflow: "hidden",
			marginBottom: "6px",
		},
		questionAnswerColText: {
			display: "flex",
			flexDirection: "column",
			width: "273px",
			padding: "6px",
		},
		questionAnswerColImage: {
			display: "flex",
			flexDirection: "column",
			width: "273px",
			padding: "6px",
			overflow: "hidden",
			marginBottom: "6px",
		},
		questionAnswerColParent: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		pageHeader: {
			fontSize: "20px",
			padding: "6px",
		},
		sectionHeader: {
			fontFamily: "Helvetica-Bold",
			border: "1px solid #ced4da",
			padding: "6px",
			margin: "6px",
			borderRadius: "4px",
			backgroundColor: "#f3f3f3",
		},
		logoImage: {
			width: "173px",
		},
	});

	const formattedResponse = await formatResponseData(
		questions,
		responseData.raw_input,
	);

	const document = (
		<Document>
			<Page size="A4" style={styles.doc}>
				<View>
					{/* Logo */}
					<View key="logo" style={styles.questionAnswer}>
						<View>
							<Image style={styles.logoImage} src={formLogo} />
						</View>
					</View>
					{/* Form Name */}
					<Text key="form name" style={styles.title}>
						{formName}
					</Text>
					{/* Date of Visit */}
					<View key="dateOfVisit" style={styles.questionAnswer}>
						<Text style={styles.question}>Date of Visit</Text>
						<Text style={styles.answer}>{submittedDate}</Text>
					</View>
					{/* Address */}
					<View key="address" style={styles.questionAnswer}>
						<Text style={styles.question}>Address</Text>
						<Text style={styles.answer}>{address}</Text>
					</View>
					{questions.map((question, i) => {
						if (question.key === "location") return;
						let response =
							formattedResponse?.[question?.key] ||
							responseData.raw_input[question.key];
						if (!response && question.label === "Not Found") return;
						if (!response && question.key === "assetGeneralNotes") return;
						if (typeof response === "object")
							if (response?.formatted !== undefined) {
								response = response?.formatted;
							} else {
								response = response.name;
							}
						else if (!response) response = " ";
						if (question.type !== "image") {
							if (question.key === "assetLocation") {
								return (
									<View
										wrap={false}
										key={`question-${i}`}
										style={styles.questionAnswer}
									>
										<Text style={styles.question}>{question.label}</Text>
										<Text style={styles.answer}>
											{responseData.asset?.location_in_facility.name ?? ""}
										</Text>
									</View>
								);
							}
							return (
								<View
									wrap={false}
									key={`question-${i}`}
									style={styles.questionAnswer}
								>
									<Text style={styles.question}>{question.label}</Text>
									<Text style={styles.answer}>{response}</Text>
								</View>
							);
						}
						if (isValidUrl(responseData.raw_input[question.key]) !== true) {
							return (
								<View
									wrap={false}
									key={`question-${i}`}
									style={styles.questionAnswer}
								>
									<Text style={styles.question}>{question.label}</Text>
									<Text style={styles.answer}>{response}</Text>
								</View>
							);
						}
						let style;
						let rotVal;
						const orientation = question.exif?.Orientation;
						if (orientation !== undefined) {
							rotVal = orientation.value;
						} else {
							rotVal = undefined;
						}
						if (rotVal === 1 || rotVal === undefined) {
							style = styles.answerImageOrientationOne;
						} else if (rotVal === 3) {
							style = styles.answerImageOrientationThree;
						} else if (rotVal === 6) {
							style = styles.answerImageOrientationSix;
						} else if (rotVal === 8) {
							style = styles.answerImageOrientationEight;
						}
						return (
							<View
								wrap={false}
								key={`question-${i}`}
								style={styles.questionAnswerImage}
							>
								<Text style={styles.questionImage}>{question.label}</Text>
								<View>
									<Image style={style} src={response} />
								</View>
							</View>
						);
					})}
				</View>
			</Page>
		</Document>
	);

	const blob = await pdf(document).toBlob();

	return blob;
};
