import { getSelfForms } from "@/services/form-service";
import { Dispatch } from "react";
import { getOrgColumns } from "../Columns/SubmissionsColumns";
import { Action, State } from "../SubmissionsTable";
import { initializeColumnsShown } from "./initializeColumnsShown";

export async function initializeFormsAndColumns(
	state: State,
	dispatch: Dispatch<Action>,
	organization: any,
) {
	try {
		// Fetch forms and set them in state
		const forms = await getSelfForms();
		dispatch({ type: "SET_FORMS_ARR", payload: forms });

		// Check if forms are available
		if (forms.length > 0) {
			// Find default form by key name or fall back to the first form
			const defaultForm =
				forms.find(
					(form) => form.master_form_key.name === "Inventory & Tagging Form",
				) || forms[0];

			// Set the selected form to the found default form or the first form
			if (!state.selectedForm) {
				dispatch({ type: "SET_SELECTED_FORM", payload: defaultForm.form_id });
			}
		}
	} catch (error) {
		console.error("Failed to fetch forms:", error);
	}

	// Initialize columns based on organization
	try {
		const columns = getOrgColumns(organization);
		dispatch({ type: "SET_TABLE_COLUMNS", payload: columns });
		dispatch({
			type: "SET_COLUMNS_SHOWN",
			payload: initializeColumnsShown(columns),
		});
	} catch (error) {
		console.error("Failed to initialize columns:", error);
	}
}
