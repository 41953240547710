import Demo1 from "@assets/img/demo1.png";
import Demo2 from "@assets/img/demo2.png";
import Demo3 from "@assets/img/demo3.png";
import { useEffect } from "react";
import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Slideshow = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	let slideInterval;

	function clickRight() {
		if (currentSlide > 1) {
			setCurrentSlide(0);
			return;
		}
		setCurrentSlide(currentSlide + 1);
	}
	function clickLeft() {
		if (currentSlide < 1) {
			setCurrentSlide(2);
			return;
		}
		setCurrentSlide(currentSlide - 1);
	}
	function autoScroll() {
		slideInterval = setInterval(() => {
			if (currentSlide < 1) {
				setCurrentSlide(2);
				return;
			}
			if (currentSlide > 1) {
				setCurrentSlide(0);
				return;
			}
			setCurrentSlide(currentSlide + 1);
		}, 3000);
	}

	useEffect(() => {
		autoScroll();
		return () => clearInterval(slideInterval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSlide]);

	return (
		<figure className="slideshow-wrapper shadow-green overflow-hidden">
			{currentSlide === 0 && (
				<img
					src={Demo1}
					className="slideshow-img w-100 overflow-hidden"
					alt="slideshow"
				/>
			)}
			{currentSlide === 1 && (
				<img
					src={Demo2}
					className="slideshow-img w-100 overflow-hidden"
					alt="slideshow"
				/>
			)}
			{currentSlide === 2 && (
				<img
					src={Demo3}
					className="slideshow-img w-100 overflow-hidden"
					alt="slideshow"
				/>
			)}
			<button onClick={clickLeft} className="slideshow-btn-left">
				<FaArrowLeft className="fs-3" />
			</button>
			<button onClick={clickRight} className="slideshow-btn-right">
				<FaArrowRight className="fs-3" />
			</button>
		</figure>
	);
};

export { Slideshow };
