export const getAssetConditionalInputs = async (
	assetType,
	setInputsRequired,
	setAssetConditionalInputs,
) => {
	const condInputs = [];
	setInputsRequired(true);
	const condInputsJSON = assetType.conditional_inputs || null;
	if (!condInputsJSON) {
		setAssetConditionalInputs([]);
		return;
	}
	for (const input of condInputsJSON) {
		condInputs.push(input);
	}
	setAssetConditionalInputs(condInputs);
};
