import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
// import { LocationPicker } from '@SignedIn/views/SingleForm/LocationPicker';
import { NewLocationPicker } from "@components/layouts/SignedIn/views/SingleForm/NewLocationPicker";
import { useChatbotStore, useFormStore } from "@stores";
import { useState } from "react";
import { Row } from "reactstrap";

const LocationInput = ({
	question,
	setUserLocation,
	organization,
	setInputData,
	fromChatbot = false,
	isOpen = false,
	setIsOpen,
	submit,
}) => {
	const { setSavedLocation: setFormSavedLocation, showDraftSelectionModal } =
		useFormStore();
	const { setSavedLocation: setChatbotSavedLocation } = useChatbotStore();
	const setSavedLocation = fromChatbot
		? setChatbotSavedLocation
		: setFormSavedLocation;
	const [isLoading, setIsLoading] = useState(true);

	// Handler to update the loading state based on NewLocationPicker's status
	const handleLoadingChange = (loading) => {
		setIsLoading(loading);
	};

	return fromChatbot ? (
		<NewLocationPicker
			setChosenLocation={(e) => {
				setInputData(question, e.id);
				setSavedLocation(e);
				setIsOpen(false);
				submit(e);
			}}
			setUserLocation={setUserLocation}
			organization={organization}
			center={true}
			fromChatbot={fromChatbot}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
		/>
	) : (
		<Row key={question.key}>
			{isLoading && <Loader />}
			{!showDraftSelectionModal && (
				<NewLocationPicker
					setChosenLocation={(e) => {
						setInputData(question, e.id);
						setSavedLocation(e);
					}}
					setUserLocation={setUserLocation}
					organization={organization}
					center={true}
					onLoadingChange={handleLoadingChange} // Pass the handler as a prop
				/>
			)}
		</Row>
	);
};

export { LocationInput };
