import { DB_ORG } from "@/constants/db";

export const getOptions = (
	item,
	value,
	manufacturers,
	smartManufacturers,
	equipmentAreas,
	assetTypes,
	flags,
	responseData,
	options,
	organization,
	categories,
) => {
	if (item.table === "equipment-area") {
		//add is_deprecated filter for Thornton's
		if (organization === DB_ORG.THORNTONS) {
			equipmentAreas = equipmentAreas.filter((area) => !area.is_deprecated);
		}
		return Object.values(equipmentAreas).map((area) => {
			return (
				<option key={area.id} value={area.id} selected={area.id === value?.id}>
					{area.name}
				</option>
			);
		});
	}
	if (item.key === "assetSubArea" && responseData.assetLocation) {
		const selectedEquipmentArea = equipmentAreas.find(
			(area) => area.id === responseData.assetLocation?.id,
		);

		if (selectedEquipmentArea?.sub_areas) {
			console.log(
				"Selected Equipment Area Sub Areas:",
				selectedEquipmentArea.sub_areas,
			);

			// Map through sub-areas and generate options
			return selectedEquipmentArea.sub_areas.map((subArea) => (
				<option key={subArea.id} value={subArea.id}>
					{subArea.name}
				</option>
			));
		}

		return <option disabled>No Sub-Areas Available</option>;
	}
	if (item.collection === "manufacturers") {
		return manufacturers;
	}
	if (item.collection === "smart-manufacturers") {
		return smartManufacturers;
	}
	if (item.collection === "asset-types") {
		let arr = Object.values(assetTypes).sort((a, b) =>
			a.name.localeCompare(b.name),
		);

		//if org is amc then filter asset-types by showRequired
		if (
			organization === DB_ORG.AMC ||
			organization === DB_ORG.BJSREST ||
			organization === DB_ORG.COOPERSHAWK ||
			organization === DB_ORG.PAPAJOHNS ||
			organization === DB_ORG.THORNTONS ||
			organization === DB_ORG.APPLEGREEN
		) {
			arr = arr.filter((type) => !type.is_deprecated);
		} else if (organization === DB_ORG.ECOTRAK && responseData.subOrg) {
			arr = arr.filter((type) => type.sub_organization === responseData.subOrg);
		} else if (flags.config.differentAssetTypesBasedOnLocation) {
			arr = arr.filter(
				(type) =>
					type.organization_equipment_area_id ===
					Number.parseInt(responseData?.assetLocation?.id),
			);
		}
		return arr.map((assetType) => {
			return (
				<option
					key={assetType.id}
					value={assetType.id}
					selected={assetType.name === value?.name}
				>
					{assetType.name}
				</option>
			);
		});
	}
	if (item.collection === "asset-type-categories") {
		return categories.map((category) => (
			<option key={category} value={category}>
				{category}
			</option>
		));
	}
	if (item.options) {
		return item.options.map((i) => {
			return <option key={`${item.key}-${i}`}>{i}</option>;
		});
	}
	if (Object.keys(options).includes(item.collection)) {
		return options[item.collection].map((name) => {
			return (
				<option key={name.id} value={name.value ?? name.name}>
					{name.value ?? name.name}
				</option>
			);
		});
	}
	return flags.collections[item.collection].map((entry) => {
		return (
			<option key={entry.id} value={entry.name}>
				{entry.name}
			</option>
		);
	});
};
