import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import type { ColumnsType } from "antd/es/table";

export const columns: ColumnsType = [
	{
		title: "Address",
		dataIndex: "address1",
		key: "address1",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		filterMode: "tree",
		filterSearch: true,
		onFilter: (value, record) => true,
		render: (text) => <CopyableTableCell text={text} />,
		width: "30%",
	},
	{
		title: "City",
		dataIndex: "city",
		key: "city",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "State",
		dataIndex: "state",
		key: "state",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Zip",
		dataIndex: "zip",
		key: "zip",
		render: (text) => text || "N/A",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Submissions",
		dataIndex: "submission_count",
		key: "submission_count",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Manager",
		dataIndex: "manager",
		key: "manager",
		render: (text) => text || "Not Assigned",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Location Health",
		dataIndex: ["health, health_score"],
		key: "health_score",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, row) => (
			<HealthScorePill healthScore={row?.health?.health_score} />
		),
	},
];

export const cushmanColumns: ColumnsType = [
	{
		title: "Site Name",
		dataIndex: "name",
		key: "name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		filterMode: "tree",
		filterSearch: true,
		onFilter: (value, record) => true,
		render: (text) => <CopyableTableCell text={text} />,
		width: "30%",
	},
	{
		title: "City",
		dataIndex: "city",
		key: "city",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "State",
		dataIndex: "state",
		key: "state",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Zip",
		dataIndex: "zip",
		key: "zip",
		render: (text) => text || "N/A",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Submissions",
		dataIndex: "submission_count",
		key: "submission_count",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Site/Facility Analyst",
		dataIndex: "contacts",
		key: "contacts",
		render: (contacts) => {
			if (!contacts || !Array.isArray(contacts)) return "Not Assigned";
			// Filter for the desired role and sort by id in descending order in one step
			const filteredContacts = contacts
				.filter((contact) => contact.role === "GRE Senior Analyst")
				.sort((a, b) => b.id - a.id);
			// Return the name of the most recent contact, or a fallback if none found
			return filteredContacts.length > 0
				? filteredContacts[0].name
				: "Not Assigned";
		},
		width: 300,
	},
	{
		title: "Location Health",
		dataIndex: "health_score",
		key: "health_score",
		sorter: true,
		sortDirections: ["descend", "ascend"],
		render: (healthScore) => <HealthScorePill healthScore={healthScore} />,
	},
];

export const getOrgColumns = (organization: any): ColumnsType => {
	switch (organization) {
		case "cushmanwakefield.com":
			return cushmanColumns;
		default:
			return columns;
	}
};
