import { getReleases } from "@services/organization-service";
import { useEffect, useState } from "react";
import { SendEmail } from "../Navbar/children/SendEmail";

const Copyright = (props) => {
	const { setIsToastShowing } = props;
	const [version, setVersion] = useState();

	const updateVersion = async () => {
		//revisit_later
		const releases = await getReleases();
		setVersion(releases.docs[0].data().version);
	};

	useEffect(() => {
		updateVersion();
	}, []);

	const date = new Date();
	const year = date.getFullYear();

	return (
		<div className="d-flex flex-column align-items-center">
			<div className="d-flex flex-row">
				<a href="https://datafleet.com" className="pe-footer-item">
					© Data Fleet LLC {year}. All Rights Reserved.
				</a>
				<div className="mx-2">•</div>
				<SendEmail setIsToastShowing={setIsToastShowing} />
			</div>
			<div className="d-flex flex-row">
				<div className="pe-footer-item-2">Version {version}</div>
				<div className="mx-2">•</div>
				<a href="https://propertyecho.com/releases" className="pe-footer-item">
					Release Notes
				</a>
			</div>
		</div>
	);
};

export { Copyright };
