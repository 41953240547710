import { MutatingDots } from "react-loader-spinner";

const Loader = (props) => {
	return (
		<div className={props.small ? "loading-spinner-small" : "loading-spinner"}>
			<div className="d-flex flex-column align-items-center">
				<MutatingDots
					width={120}
					height={120}
					color="#BF1E2D"
					secondaryColor="#BF1E2D"
				/>
				{props?.message && <span className="fw-bold">{props?.message}</span>}
			</div>
		</div>
	);
};
export { Loader };
