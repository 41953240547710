// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
export const formatDate = (oldDate) => {
	const date = new Date(oldDate);

	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const year = String(date.getFullYear());

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateMMDDYYYY = (oldDate) => {
	const date = new Date(oldDate);

	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const year = String(date.getFullYear());

	return `${month}/${day}/${year}`;
};
