import { parseDateToUTCString, toSnakeCase } from "@/utils/transforms";
import { PhotoUploadField } from "@/views/SignedIn/FacilityVisionForm/inputs/PhotoUploadField";
import { PreviewFVField } from "@/views/SignedIn/FacilityVisionForm/inputs/PreviewField";
import { AssetMap } from "@components/layouts/SignedIn/AssetMap/AssetMap";
import { useChatbotStore, useFormStore, useUserStore } from "@stores";
import {
	CheckboxInput,
	DateInput,
	IfOtherManufacturerInput,
	ImageInput,
	LocationInput,
	ManufacturerInput,
	NumberInput,
	RatingInput,
	SelectInput,
	SwitchInput,
	TagIdInput,
	TextAreaInput,
	TextInput,
	TypeOfSystemInput,
} from "./TaggingFormInputs";
import AMDOrgInputs from "./TaggingFormInputs/AMDOrgInputs";
import { RefrigerantInput } from "./TaggingFormInputs/RefrigerantInput";
import { TempAlertTextInput } from "./TaggingFormInputs/TempAlertTextInput";

export const Questions = ({
	page,
	setUserLocation,
	setInputData,
	responseData,
	generativeAiInputs,
	setGenerativeAiInputs,
	setResponseData,
	capturePhoto,
	getOptions,
	getOptionsLength,
	formId,
	fieldError,
	fromChatbot,
}) => {
	const {
		authInfo: { org },
	} = useUserStore();

	const {
		flags: formFlags,
		savedLocation: formSavedLocation,
		equipmentAreas,
	} = useFormStore();
	const { flags: chatbotFlags, savedLocation: chatbotSavedLocation } =
		useChatbotStore();

	const flags = fromChatbot ? chatbotFlags : formFlags;

	const savedLocation = fromChatbot ? chatbotSavedLocation : formSavedLocation;

	return (page?.questions ?? []).map((question, i) => {
		if (question.flag && !flags.config[question.flag]) {
			return null;
		}

		let element = null;

		switch (question.key) {
			case "location":
				element = (
					<LocationInput
						question={question}
						setUserLocation={setUserLocation}
						organization={org}
						setInputData={setInputData}
						key={`${question.key}-${i}`}
					/>
				);
				break;
			case "manufacturer":
				element = (
					<ManufacturerInput
						question={question}
						responseData={responseData}
						getOptions={getOptions}
						setInputData={setInputData}
						fieldError={fieldError}
						key={`${question.key}-${i}`}
						fromChatbot={fromChatbot}
					/>
				);
				break;
			case "ifOtherManufacturer":
				element = (
					<IfOtherManufacturerInput
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						isManufacturerNotAvailable={responseData?.manufacturerNotAvailable}
						key={`${question.key}-${i}`}
					/>
				);
				break;
			case "typeOfSystem":
				element = (
					<TypeOfSystemInput
						value={responseData?.typeOfSystem || "Self Contained"}
						key={`${question.key}-${i}`}
					/>
				);
				break;
			case "assetTag":
				element = (
					<TagIdInput
						key={question.key}
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						flags={flags}
						org={org}
						formId={formId}
						fromChatbot={fromChatbot}
					/>
				);

				break;
			case "createFacilityVision":
				element = (
					<PhotoUploadField
						organization={org}
						responseData={responseData}
						setResponseData={setResponseData}
						key={`${question.key}-${i}`}
					/>
				);
				break;
			case "previewFacilityVision":
				element = (
					<PreviewFVField
						organization={org}
						responseData={responseData}
						setResponseData={setResponseData}
						key={`${question.key}-${i}`}
					/>
				);
				break;
			case "refrigerant":
				element = (
					<RefrigerantInput
						question={question}
						placeholder={
							generativeAiInputs[toSnakeCase(question?.key) || question?.key]
						}
						responseData={responseData}
						setInputData={setInputData}
						key={`${question.key}-${i}`}
						fromChatbot={fromChatbot}
					/>
				);
				break;

			case org === "AMD" && "assetLocation":
				element = (
					<AMDOrgInputs
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						flags={flags}
						equipmentAreas={equipmentAreas}
						key={`${question.key}-${i}`}
						organization={org}
						dataType="assetLocation"
					/>
				);
				break;

			case "assetRank":
				element = (
					<AMDOrgInputs
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						flags={flags}
						equipmentAreas={equipmentAreas}
						key={`${question.key}-${i}`}
						organization={org}
						dataType="ranks"
					/>
				);
				break;

			case "assetClass":
				element = (
					<AMDOrgInputs
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						flags={flags}
						equipmentAreas={equipmentAreas}
						key={`${question.key}-${i}`}
						organization={org}
						dataType="classes"
					/>
				);
				break;

			case "assetNumber":
				element = (
					<AMDOrgInputs
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						flags={flags}
						equipmentAreas={equipmentAreas}
						key={`${question.key}-${i}`}
						organization={org}
						dataType="numbers"
					/>
				);
				break;

			case "propertyId":
				element = (
					<AMDOrgInputs
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						flags={flags}
						equipmentAreas={equipmentAreas}
						key={`${question.key}-${i}`}
						organization={org}
						dataType="propertyIds"
					/>
				);
				break;

			case "tempAlertId":
				element = (
					<TempAlertTextInput
						key={question.key}
						question={question}
						responseData={responseData}
						setInputData={setInputData}
						org={org}
						fromChatbot={fromChatbot}
					/>
				);
				break;
			default:
				switch (question.type) {
					case "image":
						element = (
							<ImageInput
								question={question}
								responseData={responseData}
								capturePhoto={capturePhoto}
								key={`${question.key}-${i}`}
							/>
						);
						break;
					case "select":
						element = (
							<SelectInput
								question={question}
								responseData={responseData}
								placeholder={
									generativeAiInputs[
										toSnakeCase(question?.key) || question?.key
									]
								}
								setInputData={setInputData}
								getOptions={getOptions}
								getOptionsLength={getOptionsLength}
								fieldError={fieldError}
								key={`${question.key}-${i}`}
								fromChatbot={fromChatbot}
							/>
						);
						break;
					case "textarea":
						element = (
							<TextAreaInput
								question={question}
								responseData={responseData}
								placeholder={
									generativeAiInputs[
										toSnakeCase(question?.key) || question?.key
									]
								}
								setInputData={setInputData}
								key={`${question.key}-${i}`}
							/>
						);
						break;
					case "switch":
						element = (
							<SwitchInput
								question={question}
								responseData={responseData}
								setInputData={setInputData}
								key={`${question.key}-${i}`}
							/>
						);
						break;
					case "date":
						element = (
							<DateInput
								question={question}
								responseData={responseData}
								setResponseData={setResponseData}
								placeholder={parseDateToUTCString(
									generativeAiInputs[
										toSnakeCase(question?.key) || question?.key
									],
								)}
								setInputData={setInputData}
								fieldError={fieldError}
								key={`${question.key}-${i}`}
								fromChatbot={fromChatbot}
							/>
						);
						break;
					case "checkbox":
						element = (
							<CheckboxInput
								key={question.key}
								question={question}
								responseData={responseData}
								setInputData={setInputData}
							/>
						);
						break;
					case "rating":
						element = (
							<RatingInput
								question={question}
								responseData={responseData}
								setInputData={setInputData}
								fieldError={fieldError}
								key={`${question.key}-${i}`}
								fromChatbot={fromChatbot}
							/>
						);
						break;
					case "text":
						element = (
							<TextInput
								question={question}
								responseData={responseData}
								setInputData={setInputData}
								fieldError={fieldError}
								placeholder={
									generativeAiInputs[
										toSnakeCase(question?.key) || question?.key
									]
								}
								key={`${question.key}-${i}`}
								fromChatbot={fromChatbot}
							/>
						);
						break;
					case "number":
						element = (
							<NumberInput
								question={question}
								responseData={responseData}
								placeholder={
									generativeAiInputs[
										toSnakeCase(question?.key) || question?.key
									]
								}
								setInputData={setInputData}
								fieldError={fieldError}
								key={`${question.key}-${i}`}
								fromChatbot={fromChatbot}
							/>
						);
						break;
					case "assetMap":
						element = (
							<AssetMap
								locationId={savedLocation.id}
								org={org}
								isFocused={true}
								chosenAssetType={responseData.assetType}
								tagId={responseData.assetTag ? responseData.assetTag : null}
								areAssetsDraggable={false}
								isSingleLocationTab={false}
								key={`${question.key}-${i}`}
								fromChatbot={fromChatbot}
							/>
						);
						break;
				}
		}

		return element;
	});
};
