import { Modal } from "antd";
import { useRef } from "react";
import { AddSingleLocation } from "./AddSingleLocation";

const AddLocationModal = ({ modalOpen, toggle, organization }) => {
	const toggleModal = () => {
		toggle();
	};

	const formRef = useRef(null);

	const handleOk = () => {
		if (formRef.current) {
			formRef.current.submit();
		}
	};

	return (
		<Modal
			open={modalOpen}
			onCancel={toggleModal}
			centered
			title="Add Location"
			onOk={handleOk}
		>
			<AddSingleLocation
				formRef={formRef}
				modalOpen={modalOpen}
				toggle={toggle}
				organization={organization}
				toggleModal={toggleModal}
			/>
		</Modal>
	);
};

export { AddLocationModal };
