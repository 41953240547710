import { assetPageFields } from "@constants/formInputs/pageFields";
import {
	getAdditionalDetailsFields,
	getNotAvailableMapping,
} from "./getAdditionalDetailsFields";
import { getArcAdditionalDetailsFields } from "./getArcAdditionalDetailsFields";
import { getCvsAdditionalDetailsPageFields } from "./getCvsAdditionalDetailsPageFields";
import { getSwarovskiFields } from "./getSwarovskiFields";
import {
	isFieldOrNotFoundPresentAdditionalDetails as isFieldOrNotFoundPresentAdditionalDetailsWrapper,
	isFieldOrNotFoundPresent as isFieldOrNotFoundPresentWrapper,
} from "./isFieldOrNotFoundPresent";

export const validateRequiredFields = (
	organization,
	responseData,
	currentPage,
	setAllRequiredFieldsPresent,
	setAllRequiredFieldsPresentAdditionalDetails,
	fromChatbot = false,
) => {
	const cvsAdditionalDetailsPageFields = getCvsAdditionalDetailsPageFields(
		organization,
		responseData,
	);

	const arcAdditionalDetailsFields = getArcAdditionalDetailsFields(
		organization,
		responseData,
	);

	const amdAdditionalDetailsFields = getArcAdditionalDetailsFields(
		organization,
		responseData,
	);

	const swarovskiFields = getSwarovskiFields(responseData);

	const campingWorldAdditionalDetailsPageFields = getAdditionalDetailsFields(
		organization,
		responseData,
		swarovskiFields,
	);

	const notAvailableCampingWorldMapping = getNotAvailableMapping(organization);
	const isFieldOrNotFoundPresentAdditionalDetails = (field) =>
		isFieldOrNotFoundPresentAdditionalDetailsWrapper(
			field,
			currentPage,
			organization,
			responseData,
			notAvailableCampingWorldMapping,
			fromChatbot,
		);

	const isFieldOrNotFoundPresent = (field) =>
		isFieldOrNotFoundPresentWrapper(
			field,
			currentPage,
			responseData,
			fromChatbot,
			organization,
		);

	const allRequiredFields = assetPageFields.every(isFieldOrNotFoundPresent);

	if (!allRequiredFields) {
		console.warn("One or more fields in assetPageFields failed validation.");
	}
	setAllRequiredFieldsPresent(allRequiredFields);

	let allRequiredFieldsAdditionalDetails = false;
	if (organization === "cvs.com") {
		allRequiredFieldsAdditionalDetails = cvsAdditionalDetailsPageFields.every(
			isFieldOrNotFoundPresentAdditionalDetails,
		);
	} else if (
		organization === "Arc Burger" ||
		organization === "Home Depot" ||
		organization === "Thortons"
	) {
		allRequiredFieldsAdditionalDetails = arcAdditionalDetailsFields.every(
			isFieldOrNotFoundPresentAdditionalDetails,
		);
	} else if (organization === "AMD") {
		allRequiredFieldsAdditionalDetails = amdAdditionalDetailsFields.every(
			isFieldOrNotFoundPresentAdditionalDetails,
		);
	} else if (
		organization === "campingworld.com" ||
		organization === "foodlion.com" ||
		organization === "carliecs.com" ||
		organization === "raisingcanes.com" ||
		organization === "razzoos.com" ||
		organization === "DIGI" ||
		organization === "swarovski.com" ||
		organization === "Smash Burger" ||
		organization === "CARMAX" ||
		organization === "applegreen" ||
		organization === "VSM" ||
		organization === "COOPERSHAWK" ||
		organization === "SSPAMERICA" ||
		organization === "Willie's" ||
		organization === "BJsRestaurant" ||
		organization === "EcoTrak"
	) {
		allRequiredFieldsAdditionalDetails =
			campingWorldAdditionalDetailsPageFields.every(
				isFieldOrNotFoundPresentAdditionalDetails,
			);
	}
	setAllRequiredFieldsPresentAdditionalDetails(
		allRequiredFieldsAdditionalDetails,
	);
};
