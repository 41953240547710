export const refrigerantOptions = [
	"R12",
	"R1233ZD",
	"R1234YF",
	"R1234ZE",
	"R1336MZZ",
	"R134A",
	"R170",
	"R22",
	"R245FA",
	"R290",
	"R32",
	"R401A",
	"R401B",
	"R401C",
	"R402A",
	"R404",
	"R404A",
	"R407A",
	"R407C",
	"R408A",
	"R410A",
	"R413A",
	"R414A",
	"R418A",
	"R422D",
	"R424A",
	"R426A",
	"R428A",
	"R434A",
	"R438A",
	"R442A",
	"R448A",
	"R449A",
	"R450A",
	"R453A",
	"R454C",
	"R455A",
	"R464A",
	"R500",
	"R502",
	"R507",
	"R513A",
	"R600A",
	"R717",
	"R744",
];
