import { DFPModal } from "@/components/Modals/dfp-modal.js";
import { CSVExporter } from "@/components/pure/CSVExporter/csv-exporter.js";
import { csvFieldTransformFnMap } from "@/components/pure/CSVExporter/field-transforms.js";
import { getSelfForms } from "@/services/form-service";
import { searchSubmissions } from "@/services/submission-service.js";
import { getOrganizationSubmittedUsers } from "@/services/user-service.js";
import { useUserStore } from "@/stores/index.js";
import { flattenRawInput } from "@/utils/flattenRawInputs.js";
import { isUnknownValue } from "@/utils/isUnknownValue.js";
import { normalizeStringForComparison } from "@/utils/transforms.js";
import { DB_ORG } from "@constants/db";
import {
	selfOrganizationAssetTypeCategories,
	selfOrganizationAssetTypes,
} from "@services/organization-service";
import { DatePicker, Form, Select, message } from "antd";
import dayjs from "dayjs";
import {
	type Reducer,
	useCallback,
	useEffect,
	useReducer,
	useState,
} from "react";
import { formatDate } from "./formatDate.js";

interface Option<T> {
	label?: string;
	value: T | null;
}

interface FormOption extends Option<number | null | string | boolean> {
	value: number | null | string | boolean;
}

interface SortOption extends Option<string> {
	value: string;
}

interface FilterFieldOption extends Option<string> {
	value: string;
}

interface ApprovalOption extends Option<boolean | null> {
	value: boolean | null;
}

interface FormModeOption extends Option<number> {
	value: number;
}

interface ExportModalState {
	loading: boolean;
	filterField: FilterFieldOption;
	filterOptions: Option<any>[];
	selectedOption: Option<any> | null;
	sortOption: SortOption;
	sortDirection: SortOption;
	showApproved: ApprovalOption;
	selectedForm: FormOption;
	startDate: string | null;
	endDate: string | null;
	formOptions: FormOption[];
	formModeOption: FormModeOption;
	isReturningDeleted: boolean;
	isFormValid: boolean;
}

interface ExportModalAction {
	type: string;
	payload: any;
}

const sortFieldOptions: SortOption[] = [
	{ label: "Submission Date", value: "submitted_date" },
	{ label: "Submitting User", value: "submitting_user" },
];

const sortDirectionOptions: SortOption[] = [
	{ label: "Ascending", value: "asc" },
	{ label: "Descending", value: "desc" },
];

const defaultFormOptions: FormOption[] = [
	{ label: "Inventory & Tagging Form", value: 3 },
	{ label: "Facility Vision Form", value: 1 },
	{ label: "Property Condition Assessment", value: 7 },
];

const approvalOptions: ApprovalOption[] = [
	{ label: "Approved", value: true },
	{ label: "Unapproved", value: false },
	{ label: "Both", value: null },
];

const filterFieldOptions: FilterFieldOption[] = [
	{ label: "(no filter applied)", value: "" },
	{ label: "Submitting User", value: "submitting_user" },
	{ label: "Asset Type Category", value: "asset_type_category" },
	{ label: "Asset Type", value: "asset_type" },
];

const formModeOptions: FormModeOption[] = [
	{ label: "Submissions", value: 0 },
	{ label: "QA Submissions", value: 1 },
	{ label: "Draft Submissions", value: 2 },
];

const cushmanFormOptions: FormOption[] = [
	{ label: "Submissions", value: 0 },
	{ label: "Draft Submissions", value: 2 },
];

// Helper function to check if the form is valid
const checkFormValidity = (state: ExportModalState) => {
	const requiredFields = [state.selectedForm, state.formModeOption];
	if (state.filterField && state.filterField.value !== "") {
		requiredFields.push(state.selectedOption);
	}
	const isValid = requiredFields.every((field) => {
		return field === null
			? false
			: field?.value === 0
				? true
				: field?.value !== "" && field?.value !== null;
	});
	return isValid;
};

// Initial state
const initialState: ExportModalState = {
	loading: false,
	filterField: filterFieldOptions[0],
	filterOptions: [],
	selectedOption: null,
	sortOption: sortFieldOptions[0],
	sortDirection: sortDirectionOptions[1],
	showApproved: approvalOptions[2],
	selectedForm: defaultFormOptions[0],
	startDate: null,
	endDate: null,
	formOptions: [],
	formModeOption: formModeOptions[0],
	isReturningDeleted: false,
	isFormValid: true,
};

// Reducer to manage state
const reducer: Reducer<ExportModalState, ExportModalAction> = (
	state,
	action,
) => {
	switch (action.type) {
		case "SET_LOADING":
			return { ...state, loading: action.payload };
		case "SET_FILTER_FIELD":
			return {
				...state,
				filterField: action.payload,
				selectedOption: null,
			};
		case "SET_FILTER_OPTIONS":
			return {
				...state,
				filterOptions: action.payload,
				selectedOption: null,
			};
		case "SET_SELECTED_OPTION":
			return { ...state, selectedOption: action.payload };
		case "SET_SORT_OPTION":
			return { ...state, sortOption: action.payload };
		case "SET_SORT_DIRECTION":
			return { ...state, sortDirection: action.payload };
		case "SET_SHOW_APPROVED":
			return { ...state, showApproved: action.payload };
		case "SET_SELECTED_FORM":
			return { ...state, selectedForm: action.payload };
		case "SET_START_DATE":
			return { ...state, startDate: action.payload };
		case "SET_END_DATE":
			return { ...state, endDate: action.payload };
		case "SET_TOAST":
			return {
				...state,
				toastColor: action.payload.color,
				toastMessage: action.payload.message,
				toastVisible: action.payload.isVisible,
			};
		case "SET_FORM_OPTIONS":
			return { ...state, formOptions: action.payload };
		case "SET_FORM_MODE":
			return { ...state, formModeOption: action.payload };
		case "SET_RETURNING_DELETED":
			return { ...state, isReturningDeleted: action.payload };
		case "SET_IS_FORM_VALID":
			return { ...state, isFormValid: action.payload };
		case "SET_USERS":
			return { ...state, users: action.payload };
		case "RESET":
			return initialState;
		default:
			return state;
	}
};

interface ExportModalProps {
	showExport: boolean;
	toggle: () => void;
	browserLocationId: string;
}

const ExportModal = ({
	showExport,
	toggle,
	browserLocationId,
}: ExportModalProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [csvData, setCsvData] = useState<any[] | null>(null);
	const user = useUserStore((state) => state.user);
	const organization = user?.organization?.external_firebase_id;

	const handleToggle = useCallback(() => {
		dispatch({ type: "RESET", payload: null });
		setCsvData(null);
		toggle();
	}, [toggle]);

	useEffect(() => {
		const fetchFieldOptions = async () => {
			let options: Option<unknown>[] = [];
			if (state.filterField?.value === "submitting_user") {
				const users = await getOrganizationSubmittedUsers(
					state.selectedForm.value,
				);
				options = users.map((user: string) => ({
					label: user,
					value: user,
				}));
			} else if (state.filterField?.value === "reviewed") {
				options = [
					{ label: "True", value: true },
					{ label: "False", value: false },
				];
			} else if (state.filterField?.value === "asset_type_category") {
				await selfOrganizationAssetTypeCategories().then(
					(res) =>
						(options = res.map((category) => ({
							label: category.name,
							value: category.name,
						}))),
				);
			} else if (state.filterField?.value === "asset_type") {
				await selfOrganizationAssetTypes().then(
					(res) =>
						(options = res.map((type) => ({
							label: type.name,
							value: type.name,
						}))),
				);
			} else if (state.filterField) {
				const exportOptions =
					organization?.config?.export_options?.[state?.filterField?.value] ||
					[];
				options = exportOptions.map((option) => ({
					label: option,
					value: option,
				}));
			} else if (state.filterField?.value === "asset_type") {
				const res = await selfOrganizationAssetTypes();
				options = res.map((type: { name: string }) => ({
					label: type.name,
					value: type.name,
				}));
			}
			dispatch({ type: "SET_FILTER_OPTIONS", payload: options });
		};

		if (state.filterField) {
			dispatch({ type: "SET_FILTER_OPTIONS", payload: [] });
			fetchFieldOptions();
		}
	}, [state.filterField]);

	useEffect(() => {
		const fetchForms = async () => {
			try {
				const forms = await getSelfForms();
				const formOptions = forms.map(
					(form: {
						master_form_key: { name: string; id: number };
						form_id: number;
					}) => ({
						label: form.master_form_key.name,
						value: form.form_id || form.master_form_key.id,
					}),
				);
				dispatch({ type: "SET_FORM_OPTIONS", payload: formOptions });
				if (organization === DB_ORG.CUSHMANWAKEFIELD) {
					dispatch({
						type: "SET_SELECTED_FORM",
						payload: formOptions.find(
							(option) => option.label === "Handyman Checklist",
						),
					});
				}
			} catch (error: any) {
				console.error("Error fetching forms:", error.message);
			}
		};
		if (state.formOptions.length === 0) {
			dispatch({ type: "SET_FORM_OPTIONS", payload: [] });
			fetchForms();
		}
	}, [organization.external_firebase_id, state.formOptions.length]);

	useEffect(() => {
		dispatch({
			type: "SET_IS_FORM_VALID",
			payload: checkFormValidity(state),
		});
	}, [
		state.selectedForm,
		state.formModeOption,
		state.filterField,
		state.selectedOption,
		state.sortOption,
		state.sortDirection,
		state.startDate,
		state.endDate,
		state.isReturningDeleted,
	]);

	const triggerExport = useCallback(async () => {
		dispatch({ type: "SET_LOADING", payload: true });
		message.info("Exporting...");
		const columnFilters =
			state.filterField && state.selectedOption
				? { [state.filterField.value]: [state.selectedOption.value] }
				: {};
		console.log(columnFilters);
		try {
			const result = await searchSubmissions(
				state.selectedForm?.value as string,
				state.formModeOption?.value,
				0,
				100000,
				state.sortOption?.value,
				state.sortDirection?.value,
				{},
				"",
				columnFilters,
				browserLocationId,
				state.isReturningDeleted,
				state.startDate && state.endDate
					? [state.startDate, state.endDate]
					: null,
				state.showApproved?.value,
			);

			if (result && result?.results?.length > 0) {
				// flatten raw_input into key-value pairs with custom columns for "Category", "Item", "Action Taken", "Image Before", and "Image After"

				// Parse raw_input for each row, applying the format only for organization "Cushman"
				const enhancedResults = result.results.map(
					(row: {
						raw_input: never[];
						id: string | number;
						submitted_date: string;
						submitting_user: string;
						location: { address1: string };
						is_approved: boolean;
						form_name: string;
					}) => {
						if (organization.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD) {
							const rawInputData = row.raw_input || [];
							const parsedRawInput = flattenRawInput(
								rawInputData,
								organization.external_firebase_id,
							);

							return parsedRawInput.map((parsedInput) => ({
								id: row.id,
								date: formatDate(row.submitted_date),
								vendorName: row.submitting_user,
								location: row.location ? row.location.address1 : "",
								approved: row.is_approved,
								item: parsedInput.item,
								category: parsedInput.category,
								description: parsedInput.description,
								actionTaken: parsedInput.actionTaken,
								imageBefore: parsedInput.imageBefore,
								imageAfter: parsedInput.imageAfter,
							}));
						}
						if (
							organization.external_firebase_id === DB_ORG.RAISINGCANES &&
							normalizeStringForComparison(state.selectedForm.label || "") ===
								normalizeStringForComparison("Property Condition Assesment")
						) {
							const rawInputData = row.raw_input || [];
							const parsedRawInput = flattenRawInput(
								rawInputData,
								organization.external_firebase_id,
							);

							// Merge parsed raw_input data for RC format
							return parsedRawInput.map((parsedInput) => ({
								location: row.location ? row.location.address1 : "",
								date: formatDate(row.submitted_date),
								formName: row.form_name,
								...parsedInput,
							}));
						}
						return [row];
					},
				);

				// Flatten the array of arrays into a single array
				const flattenedResults = enhancedResults.flat();

				// Set CSV data with the defined column order
				setCsvData(flattenedResults); // Set the enhanced CSV data with parsed raw_input

				if (
					organization.external_firebase_id !== DB_ORG.CUSHMANWAKEFIELD &&
					organization.external_firebase_id !== DB_ORG.RAISINGCANES
				) {
					const filteredUnknownResults = result.results.map(
						(submission: Record<string, any>) => {
							const newSubmission = { ...submission };
							const model = newSubmission.asset_info?.model;
							const manufacturer = newSubmission.asset_info?.manufacturer;
							if (model && isUnknownValue(model)) {
								newSubmission.asset_info.model = "N/A";
							}
							if (manufacturer && isUnknownValue(manufacturer)) {
								newSubmission.asset_info.manufacturer = "N/A";
							}
							return newSubmission;
						},
					);

					setCsvData(filteredUnknownResults); // Set CSV data when the results are fetched
				} else {
					setCsvData(flattenedResults); // Set the enhanced CSV data with parsed raw_input
				}
				// setCsvData(filteredUnknownResults); // Set CSV data when the results are fetched
				message.success("Export successful");
				// handleToggle();
				setTimeout(() => {
					handleToggle();
				}, 1000);
			} else {
				message.error("No data found for export");
			}
		} catch (error: any) {
			console.error("Export failed:", error.message);
			message.error(`Export failed: ${error.message}`);
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
			setTimeout(() => {
				setCsvData(null); // Reset csvData to ensure re-render for next download
			}, 500);
		}
	}, [
		browserLocationId,
		state.endDate,
		state.filterField,
		state.formModeOption?.value,
		state.isReturningDeleted,
		state.selectedForm?.value,
		state.selectedOption,
		state.sortDirection?.value,
		state.sortOption?.value,
		state.startDate,
		state.showApproved?.value,
	]);

	// UI for modal content (filters, sort options, etc.)
	return (
		<DFPModal
			open={showExport}
			className="export-modal"
			onCancel={handleToggle}
			onOk={triggerExport}
			title="Export CSV - Custom Options"
			confirmLoading={state.loading}
			okButtonProps={{ disabled: !state.isFormValid }}
			okText="Export"
		>
			<Form layout="vertical">
				{/* Filter Header */}
				<div className="mb-2 fs-5">Filter Your Submissions</div>
				{/* Form Selector */}
				{(organization === DB_ORG.CUSHMANWAKEFIELD ||
					organization === DB_ORG.RAISINGCANES) && (
					<Form.Item
						label={
							<span className="d-flex align-items-center">
								Select a Form to Export
							</span>
						}
						required
					>
						<Select
							options={state.formOptions}
							value={state.selectedForm?.value}
							onChange={(value, option) => {
								dispatch({
									type: "SET_SELECTED_FORM",
									payload: option,
								});
							}}
						/>
					</Form.Item>
				)}

				{/* Form Mode Selector */}
				<Form.Item
					label={
						<span className="d-flex align-items-center">
							Select a Form Mode
						</span>
					}
					required
				>
					<Select
						options={
							organization === DB_ORG.CUSHMANWAKEFIELD
								? cushmanFormOptions
								: formModeOptions
						}
						value={state.formModeOption?.value}
						onChange={(value, option) => {
							dispatch({
								type: "SET_FORM_MODE",
								payload: option,
							});
						}}
					/>
				</Form.Item>

				{/* Date Range Inputs */}
				<Form.Item
					label={
						<span className="d-flex align-items-center">
							Select a Date Range
						</span>
					}
				>
					<DatePicker.RangePicker
						className="w-100"
						value={[
							state.startDate ? dayjs(state.startDate) : null,
							state.endDate ? dayjs(state.endDate) : null,
						]}
						onChange={(dates) => {
							const [start, end] = dates || [];
							dispatch({
								type: "SET_START_DATE",
								payload: start ? start.format("YYYY-MM-DD") : "",
							});
							dispatch({
								type: "SET_END_DATE",
								payload: end ? end.format("YYYY-MM-DD") : "",
							});
						}}
					/>
				</Form.Item>

				{/* Filter Field Selector */}
				<Form.Item label="Select a Field to Filter Results">
					<Select
						options={filterFieldOptions}
						placeholder="Select a Field"
						value={state.filterField?.value}
						onChange={(value, option) => {
							dispatch({
								type: "SET_FILTER_FIELD",
								payload: option,
							});
						}}
					/>
				</Form.Item>

				{/* Filter Options Selector */}
				{state.filterField && state.filterField.value !== "" && (
					<Form.Item
						label={
							<span className="d-flex align-items-center">
								Select an Option for the Filtering Field
							</span>
						}
						required
					>
						<Select
							loading={state.filterOptions.length === 0 || state.loading}
							disabled={state.filterOptions.length === 0 || state.loading}
							options={state.filterOptions}
							value={state.selectedOption?.value}
							onChange={(value, option) => {
								dispatch({
									type: "SET_SELECTED_OPTION",
									payload: option,
								});
							}}
						/>
					</Form.Item>
				)}

				{/* Sort Field Selector */}
				<Form.Item
					label={
						<span className="d-flex align-items-center">
							Select Which Field to Sort By
						</span>
					}
					required
				>
					<Select
						options={sortFieldOptions.map((option) => ({
							...option,
							disabled:
								state.filterField && state.filterField.value === option.value,
						}))}
						value={state.sortOption?.value}
						onChange={(value, option) => {
							dispatch({
								type: "SET_SORT_OPTION",
								payload: option,
							});
						}}
					/>
				</Form.Item>

				{/* Sort Direction Selector */}
				<Form.Item
					label={
						<span className="d-flex align-items-center">
							Select Sort Direction
						</span>
					}
					required
				>
					<Select
						options={sortDirectionOptions}
						value={state.sortDirection?.value}
						onChange={(value, option) => {
							dispatch({
								type: "SET_SORT_DIRECTION",
								payload: option,
							});
						}}
					/>
				</Form.Item>

				{/* Approval Status Selector */}
				{organization.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD && (
					<Form.Item
						label={
							<span className="d-flex align-items-center">
								Include Approved, Unapproved, or Both?
							</span>
						}
						required
					>
						<Select
							options={approvalOptions}
							value={state.showApproved?.value}
							onChange={(value, option) => {
								dispatch({
									type: "SET_SHOW_APPROVED",
									payload: option,
								});
							}}
						/>
					</Form.Item>
				)}

				{/* IsReturningDeleted Select */}
				<Form.Item
					label={
						<span className="d-flex align-items-center">
							Include Deleted Submissions?
						</span>
					}
					required
				>
					<Select
						options={[
							{ label: "No", value: false },
							{ label: "Yes", value: true },
						]}
						value={state.isReturningDeleted}
						onChange={(value) => {
							dispatch({
								type: "SET_RETURNING_DELETED",
								payload: value,
							});
						}}
					/>
				</Form.Item>
			</Form>
			{/* Render CSVDownload when csvData is populated */}
			{csvData && (
				<CSVExporter
					data={csvData}
					filename="exported-data.csv"
					autoDownload
					fieldTransforms={csvFieldTransformFnMap}
					organization={organization.external_firebase_id}
					formId={state?.selectedForm?.value.toString()}
				/>
			)}
		</DFPModal>
	);
};

export { ExportModal };
