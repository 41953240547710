import { forwardRef } from "react";
import { Bar } from "react-chartjs-2";
import { Loader } from "../Loader/Loader";

const HealthScoreHoverGraph = forwardRef(({ chartData }, ref) => {
	const options = {
		scales: {
			y: {
				beginAtZero: true,
				max: 100,
				ticks: {
					// Include a % sign in the ticks
					callback: (value) => `${value}%`,
				},
			},
			x: {
				display: true,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
		maintainAspectRatio: false,
	};

	return (
		<div
			className="health-score-graph-wrapper"
			ref={ref}
			style={{
				height: "800px",
				width: "100%",
				padding: "20px",
				position: "relative",
				zIndex: 1000,
				maxHeight: "1200px",
				border: "none",
			}}
		>
			{!chartData ? <Loader /> : <Bar data={chartData} options={options} />}
		</div>
	);
});

HealthScoreHoverGraph.displayName = "HealthScoreHoverGraph";

export { HealthScoreHoverGraph };
