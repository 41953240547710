import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { ColumnsType } from "antd/es/table";

export const columns: ColumnsType = [
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
	},
	{
		title: "Role",
		dataIndex: "role",
		key: "role",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
];
