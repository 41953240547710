import { parseDateToUTCString } from "@/utils/transforms";

export const convertToLocaleDateString = (
	value: string | number | Date,
): string => {
	if (!value) return "N/A";
	if (value === "N/A") return value;
	return parseDateToUTCString(value) || "N/A";
};

export const convertToCurrency = (value: string): string => {
	if (!value) return "N/A";
	if (value === "N/A") return value;
	if (Number.isNaN(value)) return value;
	return `$${Number.parseFloat(value).toFixed(2)}`;
};

export const convertToPercentage = (value: string): string => {
	if (!value) return "N/A";
	if (value === "N/A") return value;
	if (Number.isNaN(value)) return value;
	return `${value}%`;
};

export const convertToDegrees = (value: string): string => {
	if (!value) return "N/A";
	if (value === "N/A") return value;
	if (Number.isNaN(value)) return value;
	return `${value}°`;
};

export const convertToReadableYearValue = (value: string | number): string => {
	if (!value) return "N/A";
	if (value === "N/A") return value;
	if (Number.isNaN(value)) return value.toString();
	return value === 1 ? `${value} year` : `${value} years`;
};

export const csvFieldTransformFnMap: { [x: string]: CallableFunction } = {
	"model_info.average_replacement_cost_usd": (value: string) =>
		convertToCurrency(value),
	"model_info.average_useful_life": (value: string) =>
		convertToReadableYearValue(value),
	health_score: (value: string) => convertToPercentage(value),
	"unique_fields.userLocation.latitude": (value: string) =>
		convertToDegrees(value),
	"unique_fields.userLocation.longitude": (value: string) =>
		convertToDegrees(value),
	submitted_date: (value: string) => convertToLocaleDateString(value),
	date_of_birth: (value: string) => convertToLocaleDateString(value),
	"manufacturer.time_added": (value: string) =>
		convertToLocaleDateString(value),
	"asset_info.manufacturer_date": (value: string) =>
		convertToLocaleDateString(value),
};
