import { formatDate } from "@/utils/ISOdateToMMDDYYYY";
import { useState } from "react";
import {
	Button,
	Col,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from "reactstrap";

const AuditLogModal = ({ isOpen, setIsOpen, assetAuditData }) => {
	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const toggleImageModal = () => setShowImageModal(!showImageModal);

	const openImageModal = (imageUrl) => {
		setSelectedImage(imageUrl);
		toggleImageModal();
	};

	const renderContent = (content, isImage = false) => {
		if (isImage && content) {
			return (
				<img
					src={content}
					alt="Audit Change"
					style={{
						maxWidth: "100%",
						height: "auto",
						cursor: "pointer",
					}}
					onClick={() => openImageModal(content)}
				/>
			);
		}
		return <span>{content}</span>;
	};

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	return (
		<Modal isOpen={isOpen} toggle={() => setIsOpen(false)} centered size="lg">
			<ModalHeader toggle={() => setIsOpen(false)}>
				Latest CheckUp Log
			</ModalHeader>
			<ModalBody style={{ backgroundColor: "#f8f9fa" }}>
				{assetAuditData && (
					<div>
						<h6
							className="text-center mb-4"
							style={{ color: "rgb(111 111 111)" }}
						>
							Last CheckUp done on:{" "}
							{formatDate(assetAuditData.auditedDate) || "N/A"}
						</h6>
						{assetAuditData.changes &&
							Object.entries(assetAuditData.changes).map(([key, value]) => {
								if (key === "locationId") return null;

								return (
									<FormGroup
										key={key}
										className="mb-4"
										style={{
											backgroundColor: "#ffffff",
											padding: "15px",
											borderRadius: "8px",
											boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
										}}
									>
										{" "}
										{/* Enhanced FormGroup styling */}
										<Label
											className="mb-2"
											style={{
												fontSize: "18px",
												fontWeight: "bold",
											}}
										>
											{capitalizeFirstLetter(
												key.replace(/([A-Z])/g, " $1"),
											).trim()}
										</Label>
										<Row>
											<Col xs="6">
												<Label>Before:</Label>
												<div
													className="p-2"
													style={{
														border: "1px solid #ccc",
														borderRadius: "5px",
														marginTop: "8px",
													}}
												>
													{renderContent(
														value.before,
														key.toLowerCase().includes("image"),
													)}
												</div>
											</Col>
											<Col xs="6">
												<Label>After:</Label>
												<div
													className="p-2"
													style={{
														border: "1px solid #ccc",
														borderRadius: "5px",
														marginTop: "8px",
													}}
												>
													{renderContent(
														value.after,
														key.toLowerCase().includes("image"),
													)}
												</div>
											</Col>
										</Row>
									</FormGroup>
								);
							})}
					</div>
				)}

				<Modal
					isOpen={showImageModal}
					toggle={toggleImageModal}
					centered
					size="lg"
				>
					<ModalBody className="text-center">
						<img
							src={selectedImage}
							alt="Enlarged Audit Change"
							style={{ maxWidth: "100%" }}
						/>
						<Button
							color="secondary"
							onClick={toggleImageModal}
							className="mt-2"
						>
							Close
						</Button>
					</ModalBody>
				</Modal>
			</ModalBody>
		</Modal>
	);
};

export default AuditLogModal;
