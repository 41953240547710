import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { parseDateToLocalString } from "@/utils/transforms";

// Define table columns
export const draftColumns: ColumnsType<Draft> = [
	{
		title: "Address",
		dataIndex: "address",
		key: "address",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		width: "30%",
		render: (text) => <CopyableTableCell text={text} />,
	},
	{
		title: "Submitting User",
		dataIndex: "submitting_user",
		key: "submitting_user",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Submission Date",
		dataIndex: "submitted_date",
		key: "submitted_date",
		sorter: true,
		defaultSortOrder: "descend",
		sortDirections: ["descend", "ascend", "descend"],
		render: (date) => parseDateToLocalString(date),
	},
];
