const getUsefulLifeRemainingDays = (asset) => {
	const dob = asset.dateOfBirth || asset.estDateOfBirth;
	const usefulLife = asset.avgUsefulLifeYears;

	if (!usefulLife || !dob) {
		return 0;
	}

	// Parse the date of birth (dob)
	const dobDate = new Date(dob);

	// Calculate the number of days the asset has been in use
	const today = new Date();
	const timeInUseMs = today - dobDate;
	const daysInUse = Math.floor(timeInUseMs / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

	// Convert useful life in years to days
	const usefulLifeDays = usefulLife * 365;

	// Calculate remaining useful life in days
	const remainingDays = usefulLifeDays - daysInUse;

	// If the asset is past its useful life, return 0, otherwise return remaining days
	return remainingDays > 0 ? remainingDays : 0;
};

export const getSummaryRows = (assets) => {
	// reduce assets to one object per asset type, with columns for asset count, total replacement cost, and average health score
	const assetTypeSummary = assets.reduce((acc, asset) => {
		const assetType = asset.assetType;
		const replCost = Number(asset.avgReplCostUSD);
		const healthScore = Number(asset.healthScore);
		if (!Number.isNaN(replCost) && !Number.isNaN(healthScore)) {
			if (!acc[assetType]) {
				acc[assetType] = {
					unitCount: 1,
					totalReplCost: replCost,
					totalHealthScore: healthScore,
					assetType: assetType,
					usefulLifeRemainingDays: getUsefulLifeRemainingDays(asset),
				};
			} else {
				acc[assetType].unitCount++;
				acc[assetType].totalReplCost += replCost;
				acc[assetType].totalHealthScore += healthScore;
				acc[assetType].assetType = assetType;
				acc[assetType].usefulLifeRemainingDays +=
					getUsefulLifeRemainingDays(asset);
			}
		}
		return acc;
	}, {});
	const assetArray = Object.values(assetTypeSummary).map((asset) => ({
		assetType: asset.assetType,
		totalReplCost: Math.round(asset.totalReplCost),
		unitCount: asset.unitCount,
		healthScore: Math.round(asset.totalHealthScore / asset.unitCount),
		usefulLifeRemainingDays: Math.round(
			asset.usefulLifeRemainingDays / asset.unitCount,
		),
	}));
	return assetArray;
};
