import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export const useOrganizationStore = create((set) => ({
	orgData: {},
	setOrgData: (data: { integrationSettings: any }) => {
		set((state: { orgData: { integrationSettings: any } }) => {
			if (!data) {
				return state;
			}
			const updatedIntegrationSettings = {
				...(state.orgData.integrationSettings || {}),
				...(data.integrationSettings || {}),
			};
			const newState = {
				orgData: {
					...state.orgData,
					...data,
					integrationSettings: updatedIntegrationSettings,
				},
			};
			return newState;
		});
	},
}));

if (process.env.NODE_ENV === "development") {
	mountStoreDevtool("orgDataStore", useOrganizationStore);
}
