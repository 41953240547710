export const statesCVS = [
	"CA",
	"FL",
	"TX",
	"NY",
	"PA",
	"MA",
	"VA",
	"NC",
	"OH",
	"NJ",
	"GA",
	"IN",
	"IL",
	"MI",
	"SC",
	"MD",
	"CT",
	"AL",
	"AZ",
	"TN",
	"MO",
	"LA",
	"NV",
	"MN",
	"KY",
	"RI",
	"WI",
	"WV",
	"OK",
	"DC",
	"NH",
	"HI",
	"MS",
	"KS",
	"CO",
	"WA",
	"IA",
	"ME",
	"OR",
	"PR",
	"UT",
	"DE",
	"NM",
	"NE",
	"AR",
	"MT",
	"VT",
	"ND",
	"ID",
	"SD",
	"AK",
	"WY",
];

export const statesCushman = [
	"CA",

	"TX",

	"FL",

	"NY",

	"IL",

	"OH",

	"GA",

	"NC",

	"MI",

	"PA",

	"NJ",

	"AZ",

	"VA",

	"CO",

	"WA",

	"TN",

	"AL",

	"SC",

	"IN",

	"MA",

	"MN",

	"MD",

	"WI",

	"LA",

	"OR",

	"CT",

	"MO",

	"IA",

	"NE",

	"KS",

	"AR",

	"OK",

	"UT",

	"HI",

	"NV",

	"KY",

	"NH",

	"MS",

	"ID",

	"MT",

	"NM",

	"ME",

	"DE",

	"ND",

	"SD",

	"RI",

	"AK",

	"DC",

	"VT",

	"WV",

	"WY",
];
export const statesRC = [
	"TX",

	"CA",

	"LA",

	"OH",

	"AZ",

	"IL",

	"OK",

	"MO",

	"CO",

	"NV",

	"NE",

	"KY",

	"MN",

	"FL",

	"MS",

	"AR",

	"PA",

	"UT",

	"IA",

	"IN",

	"VA",

	"KS",

	"MD",

	"MA",

	"GA",

	"TN",

	"NM",

	"SC",

	"AL",

	"NJ",

	"NY",

	"WI",

	"DC",

	"DE",

	"MI",

	"OR",

	"RI",
];
export const statesFL = [
	"NC",

	"VA",

	"SC",

	"MD",

	"GA",

	"TN",

	"DE",

	"WV",

	"KY",

	"PA",

	"TX",
];

export const stateLabels = {
	AL: "Alabama",
	AK: "Alaska",
	AZ: "Arizona",
	AR: "Arkansas",
	CA: "California",
	CO: "Colorado",
	CT: "Connecticut",
	DE: "Delaware",
	DC: "District of Columbia",
	PR: "Puerto Rico",
	FL: "Florida",
	GA: "Georgia",
	HI: "Hawaii",
	ID: "Idaho",
	IL: "Illinois",
	IN: "Indiana",
	IA: "Iowa",
	KS: "Kansas",
	KY: "Kentucky",
	LA: "Louisiana",
	ME: "Maine",
	MD: "Maryland",
	MA: "Massachusetts",
	MI: "Michigan",
	MN: "Minnesota",
	MS: "Mississippi",
	MO: "Missouri",
	MT: "Montana",
	NE: "Nebraska",
	NV: "Nevada",
	NH: "New Hampshire",
	NJ: "New Jersey",
	NM: "New Mexico",
	NY: "New York",
	NC: "North Carolina",
	ND: "North Dakota",
	OH: "Ohio",
	OK: "Oklahoma",
	OR: "Oregon",
	PA: "Pennsylvania",
	RI: "Rhode Island",
	SC: "South Carolina",
	SD: "South Dakota",
	TN: "Tennessee",
	TX: "Texas",
	UT: "Utah",
	VT: "Vermont",
	VA: "Virginia",
	WA: "Washington",
	WV: "West Virginia",
	WI: "Wisconsin",
	WY: "Wyoming",
};
