import { inputs as notRequiredInputs } from "@constants/formInputs/notRequiredInputs";
import { default as initInputs } from "@constants/formInputs/taggingFormInputs";
import { warrantyFieldsSwarovski } from "@constants/formInputs/warranty";
import { useChatbotHistory } from "@contexts/chatbotContext";
import { getOptionsData } from "@services/form-service";
import {
	useChatbotHighLevelStore,
	useChatbotStore,
	usePageStore,
	useUserStore,
} from "@stores";
import {
	addScanConfidence as addScanConfidenceWrapper,
	capturePhoto as capturePhotoWrapper,
	clearScanConfidence as clearScanConfidenceWrapper,
	getAssetConditionalInputs,
	handleNavigateAway,
	populateInputs as populateInputsWrapper,
	saveDraft,
	setDefaultValues as setDefaultValuesWrapper,
	updateInputsRequiredChange,
	warrantyLogic as warrantyLogicWrapper,
} from "@views/SignedIn/SingleForm/childFunctions";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { isEqual } from "../../../../utils/isEqual";
import {
	handleAssetDetection,
	handleAssetIdPrompt,
	handleAssetLocation,
	handleAssetPhoto,
	handleAssetTagPhoto,
	handleFile,
	handleInput,
	handleLocationSelection,
	handleMultipleForms,
	handleOptionSelection,
	handlePlatePhoto,
	handleSubmit,
	handleSubmitNew,
	handleTempAlertPhoto,
	startDataCapture,
} from "./actions";

const ActionProvider = (props, ref) => {
	const { setState, children } = props;
	const {
		handleUserMessage,
		setOptionSelected,
		handleFileDrop,
		saveDataCaptureState,
	} = useChatbotHistory();
	const {
		setLastMessageCategory,
		flags,
		setFlags,
		// selectedItem,
		assetTypes,
		setAssetTypes,
		setAssetTagError,
		setTempAlertError,
		savedLocation,
		setSavedLocation,
		chosenAssetType,
		setChosenAssetType,
		ocrConfidenceAndColor,
		setOcrConfidenceAndColor,
		// fieldError,
		draftId,
		setDraftId,
		// specific state variable for SSP AMERICA
		airportCode,
		setAirportCode,
		responseData,
		setResponseData,
		setWarrantyDetailsSwarovski,
		currentPage,
		setOcrData,
		setConditionRead,
		formName,
		setFormName,
		userLocation,
		setUserLocation,
		inputs,
		setInputs,
		options,
		setOptions,
		manufacturers,
		setManufacturers,
		formLogo,
		setFormLogo,
		formId,
		setFormId,
		formsSnap,
		setFormsSnap,
		inputsRequired,
		orgSpecificInputs,
		setOrgSpecificInputs,
		additionalLocationInputs,
		setAdditionalLocationInputs,
		assetConditionalInputs,
		shouldAutoSave,
		setShouldAutoSave,
		setInputData,
		setAssetPhoto,
		setPlatePhoto,
		setTempAlertPhoto,
		setAssetTagPhoto,
		currentState,
		setCurrentState,
		setAssetTypeName,
		setCategories,
		setInputsRequired,
		setAssetConditionalInputs,
		resetResponseData,
	} = useChatbotStore();

	const {
		setFormInProgress,
		highLevelAlertModal: alertModal,
		closeHighLevelAlertModal: closeAlertModal,
		assetTypeInput,
		setAssetTypeInput,
		submitDisabled,
		setSubmitDisabled,
		formInProgress,
	} = useChatbotHighLevelStore();

	const { isSidebarOpen } = usePageStore();

	const isMobile = window.innerWidth < 768;

	const pathname = useLocation().pathname;
	const browserLocation = pathname.includes("location")
		? pathname.split("/")[2]
		: "";

	const authInfo = useUserStore((state) => state.authInfo);
	const organization = authInfo.org;

	const fileInputRef = useRef(null);

	const [latLon, setLatLon] = useState({});

	const [masterAssetTypeInput, setMasterAssetTypeInput] = useState(false);

	const draftIdState = draftId ?? null;

	const setDefaultValues = useCallback(
		() => setDefaultValuesWrapper(inputs, setInputData, responseData),
		[inputs, setInputData, responseData],
	);

	const populateInputs = useCallback(
		async (response) =>
			await populateInputsWrapper({
				response,
				fromChatbot: true, // fromChatbot
				organization,
				responseData,
				setResponseData,
				setChosenAssetType,
				setCurrentPage: () => {},
				setOcrConfidenceAndColor,
			}),
		[
			organization,
			responseData,
			setResponseData,
			setChosenAssetType,
			setOcrConfidenceAndColor,
		],
	);

	/**
	 * Updates a confidence value for a single item
	 *
	 * @param {Object} item - The item which we want to set the confidence for
	 * @param {float} confidence - The confidence to display for the item
	 */
	const addScanConfidence = useCallback(
		(item, confidence) =>
			addScanConfidenceWrapper(
				item,
				confidence,
				ocrConfidenceAndColor,
				setOcrConfidenceAndColor,
			),
		[ocrConfidenceAndColor, setOcrConfidenceAndColor],
	);

	/**
	 * Removes a confidence value for a specified item
	 *
	 * @param {Object} item - The item which we want to set the confidence for
	 */
	const clearScanConfidence = useCallback(
		(item) =>
			clearScanConfidenceWrapper(
				item,
				ocrConfidenceAndColor,
				setOcrConfidenceAndColor,
			),
		[ocrConfidenceAndColor, setOcrConfidenceAndColor],
	);

	useEffect(() => {
		if (formInProgress) {
			handleNavigateAway();
		}
	}, [formInProgress]);

	const capturePhoto = useCallback(
		(e, item, responseData) => {
			function retakePicture() {
				// const fileInputRef = useRef();
				fileInputRef.current.click();
			}

			function noManufacturersPlate() {
				console.log("no manufacturers plate");

				// set manufacturerNotAvailable to true
				setInputData(
					{
						key: "manufacturerPlateNotAvailable",
					},
					true,
				);
			}

			return capturePhotoWrapper(
				e,
				item,
				true, // fromChatbot
				() => {},
				setInputData,
				() => {},
				() => {},
				organization,
				responseData,
				setResponseData,
				setConditionRead,
				setOcrData,
				populateInputs,
				flags,
				authInfo,
				{},
				setAssetTagError,
				formId,
				addScanConfidence,
				clearScanConfidence,
				setTempAlertError,
				retakePicture,
				noManufacturersPlate,
			);
		},
		[
			setInputData,
			organization,
			setResponseData,
			setConditionRead,
			setOcrData,
			populateInputs,
			flags,
			authInfo,
			setAssetTagError,
			formId,
			addScanConfidence,
			clearScanConfidence,
			setTempAlertError,
		],
	);

	// Get user location
	const [retryLocation, setRetryLocation] = useState(false);
	const latLonRef = React.useRef(latLon);

	// ------------------------------- USE EFFECTS ------------------------------------------------------- //

	useEffect(() => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const loc = {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					};
					setLatLon(loc);
					setUserLocation(loc);
				},
				(error) => {
					console.log(error);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [retryLocation]);

	useEffect(() => {
		setState((prev) => ({ ...prev, ...currentState }));
	}, [currentState, setState]);

	useEffect(() => {
		latLonRef.current = latLon;
	}, [latLon]);

	useEffect(() => {
		if (chosenAssetType) {
			// when asset type changes, delete all associated conditional inputs from responseData before getting new inputs
			const newState = { ...responseData };
			for (const input of assetConditionalInputs) {
				delete newState[input.key];
			}
			setResponseData(newState);
			getAssetConditionalInputs(
				chosenAssetType,
				setInputsRequired,
				setAssetConditionalInputs,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chosenAssetType]);

	useEffect(() => {
		const warrantyLogic = (field) =>
			warrantyLogicWrapper(field, organization, currentPage, responseData);

		if (organization === "swarovski.com") {
			const warrantySwarovski = warrantyFieldsSwarovski.every(warrantyLogic);
			if (!warrantySwarovski) {
				console.warn(
					"One or more fields in warrantyDetailsSwarovski failed validation.",
				);
			}
			setWarrantyDetailsSwarovski(warrantySwarovski);
		}
	}, [responseData, organization, setWarrantyDetailsSwarovski, currentPage]);

	useEffect(() => {
		if (formId) {
			getOptionsData(
				inputs,
				assetConditionalInputs,
				orgSpecificInputs,
				setOptions,
				organization,
				formId,
				airportCode,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, airportCode]);

	const prevInputs = useRef(inputs);
	useEffect(() => {
		if (organization === "SSPAMERICA") {
			const locDetails = inputs[1];
			if (locDetails.title !== "Location Details") return;
			if (inputs[3].title === "Manufacturer Plate") {
				const newInputs = [
					inputs[0],
					...inputs.slice(2, 4),
					locDetails,
					...inputs.slice(4),
				];
				if (!isEqual(prevInputs.current, newInputs)) {
					setInputs(newInputs);
				}
			} else {
				const newInputs = [
					inputs[0],
					inputs[2],
					locDetails,
					...inputs.slice(3),
				];
				if (!isEqual(prevInputs.current, newInputs)) {
					setInputs(newInputs);
				}
			}
			prevInputs.current = inputs;
		}
	}, [inputs, organization, setInputs]);

	// Updates inputs and responseData when inputsRequired becomes false
	// Else, update inputs only
	useEffect(() => {
		updateInputsRequiredChange(
			inputsRequired,
			notRequiredInputs,
			true, // fromChatbot
			responseData,
			setResponseData,
			() => {}, // setCurrentPage
			setInputs,
			initInputs,
			assetConditionalInputs,
			orgSpecificInputs,
			additionalLocationInputs,
			savedLocation,
			flags,
			false, // isQA
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		inputsRequired,
		assetConditionalInputs,
		orgSpecificInputs,
		additionalLocationInputs,
		savedLocation?.id,
		flags.allowAssetMapping,
		responseData,
		setInputs,
	]);

	// Update isLoading state when shouldAutoSave, formName, & formLogo states are all not undefined
	useEffect(() => {
		if (
			manufacturers.length > 0 &&
			assetTypes.length > 0 &&
			shouldAutoSave !== undefined &&
			formName &&
			formLogo &&
			options
		) {
			setDefaultValues();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manufacturers, assetTypes, shouldAutoSave, formName, formLogo, options]);

	useEffect(() => {
		saveDraft(
			responseData,
			shouldAutoSave,
			draftId,
			setDraftId,
			organization,
			formId,
			formName,
			authInfo,
			userLocation,
			savedLocation,
			chosenAssetType,
			submitDisabled,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldAutoSave, responseData, chosenAssetType, submitDisabled]);

	// ------------------------------- CHATBOT ACTIONS ------------------------------------------------------- //

	const handleOptionSelectionAction = async (message) => {
		await handleOptionSelection(
			message,
			browserLocation,
			handleUserMessage,
			setLastMessageCategory,
			setOptionSelected,
			setState,
		);
	};

	const handleFileAction = async (file) => {
		await handleFile(file, handleFileDrop, setState);
	};

	const handleAssetIdPromptAction = async (response) =>
		await handleAssetIdPrompt(response, setState);

	const handleInputAction = useCallback(
		async (message) =>
			await handleInput(
				message,
				location,
				handleUserMessage,
				assetTypeInput,
				setAssetTypeInput,
				assetTypes,
				inputs.find((page) => page.title === "Asset Details")?.questions[1],
				setLastMessageCategory,
				setResponseData,
				setState,
				saveDataCaptureState,
				setCurrentState,
				capturePhoto,
				draftIdState,
				setChosenAssetType,
				masterAssetTypeInput,
				setMasterAssetTypeInput,
			),
		[
			handleUserMessage,
			assetTypeInput,
			setAssetTypeInput,
			assetTypes,
			inputs,
			setLastMessageCategory,
			setResponseData,
			setState,
			saveDataCaptureState,
			setCurrentState,
			capturePhoto,
			draftIdState,
			setChosenAssetType,
			masterAssetTypeInput,
		],
	);

	// DATA CAPTURE ACTIONS
	// First step in data capture
	const startDataCaptureAction = useCallback(async () => {
		setFormInProgress(true);
		setOptionSelected(true);
		setResponseData({ beginDate: new Date().toISOString() });
		return await startDataCapture(
			organization,
			setState,
			latLonRef,
			setRetryLocation,
			setManufacturers,
			setAssetTypes,
			setShouldAutoSave,
			setOrgSpecificInputs,
			setFormId,
			setFormName,
			setAdditionalLocationInputs,
			setFormsSnap,
			setFormLogo,
			setFlags,
			setUserLocation,
			setInputData,
			inputs[0]?.questions[0],
			saveDataCaptureState,
			setCurrentState,
		);
	}, [
		setResponseData,
		organization,
		setState,
		setManufacturers,
		setAssetTypes,
		setShouldAutoSave,
		setOrgSpecificInputs,
		setFormId,
		setFormName,
		setAdditionalLocationInputs,
		setFormsSnap,
		setFormLogo,
		setFlags,
		setUserLocation,
		setInputData,
		inputs,
		setFormInProgress,
		saveDataCaptureState,
		setOptionSelected,
		setCurrentState,
	]);

	// Step 1.5 - if multiple forms are available, user selects a form
	const handleMultipleFormsAction = useCallback(
		(form) => {
			handleMultipleForms(
				form,
				formsSnap,
				setFormId,
				setFormName,
				setState,
				latLonRef,
				setRetryLocation,
				organization,
				setManufacturers,
				setAssetTypes,
				setShouldAutoSave,
				setOrgSpecificInputs,
				setAdditionalLocationInputs,
				setFormLogo,
				setFlags,
				setUserLocation,
				setInputData,
				inputs[0]?.questions[0],
				saveDataCaptureState,
				setCurrentState,
			);
		},
		[
			formsSnap,
			setFormId,
			setFormName,
			setState,
			latLonRef,
			setRetryLocation,
			organization,
			setManufacturers,
			setAssetTypes,
			setShouldAutoSave,
			setOrgSpecificInputs,
			setAdditionalLocationInputs,
			setFormLogo,
			setFlags,
			setUserLocation,
			setInputData,
			inputs,
			saveDataCaptureState,
			setCurrentState,
		],
	);

	// Step 2 - user selects a location
	const handleLocationSelectionAction = useCallback(
		(loc, locText, startNew = false) => {
			handleLocationSelection(
				loc,
				locText,
				setSavedLocation,
				setResponseData,
				organization,
				setAirportCode,
				// Location input
				inputs[0]?.questions[0],
				// Asset photo input for upload
				inputs[1]?.questions[0],
				capturePhoto,
				setState,
				startNew,
				saveDataCaptureState,
				setCurrentState,
			);
		},
		[
			setSavedLocation,
			setResponseData,
			organization,
			setAirportCode,
			inputs,
			capturePhoto,
			setState,
			saveDataCaptureState,
			setCurrentState,
		],
	);

	// Step 3 - user takes a photo of the asset
	const handleAssetPhotoAction = useCallback(
		(url) => {
			handleAssetPhoto(
				url,
				savedLocation,
				setAssetPhoto,
				setState,
				// Asset photo input for user message
				inputs[1]?.questions[0],
				// Manufacturers plate input for next upload
				inputs[2]?.questions[0],
				capturePhoto,
				saveDataCaptureState,
				setCurrentState,
			);
		},
		[
			setState,
			savedLocation,
			inputs,
			capturePhoto,
			setAssetPhoto,
			saveDataCaptureState,
			setCurrentState,
		],
	);

	// Step 4 - user takes a photo of the manufacturers plate
	const handlePlatePhotoAction = useCallback(
		(url) => {
			handlePlatePhoto(
				url,
				setResponseData,
				setState,
				// Manufacturers plate input for user message
				inputs.find((page) => page.title === "Manufacturer Plate")
					?.questions[0],
				// Temp alert input for next upload
				inputs.find((page) => page.title === "Temp Alert ID"),
				capturePhoto,
				setPlatePhoto,
				saveDataCaptureState,
				setCurrentState,
				flags,
				inputs.find((page) => page.title === "Asset Tag ID"),
				options,
				organization,
			);
		},
		[
			setResponseData,
			setState,
			inputs,
			capturePhoto,
			setPlatePhoto,
			saveDataCaptureState,
			setCurrentState,
			flags,
			options,
			organization,
		],
	);

	// Step 5 - user takes a photo of the temp alert tag
	const handleTempAlertPhotoAction = useCallback(
		(url) => {
			handleTempAlertPhoto(
				url,
				setResponseData,
				setState,
				// Temp alert input for user message
				inputs.find((page) => page.title === "Temp Alert ID")?.questions[0],
				// Asset tag input for next upload
				inputs.find((page) => page.title === "Asset Tag ID"),
				capturePhoto,
				setTempAlertPhoto,
				saveDataCaptureState,
				setCurrentState,
				flags,
				options,
			);
		},
		[
			setResponseData,
			setState,
			inputs,
			capturePhoto,
			setTempAlertPhoto,
			saveDataCaptureState,
			setCurrentState,
			flags,
			options,
		],
	);

	// Step 6 - user takes a photo of the asset tag
	const handleAssetTagPhotoAction = useCallback(
		(url) => {
			handleAssetTagPhoto(
				url,
				setResponseData,
				setState,
				inputs.find((page) => page.title === "Asset Tag ID")?.questions[0],
				capturePhoto,
				options,
				setAssetTagPhoto,
				saveDataCaptureState,
				setCurrentState,
			);
		},
		[
			setResponseData,
			setState,
			inputs,
			capturePhoto,
			options,
			setAssetTagPhoto,
			saveDataCaptureState,
			setCurrentState,
		],
	);

	// Step 7 - user selects an asset location
	const handleAssetLocationAction = useCallback(
		async (loc) => {
			await handleAssetLocation(
				loc,
				setState,
				setAssetTypeInput,
				inputs.find((page) => page.title === "Asset Details")?.questions[0],
				responseData,
				setResponseData,
				saveDataCaptureState,
				setCurrentState,
			);
		},

		[
			setState,
			setAssetTypeInput,
			inputs,
			responseData,
			setResponseData,
			saveDataCaptureState,
			setCurrentState,
		],
	);

	// Step 8 - user selects an asset type from a list of options, or inputs themselves
	const handleAssetDetectionAction = useCallback(
		(response) => {
			handleAssetDetection(
				response,
				setAssetTypeInput,
				responseData,
				setResponseData,
				setChosenAssetType,
				assetTypes,
				setState,
				inputs.find((page) => page.title === "Asset Details")?.questions[1],
				capturePhoto,
				draftIdState,
				saveDataCaptureState,
				setCurrentState,
			);
		},

		[
			responseData,
			setResponseData,
			setChosenAssetType,
			assetTypes,
			setState,
			inputs,
			capturePhoto,
			draftIdState,
			setAssetTypeInput,
			saveDataCaptureState,
			setCurrentState,
		],
	);

	const handleSubmitAction = useCallback(
		async (submitState) => {
			setFormInProgress(false);
			return await handleSubmit(
				submitState,
				setState,
				savedLocation?.id,
				saveDataCaptureState,
				setSubmitDisabled,
			);
		},
		[
			setState,
			savedLocation?.id,
			setFormInProgress,
			saveDataCaptureState,
			setSubmitDisabled,
		],
	);

	const handleSubmitNewAction = useCallback(
		async () =>
			await handleSubmitNew(
				setState,
				inputs.find((page) => page.title === "Equipment")?.questions[0],
				saveDataCaptureState,
				setCurrentState,
				setSubmitDisabled,
				capturePhoto,
				resetResponseData,
			),
		[
			inputs,
			setState,
			saveDataCaptureState,
			setCurrentState,
			setSubmitDisabled,
			capturePhoto,
			resetResponseData,
		],
	);

	React.useImperativeHandle(ref, () => ({
		handleFileAction,
		handleInputAction,
		setState,
	}));

	return (
		<div>
			{React.Children.map(children, (child) => {
				return React.cloneElement(child, {
					actions: {
						handleInputAction,
						handleOptionSelectionAction,
						handleFileAction,
						startDataCaptureAction,
						handleMultipleFormsAction,
						handleLocationSelectionAction,
						handleAssetPhotoAction,
						handlePlatePhotoAction,
						handleTempAlertPhotoAction,
						handleAssetTagPhotoAction,
						handleAssetDetectionAction,
						handleAssetLocationAction,
						handleSubmitAction,
						handleSubmitNewAction,
						handleAssetIdPromptAction,
					},
				});
			})}
			<Modal
				centered
				isOpen={alertModal.show}
				toggle={closeAlertModal}
				style={{
					left: isMobile ? "0px" : isSidebarOpen ? "130px" : "40px",
				}}
			>
				<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
				<ModalBody>
					{alertModal.body}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "5px",
						}}
					>
						{alertModal.options?.map((option, index) => (
							<button
								key={index}
								onClick={() => option.action()}
								className={"px-2 border rounded-start fs-6"}
								style={{
									margin: "2px",
									backgroundColor:
										option.text === "Send message"
											? "#9d9898 !important"
											: "#bf1e2d",
									color: option.text === "Return to form" ? "white" : "",
								}}
							>
								{option.text}
							</button>
						))}
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default React.memo(React.forwardRef(ActionProvider));
