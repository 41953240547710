import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleLocationSelection = (
	loc,
	locText,
	setSavedLocation,
	setResponseData,
	organization,
	setAirportCode,
	locationQuestion,
	assetQuestion,
	capturePhoto,
	setState,
	startNew = false,
	saveDataCaptureState,
	setCurrentState,
) => {
	if (loc) {
		setSavedLocation(loc);
		setResponseData({ [locationQuestion.key]: loc?.id });
		if (organization === "SSPAMERICA" && loc?.data?.name) {
			setAirportCode(loc.data.name.slice(0, 3));
		}
	}
	let prevMessageNoWidget;
	if (startNew) {
		prevMessageNoWidget = createChatBotMessage(
			"Your submission has been recorded, thank you. Would you like to start from the same location?",
			{ withAvatar: true },
		);
	} else {
		prevMessageNoWidget = createChatBotMessage("Select location:", {
			withAvatar: true,
		});
	}

	const userMessage = createClientMessage(locText);

	let message;
	if (locText !== "Other Location") {
		message = createChatBotMessage(
			`${loc ? (loc?.data?.name ?? loc?.data?.city) : locText}, got it. Please upload a photo of the asset.`,
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					question: assetQuestion,
					capturePhoto,
				},
			},
		);
	} else {
		return;
	}

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message, action: "add" },
	]);
	const currentState = {
		options: ["Upload photo"],
		stage: "assetPhoto",
		showOptions: true,
		otherLocation: false,
	};

	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			message,
		],
		...currentState,
	}));
};
