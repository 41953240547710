import { getAssetTypesForForm as getAssetTypes } from "@services/asset-service";
import { getFormData } from "@services/form-service";
import {
	getManufacturersForForm as getManufacturers,
	getOrgAdditionalLocationInputs,
	getOrgInputsAndAutoSave,
	populateEquipmentAreas,
} from "@services/organization-service";

//  Update locations, manufacturers, assetTypes, shouldAutoSave, orgSpecificInputs, flags, formName, & formLogo states upon init render
//  Update manufacturers, assetTypes, shouldAutoSave, orgSpecificInputs, flags, formName, & formLogo states upon init render
export const initForm = (
	setManufacturers,
	organization,
	setAssetTypes,
	setEquipmentAreas,
	isEditing,
	setShouldAutoSave,
	setOrgSpecificInputs,
	setAdditionalLocationInputs,
	formId,
	setFormName,
	setFormLogo,
	setFlags,
) => {
	getManufacturers(setManufacturers);
	populateEquipmentAreas(setEquipmentAreas);
	getAssetTypes(organization.external_firebase_id, setAssetTypes);
	getOrgInputsAndAutoSave(
		organization,
		isEditing,
		setShouldAutoSave,
		setOrgSpecificInputs,
	);
	getOrgAdditionalLocationInputs(organization, setAdditionalLocationInputs);
	getFormData(
		organization.external_firebase_id,
		formId,
		setFormName,
		setFormLogo,
		setFlags,
	);
};
