import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handlePlatePhoto = (
	urlPromise,
	setResponseData,
	setState,
	plateQuestion,
	tempAlertQuestion,
	capturePhoto,
	setPlatePhoto,
	saveDataCaptureState,
	setCurrentState,
	flags,
	assetTagQuestion,
	options,
	organization,
) => {
	const prevMessageNoWidget = createChatBotMessage(
		"Thank you! Please upload a photo of the manufacturer plate.",
		{
			withAvatar: true,
		},
	);

	const userMessage = createClientMessage("", {
		widget: "CustomImageMessage",
		payload: {
			question: plateQuestion,
			capturePhoto,
			imageUrl: urlPromise,
		},
	});
	let message;
	let currentState;
	if (
		tempAlertQuestion &&
		flags[tempAlertQuestion.flag] &&
		(organization === "cvs.com" || organization === "DIGI")
	) {
		message = createChatBotMessage(
			"Please upload a photo of the Temp Alert Id.",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					question: tempAlertQuestion.questions[0],
					capturePhoto,
				},
			},
		);
		currentState = {
			options: ["Upload photo"],
			stage: "tempAlertPhoto",
			showOptions: true,
		};
	} else if (assetTagQuestion && flags[assetTagQuestion.flag]) {
		message = createChatBotMessage(
			"Please upload a photo of the Asset Tag Id.",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					question: assetTagQuestion.questions[0],
					capturePhoto,
				},
			},
		);
		currentState = {
			options: ["Upload photo"],
			stage: "assetTagPhoto",
			showOptions: true,
		};
	} else {
		message = createChatBotMessage(
			"Thank you. Where is the asset located in the store?",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: { topThreeLocations: [] },
			},
		);
		currentState = {
			options: options["equipment-area"]?.map((loc) =>
				loc?.value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
			),
			stage: "assetLocation",
			showOptions: true,
		};
	}

	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			message,
		],
		...currentState,
	}));

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message, action: "add" },
	]);

	urlPromise.then((url) => {
		setResponseData({
			[plateQuestion.key]: url,
		});
		setPlatePhoto(url);
	});
};
