import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
	pdf,
} from "@react-pdf/renderer";
import { isValidUrl } from "@utils/validation";

export const generateRaisingCanesPCA = async (
	inputs,
	formName,
	formLogo,
	dateOfVisit,
	address,
	summary,
	_reviewed,
) => {
	const styles = StyleSheet.create({
		doc: {
			fontFamily: "Helvetica",
			padding: "16px",
			fontSize: "12px",
			flexDirection: "column",
			justifyContent: "flex-start", // Ensure the content starts from the top
		},
		title: {
			fontSize: "28px",
			paddingBottom: "12px",
			// padding: '6px 0',
			fontFamily: "Helvetica-Bold",
		},
		question: {
			fontFamily: "Helvetica-Bold",
			marginBottom: "2px",
		},
		answer: {
			border: "1px solid #ced4da",
			padding: "6px",
			borderRadius: "4px",
		},
		imageContainer: {
			width: "100%",
			paddingTop: "10px",
		},
		image: {
			width: "200px",
			height: "auto",
		},
		questionAnswerColParent: {
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "space-between",
		},
		questionAnswerColText: {
			flexDirection: "column",
			width: "45%", // Adjust the width for better layout
			marginBottom: "10px",
		},
		questionAnswerColImage: {
			flexDirection: "column",
			width: "45%",
			marginBottom: "10px",
		},
	});

	const returnPageHeaders = (page, index) => {
		return (
			<Text wrap={false} key={`page-${index}`} style={styles.title}>
				{page.title}
			</Text>
		);
	};

	const returnSectionHeaders = (i, section, j) => {
		return (
			<Text wrap={false} key={`page-${i}-section-${j}`} style={styles.question}>
				{section.title}
			</Text>
		);
	};

	const returnQuestionTop = (i, j, question, k) => {
		if (question.type === "image") {
			// Skip rendering image URLs in text fields
			return null;
		}

		return (
			<View
				wrap={false}
				key={`page-${i}-section-${j}-question-${k}-top`}
				style={styles.questionAnswerColParent}
			>
				<View key="description" style={styles.questionAnswerColText}>
					<Text style={styles.question}>{question.label}</Text>
					<Text style={styles.answer}>
						{question.value ? question.value : ""}
					</Text>
				</View>
			</View>
		);
	};

	const returnQuestionImages = (i, j, question, k) => {
		if (question.type !== "image" || !Array.isArray(question.value)) {
			return null; // Only process image questions with valid array values
		}

		return (
			<View
				wrap={false}
				key={`page-${i}-section-${j}-question-${k}-images`}
				style={styles.questionAnswerColParent}
			>
				{question.value.map((imageUrl, index) => {
					if (isValidUrl(imageUrl)) {
						return (
							<View
								key={`image-${k}-${index}`}
								style={styles.questionAnswerColImage}
							>
								<Text style={styles.question}>{question.label}</Text>

								<View style={styles.imageContainer}>
									<Image cache={true} style={styles.image} src={imageUrl} />
								</View>
							</View>
						);
					}
					return null;
				})}
			</View>
		);
	};

	const returnSubmission = () => {
		const lineItems = [];

		inputs.forEach((page, i) => {
			if (page.title && page.sections) {
				lineItems.push(returnPageHeaders(page, i));
				page.sections.forEach((section, j) => {
					lineItems.push(returnSectionHeaders(i, section, j));
					section.questions.forEach((question, k) => {
						// Only render questions with valid values
						if (
							question.value &&
							question.value.length > 0 &&
							question.type !== "image"
						) {
							lineItems.push(returnQuestionTop(i, j, question, k));
						}
						// Render images only for image questions
						if (question.type === "image" && Array.isArray(question.value)) {
							lineItems.push(returnQuestionImages(i, j, question, k));
						}
					});
				});
			}
		});
		return lineItems;
	};

	const document = (
		<Document>
			<Page size="A4" style={styles.doc}>
				<View>
					<View>
						{formLogo && <Image style={styles.logoImage} src={formLogo} />}
					</View>
					<Text style={styles.title}>{formName}</Text>
					<View>
						<Text style={styles.question}>Date of Visit</Text>
						<Text style={styles.answer}>{dateOfVisit}</Text>
					</View>
					<View>
						<Text style={styles.question}>Location</Text>
						<Text style={styles.answer}>{address}</Text>
					</View>
					<View>
						<Text style={styles.question}>Summary</Text>
						<Text style={styles.answer}>{summary}</Text>
					</View>
					{returnSubmission()}
				</View>
			</Page>
		</Document>
	);

	const blob = await pdf(document).toBlob();
	return blob;
};
