import type { Dispatch, SetStateAction } from "react";

export const setInputData = (
	item: { key: string; type: string; tiedTo: string | [] },
	fromChatbot: boolean,
	value: any,
	isEquipmentRead: boolean,
	responseData: any,
	setResponseData: Dispatch<SetStateAction<any>>,
	flags: { differentAssetTypesBasedOnLocation: boolean },
	setChosenAssetType: SetStateAction<any>,
) => {
	const response = responseData;
	response[item.key] = value;

	const setNewState = (newState: {
		[x: string]: any;
		assetType: undefined;
	}) => {
		// if checkbox is checked, delete key associated with checkbox within responseData
		if (item.type === "checkbox" && value) {
			const tiedToValues: string[] = [];
			if (typeof item.tiedTo === "string") {
				tiedToValues.push(item.tiedTo);
			} else if (Array.isArray(item.tiedTo)) {
				tiedToValues.push(...item.tiedTo);
			}
			for (const tiedTo of tiedToValues) {
				if (!newState[tiedTo] || tiedTo.toLowerCase().includes("image"))
					continue;
				newState[tiedTo] = null;
			}
		}
		if (
			flags.differentAssetTypesBasedOnLocation &&
			item.key === "assetLocation" &&
			!isEquipmentRead
		) {
			newState.assetType = undefined;
			setChosenAssetType(null);
		}
	};

	if (fromChatbot) {
		setNewState(response);
		setResponseData(response);
	} else {
		setResponseData((prevState: any) => {
			const newState = {
				...prevState,
				[item.key]: value,
			};
			setNewState(newState);
			return newState;
		});
	}
};
