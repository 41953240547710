import { screenshot } from "@utils/screenshot";
import { useRef } from "react";
import { BsFillCameraFill } from "react-icons/bs";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { HealthScoreHoverGraph } from "./HealthScoreHoverGraph";

const HoverGraphModal = ({
	chartData,
	isModalShowing,
	setIsModalShowing,
	setDisplayToast,
}) => {
	const cameraIconRef = useRef(null);
	const graphRef = useRef(null);
	const toggle = () => {
		setIsModalShowing(!isModalShowing);
	};

	return (
		<Modal isOpen={isModalShowing} toggle={toggle} centered size="xl">
			<ModalHeader className="d-flex justify-content-center position-relative">
				<span className="fw-bold mx-2">Asset Type Average Health Score</span>
				<div ref={cameraIconRef}>
					<BsFillCameraFill
						style={{
							cursor: "pointer",
							top: "20px",
							right: "20px",
							position: "absolute",
						}}
						onClick={() => {
							setDisplayToast({
								showing: true,
								title: "Success",
								message: "Graph copied to clipboard.",
								type: "success",
								position: "bottom",
							});
							screenshot(graphRef, cameraIconRef);
							setTimeout(() => {
								setDisplayToast({
									showing: false,
								});
							}, 2500);
						}}
					/>
				</div>
			</ModalHeader>
			<ModalBody className="p-0 m-0 overflow-hidden">
				<HealthScoreHoverGraph
					chartData={chartData}
					isPreview={false}
					ref={graphRef}
				/>
			</ModalBody>
		</Modal>
	);
};

export { HoverGraphModal };
