export const handleNavigateAway = () => {
	// Event handler for 'beforeunload' event
	const handleBeforeUnload = (e) => {
		e.preventDefault();
		const confirmationMessage = "Are you sure you want to leave?";
		e.returnValue = confirmationMessage;
		return confirmationMessage;
	};
	// Mimic a navigation to the current URL to prevent leaving the page
	history.pushState(null, null, document.URL);

	// Event handler for 'popstate' event (back/forward buttons)
	const handlePopstate = () => {
		if (
			!window.confirm(
				"Are you sure you want to go back? Doing so could cause loss of data",
			)
		) {
			history.pushState(null, null, document.URL);
		}
	};

	// Add 'beforeunload' event listener to prompt user before leaving
	window.onbeforeunload = handleBeforeUnload;

	// Add 'popstate' event listener for back/forward button navigation
	window.onpopstate = handlePopstate;

	// Cleanup function to remove event listeners when component unmounts
	return () => {
		window.onbeforeunload = null;
		window.onpopstate = null;
	};
};
