// File: ViewTemplateModal.tsx

import { auth } from "@/assets/services/auth-service";
import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { Button, Modal, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { MdBrokenImage } from "react-icons/md";

interface ViewerSettings {
	templateName: string | null;
	isOpen: boolean;
	templateId: string | null;
}

interface ViewTemplateModalProps {
	viewerSettings: ViewerSettings;
	onClose: () => void;
	onTest: (templateName: string, templateId: string) => void;
	onDelete: (templateId: string) => void;
}

export const ViewTemplateModal: React.FC<ViewTemplateModalProps> = ({
	viewerSettings,
	onClose,
	onTest,
	onDelete,
}) => {
	const [image, setImage] = useState<string | null>(null);
	const [error, setError] = useState<boolean>(false);

	const getImage = async (templateName: string) => {
		try {
			const token = await auth?.currentUser?.getIdToken();
			const response = await fetch(
				`${import.meta.env.VITE_ALFRED_SERVICE_URL}/FacilityVision/OCR/review_templates/image/${templateName}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					method: "GET",
				},
			);
			if (response.status !== 200)
				throw new Error("Endpoint returned non-200 status");
			const data = await response.json();
			setImage(data.url);
			setError(false); // Reset error state if image loads successfully
		} catch (error) {
			setError(true);
			console.error("Error fetching image:", error);
		}
	};

	useEffect(() => {
		if (viewerSettings.isOpen && viewerSettings.templateName) {
			getImage(viewerSettings.templateName);
		}

		return () => {
			setImage(null);
			setError(false);
		};
	}, [viewerSettings.isOpen, viewerSettings.templateName]);

	const handleImageError = () => {
		setError(true);
		setImage(null);
	};

	const confirmDelete = () => {
		onDelete(viewerSettings.templateId as string);
	};

	const handleTest = () => {
		if (viewerSettings.templateName && viewerSettings.templateId) {
			onTest(viewerSettings.templateName, viewerSettings.templateId);
		} else {
			message.error("Template information is missing.");
		}
	};

	return (
		<Modal
			title="View OCR Template"
			open={viewerSettings.isOpen}
			onCancel={onClose}
			footer={[
				<Button key="delete" type="primary" danger onClick={confirmDelete}>
					Delete
				</Button>,
				<Button key="test" onClick={handleTest}>
					Test
				</Button>,
				<Button key="close" type="primary" onClick={onClose}>
					Close
				</Button>,
			]}
			className="view-template-modal"
		>
			{image ? (
				<img
					src={image}
					alt="Template"
					style={{ width: "100%" }}
					onError={handleImageError}
				/>
			) : error ? (
				<div
					className="d-flex flex-column justify-content-center align-content-center align-items-center gap-3"
					style={{ minHeight: "50dvh" }}
				>
					<MdBrokenImage style={{ fontSize: "3rem", color: "red" }} />
					<Typography.Text title="Error" type="danger">
						Failed to load image, please try again later.
					</Typography.Text>
				</div>
			) : (
				<Loader />
			)}
		</Modal>
	);
};
