import { addExifToInputsCushman } from "@/utils/addExifToInputs";
import { SubmittedImageModal } from "@SignedIn/views/SingleForm/SubmittedImageModal";
import { LeftOutlined } from "@ant-design/icons";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { LoadingModal } from "@components/layouts/SignedIn/LoadingModal/LoadingModal";
import { getSubmissionDataRC } from "@services/submission-service";
import { useUserStore } from "@stores";
import { useFormStore } from "@stores";
import { isValidUrl } from "@utils/validation";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Card,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import * as XLSX from "xlsx";
import { generateRaisingCanesPCA } from "./generateRaisingCanesPCA";
const SubmissionRC = (props) => {
	const {
		submissionId,
		formId,
		toggleSubmission,
		quickView = false,
		setModalIndex,
		responsesLength,
	} = props;

	const location = useLocation();
	const _authInfo = useUserStore((state) => state.authInfo);
	const { user } = useUserStore();
	const setIsEditingRaisingCanes = useFormStore(
		(state) => state.setIsEditingRaisingCanes,
	);
	// const updateRaisingCanesSubmissionReview =
	// 	useUpdateRaisingCanesSubmissionReview();
	const navigate = useNavigate();

	// States used for showing loading animation upon init render and updates, respectively
	const [isLoading, setIsLoading] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	// State for submission data from database
	const [originalInputs, setOriginalInputs] = useState();
	const [inputs, setInputs] = useState();
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [dateOfVisit, setDateOfVisit] = useState();
	const [address, setAddress] = useState();
	const [reviewed, setReviewed] = useState();
	const [locationId, setLocationId] = useState();
	const [userLocation, setUserLocation] = useState();
	const [savedLocation, _setSavedLocation] = useState();
	const [_prototype, setPrototype] = useState();
	// console.log('Prototype', prototype);

	// States used for viewing images
	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImage, _setSelectedImage] = useState("");

	// State used for tracking if PDF is loading
	const [pdfLoading, setPdfLoading] = useState(false);

	// Helper function to format date to YYYY-MM-DD
	const formatDate = (date) => {
		const d = new Date(date);
		let month = `${d.getMonth() + 1}`;
		let day = `${d.getDate()}`;
		const year = d.getFullYear();

		if (month.length < 2) month = `0${month}`;
		if (day.length < 2) day = `0${day}`;

		return [year, month, day].join("-");
	};

	// Updates submission (e.g., data), inputs, formName, and address states upon init render
	useEffect(() => {
		const _submission = getSubmissionDataRC(
			formId,
			submissionId,
			setOriginalInputs,
			setInputs,
			setFormName,
			setFormLogo,
			setAddress,
			setLocationId,
			setUserLocation,
			(date) => setDateOfVisit(formatDate(date)),
			// setReviewed,
			// setSavedLocation,
			setPrototype,
		).finally(() => setIsLoading(false));
	}, [submissionId]);

	useEffect(() => {
		if (originalInputs) {
			setInputs(originalInputs);
		}
	}, [originalInputs]);

	// Updates loader state to false when all other states are loaded
	useEffect(() => {
		if (
			originalInputs &&
			inputs &&
			formName &&
			formLogo &&
			address &&
			locationId &&
			submissionId &&
			formId
		) {
			setIsLoading(false);
		}
	}, [
		originalInputs,
		inputs,
		formName,
		formLogo,
		address,
		reviewed,
		locationId,
		submissionId,
		formId,
	]);

	const returnPageHeaders = (page, index) => {
		return (
			<FormGroup
				key={`page-${index}`}
				className="pb-3 pt-4 px-3 submission-tab"
			>
				{page.title}
			</FormGroup>
		);
	};

	const returnSectionHeaders = (i, section, j) => {
		const hasNonEmptyQuestions = section.questions.some(
			(question) =>
				question.value &&
				(typeof question.value === "string" ||
					(Array.isArray(question.value) && question.value.length > 0)),
		);

		if (!hasNonEmptyQuestions) {
			return null; // Skip rendering the section if all questions are empty
		}

		return (
			<FormGroup
				key={`page-${i}-section-${j}`}
				className="px-3 pt-3 pb-2 submission-section"
			>
				<Label className="submission-section-label">{section.title}</Label>
			</FormGroup>
		);
	};

	const renderedKeys = new Set();

	const returnQuestion = (i, j, question, k) => {
		if (
			!question.value ||
			(Array.isArray(question.value) && question.value.length === 0)
		) {
			return null;
		}
		// Check if the question.responseTag has already been rendered
		if (renderedKeys.has(question.responseTag)) {
			console.warn(`Duplicate key found: ${question.responseTag}`);
			return null; // Return null to skip rendering this question
		}

		// Add the question.responseTag to the Set, marking it as rendered
		renderedKeys.add(question.responseTag);

		const imageUrl = question.value;

		return (
			<Row key={`page-${i}-section-${j}-question-${k}`}>
				{(question.type !== "image" && question.value) || !question.value ? (
					<Col xs="12" lg="6" key={`${question.responseTag}-value`}>
						<FormGroup className="p-3 d-flex flex-column">
							<Label className="submission-label fw-bold">
								{question.label}
							</Label>
							{question.description && (
								<Input
									disabled
									className="bg-white"
									type="text"
									value={question.description || ""}
								/>
							)}
							{question.value && (
								<Input
									disabled
									className="bg-white"
									type="text"
									value={question.value || "No Value"}
								/>
							)}
						</FormGroup>
					</Col>
				) : null}
				{Array.isArray(imageUrl)
					? imageUrl.map(
							(url, index) =>
								isValidUrl(url) && (
									<Col xs="12" lg="6" key={`image-${index}`}>
										<FormGroup className="p-3 d-flex flex-column">
											{/* Only render the label once for the first image */}
											{index === 0 && (
												<Label className="submission-label fw-bold">
													{question.label} Image
												</Label>
											)}
											<div className="position-relative submitted-image-parent">
												<img
													className="submitted-image"
													alt=""
													src={url}
													onClick={() => openImgModal(url)}
												/>
											</div>
										</FormGroup>
									</Col>
								),
						)
					: isValidUrl(imageUrl) && (
							<Col xs="12" lg="6">
								<FormGroup className="p-3 d-flex flex-column">
									<Label className="submission-label fw-bold">
										{question.label} Image
									</Label>
									<div className="position-relative submitted-image-parent">
										<img
											className="submitted-image"
											alt=""
											src={imageUrl}
											onClick={() => openImgModal(imageUrl)}
										/>
									</div>
								</FormGroup>
							</Col>
						)}
			</Row>
		);
	};

	const returnSubmission = () => {
		const lineItems = [];

		inputs?.forEach((page, i) => {
			// Page Header
			if (page.title && page.sections) {
				// Create a copy of the sections array to avoid direct mutation
				const filteredSections = page.sections.filter(
					(section) => section.title !== "Basic Information",
				);

				// Skip rendering if there are no sections left after filtering
				if (filteredSections.length === 0) return;

				lineItems.push(returnPageHeaders(page, i));
				// Section Header
				filteredSections.forEach((section, j) => {
					lineItems.push(returnSectionHeaders(i, section, j));
					// Question
					section.questions.forEach((question, k) => {
						lineItems.push(returnQuestion(i, j, question, k));
					});
				});
			}
		});
		return lineItems;
	};

	const canUserEdit = () => {
		const role = user.role.access_level >= 1000;
		return role;
		// === 'superuser' || role === 'admin' || role === 'manager';
	};

	// Navigate to SingleFormRC.js to edit the form
	const editSubmission = async () => {
		// Terminate function if user lacks edit permissions
		if (!canUserEdit()) return;
		setIsEditingRaisingCanes(true);

		console.log("originalInputs", originalInputs);

		console.log(savedLocation, "savedLocation");
		console.log(JSON.stringify(originalInputs));
		navigate(`/forms/${formId}`, {
			state: {
				isEditing: true,
				inputs: JSON.stringify(originalInputs), // Convert to JSON string
				submissionId,
				dateOfVisit: dateOfVisit,
				savedLocation: address,
				userLocation: userLocation,
				path: location.pathname,
			},
		});
	};

	const openImgModal = (response) => {
		setSelectedPhotoUrl(response);
		setShowImageModal(true);
		setIsPhotoShowing(true);
	};

	// Updates "reviewed" field for submission
	const _updateReviewed = async (e) => {
		setShowLoader(true);
		const value = e.target.checked;
		setReviewed(value);
		await updateRaisingCanesSubmissionReview.mutate({
			formId,
			submissionId,
			value,
		});
		setShowLoader(false);
	};

	// Renders & downloads the submission's PDF
	const downloadPdf = async () => {
		setPdfLoading(true);
		// First, fetch images & update exif data
		const newInputs = await addExifToInputsCushman(inputs);
		// Second, generate PDF blob
		const blob = await generateRaisingCanesPCA(
			newInputs,
			formName,
			formLogo,
			dateOfVisit,
			address,
			"",
			reviewed,
		);
		// Third, download the PDF
		const tempLink = document.createElement("a");
		tempLink.href = URL.createObjectURL(blob);
		tempLink.setAttribute("download", `${formName} - ${address}.pdf`);
		tempLink.click();
		setPdfLoading(false);
	};

	// Function to export the submission data to Excel CSV
	const exportToExcel = () => {
		const formattedData = [];

		inputs.forEach((page) => {
			page.sections.forEach((section) => {
				section.questions.forEach((question) => {
					if (question.value && question.label) {
						formattedData.push({
							Section: section.title,
							Question: question.label,
							Answer: Array.isArray(question.value)
								? question.value.join(", ")
								: question.value,
						});
					}
				});
			});
		});

		const worksheet = XLSX.utils.json_to_sheet(formattedData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "SubmissionData");

		XLSX.writeFile(
			workbook,
			`${formName || "Submission"} - ${address || "Details"}.xlsx`,
		);
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<div className="submission-header d-flex flex-row align-items-center justify-content-between h-100">
					{/* Invisible in single location overview tab */}
					{!quickView ? (
						toggleSubmission && (
							<Button
								type="link"
								onClick={toggleSubmission}
								icon={<LeftOutlined />}
							>
								Back to Submissions
							</Button>
						)
					) : (
						<FaArrowLeft
							style={{
								top: "50%",
								transform: "translateY(-50%)",
								cursor: "pointer",
							}}
							onClick={() => {
								setIsLoading(true);
								setModalIndex((prev) =>
									prev === 0 ? responsesLength - 1 : prev - 1,
								);
								setTimeout(() => setIsLoading(false), 500);
							}}
						/>
					)}
					{/* Invisible in single location overview tab */}
					{toggleSubmission && (
						<div className={`d-flex ${quickView ? "" : "align-content-start"}`}>
							<div className="d-flex flex-row">
								<div className="activities-page-header-buttons d-flex p-2 width-fit-content ">
									<MdDownload onClick={() => exportToExcel()} />
								</div>
								<div className="activities-page-header-buttons d-flex p-2 width-fit-content ">
									{pdfLoading ? (
										<div className="d-flex justify-content-center">
											Loading...
										</div>
									) : (
										<div
											className="d-flex justify-content-center"
											onClick={downloadPdf}
										>
											PDF <i className="bi bi-cloud-download mx-2" />
										</div>
									)}
								</div>
							</div>
							<button
								className="edit-submission-btn p-2"
								color="success"
								disabled={!canUserEdit()}
								onClick={editSubmission}
							>
								Edit
								<span>
									<AiOutlineEdit className="fs-5 mx-1" />
								</span>
							</button>
						</div>
					)}
					{quickView && (
						<FaArrowRight
							style={{
								top: "50%",
								transform: "translateY(-50%)",
								cursor: "pointer",
							}}
							onClick={() => {
								setIsLoading(true);
								setModalIndex((prev) =>
									prev === responsesLength - 1 ? 0 : prev + 1,
								);
								setTimeout(() => setIsLoading(false), 500);
							}}
						/>
					)}
				</div>
				{/* Invisible in single location overview tab */}
				<img
					hidden={toggleSubmission == null}
					src={formLogo}
					className="submission-logo"
					alt="logo"
				/>
				<CardHeader
					className="d-flex flex-row align-items-center justify-content-between"
					style={{
						backgroundColor: "#ffffff",
						border: "none",
					}}
				>
					{/* VISIBLE in single location overview tab */}
					<div hidden={toggleSubmission != null}>
						<div
							className="submission-title"
							style={{ fontSize: quickView ? "30px" : "" }}
						>
							Latest {formName}
						</div>
						<div className="d-flex align-content-start">
							<div className="d-flex flex-row">
								<div className="activities-page-header-buttons d-flex p-2 width-fit-content ">
									{pdfLoading ? (
										<div className="d-flex justify-content-center">
											Loading...
										</div>
									) : (
										<div
											className="d-flex justify-content-center"
											onClick={downloadPdf}
										>
											PDF <i className="bi bi-cloud-download mx-2" />
										</div>
									)}
								</div>
							</div>
							<button
								className="edit-submission-btn"
								color="success"
								disabled={!canUserEdit()}
								onClick={editSubmission}
							>
								Edit
								<span>
									<AiOutlineEdit className="fs-5 mx-1" />
								</span>
							</button>
						</div>
					</div>
					{/* Invisible in single location overview tab */}
					<div hidden={toggleSubmission == null} className="submission-title">
						{formName}
					</div>
				</CardHeader>
				{/* Submission Contents */}
				<div id="activities-tab-form-submission">
					{/* Line Two - Date of Visit */}
					<FormGroup key={"date-of-visit"} className="p-3">
						<Label className="fw-bolder pt-0">Date of Visit</Label>
						<Input
							disabled
							className="bg-white"
							type="date"
							value={dateOfVisit}
						/>
					</FormGroup>
					{/* Line Three - Address */}
					<FormGroup key={"address"} className="p-3">
						<Label className="fw-bolder pt-0">Location</Label>
						<Input disabled className="bg-white" type="text" value={address} />
					</FormGroup>
					<FormGroup key={"prototype"} className="p-3">
						<Label className="fw-bolder pt-0">Prototype</Label>
						<Input
							disabled
							className="bg-white"
							type="text"
							value={
								originalInputs?.[0]?.sections?.[0]?.questions?.[2]?.value
									? originalInputs?.[0]?.sections?.[0]?.questions?.[2]?.value
									: "No Value"
							}
						/>
					</FormGroup>
					{returnSubmission()}
				</div>
			</Card>
			<SubmittedImageModal
				showing={showImageModal}
				photoUrl={selectedImage}
				isZoom={true}
				cancel={() => setShowImageModal(false)}
			/>
			<LoadingModal showing={showLoader} />
		</div>
	);
};

export { SubmissionRC };
