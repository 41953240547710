import { priceSearch } from "@services/alfred-service";
import { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Loader } from "../../Loader/Loader";
import { GoogleShoppingGrid } from "./GoogleShoppingGrid";

const ShoppingModal = ({ assetType, manufacturer, model }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [googleShoppingData, setGoogleShoppingData] = useState();
	const toggle = () => setModalOpen(!modalOpen);

	const getGoogleShoppingData = async () => {
		priceSearch(assetType, manufacturer, model).then((response) => {
			response.json().then((data) => {
				setGoogleShoppingData(data);
				setIsLoading(false);
			});
		});
	};

	useEffect(() => {
		if (!googleShoppingData && modalOpen) {
			getGoogleShoppingData();
		}
	}, [modalOpen]);

	return (
		<div>
			<button onClick={toggle} className="google-shopping-btn">
				<AiOutlineShoppingCart />
			</button>
			<Modal
				className="google-shopping-modal"
				centered="true"
				isOpen={modalOpen}
				toggle={toggle}
			>
				<ModalHeader className="d-flex justify-content-center" toggle={toggle}>
					<span style={{ display: "block" }}>
						Live Market Pricing for {assetType} {model}
					</span>
					<span style={{ display: "block" }}>
						{Array.isArray(googleShoppingData?.response?.results)
							? `Cost Range: $${googleShoppingData?.response?.price_range.start_range
									.toFixed(2)
									.toLocaleString()} - $${googleShoppingData?.response?.price_range.end_range
									.toFixed(2)
									.toLocaleString()}`
							: ""}
					</span>
				</ModalHeader>
				<ModalBody>
					{isLoading ? (
						<div className="small-loader">
							<Loader />
						</div>
					) : (
						<div>
							<h6 className="text-center mb-2 fw-bold px-2"></h6>
							<GoogleShoppingGrid data={googleShoppingData} />
						</div>
					)}
				</ModalBody>
			</Modal>
		</div>
	);
};

export { ShoppingModal };
