import { storage } from "@/assets/services/auth-service";
import { formatDateMMDDYYYY } from "@/components/layouts/SignedIn/views/Admin/SubmissionsTable/children/formatDate";
import { NewLocationPicker } from "@/components/layouts/SignedIn/views/SingleForm/NewLocationPicker";
import { useFormStore } from "@/stores";
import { usePageStore } from "@/stores";
import { SubmittedImageModal } from "@SignedIn/views/SingleForm/SubmittedImageModal";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { inputs as notRequiredInputs } from "@constants/formInputs/notRequiredInputs";
import { default as initInputs } from "@constants/formInputs/taggingFormInputs";
import { useUpdateWithRef } from "@hooks/organizationQueries";
import { useAddSubmission } from "@hooks/submissionQueries";
import { getOptionsData } from "@services/form-service";
import { getOrgSpecificInputs } from "@services/organization-service";
import { getSubmissionData } from "@services/submission-service";
import { updateSubmissionAndAsset } from "@services/submission-service";
import { useUserStore } from "@stores";
import { deepCopy } from "@utils/deepCopy";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	FormGroup,
	Input,
	Label,
} from "reactstrap";

const CheckUpsEdit = (props) => {
	const {
		submissionId,
		formId,
		toggleSubmission,
		isQA,
		assetLocationChanged,
		responses,
		handleNavigation, // New prop
	} = props;
	const _location = useLocation();
	const _isMobile = window.innerWidth < 768;

	const { setActiveTab } = usePageStore();
	const { alertModal, setAlertModal } = useFormStore();
	const fileInputRef = useRef(null);
	const {
		setShowDraftSelectionModal,
		setIsPhotoShowing,
		setSelectedItem,
		setSelectedPhotoUrl,
		selectedPhotoUrl,
	} = useFormStore();
	const { authInfo, userOrganization } = useUserStore();
	const organization = authInfo.org;
	const navigate = useNavigate();
	const [showApproval, setShowApproval] = useState(false);
	const _toggleApproval = () => setShowApproval(!showApproval);
	const _addSubmission = useAddSubmission();
	const _updateWithRef = useUpdateWithRef();

	const [isLoading, setIsLoading] = useState(true);
	const [validationState, setValidationState] = useState({});
	const [responseData, setResponseData] = useState();
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [address, setAddress] = useState();
	const [_newAddress, setNewAddress] = useState();
	const [locationId, setLocationId] = useState();
	const [userLocation, setUserLocation] = useState(undefined);
	const [chosenAssetType, setChosenAssetType] = useState();

	const [showImageModal, setShowImageModal] = useState(false);

	const [uploadedImage, setUploadedImageUrl] = useState(null);

	const [inputsRequired, setInputsRequired] = useState(true);
	const [inputs, setInputs] = useState(initInputs);
	const [assetConditionalInputs, setAssetConditionalInputs] = useState([]);
	const [orgSpecificInputs, setOrgSpecificInputs] = useState([]);

	const [_showLoader, setShowLoader] = useState(false);

	const [selectedState, setSelectedState] = useState(null);

	const [originalLocation, setOriginalLocation] = useState(null);
	const [_selectedLocation, setSelectedLocation] = useState(null);

	const [areaChange, setAreaChanged] = useState(false);

	const [options, setOptions] = useState([]);

	const [previousData, _setPrevData] = useState(responses);
	console.log("Previous Data:", previousData);

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isConditionSame, setIsConditionSame] = useState(false);

	console.log("Previous Data:", previousData);

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	const _updateAddress = (locationData) => {
		let fullAddress = "";
		if (locationData.address) {
			fullAddress += locationData.address;
		}
		if (locationData.address1) {
			fullAddress += ` ${locationData.address1}`;
		}
		if (locationData.address2) {
			fullAddress += ` ${locationData.address2}`;
		}
		fullAddress += `, ${locationData.city}, ${locationData.state} ${locationData.zip}`;
		setNewAddress(fullAddress);
	};

	const formatAddress = (location) => {
		console.log(location, "location");
		if (!location) return "No location selected";
		return `${location.address1}, ${location.city}, ${location.state}`;
	};

	const handleLocationChange = (newLocation) => {
		if (newLocation?.data) {
			const newFormattedAddress = formatAddress(newLocation.data);
			setAddress(newFormattedAddress); // Update address shown in the input
			setSelectedState(newLocation);
		}
		setIsPickerOpen(false);
	};

	const _displayLocation = () => {
		console.log("Displaying location:", originalLocation);
		return address || "No location selected";
	};
	console.log(originalLocation, "originalLocation");
	const handleOpenPicker = () => setIsPickerOpen(true);
	const handleClosePicker = () => setIsPickerOpen(false);

	async function uploadImageToFirebase(blob, path) {
		const imageRef = ref(storage, path);
		const snapshot = await uploadBytes(imageRef, blob);
		const url = await getDownloadURL(snapshot.ref);
		return url;
	}

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) {
			console.log("No file selected.");
			setShowLoader(false);
			return;
		}
		setShowLoader(true);

		const reader = new FileReader();
		reader.onload = async (e) => {
			const img = new Image();
			img.src = e.target.result;

			img.onload = async () => {
				const scale = 1200 / img.width;
				const height = img.height * scale;

				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);

				canvas.toBlob(async (blob) => {
					try {
						const path = `FieldCaptures/${new Date().toISOString()}.webp`;
						const url = await uploadImageToFirebase(blob, path);
						console.log("Uploaded Image URL:", url);
						setUploadedImageUrl(url);

						setAlertModal({
							show: true,
							title: "Image Uploaded",
							body: "The image has been uploaded successfully.",
						});
						setTimeout(closeAlertModal, 2500);
					} catch (error) {
						console.error("Error during image processing:", error);
					} finally {
						setShowLoader(false);
					}
				}, "image/webp");
			};
		};
		reader.onerror = (error) => {
			console.error("FileReader error:", error);
			setShowLoader(false);
		};
		reader.readAsDataURL(file);
	};

	const handleFieldChange = (field, value) => {
		setResponseData((prevData) => ({
			...prevData,
			[field]: value,
		}));
		setValidationState((prevState) => ({
			...prevState,
			[field]: false,
		}));
	};

	const validateFields = () => {
		let isValid = true;
		const newValidationState = {};
		console.log("Area Change:", areaChange);
		console.log("Asset Sub Area:", responseData.assetSubArea);
		console.log("Asset Location:", responseData.assetLocation);

		if (assetLocationChanged && (!selectedState || !selectedState.id)) {
			isValid = false;
			newValidationState.location = true;
		}

		if (!uploadedImage) {
			isValid = false;
			newValidationState.equipmentAssetImage = true;
		}

		if (
			areaChange &&
			((organization === "cvs.com" &&
				responseData.assetSubArea === previousData.assetSubArea) ||
				(organization !== "cvs.com" &&
					responseData.assetLocation === previousData.assetLocation))
		) {
			console.log("Area Change:", areaChange);
			console.log("Asset Sub Area:", responseData.assetSubArea);
			console.log("Asset Location:", responseData.assetLocation);

			isValid = false;
			if (organization === "cvs.com") {
				newValidationState.assetSubArea = true;
			} else {
				newValidationState.assetLocation = true;
			}
		}

		setValidationState(newValidationState);
		return isValid;
	};

	const handleSubmit = async () => {
		const previousCondition = previousData?.assetCondition;

		if (!validateFields()) {
			console.log(validationState);
			setAlertModal({
				show: true,
				title: "Missing Data",
				body: "Please fill all the required fields before submitting.",
			});
			setTimeout(closeAlertModal, 2500);
			return;
		}

		if (submissionId && uploadedImage && responseData.assetCondition) {
			if (
				(assetLocationChanged &&
					selectedState &&
					selectedState.id === previousData.location?.id) ||
				(organization === "cvs.com" &&
					areaChange &&
					responseData.assetSubArea === previousData.assetSubArea) ||
				(organization !== "cvs.com" &&
					areaChange &&
					responseData.assetLocation === previousData.assetLocation)
			) {
				setAlertModal({
					show: true,
					title: "Missing Data",
					body: "Please change the area of the asset before submitting.",
				});
				setTimeout(closeAlertModal, 2500);

				return;
			}

			console.log("Preparing to update submission with new data");
			const updatedData = {
				...responseData,
				equipmentAssetImage: uploadedImage,
				audited: true,
			};
			const auditLog = responseData.auditLog || [];
			const prevData = previousData;

			console.log("Previous Data:", prevData);

			const alwaysLogged = {
				equipmentAssetImage: {
					before: prevData?.equipmentAssetImage,
					after: uploadedImage,
				},
				assetCondition: {
					before: prevData?.assetCondition,
					after: responseData.assetCondition,
				},
			};

			if (assetLocationChanged && selectedState) {
				alwaysLogged.locationId = {
					before: prevData?.location?.id,
					after: selectedState?.id || "N/A",
				};
				alwaysLogged.locationAddress1 = {
					before: prevData?.location?.address1,
					after: selectedState?.data?.address1,
				};
				updatedData.location = { ...selectedState.data };
			}

			if (areaChange) {
				const key =
					organization === "cvs.com" ? "assetSubArea" : "assetLocation";
				alwaysLogged[key] = {
					before: prevData?.[key] || "N/A",
					after: responseData[key],
				};
			}

			auditLog.push({
				auditingUser: authInfo.displayName,
				auditedDate: new Date().toISOString(),
				changes: alwaysLogged,
			});

			if (selectedState?.id) {
				updatedData.location.id = selectedState.id;
			}
			updatedData.auditLog = auditLog;

			try {
				console.log("Update of Data:", updatedData);
				await updateSubmissionAndAsset(
					organization,
					submissionId,
					formId,
					updatedData,
					assetLocationChanged,
					areaChange,
				);
				console.log("Submission successfully updated");

				setIsSubmitted(true);
				setIsConditionSame(responseData.assetCondition === previousCondition);

				setAlertModal({
					show: true,
					title: "CheckUp successful",
					body: "Check up for the asset is successful. Do you want to check up the next asset?",
					options: [
						{
							text: "Yes",
							action: () => {
								setAlertModal({
									show: false,
									title: "",
									body: "",
								});
								navigate("/checkups");
								setActiveTab(1);
								handleNavigation(); // Use the navigation function
							},
						},
						{
							text: "No",
							action: () => {
								setAlertModal({
									show: false,
									title: "",
									body: "",
								});
								setActiveTab(2);
								navigate("/checkups");

								handleNavigation(); // Use the navigation function
							},
						},
					],
				});
				// setTimeout(closeAlertModal, 2500);
			} catch (error) {
				console.error("Error during submission update:", error);
				setAlertModal({
					show: true,
					title: "Update Failed",
					body: "Failed to update the submission.",
				});
				setTimeout(closeAlertModal, 2500);
			}
		} else {
			console.log("Missing data: Cannot update submission");
			setAlertModal({
				show: true,
				title: "Missing Data",
				body: "Cannot update submission due to missing data.",
			});
			setTimeout(closeAlertModal, 2500);
		}
	};

	// Updates various states upon init render
	useEffect(() => {
		if (!submissionId || !userOrganization) return;
		const fetchData = async () => {
			const data = await getSubmissionData(
				userOrganization.external_firebase_id,
				formId,
				submissionId,
				isQA,
				setResponseData,
				setFormName,
				setFormLogo,
				setAddress,
				setLocationId,
				setUserLocation,
				setChosenAssetType,
			);
			console.log("Fetched data:", data);
			console.log("Received responses:", responses);
			setOriginalLocation(data.address);
			setSelectedLocation(data.location);
			getOrgSpecificInputs(userOrganization, setOrgSpecificInputs);
		};
		fetchData();
	}, [submissionId, userOrganization, isQA, formId]);

	// Updates assetConditionalInputs & inputsRequired upon prev states being retrieved
	useEffect(() => {
		if (
			responseData !== undefined &&
			formName !== undefined &&
			formLogo !== undefined &&
			address !== undefined &&
			locationId !== undefined &&
			userLocation !== undefined &&
			chosenAssetType !== undefined
		) {
			const condInputs = [];
			if (chosenAssetType?.data()?.inputsNotRequired) {
				setInputsRequired(false);
			} else {
				setInputsRequired(true);
			}
			const condInputsJSON = chosenAssetType.data()?.conditionalInputs || null;
			if (condInputsJSON) {
				for (const input of condInputsJSON) {
					condInputs.push(JSON.parse(input));
				}
			}
			console.log("Conditional: ", condInputs);
			setAssetConditionalInputs(condInputs);
			setIsLoading(false);
		}
	}, [
		responseData,
		formName,
		formLogo,
		address,
		locationId,
		userLocation,
		chosenAssetType,
	]);

	// Updates inputs and responseData when inputsRequired becomes false
	// Else, update inputs only
	useEffect(() => {
		if (!inputsRequired) {
			setInputs(deepCopy(notRequiredInputs));
		} else {
			let newInputs = deepCopy(inputs);
			if (
				assetConditionalInputs.length > 0 &&
				newInputs[4]?.title !== "Asset Details - Part 2"
			) {
				const newPage = {
					title: "Asset Details - Part 2",
					questions: [...assetConditionalInputs],
				};
				newInputs = [...newInputs.slice(0, 4), newPage, ...newInputs.slice(4)];
			}
			if (
				orgSpecificInputs.length > 0 &&
				newInputs.at(-2)?.title !== "Additional Details"
			) {
				const newPage = {
					title: "Additional Details",
					questions: [...orgSpecificInputs],
				};
				newInputs = [
					...newInputs.slice(0, -1),
					newPage,
					...newInputs.slice(-1),
				];
			}
			setInputs(newInputs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputsRequired, assetConditionalInputs, orgSpecificInputs]);
	useEffect(() => {
		if (
			assetLocationChanged &&
			selectedState &&
			responseData.assetLocation !== "FRONT OF STORE" &&
			organization !== "SSPAMERICA"
		) {
			setAlertModal({
				show: true,
				title: "Area Change for Asset",
				body: "Asset location has changed. Did the asset area change to?",
				options: [
					{
						text: "Yes",
						action: () => {
							setAreaChanged(true);
							closeAlertModal();
						},
					},
					{
						text: "No",
						action: () => {
							setAreaChanged(false);
							closeAlertModal();
						},
					},
				],
			});
		}
	}, [assetLocationChanged, selectedState]);

	useEffect(() => {
		console.log("Options loading", options);

		getOptionsData(
			inputs,
			assetConditionalInputs,
			orgSpecificInputs,
			setOptions,
			organization,
			formId,
		).catch(console.error);
	}, [
		inputs,
		assetConditionalInputs,
		orgSpecificInputs,
		setOptions,
		organization,
		formId,
	]);

	// Converts date field between formats
	const _convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split("T");
			return tempDate[0];
		}
		return "";
	};

	const filterKeys = (key) => {
		console.log("key for filtering", key);
		if (key === "manufacturersPlateAssetImage") {
			return !responseData.manufacturerNotAvailable;
		}
		if (
			key === "assetType" ||
			key === "tempAlertId" ||
			key === "tempAlertIdAssetImage" ||
			key === "manufacturer" ||
			key === "ifOtherManufacturer" ||
			key === "modelNumber" ||
			key === "serialNumber" ||
			key === "manufacturerDate" ||
			key === "assetTag" ||
			key === "idTagAssetImage" ||
			key === "warrantyNotFound" ||
			key === "warrantyPeriod" ||
			key === "warrantyStartDate" ||
			key === "warrantyExpirationDate" ||
			key === "warrantyProvider" ||
			key === "refrigerant" ||
			key === "refrigerantCharge" ||
			key === "voltage" ||
			key === "amps" ||
			key === "powerSource" ||
			key === "typeOfSystem" ||
			key === "numDoors"
		) {
			return true;
		}
		return false;
	};
	const generateQuestions = () => {
		const seenKeys = new Set();
		const questions = inputs.flatMap((page) => page.questions);

		const priorityKeys = ["location", "equipmentAssetImage", "assetCondition"];
		const sortedQuestions = questions.sort((a, b) => {
			const indexA = priorityKeys.indexOf(a.key);
			const indexB = priorityKeys.indexOf(b.key);
			if (indexA === -1 && indexB === -1) return 0;
			if (indexA !== -1 && indexB === -1) return -1;
			if (indexA === -1 && indexB !== -1) return 1;
			return indexA - indexB;
		});

		return sortedQuestions.map((question) => {
			const hasError = validationState[question.key];

			if (seenKeys.has(question.key)) {
				return null;
			}
			seenKeys.add(question.key);

			// Skip if response data is not available or the key is filtered out
			if (!responseData[question.key] || filterKeys(question.key)) return null;

			if (question.type === "image") {
				if (question.key === "equipmentAssetImage") {
					return (
						<FormGroup
							key={question.key}
							className="p-3 d-flex flex-column"
							style={{ borderColor: hasError ? "red" : "" }}
						>
							<Label className="submission-label text-center">
								{question.label}
							</Label>
							<div className="d-flex flex-column align-items-center">
								<img
									className="submitted-image"
									src={uploadedImage || responseData[question.key]}
									alt="Uploaded Preview"
									style={{
										maxWidth: "100%",
										cursor: "pointer",
										border: hasError ? "1px solid red" : "",
									}}
									onClick={() => fileInputRef.current?.click()}
								/>
								<Button
									color="secondary"
									onClick={() => fileInputRef.current?.click()}
									className="w-100"
								>
									Add new photo
								</Button>
								<Input
									innerRef={fileInputRef}
									type="file"
									onChange={handleImageUpload}
									style={{ display: "none" }}
									accept="image/*"
								/>
								{hasError && (
									<div className="text-danger mt-2">
										This field is required.
									</div>
								)}
							</div>
						</FormGroup>
					);
				}
				return (
					<FormGroup
						key={question.key}
						className="p-3 d-flex flex-column"
						style={{ borderColor: hasError ? "red" : "" }}
					>
						<Label className="submission-label text-center">
							{question.label}
						</Label>
						<div className="position-relative submitted-image-parent">
							<img
								className="submitted-image"
								alt=""
								src={responseData[question.key]}
								onClick={() => openImgModal(responseData[question.key])}
								style={{
									border: hasError ? "1px solid red" : "",
								}}
							/>
							{hasError && (
								<div className="text-danger mt-2">This field is required.</div>
							)}
						</div>
					</FormGroup>
				);
			}
			if (question.type === "checkbox") {
				return (
					<FormGroup
						key={question.key}
						className="p-3"
						style={{ borderColor: hasError ? "red" : "" }}
					>
						<Label className="submission-label text-center">
							{question.label}
						</Label>
						<Input
							disabled
							className="bg-white"
							type="text"
							value={(() => {
								if (responseData[question.key] === true) {
									return "true";
								}
								return "false";
							})()}
							style={{
								border: hasError ? "1px solid red" : "",
							}}
						/>
						{hasError && (
							<div className="text-danger mt-2">This field is required.</div>
						)}
					</FormGroup>
				);
			}
			if (question.key === "location") {
				if (assetLocationChanged) {
					return (
						<FormGroup
							className="p-3 d-flex flex-column align-items-center"
							style={{ borderColor: hasError ? "red" : "" }}
						>
							<Input
								disabled
								className="bg-white"
								type="text"
								value={address}
								style={{
									border: hasError ? "1px solid red" : "",
								}}
							/>
							{!isPickerOpen && (
								<Button
									onClick={handleOpenPicker}
									color="secondary"
									className="w-100"
								>
									Change Location
								</Button>
							)}
							{isPickerOpen && (
								<div className="d-flex flex-column align-items-center w-100">
									<NewLocationPicker
										setChosenLocation={handleLocationChange}
										organization={organization}
										isOpen={isPickerOpen}
										setIsOpen={setIsPickerOpen}
										setUserLocation={setUserLocation}
									/>
									<Button
										onClick={handleClosePicker}
										color="secondary"
										className="w-100"
									>
										Cancel
									</Button>
								</div>
							)}
							{hasError && (
								<div className="text-danger mt-2">This field is required.</div>
							)}
						</FormGroup>
					);
				}
				// else {
				// 	return (
				// 		<FormGroup
				// 			key={question.key}
				// 			className='p-3 d-flex flex-column'
				// 		>
				// 			<div className='position-relative'>
				// 				<Input
				// 					disabled
				// 					className='bg-white'
				// 					type='text'
				// 					value={address}
				// 				/>
				// 			</div>
				// 		</FormGroup>
				// 	);
				// }
			} else if (
				question.key === "assetCondition" &&
				question.type === "rating"
			) {
				const options = question.options.map((option) => ({
					label: option,
					value: option.split(" - ")[0],
				}));

				const currentOption = options.find(
					(option) => option.value === responseData[question.key],
				);

				return (
					<FormGroup
						key={question.key}
						className="p-3 d-flex flex-column"
						// style={{ borderColor: hasError ? 'red' : '' }}
					>
						<Label className="submission-label text-center">
							{question.label}
						</Label>
						<Select
							value={currentOption}
							options={options}
							onChange={(selectedOption) => {
								console.log("Selected Option", selectedOption);

								setResponseData((prevData) => ({
									...prevData,
									[question.key]: selectedOption.value,
								}));
							}}
							className="react-select-container"
							classNamePrefix="react-select"
							// styles={{
							// 	control: (provided) => ({
							// 		...provided,
							// 		borderColor: hasError ? 'red' : '',
							// 	}),
							// }}
						/>
						{isSubmitted && isConditionSame && (
							<div className="text-center mt-2">
								Asset condition is the same as before.
							</div>
						)}
					</FormGroup>
				);
			} else if (
				organization === "cvs.com" &&
				question.key === "assetSubArea"
			) {
				if (areaChange) {
					const options = question.options.map((option) => ({
						label: option,
						value: option.split(" - ")[0],
					}));

					const currentOption = options.find(
						(option) => option.value === responseData[question.key],
					);

					return (
						<FormGroup
							key={question.key}
							className="p-3 d-flex flex-column"
							style={{ borderColor: hasError ? "red" : "" }}
						>
							<Label className="submission-label text-center">
								{question.label}
							</Label>
							<Select
								value={currentOption}
								options={options}
								onChange={(selectedOption) => {
									handleFieldChange(question.key, selectedOption.value);
								}}
								className="react-select-container"
								classNamePrefix="react-select"
								styles={{
									control: (provided) => ({
										...provided,
										borderColor: hasError ? "red" : "",
									}),
								}}
							/>
							{hasError && (
								<div className="text-danger mt-2">This field is required.</div>
							)}
						</FormGroup>
					);
				}
			} else if (
				organization !== "cvs.com" &&
				question.key === "assetLocation"
			) {
				if (areaChange) {
					const formattedOptions = options["equipment-area"].map((opt) => ({
						value: opt.key,
						label: opt.value,
					}));

					const currentOption = formattedOptions.find(
						(option) => option.label === responseData[question.key],
					);

					return (
						<FormGroup
							key={question.key}
							className="p-3 d-flex flex-column"
							style={{ borderColor: hasError ? "red" : "" }}
						>
							<Label className="submission-label text-center">
								{question.label}
							</Label>
							<Select
								value={currentOption}
								onChange={(selectedOption) => {
									handleFieldChange(question.key, selectedOption.label);
								}}
								options={formattedOptions}
								className="react-select-container"
								classNamePrefix="react-select"
								styles={{
									control: (provided) => ({
										...provided,
										borderColor: hasError ? "red" : "",
									}),
								}}
							/>
							{hasError && (
								<div className="text-danger mt-2">This field is required.</div>
							)}
						</FormGroup>
					);
				}
				// }
				// else {
				// 	return (
				// 		<FormGroup
				// 			key={question.key}
				// 			className='p-3 d-flex flex-column'
				// 		>
				// 			<Label className='submission-label text-center'>
				// 				{question.label}
				// 			</Label>
				// 			<div className='position-relative'>
				// 				<Input
				// 					disabled
				// 					className='bg-white'
				// 					type='text'
				// 					value={responseData[question.key]}
				// 				/>
				// 			</div>
				// 		</FormGroup>
				// 	);
				// }
			}
			// else if (question.key === 'assetType') {
			// 	if (
			// 		organization !== 'COOPERSHAWK' &&
			// 		organization !== 'applegreen' &&
			// 		question.flag === 'noAssetFamily'
			// 	) {
			// 		return (
			// 			<FormGroup
			// 				key={question.key}
			// 				className='p-3 d-flex flex-column'
			// 			>
			// 				<Label className='submission-label text-center'>
			// 					{question.label}
			// 				</Label>
			// 				<div className='position-relative'>
			// 					<Input
			// 						disabled
			// 						className='bg-white'
			// 						type='text'
			// 						value={responseData[question.key]}
			// 					/>
			// 				</div>
			// 			</FormGroup>
			// 		);
			// 	} else if (
			// 		(organization === 'COOPERSHAWK' ||
			// 			organization === 'applegreen') &&
			// 		question.flag === 'allowAssetFamily'
			// 	) {
			// 		if (question.label.includes('Asset Family')) {
			// 			return (
			// 				<FormGroup
			// 					key={question.key}
			// 					className='p-3 d-flex flex-column'
			// 				>
			// 					<Label className='submission-label text-center'>
			// 						{question.label}
			// 					</Label>
			// 					<div className='position-relative'>
			// 						<Input
			// 							disabled
			// 							className='bg-white'
			// 							type='text'
			// 							value={responseData[question.key]}
			// 						/>
			// 					</div>
			// 				</FormGroup>
			// 			);
			// 		} else if (question.label.includes('Asset Type')) {
			// 			return (
			// 				<FormGroup
			// 					key={question.key}
			// 					className='p-3 d-flex flex-column'
			// 				>
			// 					<Label className='submission-label text-center'>
			// 						{question.label}
			// 					</Label>
			// 					<div className='position-relative'>
			// 						<Input
			// 							disabled
			// 							className='bg-white'
			// 							type='text'
			// 							value={responseData[question.key]}
			// 						/>
			// 					</div>
			// 				</FormGroup>
			// 			);
			// 		}
			// 	}
			// }
			// else if (question.key === 'assetTypeCategory') {
			// 	if (
			// 		!question.label.includes('Asset Types') &&
			// 		organization === 'applegreen'
			// 	) {
			// 		return (
			// 			<FormGroup
			// 				key={question.key}
			// 				className='p-3 d-flex flex-column'
			// 			>
			// 				<Label className='submission-label text-center'>
			// 					{question.label}
			// 				</Label>
			// 				<div className='position-relative'>
			// 					<Input
			// 						disabled
			// 						className='bg-white'
			// 						type='text'
			// 						value={responseData[question.key]}
			// 					/>
			// 				</div>
			// 			</FormGroup>
			// 		);
			// 	} else if (
			// 		question.label.includes('Asset Types') &&
			// 		organization === 'COOPERSHAWK'
			// 	) {
			// 		return (
			// 			<FormGroup
			// 				key={question.key}
			// 				className='p-3 d-flex flex-column'
			// 			>
			// 				<Label className='submission-label text-center'>
			// 					{question.label}
			// 				</Label>
			// 				<div className='position-relative'>
			// 					<Input
			// 						disabled
			// 						className='bg-white'
			// 						type='text'
			// 						value={responseData[question.key]}
			// 					/>
			// 				</div>
			// 			</FormGroup>
			// 		);
			// 	}
			// }
			// else if (question.key === 'manufacturer') {
			// 	if (responseData[question.checkbox.key] == true) {
			// 		filterKeys(manufacturersPlateAssetImage == false);
			// 	} else {
			// 		filterKeys(manufacturersPlateAssetImage == true);
			// 	}
			// }
			// else {
			// 	return (
			// 		<FormGroup
			// 			key={question.key}
			// 			className='p-3 d-flex flex-column'
			// 			style={{ borderColor: hasError ? 'red' : '' }}
			// 		>
			// 			<Label className='submission-label text-center'>
			// 				{question.label}
			// 			</Label>
			// 			<Input
			// 				className='bg-white'
			// 				type='text'
			// 				value={responseData[question.key]}
			// 				onChange={(e) =>
			// 					handleFieldChange(question.key, e.target.value)
			// 				}
			// 				style={{
			// 					border: hasError ? '1px solid red' : '',
			// 				}}
			// 			/>
			// 			{hasError && (
			// 				<div className='text-danger mt-2'>
			// 					This field is required.
			// 				</div>
			// 			)}
			// 		</FormGroup>
			// 	);
			// }
		});
	};

	const openImgModal = (response) => {
		setSelectedPhotoUrl(response);
		setShowImageModal(true);
		// setSelectedItem(response);
		// setIsPhotoShowing(true);
		// setSelectedImage(response);
		// setShowImageModal(true);
		// setIsPhotoShowing(true);
	};

	const canUserEdit = () => {
		const userRole = authInfo.role;
		const date1 = new Date(responseData?.submittedDate);
		const date2 = new Date();
		const diffInMilliseconds = date2.getTime() - date1.getTime();
		const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
		if (userRole === "superuser") {
			return true;
		}
		if (userRole === "user" && diffInHours <= 72) {
			return true;
		}
		if (userRole === "admin") {
			const diffInWeeks = Math.floor(diffInHours / 168);
			if (diffInWeeks < 9) {
				return true;
			}
		}

		return false;
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<img src={formLogo} className="submission-logo" alt="logo" />

				<CardHeader
					className="d-flex flex-row align-items-center justify-content-between"
					style={{
						backgroundColor: "#ffffff",
						border: "none",
						position: "relative",
					}}
				>
					<div
						style={{
							position: "absolute",
							right: "10px",
							top: "10px",
						}}
					>
						{/* <Button
							onClick={() => {
								setAlertModal({
									show: true,
									title: 'Decommission Asset',
									body: 'Are you sure you want to decommission this asset?',
									options: [
										{
											text: 'Yes',
											action: handleDecommission,
										},
										{
											text: 'No',
											action: () => {
												setAlertModal({
													show: false,
													title: '',
													body: '',
												});
											},
										},
									],
								});
							}}
						>
							<MdOutlineDelete />
						</Button>*/}
					</div>
				</CardHeader>

				<CardBody style={{ marginTop: "20px" }}>
					{(() => {
						if (responseData.deleted) {
							let text = "DELETED";
							if (responseData.deletedBy && responseData.deletedDate) {
								text += ` by ${responseData.deletedBy} on ${formatDateMMDDYYYY(
									responseData.deletedDate,
								)}`;
							}
							return (
								<div
									className="w-100 p-2 bg-red text-center text-white fw-bold"
									style={{ backgroundColor: "red" }}
								>
									{text}
								</div>
							);
						}
					})()}
					{generateQuestions()}

					<div className="d-flex align-items-center justify-content-center">
						<div className="d-flex flex-row">
							<Button
								className="m-2 px-4"
								onClick={handleSubmit}
								disabled={!canUserEdit() || responseData.deleted}
							>
								Submit
								<AiOutlineEdit className="fs-5 mx-1" />
							</Button>
						</div>
					</div>
				</CardBody>
			</Card>
			<SubmittedImageModal
				showing={showImageModal}
				photoUrl={selectedPhotoUrl}
				isZoom={true}
				cancel={() => setShowImageModal(false)}
			/>
		</div>
	);
};

export { CheckUpsEdit };
