import { ColumnsType } from "antd/es/table";
import { getOrgColumns } from "../children/AssetsTableColumns";

const getColumnsShown = (columns: ColumnsType): Record<string, boolean> => {
	const columnsToHide = [
		"sub_org",
		"temp_alert_id",
		"asset_type_category",
		"submitted_date",
	];
	const shown: Record<string, boolean> = {};
	for (const col of columns) {
		if (col.key) {
			const key = col.key.toString();
			shown[key] = !columnsToHide.includes(key);
		}
	}
	return shown;
};

export const initializeColumns = (organization: string, dispatch: any) => {
	const columns = getOrgColumns(organization);
	dispatch({
		type: "SET_TABLE_COLUMNS",
		payload: columns,
	});
	dispatch({
		type: "SET_COLUMNS_SHOWN",
		payload: getColumnsShown(columns),
	});
};
