import { ColumnsType } from "antd/lib/table";

export const transformData = (hits: any[], currentColumns: ColumnsType) => {
	return hits.map((hit) => {
		const transformedItem: any = { id: hit.id };

		currentColumns.forEach((column) => {
			const dataIndex = column.dataIndex as string;
			switch (dataIndex) {
				case "role":
					transformedItem[dataIndex] = hit?.role.name || "N/A";
					break;
				default:
					transformedItem[dataIndex] = hit[dataIndex];
					break;
			}
		});

		return transformedItem;
	});
};
