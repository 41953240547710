export const validateAndSetPage5 = (
	organization,
	allRequiredFieldsPresent,
	allRequiredFieldsPresentAdditionalDetails,
	setIsPage5Valid,
) => {
	const isValid =
		organization === "SSPAMERICA" || organization === "EcoTrak"
			? !!allRequiredFieldsPresent //SSPAMERICA
			: !!allRequiredFieldsPresentAdditionalDetails; //cvs, applegreen, campingworld, carliecs, foodlion, raisingcanes, razoos, DIGI, swarovski

	setIsPage5Valid(isValid);
};
