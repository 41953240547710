import { CalendarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Dropdown, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState } from "react";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

interface MonthRangePickerProps {
	onRangeChange?: (dates: [string | null, string | null]) => void;
}

const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
	onRangeChange,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [selectedRange, setSelectedRange] = useState<
		[Dayjs | null, Dayjs | null] | null
	>(null);

	const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
		console.log("dates", dates);

		setSelectedRange(dates);
		setDropdownOpen(false); // Close dropdown after selection

		if (onRangeChange) {
			const formattedDates: [string | null, string | null] = [
				dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
				dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
			];
			onRangeChange(formattedDates);
		}
	};

	const getRangeLabel = () => {
		if (selectedRange && selectedRange[0] && selectedRange[1]) {
			return `${selectedRange[0].format("MM/DD/YYYY")} to ${selectedRange[1].format("MM/DD/YYYY")}`;
		}
		return "Select Date Range";
	};

	return (
		<Dropdown
			trigger={["click"]}
			open={dropdownOpen}
			onOpenChange={(visible) => setDropdownOpen(visible)}
			dropdownRender={() => (
				<div
					style={{
						padding: "8px",
						backgroundColor: "white",
						borderRadius: "4px",
					}}
				>
					<Space direction="vertical">
						<RangePicker
							format="MM/DD/YYYY"
							style={{ width: "100%" }}
							onChange={handleRangeChange}
							value={selectedRange}
						/>
					</Space>
				</div>
			)}
		>
			<Button icon={<CalendarOutlined />} type="text">
				{getRangeLabel()}
			</Button>
		</Dropdown>
	);
};

export default MonthRangePicker;
