import { createChatBotMessage } from "react-chatbot-kit";

export const handleFile = async (file, handleFileDrop, setState) => {
	console.log("handle file");
	const message = createChatBotMessage("You dropped a file!", {
		widget: "FilePreview",
	});

	handleFileDrop(file).then((response) => {
		const responseMessage = createChatBotMessage(response.text, {
			withAvatar: true,
		});

		setState((prev) => ({
			...prev,
			messages: [...prev.messages, responseMessage],
			file,
		}));
	});

	setState((prev) => ({
		...prev,
		messages: [...prev.messages, message],
		file,
	}));
};
