import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useUserStore } from "@/stores";
import {
	addUserInternal,
	adminUpdateUser,
	deleteUser,
	getOrganizationSubmittedUsers,
	getOrganizationUsers,
	getRoles,
	getUserSelf,
	selfUpdateUser,
} from "@services/user-service";

export const useGetUser = () => {
	return useQuery({
		queryKey: ["self_user"],
		queryFn: getUserSelf,
	});
};

/**
 * Returns a mutation function that updates a user's data based on the given parameters.
 * This is used to edit other users and not self. Only used be ADMIN OR ABOVE
 *
 * @return {Function} The mutation function.
 */
export const useAdminUpdateUser = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params) => {
			const { userId, type, data, filterTable } = params as any;

			switch (type) {
				case "filter":
					await selfUpdateUser({
						saved_filters: { [filterTable]: data },
					});
					break;
				case "image":
					await selfUpdateUser({ profile_picture_url: data });
					break;
				case "base":
					await adminUpdateUser(userId, {
						name: data.name,
						role_id: data.role.id,
						organization_id: data.organization_id,
					});
					break;
			}
			return;
		},

		onSuccess: async () => {
			queryClient.invalidateQueries("user" as any);
		},
	});
};

/**
 * Returns a mutation function to update the current (self)'s user's properties.
 * Only used to edit things such as profile picture, config, name, organization
 *
 * @return {Function} The mutation function.
 */
export const useSelfUpdateUser = () => {
	const queryClient = useQueryClient();
	const { setUser, setUserOrganization } = useUserStore() as any;

	return useMutation({
		mutationFn: selfUpdateUser,
		onSuccess: async (data) => {
			queryClient.invalidateQueries(["self_user"]);
			queryClient.invalidateQueries(["self_organization"]);
			queryClient.setQueryData(["self_user"], data);
			queryClient.setQueryData(["self_organization"], data.organization);
			setUser(data);
			setUserOrganization(data.organization);
		},
	});
};

/**
 * Returns a mutation hook for setting a user with given id and data.
 *
 * @return {function} A mutation hook
 */
export const useSetUser = () => {
	const queryClient = useQueryClient();
	const setUserState = useUserStore?.getState()?.setUser;

	return useMutation({
		mutationFn: async (user) => {
			console.log(user);
			queryClient.invalidateQueries(["self_user"]);
			queryClient.setQueryData(["self_user"], user);
			setUserState(user);
		},
	});
};

/**
 * Retrieves the list of users from an organization based on the current org of the user
 *
 * @return {QueryResult} The query result containing the organization users.
 */
export const useGetOrganizationUsers = () => {
	return useQuery({
		queryKey: ["organizationUsers"],
		queryFn: () => getOrganizationUsers(),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});
};

/**
 * Fetches a list of all roles, for now we exclude the superuser role
 *
 * @return {QueryResult} The query result containing the organization users.
 */
export const useGetRoles = () => {
	return useQuery({
		queryKey: ["roles"],
		queryFn: () => getRoles(),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

export const useGetOrganizationSubmittedUsers = (org: any, formId: any) => {
	return useQuery({
		queryKey: ["organizationSubmittedUsers", org, formId],
		queryFn: () => getOrganizationSubmittedUsers(formId),
		enabled: !!org && !!formId,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

/**
 * @returns {{
 *   mutate: function, // A function to trigger the mutation and add a temporary user.
 *   error: Error,     // An error object if an error occurs during the mutation.
 *   isLoading: boolean // Indicates whether the mutation is in progress.
 * }}
 */

export const useDeleteUser = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params) => {
			await deleteUser(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries("user" as any);
		},
	});
};

/**
 * @returns {{
 *   mutate: function, // A function to trigger the mutation and add a temporary user.
 *   error: Error,     // An error object if an error occurs during the mutation.
 *   isLoading: boolean // Indicates whether the mutation is in progress.
 * }}
 **/

export const useAddUser = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: any) => {
			await addUserInternal(
				params.name,
				params.email,
				params.password,
				params.role,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries("user" as any);
		},
	});
};
