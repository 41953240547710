import { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { Card, CardBody, CardHeader } from "reactstrap";

export const DistanceToCoastCard = (props) => {
	const { latitude, longitude, nearestShorePoint } = props;

	const mapRef = useRef(null);

	useEffect(() => {
		const loadMap = () => {
			const google = window.google;
			const mapOptions = {
				zoom: 10,
				center: new google.maps.LatLng(Number(latitude), Number(longitude)),
				mapTypeId: "roadmap",
			};
			const map = new google.maps.Map(mapRef.current, mapOptions);

			// Marker for the store location
			new google.maps.Marker({
				position: new google.maps.LatLng(Number(latitude), Number(longitude)),
				map: map,
				label: { text: "Store", className: "custom-map-marker" },
			});

			// Custom control for displaying distance
			const controlDiv = document.createElement("div");
			const root = createRoot(controlDiv);
			root.render(
				<DistanceDisplay distance={nearestShorePoint?.distance?.miles} />,
			);

			map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
		};

		if (window.google) {
			loadMap();
		}
	}, [latitude, longitude, nearestShorePoint]);

	return (
		<Card className="mt-4 submissions-table gen-tab-submission gen-info-card-wrapper w-100 overflow-hidden">
			<CardHeader className="absorbed-tickets-title">
				Distance to Coast
			</CardHeader>
			<CardBody>
				<div ref={mapRef} className="shorepoint-map-container" />
			</CardBody>
		</Card>
	);
};

const DistanceDisplay = ({ distance }) => {
	// check if distance is null or undefined
	return !distance ? null : (
		<div className="coastal-distance-card">
			<strong>Distance to Coast:</strong> {distance.toFixed(2)}{" "}
			{distance === 1 ? "mile" : "miles"}
		</div>
	);
};
