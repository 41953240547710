// File: OCRResponse.tsx

import { Typography } from "antd";

interface OCRResponse {
	model_text?: string | null;
	serial_text?: string | null;
	manufacturer?: string | null;
	model_confidence?: number | null;
	serial_confidence?: number | null;
	extra_fields?: Record<
		string,
		{ text: string | null; confidence: number | null }
	>;
	warnings?: string[];
}

interface OCRResponseComponentProps {
	ocrResponse: OCRResponse;
}

export const OCRResponseComponent: React.FC<OCRResponseComponentProps> = ({
	ocrResponse,
}) => {
	return (
		<Typography.Paragraph>
			{ocrResponse.model_text && (
				<p>
					<strong>Model:</strong> {ocrResponse.model_text} (
					{ocrResponse.model_confidence})
				</p>
			)}
			{ocrResponse.serial_text && (
				<p>
					<strong>Serial:</strong> {ocrResponse.serial_text} (
					{ocrResponse.serial_confidence})
				</p>
			)}
			{ocrResponse.manufacturer && (
				<p>
					<strong>Manufacturer:</strong> {ocrResponse.manufacturer}
				</p>
			)}
			{ocrResponse.extra_fields &&
				Object.keys(ocrResponse.extra_fields).map((key) => (
					<p key={key}>
						<strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{" "}
						{ocrResponse.extra_fields?.[key].text} (
						{ocrResponse.extra_fields?.[key].confidence})
					</p>
				))}
			{ocrResponse.warnings && ocrResponse.warnings.length > 0 && (
				<div>
					<p>
						<strong>Warnings:</strong>
					</p>
					<ul>
						{ocrResponse.warnings.map((warning, index) => (
							<li key={`${warning}-${index}`}>{warning}</li>
						))}
					</ul>
				</div>
			)}
		</Typography.Paragraph>
	);
};
