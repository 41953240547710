import { useFormStore } from "@stores";
import { GrCamera, GrCheckmark } from "react-icons/gr";
import { Input } from "reactstrap";

export const RCImageOptions = ({
	pageIndex,
	sectionIndex,
	questionIndex,
	input,
	addPhotoToInputs,
	setPhotoToBeCropped,
}) => {
	const imageOptions = [];

	if (input.value && input.value.length > 0) {
		for (let index = 0; index < input.value.length; index++) {
			const imageUrl = input.value[index];
			if (imageUrl) {
				imageOptions.push(
					<label
						className="image-upload-button"
						key={`${input.responseTag}-${index}`}
						onClick={() => {
							useFormStore.setState({
								selectedPhotoUrl: imageUrl,
							});
							useFormStore.setState({ isPhotoShowing: true });
							useFormStore.setState({
								selectedItem: {
									pageIndex,
									sectionIndex,
									questionIndex,
									index,
									responseTag: input.responseTag,
								},
							});
						}}
					>
						<GrCheckmark className="me-2" />
						{input.label} {index + 1}
					</label>,
				);
			} else {
				imageOptions.push(
					<label
						className="image-upload-button"
						key={`${input.responseTag}-${index}`}
						htmlFor={`photo-${pageIndex}-${sectionIndex}-${questionIndex}-${index}`}
					>
						<GrCamera className="me-2" />
						{input.label} {index + 1}
						<Input
							id={`photo-${pageIndex}-${sectionIndex}-${questionIndex}-${index}`}
							type="file"
							onChange={(e) =>
								addPhotoToInputs(
									e,
									pageIndex,
									sectionIndex,
									questionIndex,
									input.responseTag,
								)
							}
						/>
					</label>,
				);
			}
		}
	}

	const addNewImageButton = (
		<label
			className="image-upload-button"
			key={`${input.responseTag}-new`}
			htmlFor={`photo-${pageIndex}-${sectionIndex}-${questionIndex}-new`}
		>
			<GrCamera className="me-2" />
			Add {input.label}
			<Input
				id={`photo-${pageIndex}-${sectionIndex}-${questionIndex}-new`}
				type="file"
				onChange={(e) =>
					addPhotoToInputs(
						e,
						pageIndex,
						sectionIndex,
						questionIndex,
						input.responseTag,
					)
				}
			/>
		</label>
	);

	return (
		<div className="d-flex align-items-center flex-wrap">
			{imageOptions}
			{addNewImageButton}
		</div>
	);
};
