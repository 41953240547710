const newInputs = [
	// page 1 - location
	{
		title: "Physical Location",
		questions: [
			{
				type: "select",
				label: "Enter Your Location",
				key: "location",
				required: "true",
			},
		],
	},
	// page 2 - upload photos
	{
		title: "Creation Process",
		key: "fvupload",
		questions: [
			{
				type: "createFacilityVision",
				label: "fvupload",
				hideLabel: false,
				key: "createFacilityVision",
				required: "true",
				multiple: true,
				min: 7,
				max: 15,
			},
		],
	},
	// page 3 - preview
	{
		title: "Preview",
		questions: [
			{
				type: "previewFacilityVision",
				label: "fvpreview",
				hideLabel: false,
				key: "previewFacilityVision",
				required: "true",
				multiple: false,
			},
		],
	},
];

export default newInputs;
