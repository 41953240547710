import { useEffect, useState } from "react";
import { Button, Input, List, Modal, ModalBody, ModalHeader } from "reactstrap";
import "@assets/scss/cropper.css";
import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { DB_ORG } from "@constants/db";
import { FiChevronRight } from "react-icons/fi";
import { Loader } from "../../../../Loader/Loader";

export const LocationPicker = ({
	organization,
	setSelectedDependencyValue,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [latLon, setLatLon] = useState({});
	const [geoLocations, _setGeoLocations] = useState([]);
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [searchVal, setSearchVal] = useState("");
	const [timeoutId, setTimeoutId] = useState(null);
	const [_isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
	const [chosenLocation, setChosenLocation] = useState(null);

	const displayLocationText = () => {
		if (!chosenLocation) {
			return "Select a Location";
		}
		if (organization !== DB_ORG.CUSHMANWAKEFIELD) {
			return `${chosenLocation.name}: ${chosenLocation.address1}`;
		}
		return chosenLocation.name;
	};

	const [locationText, setLocationText] = useState(displayLocationText());
	useEffect(() => {
		setLocationText(displayLocationText());
	}, [chosenLocation]);

	const updateMedia = () => {
		setIsDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	const updateSearchVal = (e) => {
		// Clear any existing timeout
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timeout to update the state after 3 seconds
		const newTimeoutId = setTimeout(() => {
			setIsLoading(true);
			setSearchVal(e.target.value);
		}, 2000);

		// Store the timeout ID so it can be cleared if the user types again
		setTimeoutId(newTimeoutId);
	};

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const getLocations = () => {
		let locations;
		let usingGeo = false;
		if (searchVal === "" && latLon) {
			locations = geoLocations;
			usingGeo = true;
		} else {
			locations = searchedLocations;
		}
		const results = [];
		if (locations.length === 0 && !usingGeo) {
			return <div>No Search Results</div>;
		}
		locations.forEach((location, i) => {
			results.push(
				<li
					key={`loc-num-${i}`}
					className="picker-item d-flex flex-column align-items-start"
					onClick={() => {
						selectItem(location);
						toggle();
					}}
				>
					<div>
						{`${
							(location.name ? `${location.name}: ` : "") +
							(location.address || location.address1 || "")
						}, ${location.city}, ${location.state}`}
						{/* <FiChevronRight /> */}
					</div>
				</li>,
			);
		});

		return results;
	};

	const selectItem = (item) => {
		if (organization !== DB_ORG.CUSHMANWAKEFIELD) {
			setLocationText(`${item.name}: ${item.address1}`);
		} else {
			setLocationText(item.name);
		}
		setSelectedDependencyValue(item);
		setChosenLocation(item);
	};

	const searchForLocation = async (e?: Event) => {
		if (e != null) {
			e.preventDefault();
		}
		try {
			const url = `${ALFRED_SERVICE_URL}/location/search/location_picker?search_value=${searchVal}`;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});

			if (response.status !== 200) {
				return response.json().then((errorDetails) => {
					throw new Error(
						`Failed to search for location in location picker: ${errorDetails.detail}`,
					);
				});
			}

			const results = await response.json();
			setSearchedLocations(results);
			setIsLoading(false);
		} catch (exception: any) {
			throw new Error(exception);
		}
	};

	useEffect(() => {
		if ("geolocation" in navigator && !chosenLocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const loc = {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					};
					setLatLon(loc);
				},
				(error) => {
					console.log(error);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (searchVal.length > 0) {
			searchForLocation();
		} else {
			setSearchedLocations([]);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latLon, searchVal]);

	useEffect(() => {
		if (geoLocations.length > 0) {
			selectItem(geoLocations[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoLocations]);

	return (
		<div>
			<Button
				className="blank-btn"
				id="locationPopover"
				type="button"
				onClick={toggle}
			>
				{locationText} <FiChevronRight />
			</Button>
			<Modal className="modal-restrict" isOpen={isOpen}>
				<ModalHeader toggle={toggle}>
					<Input
						type="search"
						placeholder="Search For Location"
						disabled={isLoading}
						onChange={updateSearchVal}
						style={{ width: "100%" }}
					/>
				</ModalHeader>
				<ModalBody style={{ overflow: "scroll", maxHeight: "500px" }}>
					{isLoading ? (
						<Loader small={true} />
					) : (
						<List style={{ overflow: "scroll" }}>{getLocations()}</List>
					)}
				</ModalBody>
			</Modal>
		</div>
	);
};
