export const DB_PATH = {
	ORGANIZATIONS: "organizations",
	FORMS: "forms",
	LOCATIONS: "locations",
	ABSORBED_TICKETS: "absorbedTickets",
	RESPONSES: "responses",
	QA: "qa-submissions",
	USERS: "users",
	NOTIFICATIONS: "notifications",
	TEMP_USERS: "temp-users",
	ASSET_TYPES: "asset-types",
	ASSETS: "assets",
	ASSET_QUALITY: "asset-quality",
	VERSION_HISTORY: "version-history",
	MANUFACTURERS: "manufacturers",
	MAINTENANCE: "maintenance",
	RELEASES: "releases",
	DRAFTS: "drafts",
	SUBMITTING_USERS: "submitting-users",
	SMART_MANUFACTURERS: "smart-manufacturers",
	MASTER_ASSETS_FOR_REVIEW: "master-assets-for-review",
	ASSET_RANKS: "asset-ranks",
	ASSET_CLASSES: "asset-classes",
	PROPERTY_IDS: "property-ids",
	ASSET_NUMBERS: "asset-numbers",
	EQUIPMENT_AREAS: "equipment-area",
	ASSET_TYPE_CATEGORIES: "asset-type-categories",
};

export const DB_FILTER = {
	NAME: "name",
	EMAIL: "email",
	TYPE: "assetType",
	DELETED: "deleted",
	DECOMMISSIONED: "decommissioned",
	EVEN_OR_ODD: "evenOrOdd",
	WEEK: "week",
	OTHER_ASSET_TAG: "other.assetTag",
	LOCATION_ID: "location.id",
	OTHER_ASSET_CONDITION: "other.assetCondition",
	ASSET_SUBMITTED_DATE: "other.submittedDate",
	OTHER_LOCATION_ID: "other.location.id",
	SAVED_LOCATION_E_ID: "savedLocation.e.id",
	FORM_NAME: "formName",
	FORM_ID: "formId",
	SUBMITTING_USER_ID: "submittingUserId",
	ORGANIZATION: "organization",
	TRIRIGA_BUILDING_SYSTEM_ID: "tririgaBuildingSystemId",
	LOCATION_TRIRIGA_BUILDING_SYSTEM_ID: "location.tririgaBuildingSystemId",
	MANUFACTURER: "manufacturer",
	MODEL_NUMBER: "model_number",
	SERIAL_NUMBER: "serial_number",
	SUBMISSION_COUNT: "submissionCount",
	TICKET_ID: "Ticket ID",
	APPROVED: "reviewed",
	SR_ANALYST: "greSrAnalystId",
	SR_AREA_MNGR: "greSrAreaManagerId",
	FAC_DIRECTOR: "greFacilityDirectorId",
	FAC_DIRECTOR2: "greFacilityDirector2Id",
	LOC_SPECS: "locationSpecFields",
	HEALTH_SCORE: "healthScore",
	SAVED_LOCATION_ID: "savedLocation.id",
	AUDITED: "audited",
	OTHER_AUDITED: "other.audited",
	ASSET_TAG: "assetTag",
	OTHER_ASSET_TYPE_CATEGORY: "other.assetTypeCategory",
};

export const DB_ORDER = {
	ADDRESS: "address1",
	ASSET_TYPES: "assetType",
	DATE: "date",
	ASC: "asc",
	DESC: "desc",
	SUBORG: "subOrg",
};

export const DB_FORM = {
	HANDYMAN: "Handyman Checklist",
	INVENTORY: "Inventory & Tagging Form",
};

export const DB_ORG = {
	CUSHMANWAKEFIELD: "cushmanwakefield.com",
	URBANPLATES: "urbanplates.com",
	DATAFLEET: "datafleetservices.com",
	CAMPINGWORLD: "campingworld.com",
	CVS: "cvs.com",
	DIGI: "DIGI",
	SWAROVSKI: "swarovski.com",
	ECOTRAK: "EcoTrak",
	COOPERSHAWK: "COOPERSHAWK",
	APPLEGREEN: "applegreen",
	AMD: "AMD",
	RAISINGCANES: "raisingcanes.com",
	TOG: "TOG",
	THORNTONS: "Thortons",
	AMC: "amctheatres.com",
	BJSREST: "BJsRestaurant",
	PAPAJOHNS: "PapaJohns",
};
