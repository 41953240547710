import { Button } from "reactstrap";

export const XlsxFileInput = ({ selectedDependencyValue, onFileSelect }) => {
	const handleButtonClick = () => {
		document.getElementById("fileInput").click();
	};

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<input
				id="fileInput"
				type="file"
				onChange={onFileSelect}
				accept=".xlsx"
				style={{ display: "none" }}
			/>
			<Button color="secondary" onClick={handleButtonClick}>
				Choose File
			</Button>
			{selectedDependencyValue && (
				<span className="mx-2">
					{selectedDependencyValue
						? selectedDependencyValue.name
						: "No File Selected"}
				</span>
			)}{" "}
		</div>
	);
};
