import { useEffect } from "react";
import { Button } from "reactstrap";

const Rotate = ({ responseData, setResponseData }) => {
	// State to manage rotation in degrees

	const applyRotation = async (degrees) => {
		const image = new Image();
		image.src = responseData.rotatedImage || responseData.image;
		image.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			// Normalize the angle to 0, 90, 180, or 270 degrees
			degrees = degrees % 360;
			if (degrees < 0) degrees += 360;

			// Calculate the canvas size
			if (degrees === 90 || degrees === 270) {
				canvas.width = image.height;
				canvas.height = image.width;
			} else {
				// 0 or 180 degrees
				canvas.width = image.width;
				canvas.height = image.height;
			}

			// Set the origin to the center of the canvas
			ctx.translate(canvas.width / 2, canvas.height / 2);
			// Rotate the canvas
			ctx.rotate((degrees * Math.PI) / 180);

			// Draw the image on the canvas
			ctx.drawImage(image, -image.width / 2, -image.height / 2);

			// Convert canvas to image URL
			const rotatedImageURL = canvas.toDataURL();

			// Update state with the rotated image
			setResponseData((prevData) => ({
				...prevData,
				rotatedImage: rotatedImageURL,
			}));
		};
	};

	useEffect(() => {
		setResponseData((prevData) => ({
			...prevData,
			rotatedImage: responseData.image,
		}));
	}, []);

	return (
		<div className="step" id="step-rotate">
			<Button id="step-rotate-left-button" onClick={() => applyRotation(-90)}>
				Rotate Left
			</Button>
			<Button id="step-rotate-right-button" onClick={() => applyRotation(90)}>
				Rotate Right
			</Button>
			{(responseData.rotatedImage || responseData.image) && (
				<img
					className="step-rotate-canvas"
					src={responseData.rotatedImage || responseData.image}
				/>
			)}
		</div>
	);
};

export { Rotate };
