import { useChatbotStore, useFormStore } from "@stores";
import { preventSpecialChars } from "@utils/preventSpecialChars";
import { useLocation } from "react-router-dom";
import { Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { validateAfterDelay } from "../childFunctions/formValidation";
import { CheckboxInput } from "./CheckboxInput";

const TagIdInput = ({
	question,
	responseData,
	setInputData,
	flags,
	org,
	formId,
	fromChatbot,
}) => {
	const location = useLocation();
	const {
		assetTagScanned: formAssetTagScanned,
		assetTagError: formAssetTagError,
		setAssetTagError: setFormAssetTagError,
	} = useFormStore();

	const {
		assetTagScanned: chatbotAssetTagScanned,
		assetTagError: chatbotAssetTagError,
		setAssetTagError: setChatbotAssetTagError,
	} = useChatbotStore();

	const assetTagScanned = fromChatbot
		? chatbotAssetTagScanned
		: formAssetTagScanned;
	const assetTagError = fromChatbot ? chatbotAssetTagError : formAssetTagError;
	const setAssetTagError = fromChatbot
		? setChatbotAssetTagError
		: setFormAssetTagError;

	const shouldDisable = () => {
		if (question.disabled === "true") {
			return true;
		}
		return responseData[question.tiedTo] === true;
	};

	return (
		<Row key={question.key}>
			<FormGroup>
				<div
					className={`d-flex align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div>
						<Label className="single-form-label">
							{question.label}
							{flags.tagIdRequired && <span style={{ color: "red" }}> *</span>}
						</Label>
					</div>

					{question.checkbox && (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={setInputData}
							notStandalone={true}
						/>
					)}
				</div>
				{/* <br /> */}
				<Form>
					<FormGroup>
						<Input
							value={responseData[question.key]}
							invalid={assetTagError ? assetTagError.error : null}
							valid={responseData[question.key] ? !assetTagError : false}
							type={question.type}
							key={question.key}
							disabled={assetTagScanned || shouldDisable()}
							onChange={(e) => {
								const val = e.target.value;
								validateAfterDelay(
									val,
									"assetTag",
									setAssetTagError,
									org,
									formId,
									location,
								);
								setInputData(question, val.toUpperCase());
							}}
							onPaste={(e) =>
								preventSpecialChars(e.clipboardData.getData("text"), e, true)
							}
							onKeyPress={(e) => preventSpecialChars(e.key, e, true)}
							className="form-inputs"
						/>
						<FormFeedback
							invalid
							className="line-breaks"
							style={{ textAlign: "center" }}
						>
							{assetTagError ? assetTagError.message : ""}
						</FormFeedback>
					</FormGroup>
				</Form>
			</FormGroup>
		</Row>
	);
};

export { TagIdInput };
