import { AiFillThunderbolt } from "react-icons/ai";

const CarouselItem = () => {
	return (
		<div className="carousel-item-wrapper">
			<AiFillThunderbolt />
		</div>
	);
};

export { CarouselItem };
