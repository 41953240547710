// array of objects that serves as the standard format for the inputs for all non-cushman organizations
//
// all inputs here are constant across all organizations, whereas conditional inputs will be stored in the database

// - 'label': what the user sees for the question
// - 'key': what is used for database purposes
// - 'required': whether or not the question is required
// - 'collection': the collection in the database that the question is tied to
// - 'id': the id of the input, used for populating the input from the OCR response
// - 'tiedTo': the key of the input that this input is tied to; used to disable the tiedto input if the associated checkbox is checked

const newInputs = [
	// page 1 - location
	{
		title: "Physical Location",
		questions: [
			{
				type: "select",
				label: "Enter Your Location",
				key: "location",
				required: "true",
			},
		],
	},
	// page 2 - equipment photo
	{
		title: "Equipment",
		questions: [
			{
				type: "image",
				label: "Photo of Equipment",
				hideLabel: false,
				key: "equipmentAssetImage",
				required: "true",
			},
			{
				type: "image",
				label: "Wide Angle Photo",
				hideLabel: false,
				key: "wideAngleAssetImage",
				required: "true",
				flag: "allowWideAngleImage",
				tooltip:
					"This should be taken to capture the asset with context of what is surrounding it & its placement.",
			},
		],
	},
	// page 3 - manuf. plate photo
	{
		title: "Manufacturer Plate",
		questions: [
			{
				type: "image",
				label: "Photo of Manufacturer Plate",
				hideLabel: false,
				key: "manufacturersPlateAssetImage",
				required: "true",
			},
		],
	},
	// page 4 - asset details
	{
		title: "Asset Details",
		questions: [
			{
				type: "select",
				label: "Asset Location",
				table: "equipment-area",
				key: "assetLocation",
				required: "true",
				flag: "allowAssetLocation",
				hideChatbot: "true",
			},
			{
				type: "select",
				label: "Asset Type",
				collection: "asset-types",
				key: "assetType",
				required: "true",
				id: "assetTypeInput",
				hideChatbot: "true",
				flag: "noAssetFamily",
			},

			{
				type: "select",
				label: "Asset Family",
				collection: "asset-types",
				key: "assetType",
				required: "true",
				id: "assetTypeInput",
				hideChatbot: "true",
				flag: "allowAssetFamily",
			},
			{
				type: "select",
				label: "Asset Types",
				collection: "asset-type-categories",
				key: "assetTypeCategory",
				required: "true",
				flag: "allowAssetTypeCategory",
			},
			// manufacturer input
			{
				type: "select",
				label: "Manufacturer",
				key: "manufacturer",
				tiedTo: "manufacturerNotAvailable",
				required: "true",
				flag: "allowSmartManufacturer",
				collection: "smart-manufacturers",
				previewTag: "manufacturersPlateAssetImage",
				checkbox: {
					type: "checkbox",
					label: "Manufacturer Not Found",
					key: "manufacturerNotAvailable",
					tiedTo: "manufacturer",
				},
			},
			{
				type: "text",
				label: "If Other, Enter Manufacturer",
				key: "ifOtherManufacturer",
				tiedTo: "manufacturer",
			},
			// model number
			{
				type: "text",
				label: "Model Number",
				key: "modelNumber",
				tiedTo: "modelNotAvailable",
				required: "true",
				id: "modelInput",
				previewTag: "manufacturersPlateAssetImage",
				checkbox: {
					type: "checkbox",
					label: "Model Number Not Found",
					key: "modelNotAvailable",
					tiedTo: "modelNumber",
				},
			},
			// serial number
			{
				type: "text",
				label: "Serial Number",
				key: "serialNumber",
				tiedTo: "serialNotAvailable",
				required: "true",
				id: "serialInput",
				previewTag: "manufacturersPlateAssetImage",
				checkbox: {
					type: "checkbox",
					label: "Serial Number Not Found",
					key: "serialNotAvailable",
					tiedTo: "serialNumber",
				},
			},
			// manufacturer date
			{
				type: "date",
				label: "Manufactured Date",
				key: "manufacturerDate",
				tiedTo: "manufacturerDateNotFound",
				required: "true",
				previewTag: "manufacturersPlateAssetImage",
				checkbox: {
					type: "checkbox",
					label: "Manufactured Date Not Found",
					key: "manufacturerDateNotFound",
					tiedTo: "manufacturerDate",
				},
			},
		],
	},
	//
	//
	// OPTIONAL page 5 - asset conditional inputs AND org-specific inputs
	//
	//
	// page 6 - temp alert id photo, scan, and field
	// OPTIONAL based on flags
	{
		title: "Temp Alert ID",
		questions: [
			{
				type: "image",
				label: "Temp Alert ID Image",
				key: "tempAlertIdAssetImage",
				tiedTo: "tempAlertIdNotAvailable",
				hidden: "true",
				required: "true",
				scanTag: "tempAlertId",
				scanType: "barcode",
			},
			{
				type: "text",
				label: "Temp Alert ID",
				key: "tempAlertId",
				tiedTo: "tempAlertIdNotAvailable",
				placeholder: "",
				required: "true",
				checkbox: {
					type: "checkbox",
					label: "Temp Alert ID Not Found",
					key: "tempAlertIdNotAvailable",
					tiedTo: ["tempAlertId", "tempAlertIdAssetImage"],
				},
			},
		],
		flag: "allowTempAlertId",
	},
	// page 7 - asset tag ID + QR code scan
	{
		title: "Asset Tag ID",
		questions: [
			{
				type: "image",
				label: "Photo of Tag ID",
				key: "idTagAssetImage",
				// required: 'true',
				scanTag: "assetTag",
				scanType: "qr",
			},
			{
				type: "text",
				label: "Asset Tag ID",
				key: "assetTag",
				// required: 'true',
			},
		],
		flag: "allowTagId",
	},

	{
		title: "Asset Tag ID",
		questions: [
			{
				type: "image",
				label: "Asset Tag ID Image",
				key: "idTagAssetImage",
				tiedTo: "assetTagNotAvailable",
				// hidden: 'true',
				// required: 'true',
				scanTag: "assetTag",
				scanType: "qr",
			},
			{
				type: "text",
				label: "Asset Tag ID",
				key: "assetTag",
				tiedTo: "assetTagNotAvailable",
				placeholder: "",
				// required: 'true',
				checkbox: {
					type: "checkbox",
					label: "Non-tagged",
					key: "assetTagNotAvailable",
					tiedTo: ["assetTag", "idTagAssetImage"],
				},
			},
		],
		flag: "allowOptionalTagId",
	},
	// optional warranty page based on flag
	{
		title: "Warranty Information",
		questions: [
			{
				type: "checkbox",
				label: "No Warranty?",
				key: "warrantyNotFound",
				tiedTo: [
					"warrantyPeriod",
					"warrantyStartDate",
					"warrantyExpirationDate",
					"warrantyProvider",
				],
			},
			{
				type: "number",
				label: "Warranty Period (Months)",
				key: "warrantyPeriod",
				tiedTo: "warrantyNotFound",
			},
			{
				type: "date",
				label: "Warranty Start Date",
				key: "warrantyStartDate",
				tiedTo: "warrantyNotFound",
			},
			{
				type: "date",
				label: "Warranty Expiration Date",
				key: "warrantyExpirationDate",
				tiedTo: "warrantyNotFound",
			},
			{
				type: "text",
				label: "Warranty Provider",
				key: "warrantyProvider",
				tiedTo: "warrantyNotFound",
			},
		],
		flag: "allowWarranty",
	},
	// page 8 - asset condition
	{
		title: "Asset Status",
		questions: [
			{
				type: "rating",
				label: "Asset Condition",
				options: [
					"Broken - Must Be Replaced ASAP",
					"Poor - Needs to Be Replaced Soon",
					"Average - Halfway Through Its Life",
					"Good - Not New But in Fine Condition",
					"Excellent - This Asset is Brand New",
				],
				key: "assetCondition",
				required: "true",
			},
			{
				type: "textarea",
				label: "Enter this asset's status comments:",
				key: "assetGeneralNotes",
			},
		],
		flag: "AMDAssetStatus",
	},

	{
		title: "Asset Condition",
		questions: [
			{
				type: "rating",
				label: "Asset Condition",
				options: [
					"Broken - Must Be Replaced ASAP",
					"Poor - Needs to Be Replaced Soon",
					"Average - Halfway Through Its Life",
					"Good - Not New But in Fine Condition",
					"Excellent - This Asset is Brand New",
				],
				key: "assetCondition",
				required: "true",
			},
			{
				type: "textarea",
				label: "Explain this asset's condition:",
				key: "assetGeneralNotes",
			},
		],
		flag: "allowAssetCondition",
	},

	// Further sections...
];

// export default {};

export default newInputs;
