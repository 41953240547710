import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { getAssetTypes, getLocationAssets } from "@services/asset-service";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Table } from "reactstrap";
import { Button } from "reactstrap";

const customStyles = {
	width: "auto",
	height: "auto",
};

const buttonStyles = {
	height: "auto",
	width: "80px",
};

const Viewer360Modal = ({
	isOpen,
	closeModal,
	onDataSubmit,
	viewer,
	props,
}) => {
	const [currentAssets, setCurrentAssets] = useState([]);
	const [confirmDelete, setConfirmDelete] = useState(null);
	const markerManager = viewer?.getPlugin(MarkersPlugin);
	const getAssets = async () => {
		const assets = [];
		const docSnap = await getLocationAssets(
			props.organization,
			props.locationId,
		);

		const promises = [];
		docSnap.forEach((d) => {
			promises.push(
				// eslint-disable-next-line no-async-promise-executor
				new Promise(async (resolve) => {
					const assetDoc = await getAssetTypes(
						props.organization,
						d.data().assetType,
					);
					if (assetDoc.size > 0) {
						assetDoc.forEach((asset) => {
							const obj = { ...d.data() };
							obj.subType = asset.data().subType;
							obj.id = d.id;
							assets.push(obj);
						});
					} else {
						const obj = d.data();
						obj.id = d.id;
						assets.push(obj);
					}
					resolve();
				}),
			);
		});

		await Promise.all(promises);
		console.log(assets);
		setCurrentAssets(assets);
	};
	const handleButtonClick = (cmd, asset) => {
		setConfirmDelete(null);
		onDataSubmit(cmd, asset);
		closeModal();
	};
	const create_Buttons = (asset) => {
		//Checking if the asset from firebase has a match in the existing markers
		if (markerManager.markers[asset.id] !== undefined) {
			return (
				<div>
					{confirmDelete !== asset ? (
						<Button
							color="danger"
							style={buttonStyles}
							onClick={() => {
								setConfirmDelete(asset);
							}}
						>
							Delete
						</Button>
					) : (
						<Button
							color="danger"
							style={buttonStyles}
							onClick={() => handleButtonClick("delete", asset)}
						>
							Confirm Delete
						</Button>
					)}
				</div>
			);
		}
		return (
			<div>
				<Button
					color="success"
					style={buttonStyles}
					onClick={() => handleButtonClick("add", asset)}
				>
					Add
				</Button>
			</div>
		);
	};

	useEffect(() => {
		console.log(`Assets loaded: ${currentAssets}`);
	}, [currentAssets]);

	useEffect(() => {
		if (currentAssets.length === 0) {
			getAssets();
		}
	}, []);
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			contentLabel="Add Marker Modal"
			style={customStyles}
		>
			<ModalHeader>Marker Manager</ModalHeader>
			<ModalBody>
				<p>Which asset would you like to mark in the image</p>
				{currentAssets.length === 0 ? (
					<Loader />
				) : (
					<div style={{ overflowX: "scroll", fontSize: "12px" }}>
						<Table size="sm" striped bordered style={{ overflowX: "scroll" }}>
							<thead>
								<tr>
									<th>Asset Type</th>
									<th>Make</th>
									<th>Model</th>
									<th>Location</th>
									<th>Controls</th>
								</tr>
							</thead>
							<tbody>
								{currentAssets.map((asset) => (
									<tr key={asset.id}>
										<th scope="row">{asset.assetType}</th>
										<th>{asset.make}</th>
										<td>{asset.model}</td>
										<td>{asset.other.assetLocation}</td>
										<td>{create_Buttons(asset)}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<Button style={buttonStyles} onClick={getAssets}>
					Refresh
				</Button>
				<Button style={buttonStyles} onClick={closeModal}>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { Viewer360Modal };
