export const warrantyFieldsSwarovski = [
	"warrantyExpirationDate",
	"warrantyPeriod",
	"warrantyProvider",
	"warrantyStartDate",
];

export const notAvailableWarrantySwarovski = {
	warrantyExpirationDate: "warrantyNotFound",
	warrantyPeriod: "warrantyNotFound",
	warrantyProvider: "warrantyNotFound",
	warrantyStartDate: "warrantyNotFound",
};
