import { useState } from "react";
import { useEffect } from "react";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import {
	Badge,
	Card,
	CardBody,
	CardTitle,
	Col,
	Popover,
	PopoverBody,
	PopoverHeader,
	Row,
} from "reactstrap";

const StatsCard = (props) => {
	const [isAnimated, setIsAnimated] = useState(false);
	const {
		name,
		data,
		color,
		icon,
		lineBg,
		id,
		trend,
		isCurrentMonth,
		prevAvg,
		costTrendLoading,
		absorbedTrendLoading,
	} = props.stats;
	const [modalTimer, setModalTimer] = useState(null);
	const [showPopover, setShowPopover] = useState(false);

	const startModalTimer = () => {
		const timer = setTimeout(() => {
			setShowPopover(!showPopover);
		}, 600);
		setModalTimer(timer);
	};
	const clearModalTimer = () => {
		setShowPopover(false);
		clearTimeout(modalTimer);
		setModalTimer(null);
	};

	const toggle = () => {
		setShowPopover(!showPopover);
	};

	useEffect(() => {
		setIsAnimated(true);
	}, []);

	return (
		<div>
			<Card
				className={`${
					isAnimated ? "show" : "hidden"
				} stats-card my-3 me-0 card-bg ${lineBg}`}
				onMouseEnter={() => startModalTimer()}
				onMouseLeave={() => clearModalTimer()}
				id={id}
			>
				<CardBody className="px-4 small-card-body">
					<div className="small-card-badge-wrapper">
						{(((id === "svisits" || id === "activeloc") && !costTrendLoading) ||
							((id === "avglifespan" || id === "alerts") &&
								!absorbedTrendLoading)) &&
							(trend > 0 ? (
								<BiTrendingUp
									style={{
										color: color,
									}}
									className="small-card-arrow-up fs-3 mx-3"
								/>
							) : (
								<BiTrendingDown
									style={{
										color: color,
									}}
									className="small-card-arrow-down fs-3 mx-3"
								/>
							))}
						<Badge
							pill
							color={color}
							style={{
								float: "right",
								"background-color": color,
							}}
							className="stats-card-badge"
						>
							{(((id === "svisits" || id === "activeloc") &&
								!costTrendLoading) ||
								((id === "avglifespan" || id === "alerts") &&
									!absorbedTrendLoading)) &&
								`${trend?.toLocaleString()}%`}
						</Badge>
					</div>
					<Row className="h-100">
						<Col className="col-12">
							<div className="d-flex align-items-center justify-content-between h-100">
								<div className="d-flex flex-column justify-content-center h-100 w-50">
									<span
										style={{
											color: color,
										}}
										className="text-nowrap fs-2"
									>
										{data}
									</span>
									<CardTitle tag="p" className="fs-6 text-color">
										<p className="m-0 small-card-title text-color text-nowrap">
											{name}
										</p>
									</CardTitle>
								</div>
								<div className="d-flex align-items-center justify-content-end w-50 mt-3">
									<i
										style={{
											color: color,
										}}
										className={`bi-${icon} card-icon text-color`}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
			{id ? (
				<Popover flip target={id} toggle={toggle} isOpen={showPopover}>
					<PopoverHeader>{name}</PopoverHeader>
					<PopoverBody>
						{id === "svisits"
							? `There ${
									isCurrentMonth ? "have been" : "were"
								} ${data?.toLocaleString()} total visits${
									isCurrentMonth ? " so far" : ""
								} this month, which is ${Math.abs(trend).toLocaleString()}% ${
									trend > 0 ? "more" : "less"
								} than the average (${prevAvg} visits) of the past three months${
									isCurrentMonth ? " up to this date" : ""
								}.`
							: id === "avglifespan"
								? `There ${
										isCurrentMonth ? "has been" : "was"
									} a total of ${data} absorbed ${
										isCurrentMonth ? "so far" : ""
									} this month, which is ${Math.abs(trend).toLocaleString()}% ${
										trend > 0 ? "more" : "less"
									} than the average ($${prevAvg?.toLocaleString()}) of the past three months${
										isCurrentMonth ? " up to this date" : ""
									}.`
								: id === "activeloc"
									? `There ${
											isCurrentMonth ? "has been" : "was"
										} a total of ${data} prevented ${
											isCurrentMonth ? "so far" : ""
										} this month, which is ${Math.abs(
											trend,
										).toLocaleString()}% ${
											trend > 0 ? "more" : "less"
										} than the average ($${prevAvg?.toLocaleString()}) of the past three months${
											isCurrentMonth ? " up to this date" : ""
										}.`
									: id === "alerts"
										? `There ${
												isCurrentMonth ? "have been" : "were"
											} ${data} tickets absorbed${
												isCurrentMonth ? " so far" : ""
											} this month, which is ${Math.abs(
												trend,
											).toLocaleString()}% ${
												trend > 0 ? "more" : "less"
											} than the average (${prevAvg} tickets) of the past three months${
												isCurrentMonth ? " up to this date" : ""
											}.`
										: ""}
					</PopoverBody>
				</Popover>
			) : null}
		</div>
	);
};

export { StatsCard };
