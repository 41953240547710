import styles from "@components/layouts/SignedIn/views/SingleLocation/Viewer360Tab/Viewer360Tab.scss?inline";
import { Viewer } from "@photo-sphere-viewer/core";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import "@photo-sphere-viewer/core/index.css";
import "@photo-sphere-viewer/markers-plugin/index.css";
import BluePin from "@/assets/img/pin-blue.png";
import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { Viewer360Modal } from "@/components/layouts/SignedIn/views/SingleLocation/Viewer360Modal/Viewer360Modal";
import { auth } from "@assets/services/auth-service";

let viewerInit = false;
let viewer;
const MAXPITCH = 0.1;
const MINPITCH = -0.1;
const MAXFOV = 100;
const Viewer360Tab = (props) => {
	const viewerRef = useRef(null);
	const [rowData, setRowData] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [placingMarker, setPlacingMarker] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	//This will retrieve all the data
	useEffect(() => {
		if (!rowData) {
			getRowData().then((data) => {
				// Checking if the data returned is valid
				if (data) {
					setRowData(data.data);
				} else {
					console.log("Failed to get Data...");
					setRowData(false);
				}
			});
		}
	}, []);
	useEffect(() => {
		// After we get valid data, init the viewer
		if (rowData && !viewerInit && viewerRef) {
			viewerInit = true;
			viewer = new Viewer({
				container: viewerRef.current,
				panorama: rowData[0].bucket_loc,
				defaultZoomLvl: 0,
				maxFov: MAXFOV,
				plugins: [[MarkersPlugin, { markers: rowData[0].markers }]],
				navbar: [
					"zoomOut",
					"zoomRange",
					"zoomIn",
					"moveLeft",
					"moveRight",
					"moveUp",
					"moveDown",
					"markersList",
				],
				requestHeaders: {
					Accept: "image/*",
				},
			});

			// This event viewer will lock the camera between maxpitch and minpitch constants
			viewer.addEventListener("position-updated", ({ position }) => {
				// Updating max/min pitch with increased FOV
				const fov = viewer?.state.vFov;
				const new_min_pitch = MINPITCH * (MAXFOV / fov);
				const new_max_pitch = MAXPITCH * (MAXFOV / fov);

				if (position.pitch > new_max_pitch) {
					viewer.rotate({
						pitch: new_max_pitch,
						yaw: position.yaw,
					});
				} else if (position.pitch < new_min_pitch) {
					viewer.rotate({
						pitch: new_min_pitch,
						yaw: position.yaw,
					});
				}
			});
			setSelectedRow({ name: rowData[0].name });
			console.log("Viewer initialized: ", viewer.config);
		}
	}, [rowData]);

	useEffect(() => {
		console.log(`Error Value Updated: ${errorMessage}`);
	}, [errorMessage]);

	useEffect(() => {
		if (placingMarker !== null) {
			viewer?.addEventListener("click", handleViewerClick);
		}
		return () => {
			viewer?.removeEventListener("click", handleViewerClick);
		};
	}, [placingMarker]);
	/**
	 * Creates a string representation of a html panel for a marker's content panel
	 *
	 * @param {Object} asset - A asset of the location
	 * @returns {string} - A string representation of html to be displayed in a marker's content panel
	 */
	const generateMarkerContent = (asset) => {
		const url = `${window.location.origin}/locations/${props.locationId}/assets/${placingMarker.id}`;
		return `
			<div>
				<p>Asset Type: ${asset.assetType}</p>
				<p>Manufacturer: ${asset.make || asset.manufacturer}</p>
				<p>Model: ${asset.model}</p>
				<p>Serial No: ${asset.serial}</p>
				<a href="${url}" target="_blank"><button>Go to Asset</button></a>
				<br><br>
				${
					asset.other?.equipmentAssetImage
						? `<img src="${asset.other.equipmentAssetImage}" alt="Asset Image" class='content-image' style="width: 50%; height: 50%;">`
						: ""
				}
			</div>`;
	};
	/**
	 * Handles the click event for the Photo Sphere Viewer element
	 *
	 * @param {Object} data - Event data from a Photo Sphere Viewer click event
	 */
	const handleViewerClick = ({ data }) => {
		console.log(placingMarker.id);
		console.log(
			`${data.rightclick ? "right " : ""}clicked at yaw: ${
				data.yaw
			} pitch: ${data.pitch}`,
		);
		const markersPlugin = viewer.getPlugin(MarkersPlugin);
		const exists = markersPlugin.markers[placingMarker.id] !== undefined;
		const tooltipText = `${
			placingMarker.make || placingMarker.manufacturer
		} - ${placingMarker.model}`;
		const markerValue = {
			id: placingMarker.id,
			position: { yaw: data.yaw, pitch: data.pitch },
			size: { height: 32, width: 32 },
			zoomLvl: 100,
			content: generateMarkerContent(placingMarker),
			tooltip: tooltipText,
			image: BluePin,
		};
		if (exists) {
			markersPlugin.updateMarker(markerValue);
		} else {
			markersPlugin.addMarker(markerValue);
		}
		setPlacingMarker(null);
		selectedRow.markers = Object.values(markersPlugin.markers).map(
			(entry) => entry.config,
		);
		submitMarkers();
	};
	/**
	 * Uploads the Photo Sphere viewer markers to the backend
	 */
	const submitMarkers = async () => {
		try {
			const markersPlugin = viewer?.getPlugin(MarkersPlugin);
			const configs = Object.values(markersPlugin.markers).map(
				(entry) => entry.config,
			);
			const payload = {
				photo_name: selectedRow.name,
				org: props.organization,
				location_id: props.locationId,
				marker_data: configs,
			};
			const response = await fetch(
				`${import.meta.env.VITE_ALFRED_SERVICE_URL}/SetMarkers/`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
					method: "POST",
					body: JSON.stringify(payload),
				},
			);

			if (response.ok) {
				console.log("Marker Upload Success");
			} else {
				console.error("Error while uploading markers:", response.statusText);
				setErrorMessage(
					"Error submitting markers to server, please try again later.",
				);
			}
		} catch (error) {
			console.error("Error uploading markers:", error);
			setErrorMessage(
				"Error submitting markers to server, please try again later.",
			);
		}
	};
	/**
	 * Removes a marker from the viewer and then submits the markers to be updated in firebase
	 *
	 * @param {string} markerId - the id of the marker to remove
	 */
	const removeMarker = (markerId) => {
		const markersPlugin = viewer?.getPlugin(MarkersPlugin);
		markersPlugin.removeMarker(markerId);
		selectedRow.markers = Object.values(markersPlugin.markers).map(
			(entry) => entry.config,
		);
		submitMarkers();
	};
	/**
	 * Sets setPlacingMarker to the current asset we want to place
	 *
	 * @param {Object} asset - The asset we want to place on the viewer
	 */
	const addMarker = (asset) => {
		setPlacingMarker(asset);
	};
	/**
	 * Opens the marker manager modal
	 */
	const openModal = () => {
		setIsModalOpen(true);
	};
	/**
	 * Closes the marker manager modal
	 */
	const closeModal = () => {
		setIsModalOpen(false);
	};
	/**
	 * Handles data sent from the modal
	 *
	 * @param {String} cmd - The command to run
	 * @param {String} asset - The asset we will process
	 */
	const handleModalData = (cmd, asset) => {
		console.log(`Modal button clicked: ${cmd} | ${asset.id}`);
		switch (cmd) {
			case "delete":
				removeMarker(asset.id);
				break;
			case "add":
				addMarker(asset);
				break;
		}
	};
	/**
	 * Fetches facility vision data from backend
	 */
	const getRowData = async () => {
		// This method will get the row data
		try {
			setIsLoading(true);
			const response = await fetch(
				`${import.meta.env.VITE_ALFRED_SERVICE_URL}/get_images_by_loc?location_id=${props.locationId}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			);
			const results = await response.json();
			console.log("Successfully got 360 images from backend", results);
			return results;
		} catch (error) {
			console.error("Failed to get images from backend: ", error);
			setErrorMessage(
				"Failed to get photos from our backend, please try again later.",
			);
			return null;
		} finally {
			setIsLoading(false);
		}
	};
	/**
	 * Sets the viewer to a new photo
	 *
	 * @param {Object} row - The photo / data to place in the viewer
	 */
	const updateViewerPhoto = (row) => {
		const markersPlugin = viewer.getPlugin(MarkersPlugin);
		viewer.setPanorama(row.bucket_loc).then((r) => {
			console.log("Viewer photo updated: ", r);
			markersPlugin.setMarkers(row.markers);
		});
	};
	/**
	 * Handles the 'View Photo' button and swaps the image in the viewer
	 *
	 * @param {Object} row - The photo / data to place in the viewer
	 */
	const handleRowClick = (row) => {
		setSelectedRow(row);
		updateViewerPhoto(row);
		console.log("Row selected: ", row.name);
	};
	/**
	 * A helper method to generate an error banner
	 */
	const error_banner = () => {
		return (
			<div className="errorBanner">
				<span className="errorMessage">
					<i className="errorIcon">⚠️</i>
					{errorMessage}
				</span>
				<button onClick={() => setErrorMessage(null)} className="closeButton">
					✖
				</button>
			</div>
		);
	};
	/**
	 * A helper method to generate the buttons for the rows of photos to display
	 */
	const control_buttons = (row) => {
		if (selectedRow?.name !== row.name) {
			return (
				<div>
					<Button
						style={{
							width: "auto",
							margin: "10px",
							backgroundColor: "#250245",
						}}
						onClick={() => handleRowClick(row)}
					>
						View Photo
					</Button>
				</div>
			);
		}
		return (
			<div>
				<Button
					style={{
						width: "auto",
						margin: "10px",
						backgroundColor: "#250245",
					}}
					onClick={() => openModal()}
				>
					Manage Markers
				</Button>
			</div>
		);
	};

	return (
		<Card className={`${styles.container} mt-4`}>
			<CardBody>
				{errorMessage !== null && error_banner()}
				{placingMarker && (
					<div>
						<h3>
							Please click on the image where you would like to place this
							asset.
						</h3>
					</div>
				)}
				{isLoading && <Loader />}
				{rowData && (
					<div>
						<div
							id="viewer"
							ref={viewerRef}
							style={{ width: "100%", height: "60vh" }}
						></div>
						<hr />
						<Table striped bordered hover>
							<thead>
								<tr className="viewer-table-header">
									<th>Photo Name</th>
									<th>Date Uploaded</th>
									<th>Controls</th>
								</tr>
							</thead>
							<tbody>
								{rowData ? (
									rowData.map((row, index) => (
										<tr
											className="viewer-table-row"
											key={index}
											style={
												selectedRow?.name === row.name
													? {
															backgroundColor: "lightgray",
														}
													: {}
											}
										>
											<td>{row.name}</td>
											<td>{row.date_taken}</td>
											<td>{control_buttons(row)}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="2">Loading...</td>
									</tr>
								)}
							</tbody>
						</Table>
						<Viewer360Modal
							isOpen={isModalOpen}
							closeModal={closeModal}
							onDataSubmit={handleModalData}
							viewer={viewer}
							props={props}
						/>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export { Viewer360Tab };
