import { routeMap } from "@/layouts/SignedIn/integrations/IntegrationLayout";
// import SVG from 'react-inlinesvg';
import { getIntegrationById } from "@/services/integration-service";
import logo from "@assets/img/FacilityAI_Logo.png";
import { useLogoutMutation } from "@hooks/authQueries";
import { useUserStore } from "@stores/userStore";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineBank, AiOutlineForm } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoLocation } from "react-icons/go";
import { IoMenu, IoPerson } from "react-icons/io5";
import { PiCoinsThin } from "react-icons/pi";
import { RiAdminLine } from "react-icons/ri";
import { RiWifiOffLine } from "react-icons/ri";
import { TbCheckupList } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import { Alerts } from "./children/Alerts";

const Navbar = ({
	toggleChatbot,
	setToggleChatbot,
	isSidebarOpen,
	setIsSidebarOpen,
	canViewPred,
}: any) => {
	const isMobile = window.innerWidth < 768;

	const [isOffline, setIsOffline] = useState(false);
	const [_integrationsInfo, setIntegrationsInfo] = useState([]);
	const { userOrganization } = useUserStore((state: any) => ({
		userOrganization: state.userOrganization,
		setUserOrganization: state.setUserOrganization,
	}));
	const logout = useLogoutMutation();
	const [sidemenuOpen, setSidemenuOpen] = useState(false);

	useEffect(() => {
		window.onoffline = () => {
			setIsOffline(true);
		};
		window.ononline = () => {
			setIsOffline(false);
		};
		return () => {
			window.onoffline = null;
			window.ononline = null;
		};
	}, []);

	const firebaseConfig = JSON.parse(
		import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
	);
	const isStaging = firebaseConfig.projectId.includes("staging");

	useEffect(() => {
		const fetchIntegrations = async () => {
			if (userOrganization?.config?.integration_settings) {
				const integrationData: any = await Promise.all(
					Object.keys(userOrganization?.config?.integration_settings).map(
						async (id) => {
							const integration =
								userOrganization?.config?.integration_settings[id];
							if (integration?.is_enabled) {
								const integrationInfo = await getIntegrationById(id);
								const obj = {
									key: id,
									route: routeMap?.[id],
									integrationInfo,
								};
								return obj;
							}
							return null;
						},
					),
				);
				setIntegrationsInfo(integrationData.filter(Boolean));
			}
		};

		fetchIntegrations();
	}, [userOrganization?.config?.integration_settings]);

	const toggleMenu = () => setSidemenuOpen(!sidemenuOpen);
	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

	return (
		<>
			<div
				className={`topbar navbar-wrapper d-flex w-100 justify-content-between align-items-baseline ${
					!isMobile ? "mb-3" : ""
				}`}
			>
				<div className="navbar-left position-relative d-flex flex-column align-items-end justify-content-md-start">
					<div className="toggle-button" onClick={toggleSidebar}>
						<GiHamburgerMenu />
					</div>
					<div>
						<NavLink to="/" className="logo-anchor">
							<img className="title p-0" src={logo} alt="logo" />
						</NavLink>
					</div>
				</div>
				<div className="navbar-right d-flex justify-content-center align-items-center justify-content-md-start">
					<div className="d-none d-md-block">
						<Alerts />
					</div>
					<button title="Nav Menu" onClick={toggleMenu} className="nav-menu">
						<span className="navbar-mini-icon">
							<span className="no-attributes">
								<IoMenu />
							</span>
						</span>
					</button>
					<div className="d-none d-md-block">
						<ChatbotButton
							toggleChatbot={toggleChatbot}
							setToggleChatbot={setToggleChatbot}
						/>
					</div>
				</div>
			</div>
			<Collapse isOpen={sidemenuOpen} className="nav-menu">
				<div className="nav-menu">
					<ol className="no-padding">
						<li className="navbar-normal" onClick={toggleMenu}>
							<NavLink
								to="/"
								className={(isActive) =>
									isActive.isActive ? "active" : "inactive"
								}
							>
								<span className="navbar-mini-icon">
									<AiOutlineBank />
								</span>
								<span>Dashboard</span>
							</NavLink>
						</li>
						<li className="navbar-normal" onClick={toggleMenu}>
							<NavLink
								to="/profile"
								className={(isActive) =>
									isActive.isActive ? "active" : "inactive"
								}
							>
								<span className="navbar-mini-icon">
									<IoPerson />
								</span>
								<span>My Profile</span>
							</NavLink>
						</li>
						<li className="navbar-normal" onClick={toggleMenu}>
							<NavLink
								to="/locations"
								className={(isActive) =>
									isActive.isActive ? "active" : "inactive"
								}
							>
								<span className="navbar-mini-icon">
									<GoLocation />
								</span>
								<span>Locations</span>
							</NavLink>
						</li>
						<li className="navbar-normal" onClick={toggleMenu}>
							<NavLink
								to="/forms"
								className={(isActive) =>
									isActive.isActive ? "active" : "inactive"
								}
							>
								<span className="navbar-mini-icon">
									<AiOutlineForm />
								</span>
								<span>Data Capture</span>
							</NavLink>
						</li>
						<li className="navbar-normal" onClick={toggleMenu}>
							<NavLink
								to="/checkups"
								className={(isActive) =>
									isActive.isActive ? "active" : "inactive"
								}
							>
								<span className="navbar-mini-icon">
									<TbCheckupList />
								</span>
								<span>Check Ups</span>
							</NavLink>
						</li>

						{canViewPred ? (
							<li className="navbar-normal" onClick={toggleMenu}>
								<NavLink
									to="/predictive-spend"
									className={(isActive) =>
										isActive.isActive ? "active" : "inactive"
									}
								>
									<span className="navbar-mini-icon">
										<PiCoinsThin />
									</span>
									<span>Predictive Spend</span>
								</NavLink>
							</li>
						) : null}
						<li className="navbar-normal" onClick={toggleMenu}>
							<NavLink
								to="/admin"
								className={(isActive) =>
									isActive.isActive ? "active" : "inactive"
								}
							>
								<span className="navbar-mini-icon">
									<RiAdminLine />
								</span>
								<span>Administration</span>
							</NavLink>
						</li>

						{/* Integration Links */}
						{/* {integrationsInfo?.map(
							({ key, route, integrationInfo }) => (
								<li
									key={key}
									className='navbar-normal'
									onClick={toggleMenu}
								>
									<NavLink
										to={`/integration/${route}`}
										className={(isActive) =>
											isActive.isActive
												? 'active'
												: 'inactive'
										}
									>
										<span className='navbar-mini-icon'>
											{integrationInfo?.uiConfig
												?.iconSrc ? (
												<SVG
													style={{
														width: '1.5rem',
														height: '1.5rem',
													}}
													src={
														integrationInfo
															?.uiConfig?.iconSrc
													}
													className='integration-icon'
												/>
											) : (
												<AiOutlineBank />
											)}
										</span>
										<span>
											{integrationInfo?.name || key}
										</span>
									</NavLink>
								</li>
							)
						)} */}

						<li className="navbar-normal">
							<NavLink
								to="/"
								className="inactive"
								onClick={logout.mutate as any}
							>
								<span className="sidebar-normal">Log Out</span>
							</NavLink>
						</li>
					</ol>
				</div>
			</Collapse>
			<div
				hidden={!isStaging}
				style={{
					width: "100%",
					background: "red",
					color: "white",
					textAlign: "center",
				}}
				className="mb-2"
			>
				STAGING
			</div>
			<div className="w-100 mb-2 d-flex align-items-center justify-content-center">
				<div
					className="text-center text-white p-2 rounded-rounded px-4"
					hidden={!isOffline}
					style={{ background: "red", borderRadius: "20px" }}
				>
					<RiWifiOffLine />
					<span className="mx-1">Connection Lost!</span>
				</div>
			</div>
		</>
	);
};

const ChatbotButton = ({ toggleChatbot, setToggleChatbot }: any) => {
	const location = useLocation();

	// Not displayed on pages with a chatbot card
	if (
		location.pathname === "/" ||
		location.pathname.includes("/assets/") ||
		location.state?.tab === "Overview"
	) {
		return null;
	}

	return (
		<>
			<Button
				type="primary"
				shape="round"
				className="p-3 rounded-xl"
				onClick={() => setToggleChatbot(!toggleChatbot)}
			>
				Ask FacilityAI
			</Button>
		</>
	);
};

export { Navbar };
