import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Input, Table } from "reactstrap";

const CostSavingsCard = (props) => {
	const { monthOptions, costData, activeMonth, onMonthChange } = props;
	const [isAnimated, setIsAnimated] = useState(false);

	useEffect(() => {
		setIsAnimated(true);
	}, []);

	return (
		<Card
			className={`${
				isAnimated ? "slide-down" : "slide-down-hidden"
			} cost-savings-card`}
		>
			<CardBody className="main-card-body p-4">
				<CardTitle className="cost-savings-title mb-4">
					<Input
						onChange={(e) => onMonthChange(e.target.value)}
						className="cost-savings-select"
						type="select"
					>
						{monthOptions.map((option) => (
							<option selected={activeMonth === option} key={option}>
								{option}
							</option>
						))}
					</Input>
				</CardTitle>
				<Col className="col-12 mb-4">
					<Table className="cost-savings-table">
						<thead>
							<th>Category</th>
							<th className="text-center">Cost Savings</th>
							<th className="text-center">Total Prevented</th>
						</thead>
						<tbody>
							<tr>
								<td className="fw-bold text-darkgrey">Ceiling</td>
								<td className="text-center">
									${costData.ceiling.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.ceiling.count}</td>
							</tr>
							<tr>
								<td className="fw-bold text-darkgrey">Door</td>
								<td className="text-center">
									${costData.door.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.door.count}</td>
							</tr>
							<tr>
								<td className="fw-bold text-darkgrey">Floor</td>
								<td className="text-center">
									${costData.flooring.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.flooring.count}</td>
							</tr>
							<tr>
								<td className="fw-bold text-darkgrey">Lighting</td>
								<td className="text-center">
									${costData.lighting.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.lighting.count}</td>
							</tr>
							<tr>
								<td className="fw-bold text-darkgrey">Millwork</td>
								<td className="text-center">
									${costData.millwork.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.millwork.count}</td>
							</tr>
							<tr>
								<td className="fw-bold text-darkgrey">Plumbing</td>
								<td className="text-center">
									${costData.plumbing.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.plumbing.count}</td>
							</tr>
							<tr>
								<td className="fw-bold text-darkgrey">Wall Paint</td>
								<td className="text-center">
									${costData.wallPaint.savings.toLocaleString()}
								</td>
								<td className="text-center">{costData.wallPaint.count}</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</CardBody>
		</Card>
	);
};

export { CostSavingsCard };
