import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
	pdf,
} from "@react-pdf/renderer";
import { isValidUrl } from "@utils/validation";

export const generateCushmanPDF = async (
	inputs,
	formName,
	formLogo,
	dateOfVisit,
	address,
	summary,
	reviewed,
) => {
	const styles = StyleSheet.create({
		doc: {
			fontFamily: "Helvetica",
			flexDirection: "column",
			padding: "24px",
			fontSize: "12px",
		},
		title: {
			fontSize: "28px",
			padding: "0 6px 6px 6px",
			fontFamily: "Helvetica-Bold",
		},
		question: {
			fontFamily: "Helvetica-Bold",
			marginBottom: "2px",
		},
		questionImage: {
			fontFamily: "Helvetica-Bold",
			marginBottom: "18px",
		},
		answer: {
			border: "1px solid #ced4da",
			padding: "6px",
			borderRadius: "4px",
		},
		answerImageOrientationOne: {
			width: "100%",
			marginTop: "-15px",
		},
		answerImageOrientationSix: {
			transform: "rotate(90deg)",
			width: "100%",
			marginLeft: "-33px",
			marginTop: "18px",
		},
		answerImageOrientationThree: {
			transform: "rotate(180deg)",
			width: "100%",
			marginTop: "-15px",
		},
		answerImageOrientationEight: {
			transform: "rotate(270deg)",
			width: "100%",
			marginLeft: "-33px",
			marginTop: "18px",
		},
		questionAnswer: {
			display: "flex",
			flexDirection: "column",
			padding: "6px",
		},
		questionAnswerImage: {
			display: "flex",
			flexDirection: "column",
			width: "273px",
			padding: "6px",
			overflow: "hidden",
			marginBottom: "6px",
		},
		questionAnswerColText: {
			display: "flex",
			flexDirection: "column",
			width: "273px",
			padding: "6px",
		},
		questionAnswerColImage: {
			display: "flex",
			flexDirection: "column",
			width: "273px",
			padding: "6px",
			overflow: "hidden",
			marginBottom: "6px",
		},
		questionAnswerColParent: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		pageHeader: {
			fontSize: "20px",
			padding: "6px",
		},
		sectionHeader: {
			fontFamily: "Helvetica-Bold",
			border: "1px solid #ced4da",
			padding: "6px",
			margin: "6px",
			borderRadius: "4px",
			backgroundColor: "#f3f3f3",
		},
		logoImage: {
			width: "173px",
		},
	});

	const returnPageHeaders = (page, index) => {
		return (
			<Text wrap={false} key={`page-${index}`} style={styles.pageHeader}>
				{page.title}
			</Text>
		);
	};

	const returnSectionHeaders = (i, section, j) => {
		return (
			<Text
				wrap={false}
				key={`page-${i}-section-${j}`}
				style={styles.sectionHeader}
			>
				{section.title}
			</Text>
		);
	};

	const returnQuestionTop = (i, j, question, k) => {
		return (
			<View
				wrap={false}
				key={`page-${i}-section-${j}-question-${k}-top`}
				style={styles.questionAnswerColParent}
			>
				{/* Description */}
				<View key="description" style={styles.questionAnswerColText}>
					<Text style={styles.question}>{question.label}</Text>
					<Text style={styles.answer}>
						{question.description ? question.description : ""}
					</Text>
				</View>
				{/* Action Taken */}
				<View key="action-taken" style={styles.questionAnswerColText}>
					<Text style={styles.question}>Action Taken</Text>
					<Text style={styles.answer}>
						{question.actionTaken ? question.actionTaken : ""}
					</Text>
				</View>
			</View>
		);
	};

	const returnQuestionBottom = (i, j, question, k) => {
		const imgs = [
			{
				url: "imageBefore",
				exif: "imageBeforeExif",
				label: "Asset Before",
			},
			{ url: "imageAfter", exif: "imageAfterExif", label: "Asset After" },
		];
		return (
			<View
				wrap={false}
				key={`page-${i}-section-${j}-question-${k}-bottom`}
				style={styles.questionAnswerColParent}
			>
				{/* Images Before & After */}
				{imgs.map((item) => {
					const isImage = isValidUrl(question[item.url]);
					if (isImage) {
						let style;
						const rotVal = question[item.exif]?.Orientation;
						if (rotVal === 1 || !rotVal) {
							style = styles.answerImageOrientationOne;
						} else if (rotVal === 3) {
							style = styles.answerImageOrientationThree;
						} else if (rotVal === 6) {
							style = styles.answerImageOrientationSix;
						} else if (rotVal === 8) {
							style = styles.answerImageOrientationEight;
						}
						return (
							<View key={item.key} style={styles.questionAnswerColImage}>
								<Text style={styles.questionImage}>{item.label}</Text>
								<View style={styles.imageContainer}>
									<Image cache={true} style={style} src={question[item.url]} />
								</View>
							</View>
						);
					}
					return (
						<View key={item.key} style={styles.questionAnswerColText}>
							<Text style={styles.question}>{item.label}</Text>
							<Text style={styles.answer}>
								{question.actionTaken ? question.actionTaken : "No Image"}
							</Text>
						</View>
					);
				})}
			</View>
		);
	};

	const returnSubmission = () => {
		const lineItems = [];
		inputs.forEach((page, i) => {
			// Page Header
			if (page.title && page.sections) {
				lineItems.push(returnPageHeaders(page, i));
				// Section Header
				page.sections.forEach((section, j) => {
					lineItems.push(returnSectionHeaders(i, section, j));
					// Question
					section.questions.forEach((question, k) => {
						lineItems.push(
							returnQuestionTop(i, j, question, k),
							returnQuestionBottom(i, j, question, k),
						);
					});
				});
			}
		});
		return lineItems;
	};

	const document = (
		<Document>
			<Page size="A4" style={styles.doc}>
				<View>
					{/* Logo */}
					<View key="logo" style={styles.questionAnswer}>
						<View>
							<Image style={styles.logoImage} src={formLogo} />
						</View>
					</View>
					{/* Form Name */}
					<Text key="form name" style={styles.title}>
						{formName}
					</Text>
					{/* Approved? */}
					<View key="approved" style={styles.questionAnswer}>
						<Text style={styles.question}>Report Reviewed and Approved?</Text>
						<Text style={styles.answer}>{String(reviewed)}</Text>
					</View>
					{/* Date of Visit */}
					<View key="dateOfVisit" style={styles.questionAnswer}>
						<Text style={styles.question}>Date of Visit</Text>
						<Text style={styles.answer}>{dateOfVisit}</Text>
					</View>
					{/* Address */}
					<View key="address" style={styles.questionAnswer}>
						<Text style={styles.question}>Address</Text>
						<Text style={styles.answer}>{address}</Text>
					</View>
					{/* Summary */}
					<View key="summary" style={styles.questionAnswer}>
						<Text style={styles.question}>Summary</Text>
						<Text style={styles.answer}>{summary}</Text>
					</View>
					{returnSubmission()}
				</View>
			</Page>
		</Document>
	);

	const blob = await pdf(document).toBlob();

	return blob;
};
