import { continueToLocationOptions } from "./continueToLocationOptions";

export const handleMultipleForms = async (
	form,
	formsSnap,
	setFormId,
	setFormName,
	setState,
	latLonRef,
	setRetryLocation,
	organization,
	setManufacturers,
	setAssetTypes,
	setShouldAutoSave,
	setOrgSpecificInputs,
	setAdditionalLocationInputs,
	setFormLogo,
	setFormFlags,
	setUserLocation,
	setInputData,
	question,
	saveDataCaptureState,
	setCurrentState,
) => {
	const formId = formsSnap.docs.find((doc) => doc.data().formName === form).id;
	setFormId(formId);
	continueToLocationOptions(
		latLonRef,
		setRetryLocation,
		organization,
		setManufacturers,
		setAssetTypes,
		setShouldAutoSave,
		setOrgSpecificInputs,
		setAdditionalLocationInputs,
		setState,
		setFormFlags,
		formId,
		setFormName,
		setFormLogo,
		setUserLocation,
		setInputData,
		question,
		saveDataCaptureState,
		setCurrentState,
		true,
		form,
	);
};
