import { DeleteOutlined } from "@ant-design/icons";
import { Button, type ButtonProps } from "antd";

interface DeleteButtonProps extends ButtonProps {
	state: any;
}

const DeleteButton = (props: DeleteButtonProps) => {
	return (
		<Button
			onClick={props.onClick}
			type="text"
			danger={props.danger || true}
			icon={<DeleteOutlined style={{ fontSize: "20px" }} />}
			disabled={
				props.state?.checkedItems?.length === 0 || props.state?.viewingDeleted
			}
			{...props}
		/>
	);
};

export { DeleteButton };
