import { refrigerantOptions } from "@/utils/refrigerantOptions";
import { parseDateToUTCString } from "@/utils/transforms";
import {
	getColorBasedOnConfidence,
	modelExistsInDb,
} from "@services/ocr-service";

// Helper function to process an OCR field
const processOcrField = (
	fieldName: string,
	ocrField: { text: any; confidence: any },
	newState: { [x: string]: any },
	ocrConfidenceAndColorObj: { [x: string]: { color: string; confidence: any } },
	condition = true,
) => {
	if (ocrField && condition) {
		const { text, confidence } = ocrField;
		newState[fieldName] = text;
		ocrConfidenceAndColorObj[fieldName] = {
			color: getColorBasedOnConfidence(confidence),
			confidence: confidence,
		};
	}
};

// Main function
export const populateInputs = async (params: {
	response: any;
	fromChatbot: any;
	organization: any;
	responseData: any;
	setResponseData: any;
	setChosenAssetType: any;
	setCurrentPage: any;
	setOcrConfidenceAndColor: any;
}) => {
	// Destructure parameters for clarity
	const {
		response,
		fromChatbot,
		organization,
		responseData,
		setResponseData,
		setChosenAssetType,
		setCurrentPage,
		setOcrConfidenceAndColor,
	} = params;

	const ocrData = response?.data?.ocr || {};
	const ocrConfidenceAndColorObj = {};

	// Check if the model exists in the database
	const assetTypeData =
		(await modelExistsInDb(organization, ocrData?.model_number?.text)) || false;

	const ocrManufacturer = {
		make: ocrData?.make?.text,
		existsInDB: !!ocrData?.make_id,
	};

	const populateNewState = (newState: { assetType: any }) => {
		// Serial Number
		processOcrField(
			"serialNumber",
			ocrData.serial_number,
			newState,
			ocrConfidenceAndColorObj,
		);

		// Model Number
		processOcrField(
			"modelNumber",
			ocrData.model_number,
			newState,
			ocrConfidenceAndColorObj,
		);

		// Manufacturer
		if (ocrManufacturer.make) {
			processOcrField(
				"manufacturer",
				ocrData.make,
				newState,
				ocrConfidenceAndColorObj,
			);
		}

		// Refrigerant Type
		const refrigerantTypeCondition =
			organization !== "DIGI" &&
			organization !== "raisingcanes.com" &&
			refrigerantOptions.includes(ocrData.refrigerant_type?.text);

		processOcrField(
			"refrigerant",
			ocrData.refrigerant_type,
			newState,
			ocrConfidenceAndColorObj,
			refrigerantTypeCondition,
		);

		// Refrigerant Quantity
		const refrigerantQuantityCondition =
			organization !== "DIGI" && organization !== "raisingcanes.com";

		processOcrField(
			"refrigerantCharge",
			ocrData.refrigerant_quantity,
			newState,
			ocrConfidenceAndColorObj,
			refrigerantQuantityCondition,
		);

		// Voltage and Amps
		const powerSupplyCondition =
			organization !== "DIGI" &&
			organization !== "Arc Burger" &&
			organization === "cvs.com";

		processOcrField(
			"voltage",
			ocrData.power_supply_voltage,
			newState,
			ocrConfidenceAndColorObj,
			powerSupplyCondition,
		);

		processOcrField(
			"amps",
			ocrData.power_supply_amps,
			newState,
			ocrConfidenceAndColorObj,
			powerSupplyCondition,
		);

		// Refrigerant Quantity Unit
		const refrigerantUnitCondition = organization !== "DIGI";

		processOcrField(
			"refrigerantChargeUnitMeasurement",
			ocrData.refrigerant_quantity_unit,
			newState,
			ocrConfidenceAndColorObj,
			refrigerantUnitCondition,
		);

		// Manufacturer Date
		if (ocrData.manufacturer_date) {
			const dateText = ocrData.manufacturer_date.text;
			const date = new Date(parseDateToUTCString(dateText) || "");
			if (!Number.isNaN(date.getTime())) {
				processOcrField(
					"manufacturerDate",
					ocrData.manufacturer_date,
					newState,
					ocrConfidenceAndColorObj,
				);
			}
		}

		// Asset Type Data
		if (assetTypeData && organization !== "DIGI") {
			newState.assetType = assetTypeData;
			setChosenAssetType(assetTypeData);
		}
	};

	// Update the response data state
	if (fromChatbot) {
		const newResponseData = { ...responseData };
		populateNewState(newResponseData);
		setResponseData(newResponseData);
	} else {
		setResponseData((prevState: any) => {
			const newState = { ...prevState };
			populateNewState(newState);
			return newState;
		});
	}

	// Update other states
	setCurrentPage((prevPage: number) => prevPage + 1);
	setOcrConfidenceAndColor(ocrConfidenceAndColorObj);
};
