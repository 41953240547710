import { updateAsset } from "@services/asset-service";
import { useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const AssetDecommission = ({ assetId, assetData, setDecommissioned, org }) => {
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	const handleDecommission = async () => {
		try {
			const today = new Date();
			const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
			updateAsset(org, assetId, {
				...assetData,
				decommission_date: date,
				decommissioned: true,
			});

			setDecommissioned(true);
			// close the modal after successful decommission
			toggle();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			<IoMdTrash onClick={toggle} className="decommission-btn">
				Decommission
			</IoMdTrash>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Decommission Asset</ModalHeader>
				<ModalBody>Are you sure you want to decommission this asset?</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
					<Button className="confirm-decommission" onClick={handleDecommission}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default AssetDecommission;
