import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import {
	fetchAssets,
	initializeAssetTypesAndLocations,
} from "@/services/predictive-spend-service";
import { useUserStore } from "@stores";
import { useEffect, useReducer } from "react";
import { Col, Row } from "reactstrap";
import { PredSpendChart } from "./PredSpendChart";

const initialState = {
	isLoading: true,
	allLocations: [],
	assetTypes: [],
	checkedLocations: [],
	checkedStates: [],
	checkedAssetTypes: [],
	existingAssetTypes: [],
	chartLoading: false,
	chartAssets: [],
	originalAssets: [],
	portfolioChecked: true,
	healthScoreFilterType: null,
	healthScoreFilterValue: null,
	years: 10,
	budget: Array(10).fill(null),
	budgetType: { value: "static", label: "Static Budget" },
	numAssetTypes: 0,
	startDate: null,
	endDate: null,
};

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_LOCATIONS":
			return { ...state, allLocations: action.payload };
		case "SET_ASSET_TYPES":
			return { ...state, assetTypes: action.payload };
		case "SET_CHECKED_LOCATIONS":
			return { ...state, checkedLocations: action.payload };
		case "SET_CHECKED_STATES":
			return { ...state, checkedStates: action.payload };
		case "SET_CHECKED_ASSET_TYPES":
			return { ...state, checkedAssetTypes: action.payload };
		case "SET_EXISTING_ASSET_TYPES":
			return { ...state, existingAssetTypes: action.payload };
		case "SET_CHART_LOADING":
			return { ...state, chartLoading: action.payload };
		case "SET_CHART_ASSETS":
			return { ...state, chartAssets: action.payload };
		case "SET_ORIGINAL_ASSETS":
			return { ...state, originalAssets: action.payload };
		case "SET_PORTFOLIO_CHECKED":
			return { ...state, portfolioChecked: action.payload };
		case "SET_HEALTH_SCORE_FILTER_TYPE":
			return { ...state, healthScoreFilterType: action.payload };
		case "SET_HEALTH_SCORE_FILTER_VALUE":
			return { ...state, healthScoreFilterValue: action.payload };
		case "SET_YEARS":
			return {
				...state,
				years: action.payload,
				budget: Array(action.payload).fill(null),
			};
		case "SET_BUDGET":
			return { ...state, budget: action.payload };
		case "SET_BUDGET_TYPE":
			return { ...state, budgetType: action.payload };
		case "SET_NUM_ASSET_TYPES":
			return { ...state, numAssetTypes: action.payload };
		case "RESET":
			return initialState;
		case "SET_START_DATE":
			return { ...state, startDate: action.payload };
		case "SET_END_DATE":
			return { ...state, endDate: action.payload };
		default:
			return state;
	}
};

const PredictiveSpendNew = () => {
	const { user } = useUserStore();
	const [state, dispatch] = useReducer(reducer, initialState);

	// 1. initialize asset types and locations
	useEffect(() => {
		if (user) {
			initializeAssetTypesAndLocations(dispatch);
		}
	}, [user]); // Dependency on user to re-run when user changes

	// 2. then, fetch assets
	useEffect(() => {
		const isValidDateString = (dateStr) => {
			return typeof dateStr === "string" && !isNaN(Date.parse(dateStr));
		};

		if (
			state.assetTypes.length > 0 &&
			(state.checkedLocations.length > 0 ||
				state.checkedStates.length > 0 ||
				state.checkedAssetTypes.length > 0 ||
				state.portfolioChecked ||
				(isValidDateString(state.startDate) &&
					isValidDateString(state.endDate)))
		) {
			if (state.healthScoreFilterType && !state.healthScoreFilterValue) return;
			fetchAssets(state, dispatch);
		}
	}, [
		state.assetTypes,
		state.checkedLocations,
		state.checkedStates,
		state.checkedAssetTypes,
		state.portfolioChecked,
		state.healthScoreFilterType,
		state.healthScoreFilterValue,
		state.years,
		state.startDate,
		state.endDate,
	]);
	return state.isLoading ? (
		<Loader />
	) : (
		<div className="predictive-spend-tab">
			<Row>
				<Col className="col-12 mx-auto">
					<PredSpendChart state={state} dispatch={dispatch} />
				</Col>
			</Row>
		</div>
	);
};

export { PredictiveSpendNew };
