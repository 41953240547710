import { useGetSelfAllowedOrganizations } from "@/hooks/organizationQueries";
import { AvatarUpload } from "@SignedIn/Avatar/AvatarUpload";
import { Loader } from "@SignedIn/Loader/Loader";
import { Toast } from "@SignedIn/Toast/Toast";
import { EmailModal } from "@SignedIn/views/Profile/EmailModal";
import { NameInput } from "@SignedIn/views/Profile/NameInput";
import { PasswordModal } from "@SignedIn/views/Profile/PasswordModal";
import { auth } from "@assets/services/auth-service";
import { useSelfUpdateUser } from "@hooks/userQueries";
import { useUserStore } from "@stores";
import { updateProfile } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Row,
} from "reactstrap";

const Profile = () => {
	const { user, authInfo, setAuthInfo } = useUserStore();
	const updateUser = useSelfUpdateUser();
	const {
		userAllowedOrganizations,
		UserAllowedOrganizationsError,
		isLoadingUserAllowedOrganizations,
	} = useGetSelfAllowedOrganizations();
	const [nameInputValue, setNameInputValue] = useState(
		auth?.currentUser?.displayName,
	);
	const [nameInputButtonsShowing, setNameInputButtonsShowing] = useState(false);
	const nameInputRef = useRef(null);
	const [isToastShowing, setIsToastShowing] = useState(false);
	const [options, setOptions] = useState();

	const getOptions = async () => {
		const newOptions = [];
		for (const org of userAllowedOrganizations) {
			newOptions.push(
				<option
					selected={user.organization_id === org.id}
					value={org.external_firebase_id}
				>
					{org.full_name}
				</option>,
			);
		}
		newOptions.sort((a, b) =>
			a.props?.children
				?.toLowerCase()
				?.localeCompare(b.props?.children?.toLowerCase()),
		);
		setOptions(newOptions);
	};

	const updateUserOrganization = async (e) => {
		const name = e.target.value;

		//user_self_needs_update
		await updateUser.mutate({
			organization_firebase_id: name,
			savedFilters: null,
		});
		const newAuthInfo = await JSON.parse(JSON.stringify(authInfo));
		newAuthInfo.org = name;
		setAuthInfo(newAuthInfo);

		setIsToastShowing({
			showing: true,
			title: "Success",
			subtitle: "Organization has been changed",
			type: "success",
			position: "align-profile",
		});
		setTimeout(() => {
			setIsToastShowing({
				showing: false,
			});
		}, 2500);
	};

	useEffect(() => {
		getOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAllowedOrganizations]);

	const onInputChange = (e) => {
		e.preventDefault();
		setNameInputValue(e.target.value);
	};

	function useOutsideAlert(ref) {
		useEffect(() => {
			function handleClick(e) {
				if (ref.current && !ref.current.contains(e.target))
					setNameInputButtonsShowing(false);
				else if (ref.current.contains(e.target))
					setNameInputButtonsShowing(true);
			}

			document.addEventListener("click", handleClick);

			return () => {
				document.removeEventListener("click", handleClick);
			};
		}, [ref]);
	}
	useOutsideAlert(nameInputRef);

	const updateUsername = async () => {
		if (nameInputValue === auth.currentUser.displayName) return;
		try {
			setNameInputButtonsShowing(false);
			await updateUser.mutate({
				name: nameInputValue,
			});
			await updateProfile(auth.currentUser, {
				displayName: nameInputValue,
			});
			setIsToastShowing({
				showing: true,
				title: "Success",
				subtitle: "Username successfully updated.",
				position: "align-profile",
				type: "success",
			});
			setTimeout(() => {
				setIsToastShowing({
					showing: false,
				});
			}, 2500);
		} catch (_error) {
			setIsToastShowing({
				showing: true,
				title: "Error",
				subtitle: "There was an error updating your username.",
				position: "bottom",
				type: "danger",
			});
			setTimeout(() => {
				setIsToastShowing({
					showing: false,
				});
			}, 2500);
		}
	};

	return (
		<div>
			{isLoadingUserAllowedOrganizations ? (
				<Loader />
			) : (
				<div className="profile-page">
					<Row>
						<Col className="col-12 col-lg-4 mx-auto">
							<h5 className="fw-bold">Profile</h5>
							<h6 className="profile-category mb-4">
								Manage your personal information and photo here.
							</h6>

							<Card className="profile-card">
								<CardHeader className="p-0 d-flex flex-column align-items-center">
									<div className="profile-picture-wrapper my-3">
										<AvatarUpload />
									</div>
									<div className="mb-2 text-center">
										<h5 className="fw-bold m-0">
											{auth.currentUser.displayName}
										</h5>
									</div>
								</CardHeader>
								<CardBody className="px-4 py-3">
									<div className="d-flex flex-column mb-4 position-relative">
										<h6 className="profile-category text-nowrap">
											Preferred Name
										</h6>
										<NameInput
											value={nameInputValue}
											onChange={onInputChange}
											ref={nameInputRef}
										/>

										{nameInputButtonsShowing && (
											<div className="profile-input-btns-wrapper">
												<button
													onClick={updateUsername}
													className="profile-input-btn"
												>
													<BiCheck />
												</button>
												<button
													onClick={() => setNameInputButtonsShowing(false)}
													className="profile-input-btn"
												>
													<MdClose />
												</button>
											</div>
										)}
									</div>

									<div className="d-flex flex-column mb-4">
										<div className="d-flex align-items-center">
											<h6 className="profile-category m-0">Email</h6>{" "}
											<EmailModal
												setIsToastShowing={setIsToastShowing}
												userEmail={auth?.currentUser?.email}
											/>
										</div>
										<div className="d-flex flex-column flex-md-row my-2">
											<input disabled value={auth?.currentUser?.email} />
										</div>
									</div>

									<div className="d-flex flex-column mb-4">
										<div className="d-flex align-items-center mb-2">
											<span className="profile-category">Password</span>
											<PasswordModal setIsToastShowing={setIsToastShowing} />
										</div>
										<input value={"********"} type="password" disabled />
									</div>

									<div className="d-flex flex-column mb-4">
										<h6 className="profile-category">Organization</h6>
										<Form>
											<FormGroup>
												<Input
													type="select"
													disabled={!userAllowedOrganizations?.length}
													onChange={(e) => updateUserOrganization(e)}
												>
													{options}
												</Input>
											</FormGroup>
										</Form>
									</div>

									<div className="d-flex flex-column mb-4">
										<h6 className="profile-category">Role</h6>
										<input value={user.role.name} disabled />
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Toast
						isShowing={isToastShowing.showing}
						title={isToastShowing.title}
						subtitle={isToastShowing.subtitle}
						position={isToastShowing.position}
						type={isToastShowing.type}
					></Toast>
				</div>
			)}
		</div>
	);
};

export { Profile };
