import { useGetLocationAssets } from "@hooks/assetQueries";
import { getExtractedInfo } from "@services/asset-service";
import { getLocationMaintenanceReport } from "@services/location-service";
import { Card } from "antd";
import { useEffect, useState } from "react";
import {
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	CardBody,
	Col,
	UncontrolledAccordion,
} from "reactstrap";
import styles from "./MaintenanceTab.module.css";

const MaintenanceTab = ({ organization, locationId, isFocused }) => {
	const [plan, setPlan] = useState([]);
	const [info, setInfo] = useState([]);
	const { data: assets, loading: loadingAssets } = useGetLocationAssets(
		organization,
		locationId,
	);

	useEffect(() => {
		if (assets?.docs) {
			Promise.all(
				assets.docs.map((asset) =>
					getExtractedInfo(asset.data().model).then((data) => ({
						data,
						asset,
					})),
				),
			)
				.then((extractedInfoArray) => {
					const filteredInfo = extractedInfoArray.filter(
						(info) => info.data() !== undefined,
					);
					if (filteredInfo.length > 0) {
						setInfo([
							...filteredInfo.map((i) => ({
								data: i.data.data(),
								asset: i.asset.data(),
							})),
						]);
					}
				})
				.catch((error) => {
					console.error("Error fetching extracted info:", error);
				});
		}
	}, [assets, loadingAssets]);

	useEffect(() => {
		getLocationMaintenanceReport({
			organization,
			locationId,
		})
			.then((res) => {
				setPlan(res);
			})
			.catch(() => {
				setPlan([]);
			});
	}, [locationId, organization]);

	const noPlan = plan.length === 0 && info.length === 0;

	return (
		<Card
			className={`${styles.container}`}
			title={<span className="fw-bold">Maintenance Action Plan</span>}
		>
			<CardBody>
				{noPlan ? <EmptyState /> : <MaintenancePlan plan={plan} info={info} />}
			</CardBody>
		</Card>
	);
};

const MaintenancePlan = ({ plan, info }) => {
	const openSections = plan.length > 3 ? [] : [0, 1];

	return (
		<CardBody>
			<Col>
				{plan.length > 0 &&
					plan.map((section, index) => (
						<ReportSection
							key={index}
							index={index}
							section={section}
							openSections={openSections}
							info={false}
						/>
					))}
				{info.length > 0 &&
					info.map((section, index) => (
						<ReportSection
							key={index}
							index={index}
							section={section}
							info={true}
						/>
					))}
			</Col>
		</CardBody>
	);
};

const ReportSection = ({ section, index, info, openSections }) => {
	let assetTypes, make, models, assetIds, maintenanceItems;
	if (!info) {
		assetTypes = section.assetTypes;
		make = section.make;
		models = section.models;
		assetIds = section.assetIds;
		maintenanceItems = section.maintenanceItems;
	} else {
		assetTypes = [section.asset.assetType];
		make = section.asset.make;
		models = [section.asset.model];
		assetIds = [section.asset.other.assetTag];
		maintenanceItems = section.data.maintenance
			? section.data.maintenance
					.split(", ")
					.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
			: [];
	}
	const accordionId = String(index);
	const formattedTypes = assetTypes.join(", ");
	const formattedModels = models.join(", ");
	const formattedIds = assetIds.join(", ");

	return (
		<UncontrolledAccordion defaultOpen={openSections} stayOpen>
			<AccordionItem>
				<AccordionHeader
					className={styles.maintenanceItem}
					targetId={accordionId}
				>
					<div className="d-block d-sm-flex justify-content-center align-items-center">
						<h6 className={`my-auto ${styles.itemTitle}`}>{`Make: ${make}`}</h6>
						<h6 className={`my-auto ${styles.itemTitle}`}>
							{`Type: ${formattedTypes}`}
						</h6>
						<h6 className={`d-none d-md-block my-auto ${styles.itemTitle}`}>
							{`Model: ${formattedModels}`}
						</h6>
					</div>
				</AccordionHeader>
				<AccordionBody accordionId={accordionId}>
					<div className="d-flex flex-column pb-4">
						<div className="d-md-none">
							<div className="fw-bolder">Models</div>
							<div className="px-3">{formattedModels}</div>
						</div>
						<div className="">
							<div className="fw-bolder mb-1">Tag IDs</div>
							<div className="px-3">{formattedIds}</div>
						</div>
						<div>
							<div className="mt-2 fw-bolder">Maintenance Items</div>
							<ul>
								{maintenanceItems.map((item, index) => (
									<MaintenanceItem key={item} item={item} index={index} />
								))}
							</ul>
						</div>
					</div>
				</AccordionBody>
			</AccordionItem>
		</UncontrolledAccordion>
	);
};

const MaintenanceItem = ({ item, index }) => {
	return (
		<li className={`${index === 0 ? "pt-0" : ""} ${styles.item}`}>{item}</li>
	);
};

const EmptyState = () => {
	return (
		<div className="mx-auto my-auto">{"No maintenance items to display."}</div>
	);
};

export { MaintenanceTab };
