import { useGraph } from "@contexts/graphContext.js";
import { useUserStore } from "@stores";
import { screenshot } from "@utils/screenshot";
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import { useRef, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { BsFillCameraFill } from "react-icons/bs";
import {
	Badge,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	Row,
} from "reactstrap";

const DARK_PURPLE = "#250245";
const WHITE = "#ffffff";
const STYLES = [
	{ id: "maintenance", color: "#fbc658" },
	{ id: "replace", color: "#228B22" },
	{ id: "downtime", color: "#00ccff" },
	{ id: "downtime", color: "#5e79ff" },
];

const getStyle = (index) => STYLES[index < 5 ? index - 1 : index - 5];
const MediumCard = ({ graph, tension, setDisplayToast }) => {
	const cost = graph.cost ?? false;
	const themeMode = useUserStore((state) => state.themeMode);
	const defaultTickColor = themeMode === "dark" ? WHITE : DARK_PURPLE;
	const [tickColor, setTickColor] = useState(defaultTickColor);
	const [isHovered, setIsHovered] = useState(false);
	const cardRef = useRef(null);
	const cameraIconRef = useRef(null);
	const { shouldFlashId } = useGraph();

	const { data, labels, type, index, title, pillText } = graph;

	const { id, color } = getStyle(index);
	const chartData = {
		labels,
		datasets: [
			{
				data,
				borderColor: color,
				borderRadius: 10,
				backgroundColor: color,
				pointRadius: 0,
			},
		],
	};

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		BarElement,
		Title,
		Tooltip,
		Legend,
	);
	const lineOptions = {
		tension: tension ? 0.25 : 0,
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: "top",
			},
			tooltip: {
				enabled: true,
				mode: "index",
				intersect: false,
				callbacks: {
					label: cost
						? (context) => {
								const label = context.dataset.label
									? `${context.dataset.label}: `
									: "";
								const value = `$${context.parsed.y.toLocaleString()}`;
								return label + value;
							}
						: undefined,
				},
			},
		},
		elements: {
			point: {
				pointHitRadius: 20,
			},
		},
		scales: {
			y: {
				ticks: {
					color: "#9f9f9f",
					beginAtZero: false,
					maxTicksLimit: 5,
					//padding: 20
					callback: cost ? (value) => `$${value / 1000}k` : undefined,
				},
				grid: {
					drawBorder: false,
					display: false,
				},
			},
			x: {
				grid: {
					drawBorder: false,
					display: false,
				},
				ticks: {
					padding: 20,
					color: "#9f9f9f",
				},
			},
		},
	};
	const barOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltips: {
				tooltipFillColor: "rgba(0,0,0,0.5)",
				tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
				tooltipFontSize: 14,
				tooltipFontStyle: "normal",
				tooltipFontColor: "#fff",
				tooltipTitleFontFamily:
					"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
				tooltipTitleFontSize: 14,
				tooltipTitleFontStyle: "bold",
				tooltipTitleFontColor: "#fff",
				tooltipYPadding: 6,
				tooltipXPadding: 6,
				tooltipCaretSize: 8,
				tooltipCornerRadius: 6,
				tooltipXOffset: 10,
			},
		},
		scales: {
			y: {
				ticks: {
					color: tickColor,
					beginAtZero: true,
					maxTicksLimit: 5,
					padding: 20,
				},
				grid: {
					zeroLineColor: "transparent",
					display: false,
					drawBorder: false,
					color: "#9f9f9f",
				},
			},
			x: {
				grid: {
					display: false,
					drawBorder: false,
				},
				ticks: {
					padding: 20,
					color: tickColor,
					callback: function (value) {
						const label = this.getLabelForValue(value);
						return label.includes("\n") ? label.split("\n") : label;
					},
				},
			},
		},
	};

	return (
		<Card
			innerRef={cardRef}
			className={`${
				shouldFlashId === graph.id ? "flash-effect" : ""
			} medium-card my-3`}
			onMouseEnter={() => {
				setTickColor(WHITE);
				setIsHovered(true);
			}}
			onMouseLeave={() => {
				setTickColor(defaultTickColor);
				setIsHovered(false);
			}}
			id={id}
		>
			{isHovered && (
				<div ref={cameraIconRef}>
					<BsFillCameraFill
						style={{
							color: "white",
							position: "absolute",
							top: "10px",
							right: "10px",
							cursor: "pointer",
						}}
						onClick={() => {
							setDisplayToast({
								showing: true,
								title: "Success",
								message: "Graph copied to clipboard.",
								type: "success",
								position: "bottom",
							});
							screenshot(cardRef, cameraIconRef);
							setTimeout(() => {
								setDisplayToast({
									showing: false,
								});
							}, 2500);
						}}
					/>
				</div>
			)}
			<CardHeader className="bg-light pt-2 bg-transition">
				<Row>
					<Col className="col-2"></Col>
					<Col className="col-8 my-auto text-center">
						<CardTitle
							className="m-0 fs-4 text-color text-transition"
							style={{ color: color }}
							tag="h6"
						>
							{title}
						</CardTitle>
					</Col>
					<Col className="col-2">
						{pillText ? (
							<Badge
								pill
								color={color}
								style={{
									"background-color": color,
									float: "right",
								}}
							>
								{pillText}
							</Badge>
						) : (
							<></>
						)}
					</Col>
				</Row>
			</CardHeader>
			<CardBody className="py-0 bg-light bg-transition">
				{type === "line" ? (
					<Line
						options={lineOptions}
						data={chartData}
						height="100%"
						width="100%"
					/>
				) : (
					<Bar
						options={barOptions}
						data={chartData}
						height="100%"
						width="100%"
					/>
				)}
			</CardBody>
		</Card>
	);
};

export { MediumCard };
