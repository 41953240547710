export const getOptionsLength = (
	item,
	manufacturers,
	flags,
	equipmentAreas,
	assetTypes,
	responseData,
	options,
	assetTypeName,
	_organization,
	assetTypeToCategory,
) => {
	console.log("Item", item);
	console.log("Selected Asset Type", assetTypeName);
	if (
		item.collection === "manufacturers" ||
		item.collection === "smart-manufacturers"
	) {
		return Object.keys(manufacturers).length;
	}
	if (item.table === "equipment-area") {
		return equipmentAreas.length;
	}
	if (item.key === "assetSubArea" && responseData.assetLocation) {
		const selectedEquipmentArea = equipmentAreas.find(
			(area) => area.id === responseData.assetLocation?.id,
		);
		if (selectedEquipmentArea?.sub_areas) {
			return selectedEquipmentArea.sub_areas.length;
		}
		return 0;
	}
	if (item.collection === "asset-types") {
		if (flags.differentAssetTypesBasedOnLocation) {
			return Object.values(assetTypes).filter(
				(type) =>
					type.organization_equipment_area_id === responseData.assetLocation,
			).length;
		}
		return Object.keys(assetTypes).length;
	}
	if (item.collection === "asset-type-categories") {
		console.log("In asseet type category");

		const categories = assetTypeToCategory[assetTypeName] || [];
		return categories.length;
	}
	if (item.options) {
		return item.options.length || 0;
	}
	if (item.table) {
		if (Array.isArray(options[item.table])) {
			return options[item.table]?.length;
		}
		if (
			typeof options[item.table] === "object" &&
			options[item.table] !== null
		) {
			return Object.keys(options[item.table]).length;
		}
		return 0;
	}
	if (Object.keys(options).includes(item.collection)) {
		return options[item.collection]?.length;
	}
	if (flags && Object.keys(flags.collections).includes(item.collection)) {
		return flags.collections[item.collection]?.length;
	}
	return 0;
};
