// inputs for parking lot/roof assets, etc.
// no photos, only asset location and asset condition.
export const inputs = [
	// first page - location
	{
		title: "Location Details",
		questions: [
			{
				type: "select",
				label: "Store Location",
				key: "location",
				required: "true",
			},
		],
	},
	{
		title: "Asset Details",
		questions: [
			// asset type input
			{
				type: "image",
				label: "Photo of Equipment",
				hideLabel: false,
				key: "equipmentAssetImage",
				required: false,
			},
			{
				type: "select",
				label: "Asset Type",
				collection: "asset-types",
				key: "assetType",
				required: "true",
				id: "assetTypeInput",
			},
			{
				type: "select",
				label: "Asset Location",
				collection: "equipment-area",
				key: "assetLocation",
				required: "true",
			},
			// {
			// 	type: 'select',
			// 	label: 'Asset Condition',
			// 	options: ['Broken', 'Poor', 'Average', 'Good', 'Excellent'],
			// 	key: 'assetCondition',
			// 	required: 'true',
			// },
			{
				type: "rating",
				label: "Asset Condition",
				options: [
					"Broken - Must Be Replaced ASAP",
					"Poor - Needs to Be Replaced Soon",
					"Average - Halfway Through Its Life",
					"Good - Not New But in Fine Condition",
					"Excellent - This Asset is Brand New",
				],
				key: "assetCondition",
				required: "true",
			},
			{
				type: "textarea",
				label: "Explain this asset's condition:",
				key: "assetGeneralNotes",
			},
		],
	},
];
