import {
	useMutation,
	useQueries,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";

import {
	getDocFromPath,
	getManufacturers,
	getOrganizations,
	getSelfAllowedOrganizations,
	getSelfOrganization,
	getSelfOrganizationForms,
	getSelfOrganizationGraphs,
	getSelfOrganizationManufacturers,
	getSelfOrganizationReports,
	selfUpdateOrganization,
	updateWithRef,
} from "@services/organization-service";

import { useAdminStore } from "@/stores";
import { useLocationStore } from "@/stores";
import { useUserStore } from "@/stores";

export interface SelfOrganization {
	config: OrganizationConfig;
	external_firebase_id: string;
	full_name: string;
	health: Record<string, any>;
	id: number;
}

export interface OrganizationConfig {
	organization_id: number;
	show_iguide?: boolean;
	show_matterport?: boolean;
	auto_save_drafts?: boolean;
	is_location_name_required?: boolean;
	allow_tag_id?: boolean;
	service_channel_export_enabled?: boolean;
	export_options?: Record<string, any>;
	org_specific_inputs?: Record<string, any>;
	sc_export_options?: Record<string, any>;
	unique_fields?: Record<string, any>;
	integration_settings?: Record<string, any>;
	logo_url?: string | null;
}

/**
 * Returns a query object that fetches organizations when executed.
 *
 * @param {boolean} enabled - Whether or not the query should be enabled.
 * @return {object} The query object.
 */
export const useGetOrganizations = (enabled: boolean) =>
	useQuery({
		queryKey: ["organizations"],
		queryFn: () => getOrganizations(),
		enabled,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * Returns a list of useQueries hooks, each querying for a document corresponding to a search response.
 *
 * @param {object} data - An object containing search results.
 * @return {array} An array of useQueries hooks.
 */
export const useGetDocsFromSearchResponses = (data: { results: never[] }) => {
	const searchResponses = data?.results ?? [];
	const adminSubmissions = useAdminStore(
		(state: any) => state.activeTab === "Submissions",
	);
	const locationSubmissions = useLocationStore(
		(state: any) => state.activeTab === "Submissions",
	);
	const isSubmissionsTab = adminSubmissions || locationSubmissions;
	return useQueries({
		queries: searchResponses.map((response: { path: string }) => {
			return {
				queryKey: ["doc", response.path],
				queryFn: () => getDocFromPath(response.path),
				enabled: !!searchResponses && isSubmissionsTab,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			};
		}),
	});
};

/**
 * Retrieves documents from a given path.
 *
 * @param {Object} data - The result from useSearchAssets
 * @return {Object} - The queries object representing the docs of all of the searched assets
 */
export const useGetDocsFromPath = (
	data: { results: never[] },
	sort: any,
	isPredSpend: any,
) => {
	const searchAssets = data?.results ?? [];
	const adminAssets = useAdminStore(
		(state: any) => state.activeTab === "Assets",
	);
	const locationAssets = useLocationStore(
		(state: any) => state.activeTab === "Assets",
	);
	const isAssetsTab = adminAssets || locationAssets;
	return useQueries({
		queries: searchAssets.map((response: { path: string }) => {
			return {
				queryKey: ["doc", sort, response.path],
				queryFn: () => getDocFromPath(response.path),
				enabled: !!searchAssets && (isAssetsTab || isPredSpend),
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			};
		}),
	});
};

/**
 * A custom hook that returns a mutation function to update data with a given reference.
 *
 * @return {Function} The mutation function.
 */
export const useUpdateWithRef = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ ref, data }: any) => await updateWithRef(ref, data),
		onSuccess: async () => {
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("searchAssets" as any);
			queryClient.invalidateQueries("doc" as any);
		},
	});
};

export const useUpdateOrganization = () => {
	const queryClient = useQueryClient();
	const setUserOrganization = useUserStore.getState().setUserOrganization;

	return useMutation({
		mutationFn: selfUpdateOrganization,
		onSuccess: async (data) => {
			queryClient.invalidateQueries(["self_organization"]);
			queryClient.setQueryData(["self_organization"], data);
			setUserOrganization(data);
		},
	});
};

export const useGetManufacturers = (enabled: boolean) => {
	return useQuery({
		queryKey: ["manufacturers"],
		queryFn: () => getManufacturers(),
		enabled: enabled,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

export const useGetSelfOrganizationManufacturers = (
	enabled: boolean,
	organizationId: any,
) => {
	return useQuery({
		queryKey: ["manufacturers", organizationId],
		queryFn: () => getSelfOrganizationManufacturers(),
		enabled: enabled,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

/**
 * Fetches all the current user's organization forms
 * @returns {{userOrganization: Object, isLoading: boolean, error: unknown}}
 */
export const useGetSelfOrganizationForms = () => {
	const { userOrganizationForms, setUserOrganizationForms } = useUserStore(
		(state: {
			userOrganizationForms: any;
			setUserOrganizationForms: any;
		}) => ({
			userOrganizationForms: state.userOrganizationForms,
			setUserOrganizationForms: state.setUserOrganizationForms,
		}),
	);

	const { data, error, isLoading } = useQuery(
		["self_organization_forms"],
		getSelfOrganizationForms,
		{
			enabled: !userOrganizationForms,
			onSuccess: (data) => {
				setUserOrganizationForms(data);
			},
		},
	);

	return {
		userOrganizationForms: userOrganizationForms || data,
		error,
		isLoading,
	};
};

/**
 * Fetches all the current user's organization reports
 * @returns {{userOrganization: Object, isLoading: boolean, error: unknown}}
 */
export const useGetSelfOrganizationReports = () => {
	const { userOrganizationReports, setUserOrganizationReports } = useUserStore(
		(state: {
			userOrganizationReports: any;
			setUserOrganizationReports: any;
		}) => ({
			userOrganizationReports: state.userOrganizationReports,
			setUserOrganizationReports: state.setUserOrganizationReports,
		}),
	);

	const { data, error, isLoading } = useQuery(
		["self_organization_reports"],
		getSelfOrganizationReports,
		{
			enabled: !userOrganizationReports,
			onSuccess: (data) => {
				setUserOrganizationReports(data);
			},
		},
	);

	return {
		userOrganizationReports: userOrganizationReports || data,
		error,
		isLoading,
	};
};

/**
 * Fetches all the current user's organization graphs
 * @returns {{userOrganizationGraphs: Object, isLoading: boolean, error: unknown}}
 */
export const useGetSelfOrganizationGraphs = () => {
	const { userOrganizationGraphs, setUserOrganizationGraphs } = useUserStore(
		(state: {
			userOrganizationGraphs: any;
			setUserOrganizationGraphs: any;
		}) => ({
			userOrganizationGraphs: state.userOrganizationGraphs,
			setUserOrganizationGraphs: state.setUserOrganizationGraphs,
		}),
	);

	const { data, error, isLoading } = useQuery(
		["self_organization_graphs"],
		getSelfOrganizationGraphs,
		{
			enabled: !userOrganizationGraphs,
			onSuccess: (data) => {
				setUserOrganizationGraphs(data);
			},
		},
	);

	return {
		userOrganizationGraphs: userOrganizationGraphs || data,
		error,
		isLoading,
	};
};

// Fetches information on the user's current organization
// Only fetches if the state isn't set, otherwise just return the state
export const useGetSelfOrganization = (): {
	userOrganization: SelfOrganization;
	isLoading: boolean;
	error: unknown;
} => {
	const { userOrganization, setUserOrganization } = useUserStore(
		(state: {
			userOrganization: SelfOrganization;
			setUserOrganization: (data: SelfOrganization) => void;
		}) => ({
			userOrganization: state.userOrganization,
			setUserOrganization: state.setUserOrganization,
		}),
	);

	const { data, error, isLoading } = useQuery(
		["self_organization"],
		getSelfOrganization,
		{
			enabled: !userOrganization,
			onSuccess: (data: SelfOrganization) => {
				setUserOrganization(data);
			},
		},
	);

	return { userOrganization: userOrganization || data, error, isLoading } as {
		userOrganization: SelfOrganization;
		isLoading: boolean;
		error: unknown;
	};
};

/**
 * Fetches the user's allowed organizations
 * Only fetches if the state isn't set, otherwise just return the state
 * @returns {{userAllowedOrganizations: unknown, isLoading: boolean, error: unknown}}
 */
export const useGetSelfAllowedOrganizations = () => {
	const { userAllowedOrganizations, setUserAllowedOrganizations } =
		useUserStore(
			(state: {
				userAllowedOrganizations: any;
				setUserAllowedOrganizations: any;
			}) => ({
				userAllowedOrganizations: state.userAllowedOrganizations,
				setUserAllowedOrganizations: state.setUserAllowedOrganizations,
			}),
		);

	const { data, error, isLoading } = useQuery(
		["self_allowed_organizations"],
		getSelfAllowedOrganizations,
		{
			enabled:
				!userAllowedOrganizations ||
				Object.keys(userAllowedOrganizations).length === 0,
			onSuccess: (data) => {
				setUserAllowedOrganizations(data);
			},
		},
	);

	return {
		userAllowedOrganizations: userAllowedOrganizations || data,
		error,
		isLoading,
	};
};
