import { searchLocations } from "@/services/location-service";
import type { Dispatch } from "react";

export const fetchData = async (
	state: State,
	dispatch: Dispatch,
	organization: string,
) => {
	const { pageNum, pageSize, sortBy, sortOrder, searchVal } = state;
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		const responses = await searchLocations(
			searchVal,
			organization,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			{},
		);
		if (responses) {
			dispatch({
				type: "SET_DATA_SOURCE",
				payload: responses.results,
			});
			dispatch({ type: "SET_COUNT", payload: responses.count });
		} else {
			throw new Error("Failed to search for assets");
		}
	} catch (exception) {
		console.error(`Failed to search for assets: ${exception}`);
		throw new Error("Failed to search for assets");
	} finally {
		dispatch({ type: "SET_IS_LOADING", payload: false });
	}
};
