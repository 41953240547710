import { useChatbotStore, useFormStore, useUserStore } from "@stores";
import { preventSpecialChars } from "@utils/preventSpecialChars";
import { useParams } from "react-router-dom";
import { Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { CheckboxInput } from "./CheckboxInput";

const TempAlertTextInput = ({
	question,
	responseData,
	setInputData,
	fromChatbot,
}) => {
	const { org } = useParams();

	const authInfo = useUserStore((state) => state.authInfo);
	const organization = org || authInfo.org;

	const {
		tempAlertError: formTempAlertError,
		setTempAlertError: setFormFieldError,
	} = useFormStore();
	const {
		tempAlertError: chatbotTempAlertError,
		setTempAlertError: setChatbotTempAlertError,
	} = useChatbotStore();

	const tempAlertError = fromChatbot
		? chatbotTempAlertError
		: formTempAlertError;
	const setTempAlertError = fromChatbot
		? setChatbotTempAlertError
		: setFormFieldError;

	function debounce(func, timeout = 300) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	const validateAfterDelay = debounce(async (val) => {
		if (!val) {
			setTempAlertError(false);
			return;
		}

		let regex;
		let message;
		if (organization === "cvs.com") {
			// Pattern to check if it starts with "11666" and is 20 characters long
			regex = /^11666.{15}$/;
			message =
				'Please make sure that the temp alert ID starts with "11666" and is 20 characters long.';
		} else if (organization === "DIGI") {
			// Pattern to check if it starts with "D" and is 7 characters long OR
			// starts with "11666" and is 20 characters long
			regex = /^(D.{6}|11666.{15})$/;
			message = val.startsWith("D")
				? 'Please make sure that the temp alert ID starts with "D" and is 7 characters long.'
				: 'Please make sure that the temp alert ID starts with "11666" and is 20 characters long.';
		}

		if (regex && !regex.test(val)) {
			setTempAlertError({
				error: true,
				message: message,
			});
			return;
		}

		setTempAlertError(false);
	});

	const shouldDisable = () => {
		if (question.disabled === "true") {
			return true;
		}
		return responseData[question.tiedTo] === true;
	};

	return (
		<Row key={question.key}>
			<FormGroup>
				<div
					className={`d-flex align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<Label className={question.checkbox ? "mb-0" : "single-form-label"}>
						{question.label}
						{question.required && <span style={{ color: "red" }}> *</span>}
					</Label>

					{question.checkbox && (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={setInputData}
							notStandalone={true}
						/>
					)}
				</div>

				<Form>
					<FormGroup>
						<Input
							value={responseData[question.key]}
							invalid={tempAlertError ? tempAlertError.error : null}
							valid={responseData[question.key] ? !tempAlertError : false}
							type={question.type}
							onChange={(e) => {
								const val = e.target.value;
								validateAfterDelay(val);
								setInputData(question, val);
							}}
							onPaste={(e) =>
								preventSpecialChars(e.clipboardData.getData("text"), e, true)
							}
							onKeyPress={(e) => preventSpecialChars(e.key, e, true)}
							disabled={shouldDisable()}
							placeholder={question.placeholder}
							className="form-inputs"
						/>
						<FormFeedback invalid style={{ textAlign: "center" }}>
							{tempAlertError ? tempAlertError.message : ""}
						</FormFeedback>
					</FormGroup>
				</Form>
			</FormGroup>
		</Row>
	);
};

export { TempAlertTextInput };
