// Submission.tsx
import { QAReasonCard } from "@/components/Cards/dfp-submission-qa-card";
import { CopyableCellRenderer } from "@/components/Tables/Renderers/copyable-cell-renderer";
import { DFPTypography } from "@/components/Typography/dfp-typography";
import { useFormStore } from "@/stores/formStore";
import { useUserStore } from "@/stores/userStore";
import { parseDateToUTCString } from "@/utils/transforms";
import { LeftOutlined } from "@ant-design/icons";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { inputs as notRequiredInputs } from "@constants/formInputs/notRequiredInputs";
import initInputs from "@constants/formInputs/taggingFormInputs";
import { getSubmissionData } from "@services/submission-service";
import { deepCopy } from "@utils/deepCopy";
import { Button, Card, Descriptions, Image, theme } from "antd";
import { useEffect, useMemo, useReducer } from "react";
import { LuXCircle } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { EditLog } from "./EditLog";
import { SubmissionDetailControls } from "./SubmissionDetailControls";

const { Title } = DFPTypography;

interface SubmissionProps {
	submissionId: string;
	formId: string;
	toggleSubmission: () => void;
	isQA: boolean;
	submissionsPageNum: number;
}

export interface SubmissionDetailsState {
	isLoading: boolean;
	responseData: any;
	formName: string;
	formLogo: string;
	address: string;
	locationId: string;
	userLocation: any;
	chosenAssetType: any;
	showImageModal: boolean;
	selectedImage: string;
	pdfLoading: boolean;
	assetEdits: any[];
	canEdit: boolean;
	tooltipMessage: string;
	submissionId: string;
	formId: string;
	isQA: boolean;
	submissionsPageNum: number;
	airportCode?: string;
	path?: string;
	orgSpecificInputs?: any[];
}

type Action = { type: "SET_STATE"; payload: Partial<SubmissionDetailsState> };

function getDisplayValue(input: any) {
	if (typeof input === "string") {
		return <CopyableCellRenderer value={input} />;
	}
	if (typeof input === "object" && input !== null && "name" in input) {
		return <CopyableCellRenderer value={input.name} />;
	}
	return "N/A";
}

const initialState: SubmissionDetailsState = {
	isLoading: true,
	responseData: null,
	formName: "",
	formLogo: "",
	address: "",
	locationId: "",
	userLocation: undefined,
	chosenAssetType: null,
	showImageModal: false,
	selectedImage: "",
	pdfLoading: false,
	assetEdits: [],
	canEdit: false,
	tooltipMessage: "",
	submissionId: "",
	formId: "",
	isQA: false,
	submissionsPageNum: 1,
	airportCode: undefined,
	path: undefined,
	orgSpecificInputs: [],
};

function reducer(
	state: SubmissionDetailsState,
	action: Action,
): SubmissionDetailsState {
	switch (action.type) {
		case "SET_STATE":
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

const Submission: React.FC<SubmissionProps> = (props) => {
	const { submissionId, formId, toggleSubmission, isQA, submissionsPageNum } =
		props;
	const location = useLocation();
	const { setIsPhotoShowing, setSelectedItem, setSelectedPhotoUrl } =
		useFormStore();
	const { userOrganization } = useUserStore();
	const { token } = theme.useToken();

	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		submissionId,
		formId,
		isQA,
		submissionsPageNum,
		path: location.pathname,
	});

	function getAirportCode(
		location: ReturnType<typeof useLocation>,
		responseData: any,
	): string | undefined {
		let airportCode = (location.state as any)?.airportCode;

		if (!airportCode && responseData?.location?.name) {
			const locationName = responseData.location.name;
			if (locationName.includes("JFK Airport - Terminal 7")) {
				airportCode = "JFK-T7";
			} else if (locationName.includes("JFK Terminal 4")) {
				airportCode = "JFK-T4";
			} else if (locationName.includes("Warehouse Midwest")) {
				airportCode = "Warehouse Midwest";
			} else if (locationName.includes("Warehouse South")) {
				airportCode = "Warehouse South";
			} else {
				airportCode = locationName;
			}
		}

		return airportCode;
	}

	// Fetch submission data on mount
	useEffect(() => {
		if (!submissionId || !userOrganization) return;
		getSubmissionData(
			userOrganization.external_firebase_id,
			formId,
			submissionId,
			isQA,
			(data) =>
				dispatch({ type: "SET_STATE", payload: { responseData: data } }),
			(name) => dispatch({ type: "SET_STATE", payload: { formName: name } }),
			(logo) => dispatch({ type: "SET_STATE", payload: { formLogo: logo } }),
			(address) => dispatch({ type: "SET_STATE", payload: { address } }),
			(locationId) => dispatch({ type: "SET_STATE", payload: { locationId } }),
			(userLocation) =>
				dispatch({ type: "SET_STATE", payload: { userLocation } }),
			(chosenAssetType) =>
				dispatch({ type: "SET_STATE", payload: { chosenAssetType } }),
			(assetEdits) => dispatch({ type: "SET_STATE", payload: { assetEdits } }),
		).finally(() => {
			const airportCode = getAirportCode(location, state.responseData);
			dispatch({
				type: "SET_STATE",
				payload: { isLoading: false, airportCode },
			});
		});
	}, [submissionId, userOrganization, formId, location]);

	// Compute inputsRequired using useMemo
	const inputsRequired = useMemo(() => {
		return !state.chosenAssetType?.extra?.inputsNotRequired;
	}, [state.chosenAssetType]);

	// Compute assetConditionalInputs using useMemo
	const assetConditionalInputs = useMemo(() => {
		return state.chosenAssetType?.conditional_inputs || [];
	}, [state.chosenAssetType]);

	// Compute inputs using useMemo
	const inputs = useMemo(() => {
		let newInputs = [];

		if (!inputsRequired) {
			newInputs = deepCopy(notRequiredInputs);
		} else {
			newInputs = deepCopy(initInputs);
			if (
				assetConditionalInputs.length > 0 &&
				newInputs[4]?.title !== "Asset Details - Part 2"
			) {
				const newPage = {
					title: "Asset Details - Part 2",
					questions: [...assetConditionalInputs],
				};
				newInputs = [...newInputs.slice(0, 4), newPage, ...newInputs.slice(4)];
			}
			if (
				state.orgSpecificInputs?.length > 0 &&
				newInputs.at(-2)?.title !== "Additional Details"
			) {
				const newPage = {
					title: "Additional Details",
					questions: [...state.orgSpecificInputs],
				};
				newInputs = [
					...newInputs.slice(0, -1),
					newPage,
					...newInputs.slice(-1),
				];
			}
		}
		return newInputs;
	}, [inputsRequired, assetConditionalInputs, state.orgSpecificInputs]);

	// Generate the questions display
	const generateQuestions = () => {
		const questions = inputs.flatMap((page: any) => page.questions);
		const renderedKeys = new Set<string>();

		return (
			<Descriptions bordered column={1} labelStyle={{ fontWeight: "bold" }}>
				{questions.map((question: any) => {
					if (!state.responseData.raw_input[question.key]) return null;
					if (renderedKeys.has(question.key)) return null;
					renderedKeys.add(question.key);

					let value: React.ReactNode;
					if (question.key === "location") {
						value = state.address;
					} else if (question.type === "image") {
						value = (
							<Image
								src={state.responseData.raw_input[question.key]}
								alt=""
								onClick={() =>
									openImgModal(state.responseData.raw_input[question.key])
								}
								wrapperClassName="w-50 cursor-pointer"
								style={{ maxWidth: "300px", maxHeight: "300px" }}
								wrapperStyle={{ maxWidth: "300px", maxHeight: "300px" }}
								preview={{
									destroyOnClose: true,
									closeIcon: (
										<span
											className="p-2 bg-white"
											style={{ borderRadius: token.borderRadiusDefault }}
										>
											<LuXCircle size={"1.5rem"} color={token.colorPrimary} />
										</span>
									),
									maskStyle: { backgroundColor: token.maskBg },
									toolbarRender(originalNode, info) {
										return (
											<div
												style={{
													color: token.colorPrimary,
													backgroundColor: token.colorWhite,
													borderRadius: token.borderRadiusDefault,
												}}
											>
												{originalNode}
											</div>
										);
									},
								}}
							/>
						);
					} else if (question.type === "checkbox") {
						value = state.responseData.raw_input[question.key]
							? "true"
							: "false";
					} else {
						value = getDisplayValue(state.responseData.raw_input[question.key]);
						if (question.unitMeasurementDropdown) {
							value +=
								state.responseData.raw_input[
									question.unitMeasurementDropdown.key
								];
						}
					}

					return (
						<Descriptions.Item key={question.key} label={question.label}>
							{value}
						</Descriptions.Item>
					);
				})}
			</Descriptions>
		);
	};

	// Open image modal
	const openImgModal = (response: string) => {
		setSelectedPhotoUrl(response);
		setSelectedItem(response);
		setIsPhotoShowing(true);
	};

	// Format QA flags
	const formatQAFlags = (qaFlags: any[]) => {
		return <QAReasonCard qaFlags={[...qaFlags]} />;
	};

	return state.isLoading ? (
		<Loader />
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<div className="submission-header d-flex flex-row align-items-center justify-content-between h-100">
					<Button
						className="mb-4"
						type="link"
						onClick={toggleSubmission}
						icon={<LeftOutlined />}
					>
						Back to Submissions
					</Button>

					<SubmissionDetailControls
						state={{ ...state, inputs }}
						dispatch={dispatch}
						toggleSubmission={toggleSubmission}
					/>
				</div>
				<img src={state.formLogo} className="submission-logo mt-2" alt="logo" />
				<div
					className="d-flex flex-row align-items-center justify-content-between"
					style={{
						backgroundColor: "#ffffff",
						border: "none",
					}}
				>
					<Title level={3} className="submission-title">
						{state.formName}
					</Title>
				</div>
				{state.responseData.asset.is_deleted && (
					<div
						className="w-100 p-2 bg-red text-center text-white fw-bold my-2"
						style={{ backgroundColor: "red" }}
					>
						DELETED on{" "}
						{parseDateToUTCString(state.responseData.asset.is_deleted)}
					</div>
				)}
				{state.responseData?.is_qa_flagged &&
					formatQAFlags(state.responseData?.qa_flags)}
				<Descriptions
					className="mb-2"
					bordered
					column={1}
					labelStyle={{ fontWeight: "bold" }}
				>
					<Descriptions.Item label="Date of Visit">
						<CopyableCellRenderer
							value={
								parseDateToUTCString(state.responseData?.end_time) || "N/A"
							}
						/>
					</Descriptions.Item>
				</Descriptions>
				{generateQuestions()}
				{state.assetEdits.length > 0 && (
					<div className="mt-4">
						<EditLog editLog={state.assetEdits} />
					</div>
				)}
			</Card>
		</div>
	);
};

export { Submission };
