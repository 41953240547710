import { ConfirmDialog } from "@components/layouts/SignedIn/views/Locations/children/ConfirmDialog";
import { getAssetData, savePositions } from "@services/asset-map-service";
import { useChatbotStore, useFormStore } from "@stores";
import { Button, Card } from "antd";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { Group, Layer, Line, Rect, Stage, Text } from "react-konva";
// import { useNavigate } from 'react-router-dom';
import { Loader } from "../Loader/Loader";

const AssetMap = ({
	locationId,
	org,
	isFocused,
	chosenAssetType,
	tagId,
	areAssetsDraggable = true,
	isSingleLocationTab = true,
	showResetModal,
	toggleResetModal,
	assetMapRef,
	fromChatbot = false,
}) => {
	const [assets, setAssets] = useState([]);
	const [initialPosition, setInitialPosition] = useState(null);
	const containerRef = useRef(null);
	const [hoveredAsset, setHoveredAsset] = useState(null);
	const [showLabels, setShowLabels] = useState(false);
	const {
		assetMapNewAsset: formAssetMapNewAsset,
		setAssetMapNewAsset: setFormAssetMapNewAsset,
		setAssetMapDimensions: formSetAssetMapDimensions,
		isEditing,
		alertModal,
		setAlertModal,
	} = useFormStore();

	const {
		assetMapNewAsset: chatbotAssetMapNewAsset,
		setAssetMapNewAsset: setChatbotAssetMapNewAsset,
		setAssetMapDimensions: chatbotSetAssetMapDimensions,
	} = useChatbotStore();

	const assetMapNewAsset = fromChatbot
		? chatbotAssetMapNewAsset
		: formAssetMapNewAsset;
	const setAssetMapNewAsset = fromChatbot
		? setChatbotAssetMapNewAsset
		: setFormAssetMapNewAsset;
	const setAssetMapDimensionsForForm = fromChatbot
		? chatbotSetAssetMapDimensions
		: formSetAssetMapDimensions;

	// state variable to detect if user is in landscape or portrait mode

	const getOrientation = () => {
		return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
	};
	const [orientation, setOrientation] = useState(getOrientation());

	// const navigate = useNavigate();

	// state variable for canvas width and height
	const [canvasDimensions, setCanvasDimensions] = useState({
		width: 600,
		height: 600,
	});

	// state variable to check canvas is loaded
	const [isLoadingCanvas, setIsLoadingCanvas] = useState(true);

	// Initialize canvas dimensions
	useEffect(() => {
		if (
			containerRef.current?.offsetWidth &&
			containerRef.current.offsetHeight !== 0 &&
			isFocused &&
			locationId
		) {
			setCanvasDimensions(() => ({
				width: containerRef.current.offsetWidth,
				height: containerRef.current.offsetHeight,
			}));
			setIsLoadingCanvas(false);
		}
	}, [containerRef, isFocused, locationId]);

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};
	useEffect(() => {
		const handleResize = () => {
			const newOrientation = getOrientation();
			setIsLoadingCanvas(true);
			setOrientation(newOrientation);

			if (newOrientation === "portrait" && orientation === "landscape") {
				setAlertModal({
					show: true,
					title: (
						<span style={{ color: "red", alignItems: "center" }}>
							Warning!!
						</span>
					),
					body: "Ensure your device remains in landscape mode to accurately position the asset on the map!",
				});
				setTimeout(closeAlertModal, 2500);
			}
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				if (containerRef.current) {
					const newCanvasDimensions = {
						width: containerRef.current.offsetWidth,
						height: containerRef.current.offsetHeight,
					};
					if (assetMapNewAsset) {
						if (fromChatbot) {
							const newAssetMapNewAsset = {
								x:
									(assetMapNewAsset.x / canvasDimensions.width) *
									newCanvasDimensions.width,
								y:
									(assetMapNewAsset.y / canvasDimensions.height) *
									newCanvasDimensions.height,
							};
							setAssetMapNewAsset(newAssetMapNewAsset);
						} else {
							// get coordinates of assetMapNewAsset, convert to percentages then get new coordinates based on new canvas dimensions
							setAssetMapNewAsset((prevAsset) => ({
								x:
									(prevAsset.x / canvasDimensions.width) *
									newCanvasDimensions.width,
								y:
									(prevAsset.y / canvasDimensions.height) *
									newCanvasDimensions.height,
							}));
						}
					}
					setCanvasDimensions(() => ({
						width: newCanvasDimensions.width,
						height: newCanvasDimensions.height,
					}));
				}
				setIsLoadingCanvas(false);
			}, 500); // Debounce
			// Rest of the resize logic...
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [orientation]); // Add 'orientation' as a dependency

	// Rest of your component code...

	// Window resize listener with debounce
	let timeoutId;

	// useEffect(() => {
	// 	window.addEventListener('resize', handleResize);
	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 		clearTimeout(timeoutId);
	// 	};
	// }, [canvasDimensions]);

	const getPositionsFromDB = async () => {
		const assetData = await getAssetData(locationId);
		if (assetData.length === 0) {
			generateSquares();
		} else {
			let newPositions = assetData.map((asset) => ({
				...percentToPixels(asset),
			}));
			if (isEditing) {
				newPositions = newPositions.filter(
					(asset) => asset.asset_tag !== tagId,
				);
			}
			setAssets(newPositions);
		}
	};

	// fetch data once canvas is loaded and tab is focused
	useEffect(() => {
		if (
			!isLoadingCanvas &&
			isFocused &&
			canvasDimensions.width !== 0 &&
			canvasDimensions.height !== 0 &&
			locationId
		) {
			getPositionsFromDB();
		}
	}, [isLoadingCanvas, isFocused, canvasDimensions, locationId]);

	const { width: CANVAS_WIDTH, height: CANVAS_HEIGHT } = canvasDimensions;

	// generate squares based on number of assets and get their id and type
	async function generateSquares() {
		const assetData = await getAssetData(locationId);
		const data = assetData.map((asset) => ({
			id: JSON.stringify(asset[1].id),
			tag_id: asset[1].asset_tag || null,
			x: Math.random() * (CANVAS_WIDTH - 25),
			y: Math.random() * (CANVAS_HEIGHT - 25),
			isDragging: false,
			width: 30,
			height: 30,
			type: asset[1].organization_asset_type.name,
		}));
		data.push({
			id: "Front Counter",
			tag_id: null,
			x: 25,
			y: CANVAS_HEIGHT - 50,
			isDragging: false,
			width: 100,
			height: 30,
			type: "Front Counter",
		});
		data.push({
			id: "Prescription Counter",
			tag_id: null,
			x: CANVAS_WIDTH - 130,
			y: CANVAS_HEIGHT - 50,
			isDragging: false,
			width: 100,
			height: 30,
			type: "Prescription Counter",
		});
		setAssets(data);
		savePositions(data.map(pixelsToPercent), locationId, org);
	}

	const handleDragStart = (e) => {
		const id = e.target.children[e.target.children.length - 1].id();
		const initialX = e.target.x();
		const initialY = e.target.y();

		setInitialPosition({ id, initialX, initialY });

		setAssets(
			assets.map((asset) => {
				return {
					...asset,
					isDragging: asset.id === id,
				};
			}),
		);
	};

	// Prevent the user from dragging the rectangle outside of the canvas
	const handleDragMove = (e) => {
		const x = e.target.x();
		const y = e.target.y();

		if (x < 0 || y < 30 || x > CANVAS_WIDTH - 30 || y > CANVAS_HEIGHT - 25) {
			e.target.position({
				x: initialPosition.initialX,
				y: initialPosition.initialY,
			});
			setAssets((prevAssets) => {
				return prevAssets.map((asset) => {
					return {
						...asset,
						isDragging: false,
					};
				});
			});
		}
	};

	const handleDragEnd = (e) => {
		const id = e.target.children[e.target.children.length - 1].id();
		const newX = e.target.x();
		const newY = e.target.y();

		const newPositions = assets.map((asset) => {
			if (asset.id === id || asset.tag_id === id) {
				return {
					...asset,
					x: newX,
					y: newY,
					isDragging: false,
				};
			}
			return asset;
		});

		setAssets(newPositions);

		// Clear the initial position state
		setInitialPosition(null);
		// Save the new positions to the database
		savePositions(
			newPositions.map(pixelsToPercent), // Convert to percentages before saving
			locationId,
			org,
		);
	};

	// When saving to the DB, convert to percentages
	const pixelsToPercent = (asset) => {
		return {
			...asset,
			x: (asset.x / CANVAS_WIDTH) * 100,
			y: (asset.y / CANVAS_HEIGHT) * 100,
		};
	};

	// When loading from the DB, convert percentages back to pixel values
	const percentToPixels = (asset) => {
		return {
			id: asset[1].id,
			tag_id:
				asset[1].asset === null ? asset[1].type : asset[1].asset.asset_tag,
			isDragging: false,
			width: 30,
			height: 30,
			type:
				asset[1].asset === null
					? asset[1].type
					: asset[1].asset.organization_asset_type.name,
			x: (asset[1].x_position * CANVAS_WIDTH) / 100,
			y: (asset[1].y_position * CANVAS_HEIGHT) / 100,
		};
	};

	const placeSubmittingAsset = (e) => {
		// within single location we are not placing a new asset
		if (isSingleLocationTab || orientation === "portrait") {
			return;
		}
		const pos = e.target.getStage().getPointerPosition();
		if (
			pos.x < 0 ||
			pos.y < 30 ||
			pos.x > CANVAS_WIDTH - 25 ||
			pos.y > CANVAS_HEIGHT - 25
		) {
			alert("Please place the asset within the map.");
			return;
		}
		setAssetMapNewAsset({
			x: pos.x,
			y: pos.y,
		});
		setAssetMapDimensionsForForm({
			width: CANVAS_WIDTH,
			height: CANVAS_HEIGHT,
		});
	};

	const OrientationOverlay =
		orientation === "portrait" && !isSingleLocationTab ? (
			<div className="asset-map-overlay">
				Please turn your device to landscape mode.
			</div>
		) : null;

	const drawGrid = (width, height, gridSize = 20) => {
		const gridLines = [];

		// Vertical lines
		for (let i = 0; i < width; i += gridSize) {
			gridLines.push(
				<Line
					key={`v${i}`}
					points={[i, 0, i, height]}
					stroke="#ddd" // Color for the grid lines
					strokeWidth={1}
				/>,
			);
		}

		// Horizontal lines
		for (let i = 0; i < height; i += gridSize) {
			gridLines.push(
				<Line
					key={`h${i}`}
					points={[0, i, width, i]}
					stroke="#ddd" // Color for the grid lines
					strokeWidth={1}
				/>,
			);
		}

		return gridLines;
	};

	const handleHover = (e) => {
		const id =
			e.currentTarget.children[e.currentTarget.children.length - 1].id();
		if (hoveredAsset === id) {
			setHoveredAsset(null);
			return;
		}
		setHoveredAsset(id);
	};

	// Helper function to calculate X position for Text
	const calculateTextXPosition = (asset, canvasWidth, tempTextWidth) => {
		const threshold = canvasWidth - tempTextWidth;
		const difference = threshold - asset.x;
		return asset.x + tempTextWidth > canvasWidth ? difference : 0;
	};

	const labelWidthDict = {
		"RX Cooler - Under The Counter": new Konva.Text({
			text: "RX Cooler - Under The Counter",
			fontSize: 12,
		}).width(),
		"RX Freezer - Under The Counter": new Konva.Text({
			text: "RX Freezer - Under The Counter",
			fontSize: 12,
		}).width(),
		"RX Cooler - Full Size": new Konva.Text({
			text: "RX Cooler - Full Size",
			fontSize: 12,
		}).width(),
		"Prescription Counter": new Konva.Text({
			text: "Prescription Counter",
			fontSize: 12,
		}).width(),
		"Front Counter": new Konva.Text({
			text: "Front Counter",
			fontSize: 12,
		}).width(),
		tagIdWidth: new Konva.Text({
			text: "CVS00000000",
			fontSize: 12,
		}).width(),
	};

	return (
		<Card className="map-wrapper" bordered={false} ref={containerRef}>
			<Button
				onClick={() => setShowLabels((prev) => !prev)}
				type="primary"
				className="m-2"
				style={{
					position: "absolute",
					top: "-56px",
					left: "0",
					zIndex: "1000",
				}}
			>
				{showLabels ? "Hide Labels" : "Show Labels"}
			</Button>
			<div className={`overlay ${alertModal ? "modal-open" : ""}`}></div>

			{OrientationOverlay}
			{isLoadingCanvas && locationId ? (
				<Loader />
			) : (
				<Stage
					width={CANVAS_WIDTH}
					height={CANVAS_HEIGHT}
					onClick={placeSubmittingAsset}
					ref={assetMapRef}
				>
					<Layer>
						{drawGrid(CANVAS_WIDTH, CANVAS_HEIGHT)}
						{assets.map((asset) => (
							<Group
								key={asset.id}
								x={asset.x}
								y={asset.y}
								draggable={areAssetsDraggable}
								onDragStart={handleDragStart}
								onDragMove={handleDragMove}
								onDragEnd={handleDragEnd}
								onMouseEnter={(e) => handleHover(e)}
								onMouseLeave={() => setHoveredAsset(null)}
							>
								{asset.tag_id && (hoveredAsset === asset.id || showLabels) && (
									<Text
										text={asset.tag_id}
										align="center"
										offsetY={asset.height / 2 + 15}
										fill="gray"
										x={calculateTextXPosition(
											asset,
											canvasDimensions.width,
											labelWidthDict.tagIdWidth,
										)}
									/>
								)}
								{asset.tag_id && (hoveredAsset === asset.id || showLabels) && (
									<Text
										text={asset.type}
										align="center"
										offsetY={asset.height / 2}
										fill="black"
										x={calculateTextXPosition(
											asset,
											canvasDimensions.width,
											labelWidthDict[asset.type] || 0,
										)}
									/>
								)}

								{asset.type === "Prescription Counter" && (
									<Text
										text={"Prescription Counter"}
										align="center"
										offsetY={asset.height / 2}
										fill="#000000"
										opacity={0.8}
										x={calculateTextXPosition(
											asset,
											canvasDimensions.width,
											labelWidthDict["Prescription Counter"],
										)}
									/>
								)}
								{asset.type === "Front Counter" && (
									<Text
										text={"Front Counter"}
										align="center"
										offsetY={asset.height / 2}
										fill="#000000"
										opacity={0.8}
										x={calculateTextXPosition(
											asset,
											canvasDimensions.width,
											labelWidthDict["Front Counter"],
										)}
									/>
								)}
								<Rect
									id={asset.id || asset.tag_id || asset.type}
									width={asset.width}
									height={asset.height}
									fill={
										asset.type === "Front Counter" ||
										asset.type === "Prescription Counter"
											? "#808080"
											: "#000000"
									}
									opacity={0.8}
									scaleX={asset.isDragging ? 1.3 : 1}
									scaleY={asset.isDragging ? 1.3 : 1}
								/>
							</Group>
						))}
						{assetMapNewAsset && (
							<Group
								id={null}
								isDragging={false}
								x={assetMapNewAsset.x}
								y={assetMapNewAsset.y}
							>
								<Text
									text={tagId || "Enter Tag ID"}
									align="center"
									offsetY={30}
									fill="#4d4d4d"
									x={calculateTextXPosition(
										assetMapNewAsset,
										canvasDimensions.width,
										labelWidthDict.tagIdWidth,
									)}
								/>
								<Rect
									id={null}
									width={30}
									height={30}
									fill={"#228b22"}
									opacity={0.8}
									scaleX={1}
									scaleY={1}
								/>
								<Text
									text={chosenAssetType || "Enter Asset Type"}
									align="center"
									offsetY={15}
									x={calculateTextXPosition(
										assetMapNewAsset,
										canvasDimensions.width,
										labelWidthDict[chosenAssetType] || 200,
									)}
								/>
							</Group>
						)}
					</Layer>
				</Stage>
			)}
			<ConfirmDialog
				showConfirm={showResetModal}
				toggle={toggleResetModal}
				title="Confirm Reset"
				body="Are you sure you want to randomize the positions of all assets on the map?"
				functionality={async () => await generateSquares()}
			/>
		</Card>
	);
};

export { AssetMap };
