import { ALFRED_SERVICE_URL } from "@/constants/env";
import { auth, db } from "@assets/services/auth-service";
import { DB_FILTER, DB_PATH } from "@constants/db";
import {
	collection,
	deleteDoc,
	doc,
	getCountFromServer,
	getDocs,
	query,
	where,
} from "firebase/firestore";

export const getDrafts = async (
	organization: string,
	form: string,
	locationId: unknown,
) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS,
		form,
		DB_PATH.DRAFTS,
	);
	const snap = locationId
		? await getDocs(
				query(colRef, where(DB_FILTER.LOCATION_ID, "==", locationId)),
			)
		: await getDocs(colRef);
	return snap;
};

/**
 * Retrieves a list of draft documents from the specified organization, filtered by form and location ID.
 *
 * @param {string} form_id - The form identifier. Optional.
 * @return {Promise<QuerySnapshot>} A promise that resolves to a QuerySnapshot containing the draft documents.
 */
export const getDraftsByUser = async (form_id: any) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/self/drafts/${form_id}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch users drafts: ${name} ${errorDetails.detail}`,
				);
			});
		}

		const responsejson = await response.json();
		return responsejson;
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Updates an existing draft
 * @param draft_id
 * @param data
 * @returns {Promise<void>}
 */
export const setDraft = async (draft_id: any, data: any) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/self/draft/update/${draft_id}`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify(data),
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to update draft: ${draft_id} ${errorDetails.detail}`,
				);
			});
		}
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Creates a draft for a user (also creates an open submission)
 * @param data
 * @returns {Promise<int>} submission id
 */
export const createDraft = async (data: {
	data: any;
	form_id?: any;
	location_id?: any;
}) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/self/draft/insert`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify(data),
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to insert draft: ${errorDetails.detail}`);
			});
		}

		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Updates pre-existing or creates new draft for form-in-progress
export const saveProgress = async (
	formId: any,
	draftId: any,
	setDraftId: (arg0: any) => any,
	userLocation: { latitude: any; longitude: any },
	savedLocation: { id: any },
	responseData: any,
) => {
	const data = {
		body: responseData,
		saved_location: savedLocation,
	};
	if (userLocation?.latitude && userLocation.longitude)
		Object.assign(data, { userLocation });
	try {
		const body = {
			data: data,
		};
		if (draftId) {
			await setDraft(draftId, body);
			return draftId;
		}
		Object.assign(body, { form_id: formId });
		Object.assign(body, { location_id: savedLocation?.id });
		const draft_id = await createDraft(body);
		setDraftId(draft_id);
		return draft_id;
	} catch (e) {
		console.log("Error Updating Draft", e);
	}
};

// Used only for creating a draft last minute if the user has no had one created yet
export const createNewDraft = async (
	formId: any,
	userLocation: { latitude: any; longitude: any },
	savedLocation: { id: any },
	responseData: any,
) => {
	const data = {
		body: responseData,
		saved_location: savedLocation,
	};
	if (userLocation.latitude && userLocation.longitude)
		Object.assign(data, { userLocation });
	try {
		const body = {
			data: data,
		};
		Object.assign(body, { form_id: formId });
		Object.assign(body, { location_id: savedLocation.id });
		return await createDraft(body);
	} catch (e) {
		console.log("Error Creating Draft", e);
	}
};

// Updates pre-existing or creates new draft for form-in-progress for Cushman & Wakefield
export const saveCushmanProgress = async (
	_organization: any,
	formId: any,
	formName: any,
	draftId: any,
	authInfo: { displayName: string; id: string },
	userLocation: { latitude: any; longitude: any },
	savedLocation: any,
	inputs: any,
) => {
	const submittingUser = authInfo.displayName || "N/A";
	const submittingUserId = authInfo.id || "N/A";
	const data = {
		inputs: JSON.stringify(inputs),
		formId,
		formName,
		submittingUser,
		submittingUserId,
		submittedDate: new Date().toISOString(),
		savedLocation,
	};
	if (userLocation.latitude && userLocation.longitude)
		Object.assign(data, { userLocation });
	await setDraft(draftId, data);
};

export const deleteDraft = async (organization: string, draftId: string) => {
	await deleteDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.DRAFTS, draftId),
	);
};

export const getDraftsCount = async (organization: string, locId: unknown) => {
	const params = [];
	if (locId) {
		params.push(where(DB_FILTER.SAVED_LOCATION_ID, "==", locId));
	}
	const snap = await getCountFromServer(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.DRAFTS),
			...params,
		),
	);
	return snap.data().count;
};
