import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { useFormStore } from "@/stores";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

// @ts-ignore
const Export = ({ responseData }) => {
	const configRef = useRef<any>(null);
	const [configData, setConfigData] = useState<any>(null);
	const [imageUploaded, setImageUploaded] = useState<any>(null);
	const [templateUploaded, setTemplateUploaded] = useState<any>(null);
	// @ts-ignore
	const { alertModal, setAlertModal } = useFormStore();

	const [image] = useState(new window.Image());

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};
	useEffect(() => {
		const selected_img = responseData.alignedImage;
		if (selected_img) {
			image.onload = () => {
				const canvasWidth = responseData.dims.width;
				const canvasHeight = responseData.dims.height;
				const actualWidth = image.width;
				const actualHeight = image.height;

				const formattedData = formatData(
					canvasWidth,
					canvasHeight,
					actualWidth,
					actualHeight,
				);
				configRef.current.textContent = formattedData;
			};
			image.src = selected_img;
		}
	}, [responseData]);

	const formatData = (cw: any, ch: any, aw: number, ah: number) => {
		const retval: any = {};

		Object.assign(retval, {
			manufacturer: responseData.manufacturer,
			bbox_needs_adjustment: true,
			template_name: responseData.templateName,
			extra_fields: {},
		});

		for (const box in responseData.bboxes) {
			if (
				responseData.bboxes[box].key === "m_number" ||
				responseData.bboxes[box].key === "serial_number"
			) {
				// retval[responseData.bboxes[box].key] = formatBbox(
				// 	responseData.bboxes[box],
				// 	cw,
				// 	ch,
				// 	aw,
				// 	ah
				// );
				Object.assign(retval, {
					[responseData.bboxes[box].key]: formatBbox(
						responseData.bboxes[box],
						cw,
						ch,
						aw,
						ah,
					),
				});
			} else {
				// retval['extra_fields'][responseData.bboxes[box].key] =
				// 	formatBbox(responseData.bboxes[box], cw, ch, aw, ah);
				Object.assign(retval, {
					extra_fields: {
						...retval?.extra_fields,
						[responseData.bboxes[box].key]: formatBbox(
							responseData.bboxes[box],
							cw,
							ch,
							aw,
							ah,
						),
					},
				});
			}
		}
		setConfigData(retval);
		return `Config:\n${JSON.stringify(retval, null, 2)}`;
	};

	const handleSubmitImage = async (event: { preventDefault: () => void }) => {
		event.preventDefault();

		// Check if image is loaded
		if (!image.src) {
			alert("Image not loaded!");
			return;
		}

		// Create a canvas to draw the image for blob conversion
		const canvas = document.createElement("canvas");
		canvas.width = image.width;
		canvas.height = image.height;
		const ctx = canvas.getContext("2d");
		ctx?.drawImage(image, 0, 0, image.width, image.height);

		// Convert canvas to blob
		canvas.toBlob(async (blob) => {
			if (!blob) {
				alert("Failed to convert image to blob!");
				return;
			}

			// Create FormData to send the blob and the JSON config
			const formData = new FormData();
			formData.append(
				"file",
				blob,
				`${configData?.manufacturer}_${configData.template_name}`,
			); // 'template.png' is the filename for the uploaded image

			// Post the data to the server
			try {
				const response = await fetch(
					`${ALFRED_SERVICE_URL}/FacilityVision/OCR/templates/upload_image`,
					{
						method: "POST",
						body: formData,
						headers: {
							Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
						},
					},
				);
				const result = await response.json();
				console.log("Server response:", result);
				if (response.ok) {
					setImageUploaded(true);

					setAlertModal({
						show: true,
						title: "Success",
						body: "Template image uploaded successfully",
					});
					setTimeout(closeAlertModal, 2500);
				} else {
					setImageUploaded(false);
					setAlertModal({
						show: true,
						title: "Error",
						body: "Template image was not uploaded",
					});
					setTimeout(closeAlertModal, 2500);
				}
			} catch (error) {
				console.error("Error:", error);
				setImageUploaded(false);
			}
		}, "image/png");
	};

	const handleSubmitTemplate = async () => {
		try {
			const response = await fetch(
				`${ALFRED_SERVICE_URL}/FacilityVision/OCR/templates/upload_template`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
					body: JSON.stringify(configData),
				},
			);
			const data = await response.json();
			console.log("Success:", data);
			setTemplateUploaded(true);
			setAlertModal({
				show: true,
				title: "Success",
				body: "Template uploaded successfully",
			});
			setTimeout(closeAlertModal, 2500);
		} catch (error) {
			setTemplateUploaded(false);
			setAlertModal({
				show: true,
				title: "Error",
				body: "Template was not uploaded",
			});
			setTimeout(closeAlertModal, 2500);
			console.error("Error:", error);
		}
	};

	const formatBbox = (
		bbox: { x: number; y: number; width: number; height: number },
		canvasWidth: number,
		canvasHeight: number,
		actualWidth: number,
		actualHeight: number,
	) => {
		if (!bbox) {
			return {};
		}

		if (bbox.x >= canvasWidth) {
			return {};
		}

		const actualTopLeftX = Math.round((bbox.x / canvasWidth) * actualWidth);
		const actualTopLeftY = Math.round((bbox.y / canvasHeight) * actualHeight);
		const actualSizeWidth = Math.round(
			(bbox.width / canvasWidth) * actualWidth,
		);
		const actualSizeHeight = Math.round(
			(bbox.height / canvasHeight) * actualHeight,
		);

		return {
			top_left: [actualTopLeftX, actualTopLeftY],
			size: [actualSizeWidth, actualSizeHeight],
		};
	};

	return (
		<div className="step" id="step-export">
			<div style={{ height: "500px" }}>
				<pre style={{ padding: "25px", height: "100%" }}>
					<code ref={configRef}></code>
				</pre>
			</div>
			<Button
				onClick={handleSubmitTemplate}
				style={{
					backgroundColor: templateUploaded ? "#bf1e2d" : "green",
				}}
			>
				Upload Template
			</Button>
			<Button
				onClick={handleSubmitImage}
				style={{ backgroundColor: imageUploaded ? "#bf1e2d" : "green" }}
			>
				Upload Image
			</Button>
			<Modal centered isOpen={alertModal.show} toggle={closeAlertModal}>
				<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
				<ModalBody>{alertModal.body}</ModalBody>
			</Modal>
		</div>
	);
};

export { Export };
