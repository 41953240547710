import { formateDateofVisit } from "@/utils/ISOdateToMMDDYYYY";
import { DB_ORG } from "@constants/db";
import { useFormStore, usePageStore, useUserStore } from "@stores";
import { DocumentReference } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";

export const DraftSelectionModal = ({
	drafts,
	setResponseData,
	clearState,
	isNotAssetForm,
}) => {
	const { draftId, setDraftId } = useFormStore();
	const isMobile = window.innerWidth < 768;
	const { isSidebarOpen } = usePageStore();
	const {
		authInfo: { org },
	} = useUserStore();
	const {
		setChosenAssetType,
		savedLocation,
		savedLocationCushman,
		setSavedLocation,
		setSavedLocationCushman,
		showDraftSelectionModal,
		showDraftSelectionModalCushman,
		setShowDraftSelectionModal,
		setShowDraftSelectionModalCushman,
		setStartNew,
		setStartNewCushman,
		chosenAssetType,
	} = useFormStore();
	const [response, setResponse] = useState(null);
	const [draftOptions, setDraftOptions] = useState([]);

	useEffect(() => {
		if (drafts.length > 0) {
			setDraftOptions(
				drafts.sort((a, b) => {
					return b.last_update - a.last_update;
				}),
			);
			setResponse({
				...drafts[0],
				id: drafts[0].submission_id,
			});
		}
	}, [drafts]);

	const handleContinue = async () => {
		if (isNotAssetForm) {
			setResponseData(response.user_data.body);
			setSavedLocation(response.user_data.saved_location);
			setDraftId(response.id);
			setShowDraftSelectionModal(false);
		} else if (org !== DB_ORG.CUSHMANWAKEFIELD) {
			const responseData = response?.user_data.body;
			const newResData = {};
			Object.keys(responseData).forEach((key) => {
				if (responseData[key] instanceof DocumentReference) return;
				newResData[key] = responseData[key];
			});
			setResponseData(newResData);
			setChosenAssetType(responseData.assetType);
			setSavedLocation(response.submission.location);
			setShowDraftSelectionModal(false);
			setDraftId(response?.submission_id);
		} else if (org === DB_ORG.CUSHMANWAKEFIELD) {
			setResponseData(response.user_data.body);
			setSavedLocationCushman(response.user_data.saved_location);
			setDraftId(response.id);
			setShowDraftSelectionModalCushman(false);
		}
	};

	const handleStartNew = () => {
		if (draftId || savedLocation || savedLocationCushman || chosenAssetType) {
			org === DB_ORG.CUSHMANWAKEFIELD
				? setSavedLocationCushman(null)
				: setSavedLocation(null);
			org !== DB_ORG.CUSHMANWAKEFIELD && clearState();
		}
		if (org !== DB_ORG.CUSHMANWAKEFIELD) {
			setStartNew(true);
			setShowDraftSelectionModal(false);
		} else {
			setStartNewCushman(true);
			setShowDraftSelectionModalCushman(false);
		}
	};

	return (
		<Modal
			centered
			isOpen={
				org === DB_ORG.CUSHMANWAKEFIELD
					? showDraftSelectionModalCushman
					: showDraftSelectionModal
			}
			style={{
				left: isMobile ? "0px" : isSidebarOpen ? "130px" : "40px",
			}}
		>
			<ModalHeader
				style={{
					marginBottom: "10px",
					textAlign: org === DB_ORG.CUSHMANWAKEFIELD ? "center" : "",
				}}
			>
				{"Continue from Draft or Start New?"}
			</ModalHeader>
			<ModalFooter style={{ justifyContent: "center" }}>
				<Input
					className="form-control"
					dir="rtl"
					type="select"
					style={{ marginBottom: "10px" }}
					value={response?.id}
					onChange={(e) => {
						const selectedDraft = drafts.find(
							(draft) =>
								draft.submission_id === Number.parseInt(e.target.value),
						);
						setResponse({
							...selectedDraft,
							id: selectedDraft?.id,
						});
					}}
				>
					{draftOptions.map((draft) => {
						return (
							<option key={draft.submission_id} value={draft.submission_id}>
								{`${draft.submission.form.name} - ${
									org !== DB_ORG.CUSHMANWAKEFIELD
										? draft.user_data.body?.assetType?.name
										: draft.user_data.saved_location.data.city
								} - ${formateDateofVisit(draft.submission.begin_time)}`}
							</option>
						);
					})}
				</Input>

				<button
					className="btn btn-primary"
					style={{
						backgroundColor: "#BF1E2D",
						width: "100px",
					}}
					onClick={handleContinue}
				>
					Continue
				</button>
				<button
					className="btn btn-primary"
					style={{
						backgroundColor: "#228B22",
						width: "100px",
					}}
					onClick={handleStartNew}
				>
					Start New
				</button>
			</ModalFooter>
		</Modal>
	);
};
