import { getSubmissionWithTag } from "@/services/submission-service";
import { DB_FILTER } from "@constants/db";
import { getSubmissionWithFilter } from "@services/submission-service";

const checkRequiredFields = (questions, responseData, flags, organization) => {
	for (const question of questions) {
		if (
			organization === "SSPAMERICA" &&
			question.key === "ifOtherStoreName" &&
			!responseData.storeNameNotFound
		) {
			console.log("CONTINUED");
			continue;
		}
		if (
			organization === "Home Depot" &&
			(question.key === "assetTag" || question.key === "idTagAssetImage")
		) {
			console.log("CONTINUED");
			continue;
		}
		// TODO: Fix this hardcoded stuff later, abstract config away to org_config table
		if (
			flags.config.requireManufacturersPlate == false &&
			question.key === "manufacturersPlateAssetImage"
		) {
			continue;
		}
		if (question.required && !responseData[question.key]) {
			if (question.tiedTo && responseData[question.tiedTo] === true) {
				continue;
			}
			if (question.flag && !flags[question.flag]) {
				continue;
			}
			return {
				missing: true,
				label: question.label,
			};
		}
	}
	return {
		missing: false,
	};
};

export const formValid = async (
	setAlertModal,
	setSubmitDisabled,
	inputs,
	responseData,
	organization,
	formId,
	dupWarningShown,
	setDupWarningShown,
	assetTagError,
	isEditing,
	assetMapNewAsset,
	flags,
	isQA,
) => {
	const showAlertModal = (title, body) => {
		console.log("showAlertModal");
		setAlertModal({
			title: title,
			body: body,
			show: true,
		});
		console.log("setSubmitDisabled");
		setSubmitDisabled(false);
		return false;
	};

	const flattenedInputs = inputs.flatMap((section) => section.questions);

	const result = checkRequiredFields(
		flattenedInputs,
		responseData,
		flags,
		organization,
	);
	if (result.missing) {
		return showAlertModal(
			"Missing Fields",
			`Please enter a value for "${result.label}"`,
		);
	}

	if (flags.tagIdRequired && organization !== "Home Depot") {
		if (organization !== "swarovski.com") {
			if (!responseData.assetTag) {
				return showAlertModal(
					"Missing Fields",
					'Please enter a value for "Asset Tag ID"',
				);
			}
			if (!responseData.idTagAssetImage) {
				return showAlertModal(
					"Missing Fields",
					'Please take a photo of the "Asset Tag ID"',
				);
			}
		}
	}

	if (
		responseData.manufacturer === "(other)" &&
		!responseData.ifOtherManufacturer &&
		responseData.manufacturerNotAvailable !== "true"
	) {
		return showAlertModal("Missing Fields", "Please enter other manufacturer.");
	}

	// Check for duplicates based on make, model, & serial
	let dupsSnap = {};
	if (
		responseData.manufacturer &&
		responseData.modelNumber &&
		responseData.serialNumber &&
		!isEditing
	) {
		dupsSnap = await getSubmissionWithFilter(
			organization,
			formId,
			[
				{
					name: DB_FILTER.MANUFACTURER,
					value: responseData.manufacturer,
				},
				{
					name: DB_FILTER.MODEL_NUMBER,
					value: responseData.modelNumber,
				},
				{
					name: DB_FILTER.SERIAL_NUMBER,
					value: responseData.serialNumber,
				},
			],
			responseData?.location,
		);
	} else {
		dupsSnap = { hits: [], total_hits: 0 };
	}
	if (dupsSnap.total_hits > 0 && !dupWarningShown) {
		setDupWarningShown(true);
		return showAlertModal(
			"Missing Fields",
			"It appears there is already another submission with the same make, model, and serial. Please confirm that the details are correct and try submitting again.",
		);
	}

	// Check if duplicate asset tag id
	if (assetTagError.error) {
		console.log("assetTagError");
		return showAlertModal(
			"Tag ID Error",
			assetTagError.message || "Please enter a valid tag ID.",
		);
	}

	// if org is cvs, check if asset has been placed
	if (
		organization === "cvs.com" &&
		flags?.allowAssetMapping &&
		responseData?.assetLocation === "PHARMACY" &&
		!isQA &&
		!isEditing
	) {
		if (!assetMapNewAsset) {
			return showAlertModal(
				"Missing Fields",
				"Please place the asset onto the map.",
			);
		}
	}

	// Regular expressions for the different scenarios
	const digiRegex = /^(D.{6}|11666.{15})$/; // 'DIGI': Starts with "D" (7 characters) OR "11666" (20 characters)
	const cvsTempAlertRegex = /^11666.{15}$/; // 'cvs.com' Temp Alert: Starts with "11666" (20 characters)

	// Validate Temp Alert ID and Asset Tag
	if (
		organization === "DIGI" &&
		responseData?.tempAlertId &&
		!digiRegex.test(responseData.tempAlertId)
	) {
		return showAlertModal(
			"Temp Alert ID is Incorrect",
			'Please make sure that the temp alert ID starts with "D" and is 7 characters long or starts with "11666" and is 20 characters long.',
		);
	}

	if (organization === "cvs.com") {
		if (
			responseData?.tempAlertId &&
			!cvsTempAlertRegex.test(responseData.tempAlertId)
		) {
			return showAlertModal(
				"Temp Alert ID is Incorrect",
				'Please make sure that the temp alert ID starts with "11666" and is 20 characters long.',
			);
		}
	}

	// Check warranty date/term if there is warranty type
	if (responseData.warrantyTerm && !responseData.warrantyExpiration) {
		return showAlertModal(
			"Missing Fields",
			"Please enter a date for warranty expiration.",
		);
	}
	if (responseData.warrantyExpiration && !responseData.warrantyTerm) {
		return showAlertModal(
			"Missing Fields",
			"Please enter a date for warranty term.",
		);
	}

	// Form is considered valid
	return true;
};

function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

export const validateAfterDelay = debounce(
	async (val, responseTag, setError, org, formId, location) => {
		console.log("validateAfterDelay", val, responseTag, setError, org, formId);
		if (!val) {
			setError(false);
			return;
		}
		console.log("val", val);
		if (
			(!val.startsWith("CVS") || val.length !== 11 || val.match(/\W\s/gi)) &&
			org === "cvs.com"
		) {
			setError({
				error: true,
				message:
					'Please make sure that the tag ID starts with "CVS" and is 11 characters long',
			});
			return;
		}

		// Proceed with checking for duplicates since the string passed initial validation
		const duplicateCheckResponse = await checkDuplicateScan(val);
		const draftId = location.state?.draftId;
		const _isEditingDraft = Boolean(draftId);
		const locationAssetTag = location.state?.responseData?.assetTag;
		if (!draftId && val === locationAssetTag) {
			// If editing a draft and the asset tag hasn't changed, no need to check for duplicates
			setError({ error: false, message: "" });
			return;
		}
		if (duplicateCheckResponse.isDuplicate) {
			const address = `${duplicateCheckResponse.data.location.address1} ${duplicateCheckResponse.data.location.city}, ${duplicateCheckResponse.data.location.state} ${duplicateCheckResponse.data.location.zip}`;
			setError({
				error: true,
				message: `Duplicate tag ID found at:\n ${
					address || "N/A"
				}. \nPlease enter a unique tag ID.`,
			});
			return;
		}
		responseTag === "assetTag"
			? setError({ error: false, message: "" })
			: setError(false);
	},
);

const checkDuplicateScan = async (val) => {
	const asset = await getSubmissionWithTag(val);
	return {
		isDuplicate: asset !== null,
		data: asset,
	};
};
