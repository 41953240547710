import { DFPModal } from "@/components/Modals/dfp-modal";
import { deleteSubmission } from "@/services/submission-service";
import { useUserStore } from "@/stores";
import { message } from "antd";
import { useState } from "react";

interface DeleteModalProps {
	state: any;
	dispatch: any;
	browserLocationId: string;
	tableType?: string;
	fetchData?: (
		state: any,
		dispatch: any,
		browserLocationId: string,
	) => Promise<void>;
}

export const DeleteModal = (props: DeleteModalProps) => {
	const { state, dispatch, browserLocationId, fetchData } = props;
	const [confirmLoading, setConfirmLoading] = useState(false);
	const user = useUserStore((store) => store.user);
	const organization = user?.organization?.external_firebase_id;

	const onOk = async () => {
		setConfirmLoading(true);

		try {
			// Ensure checkedItems is defined and iterate over it
			if (props.state?.checkedItems?.length) {
				for (let i = 0; i < props.state.checkedItems.length; i++) {
					try {
						await deleteSubmission(props.state.checkedItems[i], true);
						message.success("Deleted successfully");
					} catch (error) {
						console.error(
							`Error deleting item ${props.state.checkedItems[i]}:`,
							error,
						);
						message.error(`Error deleting item ${props.state.checkedItems[i]}`);
					}
				}
			} else {
				message.warning("No items selected for deletion.");
			}
		} catch (error) {
			console.error("Error during deletion process:", error);
			message.error("An error occurred while deleting submissions.");
		} finally {
			setConfirmLoading(false);
			dispatch({ type: "SET_SHOW_DELETE_MODAL", value: false });
			dispatch({ type: "SET_CHECKED_ITEMS", payload: [] });
			// reload table
			if (fetchData) {
				fetchData(state, dispatch, browserLocationId);
			}
		}
	};

	const onCancel = () =>
		dispatch({ type: "SET_SHOW_DELETE_MODAL", value: false });
	return (
		<DFPModal
			open={state.showDeleteModal}
			onCancel={onCancel}
			onOk={onOk}
			confirmLoading={confirmLoading}
		>
			<span>
				Are you sure you want to delete these {props?.tableType || "records"}?
			</span>
		</DFPModal>
	);
};
