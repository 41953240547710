export const assetTypesWithCaseLinearFeet = [
	"Multi Deck Case - Open",
	"Island Case",
	"Cold Service Case",
	"Cake Merchandiser",
	"Combo Hot/Cold Case",
	"Single Deck Case",
	"Multi Deck Case - Doors",
	"Coffin/Bunker Case",
];

export const assetTypesWithAssetTag = [
	"Checkout Counter",
	"Security Shutter",
	"Drive Thru Drawer",
	"Fire Alarm Panel",
	"Handicap Cart",
	"Elevator",
	"Customer Service Counter",
	"EVAC System",
	"Battery Charger",
	"Forklift",
	"Baler",
	"Refrigerant Leak Detection System",
	"Dock Lift",
	"Escalator",
	"Automatic Transfer Switch",
	"Automatic Doors",
	"Cartveyor",
	"Dock Leveler",
	"Misting System",
	"Air Fryer",
	"Conveyor Equipment",
	"Water Heater",
	"Lift System",
	"Generator",
	"Dock Door",
	"Pneumatic Tube System",
	"Electric Pallet Jack",
];

export const assetTypesWithRackIdDigi = [
	"Beverage Cooler",
	"Compressor Rack",
	"Walk-In Cooler",
	"Refrigerator/Freezer ",
	"Deli - Refrigerated Prep Table",
	"Rx Freezer",
	"Refrigerated Lockers",
	"Walk-In Freezer",
	"Air-Cooled Condenser",
];

// Blast Chiller, Glycol Distribution Unit- powerSource, voltage
export const assetTypesCaseLinearFeetDigi = [
	"Multi Deck Case - Doors",
	"Coffin/Bunker Case",
	"Multi Deck Case - Open,Cold Service Case",
	"Island Case",
	"Single Deck Case",
	"Cake Merchandiser",
	"Combo Hot/Cold Case",
];

export const hvacTypesSwarovski = ["HVAC"];

export const mirrorTypesSwarovski = [
	"Counter with Mirrors",
	"Hanging Mirrors",
	"Octagonal Table with Mirrors",
	"Niche Octagon Mirrors",
	"Wardrobe Mirror Storage",
];

export const cabinetTypesSwarovski = [
	"Wall Cabinets",
	"Octagonal Shelf Units",
	"Storage Counter",
	"The Glass Vitrine",
	"Shelf Unit with Back Panel",
	"Cash Desk Back Storage",
];

export const waterEquipmentTypesSwarovski = [
	"Water Filters",
	"Boiler",
	"Water Heater",
];

export const screenWallTypesSwarovski = ["Screen Wall Built-in Prisms"];

export const fireAlarmTypesSwarovski = [
	"Fire Alarm(s)",
	"Emergency Lights/Signage",
	"Control Panels & Pumps",
];

export const hangingBoxTypesSwarovski = ["Hanging Boxes"];

export const octagonalTableTypesSwarovski = [
	"Octagonal Table with Boxes/Lenses",
];

export const displayTypesSwarovski = [
	"Window Boxes",
	"Lifestyle Wall",
	"Laser Cut Swan",
	"Big Front Lit Wordmark",
	"Vertical Blades",
];

export const earringSpinnerTypesSwarovski = ["The Earring Spinner"];

export const lightingTypesSwarovski = ["Lighting"];

export const cashDeskTypesSwarovski = ["Cash Desk"];

export const cameraTypesSwarovski = ["Cameras"];

export const stereoSystemTypesSwarovski = ["Stereo System"];

export const fireHoseTypesSwarovski = ["Fire Hose"];

export const safeLockerTypesSwarovski = ["Safe/Lockers"];

export const manualCallPointTypesSwarovski = ["Manual Call Points"];

export const sprinklerSystemTypesSwarovski = ["Sprinkler Systems"];

export const hangingOctagonTypesSwarovski = ["Hanging Octagon Unit 75"];

export const aluminumTubeTypesSwarovski = [
	"Aluminum Tubes in Vertical Disposition",
];

export const panelScreenTypesSwarovski = ["Panel/Screen 86”"];

export const wardrobeTypesSwarovski = ["Wardrobes"];

export const freeStandingScreenTypesSwarovski = ["Free Standing Screen 65”"];

export const chandelierTypesSwarovski = ["The Chandelier"];

export const extinguisherTypesSwarovski = ["Extinguishers"];

export const luminaireTypesSwarovski = ["Luminaires"];

export const ledSwanTypesSwarovski = ["LED Swan"];

export const assetTypeToCategory = {
	PLUMBING: ["WATER HEATER"],
	HVAC: [
		"HEATING/AIR CONDITIONING UNIT",
		"HEATING/AIR CONDITIONING UNIT COMPRESSOR",
		// -- 'HVAC REMOTE SENSOR',
		"HVAC THERMOSTAT",
		// -- 'IONIZATION UNIT',
		"MAKE UP AIR UNIT",
	],
	REFRIGERATION: [
		"BAR COOLER",
		"BAR FREEZER",
		"BOTTLE COOLER",
		"BROIL COOLER",
		"CANDY CASE",
		"EXPO COOLER",
		"FLATBREAD COOLER",
		// -- 'FREEZER',
		"FRY COOLER - COLD SIDE",
		"FRY FLAT TOP COOLER",
		"FRY FREEZER",
		"GRILL COOLER",
		"ICE CREAM FREEZER",
		"PANTRY COOLER COLD SIDE",
		"PANTRY COOLER HOT SIDE",
		"PASS THRU COOLER",
		"QUICK COOK OVEN",
		"REFRIGERATED BASE FRY",
		"REFRIGERATED BASE SAUTE",
		"SANDWICH COOLER",
		"SAUCE/SAUTE COOLER - HOT SIDE",
		"SAUTE COOLER COLD SIDE",
		"STAND UP FREEZER",
		"UNDER COUNTER COOLER",
		"UPRIGHT FREEZER",
		"UPRIGHT REFRIGERATOR",
		"UPRIGHT REFRIGERATOR/FREEZER COMBO",
		"WALK IN BEER COOLER",
		"WALK IN CONDENSING UNIT",
		"WALK IN FREEZER",
		"WALK IN MEAT COOLER",
		"WALK IN PRODUCE COOLER",
		"WORKTOP FRY FREEZER",
	],
	"BEER SYSTEM": ["BEER SYSTEM"],
	"EXHAUST HOOD": [
		"CONDENSATION HOOD",
		"EXHAUST FAN",
		"EXHAUST HOOD SYSTEM",
		"RESTROOM EXHAUST FAN",
	],
	"ICE MACHINE": ["ICE MACHINE", "ICE MACHINE CONDENSING UNIT"],
	"PIZZA OVEN": ["PIZZA OVEN"],
	"WINE SYSTEM": ["WINE SYSTEM"],
	"FOOD SERVICE BEVERAGE EQUIPMENT": [
		"6 BURNER RANGE",
		"COMBI OVEN",
		"CONVECTION OVEN",
		"COUNTERTOP INDUCTION RANGE",
		"DECK BROILER",
		"FLAT TOP - FRY",
		"FLAT TOP - SAUTE",
		"FLOOR MIXER",
		"FRYER",
		"FRYER FILTER",
		"HEAT LAMPS",
		"HEATED CABINET",
		// -- 'HEATED PIZZA TABLE',
		// -- 'MEAT TUMBLER',
		"MIXER - TABLE TOP",
		"MULTI COOK OVEN",
		"PASTA COOKER",
		// -- 'PREP TABLE',
		"RANGE OVEN COMBO - COOKLINE",
		"RANGE OVEN COMBO - PREPLINE",
		// 'RANGE SAUTE COOLER TOP',
		"SALAD SPINNER",
		"SALAMANDER BROILER",
		"SLICER",
		"STEAM TABLE",
		"STEAMER",
		"TILT SKILLET",
		// -- 'TURBOCHEF',
		"WARMING DRAWER",
		"WATER CHILLER",
	],
};
