import { db } from "@assets/services/auth-service";
import { DB_PATH } from "@constants/db";
import { useAdminStore } from "@stores/adminStore";
import { useLocationStore } from "@stores/locationStore";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDoc, collection, getDocs } from "firebase/firestore";

import {
	addCushmanSubmission,
	addSubmission,
	deleteSubmission,
	getSubmission,
	getSubmissions,
	getSubmissionsCount,
	updateCushmanSubmission,
	updateCushmanSubmissionReview,
	updateSubmissionReturnAsset,
	// updateRaisingCanesSubmissionReview,
} from "@services/submission-service";

/**
 * Returns a mutation function that adds a Cushman submission to the system.
 *
 * @return {function} The mutation function.
 */
export const useAddCushmanSubmission = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ draftId, inputs, userLocation }: any) =>
			await addCushmanSubmission(draftId, inputs, userLocation),
		onSuccess: () => {
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("doc" as any);
			queryClient.invalidateQueries("submissionsCount" as any);
			queryClient.invalidateQueries("submission" as any);
			queryClient.invalidateQueries("submissions" as any);
			// queryClient.invalidateQueries('forms');
			queryClient.invalidateQueries("organization" as any);
		},
	});
};

/**
 * Returns a mutation function that adds a submission to the system.
 *
 * @return {function} The mutation function.
 */
export const useAddSubmission = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ organization, formId, newResponse }: any) => {
			const response = await addSubmission(organization, formId, newResponse);
			try {
				const existingUsersSnap = await getDocs(
					collection(
						db,
						DB_PATH.ORGANIZATIONS,
						organization,
						DB_PATH.FORMS,
						`${formId}/submitting-users`,
					),
				);
				const existingUsers = existingUsersSnap.docs.map(
					(doc) => doc.data().submittingUser,
				);
				if (!existingUsers.includes(newResponse.submittingUser)) {
					await addDoc(
						collection(
							db,
							DB_PATH.ORGANIZATIONS,
							organization,
							DB_PATH.FORMS,
							`${formId}/submitting-users`,
						),
						{
							submittingUser: newResponse.submittingUser,
						},
					);
				}
			} catch (e) {
				console.log(e);
			}
			return response;
		},
		onSuccess: async () => {
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("searchAssets" as any);
			queryClient.invalidateQueries("doc" as any);
			queryClient.invalidateQueries("submissionsCount" as any);
			queryClient.invalidateQueries("submission" as any);
			queryClient.invalidateQueries("submissions" as any);
			// queryClient.invalidateQueries('forms');
			queryClient.invalidateQueries("organization" as any);
		},
	});
};

/**
 * Returns a mutation function for deleting a submission.
 *
 * @return {Function} The mutation function for deleting a submission.
 */
export const useDeleteSubmission = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			responseRef,
			deleted_by = null,
			is_deleted = null,
		}: any) => await deleteSubmission(responseRef, deleted_by, is_deleted),
		onSuccess: () => {
			queryClient.invalidateQueries("submissions" as any);
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("doc" as any);
			queryClient.invalidateQueries("assets" as any);
			queryClient.invalidateQueries("searchAssets" as any);
		},
	});
};

/**
 * Returns a query object with the results of the submission with the given organization, form, and id.
 *
 * @param {string} organization - the organization of the submission
 * @param {string} form - the form of the submission
 * @param {string} id - the id of the submission
 * @return {object} a query object with the results of the submission
 */
export const useGetSubmission = (
	organization: string,
	form: string,
	id: string,
) =>
	useQuery({
		queryKey: ["submission", organization, form, id],
		queryFn: () => getSubmission(organization, form, id),
		enabled: !!organization && !!form && !!id,
	});

export const useGetSubmissionsCount = (
	organization: string,
	form: string,
	location: unknown,
) => {
	const { activeTab: locationActiveTab } = useLocationStore() as any;
	const { activeTab: adminActiveTab } = useAdminStore() as any;
	const isActiveTab =
		locationActiveTab === "Submissions" || adminActiveTab === "Submissions";

	return useQuery({
		queryKey: ["submissionsCount", organization, form, location],
		queryFn: () => getSubmissionsCount(organization, form, location),
		enabled: !!organization && !!form && isActiveTab,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

/**
 * Returns the result of a query to get submissions based on the given parameters.
 *
 * @param {string} organization - The organization to get submissions for.
 * @param {string} form - The form to get submissions for.
 * @param {string} location - The location to get submissions for.
 * @param {number} tririgaBuildingSystemId - The ID of the building system to get submissions for.
 * @param {boolean} onlyApproved - Whether to get only approved submissions.
 * @return {object} The result of the query to get submissions.
 */
export const useGetSubmissions = (
	organization: string,
	form: string,
	location: unknown,
	tririgaBuildingSystemId: unknown,
	onlyApproved: boolean | undefined,
) =>
	useQuery({
		queryKey: [
			"submissions",
			organization,
			form,
			location,
			tririgaBuildingSystemId,
			onlyApproved,
		],
		queryFn: () =>
			getSubmissions(
				organization,
				form,
				location,
				tririgaBuildingSystemId,
				onlyApproved,
			),
		enabled: !!organization && !!form,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
	});

/**
 * Returns a mutation function that updates a submission in the system.
 *
 * @return {function} The mutation function.
 */
export const useUpdateSubmissionReturnAsset = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ organization, submissionId, formId, data }: any) => {
			const res = await updateSubmissionReturnAsset(
				organization,
				submissionId,
				formId,
				data,
			);
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("doc" as any);
			// queryClient.invalidateQueries('submissionsCount');
			queryClient.invalidateQueries("submission" as any);
			queryClient.invalidateQueries("submissions" as any);
			// queryClient.invalidateQueries('forms');
			queryClient.invalidateQueries("organization" as any);
		},
	});
};

/**
 * Returns a mutation function that updates a Cushman submission in the system.
 *
 * @return {function} The mutation function.
 */
export const useUpdateCushmanSubmission = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ draftId, inputs, userLocation }: any) => {
			await updateCushmanSubmission(draftId, inputs, userLocation);
		},
		onSuccess: () => {
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("doc" as any);
			// queryClient.invalidateQueries('submissionsCount');
			queryClient.invalidateQueries("submission" as any);
			queryClient.invalidateQueries("submissions" as any);
			// queryClient.invalidateQueries('forms');
			queryClient.invalidateQueries("organization" as any);
		},
	});
};

/**
 * Returns a mutation function that updates a Cushman submission in the system.
 * (Note: this is specific to the submissions "reviewed" field.)
 *
 * @return {function} The mutation function.
 */
export const useUpdateCushmanSubmissionReview = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ submissionId, value }: any) => {
			await updateCushmanSubmissionReview(submissionId, value);
		},
		onSuccess: () => {
			queryClient.invalidateQueries("searchResponses" as any);
			queryClient.invalidateQueries("doc" as any);
			// queryClient.invalidateQueries('submissionsCount');
			queryClient.invalidateQueries("submission" as any);
			queryClient.invalidateQueries("submissions" as any);
			// queryClient.invalidateQueries('forms');
			queryClient.invalidateQueries("organization" as any);
		},
	});
};
