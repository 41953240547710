import { initializeApp } from "@firebase/app";
import {
	EmailAuthProvider,
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	getAuth,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	updateProfile,
} from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getAnalytics } from "firebase/analytics";

if (!import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG) {
	throw new Error("Firebase config is missing");
}

const firebaseConfig = JSON.parse(
	import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
);

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
const logout = () => {
	auth.signOut();
};

export const AuthProviders = {
	google: new GoogleAuthProvider(),
	email: new EmailAuthProvider(),
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendEmailVerification,
	logout,
};
