import { SingleFormProvider } from "./Context/SingleFormContext";
import SingleFormUI from "./SingleFormUI";

const SingleForm = () => (
	<SingleFormProvider>
		<SingleFormUI />
	</SingleFormProvider>
);

export { SingleForm };
