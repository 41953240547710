import { Badge, Divider } from "antd";
import type React from "react";
import ReleaseItem, { type ReleaseItemProps } from "./ReleaseItem";

export interface ReleaseSectionProps {
	title: string;
	emoji: string;
	data: ReleaseItemProps[];
	badgeColor: string;
}

const ReleaseSection: React.FC<ReleaseSectionProps> = ({
	title,
	emoji,
	data,
	badgeColor,
}) => {
	if (!data || data.length === 0) return null;

	return (
		<div className="release-section">
			<div className="release-section-header">
				<span className="release-section-emoji">{emoji}</span>
				<Badge
					className="release-section-badge"
					color={badgeColor}
					text={<span className="release-section-title">{title}</span>}
				/>
			</div>
			<Divider className="release-section-divider" />
			{data.map((item) => (
				<ReleaseItem
					key={item.title}
					title={item.title}
					details={item.details}
				/>
			))}
		</div>
	);
};

export default ReleaseSection;
