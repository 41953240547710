import { isUnknownValue } from "@/utils/isUnknownValue";
import { GeneratedPropIcon } from "@views/SignedIn/AssetProfile/GeneratedPropIcon";
import { Typography } from "antd";

interface CopyableTableCellProps {
	text: string;
	backfillValue?: string;
}

export const DFPTypography = Typography;

export const CopyableTableCell: React.FC<CopyableTableCellProps> = ({
	text,
	backfillValue = undefined,
}) => {
	const shouldShowIcon = isUnknownValue(text) && !!backfillValue; // Show icon only if text is invalid and backfillValue exists
	const displayText = isUnknownValue(text) ? backfillValue || "N/A" : text; // Prefer valid text, fall back to backfill if invalid

	return (
		<div className="copyable-cell d-flex align-items-center">
			<Typography.Text className="d-flex flex-row gap-1" copyable>
				{displayText}
			</Typography.Text>
			{shouldShowIcon && <GeneratedPropIcon />}
		</div>
	);
};
