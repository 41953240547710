import { useCallback, useEffect, useRef, useState } from "react";
import "@views/SignedIn/FacilityVisionForm/FacilityVisionForm.scss";
import { Button } from "reactstrap";

const SegmentedCircle = ({ onSegmentClick, setCircleExists, responseData }) => {
	const canvasRef = useRef(null);
	//const size = 500;
	const segments = responseData.photoLimit;
	const notchSize = 0.1;
	const strokeColor = "black";

	useEffect(() => {
		const canvas = canvasRef.current;

		// Setting the canvas height to be dynamic
		const size = Math.min(window.innerWidth, window.innerHeight) / 1.5;
		canvas.width = size;
		canvas.height = size;

		//Calculating values
		const ctx = canvas.getContext("2d");
		const centerX = size / 2;
		const centerY = size / 2;
		// Reduce the outer radius to prevent cutting off at edges
		const outerRadius = (size / 2) * 0.95; // 95% of half canvas size
		const innerRadius = outerRadius * 0.4;
		const segmentAngle = (2 * Math.PI) / segments;
		const offset = 3 * (Math.PI / 2) - segmentAngle / 2 + notchSize / 2;

		/**
		 * This method will draw the corresponding segment given the below params.
		 * @param startAngle
		 * @param endAngle
		 * @param segmentIndex
		 */
		const drawSegment = (startAngle, endAngle, segmentIndex) => {
			ctx.beginPath();
			ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle, false);
			ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
			ctx.closePath();
			// Highlighting the next segment to help guide the user
			ctx.strokeStyle =
				(responseData.photoArray[segmentIndex - 1] || segmentIndex === 0) &&
				!responseData.photoArray[segmentIndex]
					? "#BF1E2D"
					: strokeColor;
			ctx.lineWidth = 5;
			ctx.stroke();
			ctx.save();

			//Attempting to draw photos
			if (responseData.photoArray[segmentIndex]) {
				ctx.clip();
				//Calculating the cartesian points for the left(start) and right(end) outer radius arc points
				const start_x = centerX + outerRadius * Math.cos(startAngle);
				const start_y = centerY + outerRadius * Math.sin(startAngle);
				const end_x = centerX + outerRadius * Math.cos(endAngle);
				const end_y = centerY + outerRadius * Math.sin(endAngle);

				// Calculating the distance between the two points
				const distance = Math.sqrt(
					Math.pow(end_x - start_x, 2) + Math.pow(end_y - start_y, 2),
				);

				//Calculating the angle which we should orient our photo
				const angle = Math.atan2(end_y - start_y, end_x - start_x);

				//Here we translate the origin and rotate the canvas so that we can rotate our images
				ctx.translate(start_x, start_y);
				ctx.rotate(angle);

				//Calculating the distance from the midpoint line to the top of the arc for image position
				const move_h =
					outerRadius -
					Math.sqrt(Math.pow(outerRadius, 2) - Math.pow(distance / 2, 2));
				const image_height = outerRadius - innerRadius + move_h / 2;

				ctx.drawImage(
					responseData.photoArray[segmentIndex],
					0,
					-move_h,
					distance,
					image_height,
				);
				ctx.restore();
			}
		};

		/**
		 * Draws all the segments of the circle
		 */
		const draw = async () => {
			ctx.clearRect(0, 0, size, size);
			for (
				let i = 0;
				i < segments && (i === 0 || responseData.photoArray[i - 1]);
				i++
			) {
				const startAngle = i * segmentAngle + offset;
				const endAngle = startAngle + segmentAngle - notchSize;
				await drawSegment(startAngle, endAngle, i);
			}
			setCircleExists(true);
		};

		/**
		 * Determines the segment the user clicked on
		 * @param event
		 */
		const handleClick = (event) => {
			const rect = canvas.getBoundingClientRect();
			const x = event.clientX - rect.left - centerX;
			const y = event.clientY - rect.top - centerY;
			let angle = Math.atan2(y, x) - offset;

			// Normalize the angle to be within the range [0, 2π]
			if (angle < 0) {
				angle += 2 * Math.PI;
			}

			const radius = Math.sqrt(x * x + y * y);

			if (radius > innerRadius && radius < outerRadius) {
				let clickedSegment = Math.floor(angle / segmentAngle);

				// Adjust for the case where the click is in the last segment
				if (clickedSegment < 0) {
					clickedSegment = segments + clickedSegment;
				}

				//Only allowing photos to be uploading sequentially from index 0
				if (
					responseData.photoArray[clickedSegment - 1] ||
					clickedSegment === 0
				) {
					onSegmentClick(clickedSegment);
				} else {
					console.log("Wrong order.");
				}
			}
		};
		canvas.addEventListener("click", handleClick);
		draw();

		// Cleanup function
		return () => {
			canvas.removeEventListener("click", handleClick);
		};
	}, [segments, notchSize, strokeColor, responseData, onSegmentClick]);

	return <canvas id="photoUploadCanvas" ref={canvasRef} />;
};

/**
 * Element that handles image selection for facility vision
 * @param organization
 * @param responseData
 * @param setResponseData
 * @returns {JSX.Element}
 * @constructor
 */
const PhotoUploadField = ({ organization, responseData, setResponseData }) => {
	const fileInputRef = useRef(null);
	const compassRef = useRef(null);
	const isIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/);
	const isAndroid = navigator.userAgent.match(/(Android)/);
	const [circleExists, setCircleExists] = useState(false);

	/**
	 * This method handles segment clicks from the SegmentedCircle element,
	 * it will only trigger if the user clicks the next index to be uploaded or a previous one.
	 * @param segmentIndex
	 */
	const handleSegmentClick = (segmentIndex) => {
		console.log(`clicked segment: ${segmentIndex}`);
		fileInputRef.current.click();
		fileInputRef.current.onchange = (event) => {
			const file = event.target.files[0]; // Get the selected file
			const reader = new FileReader();
			reader.onloadend = () => {
				const newPhotos = [...responseData.photoArray];
				newPhotos[segmentIndex] = new Image();
				newPhotos[segmentIndex].onload = () => {
					setResponseData({
						...responseData,
						photoArray: newPhotos,
					});
				};
				newPhotos[segmentIndex].src = reader.result;
			};
			if (file) {
				reader.readAsDataURL(file);
			}
		};
	};

	/**
	 * Sets the rotation angle of the compass html element
	 * @param rotation
	 */
	const setRotationAngle = (rotation) =>
		(compassRef.current.style.transform = rotation);

	/**
	 * The handler for rotation event data
	 * @type {(function(*): void)|*}
	 */
	const compass_handler = useCallback(
		(event) => {
			const compass = event.webkitCompassHeading || Math.abs(event.alpha - 360);
			// Calculate the rotation angle based on device orientation
			const newRotationAngle = `rotate(${-compass}deg)`;
			setRotationAngle(newRotationAngle);
		},
		[circleExists],
	);

	/**
	 * Starts the compass if the user is on iOS or Android
	 */
	function startCompass() {
		if (isIOS) {
			DeviceOrientationEvent.requestPermission()
				.then((response) => {
					if (response === "granted") {
						window.addEventListener("deviceorientation", compass_handler, true);
					} else {
						console.log(response);
						alert("has to be allowed!");
					}
				})
				.catch(() => alert("not supported"));
		} else if (isAndroid) {
			window.addEventListener(
				"deviceorientationabsolute",
				compass_handler,
				true,
			);
		} else {
			console.log("Neither Android and iOS");
		}
	}

	/**
	 * After the page renders, start the compass if on IOS or Android
	 */
	useEffect(() => {
		if (circleExists) {
			console.log("Initializing Compass...");
			startCompass();
		}

		// Cleanup function
		return () => {
			console.log("cleanup");
			window.removeEventListener(
				"deviceorientationabsolute",
				compass_handler,
				true,
			);
			window.removeEventListener("deviceorientation", compass_handler, true);
		};
	}, [circleExists]);

	return (
		<div className="container justify-content-center text-center">
			<div className="row justify-content-center">
				<h4>
					Photo {responseData.photoArray.length} / {responseData.photoLimit}
				</h4>
				{responseData.photoArray.length === 0 ? (
					<p>Please click on the green section to get started.</p>
				) : (
					<p>Now please turn about 30 degrees and upload the next photo.</p>
				)}
			</div>
			<div className="justify-content-center">
				{circleExists && (isAndroid || isIOS) && (
					<div
						ref={compassRef}
						id="compass"
						style={{
							top: document.querySelector("canvas").height / 2 + 25,
						}}
					>
						<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
							<rect x="20" y="10" width="10" height="20" fill="#250245" />
							<polygon points="10,10 25,0 40,10" fill="#250245" />
						</svg>
					</div>
				)}
				<SegmentedCircle
					onSegmentClick={handleSegmentClick}
					setCircleExists={setCircleExists}
					responseData={responseData}
				/>
			</div>
			<div className="row justify-content-center">
				<Button
					className="segment-button"
					onClick={() => {
						if (responseData.photoLimit > 3) {
							const limit = --responseData.photoLimit;
							setResponseData({
								...responseData,
								photoArray: responseData.photoArray.slice(0, limit),
								photoLimit: limit,
							});
						}
					}}
				>
					Remove
				</Button>
				<Button
					className="segment-button"
					onClick={() => {
						setResponseData({
							...responseData,
							photoArray: [],
						});
					}}
				>
					Reset
				</Button>
				<Button
					className="segment-button"
					onClick={() => {
						if (responseData.photoLimit < 20) {
							setResponseData({
								...responseData,
								photoLimit: ++responseData.photoLimit,
							});
						}
					}}
				>
					Add
				</Button>
			</div>
			<input type="file" ref={fileInputRef} style={{ display: "none" }} />
		</div>
	);
};

export { PhotoUploadField };
