import { InteractableProgressBar } from "@/components/layouts/SignedIn/InteractableProgressBar/InteractableProgressBar";
import { Loader } from "@SignedIn/Loader/Loader";
import { LoadingModal } from "@SignedIn/LoadingModal/LoadingModal";
import { ConfirmDialog } from "@SignedIn/views/Locations/children/ConfirmDialog";
import { CropperModal } from "@SignedIn/views/SingleForm/CropperModal";
import { SubmittedImageModal } from "@SignedIn/views/SingleForm/SubmittedImageModal";
import { Button as AntButton } from "antd";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Button, Card, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSingleFormContext } from "./Context/SingleFormContext";
import { CurrentPage } from "./CurrentPage";
import { DraftSelectionModal } from "./DraftSelectionModal";

const SingleFormUI = () => {
	const {
		isEditing,
		isQA,
		navigate,
		originalPath,
		originalLoc,
		submissionId,
		formId,
		formLogo,
		formName,
		inputs,
		isPageValid,
		submitAttempted,
		handleProgressBarCircleClick,
		currentPage,
		submit,
		setUserLocation,
		setInputData,
		responseData,
		generativeAiInputs,
		setGenerativeAiInputs,
		setResponseData,
		capturePhoto,
		getOptions,
		getOptionsLength,
		fileInputRef,
		handleFileChange,
		prevPage,
		draftIdState,
		handleCancel,
		nextPage,
		isCropShowing,
		photoToBeCropped,
		getCroppedPhoto,
		showLoader,
		alertModal,
		closeAlertModal,
		isMobile,
		isSidebarOpen,
		showCancelModal,
		closeCancelModal,
		handleDeleteDraft,
		todaysDrafts,
		clearState,
		draftId,
		isLoading,
		setIsCropShowing,
	} = useSingleFormContext();

	if (isLoading) {
		return currentPage !== 0 ? <Loader /> : null;
	}

	return (
		<div>
			<Card className="px-4 some-drop-shadow form-card d-flex flex-column justify-content-between">
				<span className="logo-container d-flex flex-column">
					{isEditing && (
						<div
							className=" d-flex width-fit-content mt-3"
							onClick={() => {
								let tab = "Submissions";
								if (isQA) tab = "QA Submissions";
								navigate(originalPath, {
									state: {
										data: originalLoc.data,
										id: originalLoc.id,
										tab,
										submissionId,
										formId,
									},
								});
							}}
						>
							<AntButton type="link" icon={<FaArrowLeft />} className="p-0">
								Back
							</AntButton>
						</div>
					)}
					<img
						src={formLogo}
						className="form-logo mt-3"
						hidden={!formLogo}
						alt="logo"
					/>
					{/* <CardHeader className='header-card'> */}
					<span className="form-title">{formName}</span>
					<div className="mt-4 w-100">
						<InteractableProgressBar
							availableChoice={inputs.length}
							validationStates={isPageValid}
							submitAttempted={submitAttempted}
							onClick={handleProgressBarCircleClick}
							now={Math.ceil(((currentPage + 1) * 100) / inputs.length)}
							animated
							variant="greenHealth"
							style={{
								height: "12px",
								maxWidth: "500px",
								width: "100%",
							}}
						/>
					</div>
					{/* </CardHeader> */}
				</span>
				<Form onSubmit={(e) => e.preventDefault()}>
					<CurrentPage
						inputs={inputs}
						currentPage={currentPage}
						submit={submit}
						setUserLocation={setUserLocation}
						setInputData={setInputData}
						responseData={responseData}
						generativeAiInputs={generativeAiInputs}
						setGenerativeAiInputs={setGenerativeAiInputs}
						setResponseData={setResponseData}
						capturePhoto={capturePhoto}
						getOptions={getOptions}
						getOptionsLength={getOptionsLength}
						formId={formId}
					/>
				</Form>

				<input
					type="file"
					ref={fileInputRef}
					onChange={handleFileChange}
					style={{ display: "none" }}
				/>
				<div className="position-relative">
					<div className="form-page-navigation text-center my-4">
						<Button
							className="m-2 px-4"
							onClick={prevPage}
							disabled={currentPage < 1}
						>
							<FaArrowLeft />
						</Button>
						{draftIdState !== null && (
							<Button className="m-2 px-4 btn-danger" onClick={handleCancel}>
								Cancel
							</Button>
						)}
						<Button
							className="m-2 px-4"
							onClick={nextPage}
							disabled={currentPage >= inputs.length - 1}
						>
							<FaArrowRight />
						</Button>
					</div>
				</div>
			</Card>
			<SubmittedImageModal
				switchToCrop={true}
				removeImage={true}
				responseData={responseData}
				setResponseData={setResponseData}
				capturePhoto={capturePhoto}
				getOptions={getOptions}
				setInputData={setInputData}
				getOptionsLength={getOptionsLength}
			/>
			<CropperModal
				showing={isCropShowing}
				photoUrl={photoToBeCropped}
				onCrop={(data) => getCroppedPhoto(data)}
				cancel={() => setIsCropShowing(false)}
			/>
			<LoadingModal showing={showLoader} />
			<Modal
				centered
				isOpen={alertModal.show}
				toggle={closeAlertModal}
				style={{
					left: isMobile ? "0px" : isSidebarOpen ? "130px" : "40px",
				}}
			>
				<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
				<ModalBody>
					{alertModal.body}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "5px",
						}}
					>
						{alertModal.options?.map((option, index) => (
							<Button
								key={index}
								className={
									option.text === "Retake the Picture"
										? "button-green"
										: option.text === "No Manufacturer's Plate"
											? "button-red"
											: ""
								}
								onClick={() => option.action()}
								style={{ margin: "2px" }}
							>
								{option.text}
							</Button>
						))}
					</div>
				</ModalBody>
			</Modal>
			<ConfirmDialog
				showConfirm={showCancelModal}
				toggle={closeCancelModal}
				title="Confirm Deletion"
				body={"Are you sure you want to delete draft?"}
				functionality={handleDeleteDraft}
			/>
			<DraftSelectionModal
				drafts={todaysDrafts}
				setResponseData={setResponseData}
				clearState={clearState}
				draftId={draftId}
			/>
		</div>
	);
};

export default SingleFormUI;
