import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { BsCloudDownload } from "react-icons/bs";

const getStringOrObjectName = (input) => {
	if (typeof input === "string") {
		return input;
	}
	if (typeof input === "object" && input !== null && "name" in input) {
		return input.name;
	}
	return null;
};

const DownloadCsv = (props) => {
	const { form } = props;

	// Header row arr state
	const [header, setHeader] = useState();
	// Data row arr state
	const [data, setData] = useState();
	// Filename state
	const [filename, setFilename] = useState("");
	// CSV state
	const [csv, setCsv] = useState();

	// Update header row, data row, and filename states on render / when form prop changes
	useEffect(() => {
		if (form != null) {
			const keys = Object.keys(form.raw_input);
			let newHeader;
			let newData;
			let address = "";

			if (keys.length > 0) {
				newHeader = ["Form Name", "Address"];
				if (form.location.address) {
					address = form.location.address;
				}
				if (form.location.address1) {
					address = `${address} ${form.location.address1}`;
				}
				if (form.location.address2) {
					address = `${address} ${form.location.address2}`;
				}
				if (form.location.city) {
					address = `${address}, ${form.location.city}`;
				}
				if (form.location.state) {
					address = `${address}, ${form.location.state}`;
				}
				if (form.location.zip) {
					address = `${address} ${form.location.zip}`;
				}
				newData = [form.form.name, address];
			}

			keys.forEach((key) => {
				if (
					key !== "location" &&
					key !== "formName" &&
					key !== "formId" &&
					key !== "submissionId" &&
					key !== "logo"
				) {
					const result = key.replace(/([A-Z])/g, " $1");
					const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

					if (form.raw_input[key] === "on") {
						form.raw_input[key] = "Yes";
					} else if (form.raw_input[key] === "off") {
						form.raw_input[key] = "No";
					}

					newHeader = [...newHeader, finalResult];
					newData = [...newData, getStringOrObjectName(form.raw_input[key])];
				}
			});

			setHeader(newHeader);
			setData(newData);
			setFilename(`${form.form.name} - ${address}`);
		}
	}, [form]);

	// Convert a 2D array into a CSV string
	const arrayToCsv = (data) => {
		return data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double colons
						.map((v) => `"${v}"`) // quote it
						.join(","), // comma-separated
			)
			.join("\r\n"); // rows starting on new lines
	};

	// Update csv state when header & data arr states change
	useEffect(() => {
		if (header != null && data != null) {
			const newCsv = arrayToCsv([header, data]);
			setCsv(newCsv);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [header, data]);

	// Download contents as a file
	const downloadFile = (content, name, contentType) => {
		try {
			// Create a blob
			const blob = new Blob([content], { type: contentType });
			const url = URL.createObjectURL(blob);

			// Create a link to download it
			const pom = document.createElement("a");
			pom.href = url;
			pom.setAttribute("download", name);
			pom.click();
			message.success("File downloaded successfully");
		} catch (error) {
			console.error(error);
			message.error("Failed to download file");
		}
	};

	return (
		// <div
		// 	className="activities-page-header-buttons d-flex p-1 width-fit-content "
		// 	onClick={() => downloadFile(csv, filename, "text/csv;charset=utf-8;")}
		// >
		// 	<div className="d-flex justify-content-center align-items-center">
		// 		CSV <i className="bi bi-cloud-download mx-2"></i>
		// 	</div>
		// </div>
		<Button
			onClick={() => downloadFile(csv, filename, "text/csv;charset=utf-8;")}
			type="default"
			variant="outlined"
			icon={<BsCloudDownload />}
		>
			<div className="d-flex justify-content-center align-items-center">
				CSV
			</div>
		</Button>
	);
};

export { DownloadCsv };
