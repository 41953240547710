import Fuse from "fuse.js";

const searchForms = async (val, list) => {
	if (val.length > 1) {
		const options = {
			includeScore: true,
			keys: ["data.formName"],
			threshold: 0.25,
		};

		const fuse = new Fuse(list, options);

		const results = await fuse.search(val);

		let returnVal = [];

		for (const res of results) {
			returnVal = [...returnVal, res.item];
		}

		return returnVal;
	}
	return [];
};

export { searchForms };
