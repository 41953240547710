import { HealthScore } from "@components/layouts/SignedIn/views/Dashboard/HealthScore/HealthScore";
import { useDashboard } from "@contexts/dashboardContext";
import { getHoverChartResponse } from "@services/chart-service";
import { useUserStore } from "@stores";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { HealthScoreHoverPreview } from "../HealthScoreHoverGraph/HealthScoreHoverPreview";
import { HoverGraphModal } from "../HealthScoreHoverGraph/HoverGraphModal";
import Loader from "./Loader";

const ExecutiveSummary = ({ data, isLocationPage }) => {
	if (!data) return <Loader />;

	const { allAssets, monthAssets, yearAssets, badAssets, locationsCountInfo } =
		data;

	const allAssetsCountDisplay = allAssets.toLocaleString();
	const monthsAssetsCountDisplay = monthAssets
		? monthAssets.toLocaleString()
		: "no";
	const yearsAssetsCountDisplay = yearAssets.toLocaleString();
	const badAssetsCountDisplay = badAssets ? badAssets.toLocaleString() : "no";
	const locationCountDisplay = locationsCountInfo.total.toLocaleString();
	const emptyLocationsCountDisplay =
		locationsCountInfo.no_submissions.toLocaleString();

	return (
		<div className="fs-5 alfreds-notes-text">
			<div className="summary-note-container">
				<div className="summary-note">
					{`Total of ${allAssetsCountDisplay} assets`}
				</div>
			</div>
			{yearAssets ? (
				<div className="summary-note-container">
					<div className="summary-note">
						{yearAssets === 1
							? `There has been one asset added last month and ${yearsAssetsCountDisplay} year-to-date`
							: `There have been ${monthsAssetsCountDisplay} assets added last month and ${yearsAssetsCountDisplay} year-to-date`}
					</div>
				</div>
			) : null}
			<div className="summary-note-container">
				<div className="summary-note">
					{badAssets === 1
						? "There is one asset that is reported as being in broken or poor condition"
						: `There are ${badAssetsCountDisplay} assets that are reported as being in broken or poor condition`}
				</div>
			</div>
			{locationsCountInfo.total && !isLocationPage ? (
				<div className="summary-note-container">
					<div className="summary-note">
						{`In total, ${locationCountDisplay} locations have been reported in FacilityAI`}
					</div>
				</div>
			) : null}
			{locationsCountInfo.no_submissions && !isLocationPage ? (
				<div className="summary-note-container">
					<div className="summary-note">
						{`There are ${emptyLocationsCountDisplay} locations with no submitted assets`}
					</div>
				</div>
			) : null}
		</div>
	);
};

const AlfredsNotes = ({ healthScore, setDisplayToast }) => {
	const { summary, loading } = useDashboard();
	const location = useLocation();
	const locationId = location?.state?.id || null;
	const isLocationPage = location.pathname.includes("/locations");
	const [hoverChartData, setHoverChartData] = useState(undefined);
	const [isPreviewShowing, setIsPreviewShowing] = useState(false);
	const [isModalShowing, setIsModalShowing] = useState(false);
	const { user } = useUserStore();

	const getHoverChartData = async () => {
		const response = await getHoverChartResponse(
			locationId,
			user.organization.external_firebase_id,
		);
		// check if response is an empty object
		if (Object.keys(response).length === 0) {
			setHoverChartData(null);
			return;
		}
		setHoverChartData({
			labels: Object.keys(response),
			datasets: [
				{
					data: Object.values(response),
					fill: false,
					backgroundColor: Object.values(response).map((value) =>
						value < 50 ? "#df3053" : value < 75 ? "#fbc658" : "#BF1E2D",
					),
					barPercentage: 0.7,
					borderRadius: 6,
				},
			],
		});
	};

	useEffect(() => {
		getHoverChartData();
	}, []);

	return (
		<>
			{!isLocationPage && (
				<Card className="alfreds-notes pt-2">
					<CardBody className="p-0 d-md-flex" style={{ flex: "none" }}>
						<Col className="col-12 col-md-8 alfreds-notes-title">
							<h3 className="text-purple">{`${
								!isLocationPage && "Organization"
							} Overview`}</h3>
						</Col>
						<Col className="col-12 col-md-4 justify-content-center pt-3 alfreds-health-score-box">
							<div
								onMouseEnter={() => setIsPreviewShowing(true)}
								onMouseLeave={() => setIsPreviewShowing(false)}
								className="alfreds-health-score"
							>
								{!isLocationPage && (
									<HealthScore
										healthScore={healthScore}
										label={"Health Score"}
									/>
								)}
								{isPreviewShowing && (
									<HealthScoreHoverPreview
										chartData={hoverChartData}
										setIsPreviewShowing={setIsPreviewShowing}
										setIsModalShowing={setIsModalShowing}
									/>
								)}
							</div>
						</Col>
					</CardBody>

					<CardBody className="p-0 d-flex">
						<Col className="col-12 col-md-8">
							{loading ? (
								<Loader />
							) : (
								<ExecutiveSummary
									data={summary}
									isLocationPage={isLocationPage}
								/>
							)}
						</Col>
					</CardBody>
					<HoverGraphModal
						chartData={hoverChartData}
						setIsPreviewShowing={setIsPreviewShowing}
						isModalShowing={isModalShowing}
						setIsModalShowing={setIsModalShowing}
						setDisplayToast={setDisplayToast}
					/>
				</Card>
			)}
		</>
	);
};

export { AlfredsNotes };
