import { FormGroup, Label, Row } from "reactstrap";

const SwitchInput = ({ question, responseData, setInputData }) => {
	return (
		<Row key={question.key}>
			<FormGroup
				hidden={
					(question.key === "ifOther" && responseData.assetType !== "Other") ||
					responseData[question.tiedTo] === "No" ||
					(question.key === "otherAssetType" &&
						responseData.assetType !== "Other")
				}
			>
				<Label className="single-form-label">
					{question.label}
					{question.required === "true" ? (
						<span style={{ color: "red" }}> *</span>
					) : (
						""
					)}
				</Label>
				<br />
				<div className="toggle">
					<input
						key={question.key}
						type="checkbox"
						onChange={(e) => {
							setInputData(question, e.target.checked);
						}}
						id={question.id}
						className="toggle-checkbox"
						defaultChecked={question.default === "true"}
					/>
					<div className="toggle-switch">
						<span className="toggle-on-text">ON</span>
						<span className="toggle-off-text">OFF</span>
					</div>
					<div className="toggle-label">
						{!responseData[question.key]
							? ""
							: responseData[question.key] === "false"
								? "No"
								: "Yes"}
					</div>
				</div>
			</FormGroup>
		</Row>
	);
};

export { SwitchInput };
