import { SendEmail } from "@/components/layouts/SignedIn/Navbar/children/SendEmail";
import logo from "@assets/img/FacilityAI_Logo.svg";
import { AuthProviders, auth } from "@assets/services/auth-service";
import { useLoginMutation } from "@hooks/authQueries";
// import logo1 from '@assets/img/logo-clear.png';
// import logo2 from '@assets/img/Property_Eco_Logo-03.png';
import { useSetUser } from "@hooks/userQueries";
import { userSignup } from "@services/user-service";
import { useState } from "react";
import {
	IoEyeOffOutline,
	IoEyeOutline,
	IoLockClosedOutline,
	IoMailOpenOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, Col, Form, Input, Row } from "reactstrap";

const Login = () => {
	const [isResetting, setIsResetting] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [org, setOrg] = useState("");

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePassword = (e: Event) => {
		e.preventDefault();
		setPasswordShown(!passwordShown);
	};

	const navigate = useNavigate() as any;
	const setUser = useSetUser() as any;

	const loginMutation = useLoginMutation();

	const submit = () => {
		loginMutation.mutate({ email, password, navigate });
	};

	const createAccount = async () => {
		try {
			//look_into_later
			const user = await userSignup(name, email, password, org);
			await setUser.mutate({ user });
			navigate("/");
		} catch (error) {
			alert(error);
		}
	};

	const resetPassword = async () => {
		try {
			await AuthProviders.sendPasswordResetEmail(auth, email);
			alert("Password Reset Sent");
		} catch (error) {
			alert(error);
		}
	};

	return (
		<div className="main">
			{!isCreating && !isResetting ? (
				<Card className="logincard">
					<img className="logo-img1 mt-3" src={logo} alt="react"></img>
					{/* <img className='logo-img2' src={logo} alt='react'></img> */}
					<div className="login-motto mb-2 text-center">
						{/* The Future of Facilities Management */}
						{/* <br />
						<div style={{ fontSize: '12px' }}>
							Powered by Facility AI
						</div> */}
					</div>
					<Row>
						<Col>
							<Form>
								<span className="emailContainer">
									<ul className="email-icon">
										<IoMailOpenOutline />
									</ul>
									<Input
										value={email}
										className="email-input"
										placeholder="Email"
										type="email"
										onChange={(e) => setEmail(e.target.value)}
										onKeyPress={(e) => (e.key === "Enter" ? submit() : null)}
									/>
								</span>
								<span className="passwordContainer">
									<ul className="password-icon">
										<IoLockClosedOutline />
									</ul>
									<Input
										value={password}
										className="password-input"
										placeholder="Password"
										type={passwordShown ? "text" : "password"}
										onChange={(e) => setPassword(e.target.value)}
										onKeyPress={(e) => (e.key === "Enter" ? submit() : null)}
									/>
									<Button
										className="passtog-icon"
										onClick={togglePassword as any}
									>
										{passwordShown ? <IoEyeOutline /> : <IoEyeOffOutline />}
									</Button>
								</span>
								<div className="lowerButtons">
									<Button className="logButton" onClick={submit}>
										Log In
									</Button>
									<div className="orBar">
										<span className="before"></span>
										<span className="or-text">OR</span>
										<span className="after"></span>
									</div>
									<div className="row">
										<div className="col">
											<Button
												className="createButton"
												style={{ color: "black" }}
												onClick={() => setIsCreating(true)}
											>
												Create Account
											</Button>
										</div>
										<div className="col">
											<Button
												className="createButton"
												onClick={() => setIsResetting(true)}
											>
												Reset Password
											</Button>
										</div>
									</div>
								</div>
							</Form>
						</Col>
					</Row>
				</Card>
			) : !isResetting ? (
				<Card className="createcard">
					<img className="logo-img1 mt-3" src={logo} alt="react"></img>
					{/* <img className='logo-img2' src={logo} alt='react'></img> */}
					<div className="login-motto mb-2 text-center">
						{/* The Future of Facilities Management */}
					</div>
					{/* <img className='logo-img2' src={logo2} alt='react'></img>  */}
					<CardHeader className="cardHeader">Create Account</CardHeader>
					<Row>
						<Col>
							<Form>
								<span className="nameContainer">
									<Input
										value={name}
										placeholder="Full Name"
										className="name-input"
										type="text"
										onChange={(e) => setName(e.target.value)}
									/>
								</span>
								<span className="organizationContainer">
									<Input
										value={org}
										placeholder="Organization"
										className="organization-input"
										type="text"
										onChange={(e) => setOrg(e.target.value)}
									/>
								</span>
								<span className="emailContainer">
									<Input
										value={email}
										placeholder="Email"
										className="email-input"
										type="email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</span>
								<span className="passwordContainer">
									<Input
										value={password}
										placeholder="Password"
										className="password-input"
										type="password"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</span>
								<div className="lowerButtons">
									<Button
										onClick={async () => {
											await createAccount();
										}}
										className="logButton"
									>
										Submit
									</Button>
									<div className="orBar">
										<span className="before"></span>
										<span className="or-text">OR</span>
										<span className="after"></span>
									</div>
									<Button
										className="gobackButton"
										onClick={() => setIsCreating(false)}
									>
										Go Back
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Card>
			) : (
				<Card className="createcard reset">
					<img className="logo-img1 mt-3" src={logo} alt="react"></img>
					{/* <img className='logo-img2' src={logo2} alt='react'></img> */}
					<CardHeader className="cardHeader">Create Account</CardHeader>
					<Row>
						<Col>
							<Form>
								<span className="emailContainer">
									<Input
										value={email}
										placeholder="Email"
										className="email-input"
										type="email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</span>
								<div className="lowerButtons">
									<Button onClick={resetPassword} className="logButton">
										Submit
									</Button>
									<div className="orBar">
										<span className="before"></span>
										<span className="pr-text">OR</span>
										<span className="after"></span>
									</div>
									<Button
										className="gobackButton"
										onClick={() => setIsResetting(false)}
									>
										Go Back
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Card>
			)}
			<footer className="footer text-center text-lg-left fixed-bottom">
				<div className="text-center">Powered by Facility AI</div>
				<div className="text-center pb-3 d-flex justify-content-center align-items-center">
					&copy; {new Date().getFullYear()} Copyright:&nbsp;
					<a className="text-dark" href="https://datafleetservices.com/">
						Data Fleet LLC
					</a>
					<div className="mx-2">•</div>
					<SendEmail />
				</div>
			</footer>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};

export { Login };
