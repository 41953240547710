import { Tag } from "antd";
import type React from "react";

export interface ReleaseCardTitleProps {
	version: string;
	date: string;
}

const ReleaseCardTitle: React.FC<ReleaseCardTitleProps> = ({
	version,
	date,
}) => (
	<div className="releases-card-title">
		<Tag color="purple" className="release-pill">
			v{version}
		</Tag>
		<Tag color="warning" className="release-pill">
			{date}
		</Tag>
	</div>
);

export default ReleaseCardTitle;
