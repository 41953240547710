import { ColumnsType } from "antd/es/table";
import { Draft, Submission } from "../SubmissionsTable";

export const initializeColumnsShown = (
	columns: ColumnsType<Submission> | ColumnsType<Draft>,
): Record<string, boolean> => {
	const shown: Record<string, boolean> = {};
	for (const col of columns) {
		if (col.key) {
			shown[col.key.toString()] = true;
		}
	}
	return shown;
};
