import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { useChatbotHistory } from "@contexts/chatbotContext.js";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import ActionProvider from "./ActionProvider.jsx";
import { MessageParser } from "./MessageParser.jsx";
import getChatbotConfig, { CONTAINER } from "./config.js";

const PEChatbot = ({ toggleChatbot, setToggleChatbot }) => {
	const { messageHistory } = useChatbotHistory();
	const location = useLocation();

	useEffect(() => {
		if (
			toggleChatbot &&
			(location.pathname === "/" ||
				location.pathname.includes("/assets/") ||
				location.state?.tab === "Overview")
		) {
			setToggleChatbot(false);
		}
	}, [toggleChatbot, setToggleChatbot, location]);

	return (
		toggleChatbot && (
			<div className="chatbot-wrapper position-absolute">
				<>
					<button
						className="chatbot-close"
						onClick={() => setToggleChatbot(false)}
					>
						<AiOutlineClose />
					</button>
					<Chatbot
						config={getChatbotConfig(CONTAINER.WIDGET)}
						messageParser={MessageParser}
						actionProvider={ActionProvider}
						messageHistory={messageHistory}
					/>
				</>
			</div>
		)
	);
};

export { PEChatbot };
