import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";

interface StatusIndicatorProps {
	isActive: boolean;
	activeTooltip?: string;
	inactiveTooltip?: string;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
	isActive,
	activeTooltip = "The location is currently active as there has been a submission in the last 30 days.",
	inactiveTooltip = "The location is currently inactive. Create a submission to activate it.",
}) => {
	const statusText = isActive ? "Active" : "Inactive";
	const tooltipText = isActive ? activeTooltip : inactiveTooltip;
	const iconStyle = {
		fontSize: "1rem",
		marginRight: "0.5rem",
		color: isActive ? "green" : "red",
	};

	return (
		<div className="status-indicator d-flex align-items-center">
			<Tooltip title={tooltipText}>
				{isActive ? (
					<CheckCircleFilled style={iconStyle} />
				) : (
					<CloseCircleFilled style={iconStyle} />
				)}
			</Tooltip>
			<Typography.Text className="fw-bold">{statusText}</Typography.Text>
		</div>
	);
};
