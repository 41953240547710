import PlansImgLeft from "@assets/img/plans-img2.svg";
import PlansImgRight from "@assets/img/surveillance.svg";
import { Button } from "reactstrap";
import PricingModal from "./PricingModal";

const PlansPage = () => {
	return (
		<div className="plans">
			<div className="container">
				<div className="row">
					<header className="plans__title--wrapper">
						<h1 className="plans__title">
							The Future of Facilities Management
						</h1>
						<h4 className="sub-title text-center">
							There&apos;s no easier way to manage the lifecycle of your assets,{" "}
							<i>intelligently</i>.
						</h4>
						<Button size="lg" className="btn">
							START FREE TRIAL
						</Button>
						<PricingModal />
						<img
							className="header__img header__img--right"
							src={PlansImgRight}
							alt="plans"
						/>
						<img
							className="header__img header__img--left"
							src={PlansImgLeft}
							alt="plans"
						/>
					</header>
				</div>
			</div>
		</div>
	);
};

export { PlansPage };
