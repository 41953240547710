import { getLifespanChartData } from "@services/chart-service";
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Loader } from "../../Loader/Loader";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
);

const LifespanChart = ({ org, assetType, assetId }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [chartData, setChartData] = useState({
		labels: [1, 2, 3, 4],
		datasets: [
			{
				label: assetType,
				data: [],
				backgroundColor: "rgba(255, 150, 132, 0.2)",
				borderColor: "rgba(255, 99, 132, 1)",
			},
		],
	});

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Remaining Useful Life in Years",
				},
			},
			y: {
				title: {
					display: true,
					text: "Number of Assets",
				},
			},
		},
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getLifespanChartData(org, assetType, assetId);

			const labelsArr = Object.keys(response.chartData).map((key) => key);
			const valuesArr = Object.values(response.chartData).map((value) => value);
			const pointRadiusArr = new Array(
				Object.keys(response.chartData).length,
			).fill(0);

			if (response.chartData.Expired) {
				const expiredKey = labelsArr.pop();
				labelsArr.unshift(expiredKey);
				const expiredValue = valuesArr.pop();
				valuesArr.unshift(expiredValue);
			}

			// show a dot where this asset is on the graph
			const thisAssetIndex = labelsArr.indexOf(response.thisAsset.toString());
			pointRadiusArr[thisAssetIndex] = 6;

			setChartData({
				labels: labelsArr,
				datasets: [
					{
						label: assetType,
						data: valuesArr,
						borderColor: "#250245",
						fill: false,
						tension: 0.2,
						borderWidth: 3,
						pointRadius: pointRadiusArr,
						pointBackgroundColor: "#228b22",
						pointBorderColor: "#250245",
						pointBorderWidth: 2,
					},
				],
			});
			setIsLoading(false);
		}
		fetchData();
	}, []);

	return isLoading ? <Loader /> : <Line data={chartData} options={options} />;
};

export { LifespanChart };
