import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { getRemainingUsefulLifeVerbiage } from "@/utils/getRemainingUsefulLifeVerbiage";
import { isUnknownValue } from "@/utils/isUnknownValue";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { AssetsTable } from "./AssetsTable";
import { PredSpendBubbleChart } from "./PredSpendBubbleChart";
import { YearBudgetInsights } from "./YearBudgetInsights";

ChartJS.register(ArcElement, Tooltip, Legend);

const YearPieChart = ({ state, chartState, strategyAssetsRef }) => {
	const { budget, originalAssets } = state;
	const {
		selectedYear,
		chartLoading,
		assetTypeColors,
		strategy,
		legendSelectedAssetTypes,
	} = chartState;

	const getYearAssets = () => {
		let result;
		if (strategy.value === "Cumulative Do Nothing") {
			result = originalAssets.filter(
				(asset) => asset.replacementYear <= selectedYear,
			);
		} else if (
			strategy.value == "Best Fit to Budget" ||
			strategy.value == "Exact Fit to Budget"
		) {
			result = strategyAssetsRef.current.filter(
				(asset) => asset.replacementYear === selectedYear,
			);
		} else {
			result = originalAssets.filter(
				(asset) => asset.replacementYear === selectedYear,
			);
		}
		result = result.filter((asset) =>
			legendSelectedAssetTypes.includes(asset.assetType),
		);
		return result;
	};
	const assets = getYearAssets();

	// get unique asset types
	let labels = new Set(assets.map((asset) => asset?.assetType));
	labels = Array.from(labels);

	const [selectedAssetType, setSelectedAssetType] = useState(null);

	let totalReplCost = assets.reduce((acc, asset) => {
		const replCost = Number(asset?.avgReplCostUSD);
		if (Number.isNaN(replCost)) {
			return acc;
		}
		return acc + replCost;
	}, 0);

	// convert to dollars
	totalReplCost = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	}).format(totalReplCost);

	const truncate = (str, n) => {
		return str.length > n ? `${str.substr(0, n - 1)}...` : str;
	};

	function calculateUsefulLifeRemainingDays(asset) {
		const { dateOfBirth, avgUsefulLifeYears } = asset;

		// Convert avgUsefulLifeYears to days
		const avgUsefulLifeDays = avgUsefulLifeYears * 365;

		// Parse dateOfBirth and calculate the difference in days from today
		const dob = new Date(dateOfBirth);
		const today = new Date();
		const daysSinceBirth = Math.floor((today - dob) / (1000 * 60 * 60 * 24));

		// Calculate remaining useful life in days
		const remainingUsefulLifeDays = avgUsefulLifeDays - daysSinceBirth;

		// Return remaining days (or 0 if asset has exceeded useful life)
		return Math.max(remainingUsefulLifeDays, 0);
	}

	const assetsTableArr = assets.map((asset) => {
		if (isUnknownValue(asset.model)) asset.model = "N/A";
		if (isUnknownValue(asset.make)) asset.make = "N/A";
		let assetLabel = `${asset.make}: ${asset.model}`;
		assetLabel = truncate(assetLabel, 20);
		const dob = asset.dateOfBirth || asset.estDateOfBirth;
		const label = getRemainingUsefulLifeVerbiage(
			asset.avgUsefulLifeYears,
			dob,
			asset.condition,
		);

		return {
			...asset,
			make: assetLabel,
			usefulLifeRemaining: {
				label,
				value: calculateUsefulLifeRemainingDays(asset),
			},
		};
	});

	return chartLoading ? (
		<Loader />
	) : (
		<div className="p-0 w-100 h-100 position-relative">
			<Row style={{ height: "37%" }}>
				<Col className="h-100">
					<PredSpendBubbleChart
						assets={assets}
						assetTypeColors={assetTypeColors}
						selectedYear={selectedYear}
					/>
				</Col>
				<Col className="h-100 position-relative p-0">
					<YearBudgetInsights
						selectedYear={selectedYear}
						assets={assets}
						budget={budget}
					/>
				</Col>
			</Row>
			<Row style={{ height: "63%" }}>
				<AssetsTable assets={assetsTableArr} />
			</Row>
		</div>
	);
};

export { YearPieChart };
