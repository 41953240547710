import { mountStoreDevtool } from "simple-zustand-devtools";
import { type StoreApi, type UseBoundStore, create } from "zustand";

interface FormStore {
	assetMapNewAsset: any;
	setAssetMapNewAsset: (assetMapNewAsset: any) => void;
	assetMapDimensions: any;
	setAssetMapDimensions: (assetMapDimensions: any) => void;
	assetTagScanned: boolean;
	setAssetTagScanned: (assetTagScanned: any) => void;
	flags: any;
	setFlags: (flags: any) => void;
	alertModal: any;
	setAlertModal: (alertModal: any) => void;
	selectedPhotoUrl: any;
	setSelectedPhotoUrl: (selectedPhotoUrl: any) => void;
	isPhotoShowing: boolean;
	setIsPhotoShowing: (isPhotoShowing: any) => void;
	selectedItem: any;
	setSelectedItem: (selectedItem: any) => void;
	refToOpen: any;
	setRefToOpen: (refToOpen: any) => void;
	assetTypes: any;
	setAssetTypes: (assetTypes: any) => void;
	equipmentAreas: any;
	setEquipmentAreas: (equipmentAreas: any) => void;
	manufacturerError: boolean;
	setManufacturerError: (manufacturerError: any) => void;
	assetTagError: boolean;
	setAssetTagError: (assetTagError: any) => void;
	fieldError: any;
	setFieldError: (fieldName: any, errorMessage: any) => void;
	tempAlertError: boolean;
	setTempAlertError: (tempAlertError: any) => void;
	submitDisabled: boolean;
	setSubmitDisabled: (submitDisabled: boolean) => void;
	isEditing: boolean;
	setIsEditing: (isEditing: boolean) => void;
	isEditingCushman: boolean;
	setIsEditingCushman: (isEditingCushman: boolean) => void;
	isQA: boolean;
	setIsQA: (isQA: boolean) => void;
	savedLocation: any;
	setSavedLocation: (savedLocation: any) => void;
	savedLocationCushman: any;
	setSavedLocationCushman: (savedLocationCushman: any) => void;
	chosenAssetType: any;
	setChosenAssetType: (chosenAssetType: any) => void;
	photoToBeCropped: any;
	setPhotoToBeCropped: (photoToBeCropped: any) => void;
	isCropShowing: boolean;
	setIsCropShowing: (isCropShowing: boolean) => void;
	ocrConfidenceAndColor: any;
	setOcrConfidenceAndColor: (ocrConfidenceAndColor: any) => void;
	showDraftSelectionModal: boolean;
	setShowDraftSelectionModal: (showDraftSelectionModal: boolean) => void;
	showDraftSelectionModalCushman: boolean;
	setShowDraftSelectionModalCushman: (
		showDraftSelectionModalCushman: boolean,
	) => void;
	startNew: boolean;
	setStartNew: (startNew: boolean) => void;
	startNewCushman: boolean;
	setStartNewCushman: (startNewCushman: boolean) => void;
	isEditingRaisingCanes: boolean;
	setIsEditingRaisingCanes: (isEditingRaisingCanes: boolean) => void;
	draftId?: string | number;
	setDraftId: (draftId: string | number | undefined) => void;
	airportCode?: string | number;
	setAirportCode: (airportCode: any) => void;
	assetTag: string | null;
	setAssetTag: (assetTag: string | null) => void;
	assetTagUrl: string | null;
	setAssetTagUrl: (assetTagUrl: string | null) => void;
	geoLoading: boolean;
	setGeoLoading: (geoLoading: boolean) => void;
}

export const useFormStore: UseBoundStore<StoreApi<FormStore>> = create(
	(set) => ({
		assetMapNewAsset: null,
		setAssetMapNewAsset: (assetMapNewAsset: any) => set({ assetMapNewAsset }),
		assetMapDimensions: {},
		setAssetMapDimensions: (assetMapDimensions: any) =>
			set({ assetMapDimensions }),
		assetTagScanned: false,
		setAssetTagScanned: (assetTagScanned: any) => set({ assetTagScanned }),
		flags: {},
		setFlags: (flags: any) => set({ flags }),
		alertModal: {
			show: false,
			title: "",
			body: "",
			options: [],
		},
		setAlertModal: (alertModal: any) => set({ alertModal }),
		selectedPhotoUrl: "",
		setSelectedPhotoUrl: (selectedPhotoUrl: any) => set({ selectedPhotoUrl }),
		isPhotoShowing: false,
		setIsPhotoShowing: (isPhotoShowing: any) => set({ isPhotoShowing }),
		selectedItem: {},
		setSelectedItem: (selectedItem: any) => set({ selectedItem }),
		refToOpen: {},
		setRefToOpen: (refToOpen: any) => set({ refToOpen }),
		assetTypes: [],
		setAssetTypes: (assetTypes: any) => set({ assetTypes }),
		equipmentAreas: [],
		setEquipmentAreas: (equipmentAreas: any) => set({ equipmentAreas }),
		manufacturerError: false,
		setManufacturerError: (manufacturerError: any) =>
			set({ manufacturerError }),
		assetTagError: false,
		setAssetTagError: (assetTagError: any) => set({ assetTagError }),
		fieldError: {},
		setFieldError: (fieldName: string, errorMessage: string) =>
			set((state: { fieldError: Record<string, string> }) => ({
				fieldError: {
					...state.fieldError,
					[fieldName]: errorMessage,
				},
			})),
		tempAlertError: false,
		setTempAlertError: (tempAlertError: any) => set({ tempAlertError }),
		submitDisabled: false,
		setSubmitDisabled: (submitDisabled: any) => set({ submitDisabled }),
		isEditing: false,
		setIsEditing: (isEditing: any) => set({ isEditing }),
		isEditingCushman: false,
		setIsEditingCushman: (isEditingCushman: any) => set({ isEditingCushman }),
		isQA: false,
		setIsQA: (isQA: any) => set({ isQA }),
		savedLocation: null,
		setSavedLocation: (savedLocation: any) => set({ savedLocation }),
		savedLocationCushman: null,
		setSavedLocationCushman: (savedLocationCushman: any) =>
			set({ savedLocationCushman }),
		chosenAssetType: null,
		setChosenAssetType: (chosenAssetType: { [x: string]: any }) =>
			set({ chosenAssetType }),
		photoToBeCropped: null,
		setPhotoToBeCropped: (photoToBeCropped: any) => set({ photoToBeCropped }),
		isCropShowing: false,
		setIsCropShowing: (isCropShowing: any) => set({ isCropShowing }),
		ocrConfidenceAndColor: {},
		setOcrConfidenceAndColor: (ocrConfidenceAndColor: any) =>
			set({ ocrConfidenceAndColor }),
		showDraftSelectionModal: false,
		setShowDraftSelectionModal: (showDraftSelectionModal: any) =>
			set({ showDraftSelectionModal }),
		showDraftSelectionModalCushman: false,
		setShowDraftSelectionModalCushman: (showDraftSelectionModalCushman: any) =>
			set({ showDraftSelectionModalCushman }),
		startNew: false,
		setStartNew: (startNew: any) => set({ startNew }),
		startNewCushman: false,
		setStartNewCushman: (startNewCushman: any) => set({ startNewCushman }),
		draftId: undefined,
		setDraftId: (draftId: any) => set({ draftId }),
		airportCode: null,
		setAirportCode: (airportCode: any) => set({ airportCode }),
		assetTag: null,
		setAssetTag: (assetTag: any) => set({ assetTag }),
		assetTagUrl: null,
		setAssetTagUrl: (assetTagUrl: string | null) => set({ assetTagUrl }),
		isEditingRaisingCanes: false,
		setIsEditingRaisingCanes: (isEditingRaisingCanes: any) =>
			set({ isEditingRaisingCanes }),

		geoLoading: false,
		setGeoLoading: (geoLoading: any) => set({ geoLoading }),
	}),
);

if (process.env.NODE_ENV === "development") {
	mountStoreDevtool("formStore", useFormStore);
}
