import { auth } from "@assets/services/auth-service";

/**
 * Adds a 'hint' section to the request body which helps the backend
 * @param body the request body
 * @param tag the input tag
 * @param org organization
 */
const addTag = (body: FormData, tag: string, org: string) => {
	if (tag === "tempAlertId") {
		if (org === "cvs.com") {
			body.append("hint", "tempalert");
		} else if (org === "DIGI") {
			body.append("hint", "digi_tempalert");
		}
	}
};

export const scanImage = async (
	buffer: BlobPart | null,
	type: string | Blob,
	tag: string,
	flags: any,
	org: any,
) => {
	const url = `${import.meta.env.VITE_ALFRED_SERVICE_URL}BlobDecode/`;
	console.log("scanImage", url);
	const controller = new AbortController();
	const timeoutId = setTimeout(() => controller.abort(), 30000);
	if (buffer) {
		const blob = new Blob([buffer], { type: "image/webp" });
		console.log("blob", blob);
		const body = new FormData();
		body.append("file", blob);
		if (tag === "assetTag" && flags?.allowBarcodeForAssetTag) {
			console.log("allowBarcodeForAssetTag");
			body.append("scan_type", "barcode");
		} else {
			body.append("scan_type", type);
		}
		addTag(body, tag, org);
		try {
			const response = await fetch(url, {
				signal: controller.signal,
				method: "POST",
				body,
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			if (response.ok) {
				const res = await response.json();
				if (res?.results && res.results.length > 0) {
					clearTimeout(timeoutId);
					return res.results;
				}
				clearTimeout(timeoutId);
				const error = new Error();
				error.message = "Scan succeeded but returned nothing";
				throw error;
			}
			clearTimeout(timeoutId);
			const error = new Error();
			error.message = `Scan failed with status: ${response.status}`;
			throw error;
		} catch (e: any) {
			if (e.message === "The user aborted a request.") {
				const error = new Error();
				error.message = "Server timed out";
				throw error;
			}
			clearTimeout(timeoutId);
			throw e;
		}
	} else {
		clearTimeout(timeoutId);
		const error = new Error();
		error.message = "No file selected";
		throw error;
	}
};
