import { useEffect, useRef, useState } from "react";
import { Circle, Image, Layer, Line, Rect, Stage } from "react-konva";
import { Button } from "reactstrap";

const Perspective = ({ responseData, setResponseData }) => {
	const stageRef = useRef(null);
	const [image] = useState(new window.Image());
	const [anchors, setAnchors] = useState([]);
	const [anchorMetadata, setAnchorMetadata] = useState(null);
	const [polygonPoints, setPolygonPoints] = useState([]);
	const [imageDims, setImageDims] = useState({ width: 800, height: 800 });

	useEffect(() => {
		if (responseData.rotatedImage) {
			image.onload = () => {
				const dims = calculateAspectRatioFit(
					image.width,
					image.height,
					1000,
					1000,
				);
				var newHeight = dims.height;
				var newWidth = dims.width;
				var xShift = (1000 - newWidth) / 2;
				var yShift = (1000 - newHeight) / 2;
				setAnchorMetadata({
					xShift: xShift,
					yShift: yShift,
					width: newWidth,
					height: newHeight,
				});
				setImageDims(dims);
				setupFourPoints(dims.width, dims.height, dims.xShift, dims.yShift);
			};
			image.src = responseData.rotatedImage;
		}
	}, [responseData.rotatedImage, image]);

	useEffect(() => {
		setResponseData((prevData) => ({
			...prevData,
			alignedImage: responseData.rotatedImage,
		}));
	}, []);

	useEffect(() => {
		console.log("responseUpdate!");
		console.log(responseData);
	}, [responseData.alignedImage, responseData.points]);

	const createAlignedImage = () => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const img = new window.Image();

		img.onload = () => {
			// Set canvas size equal to image size
			canvas.width = img.width;
			canvas.height = img.height;

			const [x1, y1, x2, y2, x3, y3, x4, y4] = responseData.points;

			ctx.drawImage(img, 0, 0);

			ctx.beginPath();

			const pair1 = {
				x: ((x1 - anchorMetadata.xShift) / anchorMetadata.width) * canvas.width,
				y:
					((y1 - anchorMetadata.yShift) / anchorMetadata.height) *
					canvas.height,
			};

			const pair2 = {
				x: ((x2 - anchorMetadata.xShift) / anchorMetadata.width) * canvas.width,
				y:
					((y2 - anchorMetadata.yShift) / anchorMetadata.height) *
					canvas.height,
			};

			const _pair3 = {
				x: ((x3 - anchorMetadata.xShift) / anchorMetadata.width) * canvas.width,
				y:
					((y3 - anchorMetadata.yShift) / anchorMetadata.height) *
					canvas.height,
			};

			const pair4 = {
				x: ((x4 - anchorMetadata.xShift) / anchorMetadata.width) * canvas.width,
				y:
					((y4 - anchorMetadata.yShift) / anchorMetadata.height) *
					canvas.height,
			};

			// ctx.moveTo(pair1.x,pair1.y);
			// ctx.lineTo(pair1.x,pair1.y);
			// ctx.lineTo(pair2.x,pair2.y);
			// ctx.lineTo(pair3.x,pair3.y);
			// ctx.lineTo(pair4.x,pair4.y);
			// ctx.lineTo(pair1.x,pair1.y);
			// ctx.clip();
			//
			// ctx.lineWidth = 7;
			// ctx.strokeStyle = 'black';
			// ctx.stroke();
			// ctx.closePath()

			//Calculate height / width
			const test_width = pair2.x - pair1.x;
			const test_height = pair4.y - pair1.y;
			//Make a Canvas to copy the data you would like to download to
			var hidden_canv = document.createElement("canvas");
			hidden_canv.width = test_width;
			hidden_canv.height = test_height;

			//Draw the data you want to download to the hidden canvas
			var hidden_ctx = hidden_canv.getContext("2d");
			hidden_ctx.drawImage(
				canvas,
				pair1.x, //Start Clipping
				pair1.y, //Start Clipping
				test_width, //Clipping Width
				test_height, //Clipping Height
				0, //Place X
				0, //Place Y
				hidden_canv.width, //Place Width
				hidden_canv.height, //Place Height
			);

			//console.log(hidden_canv.toDataURL("image/png").replace("image/png", "image/octet-stream"))
			// ctx.rect(0, 0, 200, 100);
			// ctx.fillStyle = 'yellow';
			// ctx.fill();
			// ctx.lineWidth = 7;
			// ctx.strokeStyle = 'black';
			// ctx.stroke();

			// Convert canvas to image URL and update state
			// Convert canvas to a Blob URL
			hidden_canv.toBlob((blob) => {
				const imageURL = URL.createObjectURL(blob);
				setResponseData((prevData) => ({
					...prevData,
					alignedImage: imageURL,
				}));
			}, "image/png");
		};

		img.src = responseData.rotatedImage; // Make sure this is the correctly rotated image
	};

	const calculateAspectRatioFit = (
		srcWidth,
		srcHeight,
		maxWidth,
		maxHeight,
	) => {
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
		return {
			width: srcWidth * ratio,
			height: srcHeight * ratio,
			xShift: (maxWidth - srcWidth * ratio) / 2,
			yShift: (maxHeight - srcHeight * ratio) / 2,
		};
	};

	const setupFourPoints = (width, height, xShift, yShift) => {
		const points = [
			{ x: xShift + 0.1 * width, y: yShift + 0.1 * height },
			{ x: xShift + 0.9 * width, y: yShift + 0.1 * height },
			{ x: xShift + 0.9 * width, y: yShift + 0.9 * height },
			{ x: xShift + 0.1 * width, y: yShift + 0.9 * height },
		];
		setPolygonPoints(points.flatMap((p) => [p.x, p.y]));

		const anchorsSetup = points.map((point, index) => ({
			x: point.x,
			y: point.y,
			id: index,
		}));
		setAnchors(anchorsSetup);
		setResponseData({
			...responseData,
			points: points.flatMap((p) => [p.x, p.y]),
		});
	};

	const handleDragMove = (index) => (e) => {
		const newAnchors = anchors.map((anchor, idx) => {
			if (idx === index) {
				return { ...anchor, x: e.target.x(), y: e.target.y() };
			}
			return anchor;
		});
		setAnchors(newAnchors);
		updatePolygon(newAnchors);
	};

	const updatePolygon = (newAnchors) => {
		const newPoints = newAnchors.flatMap((anchor) => [anchor.x, anchor.y]);
		setPolygonPoints(newPoints);
		console.log(newPoints);
		setResponseData({ ...responseData, points: newPoints });
	};

	const generateAlignedImage = () => {
		createAlignedImage();
	};

	return (
		<div>
			<Button onClick={generateAlignedImage}>Set alignment</Button>
			<Stage ref={stageRef} width={1000} height={1000}>
				<Layer>
					<Rect width={800} height={800} fill="rgb(240, 240, 240)" />
					<Image
						image={image}
						x={imageDims.xShift}
						y={imageDims.yShift}
						width={imageDims.width}
						height={imageDims.height}
					/>
					<Line
						points={polygonPoints}
						fill="rgba(255, 0, 0, 0.3)"
						stroke="red"
						strokeWidth={2}
						closed
					/>
					{anchors.map((anchor, index) => (
						<Circle
							key={index}
							x={anchor.x}
							y={anchor.y}
							stroke="rgba(255, 0, 255, 0.9)"
							strokeWidth={2}
							radius={10}
							draggable
							onDragMove={handleDragMove(index)}
						/>
					))}
				</Layer>
			</Stage>
		</div>
	);
};

export { Perspective };
