import { getIntegrationById } from "@/services/integration-service";
import { useEffect, useState } from "react";
import { Alert, Container, Spinner, Tab, Tabs } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

export const routeMap = {
	1: "service-channel",
} as any;

export const integrationMap = {
	"service-channel": "1",
} as any;

const formatTitle = (route: string) => {
	return route
		.split("-")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

const IntegrationLayout = ({ userOrgData }: any) => {
	const { integrationType } = useParams() as any;
	const [integration, setIntegration] = useState<any>(null);
	const [integrationSettings, setIntegrationSettings] = useState<any>(null);
	const [enabledRoutes, setEnabledRoutes] = useState<any>([]);
	const [loading, setLoading] = useState<any>(true);
	const [error, setError] = useState<any>(null);
	const navigate = useNavigate() as any;
	const location = useLocation() as any;

	useEffect(() => {
		if (!userOrgData?.config?.integration_settings) {
			// Prevent running the effect if userOrgData is not ready
			return;
		}

		const fetchIntegration = async () => {
			try {
				setLoading(true);
				const integrationId =
					userOrgData?.config?.integration_settings?.[
						integrationMap[integrationType]
					]?.id;
				if (!integrationId) {
					throw new Error("Integration ID is not defined");
				}
				const fetchedIntegration = await getIntegrationById(integrationId);
				setIntegration(fetchedIntegration);
			} catch (_err) {
				setError("Failed to load integration data.");
			} finally {
				setLoading(false);
			}
		};

		fetchIntegration();
	}, [
		integrationType,
		location,
		userOrgData,
		userOrgData?.config?.integration_settings,
	]);

	useEffect(() => {
		if (!userOrgData?.config?.integration_settings) {
			// Prevent running the effect if userOrgData is not ready
			return;
		}

		const settings =
			userOrgData?.config?.integration_settings?.[
				integrationMap[integrationType]
			];
		if (settings?.isEnabled || settings?.is_enabled) {
			setIntegrationSettings(settings);
			const enabledRoutes = settings?.uiConfig?.enabledRoutes || [];
			setEnabledRoutes(enabledRoutes);
		}
	}, [userOrgData, integrationType]);

	const getActiveKey = () => {
		const path = location.pathname.split("/").pop();
		return enabledRoutes.includes(path) ? path : enabledRoutes[0];
	};

	if (loading || !integration || !integrationSettings) {
		return (
			<Container className="d-flex justify-content-center align-items-center min-vh-100">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</Container>
		);
	}

	if (error) {
		return (
			<Container className="mx-2 w-100">
				<Alert variant="danger" className="text-center">
					{error}
				</Alert>
			</Container>
		);
	}

	if (integrationSettings && !integrationSettings?.is_enabled) {
		return (
			<Container className="mx-2 w-100">
				<Alert variant="warning" className="text-center">
					Integration not enabled or not found.
				</Alert>
			</Container>
		);
	}

	if (integrationSettings && !integrationSettings?.credentials) {
		return (
			<Container className="mx-2 w-100">
				<Alert variant="warning" className="text-center">
					No credentials found.
				</Alert>
			</Container>
		);
	}

	return (
		<div>
			<h2 className="mb-4">{integration?.name || "Integration"}</h2>
			{enabledRoutes.length > 0 && (
				<Tabs
					activeKey={getActiveKey()}
					id={`${integrationType}-tabs`}
					className="mb-3"
					onSelect={(k) => navigate(`/integration/${integrationType}/${k}`)}
				>
					{enabledRoutes.map((route: string) => (
						<Tab key={route} eventKey={route} title={formatTitle(route)} />
					))}
				</Tabs>
			)}
			<Outlet />
		</div>
	);
};

export { IntegrationLayout };
