import Konva from "konva";
import { useEffect, useRef, useState } from "react";
import { Layer, Rect, Stage } from "react-konva";

const BoundingBoxes = ({ responseData, setResponseData }) => {
	const stageRef = useRef(null);
	const [image] = useState(new window.Image());

	useEffect(() => {
		const selected_img = responseData.alignedImage;
		if (selected_img) {
			image.onload = () => {
				const dims = calculateAspectRatioFit(
					image.width,
					image.height, //todo maybe not hardcode? was image.height before
					1000,
					1000,
				);
				setResponseData({ ...responseData, dims: dims });
				setupStage(image, dims);
			};
			image.src = selected_img;
		}
	}, [responseData.alignedImage]);

	useEffect(() => {
		console.log("Repoinsedata updated:");
		console.log(responseData);
	}, [responseData]);

	const calculateAspectRatioFit = (
		srcWidth,
		srcHeight,
		maxWidth,
		maxHeight,
	) => {
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
		return {
			width: srcWidth * ratio,
			height: srcHeight * ratio,
			xShift: (maxWidth - srcWidth * ratio) / 2,
			yShift: (maxHeight - srcHeight * ratio) / 2,
		};
	};

	const setupStage = (loadedImage, dims) => {
		const stage = stageRef.current.getStage();
		stage.height();
		const layer = new Konva.Layer();
		stage.add(layer);

		const background = new Konva.Rect({
			x: 0,
			y: 0,
			fill: "rgba(240, 240, 240, 1)",
			width: 1200,
			height: 1000,
		});
		layer.add(background);

		const konvaImage = new Konva.Image({
			x: 0, //dims.xShift,
			y: 0, //dims.yShift,
			image: loadedImage,
			width: dims.width,
			height: dims.height,
		});
		layer.add(konvaImage);

		responseData.bboxes.forEach((bbox) => {
			addBox(bbox, layer, dims.xShift, dims.yShift);
		});
		layer.draw();
	};

	const addBox = (bbox, layer, _xShift, _yShift) => {
		const group = new Konva.Group({
			x: bbox.x, // + xShift,
			y: bbox.y, // + yShift,
			draggable: true,
		});

		const box = new Konva.Rect({
			width: bbox.width,
			height: bbox.height,
			fill: "rgba(0, 0, 255, 0.3)",
		});

		const text = new Konva.Text({
			text: bbox.key,
			fontSize: 12,
			fontFamily: "Roboto",
			fill: "white",
			width: 150,
			padding: 5,
			align: "center",
		});

		const textBackground = new Konva.Rect({
			x: 0,
			y: 0,
			width: text.width(),
			height: text.height(),
			fill: "rgba(0, 0, 0, 0.6)",
		});

		group.add(box, textBackground, text);
		layer.add(group);

		const transformer = new Konva.Transformer({
			nodes: [group],
			rotateEnabled: false,
			keepRatio: false,
			enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"],
		});
		layer.add(transformer);

		group.on("dragend transformend", () => {
			updateBBoxState(group, bbox.key);
		});
	};

	const updateBBoxState = (group, key) => {
		setResponseData((prevState) => {
			const updatedBboxes = prevState.bboxes.map((b) =>
				b.key === key
					? {
							...b,
							x: group.x() - group.parent.x(),
							y: group.y() - group.parent.y(),
							width: group.children[0].width() * group.scaleX(),
							height: group.children[0].height() * group.scaleY(),
						}
					: b,
			);
			return { ...prevState, bboxes: updatedBboxes };
		});
	};

	return (
		<Stage ref={stageRef} width={1200} height={1000}>
			<Layer>
				{/*<Rect width={800} height={800} fill='rgb(240, 240, 240)' />*/}
				<Rect fill="rgb(240, 240, 240)" />
			</Layer>
		</Stage>
	);
};

export { BoundingBoxes };
