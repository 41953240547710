import { formatMillions } from "@/utils/formatNumberToMillions";
import logo from "@assets/img/FacilityAI_Logo_Mini.svg";

const BudgetNotes = ({ state, chartState, dispatch }) => {
	const { chartAssets: assets, budget, years } = state;
	const { reactivePremiumData, strategy, leftover, selectedYear } = chartState;

	const calculateWeightedAverage = () => {
		const totalReplCost = assets.reduce((acc, asset) => {
			const replCost = Number(asset?.avgReplCostUSD);
			if (Number.isNaN(replCost)) {
				return acc;
			}
			return acc + replCost;
		}, 0);
		return Math.floor(totalReplCost / assets.length).toLocaleString();
	};

	return (
		<>
			<h6 className="fw-bold m-0">
				<img src={logo} style={{ width: "40px", marginRight: "6px" }} />
				{selectedYear && selectedYear} Budget Insights:
			</h6>
			{assets.length > 0 && (
				<ul style={{ marginLeft: "92px", marginBottom: "0" }}>
					<li>
						<strong>{strategy.description}</strong>
					</li>
					<li>
						<strong>{assets.length.toLocaleString()} </strong>
						Total Assets
					</li>
					<li>
						<strong>
							{"$"}
							{assets.length > 0 && calculateWeightedAverage()}{" "}
						</strong>
						Average Replacement Cost Per Asset
					</li>
					<li>
						<strong>
							{assets.length > 0
								? formatMillions(
										Math.floor(
											assets.reduce((acc, asset) => {
												const replCost = Number(asset?.avgReplCostUSD);
												if (Number.isNaN(replCost)) {
													return acc;
												}
												return acc + replCost;
											}, 0),
										),
										1,
									)
								: "0"}{" "}
						</strong>
						Total Budget Required
						{budget?.every((val) => !!val) && (
							<>
								{" "}
								(vs{" "}
								<strong>
									{formatMillions(
										budget.reduce((a, c) => {
											return a + c;
										}),
										1,
									)}{" "}
									Current Budget, {years} Years)
								</strong>
							</>
						)}
					</li>
					{reactivePremiumData && (
						<li>
							<strong>
								{strategy.value !== "Cumulative Do Nothing"
									? formatMillions(
											reactivePremiumData.reduce((a, c) => a + c, 0),
										)
									: formatMillions(
											reactivePremiumData[reactivePremiumData.length - 1],
										)}
							</strong>{" "}
							Total Reactive Replacement Premium
						</li>
					)}
					{budget.every((val) => !!val) && (
						<li>
							<span style={{ color: "#32a852" }}>
								$0.0M Favorable to budget
							</span>
							<span style={{ color: "#bf1e2d" }} className="mx-2">
								($0.0M) Unfavorable to budget
							</span>
						</li>
					)}
					{leftover && (
						<li>
							<strong
								style={{
									color: leftover.count > 0 ? "red" : "",
								}}
							>
								{leftover.count.toLocaleString()} assets
							</strong>{" "}
							kicked out of budget -{" "}
							<strong
								style={{
									color: leftover.count > 0 ? "red" : "",
								}}
							>
								{formatMillions(leftover.cost)} Total Cost
							</strong>
						</li>
					)}
				</ul>
			)}
		</>
	);
};

export { BudgetNotes };
