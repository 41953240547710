import { usePageStore, useUserStore } from "@stores";
import { useEffect, useRef, useState } from "react";
import { IoCamera } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import styles from "../BotChatMessage.module.css";
import { identifyAsset } from "../utils/identifyAsset";

const InitialMessageOptions = ({
	actionProvider,
	state: { showInitialOptions, assetTypes },
}) => {
	const {
		themeMode,
		user: { useCameraOnPhotoCapture },
		authInfo: { org: organization },
	} = useUserStore();

	const fileInputRef = useRef(null);

	const [alertModal, setAlertModal] = useState({
		show: false,
		title: "",
		body: "",
	});

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	const [actionQueue, setActionQueue] = useState([]);
	const [clicked, setClicked] = useState(false);

	const { isSidebarOpen } = usePageStore();

	const isMobile = window.innerWidth < 768;

	useEffect(() => {
		if (actionQueue.length > 0) {
			const [nextAction, ...remainingActions] = actionQueue;

			if (!nextAction.isActionExecuted) {
				const { question } = nextAction;

				if (question === "Start Data Capture") {
					actionProvider.startDataCaptureAction();
				}

				nextAction.isActionExecuted = true;
			}

			// Delay the removal to ensure the action is completed
			// This is a simplistic approach; adjust based on your action's nature
			setTimeout(() => {
				console.log("Removing action:", nextAction);
				setActionQueue(remainingActions);
			}, 1000);
		}
	}, [actionQueue, actionProvider]);

	const assetQuestions = [
		"How is my Asset Health determined?",
		"How do I improve the asset health?",
		"How do I decommission an asset?",
	];

	const orgQuestions = [
		"Start Data Capture",
		"Identify an asset",
		"Which location has my weakest assets?",
	];

	const locationQuestions = [
		"What are my weakest assets?",
		"How many assets do I need to replace this year?",
		"What is the estimated cost to replace the assets that need replacing?",
	];

	const params = useParams();

	const initialQs = params["*"].includes("asset")
		? assetQuestions
		: params["*"].includes("locations")
			? locationQuestions
			: orgQuestions;

	const handleOption = async (e, classifier) => {
		e.preventDefault();
		if (e.target.innerText === "Start Data Capture") {
			console.log("Starting data capture");
			await actionProvider.startDataCaptureAction();
		} else {
			actionProvider.handleOptionSelectionAction(
				e.target.innerText,
				classifier,
			);
		}
	};

	const messageContainerStyleClasses =
		themeMode !== "dark"
			? styles.optionContainer
			: styles.messageContainer_dark;
	const messageTextStyleClasses =
		themeMode !== "dark" ? styles.messageText : styles.messageText_dark;

	return (
		showInitialOptions &&
		!clicked && (
			<>
				<div className="d-flex flex-column align-items-end">
					{initialQs.map((question, id) => (
						<div
							key={id}
							onClick={async (e) => {
								if (question === "Identify an asset") return;
								e.preventDefault();
								setClicked(true);
								return handleOption(
									e,
									orgQuestions.includes(question) ||
										locationQuestions.includes(question)
										? "lookup"
										: "",
								);
							}}
							className={`border ${messageContainerStyleClasses} ${
								question === "Start Data Capture"
									? styles["optionContainer-border-red"]
									: ""
							}`}
							style={{
								marginBottom: "0.5em",
								marginTop: question === "Identify an asset" ? "3px" : "",
								cursor: "pointer",
								// borderRadius: '20px'
								height: question === "Identify an asset" ? "34px" : "",
								backgroundColor:
									question === "Start Data Capture" ? "#92278F" : "",
							}}
						>
							{question !== "Identify an asset" ? (
								<span
									className={messageTextStyleClasses}
									style={{
										color: question === "Start Data Capture" ? "white" : "",
									}}
								>
									{question}
								</span>
							) : (
								<>
									<span
										style={{
											position: "relative",
											bottom: "12px",
											left: "-30px",
											borderRadius: "50%",
											width: "25px",
											// height: '25px',
											backgroundColor: "rgb(242, 242, 242)",
											border: "1px solid rgb(37, 2, 69)",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<span
											style={{
												position: "relative",
												top: "-1px",
											}}
										>
											<IoCamera />
										</span>
									</span>
									<span
										className={messageTextStyleClasses}
										style={{
											marginLeft: "-15px",
											position: "relative",
											bottom: "28px",
											paddingLeft: "15px",
										}}
									>
										{question}
										<Input
											ref={fileInputRef}
											hidden={false}
											style={{
												opacity: 0,
												position: "absolute",
												top: 0,
												left: 0,
												width: "100%",
												height: "100%",
											}}
											capture={
												useCameraOnPhotoCapture ? "environment" : undefined
											}
											id={question}
											type="file"
											onChange={async (e) => {
												setClicked(true);
												try {
													actionProvider.handleAssetIdPromptAction(
														new Promise((resolve) =>
															resolve(
																identifyAsset(
																	e,
																	setAlertModal,
																	closeAlertModal,
																	organization,
																	assetTypes,
																),
															),
														),
													);
												} catch {
													e.target.value = null;
												}
											}}
										/>
									</span>
								</>
							)}
						</div>
					))}
				</div>
				<Modal
					centered
					isOpen={alertModal.show}
					toggle={closeAlertModal}
					style={{
						left: isMobile ? "0px" : isSidebarOpen ? "130px" : "40px",
					}}
				>
					<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
					<ModalBody>
						{alertModal.body}
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "5px",
							}}
						></div>
					</ModalBody>
				</Modal>
			</>
		)
	);
};

export default InitialMessageOptions;
