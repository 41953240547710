import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleAssetLocation = async (
	loc,
	setState,
	setAssetTypeInput,
	locationQuestion,
	responseData,
	setResponseData,
	saveDataCaptureState,
	setCurrentState,
) => {
	const prevMessageNoWidget = createChatBotMessage(
		"Thank you. Where is the asset located in the store?",
		{ withAvatar: true },
	);

	setResponseData({
		[locationQuestion.key]: loc.toUpperCase(),
	});

	const userMessage = createClientMessage(loc);

	if (!responseData.assetTypeSuggestions) {
		const message = createChatBotMessage(
			"Thank you. I'm not sure what type of asset it is. Please enter below:",
		);
		const currentState = {
			stage: "assetDetection",
			showOptions: false,
		};
		setState((prev) => ({
			...prev,
			messages: [
				...prev.messages.slice(0, -1),
				prevMessageNoWidget,
				userMessage,
				message,
			],
			...currentState,
		}));

		setAssetTypeInput(true);
		saveDataCaptureState([
			{ message: prevMessageNoWidget, action: "removeWidget" },
			{ message: userMessage, action: "add" },
			{ message, action: "add" },
		]);
		setCurrentState(currentState);
	}

	const res = createChatBotMessage("Is the asset type one of the below?", {
		withAvatar: true,
		widget: "OptionalSelectors",
		payload: {
			topThreeLocations: [],
			question: {},
			capturePhoto: () => {},
		},
	});

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message: res, action: "add" },
	]);

	const currentState = {
		options: [...responseData.assetTypeSuggestions, "Other"],
		stage: "assetDetection",
		showOptions: true,
	};
	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			res,
		],
		...currentState,
	}));
};
