import { Loader } from "@SignedIn/Loader/Loader";
import { storage } from "@assets/services/auth-service";
import { usePageStore } from "@stores";
import { useChatbotStore, useUserStore } from "@stores";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import React, { useState, useEffect, useRef } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { IoInformationCircle } from "react-icons/io5";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
	Button,
	Col,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { CropperModal } from "../../views/SingleForm/CropperModal";

const CustomImageMessage = ({
	payload: { question, capturePhoto, imageUrl },
}) => {
	const {
		user: { useCameraOnPhotoCapture },
	} = useUserStore();

	const { selectedItem, setInputData, responseData } = useChatbotStore();

	const [shownPhoto, setShownPhoto] = useState();
	const imageRef = useRef(shownPhoto ?? null);
	const [clickZoomIn, setClickZoomIn] = useState(false);
	const [clickZoomOut, setClickZoomOut] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const { isSidebarOpen } = usePageStore();
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
	const [isCropShowing, setIsCropShowing] = useState(false);

	const updateMedia = () => {
		setIsDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		if (!shownPhoto) {
			imageUrl?.then((url) => {
				setShownPhoto(url);
			});
		}
	}, [imageUrl, shownPhoto]);

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	const getCroppedPhoto = async (data) => {
		setIsCropShowing(false);
		const imageRef = ref(
			storage,
			`FieldCaptures/${new Date().toISOString()}.webp`,
		);
		const task = await uploadString(imageRef, data, "data_url");
		const url = await getDownloadURL(task.ref);
		// Then attach the url to this asset.
		setInputData(selectedItem, url);
		setShownPhoto(url);
	};

	const switchToCrop = () => {
		// setIsPhotoShowing(false);
		setShowModal(false);
		setIsCropShowing(true);
		// setPhotoToBeCropped(selectedPhotoUrl);
		document.body.style.overflow = "auto";
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: shownPhoto ? "end" : "center",
					position: "relative",
					right: isDesktop ? "60px" : "0px",
					bottom: isDesktop ? "80px" : "50px",
					marginBottom: isDesktop ? "-80px" : "-50px",
					cursor: "pointer",
					height: shownPhoto ? "" : "200px",
				}}
			>
				{!shownPhoto && (
					<>
						<div
							style={{
								width: "40px",
								height: "60px",
								backgroundColor: "white",
								boxShadow: "5px 5px 5px 5px white",
								position: "absolute",
								top: 0,
								right: 0,
							}}
						></div>
						<div
							style={{
								position: "relative",
								right: isDesktop ? "-130px" : "-25px",
								top: "-100px",
							}}
						>
							<Loader />
						</div>
					</>
				)}
				{shownPhoto && (
					<>
						<IoInformationCircle
							style={{
								position: "absolute",
								bottom: "180px",
								right: "200px",
								margin: "5px",
								cursor: "pointer",
							}}
							onClick={() => setShowModal(true)}
						/>
						<img
							src={shownPhoto}
							alt="Uploaded Image"
							style={{ width: "200px", height: "200px" }}
							onClick={() => setShowModal(true)}
						/>
					</>
				)}
			</div>
			<Modal
				isOpen={showModal}
				toggle={() => setShowModal(false)}
				centered
				style={{
					left: isDesktop ? (isSidebarOpen ? "130px" : "40px") : "0px",
				}}
			>
				<ModalHeader toggle={() => setShowModal(!showModal)}></ModalHeader>
				<ModalBody>
					<TransformWrapper initialScale={1}>
						{({ zoomIn, zoomOut }) => (
							<React.Fragment>
								<div className="submitted-image-container">
									<TransformComponent>
										<img
											src={shownPhoto}
											alt="img"
											className="image-submitted"
										/>
									</TransformComponent>
									<div className="tools">
										<button
											className={`btn-zoom ${
												clickZoomOut ? "btn-zoom-pop" : ""
											}`}
											onClick={() => {
												setClickZoomOut(true);
												zoomOut();
											}}
											onAnimationEnd={() => setClickZoomOut(false)}
										>
											<FiMinus />
										</button>
										<button
											className={`btn-zoom ${
												clickZoomIn ? "btn-zoom-pop" : ""
											}`}
											onClick={() => {
												setClickZoomIn(true);
												zoomIn();
											}}
											onAnimationEnd={() => setClickZoomIn(false)}
										>
											<FiPlus />
										</button>
									</div>
								</div>
							</React.Fragment>
						)}
					</TransformWrapper>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col>
							<Button
								onClick={() => {
									switchToCrop();
								}}
								// hidden={!props.switchToCrop}
							>
								Crop
							</Button>
						</Col>
						<Col>
							<Button color="dark" style={{ position: "relative" }}>
								Retake
								<Input
									ref={imageRef}
									style={{
										opacity: 0,
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
									}}
									capture={useCameraOnPhotoCapture ? "environment" : undefined}
									id={question.key}
									type="file"
									onChange={async (e) => {
										try {
											await capturePhoto(e, question, responseData).then(
												(url) => {
													setShownPhoto(url);
												},
											);
										} catch (err) {
											console.error(err);
										} finally {
											e.target.value = null;
											setShowModal(false);
										}
									}}
								/>
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
			<CropperModal
				photoUrl={shownPhoto}
				showing={isCropShowing}
				onCrop={getCroppedPhoto}
				cancel={() => setIsCropShowing(false)}
			/>
		</>
	);
};

export default CustomImageMessage;
