import { DB_ORG } from "@/constants/db";

export const flattenRawInput = (rawInput: any[], org: string) => {
	const parsedRawInput: {
		category: string;
		item: any;
		actionTaken: any;
		imageBefore: any;
		imageAfter: any;
		description: any;
	}[] = [];
	rawInput.forEach((section: { sections: any[]; title: any }) => {
		section.sections.forEach((subsection: { questions: any[]; title: any }) => {
			subsection.questions.forEach((questionItem: any) => {
				// Check if questionItem is an array or a single object
				const questionList = Array.isArray(questionItem)
					? questionItem
					: [questionItem];

				questionList.forEach((question) => {
					// Filter out rows where actionTaken is "N/A" or "No Action Needed"

					if (org == DB_ORG.CUSHMANWAKEFIELD) {
						if (
							question &&
							question.actionTaken !== "N/A" &&
							question.actionTaken !== "No Action Needed"
						) {
							parsedRawInput.push({
								category: `${section.title} - ${subsection.title}`,
								item: question.label || "",
								actionTaken: question.actionTaken || "",
								imageBefore: question.imageBefore || "",
								imageAfter: question.imageAfter || "",
								description: question.description ? question.description : "",
							});
						}
					} else if (org == DB_ORG.RAISINGCANES) {
						// For RC, extract values according to their unique structure
						if (
							question?.value &&
							question.value !== "" &&
							question.value !== null
						) {
							let images = [];
							if (
								Array.isArray(question.value) &&
								question.responseTag.includes("image")
							) {
								// Split images into separate columns
								images = question.value.map((imageUrl: any, index: number) => ({
									[`image_${index + 1}`]: imageUrl || "",
								}));
							}

							parsedRawInput.push({
								formName: section.title,
								category: subsection.title,
								item: question.label || "",
								value: question.value || "",
								...Object.assign({}, ...images),
							});
						}
					}
				});
			});
		});
	});

	return parsedRawInput;
};
