import { useUserStore } from "@/stores";
import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
// import { CushmanLocationEdit } from './CushmanLocationEdit';
// import { AiOutlineEdit } from 'react-icons/ai';

const CushmanLocationInfoCard = (props) => {
	const { locationId, org } = props;
	const [specs, setSpecs] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const { userOrganization } = useUserStore();

	const updateSpecs = async () => {
		const info = userOrganization?.config?.unique_fields?.locationSpecFields;
		if (info) {
			setSpecs(info);
		}
	};

	const toggleModal = () => setModalOpen(!modalOpen);

	useEffect(() => {
		updateSpecs();
	}, [locationId, org]);

	return (
		<Card className="px-5 py-2 d-flex flex-column gen-info-card-wrapper gen-tab-submission sub-vh-75 h-100">
			<CardHeader className="absorbed-tickets-title d-flex flex-column flex-sm-row justify-content-between align-items-center">
				Location Specs
				<button className="edit-submission-btn p-2" onClick={toggleModal}>
					{/* <span className='fs-5'>
						Edit
						<AiOutlineEdit className='fs-5 mx-1' />
					</span> */}
				</button>
			</CardHeader>
			<CardBody>
				<Table className="absorbed-tickets-table" responsive hover>
					<thead>
						<tr>
							<th>Specification</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						{specs.map((spec, i) => {
							return (
								<tr key={`spec-row-${i}`}>
									<td>{spec.label}</td>
									<td>{spec.value}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</CardBody>
			{/* <CushmanLocationEdit
				org={org}
				modalOpen={modalOpen}
				toggleModal={toggleModal}
				locationId={locationId}
				specs={specs}
				setSpecs={setSpecs}
			/> */}
		</Card>
	);
};

export { CushmanLocationInfoCard };
