import Konva from "konva";
import { useEffect, useRef } from "react";
import { Layer, Stage } from "react-konva";

const OCRBBox = ({ responseData, setResponseData }) => {
	const stageRef = useRef(null);
	const image = new window.Image();

	useEffect(() => {
		const selected_img = responseData.ratioImage;
		if (selected_img) {
			image.src = selected_img;
			image.onload = () => {
				setupStage();
			};
		}
	}, [responseData.ratioImage, responseData.alignedImage]);

	useEffect(() => {
		console.log("Repoinsedata updated:");
		console.log(responseData);
	}, [responseData]);

	const setupStage = () => {
		const stage = stageRef.current;
		const layer = new Konva.Layer();
		const konvaImage = new Konva.Image({
			x: 0,
			y: 0,
			image: image,
			width: responseData.dims.width,
			height: responseData.dims.height,
		});
		layer.add(konvaImage);

		let [minX, minY, maxX, maxY] = [
			Number.POSITIVE_INFINITY,
			Number.POSITIVE_INFINITY,
			0,
			0,
		];
		let anyBoxWithinBounds = false;

		responseData.bboxes.forEach((bbox) => {
			const { x, y, width, height } = bbox;
			const boxWithinBounds =
				x >= 0 &&
				y >= 0 &&
				x + width <= responseData.dims.width &&
				y + height <= responseData.dims.height;

			const box = new Konva.Rect({
				x,
				y,
				width,
				height,
				fill: "rgba(0, 0, 255, 0.4)",
				draggable: false,
			});
			layer.add(box);

			if (boxWithinBounds) {
				anyBoxWithinBounds = true;
				minX = Math.min(minX, x);
				minY = Math.min(minY, y);
				maxX = Math.max(maxX, x + width);
				maxY = Math.max(maxY, y + height);
			}
		});

		if (anyBoxWithinBounds) {
			const ocrBox = new Konva.Rect({
				x: minX,
				y: minY,
				width: maxX - minX,
				height: maxY - minY,
				fill: "rgba(255, 0, 0, 0.5)",
				draggable: false,
			});
			layer.add(ocrBox);

			updateOCRBox(ocrBox);

			ocrBox.on("dragend", () => {
				updateOCRBox(ocrBox);
			});
		}

		stage.add(layer);
	};

	const updateOCRBox = (box) => {
		setResponseData((prevData) => ({
			...prevData,
			ocrBBox: {
				x: box.getAbsolutePosition().x,
				y: box.getAbsolutePosition().y,
				width: box.width() * box.getAbsoluteScale().x,
				height: box.height() * box.getAbsoluteScale().y,
			},
			// ocrBBox: {
			// 	x: box.x(),
			// 	y: box.y(),
			// 	width: box.width(),
			// 	height: box.height()
			// }
		}));
	};

	return (
		<Stage
			ref={stageRef}
			width={responseData.dims.width}
			height={responseData.dims.height}
		>
			<Layer />
		</Stage>
	);
};

export { OCRBBox };
