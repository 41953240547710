// File: TestTemplateModal.tsx

import { auth } from "@/assets/services/auth-service";
import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { Button, Input, Modal, Typography, message } from "antd";
import { useState } from "react";
import { OCRResponseComponent } from "./OCRResponse";

interface TesterSettings {
	templateName: string | null;
	isOpen: boolean;
	templateId: string | null;
}

interface OCRResponse {
	model_text?: string | null;
	serial_text?: string | null;
	manufacturer?: string | null;
	model_confidence?: number | null;
	serial_confidence?: number | null;
	extra_fields?: Record<
		string,
		{ text: string | null; confidence: number | null }
	>;
	warnings?: string[];
}

interface TestTemplateModalProps {
	testerSettings: TesterSettings;
	onClose: () => void;
}

export const TestTemplateModal: React.FC<TestTemplateModalProps> = ({
	testerSettings,
	onClose,
}) => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [responseData, setResponseData] = useState<OCRResponse | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const handleUpload = async () => {
		if (selectedFile && testerSettings.templateId) {
			const formData = new FormData();
			formData.append("file", selectedFile);
			formData.append("template_id", testerSettings.templateId);
			try {
				setIsLoading(true);
				const token = await auth?.currentUser?.getIdToken();
				const response = await fetch(
					`${import.meta.env.VITE_ALFRED_SERVICE_URL}/FacilityVision/OCR/review_templates/OCR`,
					{
						method: "POST",
						body: formData,
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (response.ok) {
					const data = await response.json();
					setResponseData(data);
				} else {
					message.error("Error uploading file");
				}
			} catch (error) {
				console.error("Error uploading file", error);
				message.error("Error uploading file");
			} finally {
				setIsLoading(false);
			}
		} else {
			message.warning("Please select a file first");
		}
	};

	const handleClose = () => {
		setSelectedFile(null);
		setResponseData(null);
		onClose();
	};

	return (
		<Modal
			open={testerSettings.isOpen}
			onCancel={handleClose}
			footer={[
				<Button type="primary" key="close" onClick={handleClose}>
					Close
				</Button>,
			]}
			className="test-template-modal"
			title={
				<Typography.Title className="py-0 my-0" level={5}>
					Test Template
				</Typography.Title>
			}
		>
			<Typography.Title level={5}>
				Upload an image to test the OCR model against the selected template.
			</Typography.Title>
			<div className="test-template-modal-body">
				<Input type="file" onChange={handleFileChange} />
				{isLoading && <Loader />}
				{responseData && (
					<div style={{ marginTop: 16 }}>
						<OCRResponseComponent ocrResponse={responseData} />
					</div>
				)}
				<Button onClick={handleUpload} style={{ marginTop: 16 }}>
					Upload Image
				</Button>
			</div>
		</Modal>
	);
};
