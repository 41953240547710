import { generateAssetTypeSuggestions } from "@/services/facility-vision-service";
import { getConditionFromEquipmentImage } from "@/services/openai-service";
import { encode } from "@jsquash/webp";
import { getOcrResponse, logFailedOcrScan } from "@services/ocr-service";
import { scanImage } from "@services/scan-service";
import { uploadFieldCaptureFromFile } from "@services/storage-service";
import { validateAfterDelay } from "./formValidation";

export const capturePhoto = (
	e,
	item,
	fromChatbot,
	setShowLoader,
	setInputData,
	setAlertModal,
	closeAlertModal,
	organization,
	responseData,
	setResponseData,
	setConditionRead,
	setOcrData,
	setIsPage3Valid,
	setIsPage4Valid,
	populateInputs,
	flags,
	authInfo,
	alertModal,
	setAssetTagError,
	formId,
	addScanConfidence,
	clearScanConfidence,
	setTempAlertError,
	retakePicture,
	noManufacturersPlate,
) => {
	// console.time('photo upload');
	// Stop function if no files or individual file
	if (!e.target.files) {
		setShowLoader(false);
		return;
	}
	if (e.target.files.length === 0) {
		setShowLoader(false);
		return;
	}
	const file = e.target.files[0];
	setInputData(item, URL.createObjectURL(file));

	// Display loader
	setShowLoader(true);
	// Stop function & loader, then display error, if user uploads non-image file
	if (!file.type.includes("image")) {
		setShowLoader(false);
		setAlertModal({
			title: "Missing Fields",
			body: "Please only import an image",
			show: true,
		});
		const timerId = setTimeout(closeAlertModal, 3000);
		return clearTimeout(timerId);
	}
	// Init FileReader (to get image source for formatting & upload)
	const reader = new FileReader();
	// Trigger image formatting & uploading process above, starting with `const reader = new FileReader()`
	reader.readAsDataURL(file);
	// return promise, so that image component can rerender (thereby, clearing the input files) if the upload fails
	return new Promise((resolve, reject) => {
		reader.onload = async (e) => {
			// Generate image
			const img = new Image();
			img.src = e.target.result;
			img.onload = async () => {
				// Calculate the height to maintain aspect ratio
				const scale = 1200 / img.width;
				const height = img.height * scale;

				// Create a canvas and draw the image on it
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);

				// Extract the raw RGBA data from the canvas
				const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

				const webpBlob = await encode(imageData);
				if (webpBlob) {
					let success = true;
					const promises = [];

					// Upload image to storage, retrieve download URL, & update inputs
					promises.push(
						new Promise((resolve, reject) => {
							uploadFieldCaptureFromFile(webpBlob)
								.then(async (url) => {
									setInputData(item, url);
									if (item.key === "equipmentAssetImage") {
										try {
											// Attempt to generate asset suggestions (don't need to await)
											generateAssetTypeSuggestions(
												url,
												organization,
												responseData,
												setResponseData,
											);
											fromChatbot
												? getConditionFromEquipmentImage(
														url,
														setInputData,
														setAlertModal,
														closeAlertModal,
														setConditionRead,
													)
												: await getConditionFromEquipmentImage(
														url,
														setInputData,
														setAlertModal,
														closeAlertModal,
														setConditionRead,
													);
										} catch (error) {
											console.error(
												"Error in getConditionFromEquipmentImage:",
												error,
											);
										}
									}
									resolve(url);
								})
								.catch((error) => {
									success = false;
									setInputData(item, undefined);
									console.error("Error uploading photo: ", error);
									setAlertModal({
										title: "Upload Failed",
										body: "Please try again later.",
										show: true,
									});
									setTimeout(closeAlertModal, 3000);
									reject();
								});
						}),
					);

					// Use OCR to prepopulate form, if applicable
					if (
						flags?.config?.allowOcr &&
						item.key === "manufacturersPlateAssetImage"
					) {
						setAlertModal({
							title: "OCR Activated",
							body: "OCR Read Processing...",
							show: true,
						});

						const timeoutId = setTimeout(closeAlertModal, 3000);
						promises.push(
							new Promise((resolve, reject) => {
								getOcrResponse(webpBlob)
									.then((ocrResponse) => {
										setOcrData(ocrResponse);
										populateInputs(ocrResponse);
										organization === "SSPAMERICA"
											? setIsPage4Valid(true)
											: setIsPage3Valid(true);
										clearTimeout(timeoutId);

										let ocrMessage = {
											title: "OCR Template Match Successful!",
											body: "Our servers successfully extracted data from the image & passed it to the form.",
											show: true,
										};
										// this will occur if template match fails but some data was extracted
										if (!ocrResponse.templateMatchSuccess) {
											ocrMessage = {
												title: "OCR Template Match Failed",
												body: "Template match failed. Some fields have been populated.",
												show: true,
											};
										}

										setAlertModal(ocrMessage);
										setTimeout(closeAlertModal, 3500);
										resolve(webpBlob);
									})
									.catch((error) => {
										console.log(error);
										console.error("Error fetching OCR response: ", error);
										// Log the error, if applicable
										if (error.ocrResponse) {
											logFailedOcrScan(
												responseData.manufacturersPlateAssetImage,
												error.ocrResponse,
												organization,
												authInfo.displayName,
											);
										}
										if (!alertModal?.show) {
											clearTimeout(timeoutId);
											setAlertModal({
												title: "OCR Failed",
												body: "Our servers failed to extract data from the image.",
												show: true,
												options: [
													{
														text: "Retake the Picture",
														action: retakePicture,
													},
													{
														text: "No Manufacturer's Plate",
														action: noManufacturersPlate,
													},
												],
											});
											console.log(alertModal);
										}
										organization === "SSPAMERICA"
											? setIsPage4Valid(true)
											: setIsPage3Valid(true);
										reject();
									});
							}),
						);
						document.body.style.overflowY = "auto";
					} else if (item.scanTag && item.scanType) {
						setAlertModal({
							title: `${item.scanType.toUpperCase()} Scan Activated`,
							body: "Processing data from the image...",
							show: true,
						});
						const timeoutId = setTimeout(closeAlertModal, 2500);
						promises.push(
							new Promise((resolve, reject) => {
								scanImage(
									webpBlob,
									item.scanType,
									item.scanTag,
									flags,
									organization,
								)
									.then((response) => {
										if (item.scanTag === "assetTag") {
											validateAfterDelay(
												response[0],
												"assetTag",
												setAssetTagError,
												organization,
												formId,
												location,
											);
										}
										setInputData(
											{
												key: item.scanTag,
											},
											response[0],
										);
										//Checking for additional info returned from backend for confidence values
										if (response.length === 2) {
											const info = response[1];
											if (info.key === "temp_alert_conf") {
												addScanConfidence(item, info.ocr_conf);
											}
										} else {
											clearScanConfidence(item);
										}
										clearTimeout(timeoutId);
										if (organization === "DIGI") {
											const digiHintResponse = "D";
											if (digiHintResponse === response[0]) {
												setTempAlertError({
													error: true,
													message:
														'Please make sure that the temp alert ID starts with "D" and is 7 characters long',
												});
												setAlertModal({
													title: `${item.scanType.toUpperCase()} Failed`,
													body: "Our servers could not extract the data from the image.",
													show: true,
												});
											} else {
												setAlertModal({
													title: `${item.scanType.toUpperCase()} Scan Successful!`,
													body: "Our servers successfully extracted data from the image & passed it to the form.",
													show: true,
												});
											}
										} else if (organization === "cvs.com") {
											const hintResponse = "11666000000";

											if (hintResponse === response[0]) {
												setTempAlertError({
													error: true,
													message:
														'Please make sure that the temp alert ID starts with "11666" and is 20 characters long',
												});
												setAlertModal({
													title: `${item.scanType.toUpperCase()} Failed`,
													body: "Our servers could not extract the data from the image.",
													show: true,
												});
											} else {
												setAlertModal({
													title: `${item.scanType.toUpperCase()} Scan Successful!`,
													body: "Our servers successfully extracted data from the image & passed it to the form.",
													show: true,
												});
											}
										}
										setTimeout(closeAlertModal, 2500);
										resolve(webpBlob);
									})
									.catch((error) => {
										console.log(organization);

										if (organization === "DIGI") {
											setInputData(
												{
													key: item.scanTag,
												},
												"D",
											);
											if (item.scanTag === "tempAlertId") {
												// Setting tempAlertError instead of assetTagError
												setTempAlertError({
													error: true,
													message:
														'Please make sure that the temp alert ID starts with "D" and is 7 characters long',
												});
											}
										} else if (organization === "cvs.com") {
											setInputData(
												{
													key: item.scanTag,
												},
												"CVS",
											);
											if (item.scanTag === "assetTag") {
												setAssetTagError({
													error: true,
													message:
														'Please make sure that the asset tag starts with "CVS" and is 11 characters long',
												});
											} else if (item.scanTag === "tempAlertId") {
												setAssetTagError({
													error: true,
													message:
														'Please make sure that the temp alert ID starts with "11666" and is 20 characters long',
												});
											}
										}
										console.log(error);
										console.error("Error fetching scan response: ", error);
										if (!alertModal?.show) {
											clearTimeout(timeoutId);
											setAlertModal({
												title: `${item.scanType.toUpperCase()} Failed`,
												body: `Our servers could not extract the data from the image.\n\nError code: "${error.message}"`,
												show: true,
											});
											setTimeout(closeAlertModal, 2500);
										}
										reject();
									});
							}),
						);
					}
					Promise.allSettled(promises).then((url) => {
						setShowLoader(false);
						// console.timeEnd('photo upload');
						if (success) resolve(url[0]?.value);
						else reject();
					});
				} else {
					console.log("blob failed");
					reject();
				}
			};
		};
	});
};
