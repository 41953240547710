import { Loader } from "@SignedIn/Loader/Loader";
import { cushmanExport } from "@SignedIn/views/Admin/cushmanExport/cushmanExport";
import { HealthScore } from "@SignedIn/views/Dashboard/HealthScore/HealthScore";
import { updateLocation } from "@services/location-service";
import { csvParse } from "d3";
import { useEffect, useState } from "react";
import { MdDownload } from "react-icons/md";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import { WeekDropdown } from "./WeekDropdown";

const CurrentMonthTab = (props) => {
	const { org, isFocused, setToast } = props;
	const [locsWithSubmission, setLocsWithSubmission] = useState();
	const [locsWithoutSubmission, setLocsWithoutSubmission] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [tabChange, setTabChange] = useState(false);
	const [week1Percent, setWeek1Percent] = useState();
	const [week2Percent, setWeek2Percent] = useState();
	const [week3Percent, setWeek3Percent] = useState();
	const [week4Percent, setWeek4Percent] = useState();
	const weeklyHeaders = "weekid";

	const getLocations = async () => {
		const firebaseConfig = JSON.parse(
			import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
		);
		const response = await fetch(
			firebaseConfig.projectId.includes("staging")
				? "https://us-central1-property-echo-staging.cloudfunctions.net/getCurrMonthCushmanData"
				: "https://us-central1-property-echo-4d747.cloudfunctions.net/getCurrMonthCushmanData",
			{
				method: "GET",
			},
		);
		const data = await response.json();
		await setWeek1Percent(data.stats.week1.percent);
		await setWeek2Percent(data.stats.week2.percent);
		await setWeek3Percent(data.stats.week3.percent);
		await setWeek4Percent(data.stats.week4.percent);
		await setLocsWithSubmission(data.locationsWithSubmission);
		await setLocsWithoutSubmission(data.locationsWithoutSubmission);
		setIsLoading(false);
	};

	const getCurrentMonthAndYear = () => {
		const date = new Date();
		const lastMonth = date.toLocaleString("default", { month: "long" });
		return `${lastMonth} ${date.getFullYear()}`;
	};

	const readCsvFile = async (e, callback) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e) => {
			console.log(e);
			const text = e.target.result;
			const parsedFile = csvParse(text);
			callback(parsedFile);
		};

		reader.readAsText(file);
	};

	const uploadLocationSchedule = (e) => {
		const input = e.target;
		readCsvFile(e, async (data) => {
			let uploadedHeaders;
			if (data[0]) {
				uploadedHeaders = Object.keys(data[0])
					.sort()
					.join("")
					.toLowerCase()
					.replace(/\s/g, "");
			}

			if (uploadedHeaders !== weeklyHeaders) {
				setToast({
					showing: true,
					title: "Error",
					message:
						"Invalid columns. Please reformat to match appropriate columns.",
					type: "danger",
					position: "bottom",
				});
				setTimeout(() => {
					setToast({
						showing: false,
					});
				}, 4500);
				input.value = null;
				return;
			}

			for (let i = 0; i < data.length; ++i) {
				const row = data[i];

				if (!row.Week && !Number.isInteger(Number.parseInt(row.Week))) {
					continue;
				}
				await updateLocation(row?.id, false, {
					unique_fields: { week: row?.Week },
				})
					.then((res) => {
						setToast({
							showing: true,
							title: "Success",
							message: "Successfully updated locations.",
							type: "success",
							position: "bottom",
						});
						setTimeout(() => {
							setToast({
								showing: false,
							});
						}, 4500);
					})
					.catch((err) => {
						setToast({
							showing: true,
							title: "Error",
							message: "Failed to update locations.",
							type: "danger",
							position: "bottom",
						});
						setTimeout(() => {
							setToast({
								showing: false,
							});
						}, 4500);
					});
			}
			input.value = null;
		});
	};

	useEffect(() => {
		if (isFocused) {
			setTabChange(true);
		}
	}, [isFocused]);

	useEffect(() => {
		if (isFocused) {
			getLocations();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabChange]);

	return (
		<div>
			<div className="d-none d-xl-block">
				{isLoading ? (
					<Loader />
				) : (
					<div className="current-month-health-wrapper">
						<div className="current-month-single-health-wrapper">
							<HealthScore
								healthScore={week1Percent}
								label={"Week 1 PM Reports"}
							/>
						</div>
						<div className="current-month-single-health-wrapper">
							<HealthScore
								healthScore={week2Percent}
								label={"Week 2 PM Reports"}
							/>
						</div>
						<div className="current-month-single-health-wrapper">
							<HealthScore
								healthScore={week3Percent}
								label={"Week 3 PM Reports"}
							/>
						</div>
						<div className="current-month-single-health-wrapper">
							<HealthScore
								healthScore={week4Percent}
								label={"Week 4 PM Reports"}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="d-xl-none">
				{isLoading ? (
					<Loader />
				) : (
					<WeekDropdown
						week1Percent={week1Percent}
						week2Percent={week2Percent}
						week3Percent={week3Percent}
						week4Percent={week4Percent}
					/>
				)}
			</div>
			<div className="w-100 d-flex justify-content-center justify-content-md-end">
				<button className="upload-pm-btn">
					<label
						htmlFor="upload-locations-schedule"
						className="upload-pm-label"
					>
						Upload Location Schedule
					</label>
					<input
						onChange={uploadLocationSchedule}
						id="upload-locations-schedule"
						type="file"
						accept=".csv"
					/>
				</button>
			</div>
			<div
				style={{
					gap: "20px",
				}}
				className="d-flex flex-column flex-md-row mt-3"
			>
				<Card className="current-month-progress-table">
					<CardHeader className="p-3 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-sm-center">
						<div className="table-title m-0 opacity-75 px-2">
							Locations With PM Report {`for ${getCurrentMonthAndYear()}`}
						</div>
						<Button
							color="primary"
							className="rounded-circle no-border table-btn"
							outline
							onClick={() =>
								cushmanExport(
									locsWithSubmission,
									`Locations With PM Report ${getCurrentMonthAndYear()}`,
								)
							}
						>
							<MdDownload className="larger-font-icon larger-font" />
						</Button>
					</CardHeader>
					<CardBody>
						{isLoading ? (
							<Loader />
						) : (
							<Table>
								<thead>
									<tr>
										<th>Site Name</th>
										<th>Address</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
									</tr>
								</thead>
								<tbody>
									{locsWithSubmission.map((loc) => {
										return (
											<tr key={loc.id} className="pmreport-table-row">
												<td>{loc.sitename}</td>
												<td>{loc.address}</td>
												<td>{loc.city}</td>
												<td>{loc.state}</td>
												<td>{loc.zip}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}
					</CardBody>
				</Card>
				<Card className="current-month-progress-table">
					<CardHeader className="p-3 d-flex flex-row justify-content-between">
						<div className="m-0 opacity-75">
							Locations Without PM Report {`for ${getCurrentMonthAndYear()}`}
						</div>
						<Button
							color="primary"
							className="rounded-circle no-border table-btn"
							outline
							onClick={() =>
								cushmanExport(
									locsWithoutSubmission,
									`Locations Without PM Report ${getCurrentMonthAndYear()}`,
								)
							}
						>
							<MdDownload className="larger-font-icon larger-font" />
						</Button>
					</CardHeader>
					<CardBody>
						{isLoading ? (
							<Loader />
						) : (
							<Table>
								<thead>
									<tr>
										<th>Site Name</th>
										<th>Address</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
									</tr>
								</thead>
								{locsWithoutSubmission.map((loc) => {
									return (
										<tr key={loc.id} className="pmreport-table-row">
											<td>{loc.sitename}</td>
											<td>{loc.address}</td>
											<td>{loc.city}</td>
											<td>{loc.state}</td>
											<td>{loc.zip}</td>
										</tr>
									);
								})}
							</Table>
						)}
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export { CurrentMonthTab };
