import { generateAssetTypeSuggestions } from "@/services/facility-vision-service";
import { getAssetTypeForPrompt } from "@/services/openai-service";
import { encode } from "@jsquash/webp";
import { uploadFieldCaptureFromFile } from "@services/storage-service";

export const identifyAsset = (
	e,
	setAlertModal,
	closeAlertModal,
	organization,
	assetTypes,
) => {
	// console.time('photo upload');
	// Stop function if no files or individual file
	if (!e.target.files) {
		return;
	}
	if (e.target.files.length === 0) {
		return;
	}
	const file = e.target.files[0];

	// Stop function & loader, then display error, if user uploads non-image file
	if (!file.type.includes("image")) {
		setAlertModal({
			title: "Missing Fields",
			body: "Please only import an image",
			show: true,
		});
		const timerId = setTimeout(closeAlertModal, 3000);
		return clearTimeout(timerId);
	}
	// Init FileReader (to get image source for formatting & upload)
	const reader = new FileReader();
	// Trigger image formatting & uploading process above, starting with `const reader = new FileReader()`
	reader.readAsDataURL(file);
	// return promise, so that image component can rerender (thereby, clearing the input files) if the upload fails
	return new Promise((resolve, reject) => {
		reader.onload = async (e) => {
			// Generate image
			const img = new Image();
			img.src = e.target.result;
			img.onload = async () => {
				// Calculate the height to maintain aspect ratio
				const scale = 1200 / img.width;
				const height = img.height * scale;

				// Create a canvas and draw the image on it
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);

				// Extract the raw RGBA data from the canvas
				const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

				const webpBlob = await encode(imageData);
				if (webpBlob) {
					let success = true;
					const promises = [];

					// Upload image to storage, retrieve download URL, & update inputs
					promises.push(
						new Promise((resolve, reject) => {
							uploadFieldCaptureFromFile(webpBlob)
								.then(async (url) => {
									try {
										if (
											organization === "cvs.com" ||
											organization === "Smash Burger"
										) {
											const suggestions = await generateAssetTypeSuggestions(
												url,
												organization,
												undefined,
												() => {},
												true,
											);

											console.log(suggestions, "suggestions");
											if (!suggestions) {
												resolve({
													name: await getAssetTypeForPrompt(url, assetTypes),
													assetPicture: url,
												});
											} else {
												resolve({
													name: suggestions,
													assetPicture: url,
												});
											}
										} else {
											resolve({
												name: await getAssetTypeForPrompt(url, assetTypes),
												assetPicture: url,
											});
										}
									} catch (error) {
										console.error(
											"Error in getConditionFromEquipmentImage:",
											error,
										);
										reject(error);
									}
								})
								.catch((error) => {
									success = false;
									console.error("Error uploading photo: ", error);
									setAlertModal({
										title: "Upload Failed",
										body: "Please try again later.",
										show: true,
									});
									setTimeout(closeAlertModal, 3000);
									reject();
								});
						}),
					);

					Promise.allSettled(promises).then((url) => {
						// console.timeEnd('photo upload');
						if (success) resolve(url[0]?.value);
						else reject();
					});
				} else {
					console.log("blob failed");
					reject();
				}
			};
		};
	});
};
