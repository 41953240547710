import { searchAssets } from "@/services/asset-service";
import type { Dispatch } from "react";
import type { Action, State } from "../children/AssetsTable";

export const fetchData = async (
	state: State,
	dispatch: Dispatch<Action>,
	browserLocationId: string,
) => {
	const { pageNum, pageSize, sortBy, sortOrder, searchVal, dateRange } = state;
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		const responses = await searchAssets(
			searchVal,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			browserLocationId,
			dateRange[0],
			dateRange[1],
			{},
		);

		if (responses) {
			dispatch({
				type: "SET_DATA_SOURCE",
				payload: responses.results,
			});
			dispatch({ type: "SET_COUNT", payload: responses.count });
		} else {
			throw new Error("Failed to search for assets");
		}
	} catch (exception) {
		console.error(`Failed to search for assets: ${exception}`);
		throw new Error("Failed to search for assets");
	} finally {
		dispatch({ type: "SET_IS_LOADING", payload: false });
	}
};
