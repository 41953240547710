export function debounce<F extends (...args: unknown[]) => void>(
	func: F,
	time = 5000,
): (...args: Parameters<F>) => void {
	let timer: ReturnType<typeof setTimeout> | null = null;
	return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null;
			func.apply(this, args);
		}, time);
	};
}
