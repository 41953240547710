import { FormGroup, Input, Label } from "reactstrap";

const ThemeModeToggle = (props) => {
	const { themeModeChoice, toggleTheme } = props;

	return (
		<FormGroup switch>
			<Label>{themeModeChoice === "dark" ? "Dark Mode" : "Light Mode"}</Label>
			<Input
				type="switch"
				role="switch"
				className="toggle-label-admin"
				checked={themeModeChoice === "dark"}
				onChange={toggleTheme}
			/>
		</FormGroup>
	);
};

export { ThemeModeToggle };
