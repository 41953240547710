import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";
import { handleAssetTypeInput } from "./handleAssetTypeInput";

export const handleAssetDetection = (
	response,
	setAssetTypeInput,
	responseData,
	setResponseData,
	setChosenAssetType,
	assetTypes,
	setState,
	question,
	capturePhoto,
	draftIdState,
	saveDataCaptureState,
	setCurrentState,
) => {
	let prevMessageNoWidget;
	let userMessage;
	if (responseData.assetTypeSuggestions) {
		prevMessageNoWidget = createChatBotMessage(
			"Is the asset type one of the below?",
			{
				withAvatar: true,
			},
		);
	} else {
		prevMessageNoWidget = createChatBotMessage(
			"Did you mean one of the options below?",
			{
				withAvatar: true,
			},
		);
	}

	userMessage = createClientMessage(response);

	if (response === "No, another option." || response === "Other") {
		handleAssetTypeInput(
			setAssetTypeInput,
			setState,
			response,
			saveDataCaptureState,
		);
	} else {
		setResponseData({ [question.key]: response });
		setChosenAssetType(
			assetTypes.filter(
				(assetType) =>
					assetType.data().orgName === response ||
					assetType.data().name === response,
			)[0],
		);

		const message = createChatBotMessage("Please review the data.", {
			withAvatar: true,
			widget: "ExtractedInfo",
			payload: {
				capturePhoto,
				draftIdState,
			},
		});

		saveDataCaptureState([
			{ message: prevMessageNoWidget, action: "removeWidget" },
			{ message: userMessage, action: "add" },
			{ message, action: "add" },
		]);

		const currentState = {
			stage: "handleAssetDetails",
			showOptions: false,
		};
		setCurrentState(currentState);
		setState((prev) => ({
			...prev,
			messages: [
				...prev.messages.slice(0, -1),
				prevMessageNoWidget,
				userMessage,
				message,
			],
			...currentState,
		}));
	}
};
