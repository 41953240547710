import type { StringMap } from "@/models/abstractTypes";
import { SubmissionsTable } from "../../Admin/SubmissionsTable/SubmissionsTable";

interface SubmissionsTabProps {
	browserLocationId?: string;
	organization: StringMap;
}

const SubmissionsTab = (props: SubmissionsTabProps) => {
	const { browserLocationId, organization } = props;
	return (
		<SubmissionsTable
			organization={organization}
			browserLocationId={browserLocationId || ""}
		/>
	);
};
export { SubmissionsTab };
