import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { smartManufacturerOptions } from "@/services/organization-service";
import { debounce } from "@/utils/debounce";
import { deepCopy } from "@/utils/deepCopy";
import { assetTypeToCategory } from "@constants/formInputs/assetTypes";
import { inputs as notRequiredInputs } from "@constants/formInputs/notRequiredInputs";
import { default as initInputs } from "@constants/formInputs/taggingFormInputs";
import { warrantyFieldsSwarovski } from "@constants/formInputs/warranty";
import { useDeleteDraft, useGetDraftsByUser } from "@hooks/draftQueries";
import { useUpdateWithRef } from "@hooks/organizationQueries";
import {
	useAddSubmission,
	useDeleteSubmission,
} from "@hooks/submissionQueries";
import { useUpdateSubmissionReturnAsset } from "@hooks/submissionQueries";
import { getOptionsData } from "@services/form-service";
import { getManufacturersForForm as getManufacturers } from "@services/organization-service";
import { useLocationStore, usePageStore, useUserStore } from "@stores";
import { useFormStore } from "../../../../stores/formStore";
import * as childFunctions from "../childFunctions";

const SingleFormContext = createContext(null);

export const useSingleFormContext = () => useContext(SingleFormContext);

export const SingleFormProvider = ({ children }: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();
	const isMobile = window.innerWidth < 768;

	const { authInfo, userOrganization } = useUserStore();
	const setActiveTab = useLocationStore(
		(state: { setActiveTab: any }) => state.setActiveTab,
	);
	const { isSidebarOpen } = usePageStore();
	const {
		assetMapNewAsset,
		assetMapDimensions,
		setAssetTagScanned,
		flags,
		setFlags,
		alertModal,
		setAlertModal,
		setSelectedPhotoUrl,
		setIsPhotoShowing,
		selectedItem,
		setSelectedItem,
		assetTypes,
		setAssetTypes,
		equipmentAreas,
		setEquipmentAreas,
		assetTagError,
		setAssetTagError,
		setTempAlertError,
		submitDisabled,
		setSubmitDisabled,
		isEditing,
		setIsEditing,
		isQA,
		setIsQA,
		savedLocation,
		setSavedLocation,
		chosenAssetType,
		setChosenAssetType,
		photoToBeCropped,
		setPhotoToBeCropped,
		isCropShowing,
		setIsCropShowing,
		ocrConfidenceAndColor,
		setOcrConfidenceAndColor,
		setShowDraftSelectionModal,
		startNew,
		setStartNew,
		// fieldError,
		setFieldError,
		draftId,
		setDraftId,
		// specific state variable for SSP AMERICA
		airportCode,
		//for checkups tab, if redirected from there, state for assetTag and assetTagUrl will be updated
		setAssetTag,
		setAssetTagUrl,
	} = useFormStore();

	const closeAlertModal = useCallback(() => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	}, [setAlertModal]);

	const formId = location.state?.formId || id || null;
	const addSubmission = useAddSubmission();
	const updateWithRef = useUpdateWithRef();
	const updateSubmission = useUpdateSubmissionReturnAsset();
	const deleteDraft = useDeleteDraft();
	const fileInputRef = useRef(null);

	const deleteSubmission = useDeleteSubmission();

	// States used for form attributes, inputs, etc.
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [inputs, setInputs] = useState(deepCopy(initInputs));
	const [assetConditionalInputs, setAssetConditionalInputs] = useState([]);
	const [orgSpecificInputs, setOrgSpecificInputs] = useState([]);
	const [additionalLocationInputs, setAdditionalLocationInputs] = useState([]);
	// state variable to track if initial inputs are required
	const [inputsRequired, setInputsRequired] = useState(true);

	const [currentPage, setCurrentPage] = useState(0);
	const [options, setOptions] = useState();
	const [responseData, setResponseData] = useState(
		location.state?.responseData || { beginDate: new Date().toISOString() },
	);
	const [generativeAiInputs, setGenerativeAiInputs] = useState(
		location.state?.generativeAiInputs || {},
	);

	// State used for location selection (when location services is enabled)
	const [userLocation, setUserLocation] = useState(
		location.state?.userLocation || {},
	);

	// States for drafts
	const [shouldAutoSave, setShouldAutoSave] = useState();
	const draftIdState = location.state?.draftId ?? draftId ?? null;
	const [showCancelModal, setShowCancelModal] = useState(false);

	// Variable used when updating a submission being edited
	const submissionId = location.state?.submissionId;
	const originalLoc = location.state?.savedLocation;
	// Variable used to track url path for navigating away from edited submission
	const originalPath = location.state?.path;

	const [dupWarningShown, setDupWarningShown] = useState(false);

	// States for init loading animation & loader modal (e.g., when cropping photos)
	const [isLoading, setIsLoading] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	const [todaysDrafts, setTodaysDrafts] = useState([]);

	const [submitAttempted, setSubmitAttempted] = useState(false);
	const [isPage1Valid, setIsPage1Valid] = useState(false);
	const [isPage2Valid, setIsPage2Valid] = useState(false);
	const [isPage3Valid, setIsPage3Valid] = useState(false);
	const [isPage4Valid, setIsPage4Valid] = useState(false);
	const [isPage5Valid, setIsPage5Valid] = useState(false);
	const [isPage6Valid, setIsPage6Valid] = useState(false);
	const [isPage7Valid, setIsPage7Valid] = useState(false);
	const [isPage8Valid, setIsPage8Valid] = useState(false);
	const [isPage9Valid, setIsPage9Valid] = useState(false);
	const [allRequiredFieldsPresent, setAllRequiredFieldsPresent] =
		useState(false);
	const [
		allRequiredFieldsPresentAdditionalDetails,
		setAllRequiredFieldsPresentAdditionalDetails,
	] = useState(false);

	const [warrantyDetailsSwarovski, setWarrantyDetailsSwarovski] = useState();

	const [manufacturers, setManufacturers] = useState([]);
	const [smartManufacturers, setSmartManufacturers] = useState([]);

	const [ocrData, setOcrData] = useState(null);

	const [conditionRead, setConditionRead] = useState(null);

	//for COOPERSHAWK org
	const [assetTypeName, _setAssetTypeName] = useState("");
	const [categories, _setCategories] = useState();

	const fromDraftsTable = location.state?.fromDraftsTable || false;

	const {
		data: drafts,
		isError: isDraftsError,
		isLoading: isDraftsLoading,
	} = useGetDraftsByUser(
		userOrganization.external_firebase_id,
		formId,
		authInfo.id,
		submissionId,
	);

	useEffect(() => {
		if (location?.state) {
			if (location.state.isEditing) {
				setIsEditing(location.state.isEditing);
			}
			if (location.state.draftId) {
				setDraftId(location.state.draftId);
			}
			if (location.state.isQA) {
				setIsQA(location.state.isQA);
			}
			if (location.state.savedLocation) {
				setSavedLocation(location.state.savedLocation);
			}
			if (location.state.chosenAssetType) {
				setChosenAssetType(location.state.chosenAssetType);
			}

			if (
				location.state.checkUps === true &&
				location.state?.assetTag &&
				location.state?.idTagAssetImage
			) {
				console.log("Setting asset tags state now");
				setAssetTag(location.state?.assetTag);
				setAssetTagUrl(location.state?.idTagAssetImage);
			}
		}
	}, [location.state]);

	//  Update locations, manufacturers, assetTypes, shouldAutoSave, orgSpecificInputs, flags, formName, & formLogo states upon init render
	//  Update manufacturers, assetTypes, shouldAutoSave, orgSpecificInputs, flags, formName, & formLogo states upon init render
	useEffect(() => {
		// Init form
		if (!formName && !formLogo) {
			childFunctions.initForm(
				setManufacturers,
				userOrganization,
				setAssetTypes,
				setEquipmentAreas,
				isEditing,
				setShouldAutoSave,
				setOrgSpecificInputs,
				setAdditionalLocationInputs,
				formId,
				setFormName,
				setFormLogo,
				setFlags,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		childFunctions.handleNavigateAway();
	}, []);

	useEffect(() => {
		if (!responseData.location && savedLocation) {
			setSavedLocation(null);
		}
	}, [savedLocation]);

	useEffect(() => {
		if (!drafts || drafts?.length === 0) {
			setShowDraftSelectionModal(false);
			setStartNew(false);
			return;
		}

		setTodaysDrafts(drafts);

		const shouldShowModal =
			currentPage === 0 &&
			!startNew &&
			!isEditing &&
			!isQA &&
			!fromDraftsTable &&
			draftId === undefined;

		if (shouldShowModal) {
			setShowDraftSelectionModal(shouldShowModal);
		} else {
			setShowDraftSelectionModal(false);
		}
	}, [
		drafts,
		currentPage,
		startNew,
		isEditing,
		isQA,
		draftId,
		isDraftsLoading,
	]);
	// Updates options state when inputs changes
	useEffect(() => {
		getOptionsData(
			inputs,
			assetConditionalInputs,
			orgSpecificInputs,
			setOptions,
			userOrganization.external_firebase_id,
			flags,
			airportCode,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, airportCode, flags]);

	// Update setIsCropShowing (ie, show cropper modal) if photoToBeCropped state != undefined
	useEffect(() => {
		if (photoToBeCropped) {
			setIsCropShowing(true);
		}
	}, [photoToBeCropped]);

	// Update isLoading state when shouldAutoSave, formName, & formLogo states are all not undefined
	useEffect(() => {
		if (
			Object.keys(manufacturers).length > 0 &&
			Object.keys(assetTypes).length > 0 &&
			shouldAutoSave !== undefined &&
			formName &&
			formLogo &&
			options
		) {
			setDefaultValues();
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manufacturers, assetTypes, shouldAutoSave, formName, formLogo, options]);

	const saveDraftDebounced = useCallback(
		debounce((params: any) => {
			childFunctions.saveDraft(...params);
		}, 1500),
		[],
	);

	useEffect(() => {
		saveDraftDebounced([
			responseData,
			shouldAutoSave,
			draftId,
			setDraftId,
			userOrganization.external_firebase_id,
			formId,
			formName,
			authInfo,
			userLocation,
			savedLocation,
			chosenAssetType,
			submitDisabled,
			submissionId,
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responseData, chosenAssetType, savedLocation, draftId]);

	useEffect(() => {
		if (
			location.state?.checkUps &&
			location.state.assetTag &&
			location.state.idTagAssetImage
		) {
			console.log("Updating responseData with assetTag and idTagAssetImage");
			setResponseData((prevData: any) => ({
				...prevData,
				assetTag: location.state.assetTag,
				idTagAssetImage: location.state.idTagAssetImage,
			}));
		}
	}, [location.state, setResponseData]);

	// get asset conditional inputs when asset type value changes
	// useEffect(() => {
	// 	if (chosenAssetType) {
	// 		// when asset type changes, delete all associated conditional inputs from responseData before getting new inputs
	// 		setResponseData((prevState: any) => {
	// 			const newState = { ...prevState };
	// 			for (const input of assetConditionalInputs) delete newState[input.key];
	// 			return newState;
	// 		});
	// 		getAssetConditionalInputs(chosenAssetType);
	// 	}
	// }, [chosenAssetType]);

	// set default values for switch inputs
	useEffect(() => {
		if (inputs.length > 0 && inputsRequired) {
			setDefaultValues();
		}
	}, [inputs]);

	const closeCancelModal = () => setShowCancelModal(false);

	// Updates inputs and responseData when inputsRequired becomes false
	// Else, update inputs only
	useEffect(() => {
		childFunctions.updateInputsRequiredChange(
			inputsRequired,
			notRequiredInputs,
			false, // fromChatbot
			responseData,
			setResponseData,
			setCurrentPage,
			setInputs,
			initInputs,
			assetConditionalInputs,
			orgSpecificInputs,
			additionalLocationInputs,
			savedLocation,
			flags,
			isQA,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		flags.config,
		inputsRequired,
		assetConditionalInputs,
		orgSpecificInputs,
		flags?.allowAssetMapping,
		responseData?.assetLocation,
		additionalLocationInputs,
	]);

	/**
	 * End Use Effects
	 */

	const handleCancel = async () => {
		if (draftIdState) {
			setShowCancelModal(true);
		}
	};

	const handleDeleteDraft = async () => {
		await deleteDraft.mutate({
			organization: userOrganization.external_firebase_id,
			draftId: draftIdState,
		});
		setShowCancelModal(false);
		navigate("/forms");
	};

	const populateInputs = useCallback(
		async (response: any) => {
			return await childFunctions.populateInputs({
				response,
				fromChatbot: false,
				organization: userOrganization.external_firebase_id,
				responseData,
				setResponseData,
				setChosenAssetType,
				setCurrentPage,
				setOcrConfidenceAndColor,
			});
		},
		[
			userOrganization,
			responseData,
			setChosenAssetType,
			setOcrConfidenceAndColor,
		],
	);

	const setInputData = useCallback(
		(item: any, value: any, isEquipmentRead: boolean) =>
			childFunctions.setInputData(
				item,
				false, // fromChatbot
				value,
				isEquipmentRead,
				responseData,
				setResponseData,
				flags,
				setChosenAssetType,
			),
		[responseData, setResponseData, flags, setChosenAssetType],
	);

	const setDefaultValues = useCallback(
		async () =>
			childFunctions.setDefaultValues(inputs, setInputData, responseData),
		[inputs, responseData, setInputData],
	);

	const clearState = useCallback(() => {
		setIsLoading(true);
		setCurrentPage(0);
		setInputs(deepCopy(initInputs));
		setAssetConditionalInputs([]);
		setInputsRequired(true);
		setResponseData({
			beginDate: new Date().toISOString(),
			location: savedLocation?.id,
		});

		// Reset other state variables
		setChosenAssetType(null);
		setSelectedPhotoUrl(null);
		setIsPhotoShowing(false);
		setSelectedItem(null);
		setIsCropShowing(false);
		setPhotoToBeCropped(null);
		setDupWarningShown(false);
		setDraftId(null);
		setAssetTagScanned(false);
		setAlertModal({
			show: false,
			title: "",
			body: "",
			options: [
				{ text: "Retake the Picture" },
				{ text: "No Manufacturer's Plate" },
			],
		});
		setOcrConfidenceAndColor({});
		setAssetTagError(false);
		setShowLoader(false);
		setSubmitDisabled(false);

		// Handle asynchronous functions
		Promise.all([
			getManufacturers(setManufacturers),
			getOptionsData(
				inputs,
				assetConditionalInputs,
				orgSpecificInputs,
				setOptions,
				userOrganization.external_firebase_id,
				flags,
				airportCode || "",
			),
		])
			.then(() => {
				// Both async operations have completed
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error during clearState:", error);
				setIsLoading(false); // Ensure loading state is reset even if there's an error
			});
	}, [
		savedLocation?.id,
		inputs,
		assetConditionalInputs,
		orgSpecificInputs,
		userOrganization.external_firebase_id,
		flags,
		airportCode,
		setChosenAssetType,
		setSelectedPhotoUrl,
		setIsPhotoShowing,
		setSelectedItem,
		setIsCropShowing,
		setPhotoToBeCropped,
		setDraftId,
		setAssetTagScanned,
		setAlertModal,
		setOcrConfidenceAndColor,
		setAssetTagError,
		setSubmitDisabled,
	]);

	const getAssetConditionalInputs = useCallback(
		async (assetType: any) =>
			childFunctions.getAssetConditionalInputs(
				assetType,
				setInputsRequired,
				setAssetConditionalInputs,
			),
		[setAssetConditionalInputs, setInputsRequired],
	);

	// 	useEffect(() => {

	// 		if (organization === 'COOPERSHAWK') {
	// 			const assetType = responseData.assetType;

	// 			if (assetType) {
	// 				setAssetTypeName(assetType);

	// 				const fetchedCategories = assetTypeToCategory[assetType] || [];
	// 				setCategories(fetchedCategories);

	// 				console.log(fetchedCategories)

	// 				setResponseData((prevState) => {
	// 					return {
	// 					...prevState,
	// 					assetTypeCategory: assetType,
	// 					assetType: categories[0],
	// 					};
	// 				});

	// 		}
	// 	}
	//  }, [responseData.assetType]);

	useEffect(() => {
		const fetchSubOrg = async () => {
			if (
				userOrganization.external_firebase_id === "EcoTrak" &&
				savedLocation
			) {
				setResponseData((prevState: any) => ({
					...prevState,
					subOrg: savedLocation.sub_org,
				}));
			}
		};
		fetchSubOrg();
	}, [savedLocation]);

	useEffect(() => {
		const fetchSmartManufacturers = async () => {
			const options = await smartManufacturerOptions(
				responseData.assetType.id,
				userOrganization.external_firebase_id,
			);
			setSmartManufacturers(options);
		};

		const fetchManufacturers = async () => {
			await getManufacturers(setManufacturers);
		};
		if (responseData.assetType) {
			fetchSmartManufacturers();
		} else {
			fetchManufacturers();
		}
	}, [
		responseData?.assetType?.id,
		responseData?.assetType,
		userOrganization.external_firebase_id,
	]);

	const getOptionsLength = useCallback(
		(item: any) =>
			childFunctions.getOptionsLength(
				item,
				manufacturers,
				flags,
				equipmentAreas,
				assetTypes,
				responseData,
				options,
				assetTypeName,
				userOrganization.external_firebase_id,
				assetTypeToCategory,
			),
		[
			manufacturers,
			flags,
			assetTypes,
			responseData,
			options,
			assetTypeName,
			userOrganization.external_firebase_id,
		],
	);

	const getOptions = useCallback(
		(item: any, value: any) =>
			childFunctions.getOptions(
				item,
				value,
				manufacturers,
				smartManufacturers,
				equipmentAreas,
				assetTypes,
				flags,
				responseData,
				options,
				userOrganization.external_firebase_id,
				categories,
			),
		[
			smartManufacturers,
			manufacturers,
			assetTypes,
			flags,
			responseData,
			options,
			userOrganization,
			categories,
		],
	);

	/**
	 * Updates a confidence value for a single item
	 *
	 * @param {Object} item - The item which we want to set the confidence for
	 * @param {float} confidence - The confidence to display for the item
	 */
	const addScanConfidence = useCallback(
		(item: any, confidence: any) =>
			childFunctions.addScanConfidence(
				item,
				confidence,
				ocrConfidenceAndColor,
				setOcrConfidenceAndColor,
			),
		[ocrConfidenceAndColor, setOcrConfidenceAndColor],
	);

	/**
	 * Removes a confidence value for a specified item
	 *
	 * @param {Object} item - The item which we want to set the confidence for
	 */
	const clearScanConfidence = useCallback(
		(item: any) =>
			childFunctions.clearScanConfidence(
				item,
				ocrConfidenceAndColor,
				setOcrConfidenceAndColor,
			),
		[ocrConfidenceAndColor, setOcrConfidenceAndColor],
	);

	const capturePhoto = useCallback(
		(e: any, item: any, responseData: any) => {
			function retakePicture() {
				// const fileInputRef = useRef();
				setAlertModal({
					show: false,
					title: "",
					body: "",
					options: [],
				});
				setSelectedItem(item);
				fileInputRef.current.click();
			}

			function noManufacturersPlate() {
				console.log("no manufacturers plate");

				// set manufacturerNotAvailable to true
				setInputData(
					{
						key: "manufacturerPlateNotAvailable",
					},
					true,
				);

				closeAlertModal();
				setCurrentPage((prevState) => prevState + 1);
			}
			const res = childFunctions.capturePhoto(
				e,
				item,
				false, // fromChatbot
				setShowLoader,
				setInputData,
				setAlertModal,
				closeAlertModal,
				userOrganization.external_firebase_id,
				responseData,
				setResponseData,
				setConditionRead,
				setOcrData,
				setIsPage3Valid,
				setIsPage4Valid,
				populateInputs,
				flags,
				authInfo,
				alertModal,
				setAssetTagError,
				formId,
				addScanConfidence,
				clearScanConfidence,
				setTempAlertError,
				retakePicture,
				noManufacturersPlate,
			);
			return res;
		},
		[
			setShowLoader,
			setInputData,
			setAlertModal,
			closeAlertModal,
			userOrganization,
			setResponseData,
			setConditionRead,
			setOcrData,
			populateInputs,
			flags,
			authInfo,
			alertModal,
			setAssetTagError,
			formId,
			addScanConfidence,
			clearScanConfidence,
			setTempAlertError,
		],
	);

	const handleFileChange = useCallback(
		(e: { target: { files: string | any[] } }) => {
			console.log(e, selectedItem);
			if (e.target.files.length > 0) {
				capturePhoto(e, selectedItem, responseData);
			}
		},
		[selectedItem, capturePhoto, responseData],
	);

	const getCroppedPhoto = useCallback(
		async (data: any) =>
			childFunctions.getCroppedPhoto(
				data,
				setIsCropShowing,
				setInputData,
				selectedItem,
				setPhotoToBeCropped,
			),
		[selectedItem, setPhotoToBeCropped, setIsCropShowing, setInputData],
	);
	const submit = useCallback(
		async (enterNew = false) =>
			childFunctions.submit(
				enterNew,
				setAlertModal,
				closeAlertModal,
				responseData,
				submitDisabled,
				setSubmitDisabled,
				setIsLoading,
				{
					isPage1Valid,
					isPage2Valid,
					isPage3Valid,
					isPage4Valid,
					isPage5Valid,
					isPage6Valid,
					isPage7Valid,
					isPage8Valid,
					isPage9Valid,
				},
				setCurrentPage,
				currentPage,
				inputs,
				setSubmitAttempted,
				setFieldError,
				userOrganization.external_firebase_id,
				formId,
				dupWarningShown,
				setDupWarningShown,
				assetTagError,
				isEditing,
				assetMapNewAsset,
				flags,
				isQA,
				draftId,
				location,
				authInfo,
				originalPath,
				submissionId,
				savedLocation,
				formName,
				userLocation,
				conditionRead,
				ocrData,
				assetMapDimensions,
				chosenAssetType,
				deleteSubmission,
				updateSubmission,
				addSubmission,
				updateWithRef,
				draftIdState,
				deleteDraft,
				setActiveTab,
				navigate,
				clearState,
			),
		[
			setAlertModal,
			closeAlertModal,
			responseData,
			submitDisabled,
			setSubmitDisabled,
			isPage1Valid,
			isPage2Valid,
			isPage3Valid,
			isPage4Valid,
			isPage5Valid,
			isPage6Valid,
			isPage7Valid,
			isPage8Valid,
			isPage9Valid,
			currentPage,
			inputs,
			setFieldError,
			userOrganization,
			formId,
			dupWarningShown,
			assetTagError,
			isEditing,
			assetMapNewAsset,
			flags,
			isQA,
			draftId,
			location,
			authInfo,
			originalPath,
			submissionId,
			savedLocation,
			formName,
			userLocation,
			conditionRead,
			ocrData,
			assetMapDimensions,
			chosenAssetType,
			deleteSubmission,
			updateSubmission,
			addSubmission,
			updateWithRef,
			draftIdState,
			deleteDraft,
			setActiveTab,
			navigate,
			clearState,
		],
	);

	useEffect(() => {
		const warrantyLogic = (field: any) => {
			childFunctions.warrantyLogic(
				field,
				userOrganization.external_firebase_id,
				currentPage,
				responseData,
			);
		};

		if (userOrganization.external_firebase_id === "swarovski.com") {
			const warrantySwarovski = warrantyFieldsSwarovski.every(warrantyLogic);
			if (!warrantySwarovski) {
				console.warn(
					"One or more fields in warrantyDetailsSwarovski failed validation.",
				);
			}
			setWarrantyDetailsSwarovski(warrantySwarovski);
		}
	}, [responseData, userOrganization, currentPage]);

	useEffect(() => {
		childFunctions.validateRequiredFields(
			userOrganization.external_firebase_id,
			responseData,
			currentPage,
			setAllRequiredFieldsPresent,
			setAllRequiredFieldsPresentAdditionalDetails,
		);
	}, [responseData, currentPage, userOrganization]);

	const validateAndSetPage1 = useCallback(
		() => childFunctions.validateAndSetPage1(responseData, setIsPage1Valid),
		[responseData, setIsPage1Valid],
	);
	//cvs
	const validateAndSetPage2 = useCallback(
		() =>
			childFunctions.validateAndSetPage2(
				userOrganization.external_firebase_id,
				responseData,
				setIsPage2Valid,
			),
		[userOrganization, responseData, setIsPage2Valid],
	);
	//cvs
	const validateAndSetPage3 = useCallback(
		() =>
			childFunctions.validateAndSetPage3(
				userOrganization.external_firebase_id,
				responseData,
				setIsPage3Valid,
			),
		[userOrganization, responseData, setIsPage3Valid],
	);

	const validateAndSetPage4 = useCallback(
		() =>
			childFunctions.validateAndSetPage4(
				userOrganization.external_firebase_id,
				responseData,
				allRequiredFieldsPresent,
				setIsPage4Valid,
			),
		[userOrganization, responseData, allRequiredFieldsPresent, setIsPage4Valid],
	);

	const validateAndSetPage5 = useCallback(
		() =>
			childFunctions.validateAndSetPage5(
				userOrganization.external_firebase_id,
				allRequiredFieldsPresent,
				allRequiredFieldsPresentAdditionalDetails,
				setIsPage5Valid,
			),
		[
			userOrganization,
			allRequiredFieldsPresent,
			allRequiredFieldsPresentAdditionalDetails,
			setIsPage5Valid,
		],
	);

	const validateAndSetPage6 = useCallback(
		() =>
			childFunctions.validateAndSetPage6(
				userOrganization.external_firebase_id,
				responseData,
				warrantyDetailsSwarovski,
				setIsPage6Valid,
			),
		[userOrganization, responseData, warrantyDetailsSwarovski, setIsPage6Valid],
	);

	const validateAndSetPage7 = useCallback(
		() =>
			childFunctions.validateAndSetPage7(
				assetTagError,
				userOrganization.external_firebase_id,
				responseData,
				setIsPage7Valid,
			),
		[assetTagError, userOrganization, responseData, setIsPage7Valid],
	);

	const validateAndSetPage8 = useCallback(
		() =>
			childFunctions.validateAndSetPage8(
				userOrganization.external_firebase_id,
				assetMapNewAsset,
				setIsPage8Valid,
				responseData,
			),
		[userOrganization, responseData, assetMapNewAsset, setIsPage8Valid],
	);

	const validateAndSetPage9 = useCallback(
		() =>
			childFunctions.validateAndSetPage9(
				userOrganization.external_firebase_id,
				responseData,
				setIsPage9Valid,
			),
		[userOrganization, responseData, setIsPage9Valid],
	);

	const handleProgressBarCircleClick = useCallback(
		(pageIndex: any) =>
			childFunctions.handleProgressBarCircleClick(
				pageIndex,
				currentPage,
				responseData,
				validateAndSetPage1,
				isPage1Valid,
				setAlertModal,
				closeAlertModal,
				validateAndSetPage2,
				validateAndSetPage3,
				validateAndSetPage4,
				validateAndSetPage5,
				validateAndSetPage6,
				validateAndSetPage7,
				validateAndSetPage8,
				validateAndSetPage9,
				setCurrentPage,
			),
		[
			currentPage,
			responseData,
			validateAndSetPage1,
			isPage1Valid,
			setAlertModal,
			closeAlertModal,
			validateAndSetPage2,
			validateAndSetPage3,
			validateAndSetPage4,
			validateAndSetPage5,
			validateAndSetPage6,
			validateAndSetPage7,
			validateAndSetPage8,
			validateAndSetPage9,
			setCurrentPage,
		],
	);

	const nextPage = useCallback(
		() =>
			childFunctions.nextPage(
				setAlertModal,
				currentPage,
				setIsLoading,
				responseData,
				closeAlertModal,
				inputs,
				setCurrentPage,
				isPage1Valid,
				{
					validateAndSetPage1,
					validateAndSetPage2,
					validateAndSetPage3,
					validateAndSetPage4,
					validateAndSetPage5,
					validateAndSetPage6,
					validateAndSetPage7,
					validateAndSetPage8,
					validateAndSetPage9,
				},
			),
		[
			setAlertModal,
			currentPage,
			setIsLoading,
			responseData,
			closeAlertModal,
			inputs,
			setCurrentPage,
			isPage1Valid,
			validateAndSetPage1,
			validateAndSetPage2,
			validateAndSetPage3,
			validateAndSetPage4,
			validateAndSetPage5,
			validateAndSetPage6,
			validateAndSetPage7,
			validateAndSetPage8,
			validateAndSetPage9,
		],
	);

	const prevPage = useCallback(
		() =>
			childFunctions.prevPage(
				setAlertModal,
				closeAlertModal,
				currentPage,
				setCurrentPage,
			),
		[setAlertModal, closeAlertModal, currentPage, setCurrentPage],
	);

	return (
		<SingleFormContext.Provider
			value={
				{
					isEditing,
					isQA,
					navigate,
					originalPath,
					originalLoc,
					submissionId,
					formId,
					formLogo,
					formName,
					inputs,
					isPageValid: [
						isPage1Valid,
						isPage2Valid,
						isPage3Valid,
						isPage4Valid,
						isPage5Valid,
						isPage6Valid,
						isPage7Valid,
						isPage8Valid,
						isPage9Valid,
					],
					submitAttempted,
					handleProgressBarCircleClick,
					currentPage,
					submit,
					setUserLocation,
					setInputData,
					responseData,
					setResponseData,
					generativeAiInputs,
					setGenerativeAiInputs,
					capturePhoto,
					getOptions,
					getOptionsLength,
					fileInputRef,
					handleFileChange,
					prevPage,
					draftIdState,
					handleCancel,
					nextPage,
					isCropShowing,
					setIsCropShowing,
					photoToBeCropped,
					getCroppedPhoto,
					showLoader,
					alertModal,
					closeAlertModal,
					isMobile,
					isSidebarOpen,
					showCancelModal,
					closeCancelModal,
					handleDeleteDraft,
					todaysDrafts,
					clearState,
					draftId,
					isLoading,
				} as any
			}
		>
			{children}
		</SingleFormContext.Provider>
	);
};
