import { useFormStore } from "@stores";
import { GrCamera, GrCheckmark } from "react-icons/gr";
import { Input } from "reactstrap";

export const CushmanImageOptions = ({
	pageIndex,
	sectionIndex,
	questionIndex,
	questionArrIndex,
	inputs,
	addPhotoToInputs,
	setSelectedPhotoUrl,
	setSelectedItem,
	formName,
}) => {
	let items = [
		{ key: "imageBefore", label: "Asset Before" },
		{ key: "imageAfter", label: "Asset After" },
	];
	const preWalkItems = [{ key: "imageBefore", label: "Asset Before" }];
	if (formName === "Pre-Walk Checklist") {
		items = preWalkItems;
	}
	// Generate buttons for each 'item' above
	const imageOptions = items.map((item) => {
		const imageUrl =
			inputs[pageIndex].sections[sectionIndex].questions[questionIndex][
				questionArrIndex
			][item.key];
		if (imageUrl) {
			return (
				<label
					className="image-upload-button"
					key={item.label}
					onClick={() => {
						useFormStore.setState({ selectedPhotoUrl: imageUrl });
						useFormStore.setState({ isPhotoShowing: true });
						useFormStore.setState({
							selectedItem: {
								pageIndex,
								sectionIndex,
								questionIndex,
								questionArrIndex,
								key: item.key,
							},
						});
						// setSelectedPhotoUrl(imageUrl);
						// setIsPhotoShowing(true);
						// setSelectedItem({
						// 	pageIndex,
						// 	sectionIndex,
						// 	questionIndex,
						// 	questionArrIndex,
						// 	key: item.key,
						// });
					}}
				>
					<GrCheckmark className="me-2" />
					{item.label}
				</label>
			);
		}
		return (
			<label
				className="image-upload-button"
				key={item.label}
				htmlFor={`photo-${pageIndex}-${sectionIndex}-${questionIndex}-${questionArrIndex}-${item.key}`}
			>
				<GrCamera className="me-2" />
				{item.label}
				<Input
					id={`photo-${pageIndex}-${sectionIndex}-${questionIndex}-${questionArrIndex}-${item.key}`}
					type="file"
					onChange={(e) =>
						addPhotoToInputs(
							e,
							pageIndex,
							sectionIndex,
							questionIndex,
							questionArrIndex,
							item.key,
						)
					}
				/>
			</label>
		);
	});

	return <div className="d-flex align-items-center">{imageOptions}</div>;
};
