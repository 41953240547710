export const getCvsAdditionalDetailsPageFields = (
	organization,
	responseData,
) => [
	...(organization === "cvs.com" && responseData.assetLocation === "PHARMACY"
		? []
		: ["numDoors"]),
	"refrigerant",
	"refrigerantCharge",
	"voltage",
	"amps",
];
