import {
	aluminumTubeTypesSwarovski,
	cabinetTypesSwarovski,
	cameraTypesSwarovski,
	cashDeskTypesSwarovski,
	chandelierTypesSwarovski,
	displayTypesSwarovski,
	earringSpinnerTypesSwarovski,
	extinguisherTypesSwarovski,
	fireAlarmTypesSwarovski,
	fireHoseTypesSwarovski,
	freeStandingScreenTypesSwarovski,
	hangingBoxTypesSwarovski,
	hangingOctagonTypesSwarovski,
	hvacTypesSwarovski,
	ledSwanTypesSwarovski,
	lightingTypesSwarovski,
	luminaireTypesSwarovski,
	manualCallPointTypesSwarovski,
	mirrorTypesSwarovski,
	octagonalTableTypesSwarovski,
	panelScreenTypesSwarovski,
	safeLockerTypesSwarovski,
	screenWallTypesSwarovski,
	sprinklerSystemTypesSwarovski,
	stereoSystemTypesSwarovski,
	wardrobeTypesSwarovski,
	waterEquipmentTypesSwarovski,
} from "@constants/formInputs/assetTypes";

export const getSwarovskiFields = (responseData) =>
	hvacTypesSwarovski.includes(responseData.assetType)
		? [
				"capacity",
				"unitType",
				"originalValue",
				"currentValueEstimate",
				"workOrderArea",
				"energyEfficiency",
				"Life Expectancy",
				"purchaseDate",
				"warrantyPeriod",
				"warrantyStartDate",
				"warrantyExpirationDate",
				"warrantyProvider",
				"installDate",
				"coilCleaningIncluded",
				"filterSize",
				"refTypeSwarovski",
				"rtuSplitSystem",
				"voltage",
				"hasCircuits",
				"hasComponents",
			]
		: mirrorTypesSwarovski.includes(responseData.assetType)
			? ["numMirrors", "installDate"]
			: cabinetTypesSwarovski.includes(responseData.assetType)
				? ["numShelves", "installDate"]
				: waterEquipmentTypesSwarovski.includes(responseData.assetType)
					? ["capacity", "installDate"]
					: screenWallTypesSwarovski.includes(responseData.assetType)
						? ["numPrisms", "installDate"]
						: fireAlarmTypesSwarovski.includes(responseData.assetType)
							? ["lastDueDate", "nextDueDate"]
							: hangingBoxTypesSwarovski.includes(responseData.assetType)
								? ["numBoxes", "installDate"]
								: octagonalTableTypesSwarovski.includes(responseData.assetType)
									? ["numBoxesLenses", "installDate"]
									: displayTypesSwarovski.includes(responseData.assetType)
										? ["installDate"]
										: earringSpinnerTypesSwarovski.includes(
													responseData.assetType,
												)
											? ["numTiers", "installDate"]
											: lightingTypesSwarovski.includes(responseData.assetType)
												? ["lightingType", "installDate", "numLights"]
												: cashDeskTypesSwarovski.includes(
															responseData.assetType,
														)
													? ["numDrawers", "installDate"]
													: cameraTypesSwarovski.includes(
																responseData.assetType,
															)
														? ["resolution", "Monitoring System"]
														: stereoSystemTypesSwarovski.includes(
																	responseData.assetType,
																)
															? ["wattage", "numSpeakers", "installDate"]
															: fireHoseTypesSwarovski.includes(
																		responseData.assetType,
																	)
																? ["length", "lastDueDate", "nextDueDate"]
																: safeLockerTypesSwarovski.includes(
																			responseData.assetType,
																		)
																	? ["lockingMechanism"]
																	: manualCallPointTypesSwarovski.includes(
																				responseData.assetType,
																			)
																		? [
																				"activationType",
																				"lastDueDate",
																				"nextDueDate",
																			]
																		: sprinklerSystemTypesSwarovski.includes(
																					responseData.assetType,
																				)
																			? ["length", "lastDueDate", "nextDueDate"]
																			: hangingOctagonTypesSwarovski.includes(
																						responseData.assetType,
																					)
																				? ["loadCapacity", "installDate"]
																				: aluminumTubeTypesSwarovski.includes(
																							responseData.assetType,
																						)
																					? ["numTubes", "installDate"]
																					: panelScreenTypesSwarovski.includes(
																								responseData.assetType,
																							)
																						? [
																								"screenSize",
																								"resolution",
																								"installDate",
																							]
																						: wardrobeTypesSwarovski.includes(
																									responseData.assetType,
																								)
																							? ["numDoors", "installDate"]
																							: freeStandingScreenTypesSwarovski.includes(
																										responseData.assetType,
																									)
																								? ["screenSize", "installDate"]
																								: chandelierTypesSwarovski.includes(
																											responseData.assetType,
																										)
																									? ["type", "installDate"]
																									: extinguisherTypesSwarovski.includes(
																												responseData.assetType,
																											)
																										? [
																												"resolution",
																												"capacity",
																												"lastDueDate",
																												"nextDueDate",
																											]
																										: luminaireTypesSwarovski.includes(
																													responseData.assetType,
																												)
																											? [
																													"voltage",
																													"numLightsControlled",
																													"lightingType",
																													"installDate",
																												]
																											: ledSwanTypesSwarovski.includes(
																														responseData.assetType,
																													)
																												? [
																														"voltage",
																														"installDate",
																													]
																												: [];
