import { PlansPage } from "@SignedIn/views/Plans/PlansPage";
import { useUserStore } from "@stores";
import { Admin } from "@views/SignedIn/Admin/Admin";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const Plans = () => {
	const isAdminOrSuperUser = useUserStore((state) => state.isAdminOrSuperUser);
	const [page, setPage] = useState();

	const updatePage = async () => {
		const isPaid = isAdminOrSuperUser;
		if (isPaid) {
			setPage(<Navigate to="/admin" element={<Admin />} replace />);
		} else {
			setPage(<PlansPage />);
		}
	};

	useEffect(() => {
		updatePage();
	}, []);

	return page;
};

export { Plans };
