// FacilityAI/src/components/layouts/SignedIn/views/Admin/ManageOCRTab/ManageOCRTabRefactor.tsx

import { DownloadOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	Divider,
	Space,
	Switch,
	Tooltip,
	Typography,
	message,
} from "antd";
import { useEffect, useReducer } from "react";
import "@/components/layouts/SignedIn/views/Admin/ManageOCRTab/ManagerOCRTab.scss";
import { auth } from "@/assets/services/auth-service";
import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table";
import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import Search from "antd/es/input/Search";
import type { ColumnType, TablePaginationConfig } from "antd/es/table";
import { useLocation } from "react-router-dom";
import { ColumnConfigDropdown } from "../SubmissionsTable/ColumnConfigDropdown";
import { DeleteButton } from "../SubmissionsTable/DeleteButton";
import { ViewTemplateModal } from "./view-ocr-template-modal";

interface OCRTabState {
	templateData: Template[] | null;
	displayedData: Template[] | null;
	isLoading: boolean;
	isError: boolean;
	viewerSettings: ViewerSettings;
	searchVal: string;
	sortBy: string;
	sortOrder: "asc" | "desc" | undefined;
	columnsShown: Record<string, boolean>;
	total: number;
}

interface ViewerSettings {
	templateName: string | null;
	isOpen: boolean;
}

interface Template {
	name: string;
	manufacturer?: string;
	date_added: string | null;
	hits: number;
	last_hit: string | null;
	average_accuracy: number;
	enabled: boolean;
	template_id: string;
	image_name: string;
}

type Action =
	| { type: "SET_TEMPLATES"; payload: Template[] }
	| { type: "SET_DISPLAYED_DATA"; payload: Template[] }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_ERROR"; payload: boolean }
	| { type: "OPEN_VIEWER"; payload: string }
	| { type: "CLOSE_VIEWER" }
	| { type: "SET_SEARCH_VAL"; payload: string }
	| { type: "TOGGLE_ENABLED"; payload: string }
	| {
			type: "SET_SORT";
			payload: { sortBy: string; sortOrder: "asc" | "desc" | undefined };
	  }
	| { type: "SET_COLUMNS_SHOWN"; payload: Record<string, boolean> }
	| { type: "SET_TOTAL"; payload: number };

const initialState: OCRTabState = {
	templateData: null,
	displayedData: null,
	isLoading: false,
	isError: false,
	viewerSettings: {
		templateName: null,
		isOpen: false,
	},
	searchVal: "",
	sortBy: "name",
	sortOrder: undefined,
	columnsShown: {
		name: true,
		manufacturer: true,
		date_added: true,
		hits: true,
		last_hit: true,
		average_accuracy: true,
		enabled: true,
	},
	total: 0,
};

function reducer(state: OCRTabState, action: Action): OCRTabState {
	switch (action.type) {
		case "SET_TEMPLATES":
			return {
				...state,
				templateData: action.payload,
				isLoading: false,
				isError: false,
			};
		case "SET_DISPLAYED_DATA":
			return { ...state, displayedData: action.payload };
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_ERROR":
			return { ...state, isError: action.payload, isLoading: false };
		case "OPEN_VIEWER":
			return {
				...state,
				viewerSettings: { templateName: action.payload, isOpen: true },
			};
		case "CLOSE_VIEWER":
			return {
				...state,
				viewerSettings: { templateName: null, isOpen: false },
			};
		case "SET_SEARCH_VAL":
			return { ...state, searchVal: action.payload };
		case "TOGGLE_ENABLED":
			const updatedTemplates = state.templateData?.map((template) =>
				template.template_id === action.payload
					? { ...template, enabled: !template.enabled }
					: template,
			);
			return { ...state, templateData: updatedTemplates };
		case "SET_SORT":
			return {
				...state,
				sortBy: action.payload.sortBy,
				sortOrder: action.payload.sortOrder,
			};
		case "SET_COLUMNS_SHOWN":
			return { ...state, columnsShown: action.payload };
		case "SET_TOTAL":
			return { ...state, total: action.payload };
		default:
			return state;
	}
}

const ManageOCRTab: React.FC = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const location = useLocation();

	useEffect(() => {
		const preservedState =
			location.state ||
			JSON.parse(sessionStorage.getItem("manageOCRTabState") || "{}");
		if (preservedState.searchVal)
			dispatch({ type: "SET_SEARCH_VAL", payload: preservedState.searchVal });
		if (preservedState.columnsShown)
			dispatch({
				type: "SET_COLUMNS_SHOWN",
				payload: preservedState.columnsShown,
			});
	}, [location.state]);

	const {
		sortBy,
		sortOrder,
		searchVal,
		isLoading,
		columnsShown,
		total,
		templateData,
		displayedData,
		viewerSettings,
	} = state;

	const toggleSwitch = async (
		e: React.MouseEvent | React.KeyboardEvent,
		templateId: string,
	) => {
		e?.stopPropagation();
		const template = state.templateData?.find(
			(t) => t.template_id === templateId,
		);
		if (!template) return;

		try {
			const token = await auth?.currentUser?.getIdToken();
			const response = await fetch(
				`${import.meta.env.VITE_ALFRED_SERVICE_URL}/FacilityVision/OCR/templates/enable/${template.template_id}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ isEnabled: !template.enabled }),
					method: "POST",
				},
			);

			if (response.status !== 200) throw new Error("Failed to toggle template");
			dispatch({ type: "TOGGLE_ENABLED", payload: template.template_id });
			message.success(
				`Template "${template.name}" has been ${!template.enabled ? "enabled" : "disabled"}.`,
			);
		} catch (error) {
			message.error("Failed to toggle the template. Please try again later.");
			console.error("Error toggling template:", error);
		}
	};

	// Define the columns after toggleSwitch is defined
	const tableColumns: ColumnType<Template>[] = [
		{
			title: <Typography.Text strong>Template</Typography.Text>,
			dataIndex: "name",
			key: "name",
			sorter: true,
			sortOrder:
				sortBy === "name" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (text: string) => <CopyableTableCell text={text} />,
		},
		{
			title: <Typography.Text strong>Manufacturer</Typography.Text>,
			dataIndex: "manufacturer",
			key: "manufacturer",
			sorter: true,
			sortOrder:
				sortBy === "manufacturer" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (text: string | undefined) =>
				text ? <CopyableTableCell text={text} /> : "Not Found",
		},
		{
			title: <Typography.Text strong>Date Added</Typography.Text>,
			dataIndex: "date_added",
			key: "date_added",
			sorter: true,
			sortOrder:
				sortBy === "date_added" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (date: string | null) =>
				date ? (
					<CopyableTableCell text={new Date(date).toLocaleDateString()} />
				) : (
					"No Date"
				),
		},
		{
			title: <Typography.Text strong>Hits</Typography.Text>,
			dataIndex: "hits",
			key: "hits",
			sorter: true,
			sortOrder:
				sortBy === "hits" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (hits: number) => <CopyableTableCell text={hits.toString()} />,
		},
		{
			title: <Typography.Text strong>Last Hit</Typography.Text>,
			dataIndex: "last_hit",
			key: "last_hit",
			sorter: true,
			sortOrder:
				sortBy === "last_hit" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (date: string | null) =>
				date ? (
					<CopyableTableCell text={new Date(date).toLocaleDateString()} />
				) : (
					"No Date"
				),
		},
		{
			title: <Typography.Text strong>Accuracy</Typography.Text>,
			dataIndex: "average_accuracy",
			key: "average_accuracy",
			sorter: true,
			sortOrder:
				sortBy === "average_accuracy" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (accuracy: number) => (
				<CopyableTableCell text={`${Math.round(accuracy * 100)}%`} />
			),
		},
		{
			title: <Typography.Text strong>Enabled</Typography.Text>,
			dataIndex: "enabled",
			key: "enabled",
			sorter: true,
			sortOrder:
				sortBy === "enabled" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (enabled: boolean, record: Template) => (
				<Switch
					checked={enabled}
					onChange={(_, e) => {
						e?.stopPropagation();
						toggleSwitch(e, record.template_id);
					}}
				/>
			),
		},
	];

	const visibleColumns = tableColumns.filter(
		(col) => columnsShown[col.key as string],
	);

	// Fetch templates from the API
	useEffect(() => {
		const fetchTemplates = async () => {
			dispatch({ type: "SET_LOADING", payload: true });
			try {
				const token = await auth?.currentUser?.getIdToken();
				const response = await fetch(
					`${import.meta.env.VITE_ALFRED_SERVICE_URL}/FacilityVision/OCR/templates`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						method: "GET",
					},
				);
				if (response.status !== 200)
					throw new Error("Endpoint returned non-200 status");
				const data = await response.json();

				dispatch({ type: "SET_TEMPLATES", payload: data.templates });
			} catch (error) {
				dispatch({ type: "SET_ERROR", payload: true });
				message.error("⚠️ Failed to load templates, please try again later ⚠️");
				console.error("Error fetching data:", error);
			}
		};
		fetchTemplates();
	}, []);

	// Handle filtering and sorting
	useEffect(() => {
		if (state.templateData) {
			let filteredData = state.templateData.filter(
				(template) =>
					template.name.toLowerCase().includes(state.searchVal.toLowerCase()) ||
					template.manufacturer
						?.toLowerCase()
						.includes(state.searchVal.toLowerCase()),
			);

			dispatch({ type: "SET_TOTAL", payload: filteredData.length });

			if (state.sortBy && state.sortOrder) {
				filteredData = filteredData.sort((a, b) => {
					let compareA: any = a[state.sortBy as keyof Template];
					let compareB: any = b[state.sortBy as keyof Template];

					if (typeof compareA === "string") compareA = compareA.toLowerCase();
					if (typeof compareB === "string") compareB = compareB.toLowerCase();

					if (compareA < compareB) return state.sortOrder === "asc" ? -1 : 1;
					if (compareA > compareB) return state.sortOrder === "asc" ? 1 : -1;
					return 0;
				});
			}

			dispatch({ type: "SET_DISPLAYED_DATA", payload: filteredData });
			const persistState = {
				searchVal: state.searchVal,
				columnsShown,
				total: filteredData.length,
			};
			sessionStorage.setItem("manageOCRTabState", JSON.stringify(persistState));
		}
	}, [
		state.templateData,
		state.searchVal,
		state.sortBy,
		state.sortOrder,
		columnsShown,
	]);

	const openViewer = (templateName: string) =>
		dispatch({ type: "OPEN_VIEWER", payload: templateName });
	const closeViewer = () => dispatch({ type: "CLOSE_VIEWER" });
	const handleSearch = (value: string) =>
		dispatch({ type: "SET_SEARCH_VAL", payload: value });

	const handleTableChange = (
		pagination: TablePaginationConfig,
		_: any,
		sorter: any,
	) => {
		if (sorter?.field && sorter.order) {
			dispatch({
				type: "SET_SORT",
				payload: {
					sortBy: sorter.field,
					sortOrder: sorter.order === "ascend" ? "asc" : "desc",
				},
			});
		} else {
			dispatch({
				type: "SET_SORT",
				payload: { sortBy: "name", sortOrder: undefined },
			});
		}
	};

	return (
		<Card className="manage-ocr-tab mt-2">
			<Space direction="vertical" style={{ width: "100%" }} size="large">
				<Space style={{ display: "flex", justifyContent: "space-between" }}>
					<Space>
						<Search
							placeholder="Search"
							allowClear
							style={{ width: 300 }}
							onSearch={handleSearch}
							onChange={(e) => handleSearch(e.target.value)}
							value={state.searchVal}
							disabled={state.isLoading}
						/>
					</Space>
					<Space>
						<ColumnConfigDropdown
							state={{ tableColumns, columnsShown }}
							dispatch={dispatch}
						/>
						<DeleteButton
							onClick={() => {
								/* TODO: Implement delete functionality */
							}}
						/>
						<Tooltip title="Export Templates">
							<Button
								disabled={true}
								icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
								type="text"
								onClick={() => {
									/* TODO: Implement export functionality */
								}}
							/>
						</Tooltip>
					</Space>
				</Space>
				{state.isError && (
					<Typography.Text type="danger">
						⚠️ Failed to load templates, please try again later ⚠️
					</Typography.Text>
				)}
				<Divider className="m-0" />
				<DFPTable
					columns={visibleColumns}
					dataSource={state.displayedData || []}
					rowKey="template_id"
					loading={state.isLoading}
					pagination={{
						showSizeChanger: true,
						pageSizeOptions: ["5", "10", "20", "50"],
					}}
					onChange={handleTableChange}
					locale={{
						emptyText: !state.isLoading && (
							<DFPEmpty
								className="w-100 h-100"
								description="This organization does not have any OCR templates."
							/>
						),
					}}
					style={{ minHeight: "50vh", alignItems: "center" }}
					rowClassName={() => "clickable-row"}
					onRow={(record) => ({
						onClick: () => {
							openViewer(record.image_name);
						},
					})}
				/>
			</Space>
			<ViewTemplateModal
				viewerSettings={viewerSettings}
				onClose={closeViewer}
			/>
		</Card>
	);
};

export { ManageOCRTab };
