import {
	deleteDraft,
	getDrafts,
	getDraftsByUser,
} from "@services/draft-service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

/**
 * Returns a mutation function that deletes a draft to the system.
 *
 * @return {function} The mutation function.
 */
export const useDeleteDraft = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ organization, draftId }: any) =>
			await deleteDraft(organization, draftId),
		onSuccess: () => {
			queryClient.invalidateQueries("drafts" as any);
			queryClient.invalidateQueries("draftsCount" as any);
		},
	});
};

/**
 * Returns a query object for fetching drafts for a form at a location of an organization.
 *
 * @param {string} org - the organization identifier
 * @param {string} form - the form identifier
 * @param {string} locId - the location identifier
 * @return {object} a query object for fetching drafts
 */
export const useGetDrafts = (org: string, form: string, locId: any) => {
	return useQuery({
		queryKey: ["drafts", org, form, locId],
		queryFn: () => getDrafts(org, form, locId),
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		staleTime: Number.POSITIVE_INFINITY,
	});
};

export const useGetDraftsByUser = (
	org: string | null,
	formId: any,
	userId: undefined,
	submissionId: null | undefined,
) => {
	return useQuery({
		queryKey: ["draftsByUser", org, formId, userId],
		queryFn: () => getDraftsByUser(formId),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		staleTime: Number.POSITIVE_INFINITY,
		enabled: submissionId !== null,
	});
};
