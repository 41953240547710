import { Button } from "antd";
import { useEffect, useState } from "react";
import type { IconType } from "react-icons";
import {
	AiOutlineCloudServer,
	AiOutlineHome,
	AiOutlineLeft,
	AiOutlineLineChart,
	AiOutlineMedicineBox,
	AiOutlineRight,
} from "react-icons/ai";
import { BsBuilding, BsMap } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { FiSettings, FiUsers } from "react-icons/fi";
import { GiProgression } from "react-icons/gi";
import { IoTicketOutline } from "react-icons/io5";
import { MdOutlineHealthAndSafety, MdOutlineHistory } from "react-icons/md";
import { RiKey2Line } from "react-icons/ri";
import { TbClipboardText, TbReportSearch } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import "./dfp-scroll-navigation.scss";

export interface ScrollableNavigationBarProps {
	tabNames: string[];
	basePath: string;
}

export const ScrollableNavigationBar: React.FC<
	ScrollableNavigationBarProps
> = ({ tabNames, basePath }) => {
	const location = useLocation();
	const [tabWidthArr, setTabWidthArr] = useState<number[]>([]);
	const [canScrollLeft, setCanScrollLeft] = useState(false);
	const [canScrollRight, setCanScrollRight] = useState(true);

	const pathSegments = location.pathname.split("/");
	const basePathSegmentCount = basePath?.split("/").length - 1;
	const currentPath =
		pathSegments[basePathSegmentCount + 1] ||
		tabNames[0].toLowerCase().replace(/\s+/g, "-");

	const selectedTabIndex =
		tabNames.findIndex(
			(tab) => tab.toLowerCase().replace(/\s+/g, "-") === currentPath,
		) + 1;

	const sumWidth = (currentTab: number | undefined): number => {
		return tabWidthArr.slice(0, currentTab).reduce((a, c) => a + c, 0);
	};

	useEffect(() => {
		const getTabWidthArr = (): void => {
			const tabElements = document.querySelectorAll(".navbar-animated-item");
			const tabs = Array.from(tabElements).map(
				(tab) => tab.getBoundingClientRect().width,
			);
			setTabWidthArr(tabs);

			const totalWidth = tabs.reduce((acc, width) => acc + width, 0);
			const navElement = document.getElementById("navbar-animated");

			if (navElement) {
				navElement.style.setProperty("--before-width", `${totalWidth}px`);
			}

			checkScrollPosition();
		};
		getTabWidthArr();
		window.addEventListener("resize", getTabWidthArr);
		window.addEventListener("load", getTabWidthArr);

		return () => window.removeEventListener("resize", getTabWidthArr);
	}, []);

	const scrollLeft = (): void => {
		const navElement = document.getElementById("navbar-animated");
		if (navElement) {
			navElement.scrollBy({ left: -150, behavior: "smooth" });
		}
	};

	const scrollRight = (): void => {
		const navElement = document.getElementById("navbar-animated");
		if (navElement) {
			navElement.scrollBy({ left: 150, behavior: "smooth" });
		}
	};

	const checkScrollPosition = (): void => {
		const navElement = document.getElementById("navbar-animated");
		if (navElement) {
			const maxScrollLeft = navElement.scrollWidth - navElement.clientWidth;

			setCanScrollLeft(navElement.scrollLeft > 0);
			setCanScrollRight(navElement.scrollLeft < maxScrollLeft);
		}
	};

	useEffect(() => {
		const navElement = document.getElementById("navbar-animated");
		if (navElement) {
			navElement.addEventListener("scroll", checkScrollPosition);
		}

		return () => {
			if (navElement) {
				navElement.removeEventListener("scroll", checkScrollPosition);
			}
		};
	}, []);

	const iconMapping: { [x: string]: React.ReactElement<IconType> } = {
		Overview: <AiOutlineHome className="tab-icon" />,
		Virtual: <AiOutlineCloudServer className="tab-icon" />,
		Assets: <MdOutlineHealthAndSafety className="tab-icon" />,
		"Predictive Spend": <AiOutlineLineChart className="tab-icon" />,
		Submissions: <TbClipboardText className="tab-icon" />,
		Maintenance: <AiOutlineMedicineBox className="tab-icon" />,
		Settings: <FiSettings className="tab-icon" />,
		"Asset Map": <BsMap className="tab-icon" />,
		Users: <FiUsers className="tab-icon" />,
		"Vision Viewer": <FaEye className="tab-icon" />,
		"Data Update": <IoTicketOutline className="tab-icon" />,
		"Previous Month Audit": <MdOutlineHistory className="tab-icon" />,
		"Current Month Progress": <GiProgression className="tab-icon" />,
		"Master Asset Types": <RiKey2Line className="tab-icon" />,
		"Org Asset Types": <BsBuilding className="tab-icon" />,
		Reports: <TbReportSearch className="tab-icon" />,
		"OCR Template Tool": <IoTicketOutline className="tab-icon" />,
		"Vision Trainer": <FaEye className="tab-icon" />,
		// Add other icons as needed
	};

	return (
		<div className="admin-navbar-container mb-3">
			<div className="d-flex justify-content-center flex-row admin-navbar-wrapper">
				<Button
					type="link"
					onClick={scrollLeft}
					icon={<AiOutlineLeft />}
					disabled={!canScrollLeft}
					size="large"
				/>
				<Nav
					id="navbar-animated"
					className="navbar-animated mb-2 admin-settings-navbar"
					onScroll={checkScrollPosition}
				>
					<div className="position-relative d-flex w-100">
						{tabNames.map((tab: string, i: number) => (
							<NavItem
								className="navbar-animated-item"
								key={tab}
								style={{ display: "inline-block" }}
							>
								<NavLink
									tag={Link}
									to={`${basePath}/${tab.toLowerCase().replace(/\s+/g, "-")}`}
									className={
										selectedTabIndex === i + 1 ? "active-tab" : "inactive-tab"
									}
									style={{ display: "inline-block" }}
								>
									<span className="tab-title">{tab}</span>
									{iconMapping[tab]}
								</NavLink>
							</NavItem>
						))}
						<div
							className="tab-indicator"
							style={{
								left: `${sumWidth(selectedTabIndex - 1)}px`,
								width: `${tabWidthArr[selectedTabIndex - 1]}px`,
							}}
						/>
					</div>
				</Nav>
				<Button
					type="link"
					onClick={scrollRight}
					icon={<AiOutlineRight />}
					disabled={!canScrollRight}
					size="large"
				/>
			</div>
		</div>
	);
};
