import { default as initInputs } from "@constants/formInputs/taggingFormInputs";
import { deepCopy } from "@utils/deepCopy";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { StoreApi, UseBoundStore, create } from "zustand";

interface ChatbotStore {
	lastMessageCategory: string;
	setLastMessageCategory: (category: string) => void;
	assetMapNewAsset: any;
	setAssetMapNewAsset: (assetMapNewAsset: any) => void;
	assetMapDimensions: any;
	setAssetMapDimensions: (assetMapDimensions: any) => void;
	assetTagScanned: boolean;
	setAssetTagScanned: (assetTagScanned: boolean) => void;
	flags: any;
	setFlags: (flags: any) => void;
	alertModal: any;
	setAlertModal: (alertModal: any) => void;
	selectedPhotoUrl: any;
	setSelectedPhotoUrl: (selectedPhotoUrl: any) => void;
	isPhotoShowing: boolean;
	setIsPhotoShowing: (isPhotoShowing: boolean) => void;
	selectedItem: any;
	setSelectedItem: (selectedItem: any) => void;
	refToOpen: any;
	setRefToOpen: (refToOpen: any) => void;
	assetTypes: any;
	setAssetTypes: (assetTypes: any) => void;
	manufacturerError: boolean;
	setManufacturerError: (manufacturerError: boolean) => void;
	assetTagError: boolean;
	setAssetTagError: (assetTagError: boolean) => void;
	fieldError: any;
	setFieldError: (fieldName: any, errorMessage: any) => void;
	tempAlertError: boolean;
	setTempAlertError: (tempAlertError: boolean) => void;
	isEditing: boolean;
	setIsEditing: (isEditing: boolean) => void;
	isEditingCushman: boolean;
	setIsEditingCushman: (isEditingCushman: boolean) => void;
	isQA: boolean;
	setIsQA: (isQA: boolean) => void;
	savedLocation: any;
	setSavedLocation: (savedLocation: any) => void;
	savedLocationCushman: any;
	setSavedLocationCushman: (savedLocationCushman: any) => void;
	chosenAssetType: any;
	setChosenAssetType: (chosenAssetType: any) => void;
	photoToBeCropped: any;
	setPhotoToBeCropped: (photoToBeCropped: any) => void;
	isCropShowing: boolean;
	setIsCropShowing: (isCropShowing: boolean) => void;
	ocrConfidenceAndColor: any;
	setOcrConfidenceAndColor: (ocrConfidenceAndColor: any) => void;
	showDraftSelectionModal: boolean;
	setShowDraftSelectionModal: (showDraftSelectionModal: boolean) => void;
	showDraftSelectionModalCushman: boolean;
	setShowDraftSelectionModalCushman: (
		showDraftSelectionModalCushman: boolean,
	) => void;
	startNew: boolean;
	setStartNew: (startNew: boolean) => void;
	startNewCushman: boolean;
	setStartNewCushman: (startNewCushman: boolean) => void;
	draftId: any;
	setDraftId: (draftId: any) => void;
	airportCode: any;
	setAirportCode: (airportCode: any) => void;
	responseData: any;
	setResponseData: (responseData: any) => void;
	resetResponseData: () => void;
	warrantyDetailsSwarovski: any;
	setWarrantyDetailsSwarovski: (warrantyDetailsSwarovski: any) => void;
	currentPage: any;
	setCurrentPage: (currentPage: any) => void;
	dupWarningShown: boolean;
	setDupWarningShown: (dupWarningShown: boolean) => void;
	ocrData: any;
	setOcrData: (ocrData: any) => void;
	conditionRead: string;
	setConditionRead: (conditionRead: string) => void;
	formName: string;
	setFormName: (formName: string) => void;
	userLocation: any;
	setUserLocation: (userLocation: any) => void;
	showExtractedModal: boolean;
	setShowExtractedModal: (showExtractedModal: boolean) => void;
	inputs: any;
	setInputs: (inputs: any) => void;
	options: any;
	setOptions: (options: any) => void;
	manufacturers: any;
	setManufacturers: (manufacturers: any) => void;
	smartManufacturers: any;
	setSmartManufacturers: (smartManufacturers: any) => void;
	formLogo: string;
	setFormLogo: (formLogo: string) => void;
	formId: string;
	setFormId: (formId: string) => void;
	formsSnap: any;
	setFormsSnap: (formsSnap: any) => void;
	inputsRequired: boolean;
	setInputsRequired: (inputsRequired: boolean) => void;
	orgSpecificInputs: any;
	setOrgSpecificInputs: (orgSpecificInputs: any) => void;
	additionalLocationInputs: any;
	setAdditionalLocationInputs: (additionalLocationInputs: any) => void;
	assetConditionalInputs: any;
	setAssetConditionalInputs: (assetConditionalInputs: any) => void;
	shouldAutoSave: any;
	setShouldAutoSave: (shouldAutoSave: any) => void;
	setInputData: (item: any, value: any, isEquipmentRead: any) => void;
	assetPhoto: any;
	setAssetPhoto: (assetPhoto: any) => void;
	platePhoto: any;
	setPlatePhoto: (platePhoto: any) => void;
	tempAlertPhoto: any;
	setTempAlertPhoto: (tempAlertPhoto: any) => void;
	assetTagPhoto: any;
	setAssetTagPhoto: (assetTagPhoto: any) => void;
	currentState: any;
	setCurrentState: (currentState: any) => void;
	assetTypeName: string;
	setAssetTypeName: (assetTypeName: string) => void;
	categories: any;
	setCategories: (categories: any) => void;
}

export const useChatbotStore: UseBoundStore<StoreApi<ChatbotStore>> = create(
	(set) => ({
		lastMessageCategory: "",
		setLastMessageCategory: (category: any) =>
			set({ lastMessageCategory: category }),
		assetMapNewAsset: null,
		setAssetMapNewAsset: (assetMapNewAsset: any) => set({ assetMapNewAsset }),
		assetMapDimensions: {},
		setAssetMapDimensions: (assetMapDimensions: any) =>
			set({ assetMapDimensions }),
		assetTagScanned: false,
		setAssetTagScanned: (assetTagScanned: any) => set({ assetTagScanned }),
		flags: {},
		setFlags: (flags: any) => set({ flags }),
		alertModal: {
			show: false,
			title: "",
			body: "",
			options: [],
		},
		setAlertModal: (alertModal: any) => set({ alertModal }),
		selectedPhotoUrl: "",
		setSelectedPhotoUrl: (selectedPhotoUrl: any) => set({ selectedPhotoUrl }),
		isPhotoShowing: false,
		setIsPhotoShowing: (isPhotoShowing: any) => set({ isPhotoShowing }),
		selectedItem: {},
		setSelectedItem: (selectedItem: any) => set({ selectedItem }),
		refToOpen: {},
		setRefToOpen: (refToOpen: any) => set({ refToOpen }),
		assetTypes: [],
		setAssetTypes: (assetTypes: any) => set({ assetTypes }),
		manufacturerError: false,
		setManufacturerError: (manufacturerError: any) =>
			set({ manufacturerError }),
		assetTagError: false,
		setAssetTagError: (assetTagError: any) => set({ assetTagError }),
		fieldError: {},
		setFieldError: (fieldName: any, errorMessage: any) =>
			set((state: { fieldError: any }) => ({
				fieldError: {
					...state.fieldError,
					[fieldName]: errorMessage,
				},
			})),
		tempAlertError: false,
		setTempAlertError: (tempAlertError: any) => set({ tempAlertError }),
		isEditing: false,
		setIsEditing: (isEditing: any) => set({ isEditing }),
		isEditingCushman: false,
		setIsEditingCushman: (isEditingCushman: any) => set({ isEditingCushman }),
		isQA: false,
		setIsQA: (isQA: any) => set({ isQA }),
		savedLocation: null,
		setSavedLocation: (savedLocation: any) => set({ savedLocation }),
		savedLocationCushman: null,
		setSavedLocationCushman: (savedLocationCushman: any) =>
			set({ savedLocationCushman }),
		chosenAssetType: null,
		setChosenAssetType: (chosenAssetType: any) => set({ chosenAssetType }),
		photoToBeCropped: null,
		setPhotoToBeCropped: (photoToBeCropped: any) => set({ photoToBeCropped }),
		isCropShowing: false,
		setIsCropShowing: (isCropShowing: any) => set({ isCropShowing }),
		ocrConfidenceAndColor: {},
		setOcrConfidenceAndColor: (ocrConfidenceAndColor: any) =>
			set({ ocrConfidenceAndColor }),
		showDraftSelectionModal: false,
		setShowDraftSelectionModal: (showDraftSelectionModal: any) =>
			set({ showDraftSelectionModal }),
		showDraftSelectionModalCushman: false,
		setShowDraftSelectionModalCushman: (showDraftSelectionModalCushman: any) =>
			set({ showDraftSelectionModalCushman }),
		startNew: false,
		setStartNew: (startNew: any) => set({ startNew }),
		startNewCushman: false,
		setStartNewCushman: (startNewCushman: any) => set({ startNewCushman }),
		draftId: undefined,
		setDraftId: (draftId: any) => set({ draftId }),
		airportCode: null,
		setAirportCode: (airportCode: any) => set({ airportCode }),
		responseData: {},
		setResponseData: (responseData: any) =>
			set((state: { responseData: any }) => ({
				responseData: { ...state.responseData, ...responseData },
			})),
		resetResponseData: () =>
			set((state: { savedLocation: { id: any } }) => ({
				responseData: {
					beginDate: new Date().toISOString(),
					location: state.savedLocation?.id,
				},
			})),
		warrantyDetailsSwarovski: undefined,
		setWarrantyDetailsSwarovski: (warrantyDetailsSwarovski: any) =>
			set({ warrantyDetailsSwarovski }),
		currentPage: 3,
		setCurrentPage: (currentPage: any) => set({ currentPage }),
		dupWarningShown: false,
		setDupWarningShown: (dupWarningShown: any) => set({ dupWarningShown }),
		ocrData: {},
		setOcrData: (ocrData: any) => set({ ocrData }),
		conditionRead: "",
		setConditionRead: (conditionRead: any) => set({ conditionRead }),
		formName: "",
		setFormName: (formName: any) => set({ formName }),
		userLocation: {},
		setUserLocation: (userLocation: any) => set({ userLocation }),
		showExtractedModal: true,
		setShowExtractedModal: (showExtractedModal: any) =>
			set({ showExtractedModal }),
		inputs: deepCopy(initInputs),
		setInputs: (inputs: any) => set({ inputs }),
		options: [],
		setOptions: (options: any) => set({ options }),
		manufacturers: [],
		setManufacturers: (manufacturers: any) => set({ manufacturers }),
		smartManufacturers: [],
		setSmartManufacturers: (smartManufacturers: any) =>
			set({ smartManufacturers }),
		formLogo: "",
		setFormLogo: (formLogo: any) => set({ formLogo }),
		formId: "",
		setFormId: (formId: any) => set({ formId }),
		formsSnap: null,
		setFormsSnap: (formsSnap: any) => set({ formsSnap }),
		inputsRequired: true,
		setInputsRequired: (inputsRequired: any) => set({ inputsRequired }),
		orgSpecificInputs: [],
		setOrgSpecificInputs: (orgSpecificInputs: any) =>
			set({ orgSpecificInputs }),
		additionalLocationInputs: [],
		setAdditionalLocationInputs: (additionalLocationInputs: any) =>
			set({ additionalLocationInputs }),
		assetConditionalInputs: [],
		setAssetConditionalInputs: (assetConditionalInputs: any) =>
			set({ assetConditionalInputs }),
		shouldAutoSave: null,
		setShouldAutoSave: (shouldAutoSave: any) => set({ shouldAutoSave }),
		setInputData: (
			item: { key: string; type: string; tiedTo: any },
			value: any,
			isEquipmentRead: any,
		) =>
			set(
				(state: {
					responseData: any;
					flags: { differentAssetTypesBasedOnLocation: any };
					setChosenAssetType: (arg0: null) => void;
				}) => {
					const response = state.responseData;
					response[item.key] = value;

					const setNewState = (newState: {
						[x: string]: any;
						assetType: any;
					}) => {
						// if checkbox is checked, delete key associated with checkbox within responseData
						if (item.type === "checkbox" && value) {
							const tiedToValues = [];
							if (typeof item.tiedTo === "string") {
								tiedToValues.push(item.tiedTo);
							} else if (Array.isArray(item.tiedTo)) {
								tiedToValues.push(...item.tiedTo);
							}
							for (const tiedTo of tiedToValues) {
								if (!newState[tiedTo] || tiedTo.toLowerCase().includes("image"))
									continue;
								delete newState[tiedTo];
							}
						}
						if (
							state.flags?.differentAssetTypesBasedOnLocation &&
							item.key === "assetLocation" &&
							!isEquipmentRead
						) {
							newState.assetType = undefined;
							state.setChosenAssetType(null);
						}
					};

					setNewState(response);
					return { responseData: response };
				},
			),
		assetPhoto: "",
		setAssetPhoto: (assetPhoto: any) => set({ assetPhoto }),
		platePhoto: "",
		setPlatePhoto: (platePhoto: any) => set({ platePhoto }),
		tempAlertPhoto: "",
		setTempAlertPhoto: (tempAlertPhoto: any) => set({ tempAlertPhoto }),
		assetTagPhoto: "",
		setAssetTagPhoto: (assetTagPhoto: any) => set({ assetTagPhoto }),
		currentState: {
			showOptions: false,
			stage: "",
			otherLocation: false,
			startNew: false,
			options: [],
		},
		setCurrentState: (currentState: any) =>
			set((state: { currentState: any }) => ({
				currentState: {
					...state.currentState,
					...currentState,
				},
			})),
		assetTypeName: "",
		setAssetTypeName: (assetTypeName: any) => set({ assetTypeName }),
		categories: [],
		setCategories: (categories: any) => set({ categories }),
	}),
);

export const useChatbotHighLevelStore = create((set) => ({
	formInProgress: false,
	setFormInProgress: (formInProgress: any) => set({ formInProgress }),
	assetTypeInput: "",
	setAssetTypeInput: (assetTypeInput: any) => set({ assetTypeInput }),
	highLevelAlertModal: {
		show: false,
		title: "",
		body: "",
		options: [],
	},
	setHighLevelAlertModal: (highLevelAlertModal: any) =>
		set({ highLevelAlertModal }),
	closeHighLevelAlertModal: () =>
		set({
			highLevelAlertModal: {
				show: false,
				title: "",
				body: "",
				options: [],
			},
		}),
	submitDisabled: false,
	setSubmitDisabled: (submitDisabled: any) => set({ submitDisabled }),
}));

if (import.meta.env.NODE_ENV === "development") {
	mountStoreDevtool("chatbotStore", useChatbotStore);
	mountStoreDevtool("chatbotHighLevelStore", useChatbotHighLevelStore);
}
