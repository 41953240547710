import { ReAuthenticateModal } from "@SignedIn/ReAuthenticateModal/ReAuthenticateModal";
import { auth, db } from "@assets/services/auth-service";
import { updateEmail } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const EmailModal = ({ userEmail, setIsToastShowing }) => {
	const [emailModal, setEmailModal] = useState(false);
	const [emailInput, setEmailInput] = useState("");

	const [authModalShowing, setAuthModalShowing] = useState(false);
	const toggleAuthModal = () => setAuthModalShowing(!authModalShowing);

	const handleChange = (e) => {
		setEmailInput(e.target.value);
	};

	const toggleEmailModal = () => setEmailModal(!emailModal);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const user = auth.currentUser;
		const userDoc = doc(db, "users", auth.currentUser.uid);

		updateEmail(user, emailInput);
		await updateDoc(userDoc, {
			email: emailInput,
		})
			.then(() => {
				setEmailModal(false);
				setIsToastShowing({
					showing: true,
					title: "Success",
					subtitle: "Email successfully updated.",
					position: "align-profile",
					type: "success",
				});
				setTimeout(() => {
					setIsToastShowing({
						showing: false,
					});
				}, 2500);
			})
			.catch((error) => {
				alert(error);
			});
	};

	return (
		<div>
			<span onClick={toggleAuthModal} className="profile-modal-toggle mx-2">
				Change Email
			</span>
			<ReAuthenticateModal
				showing={authModalShowing}
				toggleNextModal={toggleEmailModal}
				toggle={toggleAuthModal}
			/>
			<Modal
				id="email-modal"
				centered="true"
				isOpen={emailModal}
				toggle={toggleEmailModal}
			>
				<ModalHeader toggle={toggleEmailModal}>Email</ModalHeader>
				<ModalBody>
					<p>
						Your current email is <strong>{userEmail}</strong>
					</p>
					<div className="d-flex flex-column">
						<h6 className="profile-category">New Email Address</h6>

						<div className="position-relative">
							<form onSubmit={handleSubmit}>
								<input
									onChange={handleChange}
									value={emailInput}
									type="email"
									placeholder="Enter new email address"
									className="mb-4"
									required
								/>
								<Button
									disabled={`${!emailInput.length ? "disabled" : ""}`}
									className="bg-green border-none"
									type="submit"
								>
									Change Email
								</Button>
							</form>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export { EmailModal };
