import { useChatbotStore, useFormStore } from "@stores";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { GeneratedPropIcon } from "../../AssetProfile/GeneratedPropIcon";
import { PreviewField } from "./PreviewField";

const NumberInput = ({
	question,
	responseData,
	setInputData,
	hidePreview,
	fromChatbot,
	placeholder,
}) => {
	const { fieldError: chatbotFieldError, setFieldError: setChatbotFieldError } =
		useChatbotStore();
	const { fieldError: formFieldError, setFieldError: setFormFieldError } =
		useFormStore();
	const fieldError = fromChatbot ? chatbotFieldError : formFieldError;
	const setFieldError = fromChatbot ? setChatbotFieldError : setFormFieldError;

	const handleChange = (e) => {
		const val = e.target.value == "" ? null : e.target.value;

		if (question.key === "numDoors") {
			let value = "";
			if (val >= 5 && val <= 99) {
				value = "Remote";
			} else if (val < 5) {
				value = "Self Contained";
			}

			setInputData({ key: "typeOfSystem" }, value);
		}
		setInputData(question, val);

		if (question.required === "true" && !val) {
			setFieldError(question.key, " Number of Doors field is required");
		} else {
			setFieldError(question.key, "");
		}
	};

	return (
		<Row key={question.key}>
			<FormGroup>
				<div className="d-flex flex-row gap-1">
					<Label className="single-form-label">
						{question.label}
						{question.required === "true" && (
							<span style={{ color: "red" }}> *</span>
						)}
					</Label>
					{!hidePreview ? (
						<PreviewField
							question={question}
							responseData={responseData}
							fromChatbot={fromChatbot}
						/>
					) : null}
					{!responseData[question.key] && placeholder && <GeneratedPropIcon />}
				</div>
				<br />
				<Input
					type={"number"}
					onChange={handleChange}
					className={`text-area ${
						fieldError[question.key] ? "error-border" : ""
					}`}
					defaultValue={responseData[question.key]}
					value={responseData[question.key] || ""}
					disabled={
						responseData[question.tiedTo] === true || question.disabled === true
					}
					placeholder={placeholder || question.placeholder}
				/>
				{fieldError[question.key] && (
					<div className="error-message">{fieldError[question.key]}</div>
				)}
			</FormGroup>
		</Row>
	);
};

export { NumberInput };
