import { DB_ORG } from "@constants/db";
import Fuse from "fuse.js";
const searchLocations = async (val, list, org) => {
	if (val.length > 2) {
		console.log(list);
		const options = {
			includeScore: true,
			keys: ["data.name", "data.city", "data.state", "data.zip"],
			threshold: 0.25,
		};

		if (org !== DB_ORG.CUSHMANWAKEFIELD) {
			options.keys.push("data.address");
			options.keys.push("data.address1");
			options.keys.push("data.address2");
		}

		const fuse = new Fuse(list, options);

		const results = await fuse.search(val);

		let returnVal = [];

		for (const res of results) {
			returnVal = [...returnVal, res.item];
		}

		return returnVal;
	}
	return [];
};

export { searchLocations };
