import { db } from "@assets/services/auth-service";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import { Loader } from "../../Loader/Loader";
import { AssetHealthScore } from "./AssetHealthScore";

const AssetTagTooltip = ({ tagContent, label, organization, id }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [assetList, setAssetList] = useState([]);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	useEffect(() => {
		getAssetList();
	}, []);

	const getAssetList = async () => {
		const assets = [];
		const assetsRef = collection(db, "organizations", organization, "assets");
		const q = query(assetsRef, orderBy("healthScore", "desc"), limit(10));
		const assetsSnap = await getDocs(q);
		for (const asset of assetsSnap.docs) {
			assets.push(asset.data());
		}
		setAssetList(assets);
		setIsLoading(false);
	};

	return (
		<div>
			<div className="asset-profile-tag-wrapper">
				<div className="asset-profile-tag" rel="noreferrer" id={id}>
					<span>{tagContent}</span>
				</div>
				<span className="mx-2" style={{ color: "#8c8c8c" }}>
					{label}
				</span>
			</div>
			<Tooltip isOpen={false} target={id} toggle={toggle}>
				{isLoading ? (
					<Loader />
				) : (
					<>
						<h5>{label}</h5>
						{assetList.map((asset) => {
							return (
								<div
									key={asset.id}
									className="d-flex justify-content-between align-items-center mb-2"
									style={{ minWidth: "300px" }}
								>
									<img
										style={{
											width: "50px",
											height: "50px",
										}}
										src={asset.other.equipmentAssetImage}
									/>
									<span className="text-start">{asset.assetType}</span>
									<div>
										<AssetHealthScore healthScore={asset.healthScore} />
									</div>
								</div>
							);
						})}
					</>
				)}
			</Tooltip>
		</div>
	);
};

export { AssetTagTooltip };
