import { FormGroup, Input, Label, Row } from "reactstrap";
import { GeneratedPropIcon } from "../../AssetProfile/GeneratedPropIcon";

const TextAreaInput = ({
	question,
	responseData,
	setInputData,
	placeholder,
}) => {
	return (
		<Row key={question.key}>
			<FormGroup key={question.key}>
				<div className="d-flex flex-row gap-1">
					<Label className="single-form-label">
						{question.label}
						{question.required === "true" && (
							<span style={{ color: "red" }}> *</span>
						)}
						{!responseData[question.key] && placeholder && (
							<GeneratedPropIcon />
						)}
					</Label>
				</div>
				<br />
				<Input
					key={question.key}
					type={question.type}
					onChange={(e) => {
						setInputData(question, e.target.value.toUpperCase());
					}}
					className={question.type !== "checkbox" ? "form-inputs" : ""}
					defaultValue={responseData[question.key]}
					placeholder={placeholder || question.placeholder}
				/>
			</FormGroup>
		</Row>
	);
};

export { TextAreaInput };
