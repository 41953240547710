import { Progress } from "antd";
import type { FC } from "react";

interface HealthScorePillProps {
	healthScore: number | undefined;
	size?: "md" | "lg";
	className?: string;
}

const HealthScorePill: FC<HealthScorePillProps> = ({
	healthScore,
	size = "md",
	className = "w-100",
}) => {
	const getStrokeColor = (valueEnd: number) => {
		if (valueEnd >= 75) {
			return "#3f6e01"; // Green
		}
		if (valueEnd >= 25 && valueEnd < 75) {
			return "#e0a724"; // Yellow
		}
		return "#bf1e2d"; // Red
	};

	const bubbleSize = size === "md" ? 24 : 36;
	const fontSize = size === "md" ? 10 : 15;

	const getBubblePosition = (valueEnd: number) => {
		if (valueEnd === 0) {
			return "0px"; // Align flush to the start at 0
		}
		if (valueEnd >= 95) {
			return `calc(100% - ${bubbleSize}px)`; // Limit to the end for 95 and above
		}
		return `calc(${valueEnd}% - ${bubbleSize / 2}px)`; // Center the bubble
	};

	return (
		<div className="health-score-pill justify-content-lg-end align-content-lg-end justify-content-center align-items-center d-flex w-100 h-100">
			<div
				className={`health-score-container ${className}`}
				style={{ position: "relative", minWidth: "200px" }}
			>
				<div
					className={"health-dot"}
					style={{
						position: "absolute",
						left: getBubblePosition(healthScore ?? 0),
						width: `${bubbleSize}px`,
						height: `${bubbleSize}px`,
						fontSize: `${fontSize}px`,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "50%",
						color: "#fff",
						backgroundColor: getStrokeColor(healthScore ?? 0),
					}}
				>
					{healthScore || 0}
				</div>
				<Progress
					percent={healthScore ?? 0}
					showInfo={false}
					strokeColor={getStrokeColor(healthScore ?? 0)}
					trailColor="#f0f0f0"
				/>
			</div>
		</div>
	);
};

export { HealthScorePill };
