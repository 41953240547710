import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { CopyableCellRenderer } from "@/components/Tables/Renderers/copyable-cell-renderer";
import type { StringMap } from "@/models/abstractTypes";
import { lookupModels } from "@/services/manufacturer-service";
import { decamelize, parseDateToUTCString } from "@/utils/transforms";
import {
	Button,
	Card,
	List,
	Space,
	Tag,
	Timeline,
	Typography,
	theme,
} from "antd";
import { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";

const { Text } = Typography;

interface EditLogProps {
	editLog: any[];
}

interface ParsedField {
	field: string;
	oldValue: string | number | boolean;
	newValue: string | number | boolean;
}

const EditLog: React.FC<EditLogProps> = ({ editLog }) => {
	const [parsedEdits, setParsedEdits] = useState<any[]>([]);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [collapsedItems, setCollapsedItems] = useState<{
		[key: number]: boolean;
	}>({});
	const { token } = theme.useToken();

	const parseFields = async (
		field: string,
		oldEntry: any,
		newEntry: any,
	): Promise<ParsedField | null> => {
		const oldValue = oldEntry ? oldEntry[field] : undefined;
		const newValue = newEntry ? newEntry[field] : undefined;

		if (field === "date_of_birth") {
			return null;
		}

		switch (field) {
			case "model_id": {
				const models = await lookupModels([oldValue, newValue]);
				return {
					field: "Model",
					oldValue: models[0]?.manufacturer?.name
						? `${models[0]?.manufacturer?.name} - ${models[0]?.model_number || "N/A"}`
						: "Not Set",
					newValue: models[1]?.manufacturer?.name
						? `${models[1]?.manufacturer?.name} - ${models[1]?.model_number || "N/A"}`
						: "Not Set",
				};
			}
			case "unique_fields":
				return null;
			default:
				if (oldValue !== newValue) {
					return {
						field,
						oldValue: oldValue !== undefined ? oldValue : "Not Set",
						newValue: newValue !== undefined ? newValue : "Not Set",
					};
				}
				return null;
		}
	};

	useEffect(() => {
		const processEdits = async () => {
			const editsData = await Promise.all(
				editLog.map(async (entry) => {
					if (!entry) return null;
					if (
						(!entry.original_data || entry.original_data.length === 0) &&
						(!entry.new_data || entry.new_data.length === 0)
					)
						return null;

					const changes = [];
					for (
						let i = 0;
						i < Math.max(entry.original_data.length, entry.new_data.length);
						i++
					) {
						const oldEntry = entry.original_data[i] || {};
						const newEntry = entry.new_data[i] || {};

						const fields = new Set([
							...Object.keys(oldEntry),
							...Object.keys(newEntry),
						]);

						for (const field of fields) {
							const parsedResult = await parseFields(field, oldEntry, newEntry);
							if (parsedResult) {
								changes.push({
									field: decamelize(parsedResult.field),
									oldValue: parsedResult.oldValue,
									newValue: parsedResult.newValue,
								});
							}
						}
					}

					if (changes.length > 0) {
						return {
							timestamp: entry.timestamp,
							user:
								entry.user?.name ||
								decamelize(entry?.system_account) ||
								"Unknown User",
							changes,
						};
					}
					return null;
				}),
			);

			const filteredEdits = editsData.filter((edit) => edit !== null);
			const sortedEdits = filteredEdits.sort(
				(a, b) =>
					new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
			);
			setParsedEdits(sortedEdits || []);
		};

		processEdits();
	}, [editLog]);

	const renderEditedBy = (edit: any) => {
		return <Tag color={token.colorWarningBorderHover}>{edit.user}</Tag>;
	};

	const renderDateTitle = (edit: any) => {
		return <Text strong>{parseDateToUTCString(edit.timestamp, true)}</Text>;
	};

	const timelineItems = parsedEdits.map((edit, index) => {
		const isItemCollapsed = collapsedItems[index];

		return {
			key: `${index}-${edit.timestamp}`,
			children: (
				<Card
					type="inner"
					title={renderDateTitle(edit)}
					extra={
						<Space>
							{renderEditedBy(edit)}
							<Button
								size="small"
								type="primary"
								onClick={() => {
									setCollapsedItems((prev) => ({
										...prev,
										[index]: !prev[index],
									}));
								}}
							>
								{isItemCollapsed ? "Expand" : "Hide"}
							</Button>
						</Space>
					}
					styles={isItemCollapsed ? { body: { display: "none" } } : {}}
				>
					<List
						locale={{
							emptyText: (
								<DFPEmpty description="There is no edit history for this submission." />
							),
						}}
						dataSource={edit.changes}
						renderItem={(change: StringMap) => (
							<List.Item>
								<Space direction="vertical">
									<Text strong>{change.field}</Text>
									<Space>
										{change.oldValue !== null && (
											<CopyableCellRenderer
												className="red"
												value={
													<Text type="danger">
														{change.oldValue.toString()}
													</Text>
												}
											/>
										)}
										{change.oldValue !== null && change.newValue !== null && (
											<BsArrowRight />
										)}
										{change.newValue !== null && (
											<CopyableCellRenderer
												className="green"
												value={change.newValue.toString()}
											/>
										)}
									</Space>
								</Space>
							</List.Item>
						)}
					/>
				</Card>
			),
		};
	});

	return (
		<Card
			title="Edit History"
			extra={
				<Button type="primary" onClick={() => setIsCollapsed((prev) => !prev)}>
					{isCollapsed ? "Expand" : "Hide"}
				</Button>
			}
			styles={isCollapsed ? { body: { display: "none" } } : { body: {} }}
		>
			{!isCollapsed && <Timeline items={timelineItems} />}
		</Card>
	);
};

export { EditLog };
