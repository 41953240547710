export const inputs = [
	{
		title: "Property Condition Assessment",
		sections: [
			{
				title: "Basic Information",
				questions: [
					{
						label: "Store Name",
						key: "location",
						value: null,
					},
					{
						label: "Date of Visit",
						key: "dateOfVisit",
						value: null,
					},

					//Add categories with two options for NTV (non traditional venue) and conventional prototypes as one of the first questions when entering the site
					{
						type: "select",
						label: "Select Prototype",
						key: "prototype",
						options: ["Conventional", "NTV"],
						value: null,
					},
				],
			},

			//- add question for if the location has vestibules. and add text field to Collect measurements for both doors and sidelights
			{
				title: "Vestibules Information",
				questions: [
					{
						type: "text",
						label: "Vestibules",
						responseTag: "vestibules",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Door Measurements",
						responseTag: "doorMeasurements",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Sidelight Measurements",
						responseTag: "sidelightMeasurements",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Device Information",
				questions: [
					{
						type: "text",
						label: "Freedom Pay device model(s)",
						responseTag: "deviceModel",
						value: "",
					},
					{
						type: "text",
						label: "Number of headset extenders",
						responseTag: "headsetQuantity",
						value: "",
					},
					{
						type: "text",
						label: "Placement of headset extenders",
						responseTag: "headsetPlacement",
						value: "",
					},
					{
						type: "image",
						label: "Placement of headset extenders Image",
						responseTag: "imageHeadsetPlacement",
						multiple: true,
						value: [],
					},
					{
						type: "text",
						label: "Music system",
						responseTag: "musicSystem",
						value: "",
					},
				],
			},
			{
				title: "Camera Information",
				questions: [
					{
						type: "text",
						label: "Total number of cameras",
						responseTag: "numCameras",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of external cameras",
						responseTag: "numExternalCameras",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Network Information",
				questions: [
					{
						type: "text",
						label: "Location of WAPs",
						responseTag: "wapLocation",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of POS WAPs",
						responseTag: "numPOSWaps",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of office WAPs",
						responseTag: "numOfficeWaps",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Drive Thru Information",
				questions: [
					{
						type: "text",
						label: "Sprinkler system?",
						responseTag: "sprinklerSystem",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Corral type",
						responseTag: "imageCorralType",
						multiple: true,
					},
					{
						type: "text",
						label: "Drive Thru Expo lane ?",
						responseTag: "expoLane",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Drive Thru pass through lane?",
						responseTag: "passThroughLane",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Crew canopy?",
						responseTag: "crewCanopy",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Drive Thru canopy fans",
						responseTag: "canopyFans",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Drive Thru canopy misters",
						responseTag: "canopyMisters",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Drive Thru heaters",
						responseTag: "heaters",
						placeholder: "Description",
					},
					// {
					// 	type: 'text',
					// 	label: 'Drive Thru outside hand sink',
					// 	responseTag: 'outsideHandSink',
					// 	placeholder: 'Description',
					// },

					//- For Drive thru preview panels add field for dimensions and number of panels. WxH

					{
						type: "text",
						label: "Dimensions of Drive Thru Panels",
						responseTag: "dimensionsDriveThruPanels",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Parking Information",
				questions: [
					{
						type: "text",
						label: "Electric vehicle charging spots?",
						responseTag: "chargingSpots",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Location & number of curbside signs",
						responseTag: "curbsideSigns",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of handicapped spots",
						responseTag: "handicappedSpots",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of parking spots",
						responseTag: "parkingSpots",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Patio Information",
				questions: [
					{
						type: "text",
						label: "Patio style",
						responseTag: "patioStyle",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Dumpster Information",
				questions: [
					{
						type: "text",
						label: "Size of dumpster pad",
						responseTag: "dumpsterPadSize",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Dumpster Image",
						responseTag: "imageDumpsterPad",
						multiple: true,
					},
					{
						type: "text",
						label: "Size of dumpsters",
						responseTag: "dumpsterSize",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Dumpster Image",
						responseTag: "imageDumpster",
						multiple: true,
					},
				],
			},
			{
				title: "Kitchen Information",
				questions: [
					{
						type: "text",
						label: "Kitchen Type",
						responseTag: "kitchenType",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Kitchen Type Image",
						responseTag: "kitchenTypeImage",
						multiple: true,
					},

					//add option for Single and dual cookline for the kitchen

					{
						type: "select",
						label: "Single or Dual Cookline",
						responseTag: "cookline",
						options: ["Single", "Dual"],
					},
					{
						type: "text",
						label: "Size of grease trap[s]",
						responseTag: "greaseTrapSize",
						placeholder: "Description",
					},
					{
						type: "select",
						label: "Size of bird carts",
						responseTag: "birdCartSize",
						options: ["Single", "Double"],
					},
					{
						type: "text",
						label: "Measurement of bird carts",
						responseTag: "birdCartMeasurement",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Bird Cart Image",
						responseTag: "imageBirdCartSize",
						multiple: true,
					},

					//////
					{
						type: "select",
						label: "Size of bird house",
						responseTag: "birdHouseSize",
						options: ["Single", "Double"],
					},
					{
						type: "text",
						label: "Measurement of bird house",
						responseTag: "birdHouseMeasurement",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Bird House Image",
						responseTag: "imageBirdHouseSize",
						multiple: true,
					},

					{
						type: "select",
						label: "Brand of bird houses",
						responseTag: "birdHouseBrand",
						options: ["Marshall Air", "Carter Hoffman"],
					},
				],
			},
			{
				title: "Fryer Information",
				questions: [
					{
						type: "text",
						label: "Total number of fryers",
						responseTag: "numFryers",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of 2 bank fryers",
						responseTag: "numOfTwoBankFryers",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of 3 bank fryers",
						responseTag: "numOfThreeBankFryers",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Electric or gas fryer",
						responseTag: "fryer",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of hood vents",
						responseTag: "numHoodVents",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Size of hood vent",
						responseTag: "sizeHoodVent",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Grill Information",
				questions: [
					{
						type: "text",
						label: "Gas or electric toast grill",
						responseTag: "grill",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Size of toast grill",
						responseTag: "grillSize",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Board Information",
				questions: [
					{
						type: "text",
						label: 'Number of 54" Boards',
						responseTag: "numBoardsFiftyTwoInch",
						placeholder: "Description",
					},
					{
						type: "text",
						label: 'Number of 72" Boards',
						responseTag: "numBoardsSeventyTwoInch",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Ice Machine Information",
				questions: [
					{
						type: "text",
						label: "Number of ice machines",
						responseTag: "iceMachineQuantity",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Capacity of ice machine",
						responseTag: "iceMachineCapacity",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Cooler Information",
				questions: [
					{
						type: "text",
						label: "Number BOH upright cooler",
						responseTag: "numOfBOHUprightCooler",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Drink Machine Information",
				questions: [
					{
						type: "text",
						label: "Drop in Counter drink machine with or without ice",
						responseTag: "dropInCounterDrinkMachine",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Drive Thru counter popup shelf",
						responseTag: "counterPopupShelfDriveThru",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of undercounter fridges in Drive Thru",
						responseTag: "numUndercounterFridgesDriveThru",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of undercounter fridges at Front Counter",
						responseTag: "numUndercounterFridgesFrontCounter",
						placeholder: "Description",
					},
					{
						type: "select",
						label:
							"Freestanding counter drink machine with / without Ice machine",
						responseTag: "counterDrinkMachineFreestanding",
						options: ["Yes", "No"],
					},
					{
						type: "select",
						label: "Freestanding floor drink machine (DT style)",
						responseTag: "floorDrinkMachineFreestanding",
						options: ["Yes", "No"],
					},
					{
						type: "text",
						label: "Number of over drink cup dispensers",
						responseTag: "numDrinkCupDispensers",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Water Heater Information",
				questions: [
					{
						type: "text",
						label: "Number of water heaters",
						responseTag: "numWaterHeaters",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of tanks",
						responseTag: "numTanks",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Tank or tankless water heater",
						responseTag: "tankedWaterHeater",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Alarm Information",
				questions: [
					{
						type: "text",
						label: "Fire/burglar alarmed Y/N",
						responseTag: "alarmed",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Frame Information",
				questions: [
					{
						type: "text",
						label: "Frame type (e.g., wood)",
						responseTag: "typeFrame",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Elevator Information",
				questions: [
					{
						type: "text",
						label: "Elevator types",
						responseTag: "elevatorTypes",
						placeholder: "Description",
					},
				],
			},
			{
				title: "ADA Information",
				questions: [
					{
						type: "text",
						label: "Number of ADA Tables",
						responseTag: "numADATables",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Lighting Information",
				questions: [
					{
						type: "text",
						label: "Number of lights in the Dining Room / type of light",
						responseTag: "numLights",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Table Information",
				questions: [
					{
						type: "text",
						label: "Car hood table?",
						responseTag: "carHoodTable",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of hightop tables",
						responseTag: "numHightopTables",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Richlite tables? (with image)",
						responseTag: "richliteTables",
						multiple: true,
					},
				],
			},
			{
				title: "Seating Information",
				questions: [
					{
						type: "image",
						label: "Type of barstools (with image)",
						responseTag: "barstoolsType",
						multiple: true,
					},
					{
						type: "image",
						label: "Type of chairs (with image)",
						responseTag: "chairsType",
						multiple: true,
					},
					{
						type: "image",
						label: "Type of tables (with image)",
						responseTag: "tablesType",
						multiple: true,
					},
				],
			},
			{
				title: "Floor Information",
				questions: [
					{
						type: "image",
						label: "Dining Room floor type (with image)",
						responseTag: "diningRoomFloorType",
						multiple: true,
					},
					{
						type: "image",
						label: "Kitchen floor type (with image)",
						responseTag: "kitchenFloorType",
						multiple: true,
					},
				],
			},
			{
				title: "Composting Information",
				questions: [
					{
						type: "select",
						label: "Back of House composting",
						responseTag: "backOfHouseComposting",
						options: ["Yes", "No"],
					},
					{
						type: "select",
						label: "Front of House composting",
						responseTag: "frontOfHouseComposting",
						options: ["Yes", "No"],
					},
				],
			},
			{
				title: "Trash Compactor Information",
				questions: [
					{
						type: "text",
						label: "Trash compactor?",
						responseTag: "trashCompactor",
						placeholder: "Description",
					},
					{
						type: "select",
						label: "Trash compactor?",
						responseTag: "trashCompactorOptions",
						options: ["Yes", "No"],
					},
					{
						type: "text",
						label: "Type of trash compactor?",
						responseTag: "typeOfTrashCompactor",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Grease Trap Information",
				questions: [
					{
						type: "text",
						label: "Number of grease traps",
						responseTag: "numOfGreaseTraps",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Recycling Information",
				questions: [
					{
						type: "text",
						label: "Number of recycling bins",
						responseTag: "numOfRecycleBins",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Freezer Information",
				questions: [
					{
						type: "text",
						label: "Fry capacity in freezer",
						responseTag: "fryCapacity",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Thaw Cabinets Information",
				questions: [
					{
						type: "text",
						label: "Thaw cabinets?",
						responseTag: "thawCabinets",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Beverage Panel Information",
				questions: [
					{
						type: "text",
						label: "# of Backlit Beverage Panel(s)",
						responseTag: "numOfBacklitBeveragePanel",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Community Boards Information",
				questions: [
					{
						type: "text",
						label: "# Of Community Boards",
						responseTag: "numOfCommunityBoards",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Customer Entrance Information",
				questions: [
					{
						type: "text",
						label: "# of Customer Entrances",
						responseTag: "numOfCustomerEntrances",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Drive Thru Windows Information",
				questions: [
					{
						type: "text",
						label: "# of Drive Thru Windows",
						responseTag: "numOfDriveThruWindows",
						placeholder: "Description",
					},

					//for Drive thru window add text field to capture window sizes for both fixed window and sliding window. Remove tint question and only dimensions

					{
						type: "text",
						label: "Dimensions of Drive Thru Windows",
						responseTag: "dimensionsDriveThruWindows",
						placeholder: "Description",
					},
				],
			},

			{
				title: "Walk Up Windows",
				questions: [
					{
						type: "text",
						label: "Walk Up Windows",
						responseTag: "walkUpWindows",
						placeholder: "Description",
					},
				],
			},

			{
				title: "Front Counter Registers Information",
				questions: [
					{
						type: "text",
						label: "# of Front Counter Registers",
						responseTag: "numOfFrontCounterRegisters",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Garage Door Panes Information",
				questions: [
					{
						type: "text",
						label: "# of Garage Door Panes",
						responseTag: "numOfGarageDoorPanes",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Napkin Dispensers Information",
				questions: [
					{
						type: "text",
						label: "# of Napkin Dispensers",
						responseTag: "numOfNapkinDispensers",
						placeholder: "Description",
					},

					//add the type. Two types. Original (has a small window to see amount left) and alternate (shorter and wider)

					{
						type: "select",
						label: "Type of Napkin Dispensers",
						responseTag: "typeOfNapkinDispensers",
						placeholder: "Description",
						options: ["Original", "Alternate"],
					},
				],
			},
			{
				title: "Patio Chairs Information",
				questions: [
					{
						type: "text",
						label: "# of Patio Chairs",
						responseTag: "numOfPatioChairs",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Patio Tables Information",
				questions: [
					{
						type: "text",
						label: "# of Patio Tables",
						responseTag: "numOfPatioTables",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Patio Umbrellas Information",
				questions: [
					{
						type: "text",
						label: "# of Patio Umbrellas",
						responseTag: "numOfPatioUmbrellas",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Preview Panels Information",
				questions: [
					{
						type: "text",
						label: "# of Preview Panels",
						responseTag: "numOfPreviewPanels",
						placeholder: "Description",
					},

					//- For Drive thru preview panels add field for dimensions and number of panels. WxH
				],
			},
			{
				title: "Roof Flag Poles Information",
				questions: [
					{
						type: "text",
						label: "# of Roof Flag Poles",
						responseTag: "numOfRoofFlagPoles",
						placeholder: "Description",
					},

					//Add text field for Count of flagpoles

					{
						type: "text",
						label: "Count of Flag Poles",
						responseTag: "numOfFlagPoles",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Speaker Post-Box Information",
				questions: [
					{
						type: "text",
						label: "# of Speaker Post-Box",
						responseTag: "numOfSpeakerPostBox",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Tailgate Panel Split Information",
				questions: [
					{
						type: "text",
						label: "# of Tailgate Panel Split",
						responseTag: "numOfTailgatePanelSplit",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Tea Aerators Information",
				questions: [
					{
						type: "text",
						label: "# of Tea Aerators - Double",
						responseTag: "numOfTeaAeratorsDouble",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "# of Tea Aerators - Triple",
						responseTag: "numOfTeaAeratorsTriple",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Tea Brewers Information",
				questions: [
					{
						type: "text",
						label: "# of Tea Brewers Drive-Thru",
						responseTag: "numOfTeaBrewersDriveThru",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "# of Tea Brewers Front Counter",
						responseTag: "numOfTeaBrewersFrontCounter",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Yard Signs Information",
				questions: [
					{
						type: "text",
						label: "Are Yard Signs Legally Permitted",
						responseTag: "yardSignsLegallyPermitted",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Are Yard Signs Legally Permitted (with image)",
						responseTag: "imageYardSignsLegallyPermitted",
						multiple: true,
					},
				],
			},

			//- Add section  text field for yard art and free standing - yes/no question: is it permitted

			{
				title: "Yard Art Information",
				questions: [
					{
						type: "text",
						label: "Are Yard Art Legally Permitted",
						responseTag: "yardArtLegallyPermitted",
						placeholder: "Description",
					},
				],
			},

			{
				title: "Back Speaker Post-Box Information",
				questions: [
					{
						type: "text",
						label: "Back Speaker Post-Box - Height",
						responseTag: "backSpeakerPostBoxHeight",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Back Speaker Post-Box - Height (with image)",
						responseTag: "imageBackSpeakerPostBoxHeight",
						multiple: true,
					},
					{
						type: "text",
						label: "Back Speaker Post-Box - Width",
						responseTag: "backSpeakerPostBoxWidth",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Back Speaker Post-Box - Width (with image)",
						responseTag: "imageBackSpeakerPostBoxWidth",
						multiple: true,
					},
				],
			},
			{
				title: "Backlit Beverage Panel Information",
				questions: [
					{
						type: "text",
						label: "Backlit Beverage Panel - Height",
						responseTag: "backLitBeveragePanelHeight",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Backlit Beverage Panel - Height (with image)",
						responseTag: "imageBackLitBeveragePanelHeight",
						multiple: true,
					},
					{
						type: "text",
						label: "Backlit Beverage Panel - Width",
						responseTag: "backLitBeveragePanelWidth",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Backlit Beverage Panel - Width (with image)",
						responseTag: "imageBackLitBeveragePanelWidth",
						multiple: true,
					},
				],
			},
			{
				title: "Canes Gear Cabinet Information",
				questions: [
					{
						type: "text",
						label: "Canes Gear Cabinet",
						responseTag: "canesGearCabinet",
						placeholder: "Description",
						// options: ['Yes', 'No'],
					},
					{
						type: "text",
						label: "Canes Gear Cabinet Width",
						responseTag: "canesGearCabinetWidth",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Canes Gear Cabinet (with image)",
						responseTag: "imageCanesGearCabinetWidth",
						multiple: true,
					},
				],
			},
			{
				title: "Christmas Tree Outlet Information",
				questions: [
					{
						type: "select",
						label: "Christmas Tree Outlet",
						responseTag: "christmasTreeOutlet",
						options: ["Yes", "No"],
					},
				],
			},
			{
				title: "Crew Communications Board Information",
				questions: [
					{
						type: "text",
						label: "Crew Communications Board",
						responseTag: "crewCommunicationsBoard",
						placeholder: "Description",
					},
					// {
					// 	type: 'image',
					// 	label: 'Crew Communications Board (with image)',
					// 	responseTag: 'imageCrewCommunicationsBoard',
					// 	multiple: true,
					// },
				],
			},
			{
				title: "Window Panel Dimensions Information",
				questions: [
					{
						type: "text",
						label: "Dimensions of Window Panels in Dining Room",
						responseTag: "dimensionsWindowPanelsDiningRoom",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Dimensions of Window Panels in Dining Room (with image)",
						responseTag: "imageDimensionsWindowPanelsDiningRoom",
						multiple: true,
					},
				],
			},
			{
				title: "Dining Room Trash Receptacle Information",
				questions: [
					{
						type: "text",
						label: "Dining Room Trash Receptacle 1 - Door Measurements",
						responseTag: "diningRoomTrashReceptacleOneDoorMeasurements",
						placeholder: "Description",
					},
					{
						type: "image",
						label:
							"Dining Room Trash Receptacle 1 - Door Measurements (with image)",
						responseTag: "imageDiningRoomTrashReceptacleOneDoorMeasurements",
						multiple: true,
					},
					{
						type: "text",
						label: "Dining Room Trash Receptacle 2 - Door Measurements",
						responseTag: "diningRoomTrashReceptacleTwoDoorMeasurements",
						placeholder: "Description",
					},
					{
						type: "image",
						label:
							"Dining Room Trash Receptacle 2 - Door Measurements (with image)",
						responseTag: "imageDiningRoomTrashReceptacleTwoDoorMeasurements",
						multiple: true,
					},
				],
			},
			{
				title: "Interior Marquee Information",
				questions: [
					{
						type: "text",
						label: "Do you have an Interior Marquee?",
						responseTag: "interiorMarquee",
						placeholder: "Description",
						// options: ['Yes', 'No'],
					},
				],
			},
			{
				title: "Covered Patio Information",
				questions: [
					{
						type: "select",
						label: "Do you Have Covered Patio",
						responseTag: "coveredPatio",
						options: ["Yes", "No"],
					},
				],
			},
			{
				title: "Door Dimensions Information",
				questions: [
					{
						type: "text",
						label: "Door 1 - Sidelight 1: Dimensions",
						responseTag: "doorOneSidelightOne",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Door 1 - Sidelight 1: Dimensions (with image)",
						responseTag: "imageDoorOneSidelightOne",
						multiple: true,
					},
					{
						type: "text",
						label: "Door 1 - Sidelight 2: Dimensions",
						responseTag: "doorTwoSidelightTwo",
						placeholder: "Description",
					},
					// {
					// 	type: 'image',
					// 	label: 'Door 1: Vestibule (with image)',
					// 	responseTag: 'imageDoorTwoSidelightTwo',
					// 	multiple: true,
					// },
					{
						type: "text",
						label: "Door 1: Vestibule",
						responseTag: "doorOneVestibule",
						// placeholder: 'Description',
						options: ["Yes", "No"],
					},
					// {
					// 	type: 'image',
					// 	label: 'Door 1: Vestibule (with image)',
					// 	responseTag: 'imageDoorOneVestibule',
					// 	multiple: true,
					// },
					{
						type: "text",
						label: "Door 2 - Sidelight 1: Dimensions",
						responseTag: "doorTwoSidelightOne",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Door 2 - Sidelight 1: Dimensions (with image)",
						responseTag: "imageDoorTwoSidelightOne",
						multiple: true,
					},
					{
						type: "text",
						label: "Door 2 - Sidelight 2: Dimensions",
						responseTag: "doorTwoSidelightTwo",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Door 2 - Sidelight 2: Dimensions (with image)",
						responseTag: "imageDoorTwoSidelightTwo",
						multiple: true,
					},
					{
						type: "text",
						label: "Door 2: Vestibule",
						responseTag: "doorTwoVestibule",
						placeholder: "Description",
					},
					// {
					// 	type: 'image',
					// 	label: 'Door 2: Vestibule (with image)',
					// 	responseTag: 'imageDoorTwoVestibule',
					// 	multiple: true,
					// },
					{
						type: "text",
						label: "Door 3 - Sidelight 1: Dimensions",
						responseTag: "doorThreeSidelightOne",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Door 3 - Sidelight 1: Dimensions (with image)",
						responseTag: "imageDoorThreeSidelightOne",
						multiple: true,
					},
					{
						type: "text",
						label: "Door 3 - Sidelight 2: Dimensions",
						responseTag: "doorThreeSidelightTwo",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Door 3 - Sidelight 2: Dimensions (with image)",
						responseTag: "imageDoorThreeSidelightTwo",
						multiple: true,
					},
					{
						type: "text",
						label: "Door 3: Vestibule",
						responseTag: "doorThreeVestibule",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Door 3: Vestibule (with image)",
						responseTag: "imageDoorThreeVestibule",
						multiple: true,
					},
				],
			},
			{
				title: "Drive Thru Menu Fixture Information",
				questions: [
					{
						type: "text",
						label: "Drive Thru Menu Fixture #1",
						responseTag: "driveThruMenuFixtureOne",
						placeholder: "Description",
					},
					{
						type: "select",
						label: "Drive Thru Menu Fixture #2",
						responseTag: "driveThruMenuFixtureTwo",
						options: [
							"Standard",
							"Multi-Lane",
							"Dual Lane",
							"Double Drive Thru",
						],
					},
					{
						type: "select",
						label: "Drive Thru Type",
						responseTag: "driveThruType",
						options: ["Yes", "No"],
					},
					//Add drive thru type options - single lane 111, multilane (that merges into one ) 121 or 221 start with 2 and pick up with one, dual lane 222.

					{
						type: "select",
						label: "Drive Thru Type",
						responseTag: "driveThruType",
						options: ["Single Lane", "Multi-Lane", "Dual Lane"],

						//add option Standard and non standard for drive thru menu boards
					},
					{
						type: "select",
						label: "Drive Thru Menu Board Type",
						responseTag: "driveThruMenuBoardType",
						options: ["Standard", "Non-Standard"],
					},
				],
			},
			{
				title: "Drive-Thru Window Information",
				questions: [
					{
						type: "text",
						label: "Drive-Thru Window Tint",
						responseTag: "driveThruWindowTint",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Entrance Information",
				questions: [
					{
						type: "text",
						label: "Entrance 1:",
						responseTag: "entranceOne",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Entrance 2:",
						responseTag: "entranceTwo",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Entrance 3:",
						responseTag: "entranceThree",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Entrance 4:",
						responseTag: "entranceFour",
						placeholder: "Description",
					},

					{
						type: "text",
						label: "Entrance Door 1 Type",
						responseTag: "entranceDoorOneType",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Entrance Door 2 Type",
						responseTag: "entranceDoorTwoType",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Entrance Door 3 Type",
						responseTag: "entranceDoorThreeType",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Entrance Door 1 Type",
						responseTag: "entranceDoorOneType",
						placeholder: "Description",
					},

					{
						type: "text",
						label: "ENTRANCES",
						responseTag: "entrances",
						placeholder: "Description",
					},
					{
						type: "text",
						label:
							"Measurements of the glass on the door and sidelight windows",
						responseTag: "measurementsGlassDoorSidelightWindows",
						placeholder: "Description",
					},

					{
						type: "text",
						label: "Number of panes right next to door",
						responseTag: "numPanesRightNextToDoor",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Exterior Information",
				questions: [
					{
						type: "text",
						label: "EXTERIOR",
						responseTag: "exterior",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Exterior Menu Board Type",
						responseTag: "exteriorMenuBoardType",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Front Speaker Post-Box Information",
				questions: [
					{
						type: "text",
						label: "Front Speaker Post-Box - Height",
						responseTag: "frontSpeakerPostBoxHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Front Speaker Post-Box - Width",
						responseTag: "frontSpeakerPostBoxWidth",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Garage Door Information",
				questions: [
					{
						type: "text",
						label: "Garage Door",
						responseTag: "garageDoor",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Garage Door - Height",
						responseTag: "garageDoorHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Garage Door - Width",
						responseTag: "garageDoorWidth",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Marquee Board Information",
				questions: [
					{
						type: "text",
						label: "How many lines does the Marquee Board have?",
						responseTag: "marqueeBoardLines",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Interior Menu Information",
				questions: [
					{
						type: "select",
						label: "Interior Menu Type",
						responseTag: "interiorMenuType",
						options: ["Digital", "Paper"],
					},
					{
						type: "text",
						label: "Interior Menu: Printed Type",
						responseTag: "interiorMenuPrintedType",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Interior Menu: Printed Type (with image)",
						responseTag: "imageInteriorMenuPrintedType",
						multiple: true,
					},
				],
			},
			{
				title: "Mens Restroom Information",
				questions: [
					{
						type: "text",
						label: "Mens Restroom - Mirror Dimensions",
						responseTag: "mensRestroomMirrorDimensions",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Mens Restroom - Mirror Dimensions (with image)",
						responseTag: "imageMensRestroomMirrorDimensions",
						multiple: true,
					},
					{
						type: "text",
						label: "Mens Restroom - Number Mirrors",
						responseTag: "mensRestroomNumberMirrors",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Mens Restroom - Number of Stalls",
						responseTag: "mensRestroomNumberStalls",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Mens Restroom - Number of Stalls (with image)",
						responseTag: "imageMensRestroomNumberStalls",
						multiple: true,
					},
				],
			},
			{
				title: "Womens Restroom Information",
				questions: [
					{
						type: "text",
						label: "Womens Restroom - Mirror Dimensions",
						responseTag: "womensRestroomMirrorDimensions",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Womens Restroom - Mirror Dimensions (with image)",
						responseTag: "imageWomensRestroomMirrorDimensions",
						multiple: true,
					},
					{
						type: "text",
						label: "Womens Restroom - Number Mirrors",
						responseTag: "womensRestroomNumberMirrors",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Womens Restroom - Number of Stalls",
						responseTag: "womensRestroomNumberStalls",
						placeholder: "Description",
					},

					//- Update to women's restrooms to photo field for the stalls

					{
						type: "image",
						label: "Womens Restroom - Number of Stalls (with image)",
						responseTag: "imageWomensRestroomNumberStalls",
						multiple: true,
					},
				],
			},
			{
				title: "Digital Message Board Information",
				questions: [
					{
						type: "text",
						label: "Number of Digital Message Board(s)",
						responseTag: "digitalMessageBoards",
						placeholder: "Description",
					},

					//- for Digital menu and message boards add text field for the orientation and board count

					{
						type: "text",
						label: "Orientation of Digital Message Board",
						responseTag: "orientationDigitalMessageBoard",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Count of Digital Message Board",
						responseTag: "countDigitalMessageBoard",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Table Tops Information",
				questions: [
					{
						type: "text",
						label: "Number of Table Tops",
						responseTag: "numberOfTableTops",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Window Panels Information",
				questions: [
					{
						type: "text",
						label: "Number of Window Panels in Dining Room",
						responseTag: "windowPanelsDiningRoom",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Number of Window Sets",
						responseTag: "numberOfWindowSets",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Number of Window Sets (with image)",
						responseTag: "imageNumberOfWindowSets",
						multiple: true,
					},
				],
			},
			{
				title: "Panel Information",
				questions: [
					{
						type: "text",
						label: "Panel 1 (Left)",
						responseTag: "panelOneLeft",
						placeholder: "Description",
					},
					{
						type: "image",
						label: "Panel 1 (Left) (with image)",
						responseTag: "imagePanelOneLeft",
						multiple: true,
					},
					{
						type: "text",
						label: "Panel 1 (Left) - Height",
						responseTag: "panelOneLeftHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 1 (Left) - Width",
						responseTag: "panelOneLeftWidth",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 2 (Center)",
						responseTag: "panelTwoCenter",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 2 (Center) - Height",
						responseTag: "panelTwoCenterHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 2 (Center) - Width",
						responseTag: "panelTwoCenterWidth",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 3 (Right Top)",
						responseTag: "panelThreeRightTop",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 3 (Right Top) - Height",
						responseTag: "panelThreeRightTopHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 3 (Right Top) - Width",
						responseTag: "panelThreeRightTopWidth",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 4 (Right Center)",
						responseTag: "panelFourRightCenter",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 4 (Right Center) - Height",
						responseTag: "panelFourRightCenterHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 4 (Right Center) - Width",
						responseTag: "panelFourRightCenterWidth",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 5 (Right Bottom)",
						responseTag: "panelFiveRightBottom",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 5 (Right Bottom) - Height",
						responseTag: "panelFiveRightBottomHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Panel 5 (Right Bottom) - Width",
						responseTag: "panelFiveRightBottomWidth",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Party Packs Information",
				questions: [
					{
						type: "text",
						label: "Party Packs?",
						responseTag: "partyPacks",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Photograph Information",
				questions: [
					{
						type: "text",
						label: "Photograph: Canes Gear Cabinet",
						responseTag: "photoCanesGearCabinet",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Photograph: Front Counter",
						responseTag: "photoFrontCounter",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Photograph: Front Face of Building",
						responseTag: "photoFrontFaceBuilding",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Photograph: Readerboard",
						responseTag: "photoReaderboard",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Photograph: Side 1 Face of Building",
						responseTag: "photoSideOneBuilding",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Photograph: Side 2 Face of Building",
						responseTag: "photoSideTwoBuilding",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Pickup Shelves Information",
				questions: [
					{
						type: "text",
						label: "Pickup Shelves Design",
						responseTag: "pickupShelvesDesign",
						placeholder: "Description",
					},

					// Pick up shelves - 3,4 shelves free standing or built in

					{
						type: "text",
						label: "Pickup Shelves",
						responseTag: "pickupShelvesType",
						placeholder: "Description",
					},
					{
						type: "select",
						label: "Pickup Shelves Type",
						responseTag: "pickupShelvesType",
						options: ["Free Standing", "Built In"],
					},
				],
			},
			{
				title: "Preview Panel Information",
				questions: [
					{
						type: "text",
						label: "Preview Panel - Height",
						responseTag: "previewPanelHeight",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Preview Panel - Width",
						responseTag: "previewPanelWidth",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Digital Message Board Information",
				questions: [
					{
						type: "text",
						label: "Digital Message Board",
						responseTag: "digitalMessageBoard",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Readerboard Information",
				questions: [
					{
						type: "text",
						label: "Readerboard Type",
						responseTag: "readerboardType",
						placeholder: "Description",
					},
				],
			},

			{
				title: "Speaker Box Information",
				questions: [
					{
						type: "text",
						label: "Speaker Box Dimensions",
						responseTag: "speakerBoxDimensions",
						placeholder: "Description",
					},

					{
						type: "image",
						label: "Speaker Post - Front (with image)",
						responseTag: "imageSpeakerPostFront",
						multiple: true,
					},
				],
			},

			{
				title: "Speaker Post Information",
				questions: [
					{
						type: "text",
						label: "Speaker Post - Back",
						responseTag: "speakerPostBack",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Speaker Post - Front",
						responseTag: "speakerPostFront",
						placeholder: "Description",
					},

					//add photo field for Picture of different speaker boxes if non standard.

					//- add text field for Speakerbox dimensions
				],
			},
			{
				title: "Trash Receptacle Information",
				questions: [
					{
						type: "text",
						label: "Trash Receptacle - Number of Double",
						responseTag: "trashReceptacleDouble",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Trash Receptacle - Number of Single",
						responseTag: "trashReceptacleSingle",
						placeholder: "Description",
					},

					// For Trash receptacle - add text field for door count and door sizes. Combine it in FAI

					{
						type: "text",
						label: "Trash Receptacle - Door Count",
						responseTag: "trashReceptacleDoorCount",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Trash Receptacle - Door Sizes",
						responseTag: "trashReceptacleDoorSizes",
						placeholder: "Description",
					},
				],
			},
			{
				title: "Window Set Information",
				questions: [
					{
						type: "text",
						label: "Window Set #1 - Panel 1",
						responseTag: "windowSetOnePanelOne",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #1 - Panel 2",
						responseTag: "windowSetOnePanelTwo",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #1 - Panel 3",
						responseTag: "windowSetOnePanelThree",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #1 - Panel 4",
						responseTag: "windowSetOnePanelFour",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #2 - Panel 1",
						responseTag: "windowSetTwoPanelOne",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #2 - Panel 2",
						responseTag: "windowSetTwoPanelTwo",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #2 - Panel 3",
						responseTag: "windowSetTwoPanelThree",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #2 - Panel 4",
						responseTag: "windowSetTwoPanelFour",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #3 - Panel 1",
						responseTag: "windowSetThreePanelOne",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #3 - Panel 2",
						responseTag: "windowSetThreePanelTwo",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #3 - Panel 3",
						responseTag: "windowSetThreePanelThree",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #3 - Panel 4",
						responseTag: "windowSetThreePanelFour",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #4 - Panel 1",
						responseTag: "windowSetFourPanelOne",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #4 - Panel 2",
						responseTag: "windowSetFourPanelTwo",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #4 - Panel 3",
						responseTag: "windowSetFourPanelThree",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Window Set #4 - Panel 4",
						responseTag: "windowSetFourPanelFour",
						placeholder: "Description",
					},
				],
			},
			//add sectionn for pictures for doors

			{
				title: "Door Pictures Information",

				questions: [
					{
						type: "image",
						label: "Door Picture ",
						responseTag: "imageDoor",
						multiple: true,
					},
				],
			},

			//- Add yes/no questions: Collect chicken fingers sign. Add text field for Location and measurements (of bottom of sign to bottom of the window). Measure window as well

			{
				title: "Collect Chicken Fingers Sign Information",
				questions: [
					{
						type: "text",
						label: "Collect Chicken Fingers Sign",
						responseTag: "collectChickenFingersSign",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Collect Chicken Fingers Sign Location",
						responseTag: "collectChickenFingersSignLocation",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Measurements from bottom of sign to bottom of window",
						responseTag: "measurementsBottomSignToWindow",
						placeholder: "Description",
					},

					{
						type: "text",
						label: "Measurements of Window",
						responseTag: "measurementsWindow",
						placeholder: "Description",
					},
				],
			},
			//add questions and text field for open sign and location of the open sign and text field for measurements from bottom of sign to bottom of window

			{
				title: "Open Sign Information",

				questions: [
					{
						type: "text",
						label: "Open Sign",
						responseTag: "openSign",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Open Sign Location",
						responseTag: "openSignLocation",
						placeholder: "Description",
					},
					{
						type: "text",
						label: "Measurements from bottom of sign to bottom of window",
						responseTag: "measurementsBottomSignToWindow",
						placeholder: "Description",
					},
				],
			},
		],
	},
];

// export default inputs;
