// This array of objects will serve as the basis for Cushman & Wakefield's forms, submission rendering, submission editing, exports, and etc.
//
// It starts out as an array, where each item is on object that represent a "page" on the form/submission.
// It will contain the title of the page as well as the sections (ie, accordion items) found on the page.
//
// Inside each section is an object containing the title of the section and the questions.
//
// The 'questions' key represents the questions (& answers to be submitted by the user).
// You'll notice there are a 2 'types' of questions... 1. OBJECTS & 2. ARRAYS OF OBJECTS
//
// 1. OBJECTS
// Here are the three (and their keys) that you'll find:
// - 'location' will be a location dropdown that derives the choices (ie, locations) from the database
// - 'dateOfVisit' is the date that the worker visited the site, requiring a date picker tool
// - 'summary' (found on the last page) is a textarea summary of the entire submission
// Note: these three questions are the only three that are required to be filled out. Everything else can be empty.
// Also, the 'label' key is what the user will see, the 'key' is used for database purposes, and the 'value' is what the user inputs.
//
// 2. ARRAYS OF OBJECTS
// These contain 6 keys:
// - 'label': what the user sees for the question
// - 'key': what is used for database purposes
// - 'description': the user's input / answer for any notes needed to be taken for the item
// - 'actionTaken': the user's input / answer (selected from a dropdown of 3 items) that describes the action taken based on the item's quality / condition
// - 'imageBefore': the image taken before the action (as described in 'actionTaken')
// - 'imageAfter': the image taken after the action (as described in 'actionTaken')
// The reason that this is in an array is because Cushman wants the ability to add "duplicate" items.
// On the client side, if they indicate they want duplicates, then the array will contain additional objects.

// First level: the array of pages
const inputs = [
	{
		title: "Showroom",
		sections: [
			// Second level: the array of sections
			{
				title: "Basic Information",
				questions: [
					// Third level: the array of questions
					{
						// This is one type of question - an "object" - which IS required, but is NOT duplicable
						label: "Store Name",
						key: "location",
						value: null,
					},
					{
						label: "Date of Handyman Visit",
						key: "dateOfVisit",
						value: null,
					},
				],
			},
			{
				title: "Front and Back EMS Lighting Test",
				questions: [
					[
						// This is another type of question - an "array of objects" - which is NOT required, but IS duplicable
						{
							label: "Inspect Housing - Physical Damage",
							key: "housingDamageDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Emergency Lights/Exit Signs",
							key: "emsSignsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Floor and Ceiling",
				questions: [
					[
						{
							label: "Tile",
							key: "floorTileDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Carpet",
							key: "floorCarpetDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "VCT",
							key: "floorvctDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Walk Off",
							key: "floorWalkOffDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Grid",
							key: "floorGridDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Drywall",
							key: "drywallDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Interior and Exterior Doors",
				questions: [
					[
						{
							label: "Frame/Silencer",
							key: "frameDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Handles",
							key: "handlesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Closer",
							key: "closerDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Kickplates/Door Stop",
							key: "kickplatesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Weatherstripping",
							key: "weatherstrippingDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Use Deoxit to Clean Medco Key Sensors",
							key: "deoxitDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Lighting",
				questions: [
					[
						{
							label: "Track Lighting",
							key: "trackLightingDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Ceiling Lighting",
							key: "ceilingLightingDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Soffit Lighting",
							key: "soffitLightingDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Task Lighting",
							key: "taskLightingDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Exit Signs",
							key: "exitSignsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Light Boxes",
							key: "lightBoxesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Furniture",
				questions: [
					[
						{
							label: "Stools/Benches",
							key: "stoolsbenchesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Millwork",
				questions: [
					[
						{
							label: "Display Shelves",
							key: "displayshelvesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "White Display Pedestals and Base",
							key: "whiteDisplayDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Wireless Workshop Counters, Monitor Fixture",
							key: "wirelessWorkshopCountersDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Feature Cubes",
							key: "featureCubesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Solution Walls",
							key: "solutionWallsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Tablet Walls",
							key: "tabletWallsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Demo & Staging Tables",
							key: "demoTablesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Phone Tables",
							key: "phoneTablesDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Fixture Baseboards",
							key: "fixtureBaseboardsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Sales POD Drawers",
							key: "salesPodDrawersDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Sales Counter/POD Laminate",
							key: "salesPodLaminateDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Keyboard Trays",
							key: "keyboardTraysDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Footrests",
							key: "footrestsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Curved Walls",
							key: "curvedWallsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Cabinets/Hinges/Handles/Locks",
							key: "cabinetsHingesHandlesLocksDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Slatwall/Pegboard",
							key: "slatwallPegboardDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Lock Plugs",
							key: "lockPlugsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Walls",
				questions: [
					[
						{
							label: "Paint",
							key: "paintDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Wall Covering",
							key: "wallCoveringDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Cove Base",
							key: "coveBaseDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Hang Items/Snap Frame/Brick Inlay",
							key: "hangItemsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "FRP/Acrovyn",
							key: "frpDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
		],
	},
	{
		title: "Back of House",
		sections: [
			{
				title: "Telco Rooms",
				questions: [
					[
						{
							label: "Telco Rooms",
							key: "telcoRoomsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Door (including louvers, frame, floor ledge, threshold)",
							key: "doorDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Transition Strips",
							key: "transitionStripsDescription",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Ceiling/Floor",
				questions: [
					[
						{
							label: "Tile",
							key: "floorTileDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "VCT",
							key: "floorvctDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Walk Off",
							key: "floorWalkOffDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Grid",
							key: "floorGridDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Drywall",
							key: "drywallDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Interior and Exterior Doors",
				questions: [
					[
						{
							label: "Frame/Silencer",
							key: "frameDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Handles",
							key: "handlesDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Hinges",
							key: "hingesDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Closer",
							key: "closerDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Kickplates/Door Stop",
							key: "kickplatesDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Weatherstripping",
							key: "weatherstrippingDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Peephole",
							key: "peepholeDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Lighting",
				questions: [
					[
						{
							label: "Track Lighting",
							key: "trackLightingDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Ceiling Lighting",
							key: "ceilingLightingDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Exit Signs",
							key: "exitSignsDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Light Boxes",
							key: "lightBoxesDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Furniture",
				questions: [
					[
						{
							label: "Stools/Benches",
							key: "stoolsbenchesDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Chairs/Desks",
							key: "chairsDesksDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Breakroom Table",
							key: "breakroomDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Lockers",
							key: "lockersDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Shelving",
							key: "shelvingDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Refrigerator",
							key: "refrigeratorDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Restrooms",
				questions: [
					[
						{
							label: "Restroom Signage",
							key: "restroomSignagerDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Toilet",
							key: "toiletDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Sink",
							key: "sinkDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Fixtures/Dispensers/Cabinet",
							key: "fixturesDispensersDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Clean Exhaust Fans",
							key: "cleanExhaustDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Handicap Rails",
							key: "handicapRailsDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Flooring (Ceramic/VCT)",
							key: "flooringCeramicDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "FRP/Acrovyn",
							key: "frpDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Millwork",
				questions: [
					[
						{
							label: "Counters",
							key: "countersDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Cabinets/Hinges/Handles/Locks",
							key: "cabinetsHingesHandlesLocksDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Lock Plugs",
							key: "lockPlugsDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Walls",
				questions: [
					[
						{
							label: "Paint",
							key: "paintDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Cove Base",
							key: "coveBaseDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Hang Items/Snap Frame/Brick Inlay",
							key: "hangItemsDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "FRP/Acrovyn",
							key: "frpDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Coat Hooks",
							key: "coatHooksDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Ladder Rack",
							key: "ladderRackDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
			{
				title: "Inventory Room",
				questions: [
					[
						{
							label: "Shelving",
							key: "shelvingDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Furniture",
							key: "furnitureDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
					[
						{
							label: "Slatwall/Pegboard",
							key: "slatwallPegboardDescriptionTwo",
							description: null,
							actionTaken: null,
							imageBefore: null,
							imageAfter: null,
						},
					],
				],
			},
		],
	},
	{
		title: "Notes",
		sections: [
			{
				title: "Notes",
				questions: [
					{
						label: "Summary",
						key: "summary",
						value: null,
					},
				],
			},
		],
	},
];

export default inputs;
