import { useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip } from "reactstrap";

export const InfoTooltip = (props) => {
	const { message, classes } = props;
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	return (
		<div className={classes}>
			<BiInfoCircle className="fs-1" id="tooltip" />
			<Tooltip
				autohide={true}
				flip={true}
				placement="bottom"
				isOpen={tooltipOpen}
				target="tooltip"
				toggle={toggle}
			>
				{message}
			</Tooltip>
		</div>
	);
};
