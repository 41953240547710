import ExifReader from "exifreader";
import { deepCopy } from "./deepCopy";
import { isValidUrl } from "./validation";

const getExifData = async (url: string | URL | Request) => {
	try {
		const res = await fetch(url);
		const blob = await res.blob();
		const arrBuff = await blob.arrayBuffer();
		return ExifReader.load(arrBuff);
	} catch (e) {
		console.log("Error get exif data", e);
	}
};

export const addExifToInputsCushman = async (inputs: undefined) => {
	const pages = deepCopy(inputs);
	const imgs = [
		{ key: "imageBefore", newKey: "imageBeforeExif" },
		{ key: "imageAfter", newKey: "imageAfterExif" },
	];
	for (const page of pages) {
		if (!page.sections) continue;
		for (const section of page.sections) {
			if (!section.questions) continue;
			for (const question of section.questions) {
				for (const img of imgs) {
					if (!question[img.key]) continue;
					if (!isValidUrl(question[img.key])) continue;
					const data = await getExifData(question[img.key]);
					question[img.newKey] = data;
				}
			}
		}
	}
	return pages;
};

export const addExifToInputs = async (
	inputs: any[],
	responseData: { [x: string]: any },
) => {
	const questions = inputs.flatMap(
		(page: { questions: any }) => page.questions,
	);

	for (const question of questions) {
		if (question.type !== "image") continue;
		if (!responseData[question.key]) continue;
		if (!isValidUrl(responseData[question.key])) continue;
		const data = await getExifData(responseData[question.key]);
		question.exif = data;
	}

	return questions;
};
