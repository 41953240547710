import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, List } from "antd";
import type React from "react";
import { useState } from "react";
import "./dfp-submission-qa-card.scss";

// Define an interface for each QA flag object
interface QAFlag {
	id: number;
	reason: string;
}

// Define props for the QAReasonCard component
interface QAReasonCardProps {
	qaFlags: QAFlag[];
}

const QAReasonCard: React.FC<QAReasonCardProps> = ({ qaFlags }) => {
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const totalFlags = qaFlags?.length ?? 0;

	const handleNext = () =>
		setCurrentIndex((prevIndex) => (prevIndex + 1) % totalFlags);
	const handlePrev = () =>
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? totalFlags - 1 : prevIndex - 1,
		);

	return (
		<Card
			title="QA Reasons"
			className="qa-card shadow-sm rounded-lg border-0"
			extra={
				totalFlags > 1 && (
					<div className="qa-card-controls d-flex align-items-center gap-2">
						<Button
							className="btn-sm btn-outline-primary"
							onClick={handlePrev}
							aria-label="Previous Reason"
						>
							<LeftOutlined />
						</Button>
						<Button
							className="btn-sm btn-outline-primary"
							onClick={handleNext}
							aria-label="Next Reason"
						>
							<RightOutlined />
						</Button>
					</div>
				)
			}
		>
			<div className="qa-card-content text-center p-3">
				{totalFlags === 0 ? (
					<div className="qa-card-message text-danger font-weight-bold">
						Needs general approval
					</div>
				) : (
					<div>
						<List className="qa-card-list">
							<List.Item>
								<span className="qa-reason-text">
									{qaFlags[currentIndex].reason}
								</span>
							</List.Item>
						</List>
						{totalFlags > 1 && (
							<div className="qa-pagination text-muted mt-3">
								{currentIndex + 1} of {totalFlags}
							</div>
						)}
					</div>
				)}
			</div>
		</Card>
	);
};

export { QAReasonCard };
