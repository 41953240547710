export function toCamelCase(str: string): string {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) => {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, "");
}

export function toSnakeCase(str: string): string {
	return str
		.replace(/([A-Z])/g, (match: string) => `_${match.toLowerCase()}`)
		.replace(/\s+/g, "");
}

export function parseDateToUTCString(
	date: string | number | Date,
	returnTime = false,
): string | undefined {
	if (!date) return undefined;
	const dateObj = new Date(date);
	const mm = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
	const dd = String(dateObj.getUTCDate()).padStart(2, "0");
	const yyyy = dateObj.getUTCFullYear();
	const hh = String(dateObj.getUTCHours()).padStart(2, "0");
	const min = String(dateObj.getUTCMinutes()).padStart(2, "0");
	const ss = String(dateObj.getUTCSeconds()).padStart(2, "0");
	return returnTime
		? `${mm}/${dd}/${yyyy} ${hh}:${min}:${ss}`.replace(" 00:00:00", "")
		: `${mm}/${dd}/${yyyy}`;
}

export function parseDateToLocalString(
	date: string | number | Date,
): string | undefined {
	if (!date) return undefined;
	const dateObj = new Date(date);
	const mm = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
	const dd = String(dateObj.getDate()).padStart(2, "0");
	const yyyy = dateObj.getFullYear();
	return `${mm}/${dd}/${yyyy}`;
}

export function normalizeStringForComparison(
	str: string,
	removeSpecialChars = false,
): string {
	return removeSpecialChars
		? str.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
		: str.toLowerCase().replace(/\s+/g, "");
}

export function escapeQuotes(value: string) {
	if (typeof value === "string" && value.includes('"')) {
		return `"${value.replace(/"/g, '""')}"`;
	}
	return value;
}

export function decamelize(str: string) {
	return str
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		.replace(/_/g, " ")
		.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
		.replace(/([a-z])([A-Z]{2,})/g, "$1 $2")
		.replace(
			/\b(\w)(\w*)/g,
			(_, first, rest) => `${first.toUpperCase()}${rest.toLowerCase()}`,
		);
}
