import FacilityAILogoMini from "@/assets/img/FacilityAI_Logo_Mini.svg";
import { DB_ORG } from "@/constants/db";
import { useFormStore } from "@/stores/formStore";
import { useChatbotStore } from "@stores/chatbotStore";
import { useUserStore } from "@stores/userStore";
import { useState } from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { GeneratedPropIcon } from "../../AssetProfile/GeneratedPropIcon";
import { CheckboxInput } from "./CheckboxInput";
import { PreviewField } from "./PreviewField";

interface SelectInputProps {
	question: any;
	responseData: any;
	setInputData: CallableFunction;
	getOptions: CallableFunction;
	getOptionsLength: CallableFunction;
	hidePreview: boolean;
	fromChatbot: boolean;
	placeholder: string;
}

const SelectInput = ({
	question,
	responseData,
	setInputData,
	getOptions,
	getOptionsLength,
	hidePreview,
	fromChatbot,
	placeholder,
}: SelectInputProps) => {
	const [selectedSuggestion, setSelectedSuggestion] = useState(null);
	const numOptions = getOptionsLength(question);

	// Access form store
	const {
		assetTypes: formAssetTypes,
		equipmentAreas: formEquipmentAreas,
		chosenAssetType: formChosenAssetType,
		setChosenAssetType: setFormChosenAssetType,
		fieldError: formFieldError,
		setFieldError: setFormFieldError,
		ocrConfidenceAndColor: formOcrConfidenceAndColor,
	} = useFormStore();

	// Access chatbot store
	const {
		assetTypes: chatbotAssetTypes,
		chosenAssetType: chatbotChosenAssetType,
		setChosenAssetType: setChatbotChosenAssetType,
		fieldError: chatbotFieldError,
		setFieldError: setChatbotFieldError,
		ocrConfidenceAndColor: chatbotOcrConfidenceAndColor,
	} = useChatbotStore();

	const { userOrganization } = useUserStore();
	// Determine which store to use
	const fieldError = fromChatbot ? chatbotFieldError : formFieldError;
	const setFieldErrorFn = fromChatbot
		? setChatbotFieldError
		: setFormFieldError;

	const assetTypes = fromChatbot ? chatbotAssetTypes : formAssetTypes;
	const chosenAssetType = fromChatbot
		? chatbotChosenAssetType
		: formChosenAssetType;
	const setChosenAssetType = fromChatbot
		? setChatbotChosenAssetType
		: setFormChosenAssetType;

	// Access OCR confidence and color
	const ocrConfidenceAndColor = fromChatbot
		? chatbotOcrConfidenceAndColor
		: formOcrConfidenceAndColor;

	// Determine if the input should be disabled
	const shouldDisable = () => {
		if (question.disabled === "true") {
			return true;
		}
		return responseData[question.tiedTo] === true;
	};

	/**
	 * Generate asset type suggestions if responseData.assetTypeSuggestions is populated
	 * @returns {JSX.Element}
	 */
	const generateAssetSuggestions = () => {
		if (
			responseData.assetTypeSuggestions === undefined ||
			responseData.assetTypeSuggestions.length === 0
		) {
			return null;
		}

		const filtered_assets = Object.values(assetTypes).filter((type: any) => {
			const asset_name = type.name;
			const location = type.organization_equipment_area_id;

			return (
				(location === responseData.assetLocation || location === undefined) &&
				responseData.assetTypeSuggestions.includes(asset_name)
			);
		});

		if (filtered_assets.length === 0) {
			return null;
		}

		return (
			<div id="asset-type-suggestions">
				<div>
					<img id="ai-logo" src={FacilityAILogoMini} alt="FacilityAILogo" />
				</div>
				<div id="asset-type-suggestion-holder">
					{filtered_assets.map((item, index) => (
						<div
							key={`${index}${item?.id}`}
							className={`asset-suggestion ${
								item === selectedSuggestion ? "selected-suggestion" : ""
							}`}
							onClick={() => handleAssetSuggestionSelect(item)}
							onKeyDown={() => handleAssetSuggestionSelect(item)}
						>
							{item.data().orgName || item.data().name}
						</div>
					))}
				</div>
			</div>
		);
	};

	/**
	 * Handles the selecting of an asset suggestion
	 * @param assetSuggestion
	 */
	const handleAssetSuggestionSelect = (assetSuggestion: any) => {
		const assetName =
			assetSuggestion.data().orgName || assetSuggestion.data().name;

		const assetType = assetTypes.find(
			(obj: {
				data: () => { (): any; new (): any; name: any; orgName: any };
			}) => obj.data().name === assetName || obj.data().orgName === assetName,
		);
		if (assetType) {
			setChosenAssetType(assetType);
			setInputData(
				question,
				assetType.data().orgName !== undefined
					? assetType.data().orgName
					: assetType.data().name,
			);
			setSelectedSuggestion(assetSuggestion);
		}
	};

	const handleChange = (e: { target: { value: any } }) => {
		let value = e.target.value;
		if (question.key === "assetType") {
			value = assetTypes[Number.parseInt(value)];
			setChosenAssetType(value);
		} else if (question.key === "assetLocation") {
			value = formEquipmentAreas.find(
				(area: { id: number }) => area.id === Number.parseInt(value),
			);
		} else if (question.key === "assetSubArea") {
			const selectedSubArea = formEquipmentAreas
				.flatMap((area: { sub_areas: any }) => area.sub_areas)
				.find(
					(subArea: { id: number }) => subArea.id === Number.parseInt(value),
				);

			setInputData(question, selectedSubArea);
			return;
		} else if (
			question.key === "assetSubArea" &&
			userOrganization.external_firebase_id === DB_ORG.TOG
		) {
			const selectedSubArea = formEquipmentAreas
				.flatMap((area: { sub_areas: any }) => area.sub_areas)
				.find(
					(subArea: { id: number }) => subArea.id === Number.parseInt(value),
				);

			setInputData(question, selectedSubArea);

			return;
		}
		setInputData(question, value);
		setSelectedSuggestion(null);

		if (question.required === "true" && (!value || value === "")) {
			setFieldErrorFn(question.key, `${question.label} is required`);
		} else {
			setFieldErrorFn(question.key, "");
		}
	};

	const getValue = (
		questionKey: string,
		chosenAssetType: { id: string | number },
		responseData: { [x: string]: { id: string | number } },
	) => {
		if (questionKey === "assetType") {
			return chosenAssetType?.id || "";
		}
		if (responseData) {
			if (typeof responseData[questionKey] === "string") {
				return responseData[questionKey];
			}
			return responseData[questionKey]?.id || "";
		}
		return "";
	};

	// Prepare OCR confidence data
	const ocrConfidenceAndColorObj: {
		color: string;
		confidence?: string | number;
	} = {
		color: "",
		confidence: undefined,
	};

	if (ocrConfidenceAndColor?.[question.key]) {
		ocrConfidenceAndColorObj.confidence = (
			ocrConfidenceAndColor[question.key]?.confidence * 100
		).toFixed(2);
		ocrConfidenceAndColorObj.color = ocrConfidenceAndColor[question.key]?.color;
	}

	// Manage hover state
	const [isHovered, setIsHovered] = useState(false);

	// Define customStyles similar to ManufacturerInput
	const customStyles = {
		borderColor: fieldError[question.key]
			? "red"
			: ocrConfidenceAndColorObj.color
				? ocrConfidenceAndColorObj.color
				: "#ced4da",
		boxShadow: ocrConfidenceAndColorObj.color
			? `0 0 10px ${ocrConfidenceAndColorObj.color}`
			: undefined,
		outline: ocrConfidenceAndColorObj.color
			? `1px solid ${ocrConfidenceAndColorObj.color}`
			: "none",
		transition:
			"border-color 0.3s ease, box-shadow 0.3s ease, outline 0.3s ease",
		width: "100%",
		...(isHovered && {
			borderColor: fieldError[question.key]
				? "red"
				: ocrConfidenceAndColorObj.color
					? ocrConfidenceAndColorObj.color
					: "#ced4da",
		}),
	};

	// Handle checkbox changes
	const handleCheckboxChange = (
		checkboxQuestion: any,
		checkboxValue: boolean,
	) => {
		setInputData(checkboxQuestion, checkboxValue);
		if (checkboxValue) {
			setInputData({ key: question.key }, null);
			setFieldErrorFn(question.key, "");
		}
	};

	return (
		<Row key={question.key} hidden={numOptions === 0 || !numOptions}>
			<FormGroup>
				<div
					className={`d-flex flex-row gap-1 align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div className="d-flex flex-row gap-1">
						<Label className="single-form-label">
							{question.label}
							{question.required === "true" && (
								<span className="text-red"> *</span>
							)}
						</Label>

						{!hidePreview ? (
							<PreviewField
								question={question}
								responseData={responseData}
								fromChatbot={fromChatbot}
							/>
						) : null}
						{!responseData[question.key] && placeholder && (
							<GeneratedPropIcon />
						)}
					</div>

					{question.checkbox ? (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={(checkboxKey: any, checkboxValue: any) =>
								handleCheckboxChange(checkboxKey, checkboxValue)
							}
							notStandalone={true}
						/>
					) : null}
				</div>
				<div
					className="position-relative"
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<Input
						type="select"
						onChange={handleChange}
						className={`form-inputs ${
							fieldError[question.key] ? "error-border" : ""
						} ${question.highlighted ? "highlight-input" : ""}`}
						style={customStyles}
						value={getValue(question.key, chosenAssetType, responseData)}
						disabled={shouldDisable()}
					>
						<option value="" disabled hidden>
							{placeholder || question?.placeholder || "Select an option"}
						</option>
						{getOptions(question, responseData[question.key])}
					</Input>
					{ocrConfidenceAndColorObj.color &&
						ocrConfidenceAndColorObj.confidence && (
							<span
								className="confidence-text"
								style={{
									color: ocrConfidenceAndColorObj.color,
									position: "absolute",
									right: "10px",
									top: "50%",
									transform: "translateY(-50%)",
									pointerEvents: "none",
								}}
							>
								{ocrConfidenceAndColorObj.confidence}%
							</span>
						)}
				</div>
				{question.key === "assetType" &&
					!fromChatbot &&
					generateAssetSuggestions()}
				{fieldError[question.key] && (
					<div className="error-message" style={{ color: "red" }}>
						{fieldError[question.key]}
					</div>
				)}
			</FormGroup>
		</Row>
	);
};

export { SelectInput };
