import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { SignedIn } from "@layouts/SignedIn/SignedIn";
import { useUserStore } from "@stores/userStore";
import { Login } from "@views/Login/Login";
import { useEffect, useState } from "react";

export const ProtectedRoute = () => {
	const [loading, setLoading] = useState(true);
	const authInfo = useUserStore((state: any) => state.authInfo);

	const updateLoading = async () => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	useEffect(() => {
		updateLoading();
	}, []);

	return loading ? <Loader /> : authInfo.loggedIn ? <SignedIn /> : <Login />;
};

export default ProtectedRoute;
