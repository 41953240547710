import { Space, Switch } from "antd";

const DeletedSwitch = ({ state, dispatch }) => {
	const { selectedFormMode, viewingDeleted } = state;
	const onSwitchChange = (checked: boolean) => {
		dispatch({ type: "SET_VIEWING_DELETED", payload: checked });
	};

	return (
		selectedFormMode === "0" && (
			<Space>
				<Switch
					onChange={onSwitchChange}
					disabled={state.isLoading}
					checked={viewingDeleted}
				/>
				<span>View Deleted Submissions</span>
			</Space>
		)
	);
};

export { DeletedSwitch };
