import type { StringMap } from "@/models/abstractTypes";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { type StoreApi, type UseBoundStore, create } from "zustand";

interface LocationStore {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	pageSnapshots: StringMap;
	setPageSnapshot: (pageNum: number, snapshot: string) => void;
	clearPageSnapshots: () => void;
	submissionsColumnFilters: StringMap;
	submissionsTempFilters: StringMap;
	locationsTableColumnFilters: StringMap;
	locationsTableTempFilters: StringMap;
	setLocationsTableTempFilters: (filters: StringMap) => void;
	setLocationsTableColumnFilters: (filters: StringMap) => void;
	setSubmissionsTempFilters: (filters: StringMap) => void;
	setSubmissionsColumnFilters: (filters: StringMap) => void;
	assetsColumnFilters: StringMap;
	assetsTempFilters: StringMap;
	setAssetsTempFilters: (filters: StringMap) => void;
	setAssetsColumnFilters: (filters: StringMap) => void;
	assetLocationToggle: string;
	setAssetLocationToggle: (toggle: string) => void;
	submissionsLocationToggle: string;
	setSubmissionsLocationToggle: (toggle: string) => void;
	clearFilters: () => void;
}

export const useLocationStore: UseBoundStore<StoreApi<LocationStore>> = create(
	(set) => ({
		activeTab: "Overview",
		setActiveTab: (tab: string) => set({ activeTab: tab }),
		pageSnapshots: {},
		setPageSnapshot: (pageNum: number, snapshot: string) =>
			set((state: { pageSnapshots: StringMap }) => ({
				pageSnapshots: {
					...state.pageSnapshots,
					[pageNum]: snapshot,
				},
			})),
		clearPageSnapshots: () => set({ pageSnapshots: {} }),
		submissionsColumnFilters: {},
		submissionsTempFilters: {},
		locationsTableColumnFilters: {},
		locationsTableTempFilters: {},
		setLocationsTableTempFilters: (filters: StringMap) =>
			set({ locationsTableTempFilters: filters }),
		setLocationsTableColumnFilters: (filters: StringMap) =>
			set({ locationsTableColumnFilters: filters }),
		setSubmissionsTempFilters: (filters: StringMap) =>
			set({ submissionsTempFilters: filters }),
		setSubmissionsColumnFilters: (filters: StringMap) =>
			set({ submissionsColumnFilters: filters }),
		assetsColumnFilters: {},
		assetsTempFilters: {},
		setAssetsTempFilters: (filters: StringMap) =>
			set({ assetsTempFilters: filters }),
		setAssetsColumnFilters: (filters: StringMap) =>
			set({ assetsColumnFilters: filters }),
		assetLocationToggle: "city",
		setAssetLocationToggle: (toggle: string) =>
			set({ assetLocationToggle: toggle }),
		submissionsLocationToggle: "city",
		setSubmissionsLocationToggle: (toggle: string) =>
			set({ submissionsLocationToggle: toggle }),
		clearFilters: () =>
			set({
				submissionsTempFilters: {},
				assetsTempFilters: {},
				submissionsColumnFilters: {},
				assetsColumnFilters: {},
				locationsTableColumnFilters: {},
				locationsTableTempFilters: {},
				assetLocationToggle: "city",
				submissionsLocationToggle: "city",
			}),
	}),
);

if (import.meta.env.NODE_ENV === "development") {
	mountStoreDevtool("locationStore", useLocationStore);
}
