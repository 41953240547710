import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleAssetTagPhoto = (
	urlPromise,
	setResponseData,
	setState,
	question,
	capturePhoto,
	options,
	setAssetTagPhoto,
	saveDataCaptureState,
	setCurrentState,
) => {
	const prevMessageNoWidget = createChatBotMessage(
		"Please upload a photo of the Asset Tag Id.",
		{
			withAvatar: true,
		},
	);
	const userMessage = createClientMessage("", {
		widget: "CustomImageMessage",
		payload: { question, capturePhoto, imageUrl: urlPromise },
	});

	const message = createChatBotMessage(
		"Thank you. Where is the asset located in the store?",
		{
			widget: "OptionalSelectors",
			withAvatar: true,
			payload: { topThreeLocations: [] },
		},
	);

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message, action: "add" },
	]);

	const currentState = {
		options: options["equipment-area"]?.map((loc) =>
			loc?.value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
		),
		stage: "assetLocation",
		showOptions: true,
	};
	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			message,
		],
		...currentState,
	}));

	urlPromise.then((url) => {
		setResponseData({
			[question.key]: url,
		});
		setAssetTagPhoto(url);
	});
};
