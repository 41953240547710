export const notAvailableMapping = {
	manufacturer: "manufacturerNotAvailable",
	modelNumber: "modelNotAvailable",
	serialNumber: "serialNotAvailable",
	manufacturerDate: "manufacturerDateNotFound",
};

export const notAvailableMappingAdditionalDetails = {
	refrigerant: "refrigerantNotAvailable",
	refrigerantCharge: "refrigerantChargeNotAvailable",
	voltage: "voltageNotAvailable",
	amps: "ampsNotAvailable",
};

export const notAvailableMappingArcAdditionalDetails = {
	refrigerant: "refrigerantNotAvailable",
	refrigerantCharge: "refrigerantChargeNotAvailable",
};
