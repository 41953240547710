import { Loader } from "@SignedIn/Loader/Loader";
import placeholderBackground from "@assets/img/PE-logo-clear-one-line.png";
import { auth } from "@assets/services/auth-service";
import { Viewer } from "@photo-sphere-viewer/core";
import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
let viewer;
/**
 * Element that handles displaying / generating facility vision image given > 1 images in responseData.photoArray
 * @param organization
 * @param responseData
 * @param setResponseData
 * @returns {JSX.Element}
 * @constructor
 */
const PreviewFVField = ({ organization, responseData, setResponseData }) => {
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const viewerRef = useRef(null);
	const nameRef = useRef(null);

	/**
	 * Sends all files in the selectedFiles variable to the backend to be stitched
	 */
	const handleSubmit = async () => {
		if (responseData.photoArray.length === 0) {
			setErrorMessage("No photos have been selected.");
		} else {
			const formData = new FormData();
			setIsLoading(true);
			const blobs = await compress_photos(3000, 0.35);
			blobs.forEach((blob) => {
				formData.append("images", blob);
			});
			try {
				const response = await fetch(
					`${import.meta.env.VITE_ALFRED_SERVICE_URL}/CreateStitchedImage/`,
					{
						method: "POST",
						body: formData,
						headers: {
							Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
						},
					},
				);

				if (response.ok) {
					response.blob().then((blob) => {
						//Set the viewer image and then update the state (which will cause the viewer to then show)
						//If the viewer is initialized
						if (viewer) {
							viewer.setPanorama(URL.createObjectURL(blob)).then(() => {
								console.log(blob);
								setResponseData({
									...responseData,
									photoPreview: blob,
								});
							});
						} else {
							//Create viewer and set image if viewer is not initialized
							initViewer(blob).then(() => {
								console.log("Viewer created and image set.");
								setResponseData({
									...responseData,
									photoPreview: blob,
								});
							});
						}
					});
				} else {
					response.json().then((err) => {
						console.error("Error uploading files:", err.detail);
						setErrorMessage(
							`Error Creating Facility Vision image: ${err.detail}`,
						);
					});
				}
			} catch (error) {
				console.error("Error uploading files:", error);
				setErrorMessage(
					"Error Creating Facility Vision image, please try again later.",
				);
			} finally {
				setIsLoading(false);
			}
		}
	};
	/**
	 * Converts the files found in selectedFiles into webp blobs to compress them
	 *
	 * @returns {array} an array of blobs of the converted images
	 * @param max_height The max height an image is allowed to be when compressing
	 * @param quality The compression quality to apply to the images when converting to webp
	 */
	const compress_photos = async (max_height = 3000, quality = 0.35) => {
		const processImage = (img) => {
			return new Promise((resolve, reject) => {
				try {
					const canvas = document.createElement("canvas");
					canvas.width = img.width > max_height ? max_height : img.width;
					canvas.height = canvas.width / 1.25;
					canvas
						.getContext("2d")
						.drawImage(img, 0, 0, canvas.width, canvas.height);

					canvas.toBlob(
						(blob) => {
							resolve(blob); // Resolve the promise with the blob
						},
						"image/jpeg",
						quality,
					);
				} catch (e) {
					console.log(`Failed to process image to webp: ${e}`);
					return reject;
				}
			});
		};

		return await Promise.all(
			responseData.photoArray.map((img) => processImage(img)),
		);
	};
	/**
	 * Uploads a stitched image photo to the backend
	 */
	const uploadPhoto = async () => {
		//Validation
		if (nameRef.current.value === "") {
			setErrorMessage("Photo name cannot be blank.");
			return;
		}
		try {
			setIsLoading(true);
			const formData = new FormData();

			formData.append("photo_name", nameRef.current.value);
			formData.append("org", organization);
			formData.append("location_id", responseData.location);
			formData.append("image", responseData.photoPreview, "blob.txt");

			const response = await fetch(
				`${import.meta.env.VITE_ALFRED_SERVICE_URL}/UploadStitchedImage/`,
				{
					method: "POST",
					body: formData,
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			);

			if (response.ok) {
				console.log("Upload Success");
				setResponseData({
					...responseData,
					isSubmitted: true,
				});
			} else {
				console.error("Error uploading files:", response.statusText);
				setErrorMessage(
					"Error uploading photo to server, please try again later.",
				);
			}
		} catch (error) {
			console.error("Error uploading files:", error);
			setErrorMessage(
				"Error uploading photo to server, please try again later.",
			);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * Generates an error banner to display to the user
	 * @returns {JSX.Element}
	 */
	const error_banner = () => {
		return (
			<div className="errorBanner">
				<span className="errorMessage">
					<i className="errorIcon">⚠️</i>
					{errorMessage}
				</span>
				<button onClick={() => setErrorMessage(null)} className="closeButton">
					✖
				</button>
			</div>
		);
	};

	/**
	 * Initializes the Photo Sphere Viewer Element
	 */
	const initViewer = async (blob) => {
		if (viewer === undefined || viewer === null) {
			return new Promise((resolve, _reject) => {
				viewer = new Viewer({
					container: viewerRef.current,
					panorama:
						blob || responseData.photoPreview
							? URL.createObjectURL(blob || responseData.photoPreview)
							: placeholderBackground,
				});
				// Event listener for when a new panorama is loaded
				viewer.addEventListener(
					"ready",
					() => {
						resolve(viewer);
						console.log("Viewer initialized: ", viewer.config);
					},
					{ once: true },
				);
			});
		}
	};

	/**
	 * If we get a successful stitched image returned, create a preview viewer
	 */
	useEffect(() => {
		if (responseData.photoPreview) {
			initViewer(responseData.photoPreview).then(() => {
				console.log("Viewer created with responseData.photoPrewview");
			});
		} else if (responseData.photoArray.length > 1) {
			initViewer().then(() => {
				console.log(
					"Viewer created with placeholder and now auto-fetching stitched image",
				);
				handleSubmit();
			});
		}

		return () => {
			if (viewer) {
				viewer.destroy();
			}
			viewer = null;
		};
	}, []);

	return (
		<div className="container">
			{errorMessage !== null && error_banner()}
			{isLoading && <Loader />}
			<div className="justify-content-center row mb-3">
				<div
					id="viewer"
					ref={viewerRef}
					style={{
						minWidth: "35vh",
						height: "45vh",
						display: responseData.photoPreview && !isLoading ? "block" : "none",
					}}
				></div>
			</div>
			{!responseData.isSubmitted ? (
				<div>
					<div className="row justify-content-center mb-3 text-center">
						<Button
							style={{
								width: "auto",
								backgroundColor: "#250245",
							}}
							onClick={handleSubmit}
						>
							{responseData.photoPreview
								? "Regenerate Image"
								: "Generate Image"}
						</Button>
					</div>
					{responseData.photoPreview && (
						<div className="row justify-content-center align-items-center">
							<hr />
							<div className="col-md-6 text-center">
								<h4>What do you want to name this image?</h4>
								<input
									type="text"
									className="form-control"
									placeholder="Enter name here"
									ref={nameRef}
								/>
								<Button
									type="submit"
									className="btn mt-2"
									onClick={uploadPhoto}
									style={{
										width: "auto",
										backgroundColor: "#250245",
									}}
								>
									Submit
								</Button>
							</div>
						</div>
					)}
				</div>
			) : (
				<p style={{ textAlign: "center" }}>
					The photo has been successfully uploaded! You can view it under the
					locations tab under Facility Vision.
				</p>
			)}
		</div>
	);
};

export { PreviewFVField };
