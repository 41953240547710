import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Form, Table } from "reactstrap";
import { searchForms } from "./Search";

const FormsTable = (props) => {
	const { allForms } = props;
	const navigate = useNavigate();

	const [searchVal, setSearchVal] = useState("");
	const [forms, setForms] = useState();
	const [rowsTable, setRowsTable] = useState();
	const [formsChange, setFormsChange] = useState(false);

	// Handles search
	const handleSearch = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		const results = await searchForms(searchVal, allForms);
		if (results.length === 0 && searchVal === "") {
			await setForms(allForms);
		} else if (results.length === 0) {
			await setForms([]);
		} else {
			await setForms(results);
		}
		setFormsChange(true);
	};

	// Update forms state when allForms state changes
	useEffect(() => {
		setForms(allForms);
		setFormsChange(true);
	}, [allForms]);

	// Run search function when searchVal state changes
	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchVal]);

	useEffect(() => {
		if (formsChange === true && forms != null) {
			if (forms.length > 0) {
				let formRows = [];
				for (let i = 0; i < forms.length; i++) {
					const row = (
						<tr key={forms[i].id} style={{ verticalAlign: "baseline" }}>
							<td
								style={{ whiteSpace: "nowrap" }}
								onClick={() => {
									navigate(
										forms[i].org
											? `/forms/${forms[i].id}/${forms[i].org}`
											: `/forms/${forms[i].id}`,
										{
											state: { data: forms[i].data },
											org: forms[i].orgId,
										},
									);
								}}
							>
								<span
									className="form-logo-avatar"
									hidden={!forms[i].data.config.logo}
								>
									<img
										src={forms[i].data.config.logo}
										className="avatar"
										alt="avatar"
									/>
								</span>
								{forms[i].data.master_form_key.name}
							</td>
						</tr>
					);
					formRows = [...formRows, row];
				}
				setRowsTable(
					<Table responsive hover>
						<thead>
							<tr>
								<th>{/* <h4>Report Name</h4> */}</th>
								{/* <th>
									<div />
								</th> */}
							</tr>
						</thead>
						<tbody>{formRows}</tbody>
						<tr>
							<td>
								<div />
							</td>
							{/* <td className='d-flex justify-content-end'>
								<Button
									id='add-report-button'
									color='secondary'
									size='md'
									className='m-2'
								>
									Add Report
								</Button>
							</td> */}
						</tr>
					</Table>,
				);
			} else if (searchVal.length > 0) {
				setRowsTable(
					<CardBody>
						<div
							className="d-flex flex-column justify-content-center align-items-center w-100"
							style={{ minHeight: "400px" }}
						>
							<div>No search results. Try again.</div>
						</div>
					</CardBody>,
				);
			} else {
				setRowsTable(
					<Table responsive hover>
						<thead>
							<tr>
								<th>
									<Loader />
								</th>
								<th>
									<div />
								</th>
							</tr>
						</thead>
						<tbody></tbody>
					</Table>,
				);
			}
			setFormsChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formsChange]);

	return (
		<div>
			<Card className="forms-card px-2 pt-4">
				<CardHeader className="gray-background">
					<Form onSubmit={handleSearch}>
						<span className="forms-search-label" />
						<input
							id="search"
							className="searchbar rounded-pill"
							type="text"
							placeholder="Search"
							value={searchVal}
							onChange={(e) => {
								setSearchVal(e.target.value);
							}}
						/>
					</Form>
				</CardHeader>
				{rowsTable}
			</Card>
		</div>
	);
};

export { FormsTable };
