export const isEqual = (value1: { [x: string]: any } | null, value2: any[]) => {
	if (typeof value1 !== typeof value2) {
		return false;
	}

	if (typeof value1 !== "object" || value1 === null) {
		return value1 === value2;
	}

	const keys1 = Object.keys(value1);
	const keys2 = Object.keys(value2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (!isEqual(value1[key], value2[key])) {
			return false;
		}
	}

	return true;
};
