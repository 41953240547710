import { ProgressBar } from "react-bootstrap";
import { BsCheckLg, BsExclamationCircleFill } from "react-icons/bs";

/**
 * InteractableProgressBar Component
 *
 * This component extends the ProgressBar component from 'react-bootstrap'
 * to include interactive circle icons representing different progress points.
 *
 * @param {number} availableChoice - The total number of choices/progress points.
 * @param {function} onClick - Callback function triggered on clicking a circle icon.
 * @param {...rest} rest - Additional props passed down to the ProgressBar component.
 *
 * @returns {JSX.Element} - Returns a composite component with a progress bar and interactive circle icons.
 */
const InteractableProgressBar = ({
	availableChoice,
	validationStates,
	submitAttempted,
	onClick,
	...rest
}) => {
	const circleIcons = [];

	for (let i = 0; i < availableChoice; i++) {
		const isFilled = validationStates[i];
		const isPast = i < Math.ceil(rest.now / Math.ceil(100 / availableChoice));

		let circleClass = "progress-circle";
		let content = i + 1;

		if (isFilled) {
			content = <BsCheckLg />;
		} else if (isPast) {
			content = (
				<BsExclamationCircleFill className="validation-icon">
					!
				</BsExclamationCircleFill>
			);
			circleClass += submitAttempted ? " red-background" : " yellow-background";
		}

		circleIcons.push(
			<span key={i} className={circleClass} onClick={() => onClick(i)}>
				{content}
			</span>,
		);
	}

	return (
		<div className="position-relative w-100 d-flex justify-content-center">
			<ProgressBar {...rest} />
			<div className="position-absolute w-100 d-flex justify-content-around align-items-center progress-bar-circle-wrapper">
				{circleIcons}
			</div>
		</div>
	);
};

export { InteractableProgressBar };
