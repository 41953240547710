import { notAvailableWarrantySwarovski } from "@constants/formInputs/warranty";

export const warrantyLogic = (
	field,
	organization,
	currentPage,
	responseData,
) => {
	if (organization === "swarovski.com" && currentPage === 6) {
		const notAvailableField = notAvailableWarrantySwarovski[field];

		if (responseData[notAvailableField]) {
			return true;
		}

		if (field in responseData && responseData[field] !== "") {
			return true;
		}

		console.warn(`Field '${field}' is empty and not marked as not available.`);
		return false;
	}

	return true;
};
