import { useSelfUpdateUser } from "@/hooks/userQueries";
import { storage } from "@assets/services/auth-service";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { useUserStore } from "@stores";
import { useEffect, useRef, useState } from "react";

const AvatarUpload = (props) => {
	const { alt, className } = props;

	const user = useUserStore((state) => state.user);

	const inputRef = useRef();

	const maxFileSize = 10 * 1024 * 1024; // 5 MB

	// Image input state
	const [imageInput, setImageInput] = useState();

	// Image input overlay class
	const [overlayClass, setOverlayClass] = useState("inactive");

	// Image source state
	const [imageSource, setImageSource] = useState();

	// Avatar component state
	const [avatar, setAvatar] = useState();

	const updateUser = useSelfUpdateUser();

	// Updates the img state with an image link from firestore
	const initImageSource = async () => {
		const imgPath =
			user.user_config.profile_picture_url ||
			"ProfilePics/default-profile-pic.png";
		const imgRef = ref(storage, imgPath);
		const url = await getDownloadURL(imgRef);
		setImageSource(url);
	};

	// Update img state upon init render
	useEffect(() => {
		initImageSource();
	}, []);

	// Update avatar state when imageSource state changes
	useEffect(() => {
		if (imageSource != null) {
			setAvatar(
				<img
					src={imageSource}
					alt={alt}
					className={`avatar-upload-image ${className}`}
				/>,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageSource]);

	// Returns true if passed file is an image
	const isImage = (file) => {
		const validTypes = ["image/png", "image/jpg", "image/jpeg"];
		if (validTypes.includes(file.type)) {
			return true;
		}
		return false;
	};

	// Upload file to storage + user doc & return file pathname for future retrieval
	const uploadImage = async (file, path) => {
		const reference = ref(storage, path);
		try {
			await uploadBytes(reference, file);
			const newUrl = await getDownloadURL(reference);
			await updateUser.mutate({ profile_picture_url: path });
			return newUrl;
		} catch (_error) {
			return null;
		}
	};

	// Runs when user selects image to upload
	const validateImage = async () => {
		if (
			inputRef.current.files[0].size > maxFileSize ||
			isImage(inputRef.current.files[0]) === false
		) {
			inputRef.current.value = "";
		} else {
			const file = inputRef.current.files[0];
			const path = `ProfilePics/${file.name}`;
			const result = await uploadImage(file, path);
			if (result != null) {
				setImageSource(result);
			}
		}
	};

	// Update imageInput state when inputRef changes
	useEffect(() => {
		setImageInput(
			<input
				ref={inputRef}
				type="file"
				className="avatar-upload-input"
				onChange={validateImage}
			/>,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputRef]);

	return (
		<div
			className="avatar-upload-parent d-flex"
			onPointerDown={() => setOverlayClass("active")}
			onPointerUp={() => setOverlayClass("inactive")}
			onMouseDown={() => setOverlayClass("active")}
			onMouseUp={() => setOverlayClass("inactive")}
			onMouseOver={() => setOverlayClass("active")}
			onMouseOut={() => setOverlayClass("inactive")}
		>
			{avatar}
			{imageInput}
			<div className={`${overlayClass} avatar-upload-overlay`} />
		</div>
	);
};

export { AvatarUpload };
