import { initForm } from "@views/SignedIn/SingleForm/childFunctions/initForm";
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const continueToLocationOptions = async (
	latLonRef,
	setRetryLocation,
	organization,
	setManufacturers,
	setAssetTypes,
	setShouldAutoSave,
	setOrgSpecificInputs,
	setAdditionalLocationInputs,
	setState,
	setFlags,
	formId,
	setFormName,
	setFormLogo,
	setUserLocation,
	setInputData,
	question,
	saveDataCaptureState,
	setCurrentState,
	formChosen = false,
	form = null,
) => {
	const isMobile = window.innerWidth < 768;
	const firebaseConfig = JSON.parse(
		import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
	);
	const env = import.meta.env.VITE_ENV;
	const url =
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/getClosestLocations"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/getClosestLocations"
				: "http://127.0.0.1:5001/property-echo-4d747/us-central1/getClosestLocations";
	const method = "POST";
	const headers = {
		"Content-Type": "application/json",
	};

	if (Object.keys(latLonRef.current).length === 0) {
		navigator.permissions.query({ name: "geolocation" }).then((result) => {
			if (result.state === "granted") {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						latLonRef.current = {
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						};
						// Resolve the promise or continue your logic here
					},
					(error) => {
						console.error("Error getting location:", error);
						latLonRef.current = {
							latitude: undefined,
							longitude: undefined,
						};
						// Handle error or resolve promise with default values
					},
				);
			} else if (
				(result.state === "prompt" || result.state === "denied") &&
				!isMobile
			) {
				const res = createChatBotMessage(
					"Please enable location services to continue.",
				);
				saveDataCaptureState([{ message: res, action: "add" }]);
				setCurrentState({ showInitialOptions: true });
				setState((prev) => ({
					...prev,
					messages: [...prev.messages, res],
					showInitialOptions: false,
				}));
			} else {
				latLonRef.current = {
					latitude: undefined,
					longitude: undefined,
				};
			}
		});
		await new Promise((resolve) => {
			const checkLatLon = setInterval(() => {
				setRetryLocation((prev) => !prev);
				if (Object.keys(latLonRef.current).length > 0) {
					clearInterval(checkLatLon);
					resolve();
				}
			}, 100);
		});
	}
	let topThreeLocations;

	if (!latLonRef.current.latitude || !latLonRef.current.longitude) {
		topThreeLocations = [];
	} else {
		const body = JSON.stringify({
			organization,
			userLocation: latLonRef.current,
			isProd: !firebaseConfig.projectId.includes("staging"),
		});
		const res = await fetch(url, {
			method,
			headers,
			body,
		});
		const resJson = await res.json();
		topThreeLocations = resJson?.locations.slice(0, 3);
	}

	const message = createChatBotMessage("Select location:", {
		widget: "OptionalSelectors",
		withAvatar: true,
		payload: {
			topThreeLocations,
			setUserLocation,
			setInputData,
			question,
			capturePhoto: () => {},
		},
	});
	const currentState = {
		showInitialOptions: false,
		options: [
			...topThreeLocations.map(
				(loc) => `${loc.data?.name}, ${loc.data?.city}, ${loc.data?.state}`,
			),
			"Other Location",
		],
		stage: "loc",
		showOptions: true,
		otherLocation: topThreeLocations.length === 0,
	};
	setCurrentState(currentState);

	if (formChosen) {
		const prevMessageNoWidget = createChatBotMessage(
			"Please select the form you would like to fill out:",
			{
				withAvatar: true,
			},
		);
		const userMessage = createClientMessage(form);

		saveDataCaptureState([
			{ message: prevMessageNoWidget, action: "removeWidget" },
			{ message: userMessage, action: "add" },
			{ message, action: "add" },
		]);

		setState((prev) => ({
			...prev,
			messages: [...prev.messages, prevMessageNoWidget, userMessage, message],
			...currentState,
		}));
	} else {
		const prevMessageNoWidget = createChatBotMessage(
			"Hi, I'm FacilityAI, your personalized Facility Manager Assistant!\n\nAsk me anything related to your facilities and assets or choose an option below!",
			{ withAvatar: true },
		);
		saveDataCaptureState([{ message, action: "add" }]);
		setState((prev) => ({
			...prev,
			messages: [...prev.messages.slice(0, -1), prevMessageNoWidget, message],
			...currentState,
		}));
	}

	initForm(
		setManufacturers,
		organization,
		setAssetTypes,
		false,
		setShouldAutoSave,
		setOrgSpecificInputs,
		setAdditionalLocationInputs,
		formId,
		setFormName,
		setFormLogo,
		setFlags,
	);
};
