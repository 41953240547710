import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { Submission } from "@/components/layouts/SignedIn/views/SingleLocation/SubmissionsTab/children/Submission";
import { SubmissionCushman } from "@/components/layouts/SignedIn/views/SingleLocation/SubmissionsTab/children/SubmissionCushman";
import { SubmissionRC } from "@/components/layouts/SignedIn/views/SingleLocation/SubmissionsTab/children/SubmissionRC";
import { DB_ORG } from "@/constants/db";
import { useUserStore } from "@stores/userStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SubmissionDetail = () => {
	const { submissionId } = useParams<{ submissionId: string }>();
	const location = useLocation();
	const navigate = useNavigate();
	const { userOrganization } = useUserStore();

	const toggleSubmission = () => {
		const isAdminView: boolean = location.pathname.includes("admin");
		const pathName = location.pathname;
		const locationId = pathName.split("/")[2];
		navigate(
			isAdminView
				? "/admin/submissions"
				: `/locations/${locationId}/submissions`,
			{ state: location.state },
		);
	};

	if (!submissionId) {
		return <Loader />;
	}

	// Render the appropriate submission component based on the organization
	const renderSubmissionComponent = () => {
		if (
			userOrganization?.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD &&
			location.state?.formId !== 3
		) {
			return (
				<SubmissionCushman
					submissionId={submissionId}
					formId={location.state?.formId}
					toggleSubmission={toggleSubmission}
				/>
			);
		}
		if (
			userOrganization?.external_firebase_id === DB_ORG.RAISINGCANES &&
			location.state?.formId !== 3
		) {
			return (
				<SubmissionRC
					submissionId={submissionId}
					formId={location.state?.formId}
					toggleSubmission={toggleSubmission}
				/>
			);
		}
		return (
			<Submission
				submissionId={submissionId}
				formId={location.state?.formId || 3}
				toggleSubmission={toggleSubmission}
			/>
		);
	};

	return <div>{renderSubmissionComponent()}</div>;
};

export { SubmissionDetail };
