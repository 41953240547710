import { routeMap } from "@/layouts/SignedIn/integrations/IntegrationLayout";
import { getIntegrationById } from "@/services/integration-service";
import { Avatar } from "@SignedIn/Avatar/Avatar";
import { DB_ORG } from "@constants/db";
import { useLogoutMutation } from "@hooks/authQueries";
import { useFormStore } from "@stores";
import { useUserStore } from "@stores";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { AiOutlineDashboard, AiOutlineUser } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { GiTwoCoins } from "react-icons/gi";
import { IoMdArrowDropup } from "react-icons/io";
import { RiAdminLine } from "react-icons/ri";
import { TbCheckupList, TbMap2, TbReport } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { OrgDropdown } from "./OrgDropdown";
// import SVG from 'react-inlinesvg';

const Sidebar = (props) => {
	const [openAvatar, setOpenAvatar] = useState(false);
	const { setShowDraftSelectionModal, setShowDraftSelectionModalCushman } =
		useFormStore();
	const wrapper = useRef(null);
	const logout = useLogoutMutation();
	const { userRole } = props;
	const [_isSuperUser, setIsSuperUser] = useState(
		String(userRole?.name).toUpperCase() === "SUPERUSER",
	);
	const [_integrationsInfo, setIntegrationsInfo] = useState([]);
	const { userOrganization } = useUserStore((state) => ({
		userOrganization: state.userOrganization,
		setUserOrganization: state.setUserOrganization,
	}));

	useMemo(() => {
		if (String(userRole?.name).toUpperCase() === "SUPERUSER") {
			setIsSuperUser(true);
		}
	}, [userRole]);

	useEffect(() => {
		const fetchIntegrations = async () => {
			if (userOrganization?.config?.integration_settings) {
				const integrationData = await Promise.all(
					Object.keys(userOrganization?.config?.integration_settings).map(
						async (id) => {
							const integration =
								userOrganization?.config?.integration_settings[id];
							if (integration?.is_enabled) {
								const integrationInfo = await getIntegrationById(id);
								const obj = {
									key: id,
									route: routeMap?.[id],
									integrationInfo,
								};
								return obj;
							}
							return null;
						},
					),
				);
				setIntegrationsInfo(integrationData.filter(Boolean));
			}
		};

		fetchIntegrations();
	}, [userOrganization?.config?.integration_settings]);
	return (
		<nav
			className={`sidebar ${
				props.isSidebarOpen && "sidebar-expanded"
			} d-md-flex flex-md-column collapse position-fixed`}
			ref={wrapper}
		>
			<div className="user">
				<div className="photo">
					<Avatar alt="Avatar" className="avatar" />
				</div>
				<div className="info">
					<span
						style={{ color: "white" }}
						data-toggle="collapse"
						aria-expanded={openAvatar}
						onClick={() => setOpenAvatar(!openAvatar)}
					>
						<div className="sidebar-username-wrapper">
							<div
								className={`sidebar-username ${
									!props.isSidebarOpen && "hidden"
								}`}
							>
								{props.name}
							</div>
							<div
								className={
									openAvatar
										? "arrow-icon-up-wrapper"
										: "arrow-icon-down-wrapper"
								}
							>
								<IoMdArrowDropup className="arrow-icon" />
							</div>
						</div>
					</span>
					<Collapse isOpen={openAvatar}>
						<ul className="nav">
							<li
								className={`sidebar-normal ${
									!props.isSidebarOpen && "sidebar-normal-collapsed"
								}`}
							>
								<NavLink
									to="/profile"
									className={(isActive) =>
										isActive.isActive ? "active" : "inactive"
									}
								>
									<div className="sidebar-item-wrapper">
										<div
											className="sidebar-mini-icon"
											style={{
												transform: "translate(1px, 4px)",
											}}
										>
											<AiOutlineUser />
										</div>

										<div
											className={`sidebar-profile-item ${
												!props.isSidebarOpen && "hidden"
											}`}
										>
											My Profile
										</div>
									</div>
									<div
										className={`blend-effect ${
											props.isSidebarOpen && "blend-effect-expanded"
										}`}
									/>
									<div className="blend-effect-top" />
								</NavLink>
							</li>
							<li
								className={`sidebar-normal log-out ${
									!props.isSidebarOpen && "sidebar-normal-collapsed"
								}`}
							>
								<div className="inactive" onClick={logout.mutate}>
									<div className="sidebar-mini-icon log-out-icon">
										<BiLogOut />
									</div>
									<div
										className={`sidebar-profile-item log-out-text ${
											!props.isSidebarOpen && "hidden"
										}`}
									>
										Log Out
									</div>
								</div>
							</li>
						</ul>
					</Collapse>
				</div>
			</div>
			<hr className="text-white"></hr>
			<ul className="nav nav-topics">
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && "sidebar-normal-collapsed"
					}`}
				>
					<NavLink
						to="/"
						className={(isActive) =>
							isActive.isActive ? "active" : "inactive"
						}
						onClick={() => setOpenAvatar(false)}
					>
						<div className="sidebar-mini-icon">
							<AiOutlineDashboard />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && "hidden"
							}`}
						>
							Dashboard
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && "blend-effect-expanded"
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && "sidebar-normal-collapsed"
					}`}
				>
					<NavLink
						to="/locations"
						className={(isActive) =>
							isActive.isActive ? "active" : "inactive"
						}
						onClick={() => setOpenAvatar(false)}
					>
						<div className="sidebar-mini-icon">
							<TbMap2 />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && "hidden"
							}`}
						>
							Locations
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && "blend-effect-expanded"
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && "sidebar-normal-collapsed"
					}`}
				>
					<NavLink
						to="/forms"
						className={(isActive) =>
							isActive.isActive ? "active" : "inactive"
						}
						onClick={() => {
							setOpenAvatar(false);
							props.org === DB_ORG.CUSHMANWAKEFIELD
								? setShowDraftSelectionModalCushman(true)
								: setShowDraftSelectionModal(true);
						}}
					>
						<div className="sidebar-mini-icon">
							<TbReport />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && "hidden"
							}`}
						>
							Data Capture
						</div>

						<div
							className={`blend-effect ${
								props.isSidebarOpen && "blend-effect-expanded"
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && "sidebar-normal-collapsed"
					}`}
				>
					<NavLink
						to="/checkups"
						className={(isActive) =>
							isActive.isActive ? "active" : "inactive"
						}
						onClick={() => setOpenAvatar(false)}
					>
						<div className="sidebar-mini-icon">
							<TbCheckupList />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && "hidden"
							}`}
						>
							Check Ups
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && "blend-effect-expanded"
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && "sidebar-normal-collapsed"
					}`}
				>
					<NavLink
						to="/predictive-spend"
						className={(isActive) =>
							isActive.isActive ? "active" : "inactive"
						}
						onClick={() => setOpenAvatar(false)}
					>
						<div className="sidebar-mini-icon">
							<GiTwoCoins />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && "hidden"
							}`}
						>
							Predictive Spend
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && "blend-effect-expanded"
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && "sidebar-normal-collapsed"
					}`}
				>
					<NavLink
						to={props.canViewAdmin ? "/admin" : "/plans"}
						className={(isActive) =>
							isActive.isActive ? "active" : "inactive"
						}
						onClick={() => setOpenAvatar(false)}
					>
						<div className="sidebar-mini-icon">
							<RiAdminLine />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && "hidden"
							}`}
						>
							Administration
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && "blend-effect-expanded"
							}`}
						/>
					</NavLink>
				</li>
				{/* {userOrganization?.config?.integration_settings &&
					integrationsInfo?.length > 0 &&
					isSuperUser &&
					integrationsInfo?.map(({ key, route, integrationInfo }) => (
						<li
							key={key}
							className={`sidebar-normal ${
								!props.isSidebarOpen &&
								'sidebar-normal-collapsed'
							}`}
						>
							<NavLink
								to={`/integration/${route}`}
								className={(isActive) =>
									isActive.isActive ? 'active' : 'inactive'
								}
								onClick={() => setOpenAvatar(false)}
							>
								<div className='sidebar-mini-icon'>
									{integrationInfo?.uiConfig?.iconSrc ? (
										<SVG
											src={
												integrationInfo?.uiConfig
													?.iconSrc
											}
											className='integration-icon'
										/>
									) : (
										<MdOutlineDomain />
									)}
								</div>
								<div
									className={`sidebar-topic-item ${
										!props.isSidebarOpen && 'hidden'
									}`}
								>
									{integrationInfo?.name || key}
								</div>
								<div
									className={`blend-effect ${
										props.isSidebarOpen &&
										'blend-effect-expanded'
									}`}
								/>
							</NavLink>
						</li>
					))} */}
			</ul>
			{props.isSidebarOpen && (
				<OrgDropdown
					org={props.org}
					updateUserOrg={props.updateUserOrg}
					options={props.orgOptions}
					userRole={props.userRole}
				/>
			)}
		</nav>
	);
};

export { Sidebar };
