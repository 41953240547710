import Chatbot, { createClientMessage } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { useChatbotHighLevelStore } from "@/stores";
import { Loader } from "@SignedIn/Loader/Loader";
import ActionProvider from "@components/layouts/SignedIn/Chatbot/ActionProvider.jsx";
import { MessageParser } from "@components/layouts/SignedIn/Chatbot/MessageParser.jsx";
import getChatbotConfig, {
	CONTAINER,
} from "@components/layouts/SignedIn/Chatbot/config.js";
import { Dropzone } from "@components/layouts/SignedIn/Chatbot/widgets/Dropzone";
import { useChatbotHistory } from "@contexts/chatbotContext.js";
import { usePageStore } from "@stores/pageStore";
import {
	type DO_NOT_USE_OR_YOU_WILL_BE_FIRED_CALLBACK_REF_RETURN_VALUES,
	type RefAttributes,
	type RefObject,
	useEffect,
	useRef,
	useState,
} from "react";
import type { JSX } from "react/jsx-runtime";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const ChatbotMainCard: any = (): any => {
	const isMobile = window.innerWidth < 768;
	const { messageHistory } = useChatbotHistory() as any;

	const {
		formInProgress,
		setFormInProgress,
		setHighLevelAlertModal: setAlertModal,
		closeHighLevelAlertModal: closeAlertModal,
		assetTypeInput,
		submitDisabled,
	} = useChatbotHighLevelStore() as any;
	const { isSidebarOpen } = usePageStore() as any;

	const actionRef = useRef() as any;
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

	const updateMedia = () => {
		setIsDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	const validateInput = (input: string | any[]) => {
		if (formInProgress && !assetTypeInput) {
			setAlertModal({
				show: true,
				title: "Form In Progress",
				body: "Are you sure you want to send this message? Form progress may be lost.\n",
				options: [
					{
						text: "Send message",
						action: () => {
							setFormInProgress(false);
							closeAlertModal();
							if (actionRef.current) {
								actionRef.current.setState((prev: { messages: any }) => ({
									...prev,
									messages: [
										...prev.messages,
										createClientMessage(input.toString(), {}),
									],
								}));
								actionRef.current.handleInputAction(input);
							}
						},
					},
					{
						text: "Return to form",
						action: () => {
							closeAlertModal();
						},
					},
				],
			});
			return false;
		}
		return input.length > 0;
	};

	return (
		<div
			className="chatbot-main-card rounded-md lg-m-0 position-relative"
			style={{ maxHeight: isMobile ? "calc(100vh - 100px)" : "100%" }}
		>
			<Dropzone
				onDrop={(files: any[]) => {
					if (actionRef.current?.handleFileAction) {
						actionRef.current.handleFileAction(files[0]);
					}
				}}
				actionProvider={actionRef}
			/>

			<Chatbot
				config={getChatbotConfig(CONTAINER.CARD)}
				messageParser={MessageParser}
				actionProvider={(
					props: JSX.IntrinsicAttributes &
						Omit<Omit<any, "ref"> & RefAttributes<unknown>, "ref"> & {
							ref?:
								| ((
										instance: unknown,
								  ) =>
										| undefined
										| DO_NOT_USE_OR_YOU_WILL_BE_FIRED_CALLBACK_REF_RETURN_VALUES[keyof DO_NOT_USE_OR_YOU_WILL_BE_FIRED_CALLBACK_REF_RETURN_VALUES])
								| RefObject<unknown>
								| null
								| undefined;
						},
				) => <ActionProvider ref={actionRef} {...props} />}
				validator={validateInput}
				messageHistory={messageHistory}
			/>
			<Modal
				centered
				isOpen={submitDisabled}
				toggle={() => {
					closeAlertModal();
				}}
				style={{
					left: !isDesktop ? "0px" : isSidebarOpen ? "130px" : "40px",
					zIndex: 10000,
				}}
			>
				<ModalHeader
					toggle={() => closeAlertModal()}
					className="d-flex justify-content-center position-relative"
				>
					<strong className="fw-bold mx-2">Please wait...</strong>
				</ModalHeader>
				<ModalBody>
					<Loader />
				</ModalBody>
			</Modal>
		</div>
	);
};

export { ChatbotMainCard };
