import { LocationInput } from "@/views/SignedIn/SingleForm/TaggingFormInputs";
import { useChatbotStore, usePageStore, useUserStore } from "@stores";
import { useEffect, useRef, useState } from "react";
import { IoCamera } from "react-icons/io5";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import styles from "../BotChatMessage.module.css";
import { identifyAsset } from "../utils/identifyAsset";

const OptionalSelectors = ({
	actionProvider,
	state: {
		showOptions,
		stage,
		otherLocation,
		startNew,
		options,
		masterAsset = "",
	},
	payload: { topThreeLocations, question, capturePhoto },
}) => {
	const {
		themeMode,
		user: { useCameraOnPhotoCapture },
	} = useUserStore();

	const { setInputData, setUserLocation, responseData } = useChatbotStore();

	const [isOpen, setIsOpen] = useState(false);
	const [clicked, setClicked] = useState(false);

	const fileInputRef = useRef(null);

	const messageContainerStyleClasses =
		themeMode !== "dark"
			? styles.optionContainer
			: styles.messageContainer_dark;
	const messageTextStyleClasses =
		themeMode !== "dark" ? styles.messageText : styles.messageText_dark;

	const [alertModal, setAlertModal] = useState({
		show: false,
		title: "",
		body: "",
	});

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};
	const handleOption = (e) => {
		if (stage === "multipleForms") {
			actionProvider.handleMultipleFormsAction(e.target.innerText);
		} else if (stage === "loc") {
			if (e.target?.innerText === "Other Location") {
				setIsOpen(true);
			} else {
				if (e.id && e.data) {
					actionProvider.handleLocationSelectionAction(
						null,
						e.data.name ?? e.data.city,
						startNew,
					);
				} else {
					actionProvider.handleLocationSelectionAction(
						topThreeLocations.find(
							(loc) => loc.data.name === e.target.innerText.split(",")[0],
						),
						e.target.innerText,
					);
				}
			}
		} else if (stage === "assetDetection") {
			actionProvider.handleAssetDetectionAction(e.target.innerText);
		} else if (stage === "assetLocation") {
			actionProvider.handleAssetLocationAction(e.target.innerText);
		} else if (stage === "OCRData") {
			actionProvider.handleOCRDataAction(e.target.innerText);
		} else if (stage === "assetCondition") {
			actionProvider.handleAssetConditionAction(e.target.innerText);
		} else if (stage === "submit") {
			actionProvider.handleSubmitAction(e.target.innerText);
		} else if (stage === "submitNew") {
			actionProvider.handleSubmitNewSelectAction(e.target.innerText);
		} else if (stage === "assetIdPrompt") {
			actionProvider.handleAddToMasterListAction(
				e.target.innerText,
				masterAsset,
			);
		} else {
			actionProvider.handleOptionSelectionAction(e.target.innerText);
		}
	};

	const { isSidebarOpen } = usePageStore();

	const isMobile = window.innerWidth < 768;

	const authInfo = useUserStore((state) => state.authInfo);
	const organization = authInfo.org;

	useEffect(() => {
		if (otherLocation) {
			setIsOpen(true);
		}
	}, [otherLocation]);

	return (
		<>
			{showOptions && !clicked && (
				<div className="d-flex flex-column align-items-end">
					{options?.map((option, index) => {
						return (
							<div
								key={index}
								onClick={(e) => {
									option === "Start Data Capture" && setClicked(true);
									if (option.startsWith("Upload")) {
										return;
									}
									handleOption(e);
								}}
								className={`border ${messageContainerStyleClasses}`}
								style={{
									marginBottom: "0.5em",
									cursor: "pointer",
									height: !option.includes("photo") ? "auto" : "34px",
									// borderRadius: '20px',
								}}
							>
								{!option.includes("photo") ? (
									<span className={messageTextStyleClasses}>
										{option}
										{option === "Other Location" ? (
											<LocationInput
												question={question}
												setUserLocation={setUserLocation}
												organization={organization}
												setInputData={setInputData}
												fromChatbot={true}
												isOpen={isOpen}
												setIsOpen={setIsOpen}
												submit={(e) => handleOption(e)}
											/>
										) : null}
									</span>
								) : (
									<>
										<span
											style={{
												position: "relative",
												bottom: "12px",
												left: "-30px",
												borderRadius: "50%",
												width: "25px",
												// height: '25px',
												backgroundColor: "rgb(242, 242, 242)",
												border: "1px solid rgb(37, 2, 69)",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span
												style={{
													position: "relative",
													top: "-1px",
												}}
											>
												<IoCamera />
											</span>
										</span>
										<span
											className={messageTextStyleClasses}
											style={{
												marginLeft: "-15px",
												position: "relative",
												bottom: "28px",
												paddingLeft: "15px",
											}}
										>
											{option}
											<Input
												ref={fileInputRef}
												style={{
													opacity: 0,
													position: "absolute",
													top: 0,
													left: 0,
													width: "100%",
													height: "100%",
												}}
												capture={
													useCameraOnPhotoCapture ? "environment" : undefined
												}
												id={question?.key}
												type="file"
												onChange={async (e) => {
													setClicked(true);
													try {
														if (!question) {
															actionProvider.handleAssetIdPromptAction(
																new Promise((resolve) => {
																	resolve(
																		identifyAsset(
																			e,
																			setAlertModal,
																			closeAlertModal,
																			organization,
																		),
																	);
																}),
															);
														} else if (question.key === "equipmentAssetImage") {
															actionProvider.handleAssetPhotoAction(
																new Promise((resolve) => {
																	resolve(
																		capturePhoto(e, question, responseData),
																	);
																}),
															);
														} else if (
															question.key === "manufacturersPlateAssetImage"
														) {
															actionProvider.handlePlatePhotoAction(
																new Promise((resolve) => {
																	resolve(
																		capturePhoto(e, question, responseData),
																	);
																}),
															);
														} else if (
															question.key === "tempAlertIdAssetImage"
														) {
															actionProvider.handleTempAlertPhotoAction(
																new Promise((resolve) => {
																	resolve(
																		capturePhoto(e, question, responseData),
																	);
																}),
															);
														} else if (question.key === "idTagAssetImage") {
															actionProvider.handleAssetTagPhotoAction(
																new Promise((resolve) => {
																	resolve(
																		capturePhoto(e, question, responseData),
																	);
																}),
															);
														}
													} catch {
														e.target.value = null;
													}
												}}
											/>
										</span>
									</>
								)}
							</div>
						);
					})}
				</div>
			)}
			<Modal
				centered
				isOpen={alertModal.show}
				toggle={closeAlertModal}
				style={{
					left: isMobile ? "0px" : isSidebarOpen ? "130px" : "40px",
				}}
			>
				<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
				<ModalBody>
					{alertModal.body}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "5px",
						}}
					>
						{alertModal.options?.map((option, index) => (
							<Button
								key={index}
								className={
									option.text === "Retake the Picture"
										? "button-green"
										: option.text === "No Manufacturer's Plate"
											? "button-red"
											: ""
								}
								onClick={() => option.action()}
								style={{ margin: "2px" }}
							>
								{option.text}
							</Button>
						))}
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default OptionalSelectors;
