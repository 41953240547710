import { useAdminStore, useLocationStore, useUserStore } from "@stores";
import { useState } from "react";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap";

const OrgDropdown = ({ options, org, updateUserOrg }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const sortedOptions = options.sort((a, b) =>
		a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1,
	);
	const { clearFilters: clearLocationFilters } = useLocationStore();
	const { clearFilters: clearAdminFilters } = useAdminStore();
	const { user } = useUserStore();

	return (
		<Dropdown
			disabled={!options.length}
			isOpen={dropdownOpen}
			toggle={toggle}
			direction="up"
		>
			<DropdownToggle>{user.organization.full_name}</DropdownToggle>
			<DropdownMenu>
				{sortedOptions.map((org) => (
					<DropdownItem
						key={org.id}
						onClick={() => {
							clearAdminFilters();
							clearLocationFilters();
							updateUserOrg(org.external_firebase_id, org.full_name);
						}}
					>
						{org.full_name}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export { OrgDropdown };
