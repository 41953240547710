import { storage } from "@assets/services/auth-service";
import { getDownloadURL, ref } from "@firebase/storage";
import { useUserStore } from "@stores";
import { useEffect, useState } from "react";

const Avatar = (props) => {
	const { alt, className } = props;

	const user = useUserStore((state) => state.user);
	// Image source state
	const [img, setImg] = useState();

	// Updates the img state with an image link from firestore
	const updateImg = async () => {
		const imgPath =
			user?.user_config?.profile_picture_url ||
			"ProfilePics/default-profile-pic.png";
		const imgRef = ref(storage, imgPath);
		const url = await getDownloadURL(imgRef);
		setImg(url);
	};

	// Update img state upon init render
	useEffect(() => {
		updateImg();
	}, [img]);

	return (
		img != null && user && <img src={img} alt={alt} className={className} />
	);
};

export { Avatar };
