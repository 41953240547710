export const getLifespanChartData = async (
	organization: any,
	assetType: any,
	assetId: any,
) => {
	const url =
		// 'http://localhost:5001/property-echo-4d747/us-central1/getLifespanChartData';
		"https://us-central1-property-echo-4d747.cloudfunctions.net/getLifespanChartData";

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ organization, assetType, assetId }),
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
	}
};

/**
 *
 * @param locationId
 * @param organization_id firebase id of organization
 * @returns {Promise<any>}
 */
export const getHoverChartResponse = async (
	locationId: any,
	organization_id: any,
) => {
	const url =
		// 'http://localhost:5001/property-echo-4d747/us-central1/getHoverChartData';
		"https://us-central1-property-echo-4d747.cloudfunctions.net/getHoverChartData";
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			locationId: locationId,
			org: organization_id,
		}),
	});
	const chartData = await response.json();
	return chartData;
};
