import { createChatBotMessage } from "react-chatbot-kit";
import type { JSX } from "react/jsx-runtime";
import { Avatar } from "../Avatar/Avatar";
import BotChatMessage from "./BotChatMessage";
import FacilityAI_Logo_Mini from "./FacilityAI_Logo_Mini.svg";
import Header from "./Header";
import CustomImageMessage from "./widgets/CustomImageMessage";
import ExtractedInfo from "./widgets/ExtractedInfo";
import InitialMessageOptions from "./widgets/InitialMessageOptions";
import OptionalSelectors from "./widgets/OptionalSelectors";

export const CONTAINER = {
	CARD: "card",
	WIDGET: "widget",
};

const getChatbotConfig = (container: string): any => {
	if (container === CONTAINER.CARD) {
		return {
			initialMessages: [
				createChatBotMessage(
					"Hi, I'm FacilityAI, your personalized Facility Manager Assistant!\n\nAsk me anything related to your facilities and assets or choose an option below!",
					{ widget: "InitialMessageOptions" },
				),
				createChatBotMessage("Drop a file here!", {
					widget: "FileDropzone",
				}),
			],
			botName: "FacilityAI",
			customStyles: {
				botMessageBox: {
					backgroundColor: "#f2f2f2",
				},
				userMessageBox: {
					backgroundColor: "#f2f2f2",
				},
				chatButton: {
					backgroundColor: "#bf1e2d",
				},
			},
			customComponents: {
				userAvatar: (props: any) =>
					window.innerWidth > 768 && (
						<div
							style={{
								width: "50px",
								height: "50px",
								borderRadius: "50%",
								overflow: "hidden",
								marginLeft: "12px",
								marginTop: "12px",
							}}
						>
							<Avatar {...props} alt="Avatar" className="avatar" />
						</div>
					),
				header: () => <Header />,
				botChatMessage: (
					props: JSX.IntrinsicAttributes & {
						message: any;
						container: any;
					},
				) => <BotChatMessage {...props} container={container} />,
				botAvatar: () => (
					<div
						className="chatbot-avatar"
						style={{
							width: "40px",
							height: "40px",
							borderRadius: "50%",
							backgroundColor: "white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							src={FacilityAI_Logo_Mini}
							alt="FacilityAI Logo"
							style={{
								width: "27px",
								height: "27px",
								color: "white",
								transform: "translatey(-2px)",
							}}
						/>
					</div>
				),
			},
			state: {
				showInitialOptions: true,
				showOptions: false,
				options: [],
				stage: "",
				otherLocation: false,
			},
			widgets: [
				{
					// defines the name you will use to reference to this widget in "createChatBotMessage".
					widgetName: "InitialMessageOptions",
					// Function that will be called internally to resolve the widget
					widgetFunc: (
						props: JSX.IntrinsicAttributes & {
							actionProvider: any;
							state: { showInitialOptions: any; assetTypes: any };
						},
					) => <InitialMessageOptions {...props} />,
					// Any props you want the widget to receive on render
					props: {},
					// Any piece of state defined in the state object that you want to pass down to this widget
					mapStateToProps: ["showInitialOptions"],
				},
				{
					widgetName: "OptionalSelectors",
					widgetFunc: (
						props: JSX.IntrinsicAttributes & {
							actionProvider: any;
							state: {
								showOptions: any;
								stage: any;
								otherLocation: any;
								startNew: any;
								options: any;
								masterAsset?: string | undefined;
							};
							payload: {
								topThreeLocations: any;
								question: any;
								capturePhoto: any;
							};
						},
					) => <OptionalSelectors {...props} />,
					props: {},
					mapStateToProps: ["showOptions", "options", "stage", "otherLocation"],
				},
				{
					widgetName: "CustomImageMessage",
					widgetFunc: (
						props: JSX.IntrinsicAttributes & {
							payload: {
								question: any;
								capturePhoto: any;
								imageUrl: any;
							};
						},
					) => <CustomImageMessage {...props} />,
					props: {},
					mapStateToProps: ["imageUrl"],
				},
				{
					widgetName: "ExtractedInfo",
					widgetFunc: (props: JSX.IntrinsicAttributes & object) => (
						<ExtractedInfo {...props} />
					),
					props: {},
					mapStateToProps: [],
				},
			],
		};
	}

	return {
		initialMessages: [
			createChatBotMessage(
				"Hi, I'm FacilityAI, your personalized Facility Manager Assistant!\n\nAsk me anything related to your facilities and assets or choose an option below!",
				{ widget: "InitialMessageOptions" },
			),
			createChatBotMessage("Drop a file here!", {
				widget: "FileDropzone",
			}),
		],
		botName: "FacilityAI",
		customStyles: {
			botMessageBox: {
				backgroundColor: "#e9ecef",
			},
			userMessageBox: {
				backgroundColor: "#bf1e2d",
			},
			chatButton: {
				backgroundColor: "#bf1e2d",
			},
		},
		customComponents: {
			userAvatar: (props: any) => (
				<div
					style={{
						width: "40px",
						height: "40px",
						borderRadius: "50%",
						overflow: "hidden",
						marginLeft: "20px",
					}}
				>
					<Avatar {...props} alt="Avatar" className="avatar" />
				</div>
			),

			header: () => (
				<div
					style={{
						backgroundColor: "#bf1e2d",
						padding: "6px 8px",
						color: "white",
						textAlign: "center",
						borderRadius: "10px 10px 0 0",
						overflow: "hidden",
						fontSize: "20px",
						fontWeight: "bold",
					}}
				>
					FacilityAI
				</div>
			),
			botChatMessage: (
				props: JSX.IntrinsicAttributes & {
					message: any;
					container: any;
				},
			) => <BotChatMessage {...props} container={container} />,
			botAvatar: () => (
				<div
					className="chatbot-avatar"
					style={{
						width: "40px",
						height: "40px",
						borderRadius: "50%",
						backgroundColor: "grey",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						src={FacilityAI_Logo_Mini}
						alt="FacilityAI Logo"
						style={{
							width: "27px",
							height: "27px",
							color: "white",
							transform: "translatey(-2px)",
						}}
					/>
				</div>
			),
		},
	};
};

export default getChatbotConfig;
