import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { deepCopy } from "@/utils/deepCopy";
import { CurrentPage } from "@/views/SignedIn/SingleForm/CurrentPage";
import { default as initInputs } from "@constants/formInputs/facilityVisionFormInputs";
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
	Button,
	Card,
	CardHeader,
	Form,
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";

/**
 * This manages the Facility Vision Form
 * @param formData - Information fetched from firebase
 * @returns {JSX.Element}
 * @constructor
 */
const FacilityVisionForm = ({ formData }) => {
	const [isLoading, _setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [inputs] = useState(deepCopy(initInputs));
	const [_userLocation, setUserLocation] = useState(
		location.state?.userLocation || {},
	);
	const [responseData, setResponseData] = useState({
		beginDate: new Date().toISOString(),
		photoArray: [],
		photoLimit: 7,
		photoIndex: 0,
		photoPreview: null,
		isSubmitted: false,
	});
	const [alertModal, setAlertModal] = useState({
		show: false,
		title: "",
		body: "",
	});

	/**
	 * Sets the 'inputData' state to the defined key value pair
	 * @param item
	 * @param value
	 */
	const setInputData = (item, value) => {
		const response = responseData;
		response[item.key] = value;
		setResponseData((prevState) => {
			const newState = {
				...prevState,
				[item.key]: value,
			};
			// if checkbox is checked, delete key associated with checkbox within responseData
			if (item.type === "checkbox" && value) {
				const altTiedToVals = [];
				if (typeof item.altTiedTo === "string") {
					altTiedToVals.push(item.altTiedTo);
				} else if (Array.isArray(item.altTiedTo)) {
					altTiedToVals.push(...item.altTiedTo);
				}
				for (const altTiedTo of altTiedToVals) {
					if (!newState[altTiedTo] || altTiedTo.toLowerCase().includes("image"))
						continue;
					delete newState[altTiedTo];
				}
			}
			return newState;
		});
	};

	/**
	 * Navigates to the next page in the form
	 */
	const nextPage = () => {
		if (currentPage === 0 && !responseData.location) {
			setAlertModal({
				show: true,
				title: "Set Location",
				body: "Please select a location for this form.",
			});
			setTimeout(closeAlertModal, 2500);
		} else if (currentPage < inputs.length) {
			setCurrentPage(currentPage + 1);
		}
	};

	/**
	 * Closes the alert modal
	 */
	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Card className="px-4 some-drop-shadow form-card d-flex flex-column justify-content-between">
				<span className="logo-container d-flex flex-column">
					<img
						src={formData.logo}
						className="form-logo mt-3"
						hidden={!formData.logo}
						alt="logo"
					/>
					<CardHeader
						style={{
							backgroundColor: "#ffffff",
							border: "none",
							textAlign: "center",
							marginBottom: "24px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<span className="form-title">{formData.formName}</span>
						<ProgressBar
							animated
							label={`${currentPage + 1} of ${inputs.length}`}
							variant="greenHealth"
							now={Math.ceil(((currentPage + 1) * 100) / inputs.length)}
							style={{
								marginTop: "12px",
								height: "32px",
								maxWidth: "500px",
								width: "100%",
							}}
						/>
					</CardHeader>
				</span>
				<Form className="" onSubmit={(e) => e.preventDefault()}>
					<CurrentPage
						inputs={inputs}
						currentPage={currentPage}
						submit={null}
						setUserLocation={setUserLocation}
						setInputData={setInputData}
						responseData={responseData}
						setResponseData={setResponseData}
						capturePhoto={null}
						getOptions={null}
						getOptionsLength={null}
						checkDuplicateScan={null}
						hideSubmitButtons={true}
					/>
				</Form>
				<div className="form-page-navigation text-center my-4">
					<Button
						className="m-2 px-4"
						onClick={() => {
							if (currentPage > 0) {
								setCurrentPage(currentPage - 1);
							}
						}}
						disabled={currentPage < 1 || responseData.isSubmitted}
					>
						<FaArrowLeft className="fs-2" />
					</Button>
					<Button
						className="m-2 px-4"
						onClick={nextPage}
						disabled={currentPage >= inputs.length - 1}
					>
						<FaArrowRight className="fs-2" />
					</Button>
				</div>
			</Card>
			<Modal centered isOpen={alertModal.show} toggle={closeAlertModal}>
				<ModalHeader toggle={closeAlertModal}>{alertModal.title}</ModalHeader>
				<ModalBody>{alertModal.body}</ModalBody>
			</Modal>
		</div>
	);
};

export { FacilityVisionForm };
