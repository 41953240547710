//cvs
export const validateAndSetPage3 = (
	organization,
	responseData,
	setIsPage3Valid,
) => {
	const isValid =
		organization === "SSPAMERICA" || organization === "EcoTrak"
			? !!responseData.equipmentAssetImage //SSPAMERICA
			: !!responseData.manufacturersPlateAssetImage; //cvs, applegreen, campingworld, carliecs, foodlion, raisingcanes, razzoos, DIGI, swarovski
	setIsPage3Valid(isValid);
};
