/**
 * Removes a confidence value for a specified item
 *
 * @param {Object} item - The item which we want to set the confidence for
 */
export const clearScanConfidence = (
	item,
	ocrConfidenceAndColor,
	setOcrConfidenceAndColor,
) => {
	if (Object.keys(ocrConfidenceAndColor).length > 0) {
		const confObject = ocrConfidenceAndColor;
		delete ocrConfidenceAndColor[item.scanTag];
		setOcrConfidenceAndColor(confObject);
	}
};
