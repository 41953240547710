import { ReAuthenticateModal } from "@SignedIn/ReAuthenticateModal/ReAuthenticateModal";
import { auth } from "@assets/services/auth-service";
import { updatePassword } from "firebase/auth";
import { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const PasswordModal = ({ setIsToastShowing }) => {
	const [passwordModal, setPasswordModal] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordShown, setNewPasswordShown] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

	const [authModalShowing, setAuthModalShowing] = useState(false);
	const toggleAuthModal = () => setAuthModalShowing(!authModalShowing);

	const [isErrorShowing, setIsErrorShowing] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const handleChange = (e, setter) => {
		const newValue = e.target.value;
		setter(e.target.value);
		if (newPassword.length && newValue.length) checkError(newValue);
	};

	const togglePasswordModal = () => setPasswordModal(!passwordModal);

	const changePassword = async () => {
		if (newPassword !== confirmPassword) {
			alert("Passwords don't match.");
			setNewPassword("");
			setConfirmPassword("");
			return;
		}

		await updatePassword(auth.currentUser, newPassword)
			.then(() => {
				setPasswordModal(false);
				setIsToastShowing({
					showing: true,
					title: "Success",
					subtitle: "Password successfully updated.",
					position: "align-profile",
					type: "success",
				});
				setTimeout(() => {
					setIsToastShowing({
						showing: false,
					});
				}, 2500);
			})
			.catch(() => {
				alert("An Error Occurred. Please try again.");
			})
			.finally(() => {
				setNewPassword("");
				setConfirmPassword("");
			});
	};

	const checkError = (newValue) => {
		if (newPassword !== newValue) {
			setIsErrorShowing(true);
			setErrorMsg("Passwords don't match.");
		} else if (newPassword === newValue) {
			setIsErrorShowing(false);
		}
	};

	return (
		<div>
			<span onClick={toggleAuthModal} className="profile-modal-toggle mx-2">
				Change Password
			</span>
			<ReAuthenticateModal
				showing={authModalShowing}
				toggleNextModal={togglePasswordModal}
				toggle={toggleAuthModal}
			/>
			<Modal
				id="password-modal"
				centered="true"
				isOpen={passwordModal}
				toggle={togglePasswordModal}
			>
				<ModalHeader toggle={togglePasswordModal}>Change password</ModalHeader>
				<ModalBody>
					<div className="d-flex flex-column mb-4">
						<h6 className="profile-category">New Password</h6>
						<div className="position-relative">
							<input
								onChange={(e) => handleChange(e, setNewPassword)}
								value={newPassword}
								type={`${newPasswordShown ? "text" : "password"}`}
								placeholder="Enter new password"
							/>
							<button
								onClick={() => setNewPasswordShown(!newPasswordShown)}
								className="eye-icon"
							>
								{newPasswordShown ? <IoEyeOutline /> : <IoEyeOffOutline />}
							</button>
						</div>
					</div>
					<div className="d-flex flex-column mb-4">
						<h6 className="profile-category">Confirm Password</h6>
						<div className="position-relative">
							<input
								onChange={(e) => {
									handleChange(e, setConfirmPassword);
								}}
								value={confirmPassword}
								type={`${confirmPasswordShown ? "text" : "password"}`}
								placeholder="Confirm password"
							/>
							<button
								onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
								className="eye-icon"
							>
								{confirmPasswordShown ? <IoEyeOutline /> : <IoEyeOffOutline />}
							</button>
						</div>
						{isErrorShowing && <span className="error-text">{errorMsg}</span>}
					</div>
					<Button
						disabled={`${
							!newPassword.length && !confirmPassword.length ? "disabled" : ""
						}`}
						className="bg-green border-none"
						onClick={changePassword}
					>
						Change Password
					</Button>
				</ModalBody>
			</Modal>
		</div>
	);
};

export { PasswordModal };
