import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import { App } from "./App";
import defaultAntDTheme from "./assets/theme/antd.default.theme.json";
import { ENV } from "./constants/env";
import { initSentry } from "./sentry";
const antdThemeDefault = defaultAntDTheme;

// Initialize the React Query Client
const queryClient = new QueryClient();

// Retrieve the root DOM element
const rootElement = document.getElementById("root");

// Ensure the root element exists
if (!rootElement) {
	throw new Error(
		'Failed to find the root element. Please ensure there is an element with id "root" in your index.html.',
	);
}

// Create a React root
const root = ReactDOM.createRoot(rootElement);

// Determine if the current environment is development
const isDevelopment = ENV !== "production";
console.log("Is Development:", isDevelopment, "Environment:", ENV);

try {
	initSentry();
} catch (error) {
	console.error(error);
}

// Render the React application
root.render(
	isDevelopment ? (
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider theme={{ ...antdThemeDefault }}>
					<App />
				</ConfigProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</React.StrictMode>
	) : (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider theme={{ ...antdThemeDefault }}>
				<App />
			</ConfigProvider>
		</QueryClientProvider>
	),
);
