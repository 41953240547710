import type { ClipboardEvent, KeyboardEvent } from "react";

// only allows alphanumeric characters
export const preventSpecialChars = (
	val: string,
	e: ClipboardEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
	disableSpaces = false,
) => {
	let regEx = /^[a-zA-Z0-9\s]+$/; // Alphanumeric characters, including spaces
	if (disableSpaces) {
		regEx = /^[a-zA-Z0-9]+$/; // Alphanumeric characters, excluding spaces
	}
	if (!regEx.test(val)) {
		e.preventDefault();
	}
};
