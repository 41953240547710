import type { Dispatch } from "react";
import type { Action, State } from "../SubmissionsTable";
import { searchResponses } from "../children/Search";

export async function fetchData(
	state: State,
	dispatch: Dispatch<Action>,
	browserLocationId: string,
) {
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		const {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			filters,
			viewingDeleted,
			viewingDrafts,
		} = state;

		const tableParams = {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			browserLocationId,
			filters,
			viewingDeleted,
			viewingDrafts,
		};
		const responses = await searchResponses(tableParams);
		dispatch({
			type: "SET_DATA_SOURCE",
			payload: responses?.results || [],
		});
		dispatch({ type: "SET_COUNT", payload: responses.count });
	} catch (exception) {
		console.error(`Failed to search for submissions: ${exception}`);
		throw new Error("Failed to search for submissions");
	} finally {
		dispatch({ type: "SET_IS_LOADING", payload: false });
	}
}
