import { deepCopy } from "@utils/deepCopy";

// Updates inputs and responseData when inputsRequired becomes false
// Else, update inputs only
export const updateInputsRequiredChange = (
	inputsRequired,
	notRequiredInputs,
	fromChatbot,
	responseData,
	setResponseData,
	setCurrentPage,
	setInputs,
	initInputs,
	assetConditionalInputs,
	orgSpecificInputs,
	additionalLocationInputs,
	savedLocation,
	flags,
	isQA,
	organization,
) => {
	if (!inputsRequired) {
		const inputsToDelete = [];
		const flattenedQuestions = notRequiredInputs.flatMap(
			(page) => page.questions,
		);
		for (const question of Object.keys(responseData)) {
			// Check if the question is not in the notRequiredInputs
			if (!flattenedQuestions.some((q) => q.key === question)) {
				inputsToDelete.push(question);
			}
		}

		if (fromChatbot) {
			const newState = { ...responseData };
			for (const key of inputsToDelete) {
				delete newState[key];
			}
			setResponseData(newState);
		} else {
			setResponseData((prevState) => {
				const newState = { ...prevState };
				for (const key of inputsToDelete) {
					delete newState[key];
				}
				return newState;
			});
		}
		setCurrentPage(1);
		const newInputs = deepCopy(notRequiredInputs);

		if (responseData.assetType && responseData.assetType === "BAY COUNT") {
			const bayLocationInput = [
				{
					type: "select",
					label: "Bay/Fall Arrest/Awning/Scaffolding/Lifts Location",
					key: "bayLocation",
					required: "true",
					collection: "bay-location-options",
					tiedTo: "bayLocationNotAvailable",
				},
				{
					type: "checkbox",
					label: "Not Found",
					key: "bayLocationNotAvailable",
					altRequired: "true",
					tiedTo: "bayLocation",
				},
			];

			const bayQuantityInput = {
				type: "number",
				label: "Number of Bays",
				required: "true",
				key: "bayQuantity",
			};

			const assetDetailsPage = newInputs.find(
				(page) => page.title === "Asset Details",
			);
			const assetLocationIndex = assetDetailsPage.questions.findIndex(
				(question) => question.key === "assetLocation",
			);

			assetDetailsPage.questions.splice(
				assetLocationIndex + 1,
				0,
				bayQuantityInput,
				...bayLocationInput,
			);
		}

		setInputs(newInputs);
	} else {
		let newInputs = deepCopy(initInputs);
		const isCond = assetConditionalInputs.length > 0;
		const isOrg = orgSpecificInputs.length > 0;
		if (isCond || isOrg) {
			for (let i = 0; i < newInputs.length; i++) {
				if (newInputs[i].title === "Asset Details") {
					const newPage = {
						title: "Asset Specific Details",
						questions: [],
					};
					if (isCond) newPage.questions.push(...assetConditionalInputs);
					if (isOrg) newPage.questions.push(...orgSpecificInputs);
					newInputs.splice(i + 1, 0, newPage);
					break;
				}
			}
		}

		const hasAdditionalLocationInputs =
			additionalLocationInputs && additionalLocationInputs.length > 0;
		if (hasAdditionalLocationInputs) {
			const newPage = {
				title:
					organization === "SSPAMERICA"
						? "Location Details"
						: "Restaurant Details",
				questions: additionalLocationInputs,
			};
			newInputs.splice(1, 0, newPage);
		}
		if (
			savedLocation?.id &&
			flags?.allowAssetMapping &&
			responseData?.assetLocation === "PHARMACY" &&
			!isQA
		) {
			const assetMapPage = {
				title: "Place this asset on the map",
				questions: [
					{
						type: "assetMap",
					},
				],
			};
			newInputs.splice(-1, 0, assetMapPage);
		}

		// Remove any flagged pages that aren't allowed in the org
		newInputs = newInputs.filter(
			(page) => page.flag === undefined || flags.config?.[page.flag],
		);

		setInputs(newInputs);
	}
};
