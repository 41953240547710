import { useChatbotStore, useFormStore } from "@stores";
import { BsImages } from "react-icons/bs";

export const PreviewField = (props) => {
	const { question, responseData, fromChatbot } = props;

	const { setIsPhotoShowing, setSelectedPhotoUrl, setSelectedItem } =
		useFormStore();

	const {
		setIsPhotoShowing: setChatbotIsPhotoShowing,
		setSelectedPhotoUrl: setChatbotSelectedPhotoUrl,
		setSelectedItem: setChatbotSelectedItem,
	} = useChatbotStore();

	const handleClick = () => {
		if (!responseData[question.previewTag]) return;
		if (fromChatbot) {
			setChatbotSelectedPhotoUrl(responseData[question.previewTag]);
			setChatbotSelectedItem(question);
			setChatbotIsPhotoShowing(true);
		} else {
			setSelectedPhotoUrl(responseData[question.previewTag]);
			setSelectedItem(question);
			setIsPhotoShowing(true);
		}
	};

	return question.previewTag && responseData[question.previewTag] ? (
		<BsImages className="fs-3" onClick={handleClick} />
	) : null;
};
