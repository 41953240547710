import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { useChatbotHighLevelStore, useFormStore } from "@stores";
import { Button, Row } from "reactstrap";
import { Questions } from "./Questions";

export const CurrentPage = ({
	inputs,
	currentPage,
	submit,
	setUserLocation,
	setInputData,
	responseData,
	setResponseData,
	generativeAiInputs,
	setGenerativeAiInputs,
	capturePhoto,
	getOptions,
	getOptionsLength,
	formId,
	hideSubmitButtons,
	setHeader,
	fromChatbot = false,
	submitProcessing = false,
}) => {
	const page = inputs[currentPage];
	const {
		submitDisabled: submitDisabledForm,
		isEditing,
		isQA,
		geoLoading,
	} = useFormStore();
	const { submitDisabled: submitDisabledChatbot } = useChatbotHighLevelStore();
	const submitDisabled = fromChatbot
		? submitDisabledChatbot
		: submitDisabledForm;
	setHeader?.(page?.title);

	return (
		!submitProcessing && (
			<div className="form-page">
				{!setHeader && (
					<>
						<div style={{ marginBottom: "10px" }}>
							{geoLoading && page?.title === "Physical Location" && (
								<div className="loader-container" style={{ marginTop: "15px" }}>
									<Loader small={true} />
								</div>
							)}
						</div>
						<div className="form-page-title fs-3 text-center mb-3">
							<strong>{page?.title}</strong>
						</div>
					</>
				)}
				<div className="form-page-questions">
					<Questions
						page={page}
						setUserLocation={setUserLocation}
						setInputData={setInputData}
						generativeAiInputs={generativeAiInputs}
						setGenerativeAiInputs={setGenerativeAiInputs}
						responseData={responseData}
						setResponseData={setResponseData}
						capturePhoto={capturePhoto}
						getOptions={getOptions}
						getOptionsLength={getOptionsLength}
						formId={formId}
						fromChatbot={fromChatbot}
					/>
					{currentPage + 1 === inputs.length && !hideSubmitButtons ? (
						<div className="form-btns-submit">
							<Row>
								<Button
									color="secondary"
									className="submit-btn-form"
									onClick={() => submit(false)}
									disabled={submitDisabled}
								>
									{!submitDisabled && isEditing
										? "Save Changes"
										: !submitDisabled && isQA
											? "Approve & Submit"
											: !submitDisabled
												? "Submit"
												: "Submitting..."}
								</Button>
							</Row>
							<Row>
								<Button
									color="secondary"
									className="submit-btn-form"
									onClick={() => submit(true)}
									hidden={isEditing || isQA}
									disabled={submitDisabled}
								>
									{!submitDisabled ? "Submit & Enter New" : "Submitting..."}
								</Button>
							</Row>
						</div>
					) : null}
				</div>
			</div>
		)
	);
};
