import { memo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";
import { GeneratedPropIcon } from "./GeneratedPropIcon";

export const AssetProfileCard = memo(
	({ propKey, value, icon, title, onClickCopy, generatedProps }) => {
		const [isHovered, setIsHovered] = useState(false);

		return (
			<div
				key={propKey}
				className="asset-data-card"
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<div className="asset-data-icon">{icon}</div>
				<div className="asset-data-title">
					{title}
					{generatedProps.includes(propKey) && <GeneratedPropIcon />}
				</div>
				<div className="asset-data-value">
					{isHovered && (
						<CopyToClipboard text={value || "N/A"}>
							<MdOutlineContentCopy
								className="copy-icon"
								onClick={onClickCopy}
							/>
						</CopyToClipboard>
					)}
					<span className="asset-profile-card-value">{value}</span>
				</div>
			</div>
		);
	},
);

AssetProfileCard.displayName = "AssetProfileCard";
