import "./upload_image.css";
import { Input } from "reactstrap";

const UploadImage = ({ responseData, setResponseData }) => {
	const setImage = (base64Data) => {
		setResponseData({ ...responseData, image: base64Data, rotation: 0 });
	};

	const readerOnloadFunction = (e) => {
		var fileData = e.target.result;
		if (/^data:image/.test(fileData)) {
			setImage(fileData);
		}
	};

	const fileSelect = (e) => {
		var files = e.target.files || e.dataTransfer.files;
		var file = files[0];
		var reader = new FileReader();
		reader.onload = readerOnloadFunction;
		if (file.type.startsWith("image/")) {
			reader.readAsDataURL(file);
		}
	};

	return (
		<div className="step" id="step-upload-image">
			<main id="content">
				<div className="panel">
					<h2 className="panel-head">click or drag file</h2>
					<div className="panel-content">
						{/* <div
											className={`form-image-button-overlay ${overlayClass}`}
										/> */}
						<Input
							// id='image'
							type="file"
							data-droppable-input=""
							onChange={fileSelect}
							accept="image/*"
						/>
						{responseData?.image && (
							<img
								className="step-rotate-canvas"
								src={responseData.image}
								alt=""
							/>
						)}
					</div>
				</div>
			</main>
		</div>
	);
};

export { UploadImage };

//<div className="bg-image aspect-square" style={{background: `url("${responseData.image}")`, backgroundSize: 'cover', backgroundRepeat: "no-repeat"}}></div>
