import { AssetMap } from "@components/layouts/SignedIn/AssetMap/AssetMap";
import { downloadAssetMapPDF } from "@services/asset-map-service";
import { useState } from "react";
import { useRef } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

const AssetMapTab = ({ locationId, organization, isFocused, address }) => {
	const toggleResetModal = () => setShowResetModal(!showResetModal);
	const assetMapRef = useRef(null);
	const [pdfLoading, setPdfLoading] = useState(false);
	const [showResetModal, setShowResetModal] = useState(false);

	return (
		<Card className="asset-map-card mx-auto">
			<CardHeader className="w-100 p-0 mb-3 border-none d-flex justify-content-end">
				<button className="asset-map-btn" onClick={toggleResetModal}>
					Reset
				</button>
				<button
					className="asset-map-btn"
					onClick={() =>
						downloadAssetMapPDF(assetMapRef, setPdfLoading, address)
					}
					disabled={pdfLoading}
				>
					PDF <i className="bi bi-cloud-download mx-2" />
				</button>
			</CardHeader>
			<CardBody className="w-100 p-0">
				<AssetMap
					locationId={locationId}
					org={organization}
					info={location.state}
					isFocused={isFocused}
					showResetModal={showResetModal}
					toggleResetModal={toggleResetModal}
					assetMapRef={assetMapRef}
				/>
			</CardBody>
		</Card>
	);
};

export { AssetMapTab };
