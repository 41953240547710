export const validateAndSetPage7 = (
	assetTagError,
	organization,
	responseData,
	setIsPage7Valid,
) => {
	const hasAssetTagError = assetTagError?.error;

	const isValid =
		organization === "cvs.com"
			? !!responseData.idTagAssetImage &&
				!!responseData.assetTag &&
				!hasAssetTagError
			: organization === "EcoTrak"
				? responseData.warrantyNotFound ||
					(responseData.warrantyExpirationDate &&
						responseData.warrantyPeriod &&
						responseData.warrantyProvider &&
						responseData.warrantyStartDate) ||
					(!!responseData.idTagAssetImage &&
						!!responseData.assetTag &&
						!hasAssetTagError)
				: //cvs
					!!responseData.assetCondition; //applegreen, campingworld, carliecs, raisingcanes, DIGI, SSPAMERICA, swarovski
	setIsPage7Valid(isValid);
};
