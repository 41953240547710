import domtoimage from "dom-to-image";
import type { MutableRefObject } from "react";

export const screenshot = async (
	element: MutableRefObject<null>,
	cameraIconRef: MutableRefObject<null>,
) => {
	if (element.current && cameraIconRef.current) {
		const options = {
			filter: (node: any) => node !== cameraIconRef.current,
		};

		try {
			await domtoimage.toBlob(element.current, options).then((blob: any) => {
				const data = [new ClipboardItem({ "image/png": blob })];
				navigator.clipboard.write(data);
			});
		} catch (error) {
			console.log(error);
		}
	}
};
