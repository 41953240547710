import {
	useGetAssetFromParentRef,
	useGetLocationAssets,
} from "@hooks/assetQueries";
import { useGetSelfOrganizationGraphs } from "@hooks/organizationQueries";
import { useUserStore } from "@stores/userStore";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const GraphContext = createContext(null);

function GraphProvider({ children }: any) {
	const { pathname } = useLocation();
	const [dashboardGraphs, setDashboardGraphs] = useState<any>([]);
	const [locationGraphs, setLocationGraphs] = useState<any>([]);
	const [locGraphsLoading, setLocGraphsLoading] = useState<any>(true);
	const [shouldFlashId, setShouldFlashId] = useState<any>(null);

	const user = useUserStore((state: any) => state.user);

	const {
		userOrganizationGraphs,
		error: userOrganizationGraphsError,
		isLoading: isLoadingUserOrganizationGraphs,
	} = useGetSelfOrganizationGraphs();

	const { data: locationAssets } = useGetLocationAssets(
		user?.organization.external_firebase_id,
		pathname?.split("/")[2] ?? "",
	) as any;

	const assetRefs = useGetAssetFromParentRef(locationAssets);
	const allAssetRefQueriesFinished = assetRefs.every(
		(query) => !query.isFetching,
	);

	useEffect(() => {
		setLocationGraphs([]);
	}, [pathname]);

	const addHealthInfo = (
		healthGraphData: number[],
		healthScoreGraphData: number[],
		locAsset: any,
	) => {
		if (locAsset.other?.assetCondition ?? locAsset.other?.condition) {
			switch (locAsset.other?.assetCondition ?? locAsset.other?.condition) {
				case "Broken":
					healthGraphData[0] += 1;
					return;
				case "Poor":
					healthGraphData[1] += 1;
					return;
				case "Average":
					healthGraphData[2] += 1;
					return;
				case "Good":
					healthGraphData[3] += 1;
					return;
				case "Excellent":
					healthGraphData[4] += 1;
					return;
			}
		}
		const healthScore = Math.round(locAsset.healthscore);
		healthScoreGraphData[healthScore] += 1;
	};

	useEffect(() => {
		if (!locationAssets || !allAssetRefQueriesFinished) return;
		const locGraphs = [];
		const healthGraphData = [0, 0, 0, 0, 0];
		const healthScoreGraphData: any = {};
		for (let i = 0; i <= 100; i++) {
			healthScoreGraphData[i] = 0;
		}
		const dateGraph: any = {};
		const makeGraph: any = {};
		for (let locAsset of locationAssets.docs) {
			locAsset = locAsset.data();
			// const healthScore = locAsset.healthscore;
			const make = locAsset.make;
			const date = new Date(locAsset.other.submittedDate);

			const month = date.toLocaleString("default", { month: "short" });
			const year = date.getFullYear();

			const formattedDate = `${month} ${year}`;

			addHealthInfo(healthGraphData, healthScoreGraphData, locAsset);

			const assetRef = assetRefs.find(
				(asset) => asset.data?.path === locAsset.parentAssetRef?.id,
			);
			const replCost = assetRef
				? (assetRef.data?.data?.avgReplCostUSD ?? 0)
				: 0;
			if (dateGraph[formattedDate] && replCost > 0) {
				dateGraph[formattedDate] += replCost;
			} else if (replCost > 0) {
				dateGraph[formattedDate] = replCost;
			}

			if (makeGraph[make]) {
				makeGraph[make] += 1;
			} else if (make !== null && make !== "(other)" && make !== "Na") {
				makeGraph[make] = 1;
			}
		}

		// Health Graph
		const numAssetsWAssetCondition = healthGraphData.reduce(
			(acc, curr) => acc + curr,
			0,
		);
		if (numAssetsWAssetCondition === locationAssets.docs.length) {
			const healthGraph = {
				labels: ["Broken", "Poor", "Average", "Good", "Excellent"],
				title: "Asset Count by Condition",
				data: healthGraphData,
				type: "line",
			};
			locGraphs.push({ ...healthGraph, initial: true, index: 1 });
		} else {
			const healthArray = Object.entries(healthScoreGraphData);
			const healthLabels = healthArray.map((item) => item[0]);
			const healthData = healthArray.map((item) => item[1]);
			locGraphs.push({
				initial: true,
				index: 3,
				labels: healthLabels,
				title: "Asset Count by Health Score",
				data: healthData,
				type: "line",
			});
		}

		// Date Graph
		const dateArray = Object.entries(dateGraph);
		dateArray.sort((a: any, b: any) => b[1] - a[1]);
		const top7Dates = dateArray.slice(0, 7);
		const dates = top7Dates.map((item) => item[0]);
		const cost = top7Dates.map((item) => item[1]);
		if (cost.length < 5) {
			const monthsToAddFront = Math.ceil((5 - cost.length) / 2);
			const monthsToAddBack = Math.floor((5 - cost.length) / 2);
			for (let i = 0; i < monthsToAddFront; i++) {
				const firstDate = new Date(dates[0]);
				firstDate.setMonth(firstDate.getMonth() - 1);
				const formattedDate = `${firstDate.toLocaleString("default", {
					month: "short",
				})} ${firstDate.getFullYear()}`;
				dates.unshift(formattedDate);
				cost.unshift(0);
			}

			for (let i = 0; i < monthsToAddBack; i++) {
				const lastDate = new Date(dates[dates.length - 1]);
				lastDate.setMonth(lastDate.getMonth() + 1);
				const formattedDate = `${lastDate.toLocaleString("default", {
					month: "short",
				})} ${lastDate.getFullYear()}`;
				dates.push(formattedDate);
				cost.push(0);
			}
		}

		locGraphs.push({
			initial: true,
			index: 2,
			labels: dates,
			title: "Assets Added by Cost",
			data: cost,
			type: "line",
			cost: true,
		});
		setLocationGraphs(locGraphs);

		// Make Graph
		const makeArray = Object.entries(makeGraph);
		makeArray.sort((a: any, b: any) => b[1] - a[1]);
		const top7Makes = makeArray.slice(0, 7);
		const makes = top7Makes.map((item) => item[0]);
		const counts = top7Makes.map((item) => item[1]);
		locGraphs.push({
			initial: true,
			index: 1,
			labels: makes,
			title: "Assets by Make",
			data: counts,
			type: "bar",
		});
		setLocationGraphs(locGraphs);
		setLocGraphsLoading(false);
	}, [allAssetRefQueriesFinished]);

	useEffect(() => {
		if (!userOrganizationGraphs) return;
		if (pathname === "/") {
			const graphs = userOrganizationGraphs.map(
				(graph: { data: any }, index: number) => ({
					...graph.data,
					initial: true,
					index: index + 1,
				}),
			);
			setDashboardGraphs(graphs);
		}
	}, [userOrganizationGraphs]);

	const addGraph = (graphData: string, graphId = undefined) => {
		let data;
		if (typeof graphData !== "string") {
			data = { response: [graphData], id: graphId };
		} else {
			data = graphData && JSON.parse(graphData);
		}

		if (!data) return;

		const { response, id } = data;

		if (response == null) return;

		if (pathname === "/") {
			if (Array.isArray(response)) {
				response.forEach((graph) => {
					setShouldFlashId(id);
					setDashboardGraphs((prev: any) => {
						const lastIndex = prev[0]?.index || 0;
						const index = lastIndex > 7 ? 1 : lastIndex + 1;
						const graphs = [{ ...graph, id, index }, ...prev];
						graphs.length > 8 && graphs.pop();
						return graphs;
					});
				});
			} else {
				setShouldFlashId(id);
				setDashboardGraphs((prev: { index: number }[]) => {
					const lastIndex = prev[0]?.index || 0;
					const index = lastIndex > 7 ? 1 : lastIndex + 1;
					const graphs = [{ ...response, id, index }, ...prev];
					graphs.length > 8 && graphs.pop();
					return graphs;
				});
			}
		} else {
			if (Array.isArray(response)) {
				response.forEach((graph) => {
					setShouldFlashId(id);
					setLocationGraphs((prev: { index: number }[]) => {
						const lastIndex = prev[0]?.index || 0;
						const index = lastIndex > 7 ? 1 : lastIndex + 1;
						const graphs = [{ ...graph, id, index }, ...prev];
						graphs.length > 8 && graphs.pop();
						return graphs;
					});
				});
			} else {
				setShouldFlashId(id);
				setLocationGraphs((prev: { index: number }[]) => {
					const lastIndex = prev[0]?.index || 0;
					const index = lastIndex > 7 ? 1 : lastIndex + 1;
					const graphs = [{ ...response, id, index }, ...prev];
					graphs.length > 8 && graphs.pop();
					return graphs;
				});
			}
		}
	};

	const value = {
		dashboardGraphs,
		locationGraphs,
		locGraphsLoading,
		addGraph,
		shouldFlashId,
		setShouldFlashId,
	};

	return (
		<GraphContext.Provider value={value as any}>
			{children}
		</GraphContext.Provider>
	);
}

function useGraph() {
	const context = useContext(GraphContext);
	if (context === undefined) {
		throw new Error("useGraph must be used within a GraphProvider");
	}
	return context;
}

export { GraphProvider, useGraph };
