import {
	notAvailableMapping,
	notAvailableMappingAdditionalDetails,
	notAvailableMappingArcAdditionalDetails,
} from "@constants/formInputs/mapping";

export const isFieldOrNotFoundPresent = (
	field,
	currentPage,
	responseData,
	fromChatbot,
	organization,
) => {
	if (
		currentPage === 3 ||
		fromChatbot ||
		(currentPage === 4 && organization === "SSPAMERICA") ||
		organization === "EcoTrak"
	) {
		const notAvailableField = notAvailableMapping[field];
		if (responseData[notAvailableField]) {
			return true;
		}

		if (
			responseData.manufacturerNotAvailable &&
			responseData.ifOtherManufacturer !== ""
		) {
			responseData.ifOtherManufacturer = "";
		}

		if (
			field in responseData &&
			typeof responseData[field] === "string" &&
			responseData[field].trim() !== ""
		) {
			return true;
		}

		// if (!(field in responseData)) {
		// 	return true;
		// }

		console.warn(`Field '${field}' is empty and not marked as not available.`);
		return false;
	}

	return true;
};

export const isFieldOrNotFoundPresentAdditionalDetails = (
	field,
	currentPage,
	organization,
	responseData,
	notAvailableCampingWorldMapping,
	fromChatbot,
) => {
	if (currentPage === 4 || fromChatbot) {
		if (organization === "cvs.com") {
			const notAvailableField = notAvailableMappingAdditionalDetails[field];
			if (responseData[notAvailableField]) {
				return true;
			}

			if (field in responseData && responseData[field] !== "") {
				return true;
			}

			console.warn(
				`Field '${field}' is empty and not marked as not available.`,
			);
			return false;
		}
		if (
			organization === "Arc Burger" ||
			organization === "Home Depot" ||
			organization === "Thortons"
		) {
			const notAvailableField = notAvailableMappingArcAdditionalDetails[field];
			if (responseData[notAvailableField]) {
				return true;
			}

			if (field in responseData && responseData[field] !== "") {
				return true;
			}

			console.warn(
				`Field '${field}' is empty and not marked as not available.`,
			);
			return false;
		}
		if (
			organization === "campingworld.com" ||
			organization === "foodlion.com" ||
			organization === "carliecs.com" ||
			organization === "raisingcanes.com" ||
			organization === "razzoos.com" ||
			organization === "swarovski.com" ||
			organization === "DIGI" ||
			organization === "CARMAX" ||
			organization === "Smash Burger" ||
			organization === "applegreen" ||
			organization === "VSM" ||
			organization === "COOPERSHAWK" ||
			organization === "Home Depot"
			// || organization === 'SSPAMERICA'
		) {
			console.log("campingworld");
			const notAvailableField = notAvailableCampingWorldMapping.mapping[field];

			if (responseData[notAvailableField]) {
				return true;
			}

			if (field in responseData && responseData[field] !== "") {
				return true;
			}

			// if (!(field in responseData)) {
			// 	return true;
			// }

			console.warn(
				`Field '${field}' is empty and not marked as not available.`,
			);
			return false;
		}

		return true;
	}
};
