import { storage } from "@assets/services/auth-service";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

export const getCroppedPhoto = async (
	data,
	setIsCropShowing,
	setInputData,
	selectedItem,
	setPhotoToBeCropped,
) => {
	setIsCropShowing(false);
	const imageRef = ref(
		storage,
		`FieldCaptures/${new Date().toISOString()}.webp`,
	);
	const task = await uploadString(imageRef, data, "data_url");
	const url = await getDownloadURL(task.ref);
	// Then attach the url to this asset.
	setInputData(selectedItem, url);
	setPhotoToBeCropped(null);
};
