import { getRemainingUsefulLifeVerbiage } from "@/utils/getRemainingUsefulLifeVerbiage";
import { saveAs } from "file-saver";

export const exportToCSV = (assets) => {
	const headers = [
		{ title: "Asset", key: "make" },
		{ title: "Asset Type", key: "assetType" },
		{ title: "Health Score", key: "healthScore" },
		{ title: "Useful Life Remaining", key: "usefulLifeRemaining" },
		{ title: "Optimal Replacement Year", key: "replacementYear" },
		{ title: "Replacement Cost", key: "avgReplCostUSD" },
	];

	const csvRows = [headers.map((header) => header.title).join(",")];

	assets.forEach((asset) => {
		const dob = asset.dateOfBirth || asset.estDateOfBirth;
		let usefulLife = getRemainingUsefulLifeVerbiage(
			asset.avgUsefulLifeYears,
			dob,
		);
		if (asset.remainingUsefullLifeRequested) {
			usefulLife = asset.remainingUsefullLifeRequested;
		}
		if (asset.healthScoreRequested) {
			asset.healthScore = asset.healthScoreRequested;
		}
		if (asset.replacementYearRequested) {
			asset.replacementYear = asset.replacementYearRequested;
		}

		const row = headers.map((header) => {
			let value = asset[header.key];
			if (header.key === "avgReplCostUSD") {
				value = `$${Number(value)}`;
			} else if (header.key === "usefulLifeRemaining") {
				value = usefulLife.includes("Expired") ? usefulLife : `${usefulLife}`;
			} else if (header.key === "make") {
				value = `${asset.make || "(not found)"}: ${
					asset.model || "(not found)"
				}`;
			}
			return value;
		});
		csvRows.push(row.join(","));
	});

	const csvContent = csvRows.join("\n");
	const blob = new Blob([csvContent], {
		type: "text/csv;charset=utf-8;",
		title: "Assets Data",
	});
	saveAs(blob, "assets-data.csv");
};
