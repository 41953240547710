import * as XLSX from "xlsx";

export const generateCvsScReportFromXlsx = async (
	file,
	setIsLoading,
	showToast,
) => {
	const env = import.meta.env.VITE_ENV;

	// Check if the file is provided
	if (!file) {
		showToast("Error", "Please choose a file", "danger");
		setIsLoading(false);
		return;
	}
	// Define the downloadFile function
	const downloadFile = (data, filename, type) => {
		const file = new Blob([data], { type: type });
		const a = document.createElement("a");
		a.href = URL.createObjectURL(file);
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(() => {
			document.body.removeChild(a);
			window.URL.revokeObjectURL(a.href);
		}, 0);
	};

	// Start processing the file
	const reader = new FileReader();
	reader.onload = async (e) => {
		const workbook = XLSX.read(e.target.result, { type: "binary" });
		const sheetName = workbook.SheetNames[0];
		const sheet = workbook.Sheets[sheetName];
		const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

		// Extract the first column and process
		const storeNumbers = data
			.map(
				(row) => (row[0] ? row[0].toString().trim().padStart(5, "0") : ""), // format to CVS store number
			)
			.filter((cell) => cell !== undefined);

		if (!storeNumbers.length) {
			showToast("Error", "No store numbers found in the file", "danger");
			setIsLoading(false);
			return;
		}

		try {
			const response = await fetch(
				env === "production"
					? "https://us-central1-property-echo-4d747.cloudfunctions.net/cvsScReport"
					: env === "staging"
						? "https://us-central1-property-echo-staging.cloudfunctions.net/cvsScReport"
						: "http://localhost:5001/property-echo-4d747/us-central1/cvsScReport",
				{
					method: "POST",
					body: JSON.stringify(storeNumbers),
				},
			);

			// Handle the response
			if (response.ok) {
				const filename = "SC_Export.xlsx";
				const responseData = await response.blob();
				downloadFile(
					responseData,
					filename,
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				);
				setIsLoading(false);
			} else {
				console.error("Failed to upload file");
			}
		} catch (error) {
			console.error("Error during file upload", error);
		}
	};

	reader.readAsBinaryString(file);
};
