import "./CheckUps.scss";

const Tile = ({ category, onTileClick, Icon }) => {
	return (
		<div className="grid-item" onClick={() => onTileClick(category.key)}>
			<div className="grid-item-content">
				{Icon && <Icon className="mb-2" size={50} />}
				<h3>{category.label}</h3>
			</div>
		</div>
	);
};

export default Tile;
