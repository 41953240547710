export async function callGenerateCvsWeeklyReportHttp(setIsLoading, showToast) {
	const env = import.meta.env.VITE_ENV;

	try {
		fetch(
			env === "production"
				? "https://us-central1-property-echo-4d747.cloudfunctions.net/generateCvsWeeklyReportHttp"
				: env === "staging"
					? "https://us-central1-property-echo-staging.cloudfunctions.net/generateCvsWeeklyReportHttp"
					: "http://localhost:5001/property-echo-4d747/us-central1/generateCvsWeeklyReportHttp",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			},
		);
		setTimeout(() => {
			setIsLoading(false);
			showToast(
				"Generating Weekly Report",
				"You will receive an email when your export is ready.",
				"success",
			);
		}, 1000);
	} catch (error) {
		console.error("Error calling cloud function:", error);
	}
}
