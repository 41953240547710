import { Loader } from "@SignedIn/Loader/Loader";
import {
	type KeyboardEventHandler,
	type MouseEventHandler,
	useState,
} from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CheckUpsEdit } from "./CheckUpsEdit";
import { TagReplacementEdit } from "./TagReplacementEdit";

interface CheckUpsModalProps {
	isOpen: boolean;
	setIsOpen: KeyboardEventHandler<any> | MouseEventHandler<any>;
	organization: string;
	toggle: () => void;
	responses: any;
	isQA: boolean;
	assetTag: string;
	submissionId: string;
	assetLocationChanged: boolean;
	handleNavigation: (formId: string, submissionId: string) => void;
	tagSelected: boolean;
	submissionRecord: any;
}

const CheckUpsModal = ({
	isOpen,
	setIsOpen,
	organization,
	toggle,
	responses,
	isQA,
	assetTag,
	submissionId,
	assetLocationChanged,
	handleNavigation,
	tagSelected,
	submissionRecord,
}: CheckUpsModalProps) => {
	console.log("tagSelected", tagSelected, submissionId);
	const [isLoading, _setIsLoading] = useState(false);

	return (
		<Modal isOpen={isOpen} toggle={setIsOpen} centered>
			<ModalHeader toggle={() => setIsOpen(false)}>Asset CheckUp</ModalHeader>

			<ModalBody
				style={{
					maxHeight: "90vh",
					overflowY: "auto",
					// position: 'relative',
				}}
			>
				{isLoading ? (
					<Loader />
				) : tagSelected ? (
					<TagReplacementEdit
						submissionRecord={submissionRecord}
						assetTag={assetTag}
						submissionId={submissionId}
						toggleSubmission={toggle}
						isQA={false}
						assetLocationChanged={assetLocationChanged}
						responses={responses}
						handleNavigation={handleNavigation}
					/>
				) : (
					<CheckUpsEdit
						assetTag={assetTag}
						submissionId={submissionId}
						toggleSubmission={toggle}
						isQA={false}
						assetLocationChanged={assetLocationChanged}
						responses={responses}
						handleNavigation={handleNavigation}
					/>
				)}
			</ModalBody>
		</Modal>
	);
};

export default CheckUpsModal;
