import { formatMillions } from "@/utils/formatNumberToMillions";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	PointElement,
	Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bubble } from "react-chartjs-2";

// Register the components you are using
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	Tooltip,
	Legend,
	ChartDataLabels,
);

const PredSpendBubbleChart = ({
	assets,
	originalAssets,
	assetTypeColors,
	selectedYear,
}) => {
	const newAssets = originalAssets || assets;
	// Define ranges and corresponding bubble sizes
	// const bubbleSizeRanges = [
	// 	{ range: [0, 5], size: 5 },
	// 	{ range: [5, 10], size: 10 },
	// 	{ range: [10, 25], size: 20 },
	// 	{ range: [25, 50], size: 30 },
	// 	{ range: [50, 100], size: 50 },
	// ];

	// Bubble sizes are determined by the asset type which has the highest replacement cost value, that asset will have the largest bubble
	// Then, the other bubbles are determined by dividing that asset types replacement cost by the largest bubble value. The size then get assigned based on the percentage returned
	const bubbleSizeRanges = [
		{ range: [0, 20], size: 5, color: "#000000" },
		{ range: [20, 40], size: 10, color: "#262626" },
		{ range: [40, 60], size: 20, color: "#4D4D4D" },
		{ range: [60, 80], size: 30, color: "#737373" },
		{ range: [80, 100], size: 50, color: "#999999" }, // largest bubble is always 100%, so size is 50,
	];

	let totalReplCost = 0;
	let max = 0;

	// Aggregate data by asset type
	const assetData = newAssets.reduce((acc, asset) => {
		if (!acc[asset.assetType]) {
			acc[asset.assetType] = {
				count: 0,
				totalReplCost: 0,
				totalHealthScore: 0,
				assetType: asset.assetType,
			};
		}
		const replCost = Number(asset.avgReplCostUSD);
		totalReplCost += replCost;
		const healthScore = Number(asset.healthScore);
		if (!isNaN(replCost) && !isNaN(healthScore)) {
			acc[asset.assetType].totalReplCost += replCost;
			acc[asset.assetType].totalHealthScore += healthScore;
			acc[asset.assetType].count++;
		}
		return acc;
	}, {});

	// find asset with highest replacement cost, then assign totalReplCost
	for (const assetType in assetData) {
		const replCost = assetData[assetType].totalReplCost;
		if (replCost > max) {
			max = replCost;
		}
	}
	totalReplCost = max;

	// Convert aggregated data to array and calculate average replacement cost and health score
	const assetArray = Object.values(assetData).map((asset) => {
		return {
			assetType: asset.assetType,
			totalReplCost: Math.round(asset.totalReplCost),
			assetCount: asset.count,
			avgHealthScore: Math.round(asset.totalHealthScore / asset.count),
			percentOfTotal: (asset.totalReplCost / totalReplCost) * 100,
		};
	});

	// Helper function to get bubble size based on replacement cost
	const getBubbleSize = (assetType) => {
		// get percent of total
		const asset = assetArray.find((a) => a.assetType === assetType);
		const percentOfTotal = asset.percentOfTotal;
		const range = bubbleSizeRanges.find(
			(range) =>
				percentOfTotal >= range.range[0] && percentOfTotal <= range.range[1],
		);
		return range ? range.size : 5;
	};

	// sort bubbles to prevent overlapping
	const sortedAssetArray = assetArray.sort((a, b) => {
		const sizeA = getBubbleSize(a.assetType);
		const sizeB = getBubbleSize(b.assetType);
		return sizeA - sizeB; // Smaller bubbles first
	});

	const data = {
		datasets: sortedAssetArray.map((asset) => ({
			label: asset.assetType,
			data: [
				{
					x: asset.avgHealthScore,
					y: asset.assetCount,
					r: getBubbleSize(asset.assetType),
				},
			],
			backgroundColor: assetTypeColors[asset.assetType],
			borderColor: assetTypeColors[asset.assetType],
			borderWidth: 1,
			dragData: false,
		})),
	};

	const options = {
		scales: {
			x: {
				beginAtZero: true,
				max: 100, // Keep max at 100
				title: {
					display: true,
					text: "Avg Health Score",
				},
				offset: true, // Add padding around the x-axis
				ticks: {
					font: {
						size: 16,
						weight: "bold",
					},
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Asset Count",
				},
				ticks: {
					font: {
						size: 16,
						weight: "bold",
					},
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: (context) => {
						const label = context.dataset.label || "";
						const { x, y, r } = context.raw;
						const matchedAssetType = assetArray.find(
							(obj) => obj.assetType == label,
						);
						const totalReplCost = formatMillions(
							matchedAssetType.totalReplCost,
						);
						return `${label}: (Avg Health Score: ${x}, Asset Count: ${y}, Total Replacement Cost: ${totalReplCost}`;
					},
				},
				animation: {
					duration: 500, // Animation duration in ms
					easing: "easeOutQuart", // Easing function for smoothness
				},
			},
			legend: {
				display: selectedYear ? false : true,
				position: "right",
				maxWidth: 800,
				maxHeight: 2000,
				title: {
					display: true,
					text: "Interactive Legend",
					position: "bottom",
					font: {
						size: 16,
						weight: "bold",
					},
				},
				onClick: function (e, legendItem) {
					const index = legendItem.datasetIndex;
					const ci = this.chart;
					const meta = ci.getDatasetMeta(index);

					meta.hidden =
						meta.hidden === null ? !ci?.data?.datasets[index]?.hidden : null;
					ci.update();
				},
				onHover: function (e, legendItem) {
					const chart = this.chart;
					const datasetIndex = legendItem.datasetIndex;

					// Get the elements corresponding to the datasetIndex
					const activeElements = chart
						.getDatasetMeta(datasetIndex)
						.data.map((_, index) => ({
							datasetIndex: datasetIndex,
							index: index,
						}));

					// Show tooltip for the hovered legend item
					chart.tooltip.setActiveElements(activeElements);
					chart.update();
				},
				onLeave: function (e, legendItem) {
					const chart = this.chart;

					// Hide the tooltip when the mouse leaves the legend item
					chart.tooltip.setActiveElements([]);
					chart.update();
				},
				labels: {
					font: {
						size: 14,
					},
					generateLabels: (chart) => {
						const data = chart.data;
						return data.datasets
							.map((dataset, index) => {
								const meta = chart.getDatasetMeta(index);
								const isHidden = meta.hidden;
								const backgroundColor = assetTypeColors[dataset.label];
								return {
									text: dataset.label,
									lineWidth: 2,
									datasetIndex: index,
									borderRadius: "50%",
									borderColor: "white",
									backgroundColor: "white",
									fillStyle: isHidden ? "white" : backgroundColor,
									datasetIndex: index,
									strokeStyle: backgroundColor,
									fontColor: isHidden ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,1)", // Change font color based on hidden property
								};
							})
							.sort((a, b) => {
								return a.text.localeCompare(b.text);
							});
					},
					usePointStyle: true,
					padding: 12,
				},
			},
			datalabels: {
				align: "center",
				anchor: "center",
				color: "white",
				font: {
					weight: "bold",
				},
				fontSize: 10,
				formatter: (value, context) => {
					// find replacement cost of asset
					const matchedAssetType = assetArray.find(
						(obj) => obj.assetType == context.dataset.label,
					);
					const totalReplCost = formatMillions(
						matchedAssetType.totalReplCost,
						1,
					);
					// if bubble size is within 3rd bubble range, show replacement cost
					if (context.dataset.data[0].r >= 20) {
						return totalReplCost;
					}
					return "";
				},
			},
		},
		responsive: true,
		maintainAspectRatio: false,
	};

	return (
		<div className="w-100 h-100 d-flex">
			{/* {!selectedYear && (
				<div
					className='bubble-examples d-flex flex-column align-items-center justify-content-evenly'
					style={{
						height: '100%',
						width: '220px',
						transform: 'translateX(-50px)',
					}}
				>
					{bubbleSizeRanges.map((range, index) => (
						<div
							key={range.size}
							className='bubble-example d-flex align-items-center position-relative'
						>
							<div
								className='bubble'
								style={{
									width: range.size * 2,
									height: range.size * 2,
									backgroundColor: range.color,
									borderRadius: '50%',
								}}
							></div>
							<div
								className='position-absolute'
								style={{
									textWrap: 'nowrap',
									left: 10 + range.size * 2,
								}}
							>
								<p className='m-0'>{`${formatMillions(
									(bubbleSizeRanges[index].range[0] / 100) *
										totalReplCost,
									totalReplCost < 5000000 ? 1 : 0
								)} - ${formatMillions(
									(bubbleSizeRanges[index].range[1] / 100) *
										totalReplCost,
									totalReplCost < 5000000 ? 1 : 0
								)}`}</p>
							</div>
						</div>
					))}
				</div>
			)} */}
			<Bubble data={data} options={options} />
		</div>
	);
};

export { PredSpendBubbleChart };
