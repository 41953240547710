import { ALFRED_SERVICE_URL } from "@/constants/env";
import { auth, db } from "@assets/services/auth-service";
import { DB_FILTER, DB_ORDER, DB_PATH } from "@constants/db";
import {
	type DocumentData,
	type DocumentReference,
	type OrderByDirection,
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import type { SetStateAction } from "react";

//This isn't used to ever grab organization information, ignored for now
export const getDocFromRef = async (ref: DocumentReference<unknown>) => {
	const docSnap = await getDoc(ref);
	return docSnap;
};

//This isn't used to ever grab organization information, ignored for now
export const getDocFromPath = async (path: string) => {
	const docSnap = await getDoc(doc(db, path));
	return docSnap;
};

/**
 * Fetches all organizations
 * ONLY FOR >= ADMIN
 * @returns {Promise<any>}
 */
export const getOrganizations = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/organization/all`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch all organizations: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Returns all allowed organizations for a user
 * @returns {Promise<any>}
 */
export const getSelfAllowedOrganizations = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/organization/self/allowed_organizations`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch self allowed organizations: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Returns information on the current user's organization
 * @returns {Promise<any>}
 */
export const getSelfOrganization = async () => {
	try {
		console.log("Fetching user self organization");
		const url = `${ALFRED_SERVICE_URL}/organization/self`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch self organization: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Fetches all the current user's organization forms
 * @returns {Promise<any>}
 */
export const getSelfOrganizationForms = async () => {
	try {
		console.log("Fetching user self organization forms");
		const url = `${ALFRED_SERVICE_URL}/organization/self/forms`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch self organization forms: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Fetches all the current user's organization forms
 * @returns {Promise<any>}
 */
export const getSelfOrganizationReports = async () => {
	try {
		console.log("Fetching user self organization reports");
		const url = `${ALFRED_SERVICE_URL}/organization/self/reports`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch self organization reports: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Fetches the most recent 5 graphs for the user's current organization
 * @returns {Promise<any>}
 */
export const getSelfOrganizationGraphs = async () => {
	try {
		console.log("Fetching user self organization graphs");
		const url = `${ALFRED_SERVICE_URL}/organization/self/graphs`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch self organization graphs: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Gets the inputs specific to an org and sets it to the appropriate state
export const getOrgSpecificInputs = async (
	organization: {
		external_firebase_id: string;
		config: { org_specific_inputs: any };
	},
	setState: SetStateAction<any>,
) => {
	console.log(organization, "organizationPassed");
	if (organization.external_firebase_id !== "SSPAMERICA") {
		console.log(organization.config, "org_specific_inputs");
		if (organization.config.org_specific_inputs) {
			setState(organization.config.org_specific_inputs);
		}
	}
};

/**
 *
 * @param organization Organization object from userStore
 * @param setState
 * @returns {Promise<void>}
 */
export const getOrgAdditionalLocationInputs = async (
	organization: {
		config: { org_specific_inputs: undefined };
		external_firebase_id: string;
	},
	setState: (arg0: any) => void,
) => {
	// console.log(organization, 'organization');
	if (
		organization.config?.org_specific_inputs !== undefined &&
		organization.external_firebase_id === "SSPAMERICA"
	) {
		setState(organization.config?.org_specific_inputs);
	}
};

export const getManufacturer = async (name: any) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/manufacturer/lookup/${name}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch single manufacturer: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const getManufacturers = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/manufacturer/all`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch all manufacturers: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const getSelfOrganizationManufacturers = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/organization/manufacturer/all`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch all manufacturers: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Gets list of manufacturers for form and sets it to the appropriate state
export const getManufacturersForForm = async (setState: any) => {
	const manufacturers = await getManufacturers();
	//Converting to a dictionary
	const parsed = manufacturers.reduce(
		(acc: { [x: string]: any }, item: { id: string | number }) => {
			acc[item.id] = item;
			return acc;
		},
		{},
	);
	setState(parsed);
};

export const getReleases = async () => {
	const snap = await getDocs(
		query(
			collection(db, DB_PATH.RELEASES),
			orderBy(DB_ORDER.DATE, DB_ORDER?.DESC as OrderByDirection),
		),
	);
	return snap;
};

export const selfUpdateOrganization = async (data: any) => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/organization/self/update_config`;

	const response = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
		body: JSON.stringify(data),
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(
				`Failed to update user organization config: ${errorDetails.detail}`,
			);
		});
	}

	return await response.json();
};

export const addManufacturer = async (name: any) => {
	await addDoc(collection(db, DB_PATH.MANUFACTURERS), {
		name: name,
	});
};

export const deleteWithRef = async (ref: DocumentReference<unknown>) => {
	await deleteDoc(ref);
};

export const updateWithRef = async (
	ref: DocumentReference<any>,
	data: { assetRef: DocumentReference<DocumentData> },
) => {
	await updateDoc(ref, data);
};

export const updateManufacterFromQA = async (data: {
	manufacturer: any;
	ifOtherManufacturer?: string;
}) => {
	data.manufacturer = data?.ifOtherManufacturer?.toUpperCase();
	if (data.ifOtherManufacturer) {
		data.ifOtherManufacturer = undefined;
	}
	const manufacturersSnap = await getManufacturer(data.manufacturer);
	if (!manufacturersSnap) {
		await addManufacturer(data.manufacturer);
	}
};

// Gets both the org specific inputs and the auto save data from an org & passes it to the states
export const getOrgInputsAndAutoSave = async (
	organization: {
		config: { auto_save_drafts: any; org_specific_inputs: any };
		external_firebase_id: string;
	},
	isEditing: any,
	setAutoSave: (arg0: boolean) => void,
	setInputs: (arg0: any) => void,
) => {
	if (isEditing) {
		setAutoSave(false);
	} else {
		setAutoSave(organization.config.auto_save_drafts);
	}

	if (
		organization.config.org_specific_inputs &&
		organization.external_firebase_id !== "SSPAMERICA"
	) {
		setInputs(organization.config.org_specific_inputs);
	}
};

export const populateEquipmentAreas = async (setState: (arg0: any) => void) => {
	const areas = await selfOrganizationEquipmentAreas();
	setState(areas);
};

// Function to check if a manufacturer exists in the known manufacturers collection
const _manufacturerExists = async (manufacturer: unknown) => {
	const colRef = collection(db, DB_PATH.MANUFACTURERS);
	const q = query(colRef, where(DB_FILTER.NAME, "==", manufacturer));
	const querySnapshot = await getDocs(q);
	console.log("manufacturerExists:", querySnapshot.size);
	return !querySnapshot.empty;
};

export const smartManufacturerOptions = async (assetTypeId: any) => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/manufacturer/smart/${assetTypeId}`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(
				`Failed to fetch smart manufacturers for ${assetTypeId}: ${errorDetails.detail}`,
			);
		});
	}

	const manufacturers = await response.json();

	if (manufacturers && manufacturers.length === 0) {
		console.error("No manufacturers found for assetType:", assetTypeId);
	}

	return manufacturers;
};

export const getManufacturerDoc = async (manufacturer: unknown) => {
	if (manufacturer) {
		const colRef = collection(db, DB_PATH.MANUFACTURERS);
		const q = query(colRef, where(DB_FILTER.NAME, "==", manufacturer));
		const querySnapshot = await getDocs(q);
		return querySnapshot.docs[0];
	}
};

/**
 * Fetches all equipment areas for an organization based on users set organization
 * @returns {Promise<any>}
 */
export const selfOrganizationEquipmentAreas = async () => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/organization/self/equipment_areas`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(
				`Failed to fetch equipment areas: ${errorDetails.detail}`,
			);
		});
	}

	return await response.json();
};

/**
 * Fetches all the asset types based on a users organization
 * @returns {Promise<any>}
 */
export const selfOrganizationAssetTypes = async (includeDeleted = false) => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/organization/self/asset_types?include_deleted=${includeDeleted}`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(`Failed to fetch asset types: ${errorDetails.detail}`);
		});
	}

	return await response.json();
};

/**
 * Fetches all the asset types based on a users organization
 * @returns {Promise<any>}
 */
export const selfOrganizationAssetTypeCategories = async () => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/organization/self/asset_type_categories`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(
				`Failed to fetch asset type categories: ${errorDetails.detail}`,
			);
		});
	}

	return await response.json();
};

export const getAssetRanks = async (organization: string, formId: string) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.ASSET_RANKS,
		);

		const querySnapshot = await getDocs(colRef);

		const ranks: any[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			if (data?.name) {
				ranks.push(data.name);
			}
		});

		return ranks;
	} catch (error) {
		console.error("Error fetching asset ranks:", error);
		throw error;
	}
};

export const getAssetClasses = async (organization: string, formId: string) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.ASSET_CLASSES,
		);

		const querySnapshot = await getDocs(colRef);

		const classes: any[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			if (data?.name) {
				classes.push(data.name);
			}
		});

		return classes;
	} catch (error) {
		console.error("Error fetching asset classes:", error);
		throw error;
	}
};

export const getAssetNumbers = async (organization: string, formId: string) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.ASSET_NUMBERS,
		);

		const querySnapshot = await getDocs(colRef);

		const numbers: any[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			if (data?.name) {
				numbers.push(data.name);
			}
		});

		return numbers;
	} catch (error) {
		console.error("Error fetching asset numbers:", error);
		throw error;
	}
};

export const getPropertyIds = async (organization: string, formId: string) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.PROPERTY_IDS,
		);

		const querySnapshot = await getDocs(colRef);

		const propertyIds: any[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			if (data?.name) {
				propertyIds.push(data.name);
			}
		});

		return propertyIds;
	} catch (error) {
		console.error("Error fetching property ids:", error);
		throw error;
	}
};

export const getAssetLocations = async (
	organization: string,
	formId: string,
) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.EQUIPMENT_AREAS,
		);

		const querySnapshot = await getDocs(colRef);

		const locations: any[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			if (data?.name) {
				locations.push(data.name);
			}
		});

		return locations;
	} catch (error) {
		console.error("Error fetching asset locations:", error);
		throw error;
	}
};

export const getSelfOrganizationLocations = async () => {
	const url = `${ALFRED_SERVICE_URL}/location/self/all`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(
				`Failed to fetch self organization locations: ${errorDetails.detail}`,
			);
		});
	}

	return await response.json();
};
