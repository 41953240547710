import { useUserStore } from "@/stores";
import { auth } from "@assets/services/auth-service";
import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import { getAssets as getOrgAssets } from "@services/location-service";
import { formatDate } from "@utils/formalDate2";
import { useEffect, useState } from "react";

const CampingWorldReport = ({
	selectedDependencyValue,
	organization,
	isLoading,
	setIsLoading,
	showToast,
}) => {
	const { userOrganization } = useUserStore();
	const [pdf, setPdf] = useState<any>(undefined);
	const [orgLogo, setOrgLogo] = useState<string | undefined>(
		userOrganization?.config?.logo_url,
	);
	const [assets, setAssets] = useState();
	const [nonTaggedAssets, setNonTaggedAssets] = useState();
	const [visitDate, setVisitDate] = useState();

	let locationId;
	let address1;
	let address2;
	if (selectedDependencyValue && typeof selectedDependencyValue === "object") {
		locationId = selectedDependencyValue.id;
		address1 = selectedDependencyValue.address1;
		address2 = selectedDependencyValue.address2;
	}

	const getLogo = async () => {
		setOrgLogo(userOrganization?.config?.logo_url);
	};

	const getAssets = async () => {
		const assetRows = [];
		const nonTaggedAssetRows = [];
		let visit;
		const assetsSnap = await getOrgAssets(locationId);
		assetsSnap.forEach(async (asset) => {
			const newAsset = {
				id: asset.asset_tag,
				type: asset.organization_asset_type.name,
				make: asset?.model?.manufacturer.name ?? null,
				model: asset?.model?.model_number,
				serial: asset.serial_number,
				img:
					asset.photos.find((item) => item.type === "EQUIPMENT")?.url || null,
				manufacturersPlateAssetImage:
					asset.photos.find((item) => item.type === "MANUFACTURER_PLATE")
						?.url || null,
				location: asset.location_in_facility
					? asset.location_in_facility.name
					: null,
				storeSite: selectedDependencyValue.name,
				bayLocation: asset?.unique_fields?.bayLocation,
				bayCount: asset?.unique_fields?.bayQuantity,
				condition: asset?.unique_fields?.assetCondition,
				manufacturerDate: asset.date_of_birth,
				idTagAssetImage:
					asset.photos.find((item) => item.type === "ID_TAG")?.url || null,
				submittedDate: asset.created_timestamp,
				quantity: asset?.unique_fields?.quantity,
				bayQuantity: asset?.unique_fields?.bayQuantity,
				height: asset?.unique_fields?.height,
				condInputs: asset.organization_asset_type.conditional_inputs,
				other: asset?.unique_fields,
			};
			if (newAsset.id) {
				assetRows.push(newAsset);
			} else {
				nonTaggedAssetRows.push(newAsset);
			}
			if (!visit) {
				const newVisitDate = formatDate(asset.date_of_birth);
				setVisitDate(newVisitDate);
			}
		});
		setAssets(assetRows);
		setNonTaggedAssets(nonTaggedAssetRows);
	};

	const styles = StyleSheet.create({
		page: {
			flexDirection: "row",
			fontFamily: "Helvetica",
		},
		titleSection: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexGrow: 1,
		},
		section: {
			flexGrow: 1,
		},
		title: {
			fontSize: 24,
			flexDirection: "column",
			textAlign: "center",
			marginTop: 2,
			marginBottom: 12,
			fontStyle: "bolder",
		},
		address1: {
			textAlign: "center",
			margin: "8px 0 0 0",
		},
		address2: {
			textAlign: "center",
			margin: "0 0 8px 0",
		},
		date: {
			marginTop: "10px",
		},
		logo: {
			position: "absolute",
			left: 10,
			bottom: 10,
			objectFit: "contain",
			width: 160,
		},
		header: {
			height: 100,
			backgroundColor: "#000",
			position: "relative",
			top: 0,
			left: 0,
			right: 0,
			display: "flex",
			alignItems: "center",
		},
		headerText: {
			color: "#fff",
			textAlign: "center",
			fontSize: 40,
			marginTop: 25,
		},
		break: {
			height: 60,
			position: "relative",
			width: "100%",
			bottom: 0,
			backgroundColor: "transparent",
		},
		img: {
			objectFit: "cover",
			width: "160px",
			height: "160px",
		},
		inputRow: {
			display: "flex",
			flexDirection: "row",
			marginBottom: 14,
		},
		inputLabel: {
			fontWeight: "bolder",
			fontSize: 14,
			width: "280px",
			marginRight: 50,
			wordWrap: "break-word",
		},
		inputValue: {
			paddingLeft: 12,
			paddingRight: 12,
			paddingTop: 4,
			paddingBottom: 4,
			borderRadius: "4px",
			backgroundColor: "#ADD8E6",
			fontSize: 10,
			height: 22,
		},
		coverPhoto: {
			width: "100%",
			objectFit: "cover",
			position: "absolute",
			bottom: "0",
			zIndex: -5,
		},
		pageNumber: {
			position: "absolute",
			bottom: 10,
			right: 10,
			color: "#000",
			fontSize: "14px",
		},
	});

	const getPdf = async () => {
		if (assets.length > 0 || nonTaggedAssets.length > 0) {
			await setPdf(
				<Document>
					<Page size="A4" style={styles.page}>
						<View style={styles.titleSection}>
							<View
								style={{
									objectFit: "contain",
									width: "180px",
								}}
							>
								<Image src={orgLogo} />
							</View>
							<View
								style={{
									border: "2px solid #228B22",
									padding: "20px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginTop: "10px",
								}}
							>
								<Text style={styles.title}>Site Inspection Report</Text>
								<Text style={styles.address1}>{address1}</Text>
								<Text style={styles.address2}>{address2}</Text>
								<Text style={styles.date}>{`Visit Date: ${visitDate}`}</Text>
							</View>
							<View style={styles.coverPhoto}>
								<Image
									src={
										"https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/iStock-1409408119.jpg?alt=media&token=1659d894-be6c-429b-b8a2-4eff1db3ba08"
									}
								/>
							</View>
							<View style={styles.logo}>
								<Image
									src={
										"https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/Logos%2Fpe-logo.png?alt=media&token=06d779c3-03e4-4981-8b10-009679be3e9e"
									}
								/>
							</View>
						</View>
					</Page>

					{assets.map((asset, index) => {
						return (
							<Page
								size="A4"
								style={{
									...styles.page,
									paddingTop: 6,
								}}
								wrap
								key={asset.id}
							>
								<View style={styles.section}>
									<View
										style={{
											display: "flex",
											flexDirection: "column",
											margin: 12,
										}}
										wrap={false}
									>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>ASSET TAG ID</Text>
											<Text style={styles.inputValue}>{asset.id || "N/A"}</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>ASSET TYPE</Text>
											<Text style={styles.inputValue}>
												{asset.type || "N/A"}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>ASSET AREA</Text>
											<Text style={styles.inputValue}>
												{asset.location || "N/A"}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>MAKE</Text>
											<Text style={styles.inputValue}>
												{asset.make || "N/A"}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>MODEL</Text>
											<Text style={styles.inputValue}>
												{asset.model || "N/A"}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>SERIAL</Text>
											<Text style={styles.inputValue}>
												{asset.serial || "N/A"}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>MANUFACTURED DATE</Text>
											<Text style={styles.inputValue}>
												{asset.manufacturerDate || "N/A"}
											</Text>
										</View>
										{asset.bayLocation && (
											<View style={styles.inputRow}>
												<Text style={styles.inputLabel}>
													FALL ARREST SYSTEM BAY LOCATION
												</Text>
												<Text style={styles.inputValue}>
													{asset.bayLocation || "N/A"}
												</Text>
											</View>
										)}
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>CONDITION</Text>
											<Text style={styles.inputValue}>
												{asset.condition || "N/A"}
											</Text>
										</View>
										<View
											style={{
												...styles.inputRow,
											}}
										>
											<Text style={styles.inputLabel}>
												EQUIPMENT OR AREA PICTURE
											</Text>
											<View style={styles.img}>
												{asset.img && <Image src={asset.img} />}
											</View>
										</View>
										<View
											style={{
												...styles.inputRow,
											}}
										>
											<Text style={styles.inputLabel}>MANUFACTURER PLATE</Text>
											<View style={styles.img}>
												{asset.manufacturersPlateAssetImage && (
													<Image src={asset.manufacturersPlateAssetImage} />
												)}
											</View>
										</View>
									</View>
									<View style={styles.logo} fixed>
										<Image
											src={
												"https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/Logos%2Fpe-logo.png?alt=media&token=06d779c3-03e4-4981-8b10-009679be3e9e"
											}
										/>
									</View>
									<View style={styles.coverPhoto} fixed>
										<Image
											src={
												"https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/cover2.jpg?alt=media&token=c9d04f70-9f92-49e9-8c82-e12f9d89434b"
											}
										/>
									</View>
									<View fixed style={styles.break} />
									<View style={styles.pageNumber}>
										<Text>
											Page {index + 2} of{" "}
											{assets.length + 1 + nonTaggedAssets.length}
										</Text>
									</View>
								</View>
							</Page>
						);
					})}

					{nonTaggedAssets.map((asset, index) => (
						<Page
							size="A4"
							style={{
								...styles.page,
								paddingTop: 6,
							}}
							key={asset.id}
							wrap
						>
							<View style={styles.section}>
								<View style={{ margin: 12 }}>
									<Text>ADDITIONAL DATA CAPTURE</Text>
								</View>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										margin: 12,
									}}
									wrap={false}
								>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>ASSET TYPE</Text>
										<Text style={styles.inputValue}>{asset.type || "N/A"}</Text>
									</View>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>ASSET AREA</Text>
										<Text style={styles.inputValue}>
											{asset.location || "N/A"}
										</Text>
									</View>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>MAKE</Text>
										<Text style={styles.inputValue}>{asset.make || "N/A"}</Text>
									</View>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>CONDITION</Text>
										<Text style={styles.inputValue}>
											{asset.condition || "N/A"}
										</Text>
									</View>
									<View
										style={{
											...styles.inputRow,
										}}
									>
										<Text style={styles.inputLabel}>
											EQUIPMENT OR AREA PICTURE
										</Text>
										<View style={styles.img}>
											{asset.img && <Image src={asset.img} />}
										</View>
									</View>
									<View
										style={{
											...styles.inputRow,
										}}
									>
										<Text style={styles.inputLabel}>MANUFACTURER PLATE</Text>
										<View style={styles.img}>
											{asset.manufacturersPlateAssetImage && (
												<Image src={asset.manufacturersPlateAssetImage} />
											)}
										</View>
									</View>
								</View>
								<View style={styles.logo} fixed>
									<Image
										src={
											"https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/Logos%2Fpe-logo.png?alt=media&token=06d779c3-03e4-4981-8b10-009679be3e9e"
										}
									/>
								</View>
								<View style={styles.coverPhoto} fixed>
									<Image
										src={
											"https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/cover2.jpg?alt=media&token=c9d04f70-9f92-49e9-8c82-e12f9d89434b"
										}
									/>
								</View>
								<View fixed style={styles.break} />
								<View style={styles.pageNumber}>
									<Text>
										Page {assets.length + index + 2} of{" "}
										{assets.length + 1 + nonTaggedAssets.length}
									</Text>
								</View>
							</View>
						</Page>
					))}
				</Document>,
			);
		}
	};

	const downloadPdf = async () => {
		const asString = JSON.stringify(pdf, (_k, v) =>
			typeof v === "symbol" ? `$$Symbol:${Symbol.keyFor(v)}` : v,
		);

		// On localhost, open the following link and request temp access to the demo server
		// to get around the CORS issue: https://cors-anywhere.herokuapp.com/corsdemo ...
		// Before deploying to prod, switch the URLs passed to fetch, as CORS is not an issue
		// when using the app on propertyecho.com or staging.propertyecho.com
		const controller = new AbortController();
		const signal = controller.signal;
		const date = new Date().toISOString().split("T")[0];
		showToast(
			"Generating PDF",
			"Please wait while your PDF is being generated...",
		);
		await fetch(
			// 'https://micro-backend-kn7dm7lqsa-ue.a.run.app/leaveBehindExport',
			"https://us-central1-property-echo-4d747.cloudfunctions.net/downloadLeaveBehind",
			{
				method: "POST",
				signal: signal,
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					data: asString,
					email: auth?.currentUser?.email,
					location: `${address1}, ${address2}`,
					date: date,
					org: organization.external_firebase_id,
				}),
			},
		);
		setIsLoading(false);
	};

	useEffect(() => {
		if (isLoading) {
			getAssets();
			getLogo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		getPdf();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assets, nonTaggedAssets, orgLogo]);

	useEffect(() => {
		if (pdf) {
			downloadPdf();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pdf]);

	return <div></div>;
};

export { CampingWorldReport };

// Function specific for generating report
export const generateCWReport = (
	selectedDependencyValue,
	setIsLoading,
	showToast,
) => {
	if (!selectedDependencyValue) {
		setIsLoading(false);
		return showToast("Error", "Please select a location", "danger");
	}
	setIsLoading(true);
};
