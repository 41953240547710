export const validateAndSetPage9 = (
	organization,
	responseData,
	setIsPage9Valid,
) => {
	const isValid =
		organization === "cvs.com" || organization === "EcoTrak"
			? !!responseData.assetCondition
			: true; //cvs
	setIsPage9Valid(isValid);
};
