import { Bar } from "react-chartjs-2";
import { AiOutlineExpand } from "react-icons/ai";
import { Loader } from "../Loader/Loader";

const HealthScoreHoverPreview = ({ chartData, setIsModalShowing }) => {
	const options = {
		scales: {
			y: {
				beginAtZero: true,
				max: 100,
				ticks: {
					callback: (value) => `${value}%`,
				},
				grid: {
					display: false,
				},
			},
			x: {
				display: false,
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
		maintainAspectRatio: false,
	};

	if (chartData === null) {
		return (
			<div className="hover-chart-preview d-flex justify-content-center align-items-center">
				No asset data available.
			</div>
		);
	}

	return (
		<div className="hover-chart-preview">
			<AiOutlineExpand
				className="hover-expand-btn"
				onClick={() => setIsModalShowing(true)}
				style={{
					marginLeft: "auto",
					fontSize: "20px",
					position: "absolute",
					zIndex: 1000,
					top: "10px",
					right: "10px",
				}}
			/>
			{!chartData ? (
				<Loader small={true} />
			) : (
				<Bar data={chartData} options={options} />
			)}
		</div>
	);
};

export { HealthScoreHoverPreview };
