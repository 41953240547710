import { createChatBotMessage } from "react-chatbot-kit";

export const handleSubmit = async (
	submitState,
	setState,
	locationId,
	saveDataCaptureState,
) => {
	const prevMessageNoWidget = createChatBotMessage("Please review the data.", {
		withAvatar: true,
	});
	const res = createChatBotMessage(
		{
			text: "Your submission has been recorded, thank you. Please click the arrow to see your submission.",
			redirect: locationId,
			submitState,
		},
		{ withAvatar: true },
	);

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: res, action: "add" },
	]);
	setState((prev) => ({
		...prev,
		messages: [...prev.messages.slice(0, -1), prevMessageNoWidget, res],
	}));
};
