import { auth, db } from "@assets/services/auth-service";
import { addDoc, collection } from "firebase/firestore";

export class OcrError extends Error {
	ocrResponse: any;
}

export const getOcrResponse = async (buffer: BlobPart) => {
	const url = `${import.meta.env.VITE_ALFRED_SERVICE_URL}/FacilityVision/OCR/VelebitPlateRead`;
	const controller = new AbortController();
	const timeoutId = setTimeout(() => controller.abort(), 30000);
	if (buffer) {
		try {
			const newBlob = new Blob([buffer], { type: "image/webp" });
			const formData = new FormData();
			formData.append("file", newBlob);
			const response = await fetch(url, {
				signal: controller.signal,
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			if (response?.ok) {
				const responseJson = await response.json();
				const noResults = true;
				const templateMatchSuccess =
					responseJson?.data?.template_matching_success;
				const noMatchSuccess = responseJson?.data?.metadata?.no_match_passed;
				if ((!templateMatchSuccess && noResults) || noMatchSuccess === false) {
					clearTimeout(timeoutId);
					const error = new OcrError();
					error.message = "Template match failed";
					error.ocrResponse = responseJson;
					throw error;
				}
				clearTimeout(timeoutId);
				return { ...responseJson, templateMatchSuccess };
			}
			clearTimeout(timeoutId);
			const error = new Error();
			error.message = `Upload failed with status: ${response.status}`;
			throw error;
		} catch (e: any) {
			if (e.message === "The user aborted a request.") {
				const error = new Error();
				error.message = "Server timed out";
				throw error;
			}
			clearTimeout(timeoutId);
			throw e;
		}
	} else {
		clearTimeout(timeoutId);
		const error = new Error();
		error.message = "No file selected";
		throw error;
	}
};

export const modelExistsInDb = async (organization: string, model: unknown) => {
	const url = `${import.meta.env.VITE_ALFRED_SERVICE_URL}/asset_type/lookup/${organization}/${model}`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});
	if (response.ok) {
		const responseJson = await response.json();
		return responseJson;
	}

	const error = new Error(`Upload failed with status: ${response.status}`);
	throw error;
};

export const matchManufacturer = (manufacturer: string) => {
	return manufacturer ? manufacturer.split("_").join(" ").toUpperCase() : null;
};

export const logFailedOcrScan = async (
	image: any,
	response: any,
	organization: any,
	submittingUser: any,
) => {
	try {
		const colRef = collection(db, "failed-ocr-scans");
		await addDoc(colRef, {
			image,
			response: JSON.stringify(response),
			organization,
			submittingUser,
			uploadedTimestamp: new Date().toISOString(),
		});
	} catch (error) {
		console.error("Error logging failed OCR scan: ", error);
	}
};

// Function to determine color based on confidence
export const getColorBasedOnConfidence = (confidence: number) => {
	if (confidence < 0.8) return "#df3053";
	if (confidence < 0.85) return "#fbc658";
	if (confidence < 0.9) return "yellow";
	// if (confidence < 0.95) return 'lightgreen';
	return "#228b22";
};

export const addOcrDataToGoogleSheet = async (data: any, ocrData: any) => {
	const env = import.meta.env.VITE_ENV;
	const response = await fetch(
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/addOcrSubmissionToGoogleSheet"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/addOcrSubmissionToGoogleSheet"
				: "http://127.0.0.1:5001/property-echo-4d747/us-central1/addOcrSubmissionToGoogleSheet",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				data: data,
				ocrData: ocrData,
			}),
		},
	);
	if (response.status !== 200) {
		console.log("Error adding submission to Google Sheet");
	}
};
