import { BoundingBoxes } from "@/views/SignedIn/OCRTool/components/BoundingBoxes";
import { Export } from "@/views/SignedIn/OCRTool/components/Export";
import { Metadata } from "@/views/SignedIn/OCRTool/components/Metadata";
import { OCRBBox } from "@/views/SignedIn/OCRTool/components/OCRBBox";
import { Perspective } from "@/views/SignedIn/OCRTool/components/Perspective";
import { Ratio } from "@/views/SignedIn/OCRTool/components/Ratio";
import { Rotate } from "@/views/SignedIn/OCRTool/components/Rotate";
import { UploadImage } from "@/views/SignedIn/OCRTool/components/UploadImage";
import { useState } from "react";
import { Button } from "reactstrap";
import "./ocrTool.css";

const stepsConfig = [
	{ name: "Upload Image", id: "step-upload-image", component: UploadImage },
	{ name: "Rotate image", id: "step-rotate", component: Rotate },
	{
		name: "Correct Perspective",
		id: "step-perspective",
		component: Perspective,
	},
	{ name: "Modify Ratio", id: "step-ratio", component: Ratio },
	{
		name: "Select Bounding Boxes",
		id: "step-bounding-boxes",
		component: BoundingBoxes,
	},
	{
		name: "Select OCR Bounding Box",
		id: "step-ocr-bbox",
		component: OCRBBox,
	},
	{
		name: "Insert template metadata",
		id: "step-metadata",
		component: Metadata,
	},
	{ name: "Config Files", id: "step-export", component: Export },
];

const MultiStepComponentOCRTool = () => {
	const [currentStep, setCurrentStep] = useState(0);
	const [responseData, setResponseData] = useState({
		image: null,
		rotation: 0,
		scale: 0,
		points: null,
		bboxes: [
			{ key: "m_number", x: 915, y: 15, height: 50, width: 150 },
			{ key: "serial_number", x: 915, y: 80, height: 50, width: 150 },
			{ key: "refrigerant_type", x: 915, y: 145, height: 50, width: 150 },
			{
				key: "refrigerant_quantity",
				x: 915,
				y: 210,
				height: 50,
				width: 150,
			},
			{
				key: "refrigerant_quantity_unit",
				x: 915,
				y: 275,
				height: 50,
				width: 150,
			},
			{
				key: "power_supply_voltage",
				x: 915,
				y: 340,
				height: 50,
				width: 150,
			},
			{
				key: "power_supply_frequency",
				x: 915,
				y: 405,
				height: 50,
				width: 150,
			},
			{
				key: "power_supply_amps",
				x: 915,
				y: 470,
				height: 50,
				width: 150,
			},
			{ key: "date", x: 915, y: 535, height: 50, width: 150 },
		],
		ocrBBox: null,
	});

	const getSteps = (currentStepId) => {
		return (
			<div>
				{stepsConfig.map((step, index) => (
					<div
						key={step.id}
						style={{
							padding: "10px",
							margin: "5px",
							border: "1px solid #ccc",
							backgroundColor: step.id === currentStepId ? "#bf1e2d" : "white",
							color: step.id === currentStepId ? "white" : "black",
						}}
					>
						Step {index + 1}: {step.name}
					</div>
				))}
			</div>
		);
	};

	const prevStep = () => {
		setCurrentStep((prev) => (prev > 0 ? prev - 1 : 0));
	};

	const nextStep = () => {
		setCurrentStep((prev) => (prev < stepsConfig.length - 1 ? prev + 1 : prev));
	};

	const CurrentStepComponent = stepsConfig[currentStep].component;

	return (
		<div className="ocr-container">
			<div className="side">
				{getSteps(stepsConfig[currentStep].id)}
				<Button onClick={prevStep}>Previous</Button>
				<Button onClick={nextStep}>Next</Button>
			</div>
			<div className="main">
				<CurrentStepComponent
					responseData={responseData}
					setResponseData={setResponseData}
				/>
			</div>
		</div>
	);
};

export { MultiStepComponentOCRTool };
