import { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Table,
} from "reactstrap";

import { useFormStore, useUserStore } from "@/stores";
import { Loader } from "@SignedIn/Loader/Loader";
import { MdOutlineDelete } from "react-icons/md";

import { NewLocationPicker } from "@/components/layouts/SignedIn/views/SingleForm/NewLocationPicker";
import {
	getAuditedAssets,
	getDecommissionedAssets,
	getUnAuditedAssets,
} from "@/services/asset-service";
import { decommissionAsset } from "@/services/asset-service";
import { getForms } from "@services/form-service";

const AssetDetailsReport = (props) => {
	const expandedTile = props.expandedTile;
	const org = useUserStore((state) => state.authInfo.org);
	const { setAlertModal } = useFormStore();
	const [locsWithAudits, setLocsWithAudits] = useState([]);
	const [locsWithoutAudits, setLocsWithoutAudits] = useState([]);
	const [decommissionedAssets, setDecommissionedAssets] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [tooltip, _setTooltip] = useState(null);
	const [selectedRows, setSelectedRows] = useState(new Set());
	const [view, setView] = useState("pendingAudits");
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [decommissionReason, setDecommissionReason] = useState(null);

	const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
	const toggleModal = () => setModalOpen((prevState) => !prevState);

	const handleRowSelect = (id) => {
		setSelectedRows((prev) => {
			const newSelected = new Set(prev);
			if (newSelected.has(id)) {
				newSelected.delete(id);
			} else {
				newSelected.add(id);
			}
			return newSelected;
		});
	};

	const handleSelectAll = (event) => {
		if (event.target.checked) {
			const allIds = (
				view === "pendingAudits" ? locsWithoutAudits : decommissionedAssets
			).map((loc) => loc.id);
			setSelectedRows(new Set(allIds));
		} else {
			setSelectedRows(new Set());
		}
	};

	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split("T");
			return tempDate[0];
		}
	};

	const getFormId = async () => {
		let formId;
		const snap = await getForms(org, "Inventory & Tagging Form");
		snap.forEach((form) => {
			formId = form.id;
		});
		return formId;
	};

	const [selectedState, setSelectedState] = useState(null); // State to hold the selected location
	console.log(selectedState, "selectedState");
	const _isAdminOrSuperUser = useUserStore((state) => state.isAdminOrSuperUser);
	const authInfo = useUserStore((state) => state.authInfo);
	const _currentUser = authInfo.displayName;

	console.log(authInfo);

	const getLocations = async () => {
		console.log(org, "org");
		try {
			const formId = await getFormId();
			console.log(formId, "formId");
			const auditedSubmissions = await getAuditedAssets(org, selectedState.id);
			const unAuditedSubmissions = await getUnAuditedAssets(
				org,
				selectedState.id,
			);
			const decommissionedAssets = await getDecommissionedAssets(
				org,
				selectedState.id,
			);

			console.log(unAuditedSubmissions, "unAuditedSubmissions");
			console.log(decommissionedAssets, "decommissionedAssets");
			setLocsWithAudits(auditedSubmissions || []);
			setLocsWithoutAudits(unAuditedSubmissions || []);
			setDecommissionedAssets(decommissionedAssets || []);
		} catch (error) {
			console.error("Error fetching locations:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const _getLastMonthNameAndYear = () => {
		const date = new Date();
		date.setMonth(date.getMonth() - 1);
		const lastMonth = date.toLocaleString("default", { month: "long" });
		return `${lastMonth} ${date.getFullYear()}`;
	};
	const handleDecommission = async () => {
		try {
			const selectedDocIds = Array.from(selectedRows);
			console.log(selectedDocIds, "selectedDocIds");

			if (selectedDocIds.length === 0) {
				throw new Error("No assets selected for decommissioning.");
			}

			toggleModal(); // Open the modal for decommission reason input
		} catch (error) {
			console.error("Error decommissioning assets:", error);
			setAlertModal({
				show: true,
				title: "Decommission Failed",
				body: "An error occurred while decommissioning the assets. Please try again.",
			});
		}
	};

	const handleDecommissionConfirm = async () => {
		try {
			const selectedDocIds = Array.from(selectedRows);
			const decommissionPromises = selectedDocIds.map((docId) =>
				decommissionAsset(org, docId, decommissionReason),
			);
			await Promise.all(decommissionPromises);

			setAlertModal({
				show: true,
				title: "Assets Decommissioned",
				body: "Selected assets have been decommissioned successfully.",
			});
			setSelectedRows(new Set());
			setTimeout(() => setAlertModal({ show: false }), 2500);
		} catch (error) {
			console.error("Error decommissioning assets:", error);
			setAlertModal({
				show: true,
				title: "Decommission Failed",
				body: "An error occurred while decommissioning the assets. Please try again.",
			});
		} finally {
			toggleModal(); // Close the modal after handling the decommission
		}
	};

	// const handleDecommission = async () => {
	// 	try {
	// 		setAlertModal({
	// 			show: true,
	// 			title: 'Assets Decommissioned',
	// 			body: 'Please provide us with what happened to the assets?',
	// 			options: [
	// 				{
	// 					text: 'Scrapped',
	// 					action: setDecommissionReason('Scrapped'),
	// 				},
	// 				{ text: 'Sold',
	// 					action: setDecommissionReason('Sold')
	// 				 },
	// 				{ text: 'Storage',
	// 					action: setDecommissionReason('Storage')
	// 				 },
	// 				{ text: 'Unknown',
	// 					action: setDecommissionReason('Unknown')
	// 				 },
	// 			],
	// 		});

	// 		const selectedDocIds = Array.from(selectedRows);
	// 		console.log(selectedDocIds, 'selectedDocIds');

	// 		if (selectedDocIds.length === 0) {
	// 			throw new Error('No assets selected for decommissioning.');
	// 		}

	// 		const decommissionPromises = selectedDocIds.map((docId) =>
	// 			decommissionAsset(org, docId, decommissionReason)
	// 		);
	// 		await Promise.all(decommissionPromises);

	// 		setSelectedRows(new Set());
	// 		setTimeout(() => setAlertModal({ show: false }), 2500);
	// 	} catch (error) {
	// 		console.error('Error decommissioning assets:', error);
	// 		setAlertModal({
	// 			show: true,
	// 			title: 'Decommission Failed',
	// 			body: 'An error occurred while decommissioning the assets. Please try again.',
	// 		});
	// 	}
	// };

	useEffect(() => {
		if (selectedState) {
			getLocations();
		}
	}, [selectedState]);

	const renderTable = (data) => (
		<Table className="table-hover table-striped">
			<thead className="thead-dark">
				<tr>
					{view === "pendingAudits" && (
						<th>
							<input
								type="checkbox"
								onChange={handleSelectAll}
								checked={
									data.length > 0 &&
									data.every((loc) => selectedRows.has(loc.id))
								}
							/>
						</th>
					)}
					<th>Asset Tag</th>
					<th>Asset Type</th>
					<th>Submitting User</th>
					<th>Submitted Date</th>
				</tr>
			</thead>
			<tbody>
				{data.map((loc) => (
					<tr key={loc.id}>
						{view === "pendingAudits" && (
							<td>
								<input
									type="checkbox"
									checked={selectedRows.has(loc.id)}
									onChange={() => handleRowSelect(loc.id)}
								/>
							</td>
						)}
						<td>{loc.other.assetTag}</td>
						<td>{loc.assetType}</td>
						<td>{loc.submittingUser}</td>
						<td>{convertDateToISOString(loc.other.submittedDate)}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);

	return (
		<>
			<Card className="my-3" style={{ overflow: "visible" }}>
				<CardHeader className="">
					<strong>
						Please enter location for which you need to check auditing for
					</strong>
				</CardHeader>
				<CardBody>
					<Label for="locationPicker">Location Name</Label>
					{expandedTile === "assetDetailsReport" && (
						<NewLocationPicker
							setChosenLocation={setSelectedState}
							organization={org}
							setUserLocation={() => {}}
							fromChatbot={false}
							isOpen={false}
							setIsOpen={() => {}}
							onLoadingChange={setIsLoading}
							id="locationPicker"
						/>
					)}
				</CardBody>

				{tooltip && <div className="text-gray fs-6">{tooltip}</div>}
			</Card>
			<div style={{ gap: "20px" }} className="d-flex flex-column flex-lg-row">
				<Card
					className="mt-4 previous-audit-table"
					style={{ overflow: "auto" }}
				>
					<CardHeader className="p-3 d-flex flex-row justify-content-between ">
						<div className="table-title m-0">Assets Audited</div>
					</CardHeader>
					<CardBody>
						{isLoading ? <Loader /> : renderTable(locsWithAudits)}
					</CardBody>
				</Card>
				<Card
					className="mt-4 previous-audit-table"
					style={{ overflow: "auto" }}
				>
					<CardHeader className="p-3 d-flex flex-column flex-md-row justify-content-between">
						<div className="table-title m-0">
							{view === "pendingAudits"
								? "Assets with pending audits"
								: "Decommissioned Assets"}
						</div>
						<div className="d-flex align-items-center">
							<Dropdown
								isOpen={dropdownOpen}
								toggle={toggleDropdown}
								direction="down"
							>
								<DropdownToggle caret className="btn-lg btn-primary">
									{view === "pendingAudits"
										? "Pending Audits"
										: "Decommissioned Assets"}
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-left">
									<DropdownItem onClick={() => setView("pendingAudits")}>
										Pending Audits
									</DropdownItem>
									<DropdownItem onClick={() => setView("decommissioned")}>
										Decommissioned Assets
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							{view === "pendingAudits" && (
								<Button
									onClick={() => {
										setAlertModal({
											show: true,
											title: "Decommission Asset",
											body: "Are you sure you want to decommission this asset?",
											options: [
												{
													text: "Yes",
													action: handleDecommission,
												},
												{
													text: "No",
													action: () => {
														setAlertModal({
															show: false,
															title: "",
															body: "",
														});
													},
												},
											],
										});
									}}
									className="ml-2 btn-lg btn-secondary"
								>
									<MdOutlineDelete />
								</Button>
							)}
						</div>
					</CardHeader>
					<CardBody>
						{isLoading ? (
							<Loader />
						) : view === "pendingAudits" ? (
							renderTable(locsWithoutAudits)
						) : (
							renderTable(decommissionedAssets)
						)}
					</CardBody>
				</Card>
			</div>
			{/* Decommission Reason Modal */}
			<Modal isOpen={modalOpen} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}>Decommission Reason</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label for="decommissionReason">Reason</Label>
						<Input
							type="select"
							id="decommissionReason"
							value={decommissionReason}
							onChange={(e) => setDecommissionReason(e.target.value)}
						>
							<option value="">Select a reason</option>
							<option value="Scrapped">Scrapped</option>
							<option value="Sold">Sold</option>
							<option value="Storage">Storage</option>
							<option value="Unknown">Unknown</option>
						</Input>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						className="submit-user-modal"
						onClick={handleDecommissionConfirm}
						disabled={!decommissionReason}
					>
						Confirm
					</Button>{" "}
					<Button className="btn-secondary" onClick={toggleModal}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export { AssetDetailsReport };
