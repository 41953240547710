import { ListNavigation } from "@components/layouts/SignedIn/ListNavigationCard/ListNavigation";
import { memo } from "react";
import { GeneratedPropIcon } from "./GeneratedPropIcon";
export const ListNavigationAssetProfileCard = memo(
	({ propKey, value, icon, title, generatedProps }) => {
		return (
			<div key={propKey} className="asset-data-card asset-data-half-width-card">
				<div className="asset-data-icon">{icon}</div>
				<div className="asset-data-title">
					{title}
					{generatedProps.includes(propKey) && <GeneratedPropIcon />}
				</div>
				<div className="asset-data-value">
					<ListNavigation key={propKey} items={value} />
				</div>
			</div>
		);
	},
);

ListNavigationAssetProfileCard.displayName = "ListNavigationAssetProfileCard";
