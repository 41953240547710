export const validateAndSetPage6 = (
	organization,
	responseData,
	warrantyDetailsSwarovski,
	setIsPage6Valid,
	fromChatbot = false,
	tempAlertError = false,
) => {
	const isValid =
		organization === "cvs.com" || organization === "DIGI"
			? fromChatbot
				? (!!responseData.tempAlertId ||
						!!responseData.tempAlertIdNotAvailable) &&
					!tempAlertError
				: !!responseData.tempAlertId || !!responseData.tempAlertIdNotAvailable
			: organization === "foodlion.com" || organization === "razoos"
				? !!responseData.assetCondition
				: organization === "swarovski.com"
					? !!warrantyDetailsSwarovski
					: organization === "Smash Burger" ||
							organization === "CARMAX" ||
							organization === "VSM" ||
							organization === "AMD"
						? !!responseData.assetCondition
						: organization === "EcoTrak"
							? !!responseData.assetTypeCategory ||
								!!responseData.assetTagNotAvailable ||
								(!!responseData.idTagAssetImage && !!responseData.assetTag)
							: organization === "Home Depot"
								? true
								: !!responseData.idTagAssetImage && !!responseData.assetTag;

	setIsPage6Valid(isValid);
};
