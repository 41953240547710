import { useUserStore } from "@stores";

const Header = () => {
	const isMobile = window.innerWidth < 768;
	const themeMode = useUserStore((state) => state.themeMode);
	return !isMobile ? (
		<div
			style={{
				// backgroundColor: '#92278f',
				padding: "6px 8px",
				// color: 'white',
				// textAlign: 'center',
				borderRadius: "10px 10px 0 0",
				overflow: "hidden",
				fontSize: "20px",
				fontWeight: "bold",
				color: themeMode === "dark" ? "rgb(210, 206, 200)" : "black",
			}}
		>
			Your Conversation with FacilityAI
		</div>
	) : null;
};

export default Header;
