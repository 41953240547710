// openai-service.js

import { DB_ORG } from "@/constants/db";
import OpenAI, { OpenAIError } from "openai"; // Adjust based on actual exports

/**
 * Initialize the OpenAI client with the new structure.
 *
 * Ensure that the API key is managed securely and not exposed on the client side.
 * Consider moving OpenAI interactions to a backend service for enhanced security.
 */
const openai = new OpenAI({
	apiKey: import.meta.env.VITE_OPENAI_API_KEY, // Ensure this is securely managed
	organization: import.meta.env.VITE_OPENAI_ORG,
	defaultHeaders: {
		// Customize headers if needed
		// Note: Direct removal of 'User-Agent' might not be supported.
		// If necessary, override it by setting it to a custom value.
		// 'User-Agent': 'Your-Custom-Agent/1.0.0',
	},
	// Additional configurations can be added here
	// For example, to set a custom baseURL:
	// baseURL: 'https://api.example.com/v1',
	// To allow browser usage (not recommended for API keys):
	dangerouslyAllowBrowser: true,
});

/**
 * Generates an asset description using OpenAI's Chat Completion API.
 *
 * @param {string} prompt - The prompt to send to the OpenAI API.
 * @returns {Promise<Object>} - The response data from OpenAI.
 */
export const generateAssetDescription = async (prompt: string) => {
	try {
		const response = await openai.chat.completions.create({
			model: "gpt-4o-mini",
			messages: [{ role: "user", content: prompt }],
			max_tokens: 200,
			temperature: 0.2,
			frequency_penalty: 1.5,
			presence_penalty: 1.5,
		});

		return response; // You can process response.data as needed
	} catch (error) {
		if (error instanceof OpenAIError) {
			console.error("OpenAI API Error:", error.message);
			// Handle OpenAI-specific errors here (e.g., rate limits, invalid requests)
		} else {
			console.error("Unexpected Error:", error);
			// Handle other unexpected errors
		}
		throw error; // Re-throw the error after handling
	}
};

/**
 * Determines the asset type from an equipment image URL.
 *
 * @param {string} equipmentImageUrl - The URL of the equipment image.
 * @param {Array} assetTypes - An array of asset type documents.
 * @param {Function} setInputData - Function to set input data.
 * @param {Function} setChosenAssetType - Function to set the chosen asset type.
 * @param {string} org - Organization identifier.
 * @param {Function} setAlertModal - Function to set alert modal state.
 * @param {Function} closeAlertModal - Function to close the alert modal.
 */
export async function getAssetTypeFromEquipmentImage(
	equipmentImageUrl: any,
	assetTypes: any[],
	setInputData: any,
	setChosenAssetType: any,
	org: string,
	setAlertModal: any,
	closeAlertModal: any,
) {
	const timeoutId = setTimeout(closeAlertModal, 3000);
	setAlertModal({
		title: "Reading Equipment Asset Image...",
		body: "Equipment Asset Image Read Processing...",
		show: true,
	});

	const env = import.meta.env.VITE_ENV;
	const assetTypesList = assetTypes.map(
		(type: {
			data: () => { (): any; new (): any; orgName: any; name: any };
		}) => (type.data().orgName ? type.data().orgName : type.data().name || ""),
	);

	try {
		const response = await fetch(
			env === "production"
				? "https://us-central1-property-echo-4d747.cloudfunctions.net/getAssetTypeFromEquipmentImage"
				: env === "staging"
					? "https://us-central1-property-echo-staging.cloudfunctions.net/getAssetTypeFromEquipmentImage"
					: "http://127.0.0.1:5001/property-echo-4d747/us-central1/getAssetTypeFromEquipmentImage",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					equipmentImageUrl,
					assetTypesList,
				}),
			},
		);

		if (!response.ok) {
			setAlertModal({
				title: "Equipment Image Read Failed",
				body: "Unable to determine type of asset. Try again or select the correct asset type at the Asset Details page.",
				show: true,
			});
			return;
		}

		const responsetext = await response.text();
		const assetTypeDoc = assetTypes.find(
			(type: {
				data: () => { (): any; new (): any; orgName: any; name: any };
			}) => {
				const typeName = type.data().orgName || type.data().name;
				return typeName === responsetext;
			},
		);

		if (assetTypeDoc) {
			if (org === DB_ORG.CVS) {
				const assetLocation = assetTypeDoc.data().location;
				setInputData({ key: "assetLocation" }, assetLocation, true);
			}

			setInputData(
				{ key: "assetType" },
				assetTypeDoc.data().orgName || assetTypeDoc.data().name,
			);
			setChosenAssetType(assetTypeDoc);
		}

		clearTimeout(timeoutId);
		if (responsetext) {
			setAlertModal({
				title: "Equipment Image Read Successful",
				body: `This asset has been identified as ${responsetext}. Please confirm at the Asset Details page.`,
				show: true,
			});
		}
		setTimeout(closeAlertModal, 4000);
	} catch (error) {
		console.error("Error fetching asset type:", error);
		setAlertModal({
			title: "Equipment Image Read Failed",
			body: "Unable to determine type of asset. Try again or select the correct asset type at the Asset Details page.",
			show: true,
		});
	}
}

/**
 * Fetches the asset type for a given equipment image URL.
 *
 * @param {string} equipmentImageUrl - The URL of the equipment image.
 * @returns {Promise<string|undefined>} - The asset type or undefined if failed.
 */
export async function getAssetTypeForPrompt(equipmentImageUrl: unknown) {
	try {
		const env = import.meta.env.VITE_ENV;

		const response = await fetch(
			env === "production"
				? "https://us-central1-property-echo-4d747.cloudfunctions.net/getAssetTypeFromEquipmentImage"
				: env === "staging"
					? "https://us-central1-property-echo-staging.cloudfunctions.net/getAssetTypeFromEquipmentImage"
					: "http://127.0.0.1:5001/property-echo-4d747/us-central1/getAssetTypeFromEquipmentImage",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					equipmentImageUrl,
				}),
			},
		);

		if (!response.ok) {
			console.error("Failed to fetch asset type for prompt.");
			return;
		}

		const responsetext = await response.text();
		return responsetext;
	} catch (error) {
		console.error("Error fetching asset type for prompt:", error);
		return;
	}
}

/**
 * Analyzes the condition of an asset from an equipment image URL.
 *
 * @param {string} equipmentImageUrl - The URL of the equipment image.
 * @param {Function} setInputData - Function to set input data.
 * @param {Function} setAlertModal - Function to set alert modal state.
 * @param {Function} closeAlertModal - Function to close the alert modal.
 * @param {Function} setConditionRead - Function to set the condition read state.
 */
export async function getConditionFromEquipmentImage(
	equipmentImageUrl: unknown,
	setInputData: (arg0: { key: string }, arg1: string) => void,
	setAlertModal: (arg0: {
		title: string;
		body: string;
		show: boolean;
	}) => void,
	closeAlertModal: { (...args: any[]): void; (...args: any[]): void },
	setConditionRead: (arg0: string | null) => void,
) {
	const env = import.meta.env.VITE_ENV;
	const timeoutId = setTimeout(closeAlertModal, 3000);
	setAlertModal({
		title: "Analyzing Asset Condition...",
		body: "Condition Analysis Processing...",
		show: true,
	});

	try {
		const response = await fetch(
			env === "production"
				? "https://us-central1-property-echo-4d747.cloudfunctions.net/getConditionFromEquipmentImage"
				: env === "staging"
					? "https://us-central1-property-echo-staging.cloudfunctions.net/getConditionFromEquipmentImage"
					: "http://127.0.0.1:5001/property-echo-4d747/us-central1/getConditionFromEquipmentImage",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					equipmentImageUrl,
				}),
			},
		);

		if (!response.ok) {
			console.log("Condition analysis failed.");
			setAlertModal({
				title: "Condition Analysis Failed",
				body: "We were unable to determine the condition of this asset. Please select the correct condition at the Asset Condition page.",
				show: true,
			});
			return;
		}

		const responsetext = await response.text();

		if (responsetext) {
			setInputData({ key: "assetCondition" }, responsetext);
			setAlertModal({
				title: "Condition Analysis Successful",
				body: `We have determined the condition of this asset is ${responsetext}. Please confirm at the Asset Condition page.`,
				show: true,
			});
		}

		setConditionRead(responsetext ? responsetext : null);
		clearTimeout(timeoutId);
		setTimeout(closeAlertModal, 4000);
	} catch (error) {
		console.error("Error analyzing asset condition:", error);
		setAlertModal({
			title: "Condition Analysis Failed",
			body: "We were unable to determine the condition of this asset. Please select the correct condition at the Asset Condition page.",
			show: true,
		});
	}
}

// ... Add other service functions as needed
