import type { SelfOrganization } from "@/hooks/organizationQueries";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { type UseBoundStore, create } from "zustand";

export enum UserAccessLevel {
	USER = 100,
	VERIZON = 150,
	ADMIN = 500,
	SUPER_USER = 1000,
}

export const useUserStore: UseBoundStore<any> = create((set: any) => ({
	authInfo: { loggedIn: false },
	setAuthInfo: (authInfo: any) => set({ authInfo }),
	user: {},
	setUser: (user: any) => set({ user }),
	setUserFilters: (filters: any, table: any) =>
		set((state: { user: { savedFilters: any } }) => ({
			user: {
				...state.user,
				savedFilters: table
					? { ...state.user.savedFilters, [table]: filters }
					: filters,
			},
		})),
	userOrganization: null,
	setUserOrganization: (userOrganization: SelfOrganization) =>
		set({ userOrganization }),
	userOrganizationForms: null,
	setUserOrganizationForms: (userOrganizationForms: any) =>
		set({ userOrganizationForms }),
	userOrganizationReports: null,
	setUserOrganizationReports: (userOrganizationReports: any) =>
		set({ userOrganizationReports }),
	userOrganizationGraphs: null,
	setUserOrganizationGraphs: (userOrganizationGraphs: any) =>
		set({ userOrganizationGraphs }),
	userAllowedOrganizations: {},
	setUserAllowedOrganizations: (userAllowedOrganizations: any) =>
		set({ userAllowedOrganizations }),
	isAdminOrSuperUser: false,
	setIsAdminOrSuperUser: (isAdminOrSuperUser: any) =>
		set({ isAdminOrSuperUser }),
	isVerizonUser: false,
	setIsVerizonUser: (isVerizonUser: any) => set({ isVerizonUser }),
	canViewVendors: false,
	setCanViewVendors: (canViewVendors: any) => set({ canViewVendors }),
	themeMode: "light",
	setThemeMode: (themeMode: string) =>
		set((state: { authInfo: { loggedIn: any } }) => {
			const htmlElement = document.querySelector("html");
			htmlElement?.classList.remove("dark-mode");
			if (!state.authInfo.loggedIn) {
				return { themeMode: "light" };
			}
			if (themeMode === "dark") {
				htmlElement?.classList.add("dark-mode");
			}
			return { themeMode };
		}),
}));

if (process.env.NODE_ENV === "development") {
	mountStoreDevtool("userStore", useUserStore);
}
