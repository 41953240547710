import { DB_ORG } from "@/constants/db";
import { useUserStore } from "@/stores";
import { Select } from "antd";

interface QASubmissionDraftSelectProps {
	state: {
		isLoading: boolean;
		selectedFormMode: string;
	};
	dispatch: ({ type, payload }: { type: string; payload: any }) => void;
}

const QASubmissionDraftSelect = (props: QASubmissionDraftSelectProps) => {
	const { isLoading, selectedFormMode } = props.state;
	const { dispatch } = props;
	const user = useUserStore((state) => state.user);
	const organization = user?.organization?.external_firebase_id;

	const handleChange = (value) => {
		dispatch({ type: "SET_PAGE_NUM", payload: 1 });
		dispatch({ type: "SET_SELECTED_FORM_MODE", payload: value });
		dispatch({ type: "SET_IS_VIEWING_QA_FORM", payload: value === "1" });
		if (value === "0" || value === "1") {
			dispatch({ type: "SET_VIEWING_DRAFTS", payload: false });
		}
		if (value === "2") {
			dispatch({ type: "SET_VIEWING_DRAFTS", payload: true });
		}
	};

	let options = [
		{ value: "0", label: "Submissions" },
		{ value: "1", label: "QA Submissions" },
		{ value: "2", label: "Drafts" },
	];

	// remove qa submisions option if not cushman wakefield
	if (organization == DB_ORG.CUSHMANWAKEFIELD) {
		options = options.filter((option) => option.label !== "QA Submissions");
	}

	return (
		<Select
			style={{ width: 200 }}
			onChange={handleChange}
			defaultValue={
				selectedFormMode
					? options.find((form) => form.value == selectedFormMode)
					: options[0]
			}
			options={options}
			placeholder={isLoading ? "Loading Forms..." : "Select a form"}
			disabled={isLoading}
		/>
	);
};

export { QASubmissionDraftSelect };
