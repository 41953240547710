import { auth } from "@assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@constants/env";

export const MESSAGE_EVENTS = {
	END: "end_event",
	MESSAGE: "message_event",
	GRAPH: "graph_event",
};

export const sendMessage = async (
	message: any,
	location?: string,
	classifier?: string,
) => {
	try {
		const token = await auth?.currentUser?.getIdToken();

		const response = await fetch(ALFRED_SERVICE_URL, {
			method: "POST",
			body: JSON.stringify({
				query: message,
				location:
					location && typeof location === "string"
						? location?.toLowerCase()
						: "",
				classifier,
			}),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});

		if (!response.ok) {
			// Handle HTTP errors
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		return response;
	} catch (error) {
		console.error("Error in sendMessage:", error);
		// Depending on your error handling strategy, you might want to rethrow the error or handle it differently
		throw error;
	}
};

export const sendFile = async (file: string | Blob) => {
	const formData = new FormData();
	formData.append("file", file);

	return fetch(`${ALFRED_SERVICE_URL}/file`, {
		method: "POST",
		body: formData,
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});
};

export const priceSearch = async (
	assetType: any,
	manufacturer: any,
	model: any,
) =>
	fetch(
		`${ALFRED_SERVICE_URL}/search?asset_type=${assetType}&manufacturer=${manufacturer}&model=${model}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		},
	);

export const getMessages = (id: string) => {
	const eventSource = new EventSource(`${ALFRED_SERVICE_URL}/result?id=${id}`);

	eventSource.addEventListener(MESSAGE_EVENTS.END, () => {
		eventSource.close();
	});

	return eventSource;
};

/**
 * Sends a request to the /FacilityVision/Predict/AssetType backend endpoint which will predict asset types
 * @param url firebase url of asset to predict
 * @param org
 * @returns {Promise<{conf: *, predictions: *|[*]}>}
 */
export const assetPredict = async (url: any, org: any) => {
	const body = JSON.stringify({
		asset_url: url,
		org: org,
	});
	return fetch(`${ALFRED_SERVICE_URL}/FacilityVision/Predict/AssetType`, {
		method: "POST",
		body: body,
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			"Content-Type": "application/json",
		},
	})
		.then((response) => {
			if (response.status === 200) {
				return response.json();
			}
			if (response.status === 403) {
				throw new Error("Current Org Does Not Support Asset Auto Prediction");
			}
			throw new Error("Failed to generate suggested assets");
		})
		.then((parsed_data) => {
			return {
				predictions: parsed_data.details.asset_group
					? parsed_data.details.potential_assets
					: [parsed_data.details.prediction],
				conf: parsed_data.details.conf,
			};
		})
		.catch((e) => {
			throw e; // Re-throw error
		});
};

/**
 * This method will compare the users asset type prediction to a ml model
 * @param asset_url firebase url of asset to predict
 * @param asset_id
 * @param org
 * @param prediction the user's selected input for the asset type
 * @returns {Promise<{conf: *, predictions: *|[*]}>}
 */
export const assetTypeCompareUserToAI = async (
	asset_url: any,
	asset_id: any,
	org: any,
	prediction: any,
) => {
	fetch(`${ALFRED_SERVICE_URL}/FacilityVision/Predict/AssetTypeCompare`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			asset_url: asset_url,
			asset_id: asset_id,
			org: org,
			user_prediction: prediction,
		}),
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error(`Network response was not ok ${response.statusText}`);
			}
			return response.json();
		})
		.then(() => {
			console.log("Asset Shadow Mode Complete"); // Handle the data from the response
		})
		.catch((error) => {
			console.error("There was a problem with the fetch operation: ", error);
		});
};

/**
 * Fetches the orientation data of an ice maker (left, right). If machine is on right, it also returns info on machine on left
 * THIS IS ONLY FOR RASINGCANES ICE MACHINES LOCATED AT BOH or Kitchen/Refrigeration
 * @param asset_id the asset id (also known as asset tag)
 * @returns {Promise<{conf: *, predictions: *|[*]}>}
 */
export const getIceMakerOrientation = async (asset_id: any) => {
	return fetch(
		`${ALFRED_SERVICE_URL}/FacilityVision/Predict/IceMakerOrientation?asset_tag=${asset_id}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		},
	)
		.then((response) => {
			if (response.status === 200) {
				return response.json();
			}
			if (response.status === 403) {
				throw new Error("Current Org Does Not Support Asset Auto Prediction");
			}
			throw new Error("Failed to find ice maker orientation");
		})
		.then((parsed_data) => {
			return parsed_data;
		})
		.catch((e) => {
			throw e; // Re-throw error
		});
};
