import {
	isMasterAssetType,
	savePotentialMasterAsset,
} from "@services/asset-service";
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleAssetIdPrompt = async (response, setState) => {
	const userMessage = createClientMessage("Identify an asset");
	const loading = createChatBotMessage("", {
		withAvatar: true,
	});
	setState((state) => ({
		...state,
		messages: [...state.messages, userMessage, loading],
	}));

	const result = await response;
	if (!result.name || result.name === "null") {
		const res = createChatBotMessage(
			"I'm sorry, I was not able to identify the asset. Please try again.",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					question: undefined,
					capturePhoto: () => {},
				},
			},
		);

		setState((state) => ({
			...state,
			messages: [...state.messages.slice(0, -1), res],
			showOptions: true,
			options: ["Upload photo"],
			stage: "assetIdPrompt",
		}));
		return;
	}
	if (typeof result.name === "string") {
		const resultName = JSON.parse(result.name);
		const res = createChatBotMessage(
			`This appears to be a ${resultName.prediction}.`,
			{
				withAvatar: true,
			},
		);
		const isInDb = await isMasterAssetType(resultName.prediction);
		if (!isInDb) {
			try {
				await savePotentialMasterAsset({
					name: resultName.prediction,
					needsReview: true,
					assetPicture: result.assetPicture,
				});
			} catch (error) {
				console.log(error);
			}

			const message = createChatBotMessage("Identify another asset?", {
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					question: undefined,
					capturePhoto: () => {},
				},
			});

			setState((state) => ({
				...state,
				messages: [...state.messages.slice(0, -1), res, message],
				showOptions: true,
				options: ["Upload photo"],
				stage: "assetIdPrompt",
			}));
			return;
		}
		const message = createChatBotMessage("Identify another asset?", {
			widget: "OptionalSelectors",
			withAvatar: true,
			payload: {
				topThreeLocations: [],
				question: undefined,
				capturePhoto: () => {},
			},
		});

		setState((state) => ({
			...state,
			messages: [...state.messages.slice(0, -1), res, message],
			showOptions: true,
			options: ["Upload photo"],
			stage: "assetIdPrompt",
		}));
	} else {
		const res = createChatBotMessage(
			`This appears to be a ${result?.name?.predictions[0]} (${(
				result?.name?.conf * 100
			).toFixed(2)}% confidence)`,
			{
				withAvatar: true,
			},
		);

		const message = createChatBotMessage("Identify another asset?", {
			widget: "OptionalSelectors",
			withAvatar: true,
			payload: {
				topThreeLocations: [],
				question: undefined,
				capturePhoto: () => {},
			},
		});

		setState((state) => ({
			...state,
			messages: [...state.messages.slice(0, -1), res, message],
			showOptions: true,
			options: ["Upload photo"],
			stage: "assetIdPrompt",
		}));
	}
};
