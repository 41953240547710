import { CushmanImageOptions } from "@components/layouts/SignedIn/views/SingleForm/CushmanImageOptions";
import { LocationPicker } from "@components/layouts/SignedIn/views/SingleForm/LocationPicker";
import { DB_ORG } from "@constants/db";
import { default as initInputs } from "@constants/formInputs/handymanInputs";
import { createDraft, saveProgress } from "@services/draft-service";
import { getFormData } from "@services/form-service";
import { getLocationsArr } from "@services/location-service";
import { useFormStore, useUserStore } from "@stores";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDraftsByUser } from "@/hooks/draftQueries";
import { useGetSelfOrganization } from "@/hooks/organizationQueries";
import { debounce } from "@/utils/debounce";
import { DraftSelectionModal } from "@/views/SignedIn/SingleForm/DraftSelectionModal";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { LoadingModal } from "@components/layouts/SignedIn/LoadingModal/LoadingModal";
import { CropperModal } from "@components/layouts/SignedIn/views/SingleForm/CropperModal";
import { SubmittedImageModal } from "@components/layouts/SignedIn/views/SingleForm/SubmittedImageModal";
import {
	useAddCushmanSubmission,
	useUpdateCushmanSubmission,
} from "@hooks/submissionQueries";
import { uploadFieldCaptureFromDataUrl } from "@services/storage-service";
import { deepCopy } from "@utils/deepCopy";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";

const SingleFormCushman = () => {
	// Initial functions, variables, etc.
	const navigate = useNavigate();
	const location = useLocation();
	const authInfo = useUserStore((state) => state.authInfo);
	const { id, org } = useParams();
	const formId = location.state?.formId || id || null;
	const organization = org || authInfo.org;
	const {
		setShowDraftSelectionModalCushman,
		setStartNewCushman,
		startNewCushman,
		isEditingCushman,
		setIsEditingCushman,
		setSavedLocationCushman,
		savedLocationCushman,
	} = useFormStore();
	const {
		userOrganization,
		error: userOrganizationError,
		isLoading: isLoadingUserOrganization,
	} = useGetSelfOrganization();
	const isMobile = window.innerWidth < 768;
	// Array used for 'actionTaken' input, which is type=select (therefore, needs options)
	// const actions = ['No Action Needed', 'Repair Performed', 'Ticket Needed'];
	const [actions, setActions] = useState([]);
	const addCushmanSubmission = useAddCushmanSubmission();
	const updateCushmanSubmission = useUpdateCushmanSubmission();

	// States used for form attributes, inputs, etc.
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [inputs, setInputs] = useState(() => {
		if (location.state?.inputs) {
			return location.state.inputs;
		}
		return deepCopy(initInputs);
	});
	const [locations, setLocations] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentSection, setCurrentSection] = useState(0);
	const [submitDisabled, setSubmitDisabled] = useState(false);

	// States used for various image modals
	const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
	const [isPhotoShowing, setIsPhotoShowing] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isCropShowing, setIsCropShowing] = useState(false);
	const [photoToBeCropped, setPhotoToBeCropped] = useState(null);

	// State used for location selection (when location services is enabled)
	const [userLocation, setUserLocation] = useState(
		location.state?.userLocation || {},
	);

	// States for drafts
	const [shouldAutoSave, setShouldAutoSave] = useState();

	const { draftId, setDraftId } = useFormStore();

	// Variable used when updating a submission being edited
	const submissionId = location.state?.submissionId;
	const originalLoc = savedLocationCushman || location.state?.savedLocation;
	// Variable used to track url path for navigating away from edited submission
	const originalPath = location.state?.path;

	// State used for other modal(s)
	const [alertModal, setAlertModal] = useState({
		show: false,
		title: "",
		body: "",
	});

	// States for init loading animation & loader modal (e.g., when cropping photos)
	const [isLoading, setIsLoading] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	const [todaysDrafts, setTodaysDrafts] = useState([]);

	const { data: drafts } = useGetDraftsByUser(
		DB_ORG.CUSHMANWAKEFIELD,
		formId,
		authInfo.id,
		submissionId,
	);

	const fromDraftsTable = location.state?.fromDraftsTable || false;

	useEffect(() => {
		if (!drafts || drafts?.length === 0) {
			setShowDraftSelectionModalCushman(false);
			setStartNewCushman(false);
		} else {
			setTodaysDrafts(drafts);
			if (
				drafts.length > 0 &&
				currentPage === 0 &&
				!startNewCushman &&
				!isEditingCushman &&
				!fromDraftsTable
			) {
				setShowDraftSelectionModalCushman(true);
			} else {
				setShowDraftSelectionModalCushman(false);
			}
		}
	}, [drafts]);

	useEffect(() => {
		if (location?.state) {
			if (location.state.isEditing) {
				setIsEditingCushman(location.state.isEditing);
			}
			if (location.state.savedLocation) {
				setSavedLocationCushman(location.state.savedLocation);
			}
			if (location.state.submissionId) {
				setDraftId(location.state.submissionId);
			}
			if (location.state.draftId) {
				setDraftId(location.state.draftId);
			}
		}
	}, [location.state]);

	//  Update locations, shouldAutoSave, formId, & formLogo states upon init render
	useEffect(() => {
		getLocationsArr(organization, setLocations);
		if (isEditingCushman) {
			setShouldAutoSave(false);
		} else {
			setShouldAutoSave(userOrganization?.config.auto_save_drafts);
		}
		getFormData(organization, formId, setFormName, setFormLogo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update isLoading state when locations, shouldAutoSave, formName, & formLogo states are all not undefined
	useEffect(() => {
		if (
			locations.length > 0 &&
			shouldAutoSave !== undefined &&
			formName &&
			formLogo
		) {
			const allFormOptions = {
				"QC Checklist": ["Ticket Needed", "Ticket Validated", "Recall Work"],
				"Pre-Walk Checklist": ["", "Will Repair on PM", "Ticket Needed"],
				Default: ["No Action Needed", "Repair Performed", "Ticket Needed"],
			};
			const formOptions = allFormOptions[formName] || allFormOptions.Default;
			setActions(formOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locations, shouldAutoSave, formName, formLogo]);

	// Update isLoading state when locations, shouldAutoSave, formName, & formLogo states are all not undefined
	useEffect(() => {
		if (actions.length > 0) {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actions]);

	// Update setIsCropShowing (ie, show cropper modal) if photoToBeCropped state != undefined
	useEffect(() => {
		if (photoToBeCropped) setIsCropShowing(true);
	}, [photoToBeCropped]);

	const saveProgressDebounced = useCallback(
		debounce((params) => {
			saveProgress(...params);
		}, 1500),
		[],
	);

	// Update draft state when inputs changes, only if shouldAutoSave is on & the user selects a dateOfVisit & location
	useEffect(() => {
		if (
			shouldAutoSave &&
			inputs[0].sections[0].questions[0].value &&
			savedLocationCushman &&
			inputs[0].sections[0].questions[1].value?.length > 0 &&
			!isEditingCushman
		) {
			saveProgressDebounced([
				formId,
				draftId,
				setDraftId,
				userLocation,
				savedLocationCushman,
				inputs,
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	// Fails submission
	const failSubmission = (body) => {
		setAlertModal({
			title: "Missing Fields",
			body: body,
			show: true,
		});

		const timerId = setTimeout(closeAlertModal, 3000);
		setSubmitDisabled(false);
		return clearTimeout(timerId);
	};

	// Submits the form submission to the database
	const submit = async (enterNew = false) => {
		if (submitDisabled) return;
		await setSubmitDisabled(true);
		// Checks that a location is selected
		if (inputs[0].sections[0].questions[0].value === null) {
			failSubmission("Please select a location (on page 1).");
			return;
		}
		// Checks that a dateOfVisit is entered
		if (!inputs[0].sections[0].questions[1].value?.length > 0) {
			failSubmission("Please enter the date of visit (on page 1).");
			return;
		}
		// Check that a summary is entered
		if (!inputs[2].sections[0].questions[0].value?.length > 0) {
			failSubmission("Please enter a summary (on page 3).");
			return;
		}

		if (formName === "Pre-Walk Checklist") {
			const labels = [];
			let allQuestionsHaveImages = true;

			inputs.forEach((page) => {
				page.sections.forEach((section) => {
					section.questions.forEach((question) => {
						if (Array.isArray(question)) {
							question.forEach((subQuestion) => {
								// Skip subQuestions with no action taken
								if (
									!subQuestion.actionTaken ||
									subQuestion.actionTaken === "No Action Needed"
								) {
									return;
								}
								const hasImage =
									(subQuestion.actionTaken === "Will Repair on PM" ||
										subQuestion.actionTaken === "Ticket Needed") &&
									subQuestion.imageBefore;
								if (!hasImage) {
									labels.push(subQuestion.label); // Collect label of sub-question missing image
									allQuestionsHaveImages = false;
								}
							});
						} else {
							// Skip questions with no action taken
							if (
								!question.actionTaken ||
								question.actionTaken === "No Action Needed"
							) {
								return;
							}
							const hasImage =
								(question.actionTaken === "Will Repair on PM" ||
									question.actionTaken === "Ticket Needed") &&
								question.imageBefore;
							if (!hasImage) {
								labels.push(question.label); // Collect label of question missing image
								allQuestionsHaveImages = false;
							}
						}
					});
				});
			});

			// Display all labels of questions missing images to the end user
			if (!allQuestionsHaveImages) {
				failSubmission(`Missing required images for:\n${labels.join("\n")}`);
				return;
			}
		}

		// If editing submission, update it in the database
		// Otherwise, add new submission to the database
		if (isEditingCushman) {
			await updateCushmanSubmission.mutate({
				draftId,
				inputs,
				userLocation,
			});
		} else {
			if (!draftId) {
				const body = {
					data: inputs,
					form_id: formId,
					location_id: savedLocationCushman.id,
				};
				const draft_id = await createDraft(body);
				await addCushmanSubmission.mutate({
					draft_id,
					inputs,
					userLocation,
				});
			} else {
				await addCushmanSubmission.mutate({
					draftId,
					inputs,
					userLocation,
				});
			}
		}
		// Reset all states
		const location_id = inputs[0].sections[0].questions[0].value;
		const newInputs = deepCopy(initInputs);
		newInputs[0].sections[0].questions[0].value = location_id;
		setInputs(newInputs);
		setCurrentPage(0);
		setCurrentSection(0);
		setSelectedPhotoUrl(null);
		setIsPhotoShowing(false);
		setSelectedItem(null);
		setIsCropShowing(false);
		setPhotoToBeCropped(null);
		setSavedLocationCushman(null);
		setUserLocation({});
		await setSubmitDisabled(false);
		// If editing submission, navigate back to submission page
		// Else, if user hit "Submit", navigate to submissions table of location
		if (isEditingCushman) {
			navigate(originalPath, {
				state: {
					data: location_id,
					id: location_id,
					tab: "Submissions",
					submissionId: submissionId,
				},
			});
		} else if (!enterNew) {
			navigate(`/locations/${location_id}`, {
				state: {
					data: location_id,
					id: location_id,
					tab: "Submissions",
				},
			});
		}
		return;
	};

	// Generates the page headers AKA the nav items for the form
	const generatePageHeaders = () => {
		return inputs.map((page, i) => {
			return (
				<NavItem key={i}>
					<NavLink
						className={currentPage === i ? "active" : "inactive"}
						onClick={() => {
							setCurrentPage(i);
							setCurrentSection(0);
						}}
					>
						<span style={{ fontWeight: "bolder" }}>
							<Label className="single-form-label">{page.title}</Label>
						</span>
					</NavLink>
				</NavItem>
			);
		});
	};

	// Generates the sections of each page AKA the accordion items
	const generateSections = () => {
		const items = inputs[currentPage].sections.map((section, i) => {
			return (
				<AccordionItem key={`accordion-item-${i}`}>
					<AccordionHeader
						key={`accordion-header-${i}`}
						targetId={i}
						onClick={() => {
							setCurrentSection(currentSection === i ? null : i);
						}}
					>
						{section.title}
					</AccordionHeader>
					<AccordionBody accordionId={i}>
						{generateQuestions(section, i)}
					</AccordionBody>
				</AccordionItem>
			);
		});
		const toggle = (id) => {
			setCurrentSection(id === currentSection ? null : currentSection);
		};
		return (
			<Accordion open={currentSection} toggle={toggle}>
				{items}
			</Accordion>
		);
	};

	// Generates a date in MM/DD/YYYY format when given one from ReactDatePicker
	const formatDate = (e) => {
		const date = new Date(e);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${month}/${day}/${year}`;
	};

	// Generates the questions AKA form inputs found in each section AKA accordion item
	const generateQuestions = (section, i) => {
		return section.questions.map((question, j) => {
			// The condition generates the 4 inputs needed for each Cushman item
			// ie, description, action taken, image before, image after
			if (Array.isArray(question)) {
				return generateSubArrayQuestions(i, question, j).flat();
			}
			if (question.key === "location") {
				return (
					<Row key="location">
						<LocationPicker
							locations={locations}
							chosenLocation={
								inputs[0].sections[0].questions[0].value &&
								savedLocationCushman?.data
									? savedLocationCushman.data.name
									: "Select a Location"
							}
							setChosenLocation={(e) => {
								const tempInputs = deepCopy(inputs);
								tempInputs[0].sections[0].questions[0].value = e.id;
								setInputs(tempInputs);
								setSavedLocationCushman(e);
							}}
							setUserLocation={setUserLocation}
							organization={organization}
						/>
					</Row>
				);
			}
			if (question.key === "dateOfVisit") {
				return (
					<Row key="dateOfVisit">
						<FormGroup className={isMobile ? "col-12" : "col-6"}>
							<Label className="single-form-label">
								{question.label}
								<span style={{ color: "red" }}> *</span>
							</Label>
							<br />
							<div className="d-flex">
								<DatePicker
									showIcon={true}
									showPopperArrow={false}
									key={question.key}
									id={id}
									onChange={(e) => {
										const date = formatDate(e);
										const tempInputs = deepCopy(inputs);
										tempInputs[0].sections[0].questions[1].value = date;
										setInputs(tempInputs);
									}}
									popperPlacement="top-start"
									className="form-inputs form-control"
									selected={
										inputs[0].sections[0].questions[1].value
											? new Date(inputs[0].sections[0].questions[1].value)
											: null
									}
									placeholderText="Select the date of visit"
									minDate={null}
									maxDate={null}
								/>
								<AiOutlineCalendar
									className={`date-picker-icon-${
										currentPage === 0 && currentSection === 0
									}`}
								/>
							</div>
						</FormGroup>
					</Row>
				);
			}
			if (question.key === "summary") {
				return (
					<Row key="summar">
						<FormGroup className={isMobile ? "col-12" : "col-6"}>
							<Label className="single-form-label">
								{question.label}
								<span style={{ color: "red" }}> *</span>
							</Label>
							<br />
							<Input
								type="textarea"
								onChange={(e) => {
									const tempInputs = deepCopy(inputs);
									tempInputs[2].sections[0].questions[0].value = e.target.value;
									setInputs(tempInputs);
								}}
								className="text-area"
								value={inputs[2].sections[0].questions[0].value}
								placeholder="Enter a summary for this visit."
							/>
						</FormGroup>
					</Row>
				);
			}
		});
	};

	// Validates an "item" (ie, a question with a descr, actionTaken, & images) in the form
	const validateItem = async (item) => {
		if (formName !== "QC Checklist") {
			// If the descr val exists but the actionTaken val doesnt,
			// set the actionTaken key to 'No Action Needed' by default
			if (item.description?.length > 0 && !item.actionTaken) {
				item.actionTaken = "No Action Needed";
			}
			// If the descr val doesn't exist but the actionTaken val does,
			// set the actionTaken key back to 'null' by default
			else if (
				!item.description?.length > 0 &&
				item.actionTaken === "No Action Needed"
			) {
				item.actionTaken = null;
			}
		}
	};

	// Generates the individual items from the questions array from inputs
	// Uses the section index (i), the parent question (which is an array), and parent question's index inside the questions of the section
	const generateSubArrayQuestions = (i, questionArr, j) => {
		return questionArr.map((question, k) => {
			const actionTaken =
				inputs[currentPage].sections[i].questions[j][k].actionTaken;
			return (
				<span key={`section-${i}-question-array-${j}-question-${k}`}>
					<Row
						className={`d-flex flex-nowrap align-items-end ${
							isMobile ? "flex-column" : "flex-row"
						}`}
					>
						{/* Description */}
						<Col className={isMobile ? "col-12" : "col-6"}>
							<FormGroup>
								<Label className="single-form-label">{question.label}</Label>
								<Input
									type="text"
									onChange={(e) => {
										const tempInputs = deepCopy(inputs);
										const itemRef =
											tempInputs[currentPage].sections[i].questions[j][k];
										// If the descr input exists, add it to the descr key
										// Otherwise, reset key back to 'null'
										if (e.target.value?.length > 0) {
											itemRef.description = e.target.value;
										} else {
											itemRef.description = null;
										}
										validateItem(itemRef);
										setInputs(tempInputs);
									}}
									value={question.description || ""}
									className="form-inputs"
									placeholder="Repair Description"
								/>
							</FormGroup>
						</Col>
						{/* Action Taken */}
						<Col className={isMobile ? "col-12" : "col-6"}>
							<FormGroup>
								<Label className="single-form-label">Action Taken</Label>
								<Input
									type="select"
									onChange={(e) => {
										const tempInputs = deepCopy(inputs);
										const itemRef =
											tempInputs[currentPage].sections[i].questions[j][k];
										itemRef.actionTaken = e.target.value;
										validateItem(itemRef);
										setInputs(tempInputs);
									}}
									className="form-inputs"
								>
									{formName === "QC Checklist" ? (
										<option key="n/a" selected={question.actionTaken == null} />
									) : null}
									{actions.map((option) => {
										return (
											<option
												key={option}
												default={
													option === "No Action Needed" &&
													formName !== "QC Checklist"
												}
												selected={question.actionTaken === option}
												value={option}
											>
												{option}
											</option>
										);
									})}
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex justify-content-end">
							{/* Image Before & After */}
							{(actionTaken && formName === "QC Checklist") ||
							(actionTaken &&
								actionTaken !== "No Action Needed" &&
								formName !== "QC Checklist") ? (
								<CushmanImageOptions
									pageIndex={currentPage}
									sectionIndex={i}
									questionIndex={j}
									questionArrIndex={k}
									inputs={inputs}
									addPhotoToInputs={addPhotoToInputs}
									setSelectedPhotoUrl={setSelectedPhotoUrl}
									setSelectedItem={setSelectedItem}
									setIsPhotoShowing={setIsPhotoShowing}
									formName={formName}
								/>
							) : null}
							{/* Option to Duplicate (or Remove) Entire Question */}
							<div>
								<GrAddCircle
									hidden={k !== 0}
									onClick={() => {
										const tempInputs = deepCopy(inputs);
										tempInputs[currentPage].sections[i].questions[j].push({
											label: question.label,
											key: question.key,
											description: undefined,
											actionTaken: undefined,
											imageBefore: undefined,
											imageAfter: undefined,
										});
										setInputs(tempInputs);
									}}
									style={{
										marginLeft: "15px",
										cursor: "pointer",
									}}
								/>
								<GrSubtractCircle
									hidden={k === 0}
									onClick={() => {
										const tempInputs = deepCopy(inputs);
										tempInputs[currentPage].sections[i].questions[j].splice(k);
										setInputs(tempInputs);
									}}
									style={{
										marginLeft: "15px",
										cursor: "pointer",
									}}
								/>
							</div>
						</Col>
					</Row>
				</span>
			);
		});
	};

	// Generates image url from photo upload/capture and adds it to the inputs as needed
	const addPhotoToInputs = (
		e,
		pageIndex,
		sectionIndex,
		questionIndex,
		questionArrIndex,
		key,
	) => {
		// Stop function if no files or individual file
		if (!e.target.files) {
			setShowLoader(false);
			return;
		}
		if (e.target.files.length === 0) {
			setShowLoader(false);
			return;
		}
		const file = e.target.files[0];
		// Display loader
		setShowLoader(true);
		// Stop function & loader, then display error, if user uploads non-image file
		if (!file.type.includes("image")) {
			setShowLoader(false);
			setAlertModal({
				title: "Missing Fields",
				body: "Please only import an image",
				show: true,
			});
			const timerId = setTimeout(closeAlertModal, 3000);
			return clearTimeout(timerId);
		}
		// Init FileReader (to get image source for formatting & upload)
		const reader = new FileReader();
		reader.onload = (e) => {
			// Generate image
			const img = new Image();
			img.src = e.target.result;
			img.onload = async () => {
				// Calculate the height to maintain aspect ratio
				const scale = 1200 / img.width;
				const height = img.height * scale;
				// Create a canvas and draw the image on it
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);
				// Get the data URL in PNG format
				const dataUrl = canvas.toDataURL("image/png");
				// Upload image to storage and retrieve download URL
				const url = await uploadFieldCaptureFromDataUrl(dataUrl);
				// Update inputs & hide loader
				const tempInputs = deepCopy(inputs);
				tempInputs[pageIndex].sections[sectionIndex].questions[questionIndex][
					questionArrIndex
				][key] = url;
				setInputs(tempInputs);
				setShowLoader(false);
			};
		};
		// Trigger image formatting & uploading process above, starting with `const reader = new FileReader()`
		reader.readAsDataURL(file);
	};

	const getCroppedPhoto = async (dataUrl) => {
		setIsCropShowing(false);
		const url = await uploadFieldCaptureFromDataUrl(dataUrl);
		// Update inputs with cropped photo
		const tempInputs = deepCopy(inputs);
		const obj = selectedItem;
		tempInputs[obj.pageIndex].sections[obj.sectionIndex].questions[
			obj.questionIndex
		][obj.questionArrIndex][obj.key] = url;
		setInputs(tempInputs);
		setPhotoToBeCropped(null);
	};

	// Closes any alert modal
	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	return isLoading || isLoadingUserOrganization !== false ? (
		<Loader />
	) : (
		<div>
			<Card className="px-2 pt-4 some-drop-shadow">
				<span className="logo-container d-flex">
					<img
						src={formLogo}
						className="form-logo"
						hidden={!formLogo}
						alt="logo"
					/>
				</span>
				<CardHeader
					style={{
						backgroundColor: "#ffffff",
						border: "none",
						textAlign: "center",
					}}
				>
					{isEditingCushman ? (
						<div
							className="activities-page-header-buttons d-flex p-2 width-fit-content"
							onClick={() =>
								navigate(originalPath, {
									state: {
										data: originalLoc.data,
										id: originalLoc.id,
										tab: "Submissions",
										submissionId: submissionId,
										formId: formId,
									},
								})
							}
						>
							<i className="bi bi-arrow-left me-3"></i>
							<div>Go Back</div>
						</div>
					) : null}
					<span className="form-title">{formName}</span>
				</CardHeader>
				<Form className="" onSubmit={(e) => e.preventDefault()}>
					<div>
						{/* { Nav section for page headers } */}
						<Nav tabs className="form">
							{generatePageHeaders()}
						</Nav>
						{/* { Tab section for page content } */}
						<TabContent
							activeTab={(currentPage + 1).toString()}
							className="fullSize"
						>
							{generateSections()}
						</TabContent>
					</div>
					<div className="form-btns-submit">
						<Row>
							<Button
								color="secondary"
								className="submit-btn-form"
								onClick={() => submit(false)}
								disabled={submitDisabled}
							>
								{!submitDisabled && isEditingCushman
									? "Save Changes"
									: submitDisabled && isEditingCushman
										? "Saving......"
										: !submitDisabled
											? "Submit"
											: "Submitting..."}
							</Button>
						</Row>
						<Row>
							<Button
								color="secondary"
								className="submit-btn-form"
								onClick={() => submit(true)}
								hidden={isEditingCushman}
								disabled={submitDisabled}
							>
								{!submitDisabled ? "Submit & Enter New" : "Submitting..."}
							</Button>
						</Row>
					</div>
				</Form>
			</Card>
			<SubmittedImageModal
				showing={isPhotoShowing}
				photoUrl={selectedPhotoUrl}
				selectedItem={selectedItem}
				cancel={() => setIsPhotoShowing(false)}
				removeImage={() => {
					const obj = selectedItem;
					const tempInputs = deepCopy(inputs);
					tempInputs[obj.pageIndex].sections[obj.sectionIndex].questions[
						obj.questionIndex
					][obj.questionArrIndex][obj.key] = undefined;
					setInputs(tempInputs);
					setIsPhotoShowing(false);
				}}
				switchToCrop={() => {
					setIsPhotoShowing(false);
					setIsCropShowing(true);
					setPhotoToBeCropped(selectedPhotoUrl);
				}}
			/>
			<CropperModal
				showing={isCropShowing}
				photoUrl={photoToBeCropped}
				onCrop={getCroppedPhoto}
				cancel={() => {
					setIsCropShowing(false);
				}}
			/>
			<LoadingModal showing={showLoader} />
			<Modal centered isOpen={alertModal.show} toggle={closeAlertModal}>
				<ModalHeader toggle={closeAlertModal}>
					<strong>{alertModal.title}</strong>
				</ModalHeader>
				<ModalBody>
					{alertModal.body.includes("\n")
						? alertModal.body.split("\n").map((line, index) => (
								<p
									className="mb-1"
									key={index}
									style={{ fontWeight: index === 0 && "bold" }}
								>
									{line}
								</p>
							))
						: alertModal.body}
				</ModalBody>
			</Modal>
			<DraftSelectionModal
				drafts={todaysDrafts}
				setResponseData={setInputs}
				// clearState={clearState}
				draftId={draftId}
			/>
		</div>
	);
};

export { SingleFormCushman };
