export const getRemainingUsefulLifeVerbiage = (
	usefulLife: number,
	dob: string | number | Date,
	condition: string | undefined,
) => {
	if (!usefulLife || !dob) {
		return "";
	}
	if (condition === "Broken") {
		return "Broken";
	}
	const birthDate = new Date(dob);
	const currentDate = new Date();

	// Calculate the difference in years
	const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();

	// Calculate the remaining useful life in years
	let remainingYears = usefulLife - yearsDiff;

	// Calculate the months difference
	let monthsDiff = currentDate.getMonth() - birthDate.getMonth();
	if (currentDate.getDate() < birthDate.getDate()) {
		monthsDiff--;
	}
	if (monthsDiff < 0) {
		monthsDiff += 12;
	}
	let remainingMonths = 12 - monthsDiff;

	// Adjust the remaining years if no more months are left
	if (
		currentDate.getMonth() > birthDate.getMonth() ||
		(currentDate.getMonth() === birthDate.getMonth() &&
			currentDate.getDate() >= birthDate.getDate())
	) {
		remainingYears--;
	}
	if (remainingMonths === 12) {
		remainingYears++;
	}

	if (
		remainingYears < 0 ||
		(remainingYears === 0 && (remainingMonths === 0 || remainingMonths === 12))
	) {
		remainingMonths = monthsDiff;
	}

	// Construct the response string
	let response = "";
	const isExpired =
		remainingYears < 0 ||
		(remainingYears === 0 && (remainingMonths === 0 || remainingMonths === 12));

	if (remainingYears > 0 && !isExpired) {
		response += `${remainingYears} ${remainingYears === 1 ? "year" : "years"} `;
	}

	if (remainingMonths > 0 && remainingMonths < 12) {
		response += `${remainingMonths} ${
			remainingMonths === 1 ? "month" : "months"
		}`;
	}
	if (isExpired) {
		// Calculate the exact date of expiry
		const expiryYear = birthDate.getFullYear() + usefulLife;
		const expiryDate = new Date(
			expiryYear,
			birthDate.getMonth(),
			birthDate.getDate(),
		);
		// Format the date as MM/DD/YYYY
		const formattedExpiryDate = `${(expiryDate.getMonth() + 1)
			.toString()
			.padStart(2, "0")}/${expiryDate
			.getDate()
			.toString()
			.padStart(2, "0")}/${expiryDate.getFullYear()}`;
		response = `Expired ${formattedExpiryDate}`;
		return response;
	}

	return response.trim();
};
