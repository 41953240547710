//cvs
export const validateAndSetPage2 = (
	organization,
	responseData,
	setIsPage2Valid,
) => {
	const isValid =
		organization === "SSPAMERICA"
			? !!responseData.storeType //SSPAMERICA
			: // : organization === 'EcoTrak'
				// ? !!responseData.subOrg
				!!responseData.equipmentAssetImage; //cvs, applegreen, campingworld, carliecs, foodlion, raisingcanes, razzoos, DIGI, swarovski
	setIsPage2Valid(isValid);
};
