import type { TablePaginationConfig, TableProps } from "antd";
import type { SorterResult } from "antd/es/table/interface";
import type { Dispatch } from "react";
import type { Action, State } from "../SubmissionsTable";

interface ConfigureTableOptions<T> {
	rowSelection?: TableProps<T>["rowSelection"];
	onChange?: TableProps<T>["onChange"];
	handleRowClick?: (record: T) => void;
	pagination?: Partial<TableProps<T>["pagination"]>;
	rowKey?: string;
}

const ASCENDING = "asc";
const DESCENDING = "desc";

export function getTableProps<T>(
	state: Partial<State>,
	dispatch: Dispatch<Action>,
	options: ConfigureTableOptions<T> = {},
): TableProps<T> {
	const {
		pageNum,
		pageSize,
		count,
		isLoading,
		columnsShown,
		sortBy,
		sortOrder,
		dataSource,
		tableColumns,
	} = state;

	// Define the default onChange handler
	const defaultOnChange: TableProps<T>["onChange"] = (
		pagination: TablePaginationConfig,
		filters: Record<string, React.ReactText[] | null>,
		sorter: SorterResult<T>,
	) => {
		const newSortOrder = sorter.order === "ascend" ? ASCENDING : DESCENDING;
		const newSortBy = sorter?.column?.key;

		if (newSortBy && typeof newSortBy === "string") {
			dispatch({
				type: "SET_SORT_BY",
				payload: newSortBy,
			});
		}
		dispatch({ type: "SET_SORT_ORDER", payload: newSortOrder });
	};

	const defaultRowSelection: TableProps<unknown>["rowSelection"] = {
		selectedRowKeys: state.checkedItems,
		onChange: (selectedRowKeys: React.Key[], selectedRows: unknown[]) => {
			dispatch({ type: "SET_CHECKED_ITEMS", payload: selectedRowKeys });
		},
	};

	// Merge default and custom pagination options
	const pagination: TableProps<T>["pagination"] = {
		current: pageNum,
		pageSize,
		total: count,
		showTotal: (total, range) => (
			<div style={{ display: "flex", alignItems: "center" }}>
				<span>{`Showing ${range[0].toLocaleString()} - ${range[1].toLocaleString()} of ${total.toLocaleString()} items`}</span>
			</div>
		),
		pageSizeOptions: ["5", "10", "20", "50"],
		showSizeChanger: true,
		onChange: (page: number) => {
			dispatch({ type: "SET_PAGE_NUM", payload: page });
		},
		onShowSizeChange: (current: number, size: number) => {
			dispatch({ type: "SET_PAGE_SIZE", payload: size });
			dispatch({ type: "SET_PAGE_NUM", payload: 1 });
		},
		...options.pagination, // Allow custom pagination options to override defaults
	};

	// Map visible columns
	const visibleColumns = tableColumns.map((col) => {
		const isSortColumn = col.key === sortBy;
		const newSortOrder = sortOrder === ASCENDING ? "ascend" : "descend";

		return {
			...col,
			hidden: !columnsShown?.[col.key as string],
			sortOrder: isSortColumn ? newSortOrder : undefined,
		};
	});

	// Construct the table props
	const tableProps: TableProps<T> = {
		dataSource,
		columns: visibleColumns,
		pagination,
		loading: isLoading,
		rowKey: options?.rowKey || "id",
		scroll: { x: true },
		tableLayout: "auto",
		onChange: options.onChange || defaultOnChange,
		rowSelection: options.rowSelection || defaultRowSelection,
	};

	// Handle custom row click
	if (options.handleRowClick) {
		tableProps.onRow = (record) => ({
			onClick: () => options.handleRowClick?.(record),
		});
	}

	return tableProps;
}
