import { FacilityVisionForm } from "@/views/SignedIn/FacilityVisionForm/FacilityVisionForm";
import { SingleForm } from "@/views/SignedIn/SingleForm/SingleForm";
import { getSQLForm } from "@services/form-service";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SingleFormRC from "../SingleForm/SingleFormRC";

/**
 * A element which determines which form to display based on form id and user
 */
const FormHandler = ({ user }) => {
	const { id } = useParams(); // Get the 'id' from the URL.
	const [formData, setFormData] = useState(null);

	/**
	 * Based on data from firebase, select which form to display
	 * @returns {JSX.Element}
	 */
	const selectForm = () => {
		switch (formData.master_form_key.name) {
			case "Inventory & Tagging Form":
				return <SingleForm />;
			case "Facility Vision Form":
				return <FacilityVisionForm formData={formData} />;
			case "Property Condition Assesment":
				return <SingleFormRC />;
			default:
				return <div>Unknown form type or error loading form.</div>;
		}
	};

	useEffect(() => {
		// Function to load form data or determine form type.
		const loadFormData = async (formId) => {
			//Get form data from firebase
			const formsSnap = await getSQLForm(formId);
			if (formsSnap) {
				return formsSnap;
			}
			return null;
		};

		loadFormData(id).then((data) => setFormData(data)); // Load form data when the component mounts or id changes.
	}, [id]); // Only re-run the effect if the 'id' changes.
	return !formData ? null : selectForm();
};
export { FormHandler };
