// Common patterns to check for unknown or invalid values
const INVALID_PATTERNS = [
	"UNKNOWN",
	"NOT_FOUND",
	"_MODEL",
	"_MODEL_NUMBER",
	"N/A",
	"MANUFACTURER_NOT_FOUND",
];

const isInvalidValue = (value: string | null | undefined): boolean => {
	if (value == null) return true; // Handles null and undefined
	return INVALID_PATTERNS.some((pattern) => value.includes(pattern));
};

export const isUnknownValue = (
	value: string | string[] | null | undefined,
): boolean => {
	if (value == null) return true; // Handles null and undefined
	if (Array.isArray(value)) {
		return value.some((val) => isInvalidValue(val));
	}
	return isInvalidValue(value);
};

export const returnNAforUnknownValues = (
	value: string | null | undefined,
): string => {
	return isInvalidValue(value) ? "N/A" : value || "N/A";
};
