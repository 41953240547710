import { AuthProviders, auth } from "@assets/services/auth-service";
import { getUserSelf } from "@services/user-service";
import { useUserStore } from "@stores/userStore";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

/**
 * Returns a mutation function which logs in a user with provided email and password.
 *
 * @return {function} The mutation function which returns a user object on success
 * and alerts an error message on failure.
 */
export const useLoginMutation = () => {
	const navigate = useNavigate();
	const setUserState = useUserStore.getState().setUser;

	return useMutation({
		mutationFn: async ({ email, password }: any) => {
			const authResponse = await AuthProviders.signInWithEmailAndPassword(
				auth,
				email,
				password,
			);
			//user_self_needs_update
			//state_benefit
			const user = await getUserSelf();
			if (user) {
				await AuthProviders.updateProfile(authResponse.user, {
					displayName: user.name,
				});
				user.displayName = user.name;
				setUserState(user);
			}

			return user;
		},
		onSuccess: () => {
			navigate("/");
		},
		onError: (error) => {
			alert(error);
		},
	});
};

/**
 * Returns a mutation function that logs out the user, updates the authentication state, and handles errors.
 *
 * @return {function} The mutation function.
 */
export const useLogoutMutation = () => {
	// const navigate = useNavigate();
	const setAuthInfo = useUserStore((state: any) => state.setAuthInfo);

	return useMutation({
		mutationFn: async () => {
			AuthProviders.logout();
		},
		onSuccess: () => {
			setAuthInfo({ loggedIn: false });
		},
		onError: (error) => {
			alert(error);
		},
	});
};
