import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import type { StringMap } from "@/models/abstractTypes";

// Converts json to b64, used for sending small amounts of json
const jsonToBase64 = (json: string | StringMap) => {
	return btoa(encodeURIComponent(JSON.stringify(json)));
};

const searchResponses = async (tableParams: any) => {
	try {
		const {
			searchVal: val,
			selectedForm: form_id,
			selectedFormMode: form_mode,
			pageNum,
			pageSize,
			sortBy: sort,
			sortOrder,
			browserLocationId: location,
			filters,
			viewingDeleted: isViewingDeleted,
			viewingDrafts: isViewingDrafts,
		} = tableParams;
		const params = new URLSearchParams({
			limit: pageSize.toString(),
			sort: sort,
			sort_direction: sortOrder,
			offset: pageNum.toString(),
			search_value: val,
			include_deleted: String(isViewingDeleted),
			form_mode: form_mode,
		});
		if (form_id) {
			params.append("form_id", String(form_id));
		}
		if (location) {
			params.append("location_id", location);
		}
		if (filters && Object.keys(filters).length > 0) {
			params.append("column_filter", jsonToBase64(filters));
		}

		const url = `${ALFRED_SERVICE_URL}/submission/search?${params.toString()}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to search for submissions: ${errorDetails.detail}`,
				);
			});
		}

		const responsejson = await response.json();
		return {
			results: responsejson.hits,
			count: responsejson.total_hits,
		};
	} catch (exception: unknown) {
		console.error(`Failed to search for submissions: ${exception}`);
		throw new Error("Failed to search for submissions");
	}
};

export { searchResponses };
