export function formatMillions(number: number, decimalPlaces = 3) {
	// Check if the number is a valid number
	if (typeof number !== "number" || Number.isNaN(number)) {
		return "Invalid number";
	}

	const million = 1000000;
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	});

	// Handle zero case specifically
	if (number === 0) {
		return "$0";
	}

	const absNumber = Math.abs(number);
	const formattedNumber = formatter.format(absNumber / million);
	return `${formattedNumber}M`;
}
