import { FormGroup, Input, Label, Row } from "reactstrap";

// this is an asset conditional input for CVS and is based on the value of numDoors

const TypeOfSystemInput = ({ value }) => {
	return (
		<Row key={"typeOfSystem"}>
			<FormGroup>
				<Label className="single-form-label">
					Type of System
					<span style={{ color: "red" }}> *</span>
				</Label>
				<br />
				<Input type={"text"} value={value} className="text-area" disabled />
			</FormGroup>
		</Row>
	);
};

export { TypeOfSystemInput };
