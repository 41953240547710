import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleTempAlertPhoto = (
	urlPromise,
	setResponseData,
	setState,
	tempAlertQuestion,
	assetTagQuestion,
	capturePhoto,
	setTempAlertPhoto,
	saveDataCaptureState,
	setCurrentState,
	flags,
	options,
) => {
	const prevMessageNoWidget = createChatBotMessage(
		"Please upload a photo of the Temp Alert Id.",
		{
			withAvatar: true,
		},
	);
	const userMessage = createClientMessage("", {
		widget: "CustomImageMessage",
		payload: {
			question: tempAlertQuestion,
			capturePhoto,
			imageUrl: urlPromise,
		},
	});
	let message;
	let currentState;
	if (flags[assetTagQuestion.flag]) {
		message = createChatBotMessage(
			"Please upload a photo of the Asset Tag Id.",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: {
					topThreeLocations: [],
					question: assetTagQuestion.questions[0],
					capturePhoto,
				},
			},
		);
		currentState = {
			options: ["Upload photo"],
			stage: "assetTagPhoto",
			showOptions: true,
		};
	} else {
		message = createChatBotMessage(
			"Thank you. Where is the asset located in the store?",
			{
				widget: "OptionalSelectors",
				withAvatar: true,
				payload: { topThreeLocations: [] },
			},
		);
		currentState = {
			options: options["equipment-area"]?.map((loc) =>
				loc?.value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
			),
			stage: "assetLocation",
			showOptions: true,
		};
	}

	saveDataCaptureState([
		{ message: prevMessageNoWidget, action: "removeWidget" },
		{ message: userMessage, action: "add" },
		{ message, action: "add" },
	]);
	setCurrentState(currentState);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			message,
		],
		...currentState,
	}));

	urlPromise.then((url) => {
		setResponseData({
			[tempAlertQuestion.key]: url,
		});
		setTempAlertPhoto(url);
	});
};
