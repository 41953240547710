import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import { ACTION_TYPE, useChatbotHistory } from "@/contexts/chatbotContext";
import type { StringMap } from "@/models/abstractTypes";
import { formatDate } from "@/utils/ISOdateToMMDDYYYY";
import { getRemainingUsefulLifeVerbiage } from "@/utils/getRemainingUsefulLifeVerbiage";
import { LeftOutlined } from "@ant-design/icons";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import AssetDecommission from "@components/layouts/SignedIn/views/AssetProfile/AssetDecommission";
import { AssetTagTooltip } from "@components/layouts/SignedIn/views/AssetProfile/AssetTagTooltip";
import { LifespanChart } from "@components/layouts/SignedIn/views/AssetProfile/LifespanChart";
import { ShoppingModal } from "@components/layouts/SignedIn/views/AssetProfile/ShoppingModal";
import { ChatbotMainCard } from "@components/layouts/SignedIn/views/Dashboard/ChatbotMainCard/ChatbotMainCard";
import { getIceMakerOrientation } from "@services/alfred-service";
import {
	formatDateDynamic,
	getAsset,
	updateAsset,
} from "@services/asset-service";
import { getExtractedInfo } from "@services/asset-service";
import { generateAssetDescription } from "@services/openai-service";
import { useUserStore } from "@stores/userStore";
import { Button } from "antd";
import { getDoc } from "firebase/firestore";
import numWords from "num-words";
import { useEffect, useRef, useState } from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import {
	AiOutlineBarcode,
	AiOutlineDollarCircle,
	AiOutlineInfoCircle,
} from "react-icons/ai";
import { BsShieldCheck, BsTools } from "react-icons/bs";
import { FaRuler, FaUserShield } from "react-icons/fa";
import { FiMinus, FiPlus } from "react-icons/fi";
import { GiLifeBar } from "react-icons/gi";
import { IoMdEye } from "react-icons/io";
import {
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdOutlineDateRange,
} from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Card, CardBody, CardHeader, Toast, ToastBody } from "reactstrap";
import { Tooltip } from "reactstrap";
import { AssetProfileCard } from "./AssetProfileCard";
import AuditLogModal from "./AuditLogModal";
import { ListNavigationAssetProfileCard } from "./ListNavigationAssetProfileCard";

const DEFAULT_CHATBOT_MESSAGE = `Hi, I'm FacilityAI, your personalized Facility Manager Assistant!\n\nAsk me anything related to your asset or choose an option below to get started!`;

const AssetProfile = () => {
	const {
		isAdminOrSuperUser,
		authInfo: { org },
	} = useUserStore();
	const { dispatch } = useChatbotHistory();
	const showDecommission: boolean = isAdminOrSuperUser;
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Fix any types
	const [assetData, setAssetData] = useState<any>();
	const [specificDetails, setSpecificDetails] = useState<StringMap[]>([]);
	const [activeImg, setActiveImg] = useState<string>();
	const [activeImgIndex, setActiveImgIndex] = useState<number>(0);
	const [allImgs, setAllImgs] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [submission, _setSubmission] = useState();
	const [_queryingOpenAI, setQueryingOpenAI] = useState(false);
	const [combinedData, setCombinedData] = useState({});
	const [generatedProps, setGeneratedProps] = useState<StringMap[]>([]);
	const [_hoveredIndex, _setHoveredIndex] = useState(null);
	const [isCopied, setIsCopied] = useState(false);
	const [decommissioned, setDecommissioned] = useState(false);
	const [tabs, setTabs] = useState(["Make", "Model", "Serial"]);
	const [_tabContent, setTabContent] = useState<StringMap[]>([]);
	const [clickZoomIn, setClickZoomIn] = useState(false);
	const [clickZoomOut, setClickZoomOut] = useState<boolean>(false);
	const [iceMakerOrientationData, setIceMakerOrientationData] =
		useState<StringMap | null>(null);
	const [repairTooltipOpen, setRepairTooltipOpen] = useState(false);

	const handleIconClick = () => {
		setIsCopied(true);
		setTimeout(() => setIsCopied(false), 3000);
	};

	const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

	//for showing auditlog
	const [assetAuditData, _setAssetAuditData] = useState<StringMap>();

	const [isAuditModalOpen, setIsAuditModalOpen] = useState(false);

	// Function to toggle the audit modal visibility
	const toggleAuditModal = () => setIsAuditModalOpen(!isAuditModalOpen);

	const updateMedia = () => {
		setDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	const formatKey = (key: string) => {
		if (!key) return null;
		return key
			.replace(/_/g, " ")
			.replace(/([a-z])([A-Z])/g, "$1 $2")
			.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
	};

	const renderIcon = (key: string) => {
		switch (key) {
			case "Make":
				return <FaUserShield />;
			case "Model":
				return <FaRuler />;
			case "Serial":
				return <AiOutlineBarcode />;
			case "Warranty":
				return <BsShieldCheck />;
			case "Useful Life Remaining":
				return <GiLifeBar />;
			case "Maintenance Spend":
				return <BsTools />;
			case "Replacement Cost":
				return <AiOutlineDollarCircle />;
			case "DOB":
				return <MdOutlineDateRange />;
			case "Temp Alert ID":
				return <AiOutlineInfoCircle />;
			default:
				return <AiOutlineInfoCircle />;
		}
	};

	// helper state to track changes to asset doc
	const [assetChange, setAssetChange] = useState(false);

	const { id: locationId, assetid } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const doNotIncludePropsAssetData = [
		"warranty_period",
		"processedAt",
		"processed_at",
		"manufacturer",
		"model_number",
		"generatedProps",
	];

	const desiredOrder = ["Make", "Model", "Warranty", "DOB"];

	const combineAssetData = (
		assetData: {
			unique_fields: { other_generatedProps: unknown };
			date_of_birth: string;
			is_dob_estimate: boolean;
			model: {
				manufacturer: { name: string };
				replacement_cost_usd: { toLocaleString: () => string };
			};
			model_number: string;
			serial_number: string;
			health: { remaining_useful_life: string | number };
			extra: { avgReplCostUSD: { toLocaleString: () => string } };
			temp_alert_tag: string;
			is_refrigeration: boolean;
			refrigeration_details: { refrigerant_type: string };
		},
		specificDetails: any[],
		extractedSpecs: { warranty_period: unknown },
		auditData: StringMap,
	) => {
		const extractedSpecsKeys = Object.keys(extractedSpecs || {});
		const auditDataKeys = Object.keys(auditData || {});
		const specificDetailKeys = Object.keys(specificDetails || {});

		const allGeneratedProps = [
			...(assetData?.unique_fields?.other_generatedProps || []),
			...extractedSpecsKeys,
			...auditDataKeys,
			...specificDetailKeys,
			"Warranty",
			"Replacement Cost",
			"Useful Life Remaining",
		];

		const isDOBEstimate = !!(
			!assetData.date_of_birth && assetData.is_dob_estimate
		);

		if (isDOBEstimate) {
			allGeneratedProps.push("DOB");
		}

		setGeneratedProps(allGeneratedProps);

		let combinedData = {
			Make: assetData?.model?.manufacturer?.name || "UNKNOWN",
			Model: assetData?.model_number || "N/A",
			Serial: assetData?.serial_number || "N/A",
			Warranty: extractedSpecs?.warranty_period || "N/A",
			"Useful Life Remaining":
				assetData?.health?.remaining_useful_life ?? "N/A",
			"Maintenance Spend": "N/A",
			"Replacement Cost":
				assetData?.model?.replacement_cost_usd ||
				assetData?.extra?.avgReplCostUSD
					? `$${
							assetData?.extra?.avgReplCostUSD?.toLocaleString() ||
							assetData?.model.replacement_cost_usd?.toLocaleString()
						}`
					: "N/A",
			DOB: formatDateDynamic(assetData?.date_of_birth) || "N/A",
			...specificDetails?.reduce(
				(acc: unknown, detail: { label: string; value: unknown }) => ({
					...acc,
					[detail.label]: detail.value,
				}),
				{},
			),
			...extractedSpecs,
			...auditData,
		};

		if (combinedData.Model === "UNKNOWN_MODEL") {
			combinedData.Model = "N/A";
		}

		if (assetData?.temp_alert_tag) {
			combinedData["Temp Alert ID"] = assetData?.temp_alert_tag ?? "Not Found";
		}

		if (assetData?.is_refrigeration) {
			combinedData.Refrigerant =
				assetData?.refrigeration_details?.refrigerant_type ?? "Not Found";
		}

		// Filter out keys specified in doNotIncludePropsAssetData
		combinedData = Object.entries(combinedData)
			.filter(([key, _]) => !doNotIncludePropsAssetData.includes(key))
			.reduce((obj: StringMap, [key, value]) => {
				obj[key] = value as string;
				return obj;
			}, {});

		// Reorder combinedData based on desiredOrder
		const reorderedData: StringMap = {};

		// Add items from desiredOrder in order
		for (const key of desiredOrder) {
			if (combinedData[key] !== undefined) {
				reorderedData[key] = combinedData[key];
				delete combinedData[key];
			}
		}

		// Add the remaining items
		Object.assign(reorderedData, combinedData);

		return reorderedData;
	};

	const isListData = (value: unknown) =>
		Array.isArray(value) || typeof value === "object";

	const regularData = Object.entries(combinedData).filter(
		([, value]) => !isListData(value),
	);

	const listData = Object.entries(combinedData).filter(([, value]) =>
		isListData(value),
	);

	// const handleMouseEnter = useCallback((i) => {
	// 	setHoveredIndex(i);
	// }, []);

	// const handleMouseLeave = useCallback(() => {
	// 	setHoveredIndex(null);
	// }, []);

	const fetchAndProcessAssetData = async () => {
		// Fetch the asset document
		const asset = await getAsset(assetid);

		// Fetch and set asset description in the chat bot
		const assetDescription = asset?.description || DEFAULT_CHATBOT_MESSAGE;
		dispatch({ type: ACTION_TYPE.CLEAR_HISTORY });
		dispatch({
			type: ACTION_TYPE.ADD_MESSAGE,
			payload: {
				message: createChatBotMessage(assetDescription, {
					widget: "InitialMessageOptions",
				}),
			},
		});

		// Fetch extracted info
		const manualAggregationData = await getExtractedInfo(
			asset?.model?.model_number || asset?.model_number,
		).catch((err) => {
			console.log(`Error fetching extracted info: ${err}`);
			return {};
		});

		// Audit information for asset
		const assetAuditData = {};

		// TODO: -sql audit module
		// const auditLog = assetDoc.data().other.auditLog;
		// if (auditLog && auditLog.length > 0) {
		// 	auditLog.sort(
		// 		(a, b) => new Date(b.auditedDate) - new Date(a.auditedDate)
		// 	);
		//
		// 	const latestAudit = auditLog[0];
		// 	assetAuditData = latestAudit;
		// 	setAssetAuditData(latestAudit);
		// }

		const parentAssetRef = null;
		const parentAssetSnap = parentAssetRef
			? await getDoc(parentAssetRef)
			: null;
		const remainingUsefulLife = getRemainingUsefulLifeVerbiage(
			parentAssetSnap ? parentAssetSnap.data().avgUsefulLifeYears : null,
			asset.date_of_birth || asset.is_dob_estimate,
		);

		// Combine and set asset data
		const combinedData = combineAssetData(
			asset,
			specificDetails,
			manualAggregationData,
			assetAuditData,
		);
		setCombinedData(combinedData);
		setAssetData(asset);

		setSpecificDetails(specificDetails);

		const updatedAssetData = parentAssetSnap
			? {
					asset,
					avgReplCostUSD: parentAssetSnap.data()?.avgReplCostUSDScraped
						? parentAssetSnap.data()?.avgReplCostUSDScraped
						: parentAssetSnap.data()?.avgReplCostUSD,
					avgUsefulLifeYears: parentAssetSnap.data()?.avgUsefulLifeYears,
					remainingUsefulLife,
					assetId: asset.id,
				}
			: asset;
		setAssetData(updatedAssetData);

		if (asset.parsed_photos) {
			setActiveImg(asset.parsed_photos.EQUIPMENT);

			let allImgsArr = [
				asset.parsed_photos.EQUIPMENT,
				asset.parsed_photos.ID_TAG,
				asset.parsed_photos.MANUFACTURER_PLATE,
			];
			if (asset.parsed_photos.TEMP_ALERT) {
				allImgsArr.push(asset.parsed_photos.TEMP_ALERT);
			}
			allImgsArr = allImgsArr.filter((img) => img);
			setAllImgs(allImgsArr);
		}

		if (
			org === "raisingcanes.com" &&
			asset.organization_asset_type.name === "Ice Machine"
		) {
			getIceMakerOrientation(asset.asset_tag)
				.then((results) => {
					console.log(results);
					setIceMakerOrientationData(results);
				})
				.catch((err) => {
					console.log(`Ice Maker Orientation Error: ${err}`);
				});
		}

		// TODO: asset_revisit_later
		// const submissionRef = assetDoc.data().responseRef;
		// const submissionDoc = await getDocFromRef(submissionRef);
		// setSubmission(submissionDoc.data());

		setIsLoading(false);
	};

	const getAssetHealthScore = (health: number) => {
		let healthScoreColor: string;
		if (health >= 75) {
			healthScoreColor = "green";
		} else if (health >= 25 && health < 75) {
			healthScoreColor = "yellow";
		} else {
			healthScoreColor = "red";
		}
		return healthScoreColor;
	};

	const getRepairOrReplace = () => {
		let result = "Repair";
		const { healthScore, isWarrantyExpired } = assetData;
		if (
			(healthScore < 30 && isWarrantyExpired === null) ||
			isWarrantyExpired === false
		) {
			result = "Replace";
		}
		return result;
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO: Fix exhaustive dependencies
	useEffect(() => {
		fetchAndProcessAssetData();
		setAssetChange(false);
	}, [assetChange, decommissioned]);

	// ChatGPT generated asset description
	const generateDescription = async () => {
		const color = getAssetHealthScore(assetData?.health?.health_score);
		const pertinentData = {
			assetType: assetData?.organization_asset_type.name?.toLowerCase(),
			dateOfManufacture: assetData?.date_of_birth ?? assetData.is_dob_estimate,
			healthScore: assetData?.health?.health_score,
			generalHealth:
				color === "red"
					? Number.isNaN(assetData.health.health_score)
						? "unknown"
						: "poor"
					: color === "yellow"
						? "decent"
						: color === "green"
							? "great"
							: "unknown",
			make: assetData.model.manufacturer.name,
			remainingUsefulLife: assetData.health.remaining_useful_life,
			// TODO: asset_revisit_later
			// avgUsefulLifeYears: assetData.avgUsefulLifeYears,
			// avgReplacementCostUSD: `$${
			// 	assetData.avgReplCostUSDScraped ?? assetData.avgReplCostUSD
			// }`,
		};
		const prompt = `Write a professionally formatted summary of this ${
			pertinentData.assetType
		},
		describing the assets' current state in full sentences.
		Do not use the words 'asset' or 'summary'. 
		Do not use the word 'make' after the make of the asset is written.
		The asset is given by the following object: 
		${JSON.stringify(pertinentData)}.
		The health score considers factors such as the
		asset's age, maintenance history, and expected
		useful life.`;

		setQueryingOpenAI(true);
		await generateAssetDescription(prompt)
			.then((res) => {
				const description = res.choices[0].message.content;
				setAssetData({ ...assetData, description });
			})
			.catch((err) => console.log(err));
		setQueryingOpenAI(false);
	};

	// Generate description if there is none
	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO: Fix exhaustive dependencies
	useEffect(() => {
		async () => {
			if (!assetData) return;
			if (!assetData.description) {
				await generateDescription();
			}
			if (
				healthUpdated(assetData.health.health_score, assetData.description) ||
				remainingYearsUpdated(
					assetData.health.remaining_useful_life,
					assetData.description,
				)
			) {
				// Generate new description if necessary
				await generateDescription();
			}
			// TODO: Look into neccessity of this with new DB schema implementation / Refactor to use SQL DB
			await updateAsset(org, assetid, assetData);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetData]);

	// Checks if health score has changed since last description has been generated
	const healthUpdated = (health: string, description: string | string[]) =>
		!health ? false : !description.includes(health.toString());

	// Checks if remaining useful years has decreased since description was generated
	const remainingYearsUpdated = (
		years: number,
		description: string | string[],
	) => {
		if (!years) return false;
		const containsWordYears = description.includes(numWords(Math.abs(years)));

		const indexOfYear = description.indexOf(` ${Math.abs(years).toString()} `);
		const containsYearsSurroundedBySpaces = indexOfYear > 0;

		// If both conditions are false, description likely doesn't match current data
		return !(containsWordYears || containsYearsSurroundedBySpaces);
	};

	const handleNavigateToLocation = () => {
		navigate(`/locations/${assetData.other.location.id}`, {
			state: {
				data: assetData.other.location,
				tab: "Assets",
				assetsPageNum: location.state?.assetsPageNum,
				sortBy: location.state?.sortBy,
				sortOrder: location.state?.sortOrder,
			},
		});
	};

	const handleBackButtonClick = () => {
		const isAdminView: boolean = location.pathname.includes("admin");
		const pathName = location.pathname;
		const locationId = pathName.split("/")[2];
		navigate(
			isAdminView ? "/admin/assets" : `/locations/${locationId}/assets`,
			{ state: location.state },
		);
	};

	// Scroll to show all information
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Fix any types
	const divRef = useRef<any>(null);
	useEffect(() => {
		if (!isLoading) {
			window?.scrollTo({
				top: divRef?.current?.offsetTop,
				behavior: "smooth",
			});
		}
	}, [isLoading]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO: Fix exhaustive dependencies
	useEffect(() => {
		if (!assetData) return;

		const newTabs = [...tabs];
		let newTabContent = [
			assetData?.model.manufacturer.name || "N/A",
			assetData?.model_number || "N/A",
			assetData?.serial_number || "N/A",
		];

		if (specificDetails) {
			// Update newTabs with specificDetails' labels if they aren't already present
			for (const detail of specificDetails) {
				if (!newTabs.includes(detail?.label)) {
					newTabs.push(detail.label);
				}
			}

			newTabContent = [
				...newTabContent,
				...specificDetails.map((tab) => tab.value),
			];
		}

		if (assetData.temp_alert_tag && !newTabs.includes("Temp Alert ID")) {
			newTabs.push("Temp Alert ID");
			newTabContent.push(assetData.temp_alert_tag);
		}
		if (assetData.is_refrigeration && !newTabs.includes("Refrigerant")) {
			newTabs.push("Refrigerant");
			newTabContent.push(assetData.refrigeration_details.refrigerant_type);
		}

		setTabs(newTabs);
		setTabContent(newTabContent);
	}, [specificDetails]);

	return isLoading && !assetData ? (
		<Loader />
	) : (
		<div>
			<div className="d-flex flex-column justify-content-center align-items-center align-items-md-start mb-2">
				<Button
					type="link"
					variant="link"
					onClick={() => handleBackButtonClick()}
					onKeyDown={() => handleBackButtonClick()}
					icon={<LeftOutlined />}
				>
					Return to Assets
				</Button>
			</div>
			<div className="asset-profile-card position-relative align-items-center d-flex flex-column">
				<div className="asset-actions-button-row">
					<ShoppingModal
						assetType={assetData?.organization_asset_type?.name}
						manufacturer={assetData?.model?.manufacturer?.name || "UNKNOWN"}
						model={assetData?.model_number}
					/>
					{showDecommission && !assetData?.decommissioned && (
						<AssetDecommission
							assetId={assetid}
							assetData={assetData}
							org={org}
							setDecommissioned={setDecommissioned}
						/>
					)}
				</div>
				<div className="d-flex justify-content-center align-items-center mb-2 w-100">
					<div className="d-flex w-100 asset-health-container">
						<div className="w-50 d-flex align-items-center">
							<strong
								className="fs-7 align-self-center"
								style={{
									whiteSpace: "nowrap",
									fontSize: "18px",
									marginRight: "0.5rem",
								}}
							>
								Asset Health
							</strong>
							<div className="mx-2 w-100" style={{ maxWidth: "200px" }}>
								<HealthScorePill
									healthScore={assetData?.health?.health_score}
									size={"lg"}
									width={"100%"}
								/>
							</div>
						</div>

						<div className="m-2 d-flex align-items-center">
							<strong>Repair/Replace?</strong>
							<div
								className="mx-3"
								onMouseEnter={() => setRepairTooltipOpen(true)}
								onMouseLeave={() => setRepairTooltipOpen(false)}
							>
								<AssetTagTooltip
									label={""}
									tagContent={getRepairOrReplace()}
									organization={org}
									id={"repairOrReplace"}
								/>
								<Tooltip
									isOpen={repairTooltipOpen}
									target={"repairOrReplace"}
									toggle={() => setRepairTooltipOpen(true)}
									style={{ maxWidth: "300px" }}
								>
									We determine this based on several factors, including asset
									health score and warranty status.
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				<div className="asset-header">
					<h4 className="text-center">
						<strong>{assetData?.organization_asset_type.name}</strong>
					</h4>
					<h6 className="text-center" style={{ color: "rgb(111 111 111)" }}>
						Located at
						<span
							className="asset-profile-location-link"
							onClick={() => handleNavigateToLocation()}
							onKeyDown={() => handleNavigateToLocation()}
							style={{ cursor: "pointer" }}
						>
							{" "}
							{assetData?.location.address1}
						</span>
					</h6>
					<h6 className="text-center" style={{ color: "rgb(111 111 111)" }}>
						Tag ID: {assetData?.asset_tag || "N/A"}
					</h6>
					{assetAuditData && (
						<h6
							className="text-center"
							style={{
								color: "rgb(111 111 111)",
								textAlign: "left",
							}}
							// onClick={toggleAuditModal}
						>
							Last CheckUp done on:{" "}
							{formatDate(assetAuditData.auditedDate) || "N/A"}{" "}
							<IoMdEye
								size={24}
								onClick={toggleAuditModal}
								style={{ cursor: "pointer" }}
							/>
						</h6>
					)}

					{/* Audit Log Modal */}
					<AuditLogModal
						isOpen={isAuditModalOpen}
						setIsOpen={setIsAuditModalOpen}
						assetAuditData={assetAuditData}
					/>
					{assetData?.decommissioned && (
						<h6 className="text-center bg-danger text-white">Decommissioned</h6>
					)}
				</div>
				<div
					className={`mx-2 mb-2 d-flex justify-content-around w-100 ${
						isDesktop ? "flex-row" : "flex-column"
					}`}
				>
					<div
						className="d-flex align-items-center w-100"
						style={{
							minWidth: "200px",
							overflowX: "hidden",
							maxWidth: isDesktop ? "600px" : "100vw",
							flexDirection: "column-reverse",
							height: "fit-content",
						}}
					>
						<div
							className="d-flex align-items-center w-100"
							style={{
								height: "fit-content",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							<Button
								className="toggle-img-btn"
								style={
									isDesktop
										? {}
										: {
												transform: isDesktop ? "" : "scale(0.75)",
												margin: 0,
											}
								}
								onClick={() => {
									setActiveImgIndex(
										activeImgIndex > 0
											? activeImgIndex - 1
											: allImgs.length - 1,
									);
									setActiveImg(
										allImgs[
											activeImgIndex > 0
												? activeImgIndex - 1
												: allImgs.length - 1
										],
									);
								}}
							>
								<MdKeyboardArrowLeft />
							</Button>
							<div className="d-flex align-items-center">
								{allImgs?.length
									? allImgs.map((img: string) => (
											<img
												alt="asset"
												key={img}
												style={{
													width: isDesktop ? "80px" : "12vw",
													aspectRatio: "1 / 1",
													borderRadius: "15px",
													cursor: "pointer",
													outline: img === activeImg ? "2px solid #5b2286" : "",
												}}
												src={img}
												className="m-2"
												onClick={() => setActiveImg(img)}
												onKeyDown={() => setActiveImg(img)}
											/>
										))
									: null}
							</div>
							<Button
								className="toggle-img-btn"
								style={
									isDesktop
										? {}
										: {
												transform: isDesktop ? "" : "scale(0.75)",
												margin: 0,
											}
								}
								onClick={() => {
									setActiveImgIndex(
										activeImgIndex < allImgs.length - 1
											? activeImgIndex + 1
											: 0,
									);
									setActiveImg(
										allImgs[
											activeImgIndex < allImgs.length - 1
												? activeImgIndex + 1
												: 0
										],
									);
								}}
							>
								<MdKeyboardArrowRight />
							</Button>
						</div>
						<TransformWrapper
							initialScale={1}
							alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
						>
							{({ zoomIn, zoomOut }) => (
								<div className="image-zoom">
									<TransformComponent>
										<img
											alt="asset"
											src={activeImg}
											className="mx-4 my-2 asset-profile-active-img d-inline-block h-auto"
											style={{
												maxWidth: "100%",
												aspectRatio: "1 / 1",
											}}
										/>
									</TransformComponent>
									<div className="tools">
										<button
											type="button"
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													setClickZoomOut(true);
													zoomOut();
												}
											}}
											className={`btn-zoom ${
												clickZoomOut ? "btn-zoom-pop" : ""
											}`}
											onClick={() => {
												setClickZoomOut(true);
												zoomOut();
											}}
											onAnimationEnd={() => setClickZoomOut(false)}
										>
											<FiMinus />
										</button>
										<button
											type="button"
											className={`btn-zoom ${
												clickZoomIn ? "btn-zoom-pop" : ""
											}`}
											onClick={() => {
												setClickZoomIn(true);
												zoomIn();
											}}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													setClickZoomIn(true);
													zoomIn();
												}
											}}
											onAnimationEnd={() => setClickZoomIn(false)}
										>
											<FiPlus />
										</button>
									</div>
								</div>
							)}
						</TransformWrapper>
					</div>
					<div className={"flex-container-chatbot"}>
						<div
							className="mb-4 asset-profile-chatbot-container"
							style={{ height: "26em" }}
						>
							<ChatbotMainCard style={{ maxHeight: "600px", height: "26em" }} />
						</div>
					</div>
				</div>
				<div className="d-flex flex-column ms-2 mt-4 mb-2">
					<div className="d-flex" style={{ marginLeft: "30px" }}>
						<span className="text-green fw-bold">Top 10%</span>
						<span className="mx-2">FAI Assets</span>
						<span className="text-green fw-bold" style={{ marginLeft: "12px" }}>
							Top 5%
						</span>
						<span className="mx-2">Organization Assets</span>
					</div>
				</div>
				<div className="mt-2 mb-4">
					<div className="d-flex flex-column asset-profile-info-unified">
						<div className="asset-data-container my-2">
							{regularData.map(
								([propKey, value], _i) =>
									value !== "N/A" && (
										<AssetProfileCard
											key={propKey}
											propKey={propKey}
											value={value}
											icon={renderIcon(propKey)}
											title={formatKey(propKey)}
											generatedProps={generatedProps}
											onClickCopy={handleIconClick}
										/>
									),
							)}
							{listData.map(([propKey, value], index) => (
								<ListNavigationAssetProfileCard
									key={regularData.length + index}
									value={value}
									icon={renderIcon(propKey)}
									title={formatKey(propKey)}
									generatedProps={generatedProps}
								/>
							))}
						</div>

						{iceMakerOrientationData && (
							<div className="d-flex flex-row justify-content-center align-content-center align-self-start m-2 mb-3">
								<strong
									className="fs-7 align-self-center"
									style={{ whiteSpace: "nowrap" }}
								>
									Ice machine to the{" "}
									{iceMakerOrientationData.neighbor.orientation}:{" "}
									<a
										href={`${window.location.origin}/locations/${locationId}/assets/${iceMakerOrientationData.neighbor.assetId}`}
										target="_blank"
										rel="noreferrer"
									>
										{iceMakerOrientationData.neighbor.assetTag}
									</a>
								</strong>
							</div>
						)}
					</div>
				</div>
			</div>
			{assetData?.remainingUsefulLife && (
				<Card className="lifespan-card">
					<CardHeader className="py-3">
						<GiLifeBar className="fs-4" />
						<span className="fw-bold mx-2">Asset Lifespan Breakdown</span>
					</CardHeader>
					<CardBody>
						{/*asset_revisit_later TODO: to fix cloud function for asset_id*/}
						<LifespanChart
							org={org}
							assetType={assetData?.organization_asset_type.name}
							assetId={assetData?.id}
						/>
					</CardBody>
				</Card>
			)}
			<Toast isOpen={isCopied} className="asset-toast">
				<ToastBody>Text copied to clipboard.</ToastBody>
			</Toast>
		</div>
	);
};

export { AssetProfile };
