const AssetHealthScore = ({ healthScore }) => {
	const getAssetHealthScore = (health) => {
		let healthScore;
		if (health >= 75) {
			healthScore = "green";
		} else if (health >= 25 && health < 75) {
			healthScore = "yellow";
		} else {
			healthScore = "red";
		}
		return healthScore;
	};
	return (
		<div
			className={`fs-6 asset-profile-tag asset-health-score asset-health-${getAssetHealthScore(
				healthScore,
			)}`}
		>
			{!Number.isNaN(healthScore) ? `${healthScore}%` : "N/A"}
		</div>
	);
};

export { AssetHealthScore };
