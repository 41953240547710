import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";

/**
 * Returns information on the models
 */
export const lookupModels = async (modelIds: any[]): Promise<any> => {
	try {
		const params = modelIds.map((id) => `ids=${id}`).join("&");
		const url = `${ALFRED_SERVICE_URL}/model/lookup/?${params}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.log(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error("Failed to fetch the model information ", exception);
		throw new Error(exception);
	}
};
