import { useChatbotStore, useFormStore } from "@stores";
import { preventSpecialChars } from "@utils/preventSpecialChars";
import { useState } from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { GeneratedPropIcon } from "../../AssetProfile/GeneratedPropIcon";
import { CheckboxInput } from "./CheckboxInput";
import { PreviewField } from "./PreviewField";

const TextInput = ({
	question,
	responseData,
	setInputData,
	hidePreview,
	fromChatbot,
	placeholder,
}) => {
	const {
		ocrConfidenceAndColor: formOcrConfidenceAndColor,
		fieldError: formFieldError,
		setFieldError: setFormFieldError,
		setAlertModal,
	} = useFormStore();
	const {
		ocrConfidenceAndColor: chatbotOcrConfidenceAndColor,
		fieldError: chatbotFieldError,
		setFieldError: setChatbotFieldError,
	} = useChatbotStore();

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	const ocrConfidenceAndColor = fromChatbot
		? chatbotOcrConfidenceAndColor
		: formOcrConfidenceAndColor;
	const fieldError = fromChatbot ? chatbotFieldError : formFieldError;
	const setFieldErrorFn = fromChatbot
		? setChatbotFieldError
		: setFormFieldError;

	// Prepare OCR confidence data
	const ocrConfidenceAndColorObj = {
		color: "",
		confidence: null,
	};

	if (ocrConfidenceAndColor?.[question.key]) {
		ocrConfidenceAndColorObj.confidence = (
			ocrConfidenceAndColor[question.key].confidence * 100
		).toFixed(2);
		ocrConfidenceAndColorObj.color = ocrConfidenceAndColor[question.key].color;
	}

	// Manage hover state
	const [isHovered, setIsHovered] = useState(false);

	// Define customStyles similar to ManufacturerInput
	const customStyles = {
		borderColor: fieldError[question.key]
			? "red"
			: ocrConfidenceAndColorObj.color
				? ocrConfidenceAndColorObj.color
				: "#ced4da", // Default Bootstrap border color
		boxShadow: ocrConfidenceAndColorObj.color
			? `0 0 10px ${ocrConfidenceAndColorObj.color}`
			: undefined,
		outline: ocrConfidenceAndColorObj.color
			? `1px solid ${ocrConfidenceAndColorObj.color}`
			: "none",
		paddingRight: ocrConfidenceAndColorObj.confidence ? "84px" : "0px",
		transition:
			"border-color 0.3s ease, box-shadow 0.3s ease, outline 0.3s ease",
		width: "100%",
		...(isHovered && {
			borderColor: fieldError[question.key]
				? "red"
				: ocrConfidenceAndColorObj.color
					? ocrConfidenceAndColorObj.color
					: "#ced4da",
		}),
	};

	// Determine if the input should be hidden based on certain conditions
	const shouldHide = () => {
		if (question.tiedTo === "tempAlertIdNotAvailable") {
			return false;
		}
		if (
			question.key === "rackId" &&
			responseData[question.altTiedTo] !== "Connected to a Rack" &&
			responseData[question.altTiedTo] !== "Rack"
		) {
			return true;
		}
		if (
			question.key === "refType" &&
			responseData[question.altTiedTo] !== "Self Contained Asset"
		) {
			return true;
		}
		if (
			(question.key === "totalCharge" ||
				question.key === "refrigerantCharge") &&
			responseData[question.altTiedTo] !== "Self Contained Asset" &&
			responseData[question.altTiedTo] !== "Rack" &&
			responseData[question.altTiedTo] !== undefined
		) {
			return true;
		}
		if (
			question.key === "ifOtherStoreName" &&
			!responseData.storeNameNotFound
		) {
			return true;
		}
		return false;
	};

	// Determine if the input should be disabled
	const shouldDisable = () => {
		if (question.disabled === "true") {
			return true;
		}
		return (
			responseData[question.tiedTo] === true ||
			responseData[question.checkbox?.key] === true
		);
	};

	const refrigerantCharge = question.key === "refrigerantCharge";
	const voltage = question.key === "voltage";
	const amps = question.key === "amps";

	const handleCheckboxChange = (checkboxKey, checkboxValue) => {
		checkboxKey = question.checkbox.key;
		setInputData({ key: checkboxKey }, checkboxValue);

		if (checkboxValue) {
			setInputData({ key: question.key }, "");
			setFieldErrorFn(question.key, "");
		}
	};

	const handleChange = (e) => {
		let val;

		if (refrigerantCharge || amps) {
			val = e.target.value;
		} else if (voltage) {
			val = Number.parseInt(e.target.value, 10) || "";
		} else {
			val = e.target.value.toUpperCase();
		}

		if (val === null) {
			val = "";
		}

		const invalidValues = ["NOTFOUND", "NA", "UNKNOWN"];
		if (invalidValues.includes(val)) {
			setAlertModal({
				show: true,
				title: "Warning",
				body: `Please enter a valid value for field ${question.label}`,
			});
			setTimeout(closeAlertModal, 3000);

			val = "";
		}

		setInputData(question, val);

		if (
			question.required === "true" &&
			!responseData[question.checkbox.key] &&
			(!val || val === "")
		) {
			setFieldErrorFn(question.key, `${question.label} field is required`);
		} else {
			setFieldErrorFn(question.key, "");
		}
	};

	return (
		<Row key={question.key}>
			<FormGroup hidden={shouldHide()}>
				<div
					className={`d-flex flex-row gap-1 align-items-center ${
						question.checkbox ? "justify-content-between" : ""
					}`}
				>
					<div className="d-flex flex-row gap-1">
						<Label
							className={`${question.checkbox ? "mb-0" : "single-form-label"}`}
						>
							{question.label}
							{question.required === "true" && (
								<span style={{ color: "red" }}> *</span>
							)}
						</Label>

						{!hidePreview ? (
							<PreviewField
								question={question}
								responseData={responseData}
								fromChatbot={fromChatbot}
							/>
						) : null}
						{!responseData[question.key] && placeholder && (
							<GeneratedPropIcon />
						)}
					</div>

					{question.checkbox ? (
						<CheckboxInput
							key={question.checkbox.key}
							question={question.checkbox}
							responseData={responseData}
							setInputData={(checkboxKey, checkboxValue) =>
								handleCheckboxChange(checkboxKey, checkboxValue)
							}
							notStandalone={true}
						/>
					) : null}
				</div>
				<div
					className="position-relative"
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<Input
						type={refrigerantCharge || voltage || amps ? "number" : "text"}
						onChange={handleChange}
						className={`text-area ${
							fieldError[question.key] ? "error-border" : ""
						} ${question.highlighted ? "highlight-input" : ""}`}
						style={customStyles}
						value={responseData[question.key]}
						disabled={shouldDisable()}
						placeholder={
							placeholder || question.placeholder || "Enter text here"
						}
						onPaste={(e) => {
							if (
								question.label === "Model Number" ||
								question.label === "Serial Number"
							) {
								preventSpecialChars(e.clipboardData.getData("text"), e, true);
							}
						}}
						onKeyPress={(e) => {
							if (
								question.label === "Model Number" ||
								question.label === "Serial Number"
							) {
								preventSpecialChars(e.key, e, true);
							}
						}}
					/>
					{fieldError[question.key] && (
						<div
							className="error-message"
							style={{ display: "block", color: "red" }}
						>
							{fieldError[question.key]}
						</div>
					)}
					{question.unitMeasurementDropdown && (
						<select
							className="input-dropdown"
							onChange={(e) => {
								setInputData(question.unitMeasurementDropdown, e.target.value);
							}}
							value={responseData[question.unitMeasurementDropdown.key] || ""}
						>
							<option key={"default"} value="" disabled></option>
							{question.unitMeasurementDropdown.options.map((unit) => (
								<option key={unit} value={unit}>
									{unit}
								</option>
							))}
						</select>
					)}
					{/* Display OCR Confidence */}
					{ocrConfidenceAndColorObj.color &&
						ocrConfidenceAndColorObj.confidence && (
							<span
								className="confidence-text"
								style={{
									color: ocrConfidenceAndColorObj.color,
									position: "absolute",
									right: question.unitMeasurementDropdown ? "60px" : "10px",
									top: "50%",
									transform: "translateY(-50%)",
									pointerEvents: "none",
								}}
							>
								{ocrConfidenceAndColorObj.confidence}%
							</span>
						)}
				</div>
			</FormGroup>
		</Row>
	);
};

export { TextInput };
