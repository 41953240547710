import { useUpdateOrganization } from "@/hooks/organizationQueries";
import {
	addIntegration,
	deleteIntegration,
	getIntegrations,
	startIntegrationSyncJob,
} from "@/services/integration-service";
import { ThemeModeToggle } from "@components/layouts/SignedIn/ThemeMode/ThemeModeToggle";
import { ConfirmDialog } from "@components/layouts/SignedIn/views/Locations/children/ConfirmDialog";
import { useSelfUpdateUser } from "@hooks/userQueries";
import { useUserStore } from "@stores";
import { Button, Card, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	CardBody,
	CardHeader,
	FormGroup,
	Input,
	Label,
	Toast,
	ToastBody,
	ToastHeader,
} from "reactstrap";
import { Loader } from "../../../Loader/Loader";

export const SettingsTab = (props) => {
	// Init variables
	const { isFocused } = props;
	const navigate = useNavigate();
	// Theme-related variables, states, and functions
	const { themeMode, setThemeMode, user } = useUserStore();
	const { userOrganization, setUserOrganization } = useUserStore((state) => ({
		userOrganization: state.userOrganization,
		setUserOrganization: state.setUserOrganization,
	}));
	const updateUser = useSelfUpdateUser();
	const [themeModeChoice, setThemeModeChoice] = useState(themeMode);
	const { mutate: updateOrganization } = useUpdateOrganization();
	const toggleTheme = () => {
		setThemeModeChoice(themeModeChoice === "dark" ? "light" : "dark");
	};
	// Toast Related States
	const [toastVisible, setToastVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [toastColor, setToastColor] = useState("success");
	const [userIntegrationSettings, setUserIntegrationSettings] = useState(
		userOrganization?.config?.integration_settings,
	);

	// Autosave-related variables, states, and functions
	const [autoSaveDrafts, setAutoSaveDrafts] = useState(false);
	const [autoSaveDraftsChoice, setAutoSaveDraftsChoice] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [useCameraOnPhotoCapture, setUseCameraOnPhotoCapture] = useState(false);
	const [useCameraOnPhotoCaptureChoice, setUseCameraOnPhotoCaptureChoice] =
		useState(user?.user_config?.use_camera_on_photo_capture);

	const [availableIntegrations, setAvailableIntegrations] = useState([]);
	const [_integrationSettingsState, setIntegrationSettingsState] = useState({
		isAddingNewIntegration: false,
		newIntegrationKey: "",
	});

	useEffect(() => {
		setUseCameraOnPhotoCaptureChoice(user.useCameraOnPhotoCapture);
	}, [user.useCameraOnPhotoCapture]);

	useEffect(() => {
		async function fetchIntegrations() {
			setIsLoading(true);
			const integrations = await getIntegrations().finally(() =>
				setIsLoading(false),
			);
			setAvailableIntegrations(integrations);
		}
		fetchIntegrations();
	}, []);

	const _handleIntegrationUpdate = (id, updatedSettings) => {
		const updatedIntegrationSettings = {
			...userIntegrationSettings,
			[id]: updatedSettings,
		};

		setUserIntegrationSettings(updatedIntegrationSettings);

		const prevOrgData = userOrganization;
		setUserOrganization({
			...prevOrgData,
			config: {
				...prevOrgData.config,
				integration_settings: updatedIntegrationSettings,
			},
		});
	};

	const _handleAddNewIntegration = async (name, credentials) => {
		try {
			setIsLoading(true);
			const integrationConfig = availableIntegrations.find(
				(integration) => integration.name === name,
			);
			if (!integrationConfig) {
				throw new Error("Integration not found.");
			}
			const newIntegration = {
				is_enabled: true,
				credentials: credentials,
				name: name,
				id: integrationConfig.id,
				key: integrationConfig.key,
				sync_config: {
					invoice: {
						is_enabled: true,
					},
					workOrder: {
						is_enabled: true,
					},
					location: {
						is_enabled: true,
					},
					asset: {
						is_enabled: true,
					},
				},
			};
			const prevOrgData = userOrganization;
			const updatedIntegrationSettings = {
				...prevOrgData.config.integration_settings,
				[newIntegration.id]: newIntegration,
			};
			addIntegration(userOrganization.id, newIntegration.id, newIntegration);
			setUserOrganization({
				...prevOrgData,
				config: {
					...prevOrgData.config,
					integration_settings: updatedIntegrationSettings,
				},
			});
			setUserIntegrationSettings(updatedIntegrationSettings);
			setToastMessage("Integration added successfully!");
			setToastColor("success");
		} catch (_error) {
			setToastMessage("Failed to add integration.");
			setToastColor("danger");
		} finally {
			setToastVisible(true);
			handleHideToast();
			setIntegrationSettingsState((prev) => ({
				...prev,
				isAddingNewIntegration: false,
				newIntegrationKey: "",
			}));
			setIsLoading(false);
		}
	};

	const handleHideToast = (duration = 2500) => {
		if (Number.isNaN(duration)) {
			setToastVisible(false);
			return;
		}
		setTimeout(() => {
			setToastVisible(false);
		}, Number(duration));
	};

	const _handleNewIntegrationChange = (event) => {
		const selectedIntegrationKey = event.target.value;
		setIntegrationSettingsState((prev) => ({
			...prev,
			newIntegrationKey: selectedIntegrationKey,
			isAddingNewIntegration: true,
		}));
	};

	const _handleIntegrationDelete = async (integrationId) => {
		try {
			setIsLoading(true);
			const prevOrgData = userOrganization;
			await deleteIntegration(userOrganization.id, integrationId);
			const updatedIntegrationSettings = {
				...prevOrgData.config.integration_settings,
			};
			delete updatedIntegrationSettings[integrationId];
			setUserOrganization({
				...prevOrgData,
				config: {
					...prevOrgData.config,
					integration_settings: updatedIntegrationSettings,
				},
			});
			setUserIntegrationSettings(updatedIntegrationSettings);
			setToastMessage("Integration deleted successfully!");
			setToastColor("success");
		} catch (_error) {
			setToastMessage("Failed to delete integration.");
			setToastColor("danger");
		} finally {
			setIsLoading(false);
			setToastVisible(true);
			handleHideToast();
		}
	};

	const _handleIntegrationToggle = (integrationId) => {
		if (!integrationId) {
			console.error("Integration key is undefined or invalid.");
			return;
		}

		const prevOrgData = userOrganization;
		const prevIntegrationSettings = prevOrgData.config.integration_settings;
		const prevIntegrationSettingsItem = prevIntegrationSettings[integrationId];
		const updatedIntegrationSettings = {
			...prevIntegrationSettings,
			[integrationId]: {
				...prevIntegrationSettingsItem,
				is_enabled: !prevIntegrationSettingsItem.is_enabled,
			},
		};
		setUserOrganization({
			...prevOrgData,
			config: {
				...prevOrgData.config,
				integration_settings: updatedIntegrationSettings,
			},
		});
		setUserIntegrationSettings(updatedIntegrationSettings);
	};

	const _handleIntegrationSyncJobTrigger = async (integrationId, config) => {
		try {
			await startIntegrationSyncJob(userOrganization.id, integrationId, config);
			setToastMessage("Integration sync job started successfully!");
			setToastColor("success");
		} catch (error) {
			setToastMessage(
				error?.message || "Failed to start integration sync job.",
			);
			setToastColor("danger");
		} finally {
			setToastVisible(true);
			handleHideToast();
		}
	};

	useEffect(() => {
		let choice;
		if (userOrganization?.config?.auto_save_drafts === undefined) {
			choice = true;
		} else {
			choice = userOrganization?.config?.auto_save_drafts;
		}
		setAutoSaveDrafts(choice);
		setAutoSaveDraftsChoice(choice);
	}, [userOrganization?.config?.auto_save_drafts]);

	// Modal-related variables, states, and functions
	const [modalOpen, setModalOpen] = useState(false);
	const toggleModal = () => setModalOpen(!modalOpen);

	const saveChanges = async () => {
		try {
			// Apply other settings
			setIsLoading(true);
			setThemeMode(themeModeChoice);
			// Set antd global to dark mode or default

			setAutoSaveDrafts(autoSaveDraftsChoice);
			setUseCameraOnPhotoCapture(useCameraOnPhotoCaptureChoice);

			// Update user integration settings
			updateUser.mutate({
				use_camera_on_photo_capture: useCameraOnPhotoCaptureChoice,
			});

			// Ensure we use the updated integration settings from state
			updateOrganization({
				config: {
					...userOrganization.config,
					auto_save_drafts: autoSaveDraftsChoice,
					integration_settings: userIntegrationSettings, // Use the accumulated settings
				},
			});

			// Show success toast
			message.success("Settings saved successfully!");
		} catch (_error) {
			message.error("Failed to save settings.");
		} finally {
			setIsLoading(false);
			toggleModal();
		}
	};

	// Nav-away state & function
	const [navigatedAway, setNavigatedAway] = useState(false);

	useEffect(() => {
		if (!isFocused && navigatedAway) {
			setNavigatedAway(true);
		} else if (isFocused) {
			setNavigatedAway(false);
		}
	}, [isFocused, navigatedAway]);

	useEffect(() => {
		const resetChoices = () => {
			setThemeModeChoice(themeMode);
			setAutoSaveDraftsChoice(autoSaveDrafts);
			setUseCameraOnPhotoCaptureChoice(useCameraOnPhotoCapture);
		};

		if (navigatedAway) {
			resetChoices();
		}
	}, [autoSaveDrafts, navigatedAway, themeMode, useCameraOnPhotoCapture]);

	const handleAutoSaveChange = (e) => {
		setAutoSaveDraftsChoice(e.target.checked);
	};

	const handleCameraChange = (e) => {
		setUseCameraOnPhotoCaptureChoice(e.target.checked);
	};

	return (
		<div className="mt-4 mx-auto p-4">
			{/* Toast Notification */}
			<div
				aria-live="polite"
				aria-atomic="true"
				className="position-fixed w-100 d-flex justify-content-center"
				style={{
					top: "1rem",
					left: "0",
					zIndex: 9999,
				}}
			>
				<Toast
					isOpen={toastVisible}
					style={{
						minWidth: "50dvw",
						display: "flex",
						justifyContent: "center",
						alignSelf: "center",
						flexDirection: "column",
						left: "unset",
						color: "white",
					}}
					className={`bg-${toastColor}`}
					fade
					onClose={() => setToastVisible(false)}
				>
					<ToastHeader
						style={{ color: "white" }}
						className={`bg-${toastColor}`}
						toggle={() => setToastVisible(false)}
					>
						Notification
					</ToastHeader>
					<ToastBody>{toastMessage}</ToastBody>
				</Toast>
			</div>

			{!isLoading ? (
				<div className="settings-tab-content">
					<Card className="mb-3">
						<CardHeader className="settings-tab-card-header">
							Property Report Settings
						</CardHeader>
						<CardBody>
							<FormGroup switch className="mb-2">
								<Label check>Auto Save Drafts</Label>
								<Input
									type="switch"
									role="switch"
									className="toggle-label-admin"
									checked={autoSaveDraftsChoice}
									onChange={handleAutoSaveChange}
									disabled={isLoading}
								/>
							</FormGroup>
							<FormGroup switch>
								<Label check>Use Camera on Photo Capture</Label>
								<Input
									type="switch"
									role="switch"
									className="toggle-label-admin"
									checked={useCameraOnPhotoCaptureChoice}
									onChange={handleCameraChange}
									disabled={isLoading}
								/>
							</FormGroup>
						</CardBody>
					</Card>
					<Card className="mb-3">
						<CardHeader className="settings-tab-card-header">
							Theme Settings
						</CardHeader>
						<CardBody>
							<ThemeModeToggle
								themeModeChoice={themeModeChoice}
								toggleTheme={toggleTheme}
								disabled={isLoading}
							/>
						</CardBody>
					</Card>
					{/* {userIntegrationSettings && availableIntegrations.length ? (
						<Card className='mb-3'>
							<IntegrationSettings
								integrationSettings={userIntegrationSettings}
								integrationIds={Object.keys(
									userIntegrationSettings || {}
								)}
								availableIntegrations={availableIntegrations}
								onUpdate={handleIntegrationUpdate}
								onDelete={handleIntegrationDelete}
								onToggleIntegration={handleIntegrationToggle}
								onSetIsAddingNewIntegration={(e) =>
									setIntegrationSettingsState((prev) => ({
										...prev,
										isAddingNewIntegration: e,
										newIntegrationKey: '',
									}))
								}
								onAddNewIntegration={handleAddNewIntegration}
								onNewIntegrationChange={
									handleNewIntegrationChange
								}
								onStartIntegrationSyncJob={
									handleIntegrationSyncJobTrigger
								}
								isAddingNewIntegration={
									integrationSettingsState.isAddingNewIntegration
								}
								newIntegrationKey={
									integrationSettingsState.newIntegrationKey
								}
								disabled={isLoading}
							/>
						</Card>
					) : null} */}
				</div>
			) : (
				// Add a nice header as well noting that the settings are loading
				<Card className="mb-3">
					<CardHeader className="settings-tab-card-header">
						Loading Settings...
					</CardHeader>
					<CardBody>
						<Loader />
					</CardBody>
				</Card>
			)}
			<div className="d-flex justify-content-start mt-3">
				<Button
					className="me-2"
					type="primary"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						toggleModal();
					}}
					disabled={isLoading}
				>
					Save Changes
				</Button>
				<Button
					type="default"
					variant="solid"
					onClick={() => navigate("/releases")}
					disabled={isLoading}
				>
					View Releases
				</Button>
			</div>
			<ConfirmDialog
				showConfirm={modalOpen}
				toggle={toggleModal}
				title="Save Changes?"
				body="Are you sure you want to save these changes?"
				functionality={saveChanges}
			/>
		</div>
	);
};
