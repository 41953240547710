import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { LuUpload } from "react-icons/lu";

export const Dropzone = ({ actionProvider }: any): any => {
	const [dragging, setDragging] = useState<any>(false);
	const lastDragEventRef = useRef<any>(null); // Used to store the timestamp of the last dragover event

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (files) => {
			if (actionProvider.current) {
				if (actionProvider.current.handleFileAction) {
					actionProvider.current.handleFileAction(files[0]);
				}
			} else {
				console.log("ActionProvider.current is undefined");
			}

			setDragging(false);
		},

		noClick: true,
	});

	// dropzone handler for drag events
	useEffect(() => {
		const handleDragEnter = (e: any) => {
			e.preventDefault();
			e.stopPropagation();
			setDragging(true);
		};

		const handleDragOver = (e: any) => {
			e.preventDefault();
			e.stopPropagation();
			lastDragEventRef.current = Date.now(); // Update the timestamp
		};

		const handleDragLeave = (e: any) => {
			e.preventDefault();
			e.stopPropagation();

			// If it's been more than 50ms since the last dragover event, assume the drag has ended
			setTimeout(() => {
				if (Date.now() - lastDragEventRef.current > 50) {
					setDragging(false);
				}
			}, 50);
		};
		const handleDrop = (e: any) => {
			e.preventDefault(); // This prevents the browser from navigating or opening the file.

			if (!e.target.closest(".my-dropzone-class")) {
				setDragging(false);
			}
		};

		window.addEventListener("dragenter", handleDragEnter);
		window.addEventListener("dragover", handleDragOver);
		window.addEventListener("dragleave", handleDragLeave);
		window.addEventListener("drop", handleDrop);

		return () => {
			window.removeEventListener("dragenter", handleDragEnter);
			window.removeEventListener("dragover", handleDragOver);
			window.removeEventListener("dragleave", handleDragLeave);
			window.removeEventListener("drop", handleDrop);
		};
	}, []);

	return (
		<div
			className="d-flex flex-column justify-content-center align-items-center"
			{...getRootProps()}
			style={{
				width: "100%",
				height: "100%",
				position: "absolute",
				top: 0,
				left: 0,
				border: dragging ? "2px dashed rgb(34, 139, 34)" : "",
				borderRadius: 8,
				zIndex: dragging ? 100 : -1,
				backgroundColor: "white",
				opacity: dragging ? 1 : 0,
			}}
		>
			<input {...getInputProps()} />
			<div className="d-flex flex-column justify-content-center align-items-center">
				<LuUpload style={{ fontSize: 120, color: "#c9c9c9" }} />
				<div className="d-flex">
					<span className="fs-5">Drop your file here</span>
				</div>
			</div>
		</div>
	);
};
