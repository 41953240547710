import { formatMillions } from "@/utils/formatNumberToMillions";
import logo from "@assets/img/FacilityAI_Logo_Mini.svg";

const YearBudgetInsights = ({
	assets,
	budget,
	budgetShortage,
	leftover,
	selectedYear,
	years,
	chartLoading,
	width,
}) => {
	let totalReplCost = assets.reduce((acc, asset) => {
		const replCost = Number(asset?.avgReplCostUSD);
		if (Number.isNaN(replCost)) {
			return acc;
		}
		return acc + replCost;
	}, 0);
	// convert to dollars
	totalReplCost = formatMillions(totalReplCost);
	return (
		<>
			{budget.every((val) => !!val) && !selectedYear && !chartLoading && (
				<div
					className="d-flex mb-2"
					style={{
						marginLeft: "70px",
						width: width,
					}}
				></div>
			)}
			<h6 className="fw-bold text-center" style={{ paddingTop: "8px" }}>
				<img src={logo} style={{ width: "40px", marginRight: "6px" }} />
				{selectedYear && selectedYear} Budget Insights:
			</h6>
			{assets.length > 0 && (
				<ul
					// style={{ marginLeft: '92px', position: 'absolute' }}
					className="year-budget-kpi"
				>
					<li>
						<strong>{totalReplCost}</strong> Total Replacement Cost
					</li>
					<li>
						<strong>{assets.length.toLocaleString()}</strong> Assets
					</li>
				</ul>
			)}
		</>
	);
};

export { YearBudgetInsights };
