import { assetPredict } from "@/services/alfred-service";

/**
 * Sends a asset image url to the backend which will try to predict the asset type (if the org is enabled / has a model)
 * If the confidence of the model is < 80% then we don't generate the suggestions
 */
export const generateAssetTypeSuggestions = async (
	url: string,
	org: any,
	responseData: any,
	setResponseData: any,
	identifyPrompt = false,
): Promise<any> => {
	return assetPredict(url, org)
		.then((result) => {
			//Breaking early if bad confidence from model
			if (result.conf <= 0.75) {
				console.log("Model confidence too low, not generating suggestions");
				return;
			}
			!identifyPrompt &&
				setResponseData({
					...responseData,
					assetTypeSuggestions: result.predictions,
				});
			return result;
		})
		.catch((e) => {
			console.log(`Info From Alfred Service: ${e}`);
		});
};
