import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const handleAssetTypeInput = async (
	setAssetTypeInput,
	setState,
	response,
	saveDataCaptureState,
) => {
	let prevMessageNoWidget;
	let userMessage;
	if (response === "Other") {
		prevMessageNoWidget = createChatBotMessage(
			"Thank you for the photos. Is the asset type one of the below?",
			{
				withAvatar: true,
			},
		);

		userMessage = createClientMessage("Other");
	} else {
		prevMessageNoWidget = createChatBotMessage(
			"Did you mean one of the options below?",
			{
				withAvatar: true,
			},
		);

		userMessage = createClientMessage("No, another option.");
	}

	const res = createChatBotMessage("Please enter the asset type below:", {
		withAvatar: true,
	});

	saveDataCaptureState([userMessage, res]);
	setState((prev) => ({
		...prev,
		messages: [
			...prev.messages.slice(0, -1),
			prevMessageNoWidget,
			userMessage,
			res,
		],
		stage: "assetDetection",
		showOptions: false,
	}));

	setAssetTypeInput(true);
};
