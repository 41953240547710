import { useGetSelfOrganization } from "@/hooks/organizationQueries";
import { Loader } from "@SignedIn/Loader/Loader";
import { AlfredsNotes } from "@components/layouts/SignedIn/AlfredsNotes/AlfredsNotes";
import { Carousel } from "@components/layouts/SignedIn/views/Dashboard/Carousel/Carousel";
import { ChatbotMainCard } from "@components/layouts/SignedIn/views/Dashboard/ChatbotMainCard/ChatbotMainCard";
import { useGraph } from "@contexts/graphContext";
import { useUserStore } from "@stores";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const Dashboard = ({ setDisplayToast }) => {
	const user = useUserStore((state) => state.user);
	const org = user.organization.external_firebase_id;
	const { dashboardGraphs, setShouldFlashId } = useGraph();
	const navigate = useNavigate();
	const {
		userOrganization,
		error: userOrganizationError,
		isLoading: isLoadingUserOrganization,
	} = useGetSelfOrganization();

	const isMobile = window.innerWidth < 768;

	// Clear flash state on unmount
	useEffect(() => () => setShouldFlashId(null), [setShouldFlashId]);

	return isLoadingUserOrganization ? (
		<Loader />
	) : (
		<div className="dashboard mb-3 mb-md-0">
			<div className="d-block d-md-none">
				<Button
					style={{
						backgroundColor: "rgb(34, 139, 34)",
						// border: 'none',
						marginBottom: "1rem",
					}}
					className="btn w-100 d-flex justify-content-center align-items-center p-2 rounded-4"
					onClick={() => navigate("/forms")}
				>
					<span style={{ fontSize: "22px" }}>Data Capture</span>
				</Button>
			</div>
			<Row className="light-border-b row-1 mb-2 mb-md-0">
				<Col
					className="col-12 col-sm-12 col-xl-5 mb-5"
					style={{ height: isMobile ? "calc(100vh - 100px)" : "" }}
				>
					<ChatbotMainCard />
				</Col>
				<Col className="col-12 col-xl-7 mb-2 mb-md-0 d-flex justify-content-center align-items-center">
					<AlfredsNotes
						healthScore={userOrganization.health.health_score}
						organization={org}
						setDisplayToast={setDisplayToast}
					/>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Carousel graphs={dashboardGraphs} setDisplayToast={setDisplayToast} />
			</Row>
		</div>
	);
};

export { Dashboard };
