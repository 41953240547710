import { FormGroup, Input, Label, Row } from "reactstrap";

const CheckboxInput = ({
	question,
	responseData,
	setInputData,
	notStandalone,
}) => {
	const _shouldHide = () => {
		if (Array.isArray(question.altTiedTo)) {
			return false;
		}
		if (
			question.key === "refTypeNotFound" &&
			responseData[question.altTiedTo] !== "Self Contained Asset"
		) {
			return true;
		}
		if (
			question.key === "chargeNotFound" &&
			responseData[question.altTiedTo] !== "Self Contained Asset"
		) {
			return true;
		}
		return false;
	};

	const shouldDisable = () => {
		if (typeof question.altTiedTo === "string") {
			const answer = responseData[question.altTiedTo];
			if (!answer || answer.length === 0) return false;
			return true;
		}
		if (Array.isArray(question.altTiedTo)) {
			for (const tag of question.altTiedTo) {
				if (tag.toLowerCase().includes("image")) {
				} else {
					const answer = responseData[tag];
					if (answer && answer.length > 0) return true;
				}
			}
			return false;
		}
	};

	let label;
	if (question.label.includes("Not Found")) {
		label = "Not Found";
	} else {
		label = question.label;
	}

	let cName;
	if (notStandalone) {
		cName = "mb-0 p-0";
	}

	const checkbox = (
		<FormGroup
			className={cName}
			switch
			// hidden={shouldHide()}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Input
					className="fs-4"
					key={question.key}
					type="switch"
					onChange={(e) => {
						setInputData(question, e.target.checked);
					}}
					checked={Boolean(responseData[question.key])}
					disabled={shouldDisable()}
				/>

				<Label className="m-0 fw-light">{label}</Label>
			</div>
		</FormGroup>
	);

	return notStandalone ? checkbox : <Row key={question.key}>{checkbox}</Row>;
};

export { CheckboxInput };
