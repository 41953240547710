import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";

export const CushmanQCStatsCard = (props: { locationId: string | number }) => {
	const { locationId } = props;

	const [data, setData] = useState({} as any);
	const [isLoading, setIsLoading] = useState(true);

	const getData = async () => {
		try {
			const url = `${ALFRED_SERVICE_URL}/cushman/qc_stats/${locationId}`;
			const res = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			const data = await res.json();
			setData(data);
		} catch {
			setData({
				date: {
					label: "Date of Visit",
					value: "n/a",
				},
				ticketNeeded: {
					label: "Ticket Needed",
					value: "n/a",
				},
				ticketValidated: {
					label: "Ticket Validated",
					value: "n/a",
				},
				recallWork: {
					label: "Recall Work",
					value: "n/a",
				},
			});
		}
	};

	useEffect(() => {
		if (locationId) {
			getData();
		}
	}, [locationId]);

	useEffect(() => {
		if (data) {
			setIsLoading(false);
		}
	}, [data]);

	return (
		<Card className="px-5 py-2 d-flex flex-column gen-info-card-wrapper gen-tab-submission sub-vh-75 h-100">
			<CardHeader className="absorbed-tickets-title d-flex flex-column flex-sm-row justify-content-between align-items-center">
				Most Recent QC Checklist Stats
			</CardHeader>
			{isLoading ? (
				<Loader small={true} />
			) : (
				<CardBody>
					<Table className="absorbed-tickets-table" responsive hover>
						<thead>
							<tr>
								<th>{data?.date?.label}</th>
								<th>{data?.ticketNeeded?.label}</th>
								<th>{data?.ticketValidated?.label}</th>
								<th>{data?.recallWork?.label}</th>
							</tr>
						</thead>
						<tbody>
							<tr key={"qc-stat-row"}>
								<td>{data?.date?.value}</td>
								<td>{data?.ticketNeeded?.value}</td>
								<td>{data?.ticketValidated?.value}</td>
								<td>{data?.recallWork?.value}</td>
							</tr>
						</tbody>
					</Table>
				</CardBody>
			)}
		</Card>
	);
};
