import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { Loader } from "@SignedIn/Loader/Loader";
import { CostSavingsCard } from "@SignedIn/views/Dashboard/CostSavingsCard/CostSavingsCard";
import { StatsCard } from "@SignedIn/views/Dashboard/StatsCard/StatsCard";
import { type SetStateAction, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const CWDashboard = () => {
	const [data, setData] = useState<any>();
	const [absorbedData, setAbsorbedData] = useState<any>();
	const [totalSubmissions, setTotalSubmissions] = useState<number>();
	const [totalSubmissionsTrend, setTotalSubmissionsTrend] = useState<number>();
	const [prevAvgSubmissions, setPrevAvgSubmissions] = useState<number>();

	const [totalPreventedTrend, setTotalPreventedTrend] = useState<number>();
	const [prevAvgPrevented, setPrevAvgPrevented] = useState<number>();

	const [absorbedSavingsTrend, setAbsorbedSavingsTrend] = useState<number>();
	const [prevAbsorbedSavings, setPrevAbsorbedSavings] = useState<number>();

	const [absorbedTicketsTrend, setAbsorbedTicketsTrend] = useState<number>();
	const [prevAbsorbedTickets, setPrevAbsorbedTickets] = useState<number>();

	const [costTrendLoading, setCostTrendLoading] = useState(false);
	const [absorbedTrendLoading, setAbsorbedTrendLoading] = useState(false);

	const [monthOptions, setMonthOptions] = useState<any>();
	const [activeMonth, setActiveMonth] = useState<any>();
	const [isCurrentMonth, setIsCurrentMonth] = useState(false);
	// Day 1 of this month at midnight for start range
	const [startDate, setStartDate] = useState(() => {
		const date = new Date();
		date.setDate(1);
		date.setHours(0);
		return date;
	});
	// Day 1 of next month at midnight for end range
	const [endDate, setEndDate] = useState(() => {
		const date = new Date();
		date.setMonth(date.getMonth() + 1);
		date.setDate(1);
		date.setHours(0);
		return date;
	});
	const [isLoading, setIsLoading] = useState(true);

	const getCostSavings = async () => {
		const url = `${ALFRED_SERVICE_URL}/cushman/cost_savings?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});
		const data = await res.json();
		const savingsData = data.savingsData;
		setTotalSubmissions(data?.submissions?.length || 0);
		setData(savingsData);

		getCostTrend(savingsData, data);
	};

	const getCostTrend = async (
		savingsData: { total: { savings: string } },
		data: { submissions: string | any[] },
	) => {
		setCostTrendLoading(true);
		// Check if startDate month is the current month
		const isThisMonth = startDate.getMonth() === new Date().getMonth();
		setIsCurrentMonth(isThisMonth);

		// Get total submissions trend
		const threeMonthsAgoStart = new Date(startDate);
		threeMonthsAgoStart.setMonth(startDate.getMonth() - 3);
		threeMonthsAgoStart.setDate(1);
		threeMonthsAgoStart.setHours(0);
		const threeMonthsAgoEnd = isThisMonth ? new Date() : new Date(startDate);
		threeMonthsAgoEnd.setMonth(
			isThisMonth ? startDate.getMonth() - 3 : startDate.getMonth() - 2,
		);
		threeMonthsAgoEnd.setHours(0);

		const twoMonthsAgoStart = new Date(startDate);
		twoMonthsAgoStart.setMonth(startDate.getMonth() - 2);
		twoMonthsAgoStart.setDate(1);
		twoMonthsAgoStart.setHours(0);
		const twoMonthsAgoEnd = isThisMonth ? new Date() : new Date(startDate);
		twoMonthsAgoEnd.setMonth(
			isThisMonth ? startDate.getMonth() - 2 : startDate.getMonth() - 1,
		);
		twoMonthsAgoEnd.setHours(0);

		const oneMonthAgoStart = new Date(startDate);
		oneMonthAgoStart.setMonth(startDate.getMonth() - 1);
		oneMonthAgoStart.setDate(1);
		oneMonthAgoStart.setHours(0);
		const oneMonthAgoEnd = isThisMonth ? new Date() : new Date(startDate);
		oneMonthAgoEnd.setMonth(
			isThisMonth ? startDate.getMonth() - 1 : startDate.getMonth(),
		);
		oneMonthAgoEnd.setHours(0);

		await Promise.all([
			fetch(
				`${ALFRED_SERVICE_URL}/cushman/cost_savings?start=${threeMonthsAgoStart.toISOString()}&end=${threeMonthsAgoEnd.toISOString()}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			),
			fetch(
				`${ALFRED_SERVICE_URL}/cushman/cost_savings?start=${twoMonthsAgoStart.toISOString()}&end=${twoMonthsAgoEnd.toISOString()}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			),
			fetch(
				`${ALFRED_SERVICE_URL}/cushman/cost_savings?start=${oneMonthAgoStart.toISOString()}&end=${oneMonthAgoStart.toISOString()}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			),
		]).then(async ([threeMonthsAgoData, twoMonthsAgoData, oneMonthAgoData]) => {
			const threeMonthsData = await threeMonthsAgoData.json();
			const twoMonthsData = await twoMonthsAgoData.json();
			const oneMonthData = await oneMonthAgoData.json();

			const avgThreeMonthsSubmissions =
				((threeMonthsData.submissions.length || 0) +
					(twoMonthsData.submissions.length || 0) +
					(oneMonthData.submissions.length || 0)) /
				3;
			const submissionsTrend = Math.round(
				((data.submissions.length - avgThreeMonthsSubmissions || 0) /
					avgThreeMonthsSubmissions) *
					100,
			);
			setTotalSubmissionsTrend(submissionsTrend);
			setPrevAvgSubmissions(avgThreeMonthsSubmissions);

			// Get total prevented trend
			const avgThreeMonthsPrevented =
				((Number.parseFloat(
					threeMonthsData.savingsData.total.savings.replace(/,/g, ""),
				) || 0) +
					(Number.parseFloat(
						twoMonthsData.savingsData.total.savings.replace(/,/g, ""),
					) || 0) +
					(Number.parseFloat(
						oneMonthData.savingsData.total.savings.replace(/,/g, ""),
					) || 0)) /
				3;

			const preventedTrend = Math.round(
				((Number.parseFloat(savingsData.total.savings.replace(/,/g, "")) -
					avgThreeMonthsPrevented) /
					avgThreeMonthsPrevented) *
					100,
			);

			setTotalPreventedTrend(preventedTrend);
			setPrevAvgPrevented(avgThreeMonthsPrevented);
			setCostTrendLoading(false);
		});
	};

	const getAbsorbedTicketsData = async () => {
		const url = `${ALFRED_SERVICE_URL}/cushman/absorbed_tickets?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});
		const data = await res.json();
		setAbsorbedData(data);

		getAbsorbedTicketsTrend(data);
	};

	const getAbsorbedTicketsTrend = async (data: {
		count: number;
		savings: string | number;
	}) => {
		setAbsorbedTrendLoading(true);
		const isThisMonth = startDate.getMonth() === new Date().getMonth();

		const threeMonthsAgoStart = new Date(startDate);
		threeMonthsAgoStart.setMonth(startDate.getMonth() - 3);
		threeMonthsAgoStart.setDate(1);
		threeMonthsAgoStart.setHours(0);
		const threeMonthsAgoEnd = isThisMonth ? new Date() : new Date(startDate);
		threeMonthsAgoEnd.setMonth(
			isThisMonth ? startDate.getMonth() - 3 : startDate.getMonth() - 2,
		);
		threeMonthsAgoEnd.setHours(0);

		const twoMonthsAgoStart = new Date(startDate);
		twoMonthsAgoStart.setMonth(startDate.getMonth() - 2);
		twoMonthsAgoStart.setDate(1);
		twoMonthsAgoStart.setHours(0);
		const twoMonthsAgoEnd = isThisMonth ? new Date() : new Date(startDate);
		twoMonthsAgoEnd.setMonth(
			isThisMonth ? startDate.getMonth() - 2 : startDate.getMonth() - 1,
		);
		twoMonthsAgoEnd.setHours(0);

		const oneMonthAgoStart = new Date(startDate);
		oneMonthAgoStart.setMonth(startDate.getMonth() - 1);
		oneMonthAgoStart.setDate(1);
		oneMonthAgoStart.setHours(0);
		const oneMonthAgoEnd = isThisMonth ? new Date() : new Date(startDate);
		oneMonthAgoEnd.setMonth(
			isThisMonth ? startDate.getMonth() - 1 : startDate.getMonth(),
		);
		oneMonthAgoEnd.setHours(0);
		await Promise.all([
			fetch(
				`${ALFRED_SERVICE_URL}/cushman/absorbed_tickets?start=${threeMonthsAgoStart.toISOString()}&end=${threeMonthsAgoEnd.toISOString()}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			),
			fetch(
				`${ALFRED_SERVICE_URL}/cushman/absorbed_tickets?start=${twoMonthsAgoStart.toISOString()}&end=${twoMonthsAgoEnd.toISOString()}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			),
			fetch(
				`${ALFRED_SERVICE_URL}/cushman/absorbed_tickets?start=${oneMonthAgoStart.toISOString()}&end=${oneMonthAgoEnd.toISOString()}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				},
			),
		]).then(async ([threeMonthsAgoData, twoMonthsAgoData, oneMonthAgoData]) => {
			const threeMonthsData = await threeMonthsAgoData.json();
			const twoMonthsData = await twoMonthsAgoData.json();
			const oneMonthData = await oneMonthAgoData.json();

			const avgThreeMonthsAbsorbed =
				((threeMonthsData.count || 0) +
					(twoMonthsData.count || 0) +
					(oneMonthData.count || 0)) /
				3;

			const absorbedTicketsTrend = Math.round(
				((data.count - avgThreeMonthsAbsorbed || 0) / avgThreeMonthsAbsorbed) *
					100,
			);
			setAbsorbedTicketsTrend(absorbedTicketsTrend);
			setPrevAbsorbedTickets(avgThreeMonthsAbsorbed);

			// Safely handle the `savings` data, checking if it's a number or a string
			const sanitizeSavings = (savings: any) => {
				if (typeof savings === "number") {
					return savings;
				}
				if (typeof savings === "string") {
					return parseFloat(savings.replace(/,/g, "")) || 0;
				}
				return 0;
			};

			const avgThreeMonthsSavings =
				(sanitizeSavings(threeMonthsData.savings) +
					sanitizeSavings(twoMonthsData.savings) +
					sanitizeSavings(oneMonthData.savings)) /
				3;

			const absorbedSavingsTrend = Math.round(
				((sanitizeSavings(data.savings) - avgThreeMonthsSavings) /
					avgThreeMonthsSavings) *
					100,
			);
			setAbsorbedSavingsTrend(absorbedSavingsTrend);
			setPrevAbsorbedSavings(avgThreeMonthsSavings);
			setAbsorbedTrendLoading(false);
		});
	};

	// Get last 12 months including this month in 'Month Year' format
	const getMonthOptions = () => {
		const options = [];
		for (let i = 0; i < 12; ++i) {
			const date = new Date();
			date.setDate(1);
			date.setMonth(date.getMonth() - i);
			const month = date.toLocaleString("default", { month: "long" });
			const year = date.getFullYear();
			options.push(`${month} ${year}`);
		}
		setMonthOptions(options);
	};

	/**
	 * Inputs a string such as 'december 2024' and returns a datetime
	 * @param dateStr
	 * @returns {Date}
	 */
	const parseDateString = (dateStr: string) => {
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const parts = dateStr.split(" ");
		const monthPart =
			parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase();
		const yearPart = Number.parseInt(parts[1]);

		const monthIndex = months.indexOf(monthPart);
		if (monthIndex === -1) {
			throw new Error("Invalid month name");
		}
		return new Date(yearPart, monthIndex);
	};

	const onMonthChange = (e: SetStateAction<any>) => {
		setIsLoading(true);
		const selectedMonthStart = parseDateString(e);
		const selectedMonthEnd = parseDateString(e);
		selectedMonthEnd.setMonth(selectedMonthEnd.getMonth() + 1);
		setStartDate(selectedMonthStart);
		setEndDate(selectedMonthEnd);
		setActiveMonth(e);
	};

	useEffect(() => {
		getMonthOptions();
		Promise.all([getAbsorbedTicketsData(), getCostSavings()])
			.then(() => setIsLoading(false))
			.catch((error) => console.error("Error in promises", error));
	}, [endDate]);

	return isLoading ? (
		<Loader />
	) : !absorbedData ? (
		<div />
	) : (
		<div className="dashboard">
			<Row className="light-border-b row-1">
				<Col className="col-12">
					<CostSavingsCard
						monthOptions={monthOptions}
						costData={data}
						activeMonth={activeMonth}
						onMonthChange={onMonthChange}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="col-12 col-sm-6 col-xl">
					<StatsCard
						stats={{
							name: "Total Visits",
							data: totalSubmissions,
							color: "#250245",
							icon: "calendar-check",
							id: "svisits",
							trend: totalSubmissionsTrend,
							isCurrentMonth,
							prevAvg: prevAvgSubmissions?.toFixed(1),
							costTrendLoading,
						}}
					/>
				</Col>
				<Col className="col-12 col-sm-6 col-xl">
					<StatsCard
						stats={{
							name: "Total Absorbed Savings",
							data: `$${absorbedData.savings}`,
							color: "#228B22",
							icon: "cash-coin",
							id: "avglifespan",
							trend: absorbedSavingsTrend,
							isCurrentMonth,
							prevAvg: prevAbsorbedSavings,
							absorbedTrendLoading,
						}}
					/>
				</Col>
				<Col className="col-12 col-sm-6 col-xl">
					<StatsCard
						stats={{
							name: "Total Prevented",
							data: `$${data?.total?.savings}`,
							color: "#00ccff",
							icon: "cash-stack",
							id: "activeloc",
							trend: totalPreventedTrend,
							isCurrentMonth,
							prevAvg: prevAvgPrevented,
							costTrendLoading,
						}}
					/>
				</Col>
				<Col className="col-12 col-sm-6 col-xl">
					<StatsCard
						stats={{
							name: "Total Tickets Absorbed",
							data: absorbedData.count.toLocaleString(),
							color: "#fbc658",
							icon: "ticket-perforated",
							id: "alerts",
							trend: absorbedTicketsTrend,
							isCurrentMonth,
							prevAvg: prevAbsorbedTickets?.toFixed(1),
							absorbedTrendLoading,
						}}
					/>
				</Col>
			</Row>
		</div>
	);
};

export { CWDashboard };
