import { useEffect, useState } from "react";
import { Button, Input, List, Modal, ModalBody, ModalHeader } from "reactstrap";
import "@assets/scss/cropper.css";
import { auth } from "@/assets/services/auth-service";
import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { DB_ORG } from "@constants/db";
import { useFormStore, usePageStore } from "@stores";
import { useChatbotStore, useUserStore } from "@stores";
import { FiChevronRight } from "react-icons/fi";
export const NewLocationPicker = (props) => {
	const {
		setChosenLocation,
		organization,
		setUserLocation,
		fromChatbot,
		isOpen: isOpenOtherLocation,
		setIsOpen: setIsOpenOtherLocation,
		onLoadingChange,
		isCheckUps,
	} = props;
	const isMobile = window.innerWidth < 768;

	const { isSidebarOpen } = usePageStore();
	const {
		setAlertModal,
		savedLocation: formSavedLocation,
		showDraftSelectionModal,
		isEditing,
		setAirportCode: setFormAirportCode,
		geoLoading,
		setGeoLoading,
	} = useFormStore();

	const {
		savedLocation: chatbotSavedLocation,
		setAirportCode: setChatbotAirportCode,
	} = useChatbotStore();

	const chosenLocation = fromChatbot ? chatbotSavedLocation : formSavedLocation;
	const setAirportCode = fromChatbot
		? setChatbotAirportCode
		: setFormAirportCode;

	const isAdminOrSuperUser = useUserStore((state) => state.isAdminOrSuperUser);

	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [latLon, setLatLon] = useState({});
	const [geoLocation, setGeoLocation] = useState([]);
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [searchVal, setSearchVal] = useState("");
	const [timeoutId, setTimeoutId] = useState(null);
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

	const getStyles = () => {
		if (isCheckUps) {
			return {
				left: isMobile ? "0px" : isSidebarOpen ? "50%" : "50%",
				transform: isMobile
					? ""
					: isSidebarOpen
						? "translateX(-50%)"
						: "translateX(-50%)",
				position: "absolute",
			};
		}
		return {
			left: isDesktop ? (isSidebarOpen ? "130px" : "40px") : "20px",
		};
	};
	const displayLocationText = () => {
		if (!chosenLocation) {
			return "Select a Location";
		}
		if (organization !== DB_ORG.CUSHMANWAKEFIELD) {
			return `${chosenLocation.name ? `${chosenLocation.name}:` : ""} ${chosenLocation.address1}`;
		}
		return chosenLocation.name;
	};

	const [locationText, setLocationText] = useState(displayLocationText());
	useEffect(() => {
		setLocationText(displayLocationText());
	}, [chosenLocation]);

	const updateMedia = () => {
		setIsDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	useEffect(() => {
		if (onLoadingChange) {
			onLoadingChange(isLoading);
		}
	}, [geoLocation, isLoading, onLoadingChange]);

	const updateSearchVal = (e) => {
		// Clear any existing timeout
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timeout to update the state after 3 seconds
		const newTimeoutId = setTimeout(() => {
			setIsLoading(true);
			setSearchVal(e.target.value);
		}, 2000);

		// Store the timeout ID so it can be cleared if the user types again
		setTimeoutId(newTimeoutId);
	};

	const toggle = () => {
		fromChatbot
			? setIsOpenOtherLocation(!isOpenOtherLocation)
			: setIsOpen(!isOpen);
		const currentlyOpen = fromChatbot ? isOpenOtherLocation : isOpen;

		if (currentlyOpen) {
			setSearchVal("");
			setIsLoading(false);
			if (timeoutId) {
				clearTimeout(timeoutId);
				setTimeoutId(null);
			}
		}
	};

	const getLocations = () => {
		let locations;
		let usingGeo = false;
		if (searchVal === "" && latLon) {
			locations = geoLocation;
			usingGeo = true;
		} else {
			locations = searchedLocations;
		}
		const results = [];

		if (isLoading) {
			return <Loader />;
		}
		if (locations.length === 0 && !usingGeo) {
			return <div>No Search Results</div>;
		}
		locations.forEach((loc, i) => {
			results.push(
				<li
					key={`loc-num-${i}`}
					className="picker-item d-flex flex-column align-items-start"
					onClick={() => {
						selectItem(loc);
						toggle();
					}}
				>
					{usingGeo ? (
						<div style={{ fontSize: "12px" }}>
							{i === 0
								? `Closest Location - ${loc.distance} miles`
								: i === 1
									? `2nd Closest Location - ${loc.distance} miles`
									: i === 2
										? `3rd Closest Location - ${loc.distance} miles`
										: null}
						</div>
					) : null}
					<div>
						{`${
							(loc.name ? `${loc.name}: ` : "") +
							(loc.address || loc.address1 || "")
						}, ${loc.city}, ${loc.state}`}
						{/* <FiChevronRight /> */}
					</div>
				</li>,
			);
		});

		return results;
	};

	const selectItem = (loc) => {
		if (organization !== DB_ORG.CUSHMANWAKEFIELD) {
			if (organization === "SSPAMERICA" && loc.name) {
				if (loc.name.includes("JFK Airport - Terminal 7")) {
					setAirportCode("JFK-T7");
				} else if (loc.name.includes("JFK Terminal 4")) {
					setAirportCode("JFK-T4");
				} else if (loc.name.includes("Warehouse Midwest")) {
					setAirportCode("Warehouse Midwest");
				} else if (loc.name.includes("Warehouse South")) {
					setAirportCode("Warehouse South");
				} else {
					setAirportCode(loc.name.slice(0, 3));
				}
				setLocationText(`${loc.name}: ${loc.address1}`);
			} else {
				setLocationText(`${loc.name}: ${loc.address1}`);
			}
		} else {
			setLocationText(loc.name);
		}
		setChosenLocation(loc);
	};

	const searchForLocation = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		try {
			const url = `${ALFRED_SERVICE_URL}/location/search/location_picker?search_value=${searchVal}`;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});

			if (response.status !== 200) {
				return response.json().then((errorDetails) => {
					throw new Error(
						`Failed to search for location in location picker: ${errorDetails.detail}`,
					);
				});
			}

			const results = await response.json();
			setSearchedLocations(results);
			setIsLoading(false);
		} catch (exception: any) {
			throw new Error(exception);
		}
	};

	const getGeoLocations = async () => {
		// setIsLoading(true);
		try {
			const url = `${ALFRED_SERVICE_URL}/location/geo/search?latitude=${latLon.latitude}&longitude=${latLon.longitude}`;
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});

			if (response.status !== 200) {
				return response.json().then((errorDetails) => {
					throw new Error(
						`Failed to fetch closest location: ${errorDetails.detail}`,
					);
				});
			}

			const closest_location = await response.json();
			if (closest_location === null) {
				setAlertModal({
					title: "No Locations Found",
					body: "No locations were found near you. Please search for a location.",
					show: true,
				});

				setTimeout(() => {
					setAlertModal({
						title: "",
						body: "",
						show: false,
					});
				}, 2000);
				!fromChatbot && toggle();
			} else {
				setGeoLocation(closest_location);
			}
		} catch (exception: any) {
			throw new Error(exception);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if ("geolocation" in navigator && !chosenLocation) {
			setGeoLoading(true);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const loc = {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					};
					setUserLocation(loc);
					setLatLon(loc);
					setGeoLoading(false);
				},
				(error) => {
					setAlertModal({
						title: "Location Services Disabled",
						body: "Please search for a location.",
						show: true,
					});

					setTimeout(() => {
						setAlertModal({
							title: "",
							body: "",
							show: false,
						});
					}, 2000);
					setGeoLoading(false);
					toggle();
					console.log(error);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			latLon?.latitude &&
			latLon?.longitude &&
			searchVal.length === 0 &&
			!showDraftSelectionModal &&
			!chosenLocation
		) {
			if (geoLocation.length > 0) {
				setIsLoading(false);
			} else {
				getGeoLocations();
			}
		} else if (searchVal.length > 0) {
			searchForLocation();
		} else {
			setSearchedLocations([]);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latLon, searchVal]);

	useEffect(() => {
		if (geoLocation.length > 0) {
			!fromChatbot && selectItem(geoLocation[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoLocation]);

	return (
		<div className={`${props.center ? "d-flex justify-content-center" : ""}`}>
			{!fromChatbot && (
				<div className="button-loader-container">
					<Button
						className="blank-btn"
						// id='locationPopover'
						type="button"
						onClick={toggle}
						disabled={isEditing && !isAdminOrSuperUser}
					>
						{locationText} <FiChevronRight />
					</Button>
				</div>
			)}
			<Modal
				className="modal-restrict"
				isOpen={fromChatbot ? isOpenOtherLocation : isOpen}
				style={getStyles()}
			>
				<ModalHeader toggle={toggle}>
					<Input
						type="search"
						placeholder="Search For Location"
						// value={searchText}
						// disabled={isLoading}
						onChange={updateSearchVal}
						style={{ width: "100%" }}
					/>
				</ModalHeader>
				<ModalBody style={{ overflow: "scroll", maxHeight: "500px" }}>
					{/* {isLoading ? (
						// <Loader small={true} />
						<div>{getLocations()}</div>
					) : ( */}
					<List style={{ overflow: "scroll" }}>{getLocations()}</List>
				</ModalBody>
			</Modal>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};
