import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import type { SelfOrganization } from "@/hooks/organizationQueries";
import { parseDateToUTCString } from "@/utils/transforms";
import type { ColumnsType } from "antd/es/table";
import { BsShieldFillCheck, BsShieldFillMinus } from "react-icons/bs";

export const columns: ColumnsType = [
	{
		title: "Warranty",
		dataIndex: "warranty",
		key: "warranty",
		render: (text) => {
			return text ? (
				<BsShieldFillCheck className="green assets-table-icon fs-4" />
			) : (
				<BsShieldFillMinus className="red assets-table-icon fs-4" />
			);
		},
		align: "center",
	},
	{
		title: "Address",
		dataIndex: "address",
		key: "address1",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => <CopyableTableCell text={text} />,
		width: 400,
	},
	{
		title: "Asset Type",
		dataIndex: ["asset_type", "name"],
		key: "asset_type_name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
		width: 300,
	},
	{
		title: "Make",
		dataIndex: "manufacturer",
		key: "manufacturer",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
		width: 200,
	},
	{
		title: "Model",
		dataIndex: "model",
		key: "model_number",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
		width: 200,
	},
	{
		title: "Manufacturer Date",
		dataIndex: "manufacturer_date",
		key: "manufacturer_date",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (date: string, row: any) => {
			return (
				<CopyableTableCell
					text={parseDateToUTCString(date) || "N/A"}
					backfillValue={parseDateToUTCString(
						row?.generated_ai_data?.manufacturer_date,
					)}
				/>
			);
		},
	},
	{
		title: "Organization",
		dataIndex: "sub_org",
		key: "sub_org",
		render: (text) => <CopyableTableCell text={text || "N/A"} />,
		width: 300,
	},
	{
		title: "Temp Alert ID",
		dataIndex: "temp_alert_id",
		key: "temp_alert_id",
		render: (text) => <CopyableTableCell text={text || "N/A"} />,
		width: 300,
	},
	{
		title: "Category",
		dataIndex: "asset_type_category",
		key: "asset_type_category",
		render: (text) => <CopyableTableCell text={text || "N/A"} />,
	},
	{
		title: "Submission Date",
		dataIndex: "submitted_date",
		key: "submitted_date",
		render: (date: string) => (
			<CopyableTableCell text={parseDateToUTCString(date) || "N/A"} />
		),
	},
	{
		title: "Health Score",
		dataIndex: "health_score",
		key: "health_score",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (healthScore) => <HealthScorePill healthScore={healthScore} />,
	},
];

export const getOrgColumns = (
	organization: Partial<SelfOrganization>,
): ColumnsType => {
	switch (organization.external_firebase_id) {
		default:
			return columns;
	}
};
