import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import type { QueryClient } from "@tanstack/react-query";
import type { SetStateAction } from "react";

/**
 * Approves a submission that has been flagged by QA
 * @param {number} submissionId
 * @param {function} setIsLoading
 * @param {function} toggleSubmission
 * @param queryClient
 * @returns {Promise<void>}
 */
export const approveSubmission = async (
	submissionId: any,
	setIsLoading: React.Dispatch<SetStateAction<boolean>>,
	queryClient: QueryClient | undefined,
	toggleSubmission = () => {},
) => {
	setIsLoading(true);
	try {
		const url = `${ALFRED_SERVICE_URL}/submission/qa/approve/asset/${submissionId}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to approve QA submission: ${errorDetails.detail}`,
				);
			});
		}
		queryClient?.invalidateQueries({ queryKey: ["searchResponses"] });
	} catch (error) {
		console.log("Failed to approve QA submission: ", error);
	} finally {
		setIsLoading(false);
		toggleSubmission();
	}
};
