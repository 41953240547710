import { useUpdateLocation } from "@hooks/locationQueries";
import { useState } from "react";
import {
	Button,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";

function VirtualModal({
	virtualType,
	locationInfo,
	organization,
	setVirtualChange,
}) {
	const updateLocationMutation = useUpdateLocation();
	const [modal, setModal] = useState(false);
	const [inputVal, setInputVal] = useState("");

	const toggle = () => setModal(!modal);

	const handleSubmit = async () => {
		const body = { [virtualType.toLowerCase()]: inputVal };
		const is_firebase_id = false;
		const id = locationInfo.id;
		await updateLocationMutation.mutateAsync({
			id,
			is_firebase_id,
			body,
		});
		setVirtualChange(true);
		toggle();
	};

	return (
		<div className="mb-2">
			<Button color="success" onClick={toggle} style={{ width: "165px" }}>
				Connect {virtualType}
			</Button>
			<Modal isOpen={modal} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>Connect {virtualType}</ModalHeader>
				<ModalBody>
					<Label>{virtualType} ID</Label>
					<Input onChange={(e) => setInputVal(e.target.value)} />
				</ModalBody>
				<ModalFooter>
					<Button color="success" onClick={handleSubmit}>
						Connect
					</Button>{" "}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export { VirtualModal };
