import { useCallback, useEffect, useRef, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export const ListNavigation = ({ items }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const startX = useRef(null);
	const listContentRef = useRef(null);

	const handlePrev = useCallback(() => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? items.length - 1 : prevIndex - 1,
		);
	}, [items.length]);

	const handleNext = useCallback(() => {
		setCurrentIndex((prevIndex) =>
			prevIndex === items.length - 1 ? 0 : prevIndex + 1,
		);
	}, [items.length]);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 768);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const handleTouchStart = (e) => {
			startX.current = e.touches[0].clientX;
		};

		const handleTouchMove = (e) => {
			if (!startX.current) return;
			const endX = e.touches[0].clientX;
			const deltaX = startX.current - endX;
			if (deltaX > 50) {
				handleNext();
				startX.current = null;
			} else if (deltaX < -50) {
				handlePrev();
				startX.current = null;
			}
		};

		if (isMobile && listContentRef.current) {
			const listContent = listContentRef.current;
			listContent.addEventListener("touchstart", handleTouchStart);
			listContent.addEventListener("touchmove", handleTouchMove);
			return () => {
				listContent.removeEventListener("touchstart", handleTouchStart);
				listContent.removeEventListener("touchmove", handleTouchMove);
			};
		}
	}, [isMobile, handleNext, handlePrev, listContentRef]);

	return (
		<div className="list-navigation">
			<div
				className="list-navigation-content d-flex align-items-center"
				ref={listContentRef}
			>
				<button className="nav-button prev-button" onClick={handlePrev}>
					<MdKeyboardArrowLeft />
				</button>
				<div className="list-item">{items[currentIndex]}</div>
				<button className="nav-button next-button" onClick={handleNext}>
					<MdKeyboardArrowRight />
				</button>
			</div>
			<div className="navigation-indicators">
				{isMobile && (
					<div className="swipe-indicator">
						<span>Swipe to navigate</span>
					</div>
				)}
				<div className="list-navigation-indicator">
					{currentIndex + 1}/{items.length}
				</div>
			</div>
		</div>
	);
};
