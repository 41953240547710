export const handleProgressBarCircleClick = (
	pageIndex,
	currentPage,
	responseData,
	validateAndSetPage1,
	isPage1Valid,
	setAlertModal,
	closeAlertModal,
	validateAndSetPage2,
	validateAndSetPage3,
	validateAndSetPage4,
	validateAndSetPage5,
	validateAndSetPage6,
	validateAndSetPage7,
	validateAndSetPage8,
	validateAndSetPage9,
	setCurrentPage,
) => {
	if (currentPage === 0) {
		if (responseData.location) {
			validateAndSetPage1();
		} else if (!isPage1Valid) {
			setAlertModal({
				show: true,
				title: "Set Location",
				body: "Please select a location for this form.",
			});
			setTimeout(closeAlertModal, 2500);
			return;
		}
	} else if (currentPage === 1) {
		validateAndSetPage2();
	} else if (currentPage === 2) {
		validateAndSetPage3();
	} else if (currentPage === 3) {
		validateAndSetPage4();
	} else if (currentPage === 4) {
		validateAndSetPage5();
	} else if (currentPage === 5) {
		validateAndSetPage6();
	} else if (currentPage === 6) {
		validateAndSetPage7();
	} else if (currentPage === 7) {
		validateAndSetPage8();
	} else if (currentPage === 8) {
		validateAndSetPage9();
	}

	setCurrentPage(pageIndex);
};
