import { saveProgress } from "@services/draft-service";
import { deepCopy } from "@utils/deepCopy";

export const saveDraft = (
	responseData,
	shouldAutoSave,
	draftId,
	setDraftId,
	_organization,
	formId,
	_formName,
	_authInfo,
	userLocation,
	savedLocation,
	chosenAssetType,
	submitDisabled,
	submissionId,
) => {
	if (
		shouldAutoSave &&
		responseData.assetLocation &&
		responseData.location &&
		responseData.assetType &&
		responseData.manufacturer &&
		(responseData.modelNumber || responseData.modelNotAvailable) &&
		chosenAssetType &&
		!submitDisabled &&
		!submissionId
	) {
		const resCopy = deepCopy(responseData);
		saveProgress(
			formId,
			draftId,
			setDraftId,
			userLocation,
			savedLocation,
			resCopy,
		);
	}
};
