import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { searchLocations } from "@/services/location-service";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import Select from "react-select";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	FormGroup,
	Input,
	InputGroup,
	InputGroupText,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";
import MonthRangeOption from "./MonthRangeDropdown";
import {
	stateLabels,
	statesCVS,
	statesCushman,
	statesFL,
	statesRC,
} from "./geographicalStates";

const PredSpendFilter: FC<{
	state: any;
	parentDispatch: any;
	chartState: any;
	chartDispatch: any;
}> = ({ state, parentDispatch, chartState, chartDispatch }) => {
	const {
		assetTypes,
		checkedAssetTypes,
		checkedStates,
		portfolioChecked,
		checkedLocations,
		allLocations,
		organization,
		healthScoreFilterType,
		healthScoreFilterValue,
		budget,
		budgetType,
		years,
	} = state;
	const { selectedYear } = chartState;
	const [open, setOpen] = useState(null);
	const budgetOptions = [
		{ value: "static", label: "Static Budget" },
		{ value: "varied", label: "Varied Budget" },
	];

	const [searchVal, setSearchVal] = useState("");
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [searchLoading, setSearchLoading] = useState(false);
	const [timeoutId, setTimeoutId] = useState(null);

	const [stateSearchVal, setStateSearchVal] = useState("");
	const [filteredStates, setFilteredStates] = useState([]);
	const [allStates, setAllStates] = useState([]);
	const [filterByState, setFilterByState] = useState(false);

	const updateSearchVal = (e) => {
		// Clear any existing timeout
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timeout to update the state after 3 seconds
		const newTimeoutId = setTimeout(() => {
			setSearchLoading(true);
			setSearchVal(e.target.value);
		}, 2000);

		// Store the timeout ID so it can be cleared if the user types again
		setTimeoutId(newTimeoutId);
	};

	const updateStateSearchVal = (e) => {
		setStateSearchVal(e.target.value);
	};

	useEffect(() => {
		if (organization) {
			let statesArray = [];
			if (organization === "cvs.com") {
				statesArray = statesCVS;
			} else if (organization === "cushmanwakefield.com") {
				statesArray = statesCushman;
			} else if (organization === "raisingcanes.com") {
				statesArray = statesRC;
			} else if (organization === "foodlion.com") {
				statesArray = statesFL;
			}

			if (statesArray.length > 0) {
				const stateLabelsArray = statesArray.sort().map((state) => ({
					value: state,
					label: stateLabels[state],
				}));
				setAllStates(stateLabelsArray);
				setFilteredStates(stateLabelsArray);
			} else {
				const fetchStates = async () => {
					const uniqueStates = Array.from(
						new Set(
							allLocations
								.filter((item) => item?.data?.state) // Filter out null or invalid items
								.map((item) => item.data.state),
						),
					).sort();
					const stateLabelsArray = uniqueStates.map((state) => ({
						value: state,
						label: stateLabels[state],
					}));
					setAllStates(stateLabelsArray);
					setFilteredStates(stateLabelsArray);
				};
				fetchStates();
			}
		}
	}, [organization]);

	useEffect(() => {
		if (stateSearchVal === "") {
			setFilteredStates(allStates);
		} else {
			const filtered = allStates.filter((state) =>
				state.label.toLowerCase().includes(stateSearchVal.toLowerCase()),
			);
			setFilteredStates(filtered);
		}
	}, [stateSearchVal, allStates]);

	const yearsOptions = [
		{ label: "5 years", value: 5 },
		{ label: "10 years", value: 10 },
		{ label: "15 years", value: 15 },
	];

	const searchForLocation = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		setSearchLoading(true);

		const location_retval = await searchLocations(
			searchVal,
			organization,
			1,
			25,
			"state",
			"asc",
			{},
		);
		const minified_locations = location_retval.results.map((location) => ({
			id: location.id,
			address1: location.address1,
			address2: location.address2,
			city: location.city,
			state: location.state,
			zip: location.zip,
		}));
		setSearchedLocations(minified_locations);
		setSearchLoading(false);
	};

	useEffect(() => {
		if (searchVal.length > 0) {
			searchForLocation();
		} else {
			setSearchedLocations([]);
			setSearchLoading(false);
		}
	}, [searchVal]);

	const toggle = (id) => {
		if (open === id) {
			setOpen("");
		} else {
			setOpen(id);
		}
	};

	const handleLocationChange = (location) => {
		if (portfolioChecked) {
			parentDispatch({
				type: "SET_PORTFOLIO_CHECKED",
				payload: false,
			});
		}
		if (checkedLocations.filter((loc) => loc.id === location.id).length > 0) {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: state.checkedLocations.filter((loc) => loc.id !== location.id),
			});
		} else {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: [...state.checkedLocations, location],
			});
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: [],
			});
		}
	};

	const handleStateChange = (state) => {
		if (portfolioChecked) {
			dispatch({
				type: "SET_PORTFOLIO_CHECKED",
				payload: false,
			});
		}
		if (checkedStates.some((s) => s.value === state.value)) {
			dispatch({
				type: "SET_CHECKED_STATES",
				payload: checkedStates.filter((s) => s.value !== state.value),
			});
		} else {
			dispatch({
				type: "SET_CHECKED_STATES",
				payload: [...checkedStates, state],
			});
		}
		dispatch({
			type: "SET_CHECKED_LOCATIONS",
			payload: [],
		});
	};

	const handleAssetTypeChange = (assetType) => {
		if (portfolioChecked) {
			parentDispatch({
				type: "SET_PORTFOLIO_CHECKED",
				payload: false,
			});
		}

		if (checkedAssetTypes.includes(assetType)) {
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: checkedAssetTypes.filter((type) => type !== assetType),
			});
		} else {
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: [...checkedAssetTypes, assetType],
			});
		}

		if (checkedLocations.length > 0) {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: [],
			});
		}
		if (checkedStates.length > 0) {
			parentDispatch({
				type: "SET_CHECKED_STATES",
				payload: [],
			});
		}
	};

	const handlePortfolioChange = (e) => {
		if (e.target.checked) {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: [],
			});
			parentDispatch({
				type: "SET_CHECKED_STATES",
				payload: [],
			});
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: [],
			});
		}
		parentDispatch({
			type: "SET_PORTFOLIO_CHECKED",
			payload: !portfolioChecked,
		});
	};

	const healthScoreFilterOptions = [
		{ value: "less", label: "Less than or equal to" },
		{ value: "greater", label: "Greater than or equal to" },
	];

	const healthScoreOptions = [
		{ value: 5, label: "5" },
		{ value: 10, label: "10" },
		{ value: 15, label: "15" },
		{ value: 20, label: "20" },
		{ value: 25, label: "25" },
		{ value: 30, label: "30" },
		{ value: 35, label: "35" },
		{ value: 40, label: "40" },
		{ value: 45, label: "45" },
		{ value: 50, label: "50" },
		{ value: 55, label: "55" },
		{ value: 60, label: "60" },
		{ value: 65, label: "65" },
		{ value: 70, label: "70" },
		{ value: 75, label: "75" },
		{ value: 80, label: "80" },
		{ value: 85, label: "85" },
		{ value: 90, label: "90" },
		{ value: 95, label: "95" },
		{ value: 100, label: "100" },
	];

	// rewrite handleBudgetChange to use dispatch
	const handleBudgetChange = (e, year = null) => {
		const value = e.target.value.replace(/\D/g, ""); // Remove any non-digit characters
		// if budget type is static, set the budget value for all years in array format
		if (budgetType?.value === "static") {
			const newBudget = Array.from({ length: years }, () => Number(value));
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		} else {
			// if budget type is varied, set the budget value for the selected year
			let newBudget = budget
				? [...budget]
				: Array.from({ length: years }).fill(null);
			newBudget[year - new Date().getFullYear()] = Number(value);
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		}
	};

	const startYear = new Date().getFullYear();
	const endYear = startYear + years;
	const yearsArr = Array.from(
		{ length: endYear - startYear },
		(_, i) => startYear + i,
	);

	function formatNumber(amount) {
		if (Number.isNaN(amount)) {
			return "0";
		}

		// Convert the number to an integer
		const integerAmount = Math.floor(Number(amount));

		// Use a regular expression to format the number with commas
		const formattedAmount = integerAmount
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		return `${formattedAmount}`;
	}

	const handleYearChange = (e) => {
		parentDispatch({
			type: "SET_YEARS",
			payload: e.value,
		});
		if (budgetType?.value === "static") {
			const newBudget = Array.from({ length: e.value }, () =>
				budget[0] ? budget[0] : null,
			);
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		} else {
			const newBudget = Array.from({ length: e.value }, (_, i) =>
				budget[i] ? budget[i] : null,
			);
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		}
	};

	const [modal, setModal] = useState(false);

	const toggleModal = () => {
		setModal(!modal);
	};

	const [toggleSearchLocations, setToggleSearchLocations] = useState(true);

	return (
		<>
			{!selectedYear && (
				<Tooltip title="Settings">
					<div className="pred-spend-navbar-item">
						<IoMdSettings
							className="pred-spend-navbar-icon"
							onClick={toggleModal}
						/>
					</div>
				</Tooltip>
			)}

			<MonthRangeOption
				onRangeChange={(dates) => {
					parentDispatch({
						type: "SET_START_DATE",
						payload: dates[0],
					});
					parentDispatch({
						type: "SET_END_DATE",
						payload: dates[1],
					});
				}}
			/>

			<Modal isOpen={modal} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}>Settings</ModalHeader>
				<ModalBody
					className="predictive-spend-card px-3 py-4"
					style={{ overflowY: "auto" }}
				>
					<FormGroup
						switch
						className="d-flex align-items-center justify-content-between w-100 p-0"
					>
						<Label check>Portfolio - All In-scope Equipment</Label>
						<Input
							type="switch"
							role="switch"
							onChange={handlePortfolioChange}
							checked={portfolioChecked}
							disabled={portfolioChecked}
						/>
					</FormGroup>
					<FormGroup
						switch
						className="d-flex align-items-center justify-content-between w-100 p-0"
					>
						<Label check>Filter by State</Label>
						<Input
							type="switch"
							role="switch"
							onChange={() => setFilterByState(!filterByState)}
							checked={filterByState}
						/>
					</FormGroup>
					<Accordion open={open} toggle={toggle} className="mb-2">
						<AccordionItem>
							<AccordionHeader targetId="1">
								Location - All In-scope Equipment
							</AccordionHeader>
							<AccordionBody
								style={{
									maxHeight: "400px",
									overflowY: "auto",
								}}
								accordionId="1"
							>
								{/* <div className='mb-2'>
									<Button onClick={toggleSearch}>
										Search
									</Button>
									<Button onClick={toggleAllLocations}>
										All Locations
									</Button>
								</div> */}
								{toggleSearchLocations ? (
									<>
										<Input
											type="search"
											placeholder="Search For Location"
											disabled={searchLoading}
											onChange={updateSearchVal}
											style={{ width: "100%" }}
											className="mb-2"
										/>
										{searchLoading && <Loader />}
										<SearchedLocations
											locations={searchedLocations}
											handleLocationChange={handleLocationChange}
											checkedLocations={checkedLocations}
										/>
									</>
								) : (
									<AllLocationsList
										locations={allLocations}
										handleLocationChange={handleLocationChange}
										checkedLocations={checkedLocations}
									/>
								)}
							</AccordionBody>
						</AccordionItem>
						{filterByState && (
							<AccordionItem>
								<AccordionHeader targetId="2">
									State - All In-scope Equipment
								</AccordionHeader>
								<AccordionBody
									style={{
										maxHeight: "400px",
										overflowY: "auto",
									}}
									accordionId="2"
								>
									<Input
										type="search"
										placeholder="Search For State"
										value={stateSearchVal}
										onChange={updateStateSearchVal}
										style={{ width: "100%" }}
										className="mb-2"
									/>
									{filteredStates.map((state) => (
										<StateOption
											key={state.value}
											label={state.label}
											isChecked={checkedStates.some(
												(s) => s.value === state.value,
											)}
											onSwitchChange={() => handleStateChange(state)}
											checkedStates={checkedStates}
										/>
									))}
								</AccordionBody>
							</AccordionItem>
						)}
						<AccordionItem>
							<AccordionHeader targetId="3">Equipment Type</AccordionHeader>
							<AccordionBody
								accordionId="3"
								style={{
									maxHeight: "400px",
									overflowY: "auto",
								}}
							>
								{assetTypes.map((assetType) => (
									<AssetTypeOption
										key={assetType.id}
										label={assetType}
										isChecked={checkedAssetTypes.includes(assetType)}
										onSwitchChange={() => handleAssetTypeChange(assetType)}
										checkedAssetTypes={checkedAssetTypes}
									/>
								))}
							</AccordionBody>
						</AccordionItem>
						<AccordionItem>
							<AccordionHeader targetId="4">Health Score</AccordionHeader>
							<AccordionBody accordionId="4">
								<h5>Filter Type</h5>
								<Select
									className="basic-single mb-3"
									classNamePrefix="select"
									options={healthScoreFilterOptions}
									onChange={(e) =>
										parentDispatch({
											type: "SET_HEALTH_SCORE_FILTER_TYPE",
											payload: e,
										})
									}
									value={healthScoreFilterType}
								/>
								<h5>Filter by Value</h5>
								<Select
									className="basic-single mb-3"
									classNamePrefix="select"
									name="color"
									options={healthScoreOptions}
									onChange={(e) =>
										parentDispatch({
											type: "SET_HEALTH_SCORE_FILTER_VALUE",
											payload: e,
										})
									}
									value={healthScoreFilterValue}
									isDisabled={!healthScoreFilterType}
								/>
								{healthScoreFilterType && healthScoreFilterValue && (
									<Button
										onClick={() => {
											parentDispatch({
												type: "SET_HEALTH_SCORE_FILTER_TYPE",
												payload: null,
											});
											parentDispatch({
												type: "SET_HEALTH_SCORE_FILTER_VALUE",
												payload: null,
											});
										}}
									>
										Clear
									</Button>
								)}
							</AccordionBody>
						</AccordionItem>
						<AccordionItem>
							<AccordionHeader targetId="5">Budget</AccordionHeader>
							<AccordionBody accordionId="5">
								<h6>Budget Type</h6>
								<Select
									className="basic-single mb-3"
									classNamePrefix="select"
									options={budgetOptions}
									onChange={(e) => {
										parentDispatch({
											type: "SET_BUDGET",
											payload: Array.from({ length: years }).fill(null),
										});
										parentDispatch({
											type: "SET_BUDGET_TYPE",
											payload: e,
										});
									}}
									value={budgetType}
								/>
								{budgetType?.value === "static" ? (
									<>
										<h6>Annual Budget</h6>
										<InputGroup className="mb-3">
											<InputGroupText>$</InputGroupText>
											<Input
												placeholder="Enter Budget"
												value={budget[0] ? formatNumber(budget[0]) : ""}
												onChange={handleBudgetChange}
												type="text"
											/>
										</InputGroup>
									</>
								) : (
									yearsArr.map((year) => (
										<>
											<h6>{year}</h6>
											<InputGroup className="mb-3" style={{ zIndex: 0 }}>
												<InputGroupText>$</InputGroupText>
												<Input
													placeholder="Enter Budget"
													value={
														budget[year - startYear]
															? formatNumber(budget[year - startYear])
															: ""
													}
													onChange={(e) => handleBudgetChange(e, year)}
													type="text"
													invalid={!budget[year - startYear]}
												/>
											</InputGroup>
										</>
									))
								)}
							</AccordionBody>
						</AccordionItem>
						<AccordionItem>
							<AccordionHeader targetId="6">Timeframe</AccordionHeader>
							<AccordionBody accordionId="6">
								<h6>Years</h6>
								<Select
									className="basic-single mb-3"
									classNamePrefix="select"
									options={yearsOptions}
									onChange={(e) => handleYearChange(e)}
									value={{
										label: `${years} years`,
										value: years,
									}}
								/>
							</AccordionBody>
						</AccordionItem>
					</Accordion>
				</ModalBody>
			</Modal>
		</>
	);
};

const truncateLabel = (label, maxLength = 20) => {
	if (label.length > maxLength) {
		return `${label.substring(0, maxLength)}...`;
	}
	return label;
};

const LocationOption = ({
	label,
	isChecked,
	onSwitchChange,
	checkedLocations,
}) => {
	return (
		<FormGroup
			switch
			className="d-flex align-items-center justify-content-between w-100 p-0"
			style={{ fontSize: "14px" }}
		>
			<Label check>{truncateLabel(label, 30)}</Label>

			<Input
				type="switch"
				role="switch"
				checked={isChecked}
				onChange={onSwitchChange}
				disabled={checkedLocations.length === 1 && isChecked}
			/>
		</FormGroup>
	);
};

const StateOption = ({ label, isChecked, onSwitchChange, checkedStates }) => {
	return (
		<FormGroup
			switch
			className="d-flex align-items-center justify-content-between w-100 p-0"
			style={{ fontSize: "14px" }}
		>
			<Label check>{truncateLabel(label, 30)}</Label>

			<Input
				type="switch"
				role="switch"
				checked={isChecked}
				onChange={onSwitchChange}
				disabled={checkedStates.length === 1 && isChecked}
			/>
		</FormGroup>
	);
};

const SearchedLocations = ({
	locations,
	handleLocationChange,
	checkedLocations,
}) => {
	const _sortLocationsByState = (locations) => {
		return locations.sort((a, b) => {
			if (a.state && b.state) {
				return a.state.localeCompare(b.state);
			}
			return 0;
		});
	};
	let lastState = null;

	return (
		<div>
			{locations.map((location, index) => {
				const stateLabel =
					location.state !== lastState ? stateLabels[location.state] : null;
				lastState = location.state;
				stateLabel && <h2>{stateLabel}</h2>;
				let label = "";
				if (location.address1) label += `${location.address1}, `;
				if (location.city) label += `${location.city}, `;
				if (location.state) label += `${location.state}`;
				if (location.zip) label += `${location.zip}`;

				return (
					<div key={index}>
						{stateLabel && <h5 className="fw-bold">{stateLabel}</h5>}
						<LocationOption
							label={label}
							isChecked={checkedLocations.some((loc) => loc.id === location.id)}
							onSwitchChange={() => handleLocationChange(location)}
							checkedLocations={checkedLocations}
						/>
					</div>
				);
			})}
		</div>
	);
};

const AllLocationsList = ({
	locations,
	handleLocationChange,
	checkedLocations,
}) => {
	const sortLocationsByState = (locations) => {
		return locations.sort((a, b) => {
			if (a.state && b.state) {
				return a.state.localeCompare(b.state);
			}
			return 0;
		});
	};

	const sortedLocations = sortLocationsByState(locations);
	let lastState = null;

	return (
		<div>
			{sortedLocations.map((location, index) => {
				const stateLabel =
					location.state !== lastState ? stateLabels[location.state] : null;
				lastState = location.state;
				stateLabel && <h2>{stateLabel}</h2>;
				let label = "";
				if (location.address1) label += `${location.address1}, `;
				if (location.city) label += `${location.city}, `;
				if (location.state) label += `${location.state}`;
				if (location.zip) label += `${location.zip}`;

				return (
					<div key={index}>
						{stateLabel && <h5 className="fw-bold">{stateLabel}</h5>}
						<LocationOption
							label={label}
							isChecked={checkedLocations.some((loc) => loc.id === location.id)}
							onSwitchChange={() => handleLocationChange(location)}
							checkedLocations={checkedLocations}
						/>
					</div>
				);
			})}
		</div>
	);
};

const AssetTypeOption = ({
	label,
	isChecked,
	onSwitchChange,
	checkedAssetTypes,
}) => {
	return (
		<FormGroup
			switch
			className="d-flex align-items-center justify-content-between w-100 p-0"
			style={{ fontSize: "14px" }}
		>
			<Label check>{truncateLabel(label, 25)}</Label>
			<Input
				type="switch"
				role="switch"
				checked={isChecked}
				onChange={onSwitchChange}
				disabled={checkedAssetTypes.length === 1 && isChecked}
			/>
		</FormGroup>
	);
};

export { PredSpendFilter };
