import { BsStarHalf } from "react-icons/bs";
import { BsStarFill } from "react-icons/bs";
import { BsStar } from "react-icons/bs";

const FullStar = () => (
	<span
		className="d-flex align-items-center"
		style={{
			fontSize: "10px",
			marginRight: "2px",
		}}
	>
		<BsStarFill style={{ height: "10px", width: "10px" }} />
	</span>
);

const HalfStar = () => (
	<span
		className="d-flex align-items-center"
		style={{
			fontSize: "10px",
			marginRight: "2px",
		}}
	>
		<BsStarHalf style={{ height: "10px", width: "10px" }} />
	</span>
);
const EmptyStar = () => (
	<span
		className="d-flex align-items-center"
		style={{
			fontSize: "10px",
			marginRight: "2px",
		}}
	>
		<BsStar style={{ height: "10px", width: "10px" }} />
	</span>
);

const GoogleShoppingGrid = ({ data }) => {
	if (!data) return;

	const renderStarRating = (rating) => {
		const wholeRating = Math.floor(rating);
		const fraction = rating - wholeRating;

		const stars = [];

		// Add full stars
		for (let i = 0; i < wholeRating; i++) {
			stars.push(<FullStar key={`full${i}`} />);
		}

		// Add half star
		if (fraction >= 0.5) {
			stars.push(<HalfStar key={"half"} />);
		}

		// Add empty stars
		for (let i = 0; i < 5 - Math.ceil(rating); i++) {
			stars.push(<EmptyStar key={`empty${i}`} />);
		}

		return stars;
	};

	return (
		<div className="google-shopping-wrapper">
			{Array.isArray(data.response.results) ? (
				data.response.results.map((result) => (
					<div className="google-shopping-item" key={result.product_link}>
						<a
							href={result.product_link}
							className="h-100 w-100"
							target="_blank"
							rel="noreferrer"
						>
							<div className="google-shopping-item-top-half">
								<img src={result.thumbnail} alt="" />
							</div>
							<div className="google-shopping-item-bottom-half">
								<h3 className="google-shopping-item-title">{result.title}</h3>
								<b className="google-shopping-item-price">{result.price}</b>
								<span className="google-shopping-item-site-name text-start">
									{result.source}
								</span>
								{result.rating && (
									<div
										className="d-flex align-items-center"
										style={{
											paddingTop: "4px",
										}}
									>
										<span
											style={{
												color: "#5b6167",
												marginRight: "4px",
											}}
										>
											{result.rating}
										</span>
										<span className="google-shopping-item-rating d-flex align-items-center">
											{renderStarRating(result.rating)}
										</span>
										<span
											style={{
												color: "#5b6167",
												marginLeft: "4px",
											}}
										>
											({result.reviews})
										</span>
									</div>
								)}
								<span
									style={{
										color: "#5b6167",
									}}
								>
									{result.delivery}
								</span>
							</div>
						</a>
					</div>
				))
			) : (
				<span className="text-center w-100">{data.response.message}</span>
			)}
		</div>
	);
};

export { GoogleShoppingGrid };
