import { getFormId } from "@/services/form-service";
import { useFormStore, usePageStore, useUserStore } from "@/stores";
import { LoadingModal } from "@SignedIn/LoadingModal/LoadingModal";
import { scanImage } from "@services/scan-service";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { GrCheckmark } from "react-icons/gr";
import { PiImageSquareDuotone } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Label } from "reactstrap";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FormGroup, Input } from "reactstrap";
import CheckUpsModal from "./CheckUpsModal";

import { storage } from "@/assets/services/auth-service";
import { NewLocationPicker } from "@/components/layouts/SignedIn/views/SingleForm/NewLocationPicker";
import { getSubmissionWithTag } from "@/services/submission-service";
import { getSubmissionBasedLocation } from "@/services/submission-service";
import { AssetDetailsReport } from "./AssetDetailsReport";
import Tile from "./Tile";
import "./CheckUps.scss";
const CheckUps = () => {
	// const isMobile = window.innerWidth < 768;
	const organization = useUserStore((state) => state.authInfo.org);
	const [showLoader, setShowLoader] = useState(false);

	const { activeTab, setActiveTab } = usePageStore();
	const [tabWidthArr, setTabWidthArr] = useState([]);
	console.log(activeTab);

	const _toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const { alertModal, setAlertModal } = useFormStore();
	const fileInputRef = useRef(null);
	const [assetTag, setAssetTag] = useState(null);
	const [enteredAssetTag, setEnteredAssetTag] = useState("");
	const [submissionData, setSubmissionData] = useState({});
	const [_assetTagUrl, _setUploadedAssetImageUrl] = useState(null);

	const [_isLoading, setIsLoading] = useState(true);
	const [selectedLocation, setSelectedLocation] = useState<Record<string, any>>(
		{},
	);
	const _location = useLocation();
	const navigate = useNavigate();
	const [overlayClass, setOverlayClass] = useState(
		"form-image-button-overlay invisible",
	);
	const [expandedTile, setExpandedTile] = useState(null);

	const _sumWidth = (currentTab) => {
		return tabWidthArr.slice(0, currentTab).reduce((a, c) => a + c, 0);
	};

	const getTabWidthArr = () => {
		const tabs = [];
		const tabElements = document.querySelectorAll(".nav-link");
		tabElements.forEach((tab) => {
			tabs.push(tab.getBoundingClientRect().width);
		});
		setTabWidthArr(tabs);
	};

	useEffect(() => {
		getTabWidthArr();
		window.addEventListener("resize", getTabWidthArr);
		return () => {
			window.removeEventListener("resize", getTabWidthArr);
		};
	}, []);
	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
			options: [],
		});
	};

	const handleOptionClick = (action) => {
		action();
		closeAlertModal();
	};
	const toggleModal = () => {
		setShowSubmissionForm((prev) => !prev);
	};

	// console.log(uploadedImage);

	// const timeoutId = setTimeout(closeAlertModal, 12000);

	const [_checkUps, _setCheckUps] = useState(true);

	const [showSubmissionForm, setShowSubmissionForm] = useState<boolean>(false);

	const [formId, setFormId] = useState();
	const [chosenSubmissionId, setChosenSubmissionId] = useState<
		number | undefined
	>(undefined);

	const [uploadedImage, setUploadedImageUrl] = useState<string | undefined>(
		undefined,
	);

	const [assetLocationChanged, setAssetLocationChanged] =
		useState<boolean>(false);
	const [bypassModal, setBypassModal] = useState<boolean>(false);

	const [locationSelected, setLocationSelected] = useState<boolean>(false);
	const [tagSelected, setTagSelected] = useState<boolean>(false);

	const authInfo = useUserStore((state) => state.authInfo);
	const _currentUser = authInfo.displayName;

	const handleTileClick = (tileKey) => {
		setExpandedTile(tileKey);
	};
	const _toggle = async (submission = null, searchIndex = null) => {
		if (submission !== undefined && searchIndex != null) {
			setChosenSubmissionId(submission.submission_id);

			setFormId(submission.formId);
		}

		if (showSubmissionForm === true) {
			await setShowSubmissionForm(false);
		} else {
			setShowSubmissionForm(true);
		}
	};
	const handleBackClick = () => {
		setExpandedTile(null);
	};

	async function uploadImageToFirebase(blob, path) {
		const imageRef = ref(storage, path);
		const snapshot = await uploadBytes(imageRef, blob);
		const url = await getDownloadURL(snapshot.ref);
		return url;
	}
	const handleNavigationWithClearState = () => {
		setShowSubmissionForm(false);
		setUploadedImageUrl(null);
		setSubmissionData(null);
		setChosenSubmissionId(null);
		setShowLoader(false);
		setShowSubmissionForm(false);

		setAssetLocationChanged(false);

		setAssetTag(null);

		navigate("/checkups");
		setBypassModal(true);
	};
	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) {
			console.log("No file selected.");
			setShowLoader(false);
			return;
		}
		setShowLoader(true);

		const reader = new FileReader();
		reader.onload = async (e) => {
			const img = new Image();
			img.src = e.target.result;

			img.onload = async () => {
				const scale = 1200 / img.width;
				const height = img.height * scale;

				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);

				canvas.toBlob(async (blob) => {
					try {
						const path = `FieldCaptures/${new Date().toISOString()}.webp`;
						const url = await uploadImageToFirebase(blob, path);
						console.log("Uploaded Image URL:", url);

						const scanResult = await scanImage(
							blob,
							"qr",
							"assetTag",
							true,
							organization,
						);
						const extractedAssetTag = scanResult[0];
						setUploadedImageUrl(url);
						setAssetTag(extractedAssetTag);

						// setAlertModal({
						// 	show: true })
					} catch (error) {
						console.error("Error during image processing:", error);
					} finally {
						setShowLoader(false);
					}
				}, "image/webp");
			};
		};
		reader.onerror = (error) => {
			console.error("FileReader error:", error);
			setShowLoader(false);
		};
		reader.readAsDataURL(file);
	};

	const navigateToSingleForm = async (currentAssetTag, currentAssetTagUrl) => {
		try {
			console.log(
				"Navigating with assetTag:",
				currentAssetTag,
				"and image URL:",
				currentAssetTagUrl,
			);
			navigate(`/forms/${formId}`, {
				state: {
					checkUps: true,
					assetTag: currentAssetTag,
					idTagAssetImage: currentAssetTagUrl,
				},
			});
		} catch (error) {
			console.error("Error navigating to single form:", error);
		} finally {
			setShowLoader(false);
		}
	};

	const fetchFormId = async () => {
		try {
			const fetchedFormId = await getFormId(
				organization,
				"Inventory & Tagging Form",
			);
			if (fetchedFormId) {
				setFormId(fetchedFormId);
			} else {
				console.error("Failed to fetch formId");
			}
		} catch (error) {
			console.error("Error fetching formId:", error);
		}
	};

	useEffect(() => {
		console.log("running effect");
		if (organization) {
			fetchFormId();
		}
	}, []);
	useEffect(() => {
		const checkAssetLocation = async (assetTag: string) => {
			if (bypassModal) {
				setBypassModal(false);
				return;
			}

			if (tagSelected) {
				const submission = await getSubmissionWithTag(assetTag);

				if (submission) {
					console.log("Submission Data", submission);
					setSubmissionData(submission);
					setShowSubmissionForm(true);
					setChosenSubmissionId(submission.submission_id);
					setAssetLocationChanged(false);
				} else {
					console.log(assetTag, uploadedImage);
					setAlertModal({
						show: true,
						title: "No Submission Found",
						body: "No submissions were found for the provided asset tag. Do you want to make a submission?",
						options: [
							{
								text: "Yes",
								action: () => navigateToSingleForm(assetTag, uploadedImage),
							},
							{
								text: "No",
								action: () => navigate("/checkups"),
							},
						],
					});
				}
			} else {
				const assetPresentInLocation = await getSubmissionBasedLocation(
					selectedLocation?.id,
					assetTag,
					formId,
				);

				if (assetPresentInLocation) {
					console.log(
						"Asset present in location:",
						assetPresentInLocation.results[0],
					);
					setSubmissionData(assetPresentInLocation.results[0]);
					setShowSubmissionForm(true);
					setChosenSubmissionId(
						assetPresentInLocation?.results?.[0].submission_id,
					);
					setAssetLocationChanged(false);
				} else {
					const submission = await getSubmissionWithTag(assetTag);

					if (submission) {
						setSubmissionData(submission);

						setAlertModal({
							show: true,
							title: "Asset Location",
							body: "Asset is present at a different location. Would you like to change the location of the asset?",
							options: [
								{
									text: "Yes",
									action: () => {
										setShowSubmissionForm(true);
										setChosenSubmissionId(submission.submission_id);
										setAssetLocationChanged(true);
									},
								},
								{
									text: "No",
									action: () => {
										setShowSubmissionForm(true);
										setChosenSubmissionId(submission.submission_id);
										setAssetLocationChanged(false);
									},
								},
							],
						});
					} else {
						console.log(assetTag, uploadedImage);
						setAlertModal({
							show: true,
							title: "No Submission Found",
							body: "No submissions were found for the provided asset tag. Do you want to make a submission?",
							options: [
								{
									text: "Yes",
									action: () => navigateToSingleForm(assetTag, uploadedImage),
								},
								{
									text: "No",
									action: () => navigate("/checkups"),
								},
							],
						});
					}
				}
			}

			setIsLoading(false);
		};

		if (assetTag) {
			checkAssetLocation(assetTag);
		}
	}, [
		assetTag,
		organization,
		formId,
		selectedLocation,
		bypassModal,
		tagSelected,
	]);

	const tiles = [
		{ label: "Current Check-Up", key: "currentCheckup", icon: GrCheckmark },
		{
			label: "Asset Details Report",
			key: "assetDetailsReport",
			icon: PiImageSquareDuotone,
		},
	];

	return (
		<div style={{ height: "auto" }}>
			{expandedTile ? (
				<div onClick={handleBackClick}>
					<i className="bi bi-arrow-left me-3"></i>
					Back
				</div>
			) : (
				<div className="grid-container">
					{tiles.map((tile) => (
						<Tile
							key={tile.key}
							category={tile}
							onTileClick={handleTileClick}
							Icon={tile.icon}
						/>
					))}
				</div>
			)}

			{expandedTile === "currentCheckup" && (
				<div>
					<Card className="my-3">
						<CardHeader>
							<strong>Check By</strong>
						</CardHeader>
						<CardBody>
							{/* <Row> */}
							<Button
								className="m-2"
								onClick={() => {
									setLocationSelected(true);
									setTagSelected(false);
								}}
							>
								Location
							</Button>
							<Button
								className="m-2"
								onClick={() => {
									setTagSelected(true);
									setLocationSelected(false);
								}}
							>
								Tag
							</Button>
							{/* </Row> */}
						</CardBody>
					</Card>
					{(locationSelected || tagSelected) && (
						<Card className="my-3">
							<CardHeader>
								<strong>Start by selecting a location:</strong>
							</CardHeader>
							<CardBody>
								<NewLocationPicker
									setChosenLocation={setSelectedLocation}
									organization={organization}
									setUserLocation={() => {}}
									fromChatbot={false}
									isOpen={false}
									setIsOpen={() => {}}
									onLoadingChange={setIsLoading}
									isCheckUps={true}
								/>
							</CardBody>
						</Card>
					)}
					{selectedLocation && (
						<Card className="my-3">
							<CardHeader>
								{tagSelected ? (
									<strong>Enter the Asset Tag:</strong>
								) : (
									<strong>Scan the First QR Code: </strong>
								)}
							</CardHeader>
							<CardBody>
								{tagSelected ? (
									<FormGroup>
										<Label for="assetTagInput">Asset Tag</Label>
										<Input
											type="text"
											id="assetTagInput"
											value={enteredAssetTag}
											onChange={(e) => setEnteredAssetTag(e.target.value)}
											placeholder="Enter asset tag"
										/>
										<Button
											// color='se'
											onClick={() => {
												setAssetTag(enteredAssetTag);
											}}
											className="mt-2"
										>
											Confirm Tag
										</Button>
									</FormGroup>
								) : (
									<FormGroup>
										<div
											className="form-image-button-container"
											onMouseEnter={() => setOverlayClass("light")}
											onTouchStart={() => setOverlayClass("light")}
											onMouseLeave={() => setOverlayClass("")}
											onTouchEnd={() => setOverlayClass("")}
										>
											<div className="form-image-button">
												<PiImageSquareDuotone className="form-image-button-icon" />
												<div className="mb-2">
													{uploadedImage ? (
														<div className="d-flex flex-row align-items-center">
															<GrCheckmark className="me-2 fw-bold" />
															<div className="fw-bold">Preview Photo</div>
														</div>
													) : (
														<div className="fw-bold">Add Photo</div>
													)}
												</div>
											</div>
											<div
												className={`form-image-button-overlay ${overlayClass}`}
											/>
											{uploadedImage && (
												<img
													className="form-image-button-image"
													src={uploadedImage}
													alt="Uploaded Preview"
												/>
											)}
											<Input
												ref={fileInputRef}
												type="file"
												onChange={handleImageUpload}
												className="form-image-button-input"
												accept="image/*"
											/>
										</div>
									</FormGroup>
								)}
							</CardBody>
						</Card>
					)}
					<div
						style={{
							gap: "20px",
						}}
						className="d-flex flex-column flex-lg-row"
					>
						<LoadingModal showing={showLoader} />
						<Modal centered isOpen={alertModal.show} toggle={() => {}}>
							<ModalHeader>{alertModal.title}</ModalHeader>
							<ModalBody>
								{alertModal.body}
								<div
									style={{
										display: "flex",
										justifyContent: "space-evenly",
										marginTop: "5px",
									}}
								>
									{alertModal.options?.map((option, index) => (
										<Button
											key={index}
											onClick={() => handleOptionClick(option.action)}
										>
											{option.text}
										</Button>
									))}
								</div>
							</ModalBody>
						</Modal>

						{submissionData !== null ? (
							<CheckUpsModal
								submissionRecord={submissionData}
								isOpen={showSubmissionForm}
								setIsOpen={setShowSubmissionForm}
								responses={submissionData}
								toggle={toggleModal}
								organization={organization}
								submissionId={chosenSubmissionId}
								assetTag={assetTag}
								assetLocationChanged={assetLocationChanged}
								handleNavigation={handleNavigationWithClearState}
								locationSelected={locationSelected}
								tagSelected={tagSelected}
								setAs
							/>
						) : null}
					</div>
				</div>
			)}

			{expandedTile === "assetDetailsReport" && (
				<Card className="mt-4 checkups">
					<CardHeader className="p-3 d-flex flex-row justify-content-between">
						<div className="table-title m-0 opacity-75">
							Asset Details Report{" "}
						</div>
					</CardHeader>
					<CardBody>
						{/* {!isMobile && ( */}
						<AssetDetailsReport expandedTile={expandedTile} />
						{/* )} */}
					</CardBody>
				</Card>
			)}
		</div>
	);
};

export { CheckUps };
