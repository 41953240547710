const LineDatasetLegendItems = ({ budget, strategy }) => {
	return (
		<div className="d-flex align-items-center mx-3">
			{budget && (
				<>
					<div
						style={{
							width: "20px",
							height: "20px",
							borderRadius: "50%",
							backgroundColor: "#0287b2",
							marginRight: "3px",
						}}
					/>
					<span style={{ fontSize: "14px", cursor: "default" }}>Budget</span>
				</>
			)}
			{strategy.value !== "End of Life Replacement" && (
				<>
					<div
						className="mx-2"
						style={{
							width: "20px",
							height: "20px",
							borderRadius: "50%",
							backgroundColor: "#FF8C00",
							marginRight: "3px",
							cursor: "default",
						}}
					/>
					<span style={{ fontSize: "14px", cursor: "default" }}>
						Reactive Replacement Premium
					</span>
				</>
			)}
		</div>
	);
};

export { LineDatasetLegendItems };
