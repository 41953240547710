import { Loader } from "@SignedIn/Loader/Loader";
import { usePageStore } from "@stores";
import { useEffect, useState } from "react";
///////////////////////////////////////////////////////////////////////
import { Modal, ModalBody } from "reactstrap";

///////////////////////////////////////////////////////////////////////

const LoadingModal = ({ showing }) => {
	const { isSidebarOpen } = usePageStore();
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

	const updateMedia = () => {
		setIsDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	///////////////////////////////////////////////////////////////////////
	return (
		<Modal
			centered
			isOpen={showing}
			className="loading-modal"
			style={{
				left: isDesktop ? (isSidebarOpen ? "130px" : "40px") : "20px",
			}}
		>
			<ModalBody>
				<Loader small={true} />
			</ModalBody>
		</Modal>
	);
	///////////////////////////////////////////////////////////////////////
};
export { LoadingModal };
