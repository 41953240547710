import { storage } from "@/assets/services/auth-service";
import { useFormStore, useUserStore } from "@/stores";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { inputs as notRequiredInputs } from "@constants/formInputs/notRequiredInputs";
import { default as initInputs } from "@constants/formInputs/taggingFormInputs";
import { getOrgSpecificInputs } from "@services/organization-service";
import { scanImage } from "@services/scan-service";
import { getSubmissionData } from "@services/submission-service";
import { updateTagReplacementFields } from "@services/submission-service";
import { deepCopy } from "@utils/deepCopy";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	FormGroup,
	Input,
	Label,
} from "reactstrap";

interface TagReplacementEditProps {
	assetTag: string;
	submissionId: string;
	toggleSubmission: () => void;
	isQA: boolean;
	assetLocationChanged: boolean;
	responses: any;
	handleNavigation: () => void;
	submissionRecord: any;
}

const TagReplacementEdit = ({
	assetTag,
	submissionId,
	toggleSubmission,
	isQA,
	assetLocationChanged,
	responses,
	handleNavigation,
	submissionRecord,
}: TagReplacementEditProps) => {
	const { alertModal, setAlertModal } = useFormStore();
	const { authInfo, userOrganization } = useUserStore();
	const organization = authInfo.org;
	const fileInputRef = useRef(null);

	console.log("Submission Record:", submissionRecord);

	const [isLoading, setIsLoading] = useState(true);
	const [validationState, setValidationState] = useState({});
	const [responseData, setResponseData] = useState();
	const [formName, setFormName] = useState();
	const [formLogo, setFormLogo] = useState();
	const [address, setAddress] = useState();
	const [_newAddress, _setNewAddress] = useState();
	const [locationId, setLocationId] = useState();
	const [userLocation, setUserLocation] = useState(undefined);
	const [chosenAssetType, setChosenAssetType] = useState<
		Record<string, any> | undefined
	>(undefined);

	const [_showImageModal, _setShowImageModal] = useState(false);

	const [uploadedImage, setUploadedImageUrl] = useState(null);

	const [inputsRequired, setInputsRequired] = useState(true);
	const [inputs, setInputs] = useState(initInputs);
	const [assetConditionalInputs, setAssetConditionalInputs] = useState([]);
	const [orgSpecificInputs, setOrgSpecificInputs] = useState([]);
	const [assetEdits, setAssetEdits] = useState({});

	const [_showLoader, _setShowLoader] = useState(false);

	const [_selectedState, _setSelectedState] = useState(null);

	const [_originalLocation, setOriginalLocation] = useState(null);
	const [_selectedLocation, setSelectedLocation] = useState(null);

	const [_areaChange, _setAreaChanged] = useState(false);

	const [_options, _setOptions] = useState([]);

	const [previousData, _setPrevData] = useState(responses);
	console.log("Previous Data:", previousData);

	const [_isSubmitted, setIsSubmitted] = useState(false);
	const [_isConditionSame, _setIsConditionSame] = useState(false);

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	async function uploadImageToFirebase(blob, path) {
		const imageRef = ref(storage, path);
		const snapshot = await uploadBytes(imageRef, blob);
		const url = await getDownloadURL(snapshot.ref);
		return url;
	}
	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) {
			console.log("No file selected.");
			setIsLoading(false);
			return;
		}
		setIsLoading(true);

		const reader = new FileReader();
		reader.onload = async (e) => {
			const img = new Image();
			img.src = e.target.result;

			img.onload = async () => {
				const scale = 1200 / img.width;
				const height = img.height * scale;

				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = 1200;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, 1200, height);

				canvas.toBlob(async (blob) => {
					try {
						const path = `FieldCaptures/${new Date().toISOString()}.webp`;
						const url = await uploadImageToFirebase(blob, path);
						console.log("Uploaded Image URL:", url);
						setUploadedImageUrl(url);

						// Extract asset tag
						const scanResult = await scanImage(
							blob,
							"qr",
							"assetTag",
							true,
							organization,
						);
						const extractedAssetTag = scanResult[0];
						console.log("Extracted Asset Tag:", extractedAssetTag);

						// Populate the newAssetTag field
						handleFieldChange("newAssetTag", extractedAssetTag);

						setAlertModal({
							show: true,
							title: "Image Uploaded",
							body: "The image has been uploaded successfully.",
						});
						setTimeout(closeAlertModal, 2500);
					} catch (error) {
						console.error("Error during image processing:", error);
					} finally {
						setIsLoading(false);
					}
				}, "image/webp");
			};
		};
		reader.onerror = (error) => {
			console.error("FileReader error:", error);
			setIsLoading(false);
		};
		reader.readAsDataURL(file);
	};

	const handleFieldChange = (field, value) => {
		setResponseData((prevData) => ({
			...prevData,
			[field]: value,
		}));
		setValidationState((prevState) => ({
			...prevState,
			[field]: false,
		}));
	};

	const validateFields = () => {
		let isValid = true;
		const newValidationState = {};

		// if (!selectedState || !selectedState.id) {
		// 	isValid = false;
		// 	newValidationState.location = true;
		// }

		if (!uploadedImage) {
			isValid = false;
			newValidationState.equipmentAssetImage = true;
		}

		if (!assetTag) {
			isValid = false;
			newValidationState.newAssetTag = true;
		}

		setValidationState(newValidationState);
		return isValid;
	};
	const handleSubmit = async () => {
		console.log(previousData, "previousData");
		console.log(responseData, "responseData");
		// Get previous values for comparison in the audit log
		const previousImage = previousData?.idTagAssetImage;
		const previousTag = previousData?.assetTag;

		// Validate fields before submission
		if (!validateFields()) {
			console.log(validationState);
			setAlertModal({
				show: true,
				title: "Missing Data",
				body: "Please fill all the required fields before submitting.",
			});
			setTimeout(closeAlertModal, 2500);
			return;
		}

		// Proceed if submission ID, image, and new asset tag are available
		if (submissionId && uploadedImage && responseData.newAssetTag) {
			console.log("Preparing to update submission with new data");

			// Prepare the updated data
			const updatedData = {
				idTagAssetImage: uploadedImage,
				newAssetTag: responseData.newAssetTag,
				assetTag: assetTag,
				auditingUser: authInfo.displayName, // Track the user making the change
			};

			// Get the existing audit log or initialize a new one
			const auditLog = previousData.auditLog || [];

			console.log(previousTag, "previousTag");
			// Prepare the new audit entry
			const auditEntry = {
				auditingUser: authInfo.displayName,
				auditedDate: new Date().toISOString(),
				changes: {
					idTagAssetImage: {
						before: previousImage || "N/A",
						after: uploadedImage,
					},
					assetTag: {
						before: previousTag || "N/A",
						after: responseData.newAssetTag,
					},
				},
			};

			console.log("Audit Entry:", auditEntry);
			// Add the new audit entry to the existing audit log
			auditLog.push(auditEntry);

			// Include the audit log in the updated data
			updatedData.auditLog = auditLog;

			try {
				console.log("Updating submission with data:", updatedData);
				await updateTagReplacementFields(
					organization,
					submissionId,
					submissionRecord.form_id,
					updatedData,
				);
				console.log("Submission successfully updated");

				setIsSubmitted(true);

				setAlertModal({
					show: true,
					title: "Tag Replacement Successful",
					body: "The asset tag has been successfully updated.",
					options: [
						{
							text: "OK",
							action: () => {
								setAlertModal({
									show: false,
									title: "",
									body: "",
								});
								handleNavigation(); // Use the navigation function
							},
						},
					],
				});
			} catch (error) {
				console.error("Error during submission update:", error);
				setAlertModal({
					show: true,
					title: "Update Failed",
					body: "Failed to update the submission.",
				});
				setTimeout(closeAlertModal, 2500);
			}
		} else {
			console.log("Missing data: Cannot update submission");
			setAlertModal({
				show: true,
				title: "Missing Data",
				body: "Cannot update submission due to missing data.",
			});
			setTimeout(closeAlertModal, 2500);
		}
	};

	// // Example useEffect to fetch existing submission data if needed
	// useEffect(() => {
	// 	// Fetch existing submission data logic here

	// 	setIsLoading(false);
	// }, []);

	// Updates various states upon init render
	useEffect(() => {
		console.log(
			"bullshit",
			organization,
			submissionRecord.form_id,
			submissionId,
			isQA,
		);
		if (!submissionId || !organization) return;
		const fetchData = async () => {
			const data = await getSubmissionData(
				organization,
				submissionRecord.form_id,
				submissionId,
				isQA,
				setResponseData,
				setFormName,
				setFormLogo,
				setAddress,
				setLocationId,
				setUserLocation,
				setChosenAssetType,
				setAssetEdits,
			);
			setOriginalLocation(address || data?.location);
			setSelectedLocation(address || data?.location);
			getOrgSpecificInputs(userOrganization, setOrgSpecificInputs);
		};
		fetchData();
	}, [submissionId, organization, isQA, submissionRecord.form_id]);

	// Updates assetConditionalInputs & inputsRequired upon prev states being retrieved
	useEffect(() => {
		if (
			responseData !== undefined &&
			formName !== undefined &&
			formLogo !== undefined &&
			address !== undefined &&
			locationId !== undefined &&
			userLocation !== undefined &&
			chosenAssetType !== undefined
		) {
			const condInputs = [];
			if (chosenAssetType?.inputsNotRequired) {
				setInputsRequired(false);
			} else {
				setInputsRequired(true);
			}
			const condInputsJSON = chosenAssetType?.conditionalInputs || null;
			if (condInputsJSON) {
				for (const input of condInputsJSON) {
					condInputs.push(JSON.parse(input));
				}
			}
			console.log("Conditional: ", condInputs);
			setAssetConditionalInputs(condInputs);
			setIsLoading(false);
		}
	}, [
		responseData,
		formName,
		formLogo,
		address,
		locationId,
		userLocation,
		chosenAssetType,
	]);

	// Updates inputs and responseData when inputsRequired becomes false
	// Else, update inputs only
	useEffect(() => {
		if (!inputsRequired) {
			setInputs(deepCopy(notRequiredInputs));
		} else {
			let newInputs = deepCopy(inputs);
			if (
				assetConditionalInputs.length > 0 &&
				newInputs[4]?.title !== "Asset Details - Part 2"
			) {
				const newPage = {
					title: "Asset Details - Part 2",
					questions: [...assetConditionalInputs],
				};
				newInputs = [...newInputs.slice(0, 4), newPage, ...newInputs.slice(4)];
			}
			if (
				orgSpecificInputs.length > 0 &&
				newInputs.at(-2)?.title !== "Additional Details"
			) {
				const newPage = {
					title: "Additional Details",
					questions: [...orgSpecificInputs],
				};
				newInputs = [
					...newInputs.slice(0, -1),
					newPage,
					...newInputs.slice(-1),
				];
			}
			setInputs(newInputs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputsRequired, assetConditionalInputs, orgSpecificInputs]);

	const _filterKeys = (key) => {
		console.log("key for filtering", key);
		if (key === "manufacturersPlateAssetImage") {
			return !responseData.manufacturerNotAvailable;
		}
		if (
			key === "assetType" ||
			key === "tempAlertId" ||
			key === "tempAlertIdAssetImage" ||
			key === "manufacturer" ||
			key === "ifOtherManufacturer" ||
			key === "modelNumber" ||
			key === "serialNumber" ||
			key === "manufacturerDate" ||
			key === "assetTag" ||
			key === "idTagAssetImage" ||
			key === "warrantyNotFound" ||
			key === "warrantyPeriod" ||
			key === "warrantyStartDate" ||
			key === "warrantyExpirationDate" ||
			key === "warrantyProvider" ||
			key === "refrigerant" ||
			key === "refrigerantCharge" ||
			key === "voltage" ||
			key === "amps" ||
			key === "powerSource" ||
			key === "typeOfSystem" ||
			key === "numDoors"
		) {
			return true;
		}
		return false;
	};
	const generateQuestions = () => {
		const seenKeys = new Set(); // Set to keep track of already rendered questions
		const questions = inputs.flatMap((page) => page.questions);

		// Only include the relevant keys in the priorityKeys
		const priorityKeys = [
			"equipmentAssetImage",
			"assetType",
			"idTagAssetImage",
			"assetTag",
		];
		const sortedQuestions = questions.sort((a, b) => {
			const indexA = priorityKeys.indexOf(a.key);
			const indexB = priorityKeys.indexOf(b.key);
			if (indexA === -1 && indexB === -1) return 0;
			if (indexA !== -1 && indexB === -1) return -1;
			if (indexA === -1 && indexB !== -1) return 1;
			return indexA - indexB;
		});

		const questionComponents = sortedQuestions.map((question) => {
			const hasError = validationState[question.key];

			// Skip if the question has already been rendered
			if (seenKeys.has(question.key)) {
				return null;
			}
			seenKeys.add(question.key);

			// Skip if the key is not one of the relevant keys
			if (!priorityKeys.includes(question.key)) return null;

			if (question.type === "image") {
				if (question.key === "equipmentAssetImage") {
					return (
						<FormGroup
							key={question.key}
							className="p-3 d-flex flex-column"
							style={{ borderColor: hasError ? "red" : "" }}
						>
							<Label className="submission-label text-center">
								{question.label}
							</Label>
							<div className="d-flex flex-column align-items-center">
								<img
									className="submitted-image"
									src={responseData[question.key]}
									alt="Uploaded Preview"
									style={{
										maxWidth: "100%",
										cursor: "not-allowed",
										border: hasError ? "1px solid red" : "",
									}}
									disabled
								/>
								{/* This image is not editable */}
							</div>
						</FormGroup>
					);
				}
				if (question.key === "idTagAssetImage") {
					return (
						<FormGroup
							key={question.key}
							className="p-3 d-flex flex-column"
							style={{ borderColor: hasError ? "red" : "" }}
						>
							<Label className="submission-label text-center">
								{question.label}
							</Label>
							<div className="d-flex flex-column align-items-center">
								<img
									className="submitted-image"
									src={uploadedImage || responseData[question.key]}
									alt="Uploaded Preview"
									style={{
										maxWidth: "100%",
										cursor: "pointer",
										border: hasError ? "1px solid red" : "",
									}}
									onClick={() => fileInputRef.current?.click()}
								/>
								<Button
									color="secondary"
									onClick={() => fileInputRef.current?.click()}
									className="w-100"
								>
									Add new photo
								</Button>
								<Input
									innerRef={fileInputRef}
									type="file"
									onChange={handleImageUpload}
									style={{ display: "none" }}
									accept="image/*"
								/>
								{hasError && (
									<div className="text-danger mt-2">
										This field is required.
									</div>
								)}
							</div>
						</FormGroup>
					);
				}
			} else if (question.key === "assetTag") {
				return (
					<FormGroup
						key={question.key}
						className="p-3 d-flex flex-column"
						style={{ borderColor: hasError ? "red" : "" }}
					>
						<Label className="submission-label text-center">
							{question.label}
						</Label>
						<Input
							type="text"
							value={responseData[question.key]}
							disabled
							style={{
								border: hasError ? "1px solid red" : "",
							}}
						/>
						{hasError && (
							<div className="text-danger mt-2">This field is required.</div>
						)}
					</FormGroup>
				);
			} else if (question.key === "assetType") {
				return (
					<FormGroup
						key={question.key}
						className="p-3 d-flex flex-column"
						style={{ borderColor: hasError ? "red" : "" }}
					>
						<Label className="submission-label text-center">
							{question.label}
						</Label>
						<Input
							type="text"
							value={responseData[question.key]?.name}
							disabled
							style={{
								border: hasError ? "1px solid red" : "",
							}}
						/>
						{hasError && (
							<div className="text-danger mt-2">This field is required.</div>
						)}
					</FormGroup>
				);
			}
			return null;
		});

		// Add new text input for entering a new assetTag
		const newAssetTagInput = (
			<FormGroup key="newAssetTag" className="p-3 d-flex flex-column">
				<Label className="submission-label text-center">New Asset Tag</Label>
				<Input
					type="text"
					value={responseData.newAssetTag || ""}
					onChange={(e) => handleFieldChange("newAssetTag", e.target.value)}
					style={{
						border: validationState.newAssetTag ? "1px solid red" : "",
					}}
				/>
				{validationState.newAssetTag && (
					<div className="text-danger mt-2">This field is required.</div>
				)}
			</FormGroup>
		);

		return [...questionComponents, newAssetTagInput];
	};

	// const canUserEdit = () => {
	// 	const userRole = authInfo.role;
	// 	// Add logic to determine if the user can edit the data
	// 	return true; // Example: Allow all users to edit for now
	// };

	return isLoading ? (
		<Loader />
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<CardHeader
					className="d-flex flex-row align-items-center justify-content-between"
					style={{
						backgroundColor: "#ffffff",
						border: "none",
						position: "relative",
					}}
				></CardHeader>

				<CardBody style={{ marginTop: "20px" }}>
					{generateQuestions()}

					<div className="d-flex align-items-center justify-content-center">
						<Button
							className="m-2 px-4"
							onClick={handleSubmit}
							// disabled={!canUserEdit()}
						>
							Submit
						</Button>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export { TagReplacementEdit };
