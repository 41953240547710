import { ALFRED_SERVICE_URL } from "@/constants/env";
import type { StringMap } from "@/models/abstractTypes";
import { auth, db } from "@assets/services/auth-service";
import { DB_FILTER, DB_PATH } from "@constants/db";
import { collection, getDocs, query, where } from "firebase/firestore";

// Fetches a specific form from the sql database
export const getSQLForm = async (form_id: number) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/organization/self/forms/${form_id}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to get self forms: ${errorDetails.detail}`);
			});
		}

		const responsejson = await response.json();
		return responsejson;
	} catch (exception: unknown) {
		console.error(`Failed to get self forms: ${exception}`);
		throw new Error("Failed to get forms");
	}
};

export const getForms = async (
	organization: string,
	formName: string | undefined,
) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS,
	);
	const formsSnap = formName
		? await getDocs(query(colRef, where(DB_FILTER.FORM_NAME, "==", formName)))
		: await getDocs(colRef);
	return formsSnap;
};

export const getSelfForms = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/organization/self/forms`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to get self forms: ${errorDetails.detail}`);
			});
		}

		const responsejson = await response.json();
		return responsejson;
	} catch (exception: unknown) {
		console.error(`Failed to get self forms: ${exception}`);
		throw new Error("Failed to get forms");
	}
};

export const getFormId = async (organization: string, formName: string) => {
	let formId: number | string = "";
	const snap = await getForms(organization, formName);
	for (const form of snap.docs) {
		formId = form.id;
	}
	return formId;
};

export const getFormDropdowns = async (
	organization: string,
	formId: string,
	collectionName: string,
) => {
	return await getDocs(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			collectionName,
		),
	);
};

// Queries database for form data and passes to state arg
export const getFormData = async (
	_org: string,
	formId: string | number,
	setFormName: CallableFunction,
	setFormLogo: CallableFunction,
	setFormFlags: CallableFunction | undefined,
) => {
	const form = await getSQLForm(formId);
	if (form) {
		setFormName(form.master_form_key.name);
		setFormLogo(form.config.logo);
		if (setFormFlags) setFormFlags(form);
	}
};

// Retrieves the dropdown options associated with applicable inputs and passes it to state arg
export const getOptionsData = async (
	inputs: { questions: StringMap[] }[],
	assetCondInputs: string | unknown[],
	orgSpecInputs: string | unknown[],
	setOptions: CallableFunction,
	organization: StringMap,
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
	flags: { collections: { [x: string | string]: any } },
	airportCode: string | number,
) => {
	const newOptions: { [x: number | string]: { [x: string]: unknown }[] } = {};
	// get options for initial inputs
	for (let i = 0; i < inputs.length; i++) {
		const questions = inputs[i].questions;
		for (let j = 0; j < questions.length; j++) {
			const question = questions[j];
			await pushToNewOptions(
				question,
				newOptions,
				organization,
				flags,
				airportCode,
			);
		}
	}
	// get options for asset conditional inputs
	for (let i = 0; i < assetCondInputs.length; i++) {
		const question = assetCondInputs[i] as StringMap;
		await pushToNewOptions(
			question,
			newOptions,
			organization,
			flags,
			airportCode,
		);
	}
	// get options for org specific inputs
	for (let i = 0; i < orgSpecInputs.length; i++) {
		const question = orgSpecInputs[i] as StringMap;
		await pushToNewOptions(
			question,
			newOptions,
			organization,
			flags,
			airportCode,
		);
	}

	if (flags?.collections && Object.keys(flags.collections).length > 0) {
		for (const key in flags.collections) {
			if (
				newOptions[key] === undefined ||
				newOptions[key] === null ||
				newOptions[key].length === 0
			) {
				newOptions[key] = flags.collections[key];
			}
		}
	}

	if (flags?.collections && Object.keys(flags.collections).length > 0) {
		for (const key in flags.collections) {
			// Only overwrite newOptions[key] if it's not already populated
			if (
				newOptions[key] === undefined ||
				newOptions[key] === null ||
				newOptions[key].length === 0
			) {
				newOptions[key] = flags.collections[key];
			}
		}
	}
	setOptions(newOptions);
};

const pushToNewOptions = async (
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
	question: { [x: string]: string | any },
	newOptions: { [x: number]: unknown[] },
	_organization: StringMap,
	// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
	flags: { collections: { [x: string]: any[] } },
	airportCode: string | number,
) => {
	if (question.collection) {
		const collectionName = question.collection;
		if (!flags || !flags.collections) {
			newOptions[collectionName] = [];
		} else {
			const docSnap = flags.collections[collectionName];
			if (docSnap && Object.keys(docSnap).length > 0) {
				let names = docSnap.map(
					(doc: {
						id: string;
						name: string;
						order: string;
						location: string;
					}) => {
						const obj = {
							key: doc.id,
							value: doc.name,
							order: doc.order,
							location:
								question.collection === "store-names" ? doc.location : null,
						};
						return obj;
					},
				);
				if (airportCode) {
					const newNames = names.filter(
						(name) => name.location === airportCode,
					);
					if (newNames.length > 0) {
						names = newNames;
					}
				}
				// biome-ignore lint/suspicious/noExplicitAny: TODO: Circle back and add types
				names.sort((a: any, b: any) => {
					// biome-ignore lint/style/noParameterAssign: TODO: Circle back and figure out wtf we were doing here
					a =
						a.order && typeof a.order === "string"
							? a.order.toLowerCase()
							: a.value && typeof a.value === "string"
								? a.value.toLowerCase()
								: "";
					// biome-ignore lint/style/noParameterAssign: TODO: Circle back and figure out wtf we were doing here
					b =
						b.order && typeof b.order === "string"
							? b.order.toLowerCase()
							: b.value && typeof b.value === "string"
								? b.value.toLowerCase()
								: "";

					// biome-ignore lint/style/noParameterAssign: TODO: Circle back and figure out wtf we were doing here
					if (typeof a === "string" && a.charAt(0) === " ") a = a.slice(1);
					// biome-ignore lint/style/noParameterAssign: TODO: Circle back and figure out wtf we were doing here
					if (typeof b === "string" && b.charAt(0) === " ") b = b.slice(1);

					return a < b ? -1 : a > b ? 1 : 0;
				});

				newOptions[collectionName] = names;
			} else {
				newOptions[collectionName] = [];
			}
		}
	}
};
