// const Metadata = () => {
// 	return (
// 		<div className='step' id='step-metadata'>
// 			<label>
// 				Manufacturer
// 				<input
// 					id='manufacturer-input'
// 					list='manufacturers'
// 					name='inputManufacturer'
// 				/>
// 			</label>
// 			<datalist id='manufacturers'></datalist>
// 			<br />
// 			<br />
// 			<label>
// 				Unique template name
// 				<input id='name-input' name='inputName' />
// 			</label>
// 			<br />
// 			<br />
// 			<img
// 				id='metadata-template-image'
// 				height='800'
// 				width='800'
// 				style={{ 'object-fit': 'contain' }}
// 			/>
// 		</div>
// 	);
// };
//
// export { Metadata };

const Metadata = ({ responseData, setResponseData }) => {
	// Handle changes in the manufacturer input
	const handleManufacturerChange = (event) => {
		setResponseData({
			...responseData,
			manufacturer: event.target.value,
		});
	};

	// Handle changes in the template name input
	const handleTemplateNameChange = (event) => {
		const newName = event.target.value;
		setResponseData({
			...responseData,
			templateName: newName,
		});
	};

	return (
		<div className="step" id="step-metadata">
			<label>
				Manufacturer
				<input
					id="manufacturer-input"
					list="manufacturers"
					name="inputManufacturer"
					value={responseData.manufacturer || ""}
					onChange={handleManufacturerChange}
				/>
			</label>
			<br />
			<br />
			<label>
				Unique template name
				<input
					id="name-input"
					name="inputName"
					value={responseData.templateName || ""}
					onChange={handleTemplateNameChange}
				/>
			</label>
			<br />
			<br />
			<img
				id="metadata-template-image"
				src={responseData.image}
				alt="Template Preview"
				height="800"
				width="800"
				style={{ objectFit: "contain" }}
			/>
		</div>
	);
};

export { Metadata };
